define('scientia-resourcebooker/models/booking-form-field', ['exports', 'ember', 'ember-data', 'model-fragments/fragment', 'ember-cp-validations'], function (exports, _ember, _emberData, _modelFragmentsFragment, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    // description: [
    // validator('presence', true)
    // ],
    shortDescription: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed.bool('model.isReadOnlyField') }), (0, _emberCpValidations.validator)('length', {
      max: 15,
      disabled: _ember['default'].computed.bool('model.isReadOnlyField') })],
    externalResourceType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed.not('model.isExternallyManaged')
    })],
    externalFieldName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed.not('model.isExternallyManaged')
    })]

  });

  exports['default'] = _modelFragmentsFragment['default'].extend(Validations, {
    identity: attr('string'),
    fieldType: attr('number'),
    fieldTypeName: attr('string'),
    description: attr('string'),
    shortDescription: attr('string'),
    isActive: attr('boolean'),
    rank: attr('number'),
    resetApprovalOnEdit: attr('boolean'),
    isMandatory: attr('boolean'),
    isExternallyManaged: attr('boolean'),
    externalResourceType: attr('raw'),
    externalFieldName: attr('string'),
    externalDataSource: attr('string'),
    value: attr('raw'),
    isManaging: attr('boolean'),
    isReadOnlyField: (function () {
      return [1, 6].includes(this.get('fieldType')); // 1 is Label and 6 is URL
    }).property('fieldType'),
    isAttachment: (function () {
      return this.get('fieldType') === 0;
    }).property('fieldType')
  });
});