define('scientia-resourcebooker/adapters/booking-request', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    lastFetched: null,
    shouldRefreshInSeconds: 60,

    /**
     * Modifies the URL when calling findRecord(booking-request, id);
     * When fetching a booking we need to call the API with the Event ID and not with the Unique ID (occurrence ID)
     * So we check the if the snapshot has an eventId. If it does use it else use the normal ID
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var eventId = snapshot.attr('eventId');
      var url = this._buildURL(modelName, eventId || id);
      return url += '?checkResourceRestrictions=true';
    },

    urlForQueryRecord: function urlForQueryRecord(query, modelName) {

      query.checkResourceRestrictions = true;
      if (!query.occurrenceId || !query.bookingId) return this._super.apply(this, arguments);

      var url = this._buildURL(modelName, query.bookingId);
      url += '/Occurrences/' + query.occurrenceId;
      delete query.bookingId;
      delete query.occurrenceId;
      return url;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return true;
    },

    /**
     * Hook to decide whether or not the model should be reloaded or a cache returned
     */
    shouldReloadAll: function shouldReloadAll() /*store, snapshot*/{

      // let hasData = store.peekAll(snapshot.type.modelName).get('length');
      var lastFetched = this.get('lastFetched');

      if (!lastFetched) {
        this.set('lastFetched', moment());
        return true;
      }
      return false;
    },

    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() /*store, snapshot*/{
      // let hasData = store.peekAll(snapshot.type.modelName).get('length');
      var lastFetched = this.get('lastFetched');

      if (!lastFetched) {
        this.set('lastFetched', moment());
        return true;
      }

      var diffSeconds = moment().diff(lastFetched, 'seconds');

      var shouldReload = diffSeconds > this.get('shouldRefreshInSeconds');
      if (shouldReload) {
        this.set('lastFetched', moment());
        return true;
      }
    }

  });
});