define('scientia-resourcebooker/services/icons-fetcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    systemSettings: _ember['default'].inject.service(),

    fetch: function fetch() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.getJSON(_this.get('systemSettings').getSetting('iconsTemplateUrl') + '/manifest-icons.json').done(function (_ref) {
          var icons = _ref.icons;

          resolve(icons.map(function (pack) {
            return _this._mapObject(pack);
          }));
        }).fail(reject);
      });
    },

    _mapObject: function _mapObject(pack) {
      var _this2 = this;

      return _ember['default'].Object.create({
        value: pack.value,
        label: pack.label,
        icons: pack.icons.map(function (icon) {
          return _this2.get('systemSettings').getSetting('iconsTemplateUrl') + '/' + pack.value + '/' + icon;
        })
      });
    }
  });
});