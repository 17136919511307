define('scientia-resourcebooker/pods/components/manage-resources/permissions-summary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['permissionsSummary'],
    classNameBindings: ['location', 'showUserGroupsTab:permissionsSummary-withUserGroupsTab'],
    location: null,

    showUserGroupsTab: false,

    selectedTab: 1,

    adminPermissions: _ember['default'].computed.alias('model.admin'),
    bookPermissions: _ember['default'].computed.alias('model.book'),
    userGroupsPermissions: _ember['default'].computed.alias('model.permissionGroupPermissions'),

    tabs: _ember['default'].computed('showUserGroupsTab', function () {
      var tabs = ['adminPermissions', 'bookPermissions'];
      if (this.get('showUserGroupsTab')) tabs.push('userGroupsPermissions');
      return tabs;
    }),

    tabIndexes: _ember['default'].computed(function () {
      return {
        adminPermissions: 1,
        bookPermissions: 2,
        userGroupsPermissions: 3
      };
    }),

    isShowingUserGroupsData: _ember['default'].computed.equal('selectedTab', 3),

    permissionData: _ember['default'].computed('selectedTab', function () {
      return this.get('selectedTab') === 1 ? this.get('model.admin') : this.get('model.book');
    }),

    actions: {
      tabChange: function tabChange(tab) {
        this.set('selectedTab', tab);
      },

      isTabHidden: function isTabHidden(tabName, selectedTab) {
        return this.get('tabIndexes')[tabName] !== selectedTab;
      }
    }
  });
});