define('scientia-resourcebooker/models/booking-form-field-single-choice', ['exports', 'ember', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'model-fragments/attributes', 'ember-cp-validations'], function (exports, _ember, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _modelFragmentsAttributes, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    externalDataSourceUri: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed.not('model.isUsingExternalDataSource')
    })],

    items: [(0, _emberCpValidations.validator)('uniqueItemValue', {
      dependentKeys: ['model.items.@each.value'],
      disabled: _ember['default'].computed.bool('model.isUsingExternalDataSource')
    }), (0, _emberCpValidations.validator)('emptyItemValueText', {
      dependentKeys: ['model.items.@each.value', 'model.items.@each.text'],
      disabled: _ember['default'].computed.bool('model.isUsingExternalDataSource') })]

  });

  var ValidationsUser = (0, _emberCpValidations.buildValidations)({
    value: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        dependentKeys: ['model.items.[]', 'value'],
        messageKey: 'errors.singleChoiceRequired',
        disabled: _ember['default'].computed(function () {
          return !this.get('model.isActive') || !this.get('model.isMandatory') || this.get('model.isManaging') || _ember['default'].isEmpty(this.get('model.items'));
        })
      })]
    }
  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, ValidationsUser, {
    defaultValue: (0, _emberDataAttr['default'])('string'),
    isUsingExternalDataSource: (0, _emberDataAttr['default'])('boolean'),
    externalDataSourceIsSearchable: (0, _emberDataAttr['default'])('boolean'),
    externalDataSourceUri: (0, _emberDataAttr['default'])('string'),
    singleChoiceType: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    isDropdown: _ember['default'].computed.equal('singleChoiceType', 0),
    isRadio: _ember['default'].computed.equal('singleChoiceType', 1),
    isSearch: _ember['default'].computed.equal('singleChoiceType', 2),
    items: (0, _modelFragmentsAttributes.fragmentArray)('booking-form-field-single-choice-item', { defaultValue: [] }),
    isListType: (function () {
      return this.get('singleChoiceType') === 1;
    }).property('singleChoiceType')
  });
});