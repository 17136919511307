define('scientia-resourcebooker/pods/components/button-print/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['buttonPrint'],
    classNameBindings: ['location'],

    actions: {
      onClick: function onClick() {
        this.onClick();
      }
    }
  });
});