define('scientia-resourcebooker/services/help-fetcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    systemSettings: _ember['default'].inject.service(),
    helpData: null,
    appInsights: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    fetch: function fetch(path) {
      var _this = this;

      var url = 'assets/help.data.json';
      // this.get('appInsights').trackEvent('global-help-click', {path: path, locale: this.get('i18n.locale')});

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this.get('helpData')) {
          resolve(_this._getHelpForPath(path));
        } else {
          _ember['default'].$.getJSON(url).done(function (helpData) {
            _this.set('helpData', helpData);
            resolve(_this._getHelpForPath(path));
          }).fail(function (e) {
            reject(e);
          });
        }
      });
    },

    getHomepageForLocale: function getHomepageForLocale(locale) {
      var home = this.get('helpData.home');
      var prefix = this.get('helpData.url');
      if (home && home.lang && home.lang[locale]) {
        return prefix + home.lang[locale];
      } else {
        return prefix + home.url;
      }
    },

    _getHelpForPath: function _getHelpForPath(path) {
      var data = this.get('helpData');
      var pathData = data.pages[path];

      this.get('appInsights').trackEvent('global-help-click', { path: path, locale: this.get('i18n.locale'), hasHelpData: !_ember['default'].isEmpty(pathData) });

      if (!pathData) return null;
      pathData.prefix = data.url;
      return pathData;
    },

    _mapObject: function _mapObject(url) {
      return _ember['default'].Object.create({ url: url });
    }
  });
});