define('scientia-resourcebooker/pods/manage-resources/booking-type/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.booking_type.index.booking_types'), linkable: false }];
    }).property('i18n.locale'),

    bookingTypes: _ember['default'].computed.sort('model', 'bookingTypeSorting'),
    bookingTypeSorting: ['name'],

    bookingType: null,
    showConfirmDeleteBookingType: false,

    systemSettings: _ember['default'].inject.service(),

    exchangeEnabled: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('exchangeEnabled');
    }),

    actions: {
      deleteBookingType: function deleteBookingType(bookingType) {
        this.set('bookingType', bookingType);
        this.set('showConfirmDeleteBookingType', true);
      },

      confirmDeleteBookingType: function confirmDeleteBookingType() {
        var _this = this;

        this.get('bookingType').destroyRecord().then(function () {
          return _this.deleteSuccessFlash('booking-type');
        })['catch'](function () {
          return _this.deleteErrorFlash('booking-type');
        })['finally'](function () {
          _this.set('showConfirmDeleteBookingType', false);
          _this.set('bookingType', null);
          _this.set('confirmMessage', null);
          _this.set('confirmTitle', null);
        });
      },

      onBookingTypeBallClick: function onBookingTypeBallClick(bookingType) {
        this.transitionToRoute('manage-resources.booking-type.edit', bookingType.get('id'), { queryParams: { section: 1 } });
      }
    }
  });
});