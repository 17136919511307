define('scientia-resourcebooker/models/resource-group', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    description: attr('string'),

    canEdit: attr('boolean'),
    canDelete: attr('boolean'),
    canEditAll: attr('boolean'),
    canEditDescription: attr('boolean'),
    canEditPermissions: attr('boolean'),
    canEditResources: attr('boolean'),
    canAddResources: attr('boolean'),

    resourceTypeId: attr('string'),
    resourceType: belongsTo('resource-type'),
    resourceIds: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    resourceCount: attr('number'),
    hasAssociatedBookingTypes: attr('boolean'),

    readPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('read-permission-group', { defaultValue: [] }),
    showPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('show-permission-group', { defaultValue: [] }),
    writePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('write-permission-group', { defaultValue: [] }),
    bookPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('book-permission-group', { defaultValue: [] }),
    requestPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('request-permission-group', { defaultValue: [] }),

    editAllPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-all-permission-group'),
    editDescriptionPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-description-permission-group'),
    editPermissionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-permissions-permission-group'),
    editResourcesPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-resources-permission-group'),

    viewCalendarsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-calendars-permission-group'),
    viewBookingsReportPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-bookings-report-permission-group'),
    viewAuditReportPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-audit-report-permission-group'),
    manageBookingsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('manage-bookings-permission-group'),

    resourcesEditAllPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('resources-edit-all-permission-group'),
    resourcesEditDescriptionPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('resources-edit-description-permission-group'),
    resourcesEditCustomFieldsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('resources-edit-custom-fields-permission-group'),
    resourcesEditAvailabilityPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('resources-edit-availability-permission-group'),
    resourcesEditPermissionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('resources-edit-permissions-permission-group'),

    permissionsKeys: ['read', 'write', 'show', 'request', 'book', 'view-calendars', 'view-bookings-report', 'view-audit-report', 'manage-bookings', 'edit-description', 'edit-resources', 'edit-permissions', 'edit-all', 'resources-edit-description', 'resources-edit-custom-fields', 'resources-edit-availability', 'resources-edit-permissions', 'resources-edit-all']
  });
});