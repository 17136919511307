define('scientia-resourcebooker/pods/components/manage-resources/bulk-importer/component', ['exports', 'ember', 'ember-uploader/uploaders'], function (exports, _ember, _emberUploaderUploaders) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkImporter'],
    classNameBindings: ['location'],
    location: '',

    errorHandler: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    bulkImporter: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    step: 1,
    isStep1: _ember['default'].computed.equal('step', 1),
    isStep2: _ember['default'].computed.equal('step', 2),
    isStep3: _ember['default'].computed.equal('step', 3),

    step2ProgressTaskKey: null,
    step2PoolingProgressResults: null,

    // Enable showing any errors message after import is completed
    showErrorsOnCompletion: false,

    files: [],

    // Only applicable if we are importing bookings
    bookingTypeId: null,

    isCreateMode: _ember['default'].computed('importOptions.@each.value', function () {

      if (this.get('importType') === 'bookings') return true;

      var option = this.get('importOptions').findBy('isCreateKey', true);
      if (option) return option.value === true;
      return false;
    }),

    didInsertElement: function didInsertElement() {
      _ember['default'].run.next(this, 'set', 'files', []);
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('isDestroyed') || this.get('isDestroying')) return;
      this.set('files', []);

      this.sendAction('resetProperties');
    },

    /**
    * [
      {
        id: 'existing_user_groups', // Key to be used for translation (localePrefix + id)
        valueType: 'toggle', // boolean or toggle
        options: [{id: 'update', label: 'update', value: 1}, {id: 'replace', label: 'replace', value: 0}], // Only provide if toggle
        value: ['update'] // For toggle is an array of length 1 with the selected id from the options array. For boolean is True or False
      },
    */
    importOptions: [],

    actions: {

      filesDidChange: function filesDidChange(optionId, file) {

        var files = this.get('files');
        var existingFile = files.findBy('optionId', optionId);
        if (existingFile) {
          files.removeObject(existingFile);
        }

        // If we are uploading a mapping file then make sure we set the mappingFileName as well (due to api not knowing which one is which)
        if (optionId === 'mapping_file') {
          var mappingFilenameField = this.get('importOptions').findBy('id', 'mapping_filename');
          if (mappingFilenameField) _ember['default'].set(mappingFilenameField, 'value', file.name);
        }

        files.pushObject({
          optionId: optionId,
          file: file,
          name: file.name,
          sizeKB: (file.size / 1024.0).toFixed(2)
        });
      },

      /**
      *
      * @param {*} files
      */
      // onFileSelect(files) {
      //   const upload = files[0];
      //   this.set('uploadFile', upload);
      // },

      /**
      * Ran when clicking the preview button. Uploads the selected file and sets up the event handlers for the upload
      */
      onPreviewImport: function onPreviewImport() {
        var _this = this;

        var files = this.get('files');
        var params = this._getPreviewParams();
        var importType = this.get('importType');

        // Remove mapping file is "Include mapping file" was toggle after selecting the file
        var isMappedImport = this.get('importOptions').findBy('id', 'is_mapped_import');
        if (isMappedImport && !isMappedImport.value) {
          var mappingFile = files.findBy('optionId', 'mapping_file');
          if (mappingFile) files.removeObject(mappingFile);
        }

        if (files) {
          var url = this.get('bulkImporter').getPreviewURL({ type: importType, params: params });

          var uploader = _emberUploaderUploaders.Uploader.create({
            url: url,
            ajaxSettings: this.get('bulkImporter').getAjaxSettings(importType)
          });
          uploader.upload(files.getEach('file'));
          this.send('uploadStarted');

          uploader.on('didUpload', function (e) {
            return _this.send('onUploadFinish', e);
          });
          uploader.on('didError', function (e) {
            return _this.send('uploadFailed', e);
          });
        }
      },

      /**
      * Cancels a preview and returns you to the first step by resetting most of the state properties
      */
      onPreviewCancel: function onPreviewCancel() {
        this.send('resetProperties');
        // workaround to make sure after clearing we can select the same file
        _ember['default'].$('.bulkImporterUploader-fileWrapper input').val('');
      },

      onUploadFinish: function onUploadFinish(progressTaskKey) {
        this.set('step2ProgressTaskKey', progressTaskKey);
      },
      /**
      * Event handler for when the upload has started. Sets up some of the state properties
      */
      uploadStarted: function uploadStarted() {
        this.setProperties({
          isUploading: true
        });
      },

      getImportPreviewResults: function getImportPreviewResults(progressResults) {
        var _this2 = this;

        var importType = this.get('importType');
        this.get('bulkImporter').getPreviewData({ type: importType, taskKey: progressResults.dataFetchKey }).then(function (taskPreviewResponse) {
          _this2.send('previewProcessed', taskPreviewResponse);
        });
      },

      /**
      * Event handler for when the upload has finished. Sets up some of the state properties and process it's response by calling `_processResponse`
      * @param {any} taskPreviewResponse An object containing information regarding the import, if it's valid, error messages or information messages inc the importKey `{errorMessages, importKey, importValid, informationMessages}`
      */
      previewProcessed: function previewProcessed(taskPreviewResponse) {
        this.set('step2ProgressTaskKey', null);
        var response = this._processResponse(taskPreviewResponse);

        try {
          this.get('bulkImporter').log({ type: this.get('importType'), step: this.get('step'), request: JSON.stringify(this._getPreviewParams()) });
        } catch (e) {
          // no-op
        }

        this.setProperties({
          isUploaded: true,
          isUploading: false,
          taskPreviewResponse: response,
          step: 2
        });
      },

      /**
      * Event handler for when the upload has failed. For some rason we are not getting the jqXHR or textStatus from the component
      * And because this is going through the internal ajax method of the uploader component we are not handling 401's nor can we check for it
      * @todo Check if error occurred and determine if the token expired or simply an upload error. (Maybe calling userProfile to trigger 401 if thats the case)
      * @param {*} jqXHR
      * @param {*} textStatus
      * @param {*} errorThrown
      */
      uploadFailed: function uploadFailed() /*jqXHR, textStatus, errorThrown*/{

        // Remove filename from input field
        if (!this.get('isDestroyed')) {
          _ember['default'].$('.bulkImporterUploader-file-dropzone input').val('');
        }

        this.setProperties({
          isError: true,
          isUploading: false
        });

        // This triggers an api request through authenticatedRequest which will trigger 401 and redirect user in case
        // the reason of this error was due to authentication
        this.get('systemSettings').reload();
      },

      onGetProgressFailed: function onGetProgressFailed(progressResults, error) {
        this.setProperties({
          isUploading: false,
          step2ProgressTaskKey: null
        });

        LE.error({
          progressResults: progressResults,
          error: error
        });
      },

      onCancelGetProgress: function onCancelGetProgress() {
        this.setProperties({
          isUploading: false,
          step2ProgressTaskKey: null
        });
      },

      /**
       * Confirms the preview task and initiates pooling for the progress status
       * @todo: Handle error
       * @param {string} previewTaskKey The preview task key
       */
      onConfirmTask: function onConfirmTask(previewTaskKey) {
        var _this3 = this;

        var importType = this.get('importType');
        this.set('isConfirmingTask', true);

        this.get('bulkImporter').confirmTask({ type: importType, taskKey: previewTaskKey }).then(function (taskConfirmProgressKey) {
          _this3.set('isTaskConfirmed', true);
          _this3.set('isTaskRunning', true);
          _this3.send('onConfirmTaskProgress', taskConfirmProgressKey);
        })['catch'](function () {
          // Show error
        })['finally'](function () {
          _this3.set('isConfirmingTask', false);
        });
      },

      /**
       * Pools the api every xxx ms to check the status of a task. Will keep pooling until the response's `isCompleted` property  is True
       * @param {string} taskConfirmProgressKey The key used to check on the task progress retrieved by onConfirmTask()
       */
      onConfirmTaskProgress: function onConfirmTaskProgress(taskConfirmProgressKey) {
        var _this4 = this;

        this._getProgressForTaskKey(taskConfirmProgressKey).then(function (progressResults) {

          if (progressResults.errorMessages) {
            var _processResponse2 = _this4._processResponse(progressResults);

            var messages = _processResponse2.messages;

            progressResults.messages = messages;
          }

          _this4.set('taskProgressResults', progressResults);
          _this4.set('step', 3);
          if (!progressResults.isCompleted) {
            var pid = _ember['default'].run.later(_this4, 'send', 'onConfirmTaskProgress', taskConfirmProgressKey, 1000);
            _this4.set('taskProgressResultsPollTask', pid);
          } else {

            try {
              _this4.get('bulkImporter').log({ type: _this4.get('importType'), step: 2, request: JSON.stringify(_this4._getPreviewParams()), response: JSON.stringify(progressResults), previewKey: _this4.get('taskPreviewResponse.key') });
            } catch (e) {
              // no-op
            }

            _this4.setProperties({
              showUnsavedChangesModal: false,
              previousTransition: null,
              taskProgressResultsPollTask: null,
              isTaskRunning: false,
              isTaskCompleted: true
            });
          }
        })['catch'](function () {
          // error handling here
        });
      },

      onClickComplete: function onClickComplete() {
        this.get('session').restoreAutoLogout();
        this.send('resetProperties');
      },

      leavePageConfirm: function leavePageConfirm() {
        if (this.get('taskProgressResultsPollTask')) {
          _ember['default'].run.cancel(this.get('taskProgressResultsPollTask'));
        }

        this.send('resetControllerProperties');
        this.get('previousTransition').retry();
      },

      leavePageCancel: function leavePageCancel() {
        this.setProperties({
          previousTransition: null,
          showUnsavedChangesModal: false
        });
      },

      goToStep: function goToStep(newStep) {
        if (newStep === 1 && this.get('step') > 1) {
          this.send('onPreviewCancel');
          this.get('session').restoreAutoLogout();
        } else if (newStep === 2 && this.get('step') > 2) {
          this.set('step', 2);
          this.get('session').restoreAutoLogout();
        }
      },

      resetProperties: function resetProperties() {

        this.setProperties({
          taskPreviewResponse: null,
          isUploaded: false,
          isUploading: false,
          uploadFile: null,
          files: [],
          step: 1,
          isConfirmingTask: false,
          isTaskRunning: false,
          isTaskCompleted: false,
          taskProgressResults: null,
          taskProgressResultsPollTask: null,
          showUnsavedChangesModal: false
        });
      }
    },

    /**
    * Gets the params for the preview endpoint by going through all the selected values in importOption array
    */
    _getPreviewParams: function _getPreviewParams() {
      var importOptions = this.get('importOptions');

      return importOptions.reduce(function (memo, option) {

        // We don't want to include file in the query params
        if (option.valueType === 'file') return memo;

        var queryParam = { key: option.key, value: option.invertValueOnQueryParam ? !option.value : option.value };

        // The toggle option type value is actually an array of size 1
        if (option.valueType === 'toggle') {
          var toggleOptions = option.options;
          var selectedId = option.value[0];
          var selectedOption = toggleOptions.findBy('id', selectedId);
          queryParam.value = selectedOption.value;
        }

        memo.pushObject(queryParam);
        return memo;
      }, []);
    },

    _processResponse: function _processResponse(payload) {
      var _this5 = this;

      var response = this.get('store').serializerFor('application')._camelizeKeys(payload);
      var errorHandler = this.get('errorHandler');
      var messages = { error: [], info: [], warning: [] };

      var infoMessages = response.informationMessages || [];
      var errorMessages = response.errorMessages || [];
      var warningMessages = response.warningMessages || [];

      infoMessages.forEach(function (infoMessagesGroup) {

        messages.info.pushObject({ group: infoMessagesGroup.groupKey, messages: [] });

        infoMessagesGroup.messages.forEach(function (infoMessage) {

          var localePath = _this5.get('localePrefix') + '.messages';
          var fallbackLocalePath = ['components.manage_resources.bulk_importer.messages', 'error_messages'];

          var msgData = _this5._getImportMessageResponseData(infoMessage);
          var msg = errorHandler.buildErrorMessage(msgData, localePath, false, fallbackLocalePath);

          var currentGroup = messages.info.get('lastObject');

          // If we are importing resources or customfields we want to group messages by their resource name as well
          if (_this5.get('importType') === 'resources' || _this5.get('importType') === 'customFields') {
            var resourceName = msgData.ExceptionMessageParams.resource;
            if (!currentGroup.messages[resourceName]) {
              currentGroup.messages[resourceName] = [];
            }
            currentGroup.messages[resourceName].push(msg);

            // Else only group them by the initial group
          } else {
              currentGroup.messages.push(msg);
            }
        });
      });

      warningMessages.forEach(function (warningMessagesGroup) {

        messages.warning.pushObject({ group: warningMessagesGroup.groupKey, messages: [] });

        warningMessagesGroup.messages.forEach(function (infoMessage) {

          var localePath = _this5.get('localePrefix') + '.messages';
          var fallbackLocalePath = ['components.manage_resources.bulk_importer.messages', 'error_messages'];

          var msgData = _this5._getImportMessageResponseData(infoMessage);
          var msg = errorHandler.buildErrorMessage(msgData, localePath, false, fallbackLocalePath);

          var currentGroup = messages.warning.get('lastObject');

          // If we are importing resources or customfields we want to group messages by their resource name as well
          if (_this5.get('importType') === 'resources' || _this5.get('importType') === 'customFields') {
            var resourceName = msgData.ExceptionMessageParams.resource;
            if (!currentGroup.messages[resourceName]) {
              currentGroup.messages[resourceName] = [];
            }
            currentGroup.messages[resourceName].push(msg);

            // Else only group them by the initial group
          } else if (_this5.get('importType') === 'bookings') {
              var doNotSubGroup = warningMessagesGroup.isTopGroup;
              var subGroupName = doNotSubGroup ? '' : msgData.ExceptionMessageParams.bookingRequestName;
              if (!currentGroup.messages[subGroupName]) {
                currentGroup.messages[subGroupName] = [];
              }
              currentGroup.messages[subGroupName].push(msg);
            } else {
              currentGroup.messages.push(msg);
            }
        });
      });

      errorMessages.forEach(function (errorMessagesGroup) {

        messages.error.pushObject({ group: errorMessagesGroup.groupKey, messages: [] });

        errorMessagesGroup.messages.forEach(function (errorMessage) {

          var localePath = _this5.get('localePrefix') + '.messages';
          var fallbackLocalePath = ['components.manage_resources.bulk_importer.messages', 'error_messages'];

          var msgData = _this5._getImportMessageResponseData(errorMessage);
          var msg = errorHandler.buildErrorMessage(msgData, localePath, false, fallbackLocalePath);

          var currentGroup = messages.error.get('lastObject');

          // If we are importing resources or customfields we want to group messages by their resource name as well
          if (_this5.get('importType') === 'resources' || _this5.get('importType') === 'customFields') {
            var resourceName = msgData.ExceptionMessageParams.resource;
            if (!currentGroup.messages[resourceName]) {
              currentGroup.messages[resourceName] = [];
            }
            currentGroup.messages[resourceName].push(msg);
          } else if (_this5.get('importType') === 'bookings') {
            var doNotSubGroup = errorMessagesGroup.isTopGroup;
            var bookingName = doNotSubGroup ? '' : msgData.ExceptionMessageParams.bookingRequestName;
            if (!currentGroup.messages[bookingName]) {
              currentGroup.messages[bookingName] = [];
            }
            currentGroup.messages[bookingName].push(msg);
          } else {
            messages.error.get('lastObject').messages.push(msg);
          }
        });
      });

      return {
        isValid: response.importValid,
        noAction: response.noAction,
        key: response.importKey,
        messages: messages,
        countTotal: response.totalObjectsAffectedByImport,
        countUpdated: response.totalObjectsUpdated,
        countCreated: response.totalObjectsCreated,
        countNoAction: response.totalObjectsNoActionTaken,
        countErrors: response.totalObjectsWithErrors,
        useCountUpdate: this.get('isCreateMode'),
        countIgnored: response.totalObjectsToBeIgnored
      };
    },

    _getImportMessageResponseData: function _getImportMessageResponseData(message) {

      var msgKey = message.messageKey.underscore();
      var msgParams = this.get('store').serializerFor('application')._camelizeKeys(message.messageParams);

      // const msgData = {key: IMPORT_MESSAGES_LOCALE_PATH + messageKey, params: msgParams.messageParams, message: message.Message};
      var msgData = { ExceptionMessageKey: msgKey, ExceptionMessageParams: msgParams, ExceptionMessage: message.message };
      return msgData;
    },

    _getProgressForTaskKey: function _getProgressForTaskKey(taskKey) {
      return this.get('bulkImporter').getTaskProgress(taskKey);
    }

  });
});