define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['externalDataSourcePreview'],
    externalDataSourceFetcher: _ember['default'].inject.service(),

    externalDataSourceUri: null,
    externalDataSourceResponse: null,

    itemType: null,

    showPreviewControl: false,

    isRadio: false,
    isDropdown: false,

    isFetchingData: false,
    externalDataError: null,

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // Hide previewControl when something changes
      this.set('showPreviewControl', false);
      this.set('externalDataError', null);
    },

    actions: {

      /**
       * Toggles the display of the preview control
       */
      togglePreviewControl: function togglePreviewControl() {
        this.toggleProperty('showPreviewControl');

        // If radio button automatically query the data.
        if (this.get('isRadio')) {
          this._searchExternalDataSource();
        }
      },

      /** Handler for when select-2 dropdown queries for data */
      externalDataSourceSearch: function externalDataSourceSearch(query, deferred) {

        // Debounce call
        _ember['default'].run.debounce(this, '_searchExternalDataSource', query, deferred, 1000);
      }
    },

    /**
     * Method that handles the search query
     * @param {*} query The query string (if any)
     * @param {*} deferred The deferred object passed by the select-2 component which will need to be resolved with
     * the data
     */
    _searchExternalDataSource: function _searchExternalDataSource(query, deferred) {
      var _this = this;

      this.set('isFetchingData', true);

      var fetchURL = this.get('externalDataSourceUri');

      var data = {
        query: query ? query.term : '',
        page: query ? query.page : 1
      };

      this.get('externalDataSourceFetcher').fetch({ url: fetchURL, modelName: this.get('itemType'), query: data.query, page: data.page }).then(function (results) {

        // Resolve promise if type is dropdown
        if (_this.get('isDropdown')) {
          deferred.resolve(results);

          // Else it should be of type Radio and so we need to store the response as to iterate over it
        } else {
            _this.set('externalDataSourceResponse', results.data);
            if (!results.data || !results.data.length) {
              _this.setProperties({
                externalDataError: _this.get('i18n').t('components.manage_resources.booking_form_field.no_data').toString()
              });
            }
          }
      })['catch'](function (e) {
        _this.setProperties({
          externalDataError: _this.get('i18n').t('errors.externalDataSourceFetchError').toString()
        });

        if (_this.get('isDropdown')) {
          deferred.reject(e);
        }
      })['finally'](function () {
        return _this.set('isFetchingData', false);
      });
    }
  });
});