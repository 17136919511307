define('scientia-resourcebooker/services/unavailability-clearer', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    clear: function clear(_ref) {
      var id = _ref.id;
      var parentId = _ref.parentId;
      var type = _ref.type;
      var unavailabilityOption = _ref.unavailabilityOption;

      var isClearDefaults = unavailabilityOption === 'all_defaults';
      var specificOptions = ['clear_nothing', 'future_specific', 'all_specific'];

      var data = {
        clearDefaults: isClearDefaults,
        clearSpecifics: isClearDefaults ? 0 : specificOptions.indexOf(unavailabilityOption)
      };

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.UNAVAILABILITY_CLEAR[type];

      if (parentId) {
        url = url.loc(parentId, id);
      } else {
        url = url.loc(id);
      }

      return this.get('authenticatedRequest').post(url, data);
    }
  });
});