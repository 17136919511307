define('scientia-resourcebooker/pods/components/manage-resources/rules-form/types/bool/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-inputList'],

    // Used as a random name attribute in the fake field type item form.
    uniqueName: (function () {
      return Math.random().toString(36).substr(5, 10);
    }).property()

  });
});