define('scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingReportListHeader-filters'],
    dateFormat: (0, _emberI18n.translationMacro)('components.date_period_selector.specific_range_date_format'),

    // Whether the show Activities toggle should be rendered
    showActivitiesToggleVisible: false,

    // Whether the show Activities toggle should be enabled
    showActivitiesToggleEnabled: false,

    timeframeSelectedLabel: _ember['default'].computed('timeframe', 'startDateTime', 'endDateTime', function () {
      var timeframe = this.get('timeframe');
      if (timeframe !== 'specific_range') return this.get('i18n').t('components.date_period_selector.' + timeframe);

      var dateFormat = this.get('i18n').t('components.date_period_selector.specific_range_date_format');
      return this.get('startDateTime').format(dateFormat) + ' - ' + this.get('endDateTime').format(dateFormat);
    })
  });
});