define('scientia-resourcebooker/pods/components/manage-resources/rules-form/types/date/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),
    locale: _ember['default'].computed.alias('i18n.locale'),

    placeholder: _ember['default'].computed('startRange', 'endRange', function () {
      var placeholder = this.get('startRange') ? 'start_date' : this.get('endRange') ? 'end_date' : '';

      return this.get('i18n').t('components.manage_resources.rules_form.fields.' + placeholder).toString();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var value = this.get('value');
      if (value) {
        if (this.get('startRange')) {
          value = moment(value).startOf('day');
        } else if (this.get('endRange')) {
          value = moment(value).startOf('day').add(1, 'd').subtract(1, 'm').toISOString();
        }
        this.set('value', value);
      }
    }

    // getMoment:function() {
    //   var date = this.get('value');
    //   var md = moment(date);
    //   return md;
    // },
    // setMoment:function(momentDate) {
    //   var md = momentDate;
    //   if (this.get('startRange')) {
    //     md = md.startOf('day');
    //   }
    //   if (this.get('endRange')) {
    //     md = md.startOf('day');
    //     md = md.add('days', 1).subtract('seconds', 1);
    //   }
    //   let formatted = md.format();
    //   this.set('value', formatted);
    // },
    // formattedValue: function() {
    //   var date = this.get('value');
    //   if (!date) {
    //     this.setMoment(moment());
    //   }
    //   return this.getMoment().format(this.get('i18n').t('components.date_input.date_format').toString());
    // }.property('value'),

    // // didReceiveAttrs() {
    // //   this.setMoment(moment().subtract(1, 'day'));$
    // // },

    // change(event) {
    //   this.setMoment(moment(Ember.$(event.currentTarget).find('input').val()));
    // }
    //
    //
    //

  });
});