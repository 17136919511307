define('scientia-resourcebooker/pods/components/week-time-pattern/component', ['exports', 'ember'], function (exports, _ember) {

  var SLOT_DURATION_IN_SECONDS = 300; // 5 minutes
  var ONE_HOUR_IN_SECONDS = 3600;

  var ZOOM_LEVELS_IN_PX = {
    '-2': 20,
    '-1': 30,
    '0': 40,
    '1': 120,
    '2': 240,
    '3': 360,
    '4': 420
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['chronos-weekView-event at-weekTimePattern'],
    model: null,
    zoomLevel: 0,

    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        this.rerenderItem();
      });
    }).on('init'),

    rerenderItem: function rerenderItem() {
      var model = this.get('model');
      var fromTime = model.fromTime;
      var toTime = model.toTime;

      var secondDiff = toTime.asSeconds() - fromTime.asSeconds();
      var span = Math.round(secondDiff / SLOT_DURATION_IN_SECONDS * 10) / 10;

      // let hourSlotHeight = Ember.$(`.${this.get('slotClassName')}`).outerHeight() * this.get('zoomLevel');
      var hourSlotHeight = ZOOM_LEVELS_IN_PX[this.get('zoomLevel')];

      var top = model.fromTime.asHours() * hourSlotHeight;

      var topOffset = 0;
      var bottomOffset = 24 * hourSlotHeight;
      var maxHeight = bottomOffset - topOffset - top;
      var height = Math.min(span * hourSlotHeight / (ONE_HOUR_IN_SECONDS / SLOT_DURATION_IN_SECONDS), maxHeight);

      this.$().css({ height: height, top: top });

      this.$().addClass(model.isPendingAdd ? 'chronos-event--pendingAdd' : '');
      this.$().addClass(model.isPendingRemove ? 'chronos-event--pendingRemove' : '');

      // If 15min slot or less
      if (height <= 10) {
        this.$().addClass('chronos-weekView-event--tiny');
      }
    },

    zoomLevelChanged: (function () {
      _ember['default'].run.next(this, 'rerenderItem');
    }).observes('zoomLevel'),

    click: function click() {
      var model = this.get('model');

      if (model.isPendingAdd) return this.get('undoAddSlot')(model);
      if (model.isPendingRemove) return this.get('undoRemoveSlot')(model);

      return this.get('showRemoveSlot')(model);
    }
  });
});