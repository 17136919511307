define('scientia-resourcebooker/adapters/application', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _emberData, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    session: _ember['default'].inject.service(),

    host: _scientiaResourcebookerConfigEnvironment['default'].API.HOST,
    namespace: _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE,

    headers: (function () {
      return {
        Authorization: 'Bearer ' + this.get('session.authorization.access_token')
      };
    }).property(),

    pathForType: function pathForType(type) {
      return type.classify().pluralize();
    },

    ajaxOptions: function ajaxOptions(url, type, options) {
      options = this._super(url, type, options);
      delete options.dataType;
      return options;
    },

    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 401) {
        return this.ajaxError(status);
      }

      if (status === 400) {
        if (typeof payload === 'string') {
          payload = { ExceptionMessage: payload };
        }
        return payload;
      }

      return this._super.apply(this, arguments);
    },

    shouldReloadAll: function shouldReloadAll(store, snapshot) {
      var shouldReloadAll = !store.peekAll(snapshot.type.modelName).get('length');
      return shouldReloadAll;
    },

    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() /*store, snapshot*/{
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return true;
    },

    ajaxError: function ajaxError(status) {

      if (status === 401) {
        var loggedInAt = this.get('session.loggedInAt');

        if (moment().diff(loggedInAt, 'seconds') > 30) {
          this.get('session').timeout();
        } else {
          this.get('session').saveRedirectURL();
          this.get('session').logout();
        }
      } else {
        _ember['default'].RSVP.reject(status);
      }
    }
  });
});