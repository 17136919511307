define('scientia-resourcebooker/services/resource-unavailability-patterns-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var COPY_MODES = { ADD: 0, REPLACE: 1 };

  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;

      var url = this._getUrl(type, id, parentId, false, true);
      return this.get('authenticatedRequest').fetch(url);
    },

    update: function update(_ref2) {
      var type = _ref2.type;
      var id = _ref2.id;
      var parentId = _ref2.parentId;
      var patterns = _ref2.patterns;

      var data = {
        availabilityPatternIds: []
      };

      data.availabilityPatternIds = patterns.filter(function (p) {
        return !_ember['default'].get(p, 'isPendingRemove');
      }).map(function (v) {
        return _ember['default'].get(v, 'id');
      });

      var url = this._getUrl(type, id, parentId);

      return this.get('authenticatedRequest').put(url, data);
    },

    copy: function copy(_ref3) {
      var type = _ref3.type;
      var id = _ref3.id;
      var parentId = _ref3.parentId;
      var pattern = _ref3.pattern;
      var replaceAll = _ref3.replaceAll;

      var data = {
        availabilityPatternIds: [_ember['default'].get(pattern, 'id')],
        copyFunction: replaceAll ? COPY_MODES.REPLACE : COPY_MODES.ADD

      };

      var url = this._getUrl(type, id, parentId, true);
      return this.get('authenticatedRequest').put(url, data);
    },

    clonePatternData: function clonePatternData(patterns) {
      return patterns.map(function (p) {
        return _ember['default'].Object.create(JSON.parse(JSON.stringify(p)));
      });
    },

    _getUrl: function _getUrl(type, id, parentId, isCopyMode, isFetchMode) {
      var baseUrl = undefined;

      if (isCopyMode) {
        baseUrl = _scientiaResourcebookerConfigEnvironment['default'].API.UNAVAILABILITY_PATTERNS_COPIER[type];
      } else if (isFetchMode) {
        baseUrl = _scientiaResourcebookerConfigEnvironment['default'].API.LINKED_UNAVAILABILITY_PATTERNS_FETCHER[type];
      } else {
        baseUrl = _scientiaResourcebookerConfigEnvironment['default'].API.UNAVAILABILITY_PATTERNS_LINKER[type];
      }

      var url = undefined;

      if (parentId) {
        url = baseUrl.loc(parentId, id);
      } else {
        url = baseUrl.loc(id);
      }

      return url;
    }
  });
});