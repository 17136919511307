define('scientia-resourcebooker/pods/components/help-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['helpModal'],
    i18n: _ember['default'].inject.service(),
    locale: _ember['default'].computed.alias('i18n.locale'),
    appInsights: _ember['default'].inject.service(),

    isAdminRoute: _ember['default'].computed('model.path', function () {
      return this.get('model.path') && this.get('model.path').indexOf('manage-resources') === 0;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('model')) return;

      var locale = this.get('locale');
      var localesData = this.get('model.lang');

      if (localesData && localesData[locale] && localesData[locale].length === 1) {
        this.send('onHelpClick', localesData[locale][0]);
        _ember['default'].run.next(this, 'sendAction', 'close');
      } else {
        var topicsData = this.get('model.topics');
        if (topicsData && topicsData.length === 1) {
          this.send('onHelpClick', topicsData[0]);
          _ember['default'].run.next(this, 'sendAction', 'close');
        }
      }
    },

    actions: {

      onHelpClick: function onHelpClick(topic) {
        var hasHelpDataInCurrentLocale = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var url = topic.url;

        if (url.indexOf('http') !== 0) {
          url = this.get('model.prefix') + topic.url;
        }
        window.open(url, '_blank');
        this.sendAction('trackHelp', topic, hasHelpDataInCurrentLocale);
      },

      onHomepageClick: function onHomepageClick() {
        var url = this.get('model.home');
        window.open(url, '_blank');
        this.get('appInsights').trackEvent('global-help-homepage-click', { path: this.get('model.path') });
      },

      trackHelp: function trackHelp(topic) {
        var hasHelpDataInCurrentLocale = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        this.get('appInsights').trackEvent('global-help-topic-click', { path: this.get('model.path'), topic: topic.text, locale: this.get('locale'), hasHelpDataInCurrentLocale: hasHelpDataInCurrentLocale });
      }
    }
  });
});