define('scientia-resourcebooker/services/booking-request-destroyer', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    destroyRequest: function destroyRequest(bookingRequest, cancelType, note) {

      var bookingId = bookingRequest.get('id');
      var data = note ? { note: note } : null;

      // CancelType 1 means user is trying to delete a single occurrence from a recurring Booking
      if (cancelType && cancelType === 1) {
        var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.CANCEL_OCCURRENCE.loc(bookingRequest.get('occurrenceGroupId'), bookingId);

        var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
        return this.get('authenticatedRequest').externalPost(url, data);

        // Else the user is deleting a normal booking or all its occurrences
      } else {
          var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.CANCEL_BOOKING.loc(bookingId);
          var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
          return this.get('authenticatedRequest').externalPost(url, data);
        }
    }
  });
});