define("scientia-resourcebooker/pods/manage-resources/resource-group/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 24,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["inline", "section-title-actions/item", [], ["icon", "icons/tag.svg", "text", ["subexpr", "t", ["pods.manage_resources.resource_group.edit.view_resources"], [], ["loc", [null, [12, 15], [12, 77]]], 0, 0], "routeTarget", "manage-resources.resource-group.resources", "routeModel", ["subexpr", "array-from", [["get", "model.resourceType.id", ["loc", [null, [14, 33], [14, 54]]], 0, 0, 0, 0], ["get", "model.resourceGroup.id", ["loc", [null, [14, 55], [14, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 21], [14, 78]]], 0, 0]], ["loc", [null, [10, 10], [14, 80]]], 0, 0], ["inline", "section-title-actions/item", [], ["type", "danger", "disabled", ["subexpr", "not", [["subexpr", "can", ["delete resource-group"], ["model", ["get", "model.resourceGroup", ["loc", [null, [19, 59], [19, 78]]], 0, 0, 0, 0]], ["loc", [null, [19, 24], [19, 79]]], 0, 0]], [], ["loc", [null, [19, 19], [19, 80]]], 0, 0], "icon", "icons/trash.svg", "text", ["subexpr", "t", ["pods.manage_resources.resource_group.edit.delete_group"], [], ["loc", [null, [21, 15], [21, 75]]], 0, 0], "action", ["subexpr", "action", ["deleteModel"], [], ["loc", [null, [22, 17], [22, 39]]], 0, 0]], ["loc", [null, [17, 10], [22, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [7, 6], [24, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 10
                },
                "end": {
                  "line": 34,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [33, 12], [33, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource.edit.description"], [], ["loc", [null, [33, 73], [33, 128]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 10
                },
                "end": {
                  "line": 37,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [36, 12], [36, 68]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_group.edit.resources"], [], ["loc", [null, [36, 68], [36, 127]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 10
                },
                "end": {
                  "line": 40,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [39, 12], [39, 70]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource.edit.permissions"], [], ["loc", [null, [39, 70], [39, 125]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 41,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditDescription", ["loc", [null, [32, 31], [32, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 26], [32, 50]]], 0, 0]], 0, null, ["loc", [null, [32, 10], [34, 21]]]], ["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditResources", ["loc", [null, [35, 31], [35, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 26], [35, 48]]], 0, 0]], 1, null, ["loc", [null, [35, 10], [37, 21]]]], ["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [38, 31], [38, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 26], [38, 50]]], 0, 0]], 2, null, ["loc", [null, [38, 10], [40, 21]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/resource-form", [], ["localePrefix", "components.manage_resources.resource_group_edit", "resource", ["subexpr", "@mut", [["get", "model.resourceGroup", ["loc", [null, [46, 21], [46, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditDescription", ["loc", [null, [47, 26], [47, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 21], [47, 45]]], 0, 0]], ["loc", [null, [44, 10], [48, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 8
              },
              "end": {
                "line": 55,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/resource-group-resources-manager", [], ["resourceGroup", ["subexpr", "@mut", [["get", "model.resourceGroup", ["loc", [null, [53, 24], [53, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditResources", ["loc", [null, [54, 24], [54, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 19], [54, 41]]], 0, 0]], ["loc", [null, [52, 8], [54, 43]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 11
                },
                "end": {
                  "line": 64,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle at-permissionsForm manageResources-itemForm-subtitle--disabledTab");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [61, 14], [61, 93]]], 0, 0], ["inline", "t", ["pods.manage_resources.tab_no_permission"], [], ["loc", [null, [62, 20], [62, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 8
              },
              "end": {
                "line": 70,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1, "class", "manageResources-itemForm-title at-permissionsForm");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["pods.manage_resources.resource_group.edit.permissions_title"], ["name", ["subexpr", "@mut", [["get", "model.resourceGroup.name", ["loc", [null, [58, 143], [58, 167]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [58, 72], [58, 169]]], 0, 0], ["block", "if", [["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [59, 22], [59, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 17], [59, 41]]], 0, 0]], [], 0, null, ["loc", [null, [59, 11], [64, 17]]]], ["inline", "manage-resources/permissions-form", [], ["permissions", ["subexpr", "@mut", [["get", "permissions", ["loc", [null, [66, 22], [66, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "permissionsTypes", ["subexpr", "@mut", [["get", "permissionsTypes", ["loc", [null, [67, 27], [67, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "labelOverrides", ["subexpr", "@mut", [["get", "permissionsTypesLabelOverrides", ["loc", [null, [68, 25], [68, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [69, 24], [69, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 19], [69, 43]]], 0, 0]], ["loc", [null, [65, 10], [69, 45]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 6
            },
            "end": {
              "line": 71,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [31, 8], [41, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [43, 8], [49, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-resourcesForm"], 2, null, ["loc", [null, [51, 8], [55, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-permissionsForm"], 3, null, ["loc", [null, [57, 8], [70, 25]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n     ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createAttrMorph(element2, 'aria-label');
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createMorphAt(element3, 3, 3);
        morphs[5] = dom.createMorphAt(element3, 5, 5);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [4, 90], [4, 118]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.resource_group.edit.title"], ["name", ["get", "model.resourceGroup.name", ["loc", [null, [5, 84], [5, 108]]], 0, 0, 0, 0]], ["loc", [null, [5, 26], [5, 109]]], 0, 0]], 0, null, ["loc", [null, [5, 4], [25, 22]]]], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [28, 24], [28, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 6], [28, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [30, 59], [30, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [30, 6], [71, 18]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [73, 48], [73, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["onCancel"], [], ["loc", [null, [73, 70], [73, 89]]], 0, 0], "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [73, 97], [73, 112]]], 0, 0]], ["loc", [null, [73, 5], [73, 114]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});