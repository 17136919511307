define("scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [27, 18], [27, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [28, 16], [28, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.fromTime", ["loc", [null, [29, 30], [29, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 25], [29, 45]]], 0, 0]], [], ["loc", [null, [29, 17], [29, 46]]], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [30, 24], [30, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.fromTime", ["loc", [null, [31, 14], [31, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-startDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [26, 8], [32, 91]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 6
              },
              "end": {
                "line": 44,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input-select", [], ["data-focus-id", "bookingStartTime", "class", "bookingRequest-startTime", "ariaLabel", ["subexpr", "t", ["components.booking_request_form.booking_start_time"], [], ["loc", [null, [37, 18], [37, 74]]], 0, 0], "content", ["subexpr", "@mut", [["get", "contentFromTime", ["loc", [null, [38, 16], [38, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [39, 17], [39, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.fromTime", ["loc", [null, [40, 24], [40, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 14], [40, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateFromTime"], [], ["loc", [null, [42, 17], [42, 43]]], 0, 0]], ["loc", [null, [34, 8], [43, 10]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 8
                },
                "end": {
                  "line": 66,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [60, 20], [60, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [61, 18], [61, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [62, 16], [62, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [63, 26], [63, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [64, 32], [64, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 27], [64, 45]]], 0, 0]], [], ["loc", [null, [64, 19], [64, 46]]], 0, 0], "class", "at-endDate--limited bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [59, 10], [65, 100]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 8
                },
                "end": {
                  "line": 76,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["class", "bookingRequest-endTime", "data-focus-id", "bookingEndTime", "ariaLabel", ["subexpr", "t", ["components.booking_request_form.booking_end_time"], [], ["loc", [null, [70, 20], [70, 74]]], 0, 0], "content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [71, 18], [71, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [72, 26], [72, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [72, 16], [72, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [74, 19], [74, 42]]], 0, 0]], ["loc", [null, [67, 10], [75, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 6
              },
              "end": {
                "line": 78,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-input bookingRequestForm-input");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "disabled", "true");
            dom.setAttribute(el2, "class", "form-input");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'aria-label');
            morphs[1] = dom.createAttrMorph(element2, 'value');
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.to"], [], ["loc", [null, [54, 27], [54, 69]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["subexpr", "moment-format", [["get", "model.startDateTime", ["loc", [null, [54, 93], [54, 112]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [54, 119], [54, 147]]], 0, 0]], [], ["loc", [null, [54, 113], [54, 148]]], 0, 0]], [], ["loc", [null, [null, null], [54, 150]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [58, 14], [58, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [58, 8], [76, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 8
                },
                "end": {
                  "line": 108,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [101, 26], [101, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [102, 20], [102, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [103, 18], [103, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [104, 16], [104, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [105, 32], [105, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [105, 27], [105, 45]]], 0, 0]], [], ["loc", [null, [105, 19], [105, 46]]], 0, 0], "class", "at-endDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [100, 10], [106, 91]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 8
                },
                "end": {
                  "line": 118,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["data-focus-id", "bookingEndTime", "class", "bookingRequest-endTime", "ariaLabel", ["subexpr", "t", ["components.booking_request_form.booking_end_time"], [], ["loc", [null, [112, 20], [112, 74]]], 0, 0], "content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [113, 18], [113, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [114, 26], [114, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [114, 16], [114, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [116, 19], [116, 42]]], 0, 0]], ["loc", [null, [109, 10], [117, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 6
              },
              "end": {
                "line": 120,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-input date-input");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "sr-only");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [2]);
            var element1 = dom.childAt(element0, [2]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createMorphAt(element0, 4, 4);
            morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            return morphs;
          },
          statements: [["element", "action", ["focusOnEndTime"], [], ["loc", [null, [83, 32], [83, 59]]], 0, 0], ["inline", "t", ["components.booking_request_form.skip_to_end_time"], [], ["loc", [null, [83, 60], [83, 116]]], 0, 0], ["inline", "bs-datetimepicker", [], ["data-focus-id", "bookingEndDate", "date", ["subexpr", "@mut", [["get", "model.endDateTime", ["loc", [null, [86, 13], [86, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "ariaLabel", ["subexpr", "t", ["components.booking_request_form.booking_end_date"], [], ["loc", [null, [88, 18], [88, 72]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [89, 17], [89, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [90, 21], [90, 49]]], 0, 0]], [], ["loc", [null, [90, 15], [90, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateEndDateDebounce"], [], ["loc", [null, [91, 19], [91, 51]]], 0, 0], "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [92, 27], [92, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [84, 8], [95, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [99, 14], [99, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [99, 8], [118, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 123,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "bookingRequestForm-input date-input");
          dom.setAttribute(el3, "data-focus-id", "bookingStartDate");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "sr-only");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element4, [2]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(fragment, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(element6, 0, 0);
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          morphs[4] = dom.createMorphAt(element4, 4, 4);
          morphs[5] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_form.from"], [], ["loc", [null, [4, 83], [4, 127]]], 0, 0], ["element", "action", ["focusOnStartTime"], [], ["loc", [null, [10, 32], [10, 61]]], 0, 0], ["inline", "t", ["components.booking_request_form.skip_to_start_time"], [], ["loc", [null, [10, 62], [10, 120]]], 0, 0], ["inline", "bs-datetimepicker", [], ["ariaLabel", ["subexpr", "t", ["components.booking_request_form.booking_start_date"], [], ["loc", [null, [13, 18], [13, 74]]], 0, 0], "date", ["subexpr", "@mut", [["get", "model.startDateTime", ["loc", [null, [14, 13], [14, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [16, 21], [16, 49]]], 0, 0]], [], ["loc", [null, [16, 15], [16, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateStartDateDebounce"], [], ["loc", [null, [17, 19], [17, 53]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "canEditEndDateTimeOnly", ["loc", [null, [18, 17], [18, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [19, 27], [19, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [12, 8], [22, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [25, 12], [25, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [25, 6], [44, 13]]]], ["inline", "t", ["components.booking_request_form.to"], [], ["loc", [null, [49, 83], [49, 125]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [52, 16], [52, 57]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "calendarOptions.bookingSpanMultipleDays", ["loc", [null, [52, 63], [52, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 58], [52, 103]]], 0, 0]], [], ["loc", [null, [52, 12], [52, 104]]], 0, 0]], [], 2, 3, ["loc", [null, [52, 6], [120, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 123,
              "column": 0
            },
            "end": {
              "line": 125,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["size", "medium", "align", "center"], ["loc", [null, [124, 2], [124, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 129,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "role", "region");
        dom.setAttribute(el1, "tabindex", "0");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(fragment, [2]);
        var element10 = dom.childAt(fragment, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element8, 'aria-label');
        morphs[1] = dom.createAttrMorph(element8, 'class');
        morphs[2] = dom.createMorphAt(element8, 1, 1);
        morphs[3] = dom.createMorphAt(element9, 0, 0);
        morphs[4] = dom.createMorphAt(element9, 2, 2);
        morphs[5] = dom.createMorphAt(element10, 0, 0);
        morphs[6] = dom.createMorphAt(element10, 2, 2);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.booking_date_and_time"], [], ["loc", [null, [1, 44], [1, 105]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["bookingRequestForm-fields--datetime-contentWrapper ", ["subexpr", "if", [["get", "isLoading", ["loc", [null, [1, 170], [1, 179]]], 0, 0, 0, 0], "bookingRequestForm-fields--datetime-contentWrapper--loading", ""], [], ["loc", [null, [1, 165], [1, 246]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "isLoading", ["loc", [null, [2, 12], [2, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [125, 11]]]], ["inline", "moment-format", [["get", "model.startDateTime", ["loc", [null, [127, 60], [127, 79]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [127, 44], [127, 100]]], 0, 0], ["content", "model.fromTime", ["loc", [null, [127, 104], [127, 122]]], 0, 0, 0, 0], ["inline", "moment-format", [["get", "model.endDateTime", ["loc", [null, [128, 60], [128, 77]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [128, 44], [128, 98]]], 0, 0], ["content", "model.toTime", ["loc", [null, [128, 102], [128, 118]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});