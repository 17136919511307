define('scientia-resourcebooker/serializers/resource-type', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {

  var EDIT_PERMISSION_GROUPS = ['all', 'description', 'permissions', 'customFields'];

  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      properties: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, hash) {

      var applicationSerializer = this.store.serializerFor('application');
      var payload = this.store.serializerFor('application')._camelizeKeysDeep(hash);

      payload = applicationSerializer._setPermissionGroupDefaults(payload);
      payload = applicationSerializer._normalizeNestedPermissionGroups(payload, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');

      if (payload.Properties && payload.Properties.length) payload.Properties = payload.Properties.reject(function (property) {
        return property.IsObsolete;
      });
      return this._super(modelClass, payload);
    },

    serialize: function serialize() /*snapshot*/{
      var serialized = this._super.apply(this, arguments);
      var applicationSerializer = this.store.serializerFor('application');
      serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');
      return serialized;
    }

  });
});