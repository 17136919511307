define('scientia-resourcebooker/validators/unique-item-value', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var UniqueItemValue = _emberCpValidationsValidatorsBase['default'].extend({
    i18n: _ember['default'].inject.service(),
    validate: function validate(value, options, model /*, attribute*/) {
      if (model.get('items.length') !== model.get('items').uniqBy('value').get('length')) {
        return this.get('i18n').t('errors.uniqueItemValue').toString();
      }

      return true;
    }
  });

  UniqueItemValue.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = UniqueItemValue;
});