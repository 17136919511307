define('scientia-resourcebooker/serializers/request-permission-group', ['exports', 'ember-data/serializers/json'], function (exports, _emberDataSerializersJson) {
  exports['default'] = _emberDataSerializersJson['default'].extend({

    normalize: function normalize(type, payload, prop) {

      var newPayload = this.store.serializerFor('application')._camelizeKeysDeep(payload);

      try {
        var targetIsClaimMode = !!payload.permissionTarget.permissionClaim.claimType;
        var approversIsClaimMode = !!payload.approvers[0].permissionClaim.claimType;

        if (targetIsClaimMode) {
          newPayload.permissionTarget = {
            isClaimMode: true,
            claimType: newPayload.permissionTarget.permissionClaim.claimType.toLowerCase(),
            claimValue: newPayload.permissionTarget.permissionClaim.claimValue
          };
        } else {
          newPayload.permissionTarget.id = newPayload.permissionTarget.identity;
        }

        if (approversIsClaimMode) {
          newPayload.approvers = {
            isClaimMode: true,
            claimType: newPayload.approvers[0].permissionClaim.claimType.toLowerCase(),
            claimValue: newPayload.approvers[0].permissionClaim.claimValue
          };
        } else {
          newPayload.approvers.forEach(function (a) {
            a.id = a.identity || a.id;
          });
        }
      } catch (error) {
        //no-op
      }

      var results = this._super(type, newPayload, prop);
      return results;
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var targetIsClaimMode = json.permissionTarget.isClaimMode;
      var approversIsClaimMode = json.approvers.isClaimMode;

      if (targetIsClaimMode) {
        json.permissionTarget = { permissionClaim: json.permissionTarget };
        delete json.permissionTarget.permissionClaim.isClaimMode;
      }

      if (approversIsClaimMode) {
        json.approvers = [{ permissionClaim: json.approvers }];
        delete json.approvers[0].permissionClaim.isClaimMode;
      }
      return json;
    }
  });
});
// Serializers for fragments work just as with models