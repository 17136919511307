define("scientia-resourcebooker/pods/application/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "waitingBar");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["logout-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [14, 25], [14, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 20], [14, 39]]], 0, 0], false], [], ["loc", [null, [14, 12], [14, 46]]], 0, 0], "singleLogoutMode", ["subexpr", "@mut", [["get", "singleLogoutMode", ["loc", [null, [15, 23], [15, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "singleLogoutCheckboxValue", ["subexpr", "@mut", [["get", "singleLogoutCheckboxValue", ["loc", [null, [16, 32], [16, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["confirmLogout"], [], ["loc", [null, [17, 14], [17, 38]]], 0, 0]], ["loc", [null, [12, 11], [17, 39]]], 0, 0], "dialogDescription", ["subexpr", "t", ["components.logout_modal.description"], [], ["loc", [null, [18, 22], [18, 63]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [19, 32], [19, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 27], [19, 46]]], 0, 0], false], [], ["loc", [null, [19, 19], [19, 53]]], 0, 0]], ["loc", [null, [11, 9], [19, 54]]], 0, 0]], ["loc", [null, [9, 1], [20, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["location", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.location", ["loc", [null, [30, 15], [30, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "showGlobalConfirmModal", ["loc", [null, [31, 12], [31, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "singleButtonMode", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.singleButtonMode", ["loc", [null, [32, 23], [32, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "confirmTitle", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.title", ["loc", [null, [33, 19], [33, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "headerIcon", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.headerIcon", ["loc", [null, [34, 17], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "headerIconClass", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.headerIconClass", ["loc", [null, [35, 22], [35, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "confirmMessage", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.message", ["loc", [null, [36, 21], [36, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 11], [36, 52]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showGlobalConfirmModal", ["loc", [null, [37, 32], [37, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 27], [37, 55]]], 0, 0], null], [], ["loc", [null, [37, 19], [37, 61]]], 0, 0]], ["loc", [null, [27, 9], [37, 62]]], 0, 0]], ["loc", [null, [25, 0], [38, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["help-modal"], ["model", ["subexpr", "@mut", [["get", "helpDataForPage", ["loc", [null, [47, 14], [47, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [48, 27], [48, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 22], [48, 41]]], 0, 0], false], [], ["loc", [null, [48, 14], [48, 48]]], 0, 0]], ["loc", [null, [45, 11], [48, 49]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [49, 34], [49, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 29], [49, 48]]], 0, 0], false], [], ["loc", [null, [49, 21], [49, 55]]], 0, 0]], ["loc", [null, [44, 9], [49, 56]]], 0, 0]], ["loc", [null, [42, 2], [50, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 0
            },
            "end": {
              "line": 64,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["download-modal"], ["model", ["subexpr", "@mut", [["get", "downloadFile", ["loc", [null, [59, 12], [59, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", ["closeDownloadFileModal"], [], ["loc", [null, [60, 12], [60, 45]]], 0, 0], "confirm", ["subexpr", "action", ["closeDownloadFileModal"], [], ["loc", [null, [61, 14], [61, 47]]], 0, 0]], ["loc", [null, [57, 11], [61, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [62, 37], [62, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 27], [62, 42]]], 0, 0]], [], ["loc", [null, [62, 19], [62, 43]]], 0, 0]], ["loc", [null, [56, 9], [62, 44]]], 0, 0]], ["loc", [null, [54, 1], [63, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 66,
              "column": 0
            },
            "end": {
              "line": 78,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/task-modal"], ["progressKey", ["subexpr", "@mut", [["get", "taskKey", ["loc", [null, [72, 18], [72, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onGetProgressFailed", ["subexpr", "action", ["onProgressFailed"], [], ["loc", [null, [73, 26], [73, 53]]], 0, 0], "onCancel", ["subexpr", "action", ["onProgressCancel"], [], ["loc", [null, [74, 15], [74, 42]]], 0, 0], "onComplete", ["subexpr", "action", ["onProgressComplete"], [], ["loc", [null, [75, 17], [75, 46]]], 0, 0]], ["loc", [null, [70, 11], [75, 47]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [76, 39], [76, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [76, 29], [76, 44]]], 0, 0]], [], ["loc", [null, [76, 21], [76, 45]]], 0, 0]], ["loc", [null, [69, 9], [76, 46]]], 0, 0]], ["loc", [null, [67, 1], [77, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "custom-flash-message", [], ["flash", ["subexpr", "@mut", [["get", "flash", ["loc", [null, [82, 31], [82, 36]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [82, 2], [82, 38]]], 0, 0]],
        locals: ["flash"],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 0
            },
            "end": {
              "line": 87,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "timeout-overlay");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 89,
              "column": 0
            },
            "end": {
              "line": 91,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "timeout-overlay");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 94,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "aria-hidden", "true");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'data-route-name');
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 14, 14, contextualElement);
        morphs[8] = dom.createMorphAt(fragment, 16, 16, contextualElement);
        morphs[9] = dom.createMorphAt(fragment, 17, 17, contextualElement);
        morphs[10] = dom.createMorphAt(fragment, 19, 19, contextualElement);
        morphs[11] = dom.createMorphAt(fragment, 21, 21, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "modal-target", ["loc", [null, [1, 0], [1, 16]]], 0, 0, 0, 0], ["attribute", "data-route-name", ["concat", [["get", "router.currentRouteName", ["loc", [null, [2, 44], [2, 67]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "loading", ["loc", [null, [4, 6], [4, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 0], [6, 7]]]], ["block", "if", [["get", "confirmLogout", ["loc", [null, [8, 6], [8, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [8, 0], [21, 7]]]], ["block", "if", [["get", "showGlobalConfirmModal", ["loc", [null, [23, 6], [23, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [23, 0], [39, 7]]]], ["block", "if", [["get", "showHelpModal", ["loc", [null, [41, 6], [41, 19]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [41, 0], [51, 7]]]], ["block", "if", [["get", "downloadFile", ["loc", [null, [53, 6], [53, 18]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [53, 0], [64, 7]]]], ["block", "if", [["get", "taskKey", ["loc", [null, [66, 6], [66, 13]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [66, 0], [78, 7]]]], ["block", "each", [["get", "flashMessages.queue", ["loc", [null, [81, 8], [81, 27]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [81, 0], [83, 9]]]], ["block", "if", [["get", "isSessionTimedout", ["loc", [null, [85, 6], [85, 23]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [85, 0], [87, 7]]]], ["block", "if", [["get", "isNetworkOffline", ["loc", [null, [89, 6], [89, 22]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [89, 0], [91, 7]]]], ["content", "liquid-outlet", ["loc", [null, [93, 0], [93, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});