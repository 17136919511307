define('scientia-resourcebooker/serializers/booking-form-field', ['exports', 'ember-data/serializers/json', 'scientia-resourcebooker/helpers/booking-form-field-type-to-name', 'ember-data'], function (exports, _emberDataSerializersJson, _scientiaResourcebookerHelpersBookingFormFieldTypeToName, _emberData) {
  exports['default'] = _emberDataSerializersJson['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      externalResourceType: { embedded: 'always' }
    },

    normalize: function normalize(type, payload, prop) {
      // this._super(...arguments);

      var newPayload = this.store.serializerFor('application')._camelizeKeys(payload);

      // if (newPayload.externalResourceType) {
      //   newPayload.externalResourceType.id = newPayload.externalResourceType.identity;
      // }
      if (newPayload.fieldType !== 2 && newPayload.values) {
        newPayload.value = newPayload.value[0];
      } else if (newPayload.fiedType === 2 && newPayload.values) {
        newPayload.value = newPayload.values;
      }

      var fieldTypeName = 'booking-form-field-' + (0, _scientiaResourcebookerHelpersBookingFormFieldTypeToName.bookingFormFieldTypeToName)(newPayload.fieldType);
      type = this.store.modelFactoryFor(fieldTypeName);

      newPayload.fieldTypeName = fieldTypeName;
      var results = this._super(type, newPayload, prop);
      return results;
    },

    // https://github.com/lytics/ember-data-model-fragments#polymorphism
    // Serializing the fragment type back to JSON is not currently supported out of the box.
    // To serialize the polymorphic type, create a custom serializer to perform manual introspection:
    serialize: function serialize() /*snapshot, options*/{

      return this._super.apply(this, arguments);
    }
  });
});