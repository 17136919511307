define('scientia-resourcebooker/pods/components/recurring-tabs/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recurringTabs'],
    classNameBindings: ['location'],
    attributeBindings: ['ariaLabel', 'tabindex', 'role'],
    ariaLabel: (0, _emberI18n.translationMacro)('components.resource_filters.booking_mode'),
    role: 'menu',
    selected: 1,
    tabindex: 0
  });
});