define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'label',
    classNames: ['manageResources-itemForm-form-item'],

    choice: null,
    checked: false,
    boundChecked: _ember['default'].computed({
      get: function get() {
        return this.get('checked');
      },

      set: function set(key, value) {
        this.toggleProperty('checked');

        var action = this.get('checked') ? 'onChecked' : 'onUnchecked';
        this.sendAction(action, this.get('choice'));

        return value;
      }
    })
  });
});