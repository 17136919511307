define('scientia-resourcebooker/services/permission-deprecated-checker', ['exports', 'ember'], function (exports, _ember) {

  var DEPRECATED_PERMISSIONS = ['resource-type|read', 'resource-type|write', 'resource-group|read', 'resource-group|write', 'resource|read', 'resource|write', 'resource|approve'];

  exports['default'] = _ember['default'].Service.extend({

    isDeprecated: function isDeprecated(_ref) {
      var type = _ref.type;
      var name = _ref.name;

      return DEPRECATED_PERMISSIONS.indexOf(type + '|' + name) !== -1;
    }

  });
});