define('scientia-resourcebooker/abilities/concurrency-group', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    canList: (function () {
      return this.get('currentUser').isConcurrencyManager();
    }).property(),

    canCreate: (function () {
      return this.get('currentUser').isConcurrencyManager();
    }).property(),

    canViewCalendar: _ember['default'].computed.alias('model.canViewCalendar'),
    canShowCalendarIcon: _ember['default'].computed(function () {
      return !this.get('systemSettings').getSetting('betaConcurrencyCombinedCalendarsDisabled');
    }),
    canEdit: _ember['default'].computed.alias('model.canEdit'),
    canDelete: _ember['default'].computed.alias('model.canDelete')

  });
});