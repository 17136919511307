define('scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/component', ['exports', 'ember'], function (exports, _ember) {
  var ITEM_TYPE = 'booking-form-field-multiple-choice-item';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestForm-field bookingRequestForm-field-multipleChoice'],

    attributeBindings: ['fieldId:data-field-id'],
    fieldId: _ember['default'].computed.alias('model.identity'),

    authenticatedRequest: _ember['default'].inject.service(),
    externalDataSourceFetcher: _ember['default'].inject.service(),

    externalData: [],
    externalDataError: null,
    isUsingExternalDataContent: _ember['default'].computed.and('model.externalDataSourceUri', 'model.isUsingExternalDataSource'),

    content: _ember['default'].computed('model.items.length', 'isUsingExternalDataContent', function () {
      return this.get('isUsingExternalDataContent') ? [] : this.get('model.items');
    }),

    selectedValues: _ember['default'].computed('model.value.@each.value', function () {
      if (_ember['default'].isEmpty(this.get('model.value'))) return [];

      return this.get('model.value').map(function (i) {
        return _ember['default'].get(i, 'value');
      });
    }),

    disabled: false,

    showValidations: false,
    shouldDisplayValidations: _ember['default'].computed.or('showValidations', 'didValidate').readOnly(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].run.schedule('afterRender', this, function () {

        if (!this.get('model.value')) this.set('model.value', []);

        if (this.get('isUsingExternalDataContent')) {
          this._fetchExternalDataContent();
        } else {
          this._removeInvalidValues();
          this._setDefaultValue();
        }
      });
    },

    actions: {

      toggleSelection: function toggleSelection(item) {
        var selectedItems = this.get('model.value');
        var itemExistsInSelection = selectedItems.findBy('value', item.get('value'));
        if (itemExistsInSelection) {
          selectedItems.removeObject(itemExistsInSelection);
        } else {
          selectedItems.pushObject(item);
        }

        this.set('showValidations', true);
      },

      externalDataSourceSearch: function externalDataSourceSearch(query, deferred) {
        _ember['default'].run.debounce(this, '_searchExternalDataSource', query, deferred, 1000);
      }
    },

    _fetchExternalDataContent: function _fetchExternalDataContent() {
      var _this = this;

      var fetchURL = this.get('model.externalDataSourceUri');
      this.get('externalDataSourceFetcher').fetch({ url: fetchURL, modelName: ITEM_TYPE }).then(function (results) {
        _this.set('model.items', results.data);
        _this._removeInvalidValues();
      })['catch'](function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) return;
        _this.setProperties({
          externalDataError: _this.get('i18n').t('errors.externalDataSourceFetchError').toString(),
          disabled: true
        });
      });
    },

    _setDefaultValue: function _setDefaultValue() {
      var _this2 = this;

      // Do not set default values if it is editing
      if (this.get('isEditing')) return;

      this.get('model.items').forEach(function (item) {
        if (item.get('checked')) {
          var itemExists = _this2.get('model.value').findBy('value', item.get('value'));
          if (!itemExists) _this2.send('toggleSelection', item);
        }
      });
    },

    _removeInvalidValues: function _removeInvalidValues() {
      var items = this.get('model.items');
      var selected = this.get('model.value');

      if (_ember['default'].isEmpty(selected)) return;

      var invalidSelections = [];
      selected.forEach(function (selectedItem) {
        if (!items.findBy('value', _ember['default'].get(selectedItem, 'value'))) {
          invalidSelections.pushObject(selectedItem);
        }
      });

      invalidSelections.forEach(function (invalid) {
        selected.removeObject(invalid);
      });
    }

  });
});