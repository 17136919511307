define('scientia-resourcebooker/abilities/availability', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canList: (function () {
      return this.get('currentUser').isAvailabilityManager() || this.get('currentUser').isAvailabilityViewer();
    }).property(),

    canReadOnly: (function () {
      return this.get('currentUser').isAvailabilityViewer();
    }).property(),

    canEdit: (function () {
      return this.get('currentUser').isAvailabilityManager();
    }).property(),

    canSeeEditIcon: (function () {
      return this.get('currentUser').isAvailabilityManager() || this.get('currentUser').isAvailabilityViewer();
    }).property(),

    canCreate: (function () {
      return this.get('currentUser').isAvailabilityManager();
    }).property(),

    canDelete: (function () {
      return this.get('currentUser').isAvailabilityManager();
    }).property(),

    canEditAvailability: (function () {
      return this.get('currentUser').isAvailabilityManager();
    }).property(),

    canEditDescription: (function () {
      return this.get('currentUser').isAvailabilityManager();
    }).property()

  });
});