define('scientia-resourcebooker/services/external-data-source-fetcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var url = _ref.url;
      var modelName = _ref.modelName;
      var _ref$query = _ref.query;
      var query = _ref$query === undefined ? '' : _ref$query;
      var _ref$page = _ref.page;
      var page = _ref$page === undefined ? 1 : _ref$page;

      return this.get('authenticatedRequest').externalFetch(url, { query: query, page: page }).then(function (payload) {
        var results = {};
        var items = payload.items && payload.items.length ? payload.items : _ember['default'].isArray(payload) ? payload : [];

        if (!items || !items.length && page === 1) {
          LE.error('BookingFormField: unable to retrieve external data', url);
        }

        results.data = _this._normalizeItems(modelName, items);
        results.more = payload.more;
        return results;
      })['catch'](function (e) {
        LE.error('BookingFormField: unable to retrieve external data', url);
        throw e;
      });
    },

    _normalizeItems: function _normalizeItems(modelName) {
      var _this2 = this;

      var payload = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      return payload.reduce(function (memo, item) {

        var modelItem = undefined;
        if (typeof item === 'object') {
          if (item.text && !item.value) item.value = item.text;
          if (!item.text && item.value) item.text = item.value;
          if (item.text && item.value) {
            modelItem = _this2.get('store').createFragment(modelName, item);
          }
        } else if (typeof item === 'string') {
          modelItem = _this2.get('store').createFragment(modelName, { text: item, value: item });
        }

        if (modelItem) memo.pushObject(modelItem);
        return memo;
      }, []);
    }

  });
});