define('scientia-resourcebooker/pods/index/route', ['exports', 'ember'], function (exports, _ember) {
  var SCOPE = '50D3EB5E-6BD2-44F1-830C-F5725CAF7F49';

  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    configFetcher: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('session.authorization') !== null) {
        this.transitionTo('logged-in');
      }
    },

    actions: {
      login: function login() {

        var clientId = undefined;
        if (this.get('configFetcher.extraDatasets')) {
          clientId = JSON.parse(localStorage.getItem('scientia-rb-dataset')) || this.get('configFetcher.clientId');
        } else {
          clientId = this.get('configFetcher.clientId');
        }

        var query = ['response_type=token', 'client_id=' + clientId, 'redirect_uri=' + encodeURIComponent(this.get('configFetcher.redirectURL')), 'scope=' + SCOPE];

        if (this.get('configFetcher.entityId')) {
          query.push('entityID=' + this.get('configFetcher.entityId'));
        }

        window.location = this.get('configFetcher.baseURL') + '?' + query.join('&');
        localStorage.removeItem('scientia-rb-dataset');
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          errorMessage: null,
          isLoadingConfig: false,
          hasClickedLoginButton: false
        });
      }
    }
  });
});