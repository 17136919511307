define('scientia-resourcebooker/services/booking-request-clash-checker', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    /**
     * Whether the polling has already been started
     */
    isPolling: false,

    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    customEvents: _ember['default'].inject.service(),

    globalConfirmer: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    /**
     * Simply starts the polling and prevents it from being called multiple times
     * @returns
     */
    start: function start() {

      if (this.get('isPolling')) return;

      this.set('isPolling', true);
      this.check();
    },

    check: function check() {
      var _this = this;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.CLASHES_FETCH;
      return this.get('authenticatedRequest').fetch(url).then(function (data) {

        // Show Clash Modal if any clashes were returned
        if (data && data.length) {
          _this.showClashModal(data);
        }

        // Trigger clash check again in 60sec
        _ember['default'].run.later(_this, 'check', 60000);
      });
    },

    /**
     * Show clashes modal with clashes information
     * @param {*} data
     */
    showClashModal: function showClashModal(data) {
      var _this2 = this;

      // Log clash event
      this.get('appInsights').trackEvent('booking-clash', data);

      // Create defer object so we know when modal was closed
      var defer = _ember['default'].RSVP.defer();
      defer.promise['finally'](function () {
        return _this2.onClashModalClosed(data);
      });

      // Generate html with [Reference] - [Booking Title] [Resource Name] Unavailable message
      var bookingNames = data.map(function (b) {
        var resourceMsg = _this2.get('i18n').t('pods.app.booking_clash_modal.message_resource', { resourceName: b.clashingEvent.resourceName }).toString();
        return '<p>' + b.clashingEvent.bookingReference + ' - ' + b.clashingEvent.eventName + ' </br> ' + resourceMsg + ' </p>';
      }).join('');

      var message = this.get('i18n').t('pods.app.booking_clash_modal.message').toString();

      var modalData = {
        titlePath: 'pods.app.booking_clash_modal.title',
        message: message + '</br>' + bookingNames,
        singleButtonMode: true,
        confirmLabelPath: 'general.ok',
        headerIcon: 'icons/attention-exclamation-triangular-signal.svg',
        headerIconClass: 'modal-header-title-clash-icon',
        location: 'at-clashModal',
        deferred: defer
      };

      // Trigger globalConfirmer / modal
      this.get('globalConfirmer').confirmModal(modalData);
    },

    /**
     * Callback when the modal has been closed
     * @param {*} data
     * @returns
     */
    onClashModalClosed: function onClashModalClosed(data) {
      var _this3 = this;

      // Close the modal
      this.get('globalConfirmer').close();

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.CLASHES_CLEAR;

      // Get array of Ids that were on the modal
      var ids = data.map(function (e) {
        return e.clashingEvent.eventId;
      });

      // We want to trigger with a delay so that the bookingRequest popup
      // has time to come into display and listen for the event
      _ember['default'].run.later(this, function () {
        _this3.get('customEvents').triggerClashModalClosed();
      }, 1000);

      // And post those Ids to ClearCache endpoint to confirm the user has seen and confirmed
      return this.get('authenticatedRequest').post(url, ids)['catch'](function () {
        return null;
      });
    }

  });
});