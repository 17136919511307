define('scientia-resourcebooker/serializers/user-group', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      claims: { embedded: 'always' }
    },

    normalize: function normalize(type, payload) {
      var applicationSerializer = this.store.serializerFor('application');
      payload = applicationSerializer._setPermissionGroupDefaults(payload);

      if (payload.Claims && payload.Claims.length === 1 && !payload.Claims[0].ClaimType) {
        payload.Claims = [];
      }

      return this._super.apply(this, arguments);
    }

  });
});