define('scientia-resourcebooker/models/notification-template', ['exports', 'ember', 'ember-data', 'model-fragments/fragment', 'ember-cp-validations'], function (exports, _ember, _emberData, _modelFragmentsFragment, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    subjectLine: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed(function () {
        return !this.get('model.sendEmail');
      }).volatile()
    })],
    fromEmail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed(function () {
        return !this.get('model.sendEmail');
      }).volatile()
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true,
      disabled: _ember['default'].computed(function () {
        return !this.get('model.sendEmail');
      }).volatile()
    })],
    content: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed(function () {
        return !this.get('model.sendEmail');
      }).volatile()
    })]
  }, {
    dependentKeys: ['model.sendEmail']
  });

  exports['default'] = _modelFragmentsFragment['default'].extend(Validations, {
    identity: attr('string'),
    bookingTypeIdentity: attr('string'),
    fromEmail: attr('string'),
    bccEmailList: attr('string'),
    ccEmailList: attr('string'),
    toEmailList: attr('string'),
    conditionals: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    options: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    name: attr('string'),
    subjectLine: attr('string'),
    content: attr('string'),
    sendEmail: attr('boolean'),
    recipientType: attr('string'),
    notificationType: attr('string'),
    isSystemTemplate: attr('boolean')
  });
});