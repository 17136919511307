define('scientia-resourcebooker/pods/manage-resources/booking-form/new/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_form.new.page_title');
    },

    model: function model() {
      return this.store.createRecord('booking-form', {
        sections: [this.store.createFragment('booking-form-section', {
          name: this.get('i18n').t('components.manage_resources.booking_form_tab.main').toString(),
          rank: 0,
          fields: []
        })]
      });
    },

    actions: {

      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        var controller = this.controllerFor('manage-resources.booking-form.new');
        if (controller.get('model.isNew')) {
          controller.get('model').deleteRecord();
        }
        controller.set('error', '');
      }
    }
  });
});