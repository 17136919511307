define('scientia-resourcebooker/services/current-user-roles-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetch: function fetch() {
      var _this = this;

      // If roles have been loaded or user is an admin do not (re)fetch them
      if (this.get('currentUser.hasLoadedPermissions') || this.get('currentUser').isAdmin()) return _ember['default'].RSVP.resolve();

      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.USER_ADMIN_ROLES).then(function (roles) {
        _this.get('currentUser').setPermissions(roles);
      });
    }
  });
});