define('scientia-resourcebooker/pods/manage-resources/resource-group/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    isSaving: false,
    error: '',

    actions: {
      save: function save() {
        var modelName = this.get('model.resourceGroup.name') || '';
        if (_ember['default'].isEmpty(modelName.trim())) {
          this.set('error', this.get('i18n').t('error_messages.resourcegroup_noname').toString());
          return;
        }

        this.set('error', '');
        this.send('createResourceGroup');
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_group.new.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resourceType.id')
      }, {
        label: this.get('i18n').t('pods.manage_resources.resource_group.new.page_title'),
        linkable: false
      }];
    }).property('model.resourceType.name', 'model.resourceType.id', 'i18n.locale')
  });
});