define('scientia-resourcebooker/pods/print/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/authenticated-route', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsAuthenticatedRoute, _emberCan) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAuthenticatedRoute['default'], _emberCan.CanMixin, {

    systemSettings: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super();
      this.get('systemSettings').fetch();
      return this.get('currentUserFetcher').fetch().then(function (user) {

        _ember['default'].$('.progressLoading').remove();
        if (_ember['default'].isEmpty(user.name) || _ember['default'].isEmpty(user.nameIdentifier) || _ember['default'].isEmpty(user.email)) {
          _this.get('session').logout();
          return;
        }

        _this.get('currentUser').setUser(user);
      });
    },

    actions: {
      triggerPrintDialog: function triggerPrintDialog() {
        window.print();
      },

      updateHeader: function updateHeader(header) {
        this.controller.set('header', header);
      },
      updateSubheader: function updateSubheader(subheader) {
        this.controller.set('subheader', subheader);
      },

      updateTopRightHeader: function updateTopRightHeader(value) {
        this.controller.set('topRightHeaderValue', value);
      },

      didTransition: function didTransition() {
        _ember['default'].$('html').attr('is-print', true);
      }
    }
  });
});