define('scientia-resourcebooker/pods/components/calendar-grid-empty/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),

    isSystem: false,

    startHour: 0,
    endHour: 24,
    showWeekends: true,
    weekdays: _ember['default'].computed('showWeekends', 'i18n.locale', function () {

      // Make sure we offset the weekdays if there is a weekday offset in the ENV file
      var weekDays = this.get('i18n').t('general.weekdays_abbreviations').toString().split(',');

      var weekDaysAsObjects = weekDays.map(function (dayName, i) {
        return { index: i, name: dayName };
      });

      var weekDayOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');

      for (var i = 0; i < weekDayOffset; i++) {
        weekDaysAsObjects.push(weekDaysAsObjects.shift());
      }

      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      if (!this.get('showWeekends')) {
        weekDaysAsObjects = weekDaysAsObjects.filter(function (w) {
          return !weekendDays.includes(w.index);
        });
      }
      return weekDaysAsObjects;
    }),

    hours: _ember['default'].computed('startHour', 'endHour', 'i18n.locale', function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var endHour = this.get('endHour') === 0 ? 24 : this.get('endHour');

      var startIndex = parseInt(this.get('startHour'), 10);
      var endIndex = parseInt(endHour, 10);

      for (var index = startIndex; index < endIndex; index++) {
        hours.push(startTime.clone().add(index, 'hours'));
      }

      return hours;
    })

  });
});