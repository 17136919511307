define("scientia-resourcebooker/pods/components/manage-resources/notification-templates/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [7, 29], [7, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 24], [7, 60]]], 0, 0], false], [], ["loc", [null, [7, 16], [7, 67]]], 0, 0], "confirmTitle", ["subexpr", "t", [["subexpr", "concat-strings", ["components.manage_resources.notification_templates.", ["subexpr", "if", [["get", "useCustomTemplates", ["loc", [null, [8, 100], [8, 118]]], 0, 0, 0, 0], "toggle_off_title", "toggle_on_title"], [], ["loc", [null, [8, 96], [8, 156]]], 0, 0]], [], ["loc", [null, [8, 26], [8, 157]]], 0, 0]], [], ["loc", [null, [8, 23], [8, 158]]], 0, 0], "confirmMessage", ["subexpr", "t", [["subexpr", "concat-strings", ["components.manage_resources.notification_templates.", ["subexpr", "if", [["get", "useCustomTemplates", ["loc", [null, [9, 102], [9, 120]]], 0, 0, 0, 0], "toggle_off_message", "toggle_on_message"], [], ["loc", [null, [9, 98], [9, 162]]], 0, 0]], [], ["loc", [null, [9, 28], [9, 163]]], 0, 0]], [], ["loc", [null, [9, 25], [9, 164]]], 0, 0], "confirm", ["subexpr", "action", ["toggleUseCustomTemplatesConfirm"], [], ["loc", [null, [10, 18], [10, 60]]], 0, 0]], ["loc", [null, [5, 13], [10, 61]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [11, 36], [11, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 31], [11, 67]]], 0, 0], false], [], ["loc", [null, [11, 23], [11, 74]]], 0, 0]], ["loc", [null, [4, 11], [11, 75]]], 0, 0]], ["loc", [null, [2, 3], [12, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "copyFromSystemModalData", ["loc", [null, [21, 29], [21, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 24], [21, 53]]], 0, 0], null], [], ["loc", [null, [21, 16], [21, 59]]], 0, 0], "model", ["subexpr", "@mut", [["get", "copyFromSystemModalData", ["loc", [null, [22, 16], [22, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 13], [22, 40]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [23, 41], [23, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 31], [23, 46]]], 0, 0]], [], ["loc", [null, [23, 23], [23, 47]]], 0, 0]], ["loc", [null, [18, 11], [23, 48]]], 0, 0]], ["loc", [null, [16, 3], [24, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["location", "at-notificationTemplates--big"], ["loc", [null, [28, 2], [28, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle manageResources-itemForm-subtitle--disabledTab");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "notificationsList-warning");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [36, 8], [36, 87]]], 0, 0], ["inline", "t", ["components.manage_resources.notification_templates.notifications_disabled_warning"], [], ["loc", [null, [37, 48], [37, 137]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 2
              },
              "end": {
                "line": 56,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "loading-icon", [], ["location", "at-notificationTemplates--big"], ["loc", [null, [55, 4], [55, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 4
                },
                "end": {
                  "line": 68,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/notification-templates/editor", [], ["selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [59, 23], [59, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "isSaving", ["subexpr", "@mut", [["get", "isSavingTemplate", ["loc", [null, [60, 15], [60, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [61, 18], [61, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [62, 15], [62, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystem", ["subexpr", "@mut", [["get", "isSystem", ["loc", [null, [63, 15], [63, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "copyFromSystem", ["subexpr", "action", ["copyFromSystem", true], [], ["loc", [null, [64, 21], [64, 51]]], 0, 0], "save", ["subexpr", "action", ["save", ["get", "selectedTemplate", ["loc", [null, [65, 26], [65, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 11], [65, 43]]], 0, 0]], ["loc", [null, [58, 6], [66, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 6
                },
                "end": {
                  "line": 70,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "manageResources-itemForm-form-emptyInstruction at-bookingTypeEditNotifications");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.notification_templates.select_template"], [], ["loc", [null, [69, 98], [69, 172]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 2
              },
              "end": {
                "line": 71,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "selectedTemplate", ["loc", [null, [57, 10], [57, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [57, 4], [70, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "notificationsList-wrapper");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "notificationsDisabled", ["loc", [null, [34, 10], [34, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 4], [39, 11]]]], ["inline", "manage-resources/notification-templates/list", [], ["model", ["subexpr", "@mut", [["get", "templateSummary", ["loc", [null, [42, 10], [42, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [43, 21], [43, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "clickedItemId", ["subexpr", "@mut", [["get", "clickedItemId", ["loc", [null, [44, 18], [44, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "useCustomTemplates", ["subexpr", "@mut", [["get", "useCustomTemplates", ["loc", [null, [45, 23], [45, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystem", ["subexpr", "@mut", [["get", "isSystem", ["loc", [null, [46, 13], [46, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [47, 13], [47, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "copyFromSystem", ["subexpr", "action", ["copyFromSystem"], [], ["loc", [null, [48, 19], [48, 44]]], 0, 0], "onSelect", ["subexpr", "action", ["onItemClick"], [], ["loc", [null, [49, 13], [49, 35]]], 0, 0], "toggleUseCustomTemplates", ["subexpr", "action", ["toggleUseCustomTemplates"], [], ["loc", [null, [50, 29], [50, 64]]], 0, 0]], ["loc", [null, [41, 4], [51, 6]]], 0, 0], ["block", "if", [["get", "isLoadingTemplate", ["loc", [null, [54, 8], [54, 25]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [54, 2], [71, 7]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [1, 6], [1, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 7]]]], ["block", "if", [["get", "copyFromSystemModalData", ["loc", [null, [15, 6], [15, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [25, 7]]]], ["block", "if", [["get", "isLoadingSummary", ["loc", [null, [27, 6], [27, 22]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [27, 0], [72, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});