define('scientia-resourcebooker/pods/components/manage-resources/icon-selector/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    iconsFetcher: _ember['default'].inject.service(),

    classNames: ['iconSelector'],
    selectedIcon: null,
    packs: [],
    icons: [],
    filteredIcons: [],
    pack: null,
    hasLoadingError: false,
    selectedPack: null,

    // By default we only load the manifest for pack1.
    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        this.get('iconsFetcher').fetch().then(function (icons) {
          var allIcons = [];
          var arrays = [];

          for (var i = 0; i < icons.length; i++) {
            icons[i].label = _this.get('i18n').t('components.icon_selector.categories.' + icons[i].value, { fallback: icons[i].label });
            arrays.push(icons[i].icons);
          }

          var allIconsObject = {
            value: 'all',
            label: _this.get('i18n').t('components.icon_selector.categories.all'),
            icons: arrays.reduce(function (a, b) {
              return a.concat(b);
            }, [])
          };

          icons.unshift(allIconsObject);

          _this.set('icons', icons);

          allIcons = arrays.reduce(function (a, b) {
            return a.concat(b);
          }, []);

          _this.set('filteredIcons', allIcons);
          _this.set('selectedPack', allIconsObject);
        })['catch'](function () {
          _this.set('hasLoadingError', true);
        });
      });
    }).on('init'),

    actions: {
      selectIcon: function selectIcon(icon) {
        this.set('selectedIcon', icon);
      },

      onPackChange: function onPackChange(pack) {
        this.set('filteredIcons', this.get('icons').findBy('value', pack.value).icons);
      }
    }
  });
});