define('scientia-resourcebooker/pods/components/rb-agenda-view/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['chronos-agendaView'],

    sortCriteria: ['date:desc'],
    sortedResults: _ember['default'].computed.sort('results', 'sortCriteria'),
    hourFormat: (function () {
      return this.get('i18n').t('components.rb_calendar.agenda_view.hour_format').toString();
    }).property(),

    dateFormat: (function () {
      return this.get('i18n').t('components.rb_calendar.agenda_view.date_format').toString();
    }).property(),

    actions: {
      onEventClick: function onEventClick(event) {
        this.sendAction('onEventClick', event);
      }
    }
  });
});