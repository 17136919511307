define('scientia-resourcebooker/pods/components/time-input-select/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':timeInputSelect', 'location'],
    systemSettings: _ember['default'].inject.service(),

    ariaLabel: null,
    disabled: false,

    value: '',
    placeholder: false,

    content: null,
    isRefreshingValue: false,

    // Due to a Firefox bug, Firefox loses the selected value whenever we refresh the content of this component/select element.
    // For that reason we need to make sure to unset and re-set the `value` property whenever the attrs of this component change
    // as to force Firefox to select the correct option that is in `value`
    didUpdateAttrs: function didUpdateAttrs() {
      this._forceRefreshValue();
    },

    _forceRefreshValue: function _forceRefreshValue() {

      if (this.get('isDetroyed') || this.get('isDestroying')) return;
      this.set('isRefreshingValue', true);

      _ember['default'].run.later(this, function () {
        if (this.get('isDetroyed') || this.get('isDestroying')) return;
        this.set('isRefreshingValue', false);
      });
    },

    actions: {
      onChange: function onChange(value) {

        // Do not process if value is the placeholder, or if the value is the same as the already selected on
        if (value === this.get('value') || value === '-- : --') return;
        if (this.get('onChange')) {
          this.get('onChange')(value);
        } else {
          this.set('value', value);
        }
      }
    }
  });
});