define('scientia-resourcebooker/pods/manage-resources/resource/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSaving: false,
    error: '',

    actions: {
      save: function save() {
        this.send('createResource', this.get('model.resource'));
      }
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', 'model.resourceType.id', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource.new.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resourceType.id')
      }, {
        label: this.get('i18n').t('pods.manage_resources.resource.new.page_title'),
        linkable: false
      }];
    })
  });
});