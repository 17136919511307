define('scientia-resourcebooker/pods/components/resources-meta/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    attributeBindings: ['role', 'aria-label'],
    classNames: ['resourcesList-meta'],
    currentPage: 1,
    totalPages: 1,
    isGroup: false,
    showPageIndicator: true,
    showLinkLabel: true,
    itemsPerPage: null,
    role: 'region',
    'aria-label': (0, _emberI18n.translationMacro)('components.resources_meta.region_label'),

    itemsPerPageAvailable: _ember['default'].computed('i18n.locale', function () {
      var values = [10, 25, 50, 100];
      var labels = this.get('i18n').t('components.resources_meta.items_per_page_options').toString().split(',');
      return values.map(function (v, i) {
        return { value: v, label: labels[i] };
      });
    }),

    jumptoPageObserver: (function () {
      var _this = this;

      _ember['default'].run.once('this', function () {
        if (_this.get('useActions')) return;
        _this.sendAction('changePage', +_this.get('currentPage') || 1);
      });
    }).observes('currentPage'),

    nextPage: (function () {
      if (+this.get('currentPage') < this.get('totalPages')) {
        return +this.get('currentPage') + 1;
      }
    }).property('currentPage', 'totalPages'),

    previousPage: (function () {
      if (+this.get('currentPage') > 1) {
        return +this.get('currentPage') - 1;
      }
    }).property('currentPage'),

    pageList: (function () {
      var list = [];
      for (var i = 1; i <= this.get('totalPages'); i++) {
        list.pushObject({ id: i, label: i });
      }
      return list;
    }).property('totalPages'),

    actions: {
      changeItemsPerPage: function changeItemsPerPage(itemsPerPage) {
        if (this.get('useActions') && this.get('onSetItemsPerPage')) {
          this.get('onSetItemsPerPage')(itemsPerPage);
        } else {
          this.setProperties({
            currentPage: 1,
            itemsPerPage: itemsPerPage
          });
        }
      },

      setPage: function setPage(page) {
        this.sendAction('changePage', page);
      }
    }
  });
});