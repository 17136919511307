define('scientia-resourcebooker/serializers/recurrence-pattern', ['exports', 'scientia-resourcebooker/serializers/application', 'ember', 'moment'], function (exports, _scientiaResourcebookerSerializersApplication, _ember, _moment) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend({
    recurrenceProcessor: _ember['default'].inject.service(),

    normalize: function normalize(model, hash /*prop*/) {
      var startDateTime = (0, _moment['default'])(hash.startDateTime);
      var endDateTime = (0, _moment['default'])(hash.endDateTime);
      hash.startDate = startDateTime;
      hash.fromTime = startDateTime.format('HH:mm');
      hash.toTime = endDateTime.format('HH:mm');
      hash.recurrenceTypeSelected = hash.recurrenceType || 0;
      hash.recurrenceMonthlyInterval = [hash.recurrenceMonthlyInterval];
      hash.dayOfWeek = [hash.dayOfWeek];
      hash.untilDate = (0, _moment['default'])(hash.untilDate);
      delete hash.recurrenceType;
      delete hash.startDateTime;
      delete hash.endDateTime;
      return this._super.apply(this, arguments);
      // return hash;
    },

    serialize: function serialize(snapshot /*, options*/) {
      var recurrence = _ember['default'].Object.create(snapshot.attributes());
      var json = this.get('recurrenceProcessor').generate(recurrence);
      if (!json) return null;
      var serialized = Object.keys(json).reduce(function (memo, key) {
        memo[key.classify()] = json[key];
        return memo;
      }, {});
      return serialized;
    }

  });
});