define('scientia-resourcebooker/pods/components/manage-resources/rules-form/new/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-group manageResources-itemForm-form-group-newRule'],
    attributeBindings: ['data-rule-template-id'],
    'data-rule-template-id': _ember['default'].computed.alias('rule.identity'),
    rule: null,
    loading: false,

    actions: {

      save: function save() {
        this.sendAction('save');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      },

      searchUserGroups: function searchUserGroups(query, deferred) {
        this.sendAction('searchUserGroups', query, deferred);
      }
    }

  });
});