define('scientia-resourcebooker/pods/components/manage-resources/concurrency-configuration-groups-form/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['concurrencyConfigurationGroupsForm'],

    concurrencyGroupResourceTreeDataFetcher: _ember['default'].inject.service(),
    concurrencyGroupSearcher: _ember['default'].inject.service(),

    concurrencyGroups: null,
    concurrencyConfiguration: null,
    model: null,
    selected: null,
    searchResults: null,
    query: '',

    init: function init() {
      this._super.apply(this, arguments);
      this._createResourceTreeModel();
    },

    _createResourceTreeModel: function _createResourceTreeModel() {

      var groups = this.get('concurrencyGroups');
      var result = _ember['default'].Object.create({
        name: 'concurrencygroups',
        data: groups
      });

      this.set('model', result);
    },

    actions: {

      onSelect: function onSelect(item) {
        var selected = this.get('selected');
        var existingItem = selected.findBy('id', _ember['default'].get(item, 'id'));
        var highestIndex = selected.get('length') ? Math.max.apply(Math, selected.getEach('order')) : 0;

        if (_ember['default'].isEmpty(existingItem)) {
          var itemId = _ember['default'].get(item, 'id');
          var itemData = {
            id: itemId,
            name: _ember['default'].get(item, 'name'),
            order: highestIndex + 1,
            type: 'concurrencygroups'
          };

          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }

        this.get('onSelectionChanged')(selected);
      },

      onSearch: function onSearch(query) {
        var _this = this;

        this.setProperties({
          query: query,
          isLoading: true
        });

        this.get('concurrencyGroupSearcher').search({
          query: query
        }).then(function (data) {
          _this.set('searchResults', { concurrencygroups: data });
        })['finally'](function () {
          _this.set('isLoading', false);
        });

        // this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
        //   type: 'concurrencygroup',
        //   data: {
        //     query,
        //     itemsPerPage: 999
        //   }}).then(data => {
        //   this.set('searchResults', {resources: data.results});
        // }).finally(() => {
        //   this.set('isLoading', false);
        // });
      },

      onClearSearch: function onClearSearch() {
        this.setProperties({
          query: '',
          searchResults: null
        });
      },

      onSelectSearchResultItem: function onSelectSearchResultItem(type, item) {
        this.send('onSelect', item);
      },

      clearSelection: function clearSelection() {
        this.set('selected', []);
        this.get('onSelectionChanged')([]);
      },

      onRemoveItem: function onRemoveItem(item) {
        this.send('onSelect', item);
      }

    }

  });
});