define('scientia-resourcebooker/pods/manage-resources/booking-type/bookings-importer/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    errorHandler: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    bulkExporter: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    importOptions: _ember['default'].computed('i18n.locale', 'model.bookingType.id', 'model.bookingType.bookingForm', function () {
      return [{
        id: 'import_file',
        valueType: 'file',
        key: 'import_file',
        value: null
      }, {
        id: 'validate_calendar_options',
        key: 'validateCalendarOptions',
        valueType: 'boolean',
        value: true
      }, {
        id: 'validate_template_availability',
        key: 'bypassTemplateAvailability',
        valueType: 'boolean',
        value: true,
        invertValueOnQueryParam: true
      }, {
        id: 'validate_resource_availability',
        key: 'bypassResourceAvailability',
        valueType: 'boolean',
        value: true,
        invertValueOnQueryParam: true
      }, {
        id: 'validate_booker_permissions',
        key: 'bypassBookerPermissionChecks',
        valueType: 'boolean',
        value: true,
        invertValueOnQueryParam: true
      }, {
        id: 'auto_approve',
        key: 'autoApprove',
        valueType: 'boolean',
        disabled: false,
        value: false
      }, {
        id: 'send_notifications',
        key: 'sendEmails',
        valueType: 'boolean',
        value: false
      }, {
        id: 'include_form_responses',
        key: 'includeFormResponses',
        valueType: 'boolean',
        value: false,
        hide: _ember['default'].isEmpty(this.get('model.bookingType.bookingForm'))
      }, {
        id: 'has_headers',
        key: 'hasHeader',
        valueType: 'boolean',
        value: true
      }, {
        id: 'check_against_user_rules',
        key: 'checkAgainstUserRules',
        valueType: 'boolean',
        hide: true,
        value: false
      }, {
        id: 'reevaluate_on_import',
        key: 'reevaluateOnImport',
        valueType: 'boolean',
        hide: true,
        value: false
      }, {
        id: 'booking_type_identity',
        key: 'bookingTypeIdentity',
        hide: true,
        value: this.get('model.bookingType.id')
      }];
    }),

    /**
     * This forces conditionally setting the value and disabled status of auto_approve when changes happen on validate_booker_permissions
     */
    onValidateBookerPermissionsChanged: (function () {
      _ember['default'].run.next(this, function () {
        var bookerPermissionOption = this.get('importOptions').find(function (o) {
          return o.id === 'validate_booker_permissions';
        });
        var autoApprove = this.get('importOptions').find(function (o) {
          return o.id === 'auto_approve';
        });

        var isBookerPermissionToggled = _ember['default'].get(bookerPermissionOption, 'value');

        // disabled status of autoApprove should be linked to bookerPermission value
        _ember['default'].set(autoApprove, 'disabled', !isBookerPermissionToggled);

        // only toggle autoApprove if bookerPermission was turned on
        if (!isBookerPermissionToggled) {
          _ember['default'].set(autoApprove, 'value', true);
        }
      });
    }).observes('importOptions.@each.value'),

    handleExceptionFromExport: function handleExceptionFromExport() /*data*/{
      this.flashError(this.get('i18n').t('general.generic_error'));
    },

    actions: {

      onGetProgressFailed: function onGetProgressFailed() {
        this.setProperties({
          progressTaskKey: null,
          isExportingAll: false,
          isExportingSelected: false
        });
        this.handleExceptionFromExport();
      }

    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_type.index.booking_types'),
        path: 'manage-resources.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        linkable: true,
        path: 'manage-resources.booking-type.edit',
        model: this.get('model.bookingType.id')
      }, {
        label: this.get('i18n').t('pods.manage_resources.booking_type.bookings_importer.breadcrumb'),
        linkable: false
      }];
    }).property('model.bookingType.name', 'i18n.locale')
  });
});