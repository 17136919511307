define('scientia-resourcebooker/services/date-time-helpers', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Service.extend({

    calendarOptionsHelper: _ember['default'].inject.service(),
    timeFormat: 'HH:mm',

    /**
     * Checks if timeA is before timeB.
     * @param {string} timeA The time to check as string. Example: 16:00
     * @param {string} timeB The time to check against. Example: 16:15. 00:00 for timeB will be considered as 24:00
     * @param {boolean} includeSame should equal times be considered truthful as well
     * @returns {boolean} If timeA is before timeB
     */
    isTimeBefore: function isTimeBefore(timeA, timeB, includeSame) {
      var _timeA$split = timeA.split(':');

      var _timeA$split2 = _slicedToArray(_timeA$split, 2);

      var timeAHour = _timeA$split2[0];
      var timeAMin = _timeA$split2[1];

      var timeAMoment = moment().hour(timeAHour).minute(timeAMin);

      var _timeB$split = timeB.split(':');

      var _timeB$split2 = _slicedToArray(_timeB$split, 2);

      var timeBHour = _timeB$split2[0];
      var timeBMin = _timeB$split2[1];

      var timeBMoment = moment().hour(timeBHour).minute(timeBMin);
      if (parseInt(timeBHour, 10) === 0 && parseInt(timeBMoment, 10) === 0) timeBMoment.add(1, 'day');

      if (includeSame) {
        return timeAMoment.isSameOrBefore(timeBMoment, 'minute');
      } else {
        return timeAMoment.isBefore(timeBMoment, 'minute');
      }
    },

    /**
     * Gets the next available period of a timeslot. Example: With 15min bookingPeriods, 16:30 would return 16:45
     * @param {string} time The time as a string. Ie: 16:15
     * @param {CalendarOptions} calendarOptions The calendarOptions for the bookingType
     * @param {boolean} outputAsString Outputs as a string (16:00) instead of Moment object. Default is true
     * @returns {string|Moment} Returns the next period either a a string or Moment object
     */
    getNextTimePeriod: function getNextTimePeriod(time, calendarOptions) {
      var outputAsString = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];
      var extraMinutes = arguments.length <= 3 || arguments[3] === undefined ? 0 : arguments[3];

      var _time$split = time.split(':');

      var _time$split2 = _slicedToArray(_time$split, 2);

      var valueHour = _time$split2[0];
      var valueMinute = _time$split2[1];

      var timeMoment = moment().hour(valueHour).minute(valueMinute);

      var bookingPeriods = _ember['default'].get(calendarOptions, 'calendarBookingTimes');
      var periodsAvailable = this.get('calendarOptionsHelper').parse(bookingPeriods).reject(function (x) {
        return x === 0;
      });

      if (!periodsAvailable.length) periodsAvailable = [0];

      var nextPeriod = undefined;
      if (extraMinutes) {
        nextPeriod = timeMoment.clone().add(extraMinutes, 'minute');
      } else {
        nextPeriod = timeMoment.clone().add(periodsAvailable[0], 'minute');
      }

      var calendarEndTime = _ember['default'].get(calendarOptions, 'calendarEndTime');

      var _calendarEndTime$split = calendarEndTime.split(':');

      var _calendarEndTime$split2 = _slicedToArray(_calendarEndTime$split, 2);

      var calHour = _calendarEndTime$split2[0];
      var calMin = _calendarEndTime$split2[1];

      var calendarEndMoment = moment().hour(calHour).minute(calMin);
      if (parseInt(calHour, 10) === 0 && parseInt(calMin, 10)) calendarEndMoment.add(1, 'day');

      var result = undefined;

      if (nextPeriod.isBefore(calendarEndMoment, 'minute')) {
        result = nextPeriod;
      } else {
        result = calendarEndMoment;
      }

      return outputAsString ? result.format(this.timeFormat) : result;
    },

    /**
     * Gets the hour value given a time. Ie: 16:15 returns 16
     * @param {string} time
     * @returns {number} The hour value
     */
    getStartHourFromTime: function getStartHourFromTime(time) {
      if (!time || time.indexOf(':') === -1) return 0; // fallback

      var _time$split3 = time.split(':');

      var _time$split32 = _slicedToArray(_time$split3, 1);

      var hour = _time$split32[0];

      return parseInt(hour);
    },

    /**
     * Get the whole hour given a time. Ie: 15:30 returns 16. 17:00 returns 17
     * @param {string} time The time as xx:xx
     * @returns {number} The whole hour
     */
    getEndHourFromTime: function getEndHourFromTime(time) {
      if (!time || time.indexOf(':') === -1) return 0; // fallback
      var endTime = time;

      var _endTime$split$map = endTime.split(':').map(function (x) {
        return parseInt(x, 10);
      });

      var _endTime$split$map2 = _slicedToArray(_endTime$split$map, 2);

      var hour = _endTime$split$map2[0];
      var min = _endTime$split$map2[1];

      if (hour !== 0 && min !== 0) hour++;
      return hour;
    },

    /**
     * Only used by old timepicker
     * @param {string} endTime The end time as a string xx:xx
     * @param {number[]} availableMinutes The available minutes
     */
    getAvailablePeriodsForLastHour: function getAvailablePeriodsForLastHour(endTime, availableMinutes) {
      if (!endTime || endTime.indexOf(':') === -1) return availableMinutes; // fallback
      var minute = endTime.split(':')[1];
      return availableMinutes.filter(function (x) {
        return x <= minute;
      });
    }

  });
});