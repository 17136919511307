define('scientia-resourcebooker/serializers/default-permission-group', ['exports', 'ember-data/serializers/json'], function (exports, _emberDataSerializersJson) {
  exports['default'] = _emberDataSerializersJson['default'].extend({

    normalize: function normalize(type, payload, prop) {

      var newPayload = this.store.serializerFor('application')._camelizeKeys(payload);
      newPayload.isClaimMode = !!newPayload.permissionClaim.claimType && !!newPayload.permissionClaim.claimValue;

      if (newPayload.isClaimMode) {

        try {
          newPayload.claimType = newPayload.permissionClaim.claimType.toLowerCase();
          newPayload.claimValue = newPayload.permissionClaim.claimValue;
          delete newPayload.permissionClaim;
        } catch (e) {
          //no-op
        }
      }

      var results = this._super(type, newPayload, prop);
      return results;
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.isClaimMode) {
        delete json.identity;
        delete json.name;
        json.permissionClaim = { claimType: json.claimType, claimValue: json.claimValue };
      }

      delete json.claimType;
      delete json.claimValue;
      delete json.isClaimMode;

      return json;
    }
  });
});
// Serializers for fragments work just as with models