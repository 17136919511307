define('scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/component', ['exports', 'ember'], function (exports, _ember) {

  var ATTACHMENT = 'attachment';
  var SINGLECHOICE = 'single_choice';
  var LABEL = 'label';
  var MULTIPLECHOICE = 'multiple_choice';
  var NUMERIC = 'numeric';
  var TEXTBOX = 'textbox';
  var LINK = 'url';
  var FIELD_TYPES = [ATTACHMENT, LABEL, MULTIPLECHOICE, NUMERIC, SINGLECHOICE, TEXTBOX, LINK];

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bookingFormTab-content dc-tabs-panel active'],
    classNameBindings: ['isEditingAnyField:bookingFormTab-content--isEditingField'],
    id: _ember['default'].computed.alias('model.id'),
    rank: _ember['default'].computed.alias('model.rank'),
    selectedNewFieldType: null,

    possibleValueTypes: (function () {
      var _this = this;

      return FIELD_TYPES.map(function (type, i) {
        return { id: i, value: type, label: _this.get('i18n').t('components.manage_resources.booking_form_tab.value_types.' + type) };
      }).sortBy('label');
    }).property('i18n.locale'),

    isEditingAnyField: (function () {
      if (!this.get('model') || !this.get('model.fields')) return; // Fixes when tabs are being sorted
      return this.get('model.fields').filter(function (field) {
        return field.get('isEditing');
      }).length > 0;
    }).property('model.fields.@each.isEditing'),

    addFieldDisabled: (function () {
      return this.get('fieldIsEditing') || !Number.isInteger(this.get('selectedNewFieldType'));
    }).property('fieldIsEditing', 'selectedNewFieldType')
  });
});