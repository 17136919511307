define('scientia-resourcebooker/pods/components/manage-resources/remove-unavailability-weekday-slot/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    userGroup: null,
    isSaving: false,

    model: null,

    formattedDate: _ember['default'].computed('model.dayIndex', function () {
      if (this.get('model') === null) return;
      var dayIndex = this.get('model.dayIndex');
      return moment().weekday(dayIndex).format('dddd');
    }),

    formattedWeekdayFromTime: _ember['default'].computed('model.fromTime', function () {
      return this._formatWeekdayTime(this.get('model.fromTime'));
    }),

    formattedWeekdayToTime: _ember['default'].computed('model.toTime', function () {
      return this._formatWeekdayTime(this.get('model.toTime'), true);
    }),

    isAllDay: (function () {
      if (this.get('model.dayIndex') === null) return;
      return this.get('model.data.duration') >= 1439;
      // // API tends to return 00:00 as 23:59 so we need to check against 1439min
    }).property('model.fromTime', 'model.toTime'),

    duration: (function () {
      return this.get('model.toTime').asMinutes() - this.get('model.fromTime').asMinutes();
    }).property('model.fromTime', 'model.toTime'),

    _formatWeekdayTime: function _formatWeekdayTime(duration) {
      var isEndTime = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      if (duration === null) return;

      var time = moment().startOf('day').add(duration.asSeconds(), 'seconds');

      if (time.minute() === 59) time.add(1, 'minute');

      if (isEndTime && time.hour() === 0 && time.minute() === 0) {
        return this.get('i18n').t('date_formats.midnight_twenty_four');
      }

      return time.format(this.get('i18n').t('components.remove_unavailability_slot.time_format').toString());
    },

    actions: {
      submit: function submit() {
        this.set('isSaving', true);
        this.get('deleteUnavailabilityWeekdaySlot')(this.get('model'), this.get('userGroup'));
      }
    }
  });
});