define('scientia-resourcebooker/pods/manage-resources/user-group/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    name: '',
    claims: [],

    selectedTab: 0,
    isSaving: false,
    error: '',

    actions: {
      save: function save() {

        if (_ember['default'].isEmpty(this.get('name'))) {
          this.set('error', this.get('i18n').t('error_messages.usergroup_noname').toString());
          return;
        }

        this.set('error', null);
        this.send('createUserGroup', {
          name: this.get('name'),
          claims: [],
          editClaimsPermissionGroups: []
        });
      }
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.user_group.index.user_groups'),
        path: 'manage-resources.user-group.index'
      }, {
        label: this.get('i18n').t('pods.manage_resources.user_group.new.page_title'),
        linkable: false
      }];
    })
  });
});