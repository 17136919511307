define('scientia-resourcebooker/services/global-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var SEARCH_TYPES = ['bookingTypes', 'resourceGroups', 'resources'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    search: function search(_ref) {
      var query = _ref.query;
      var type = _ref.type;
      var perPage = _ref.perPage;
      var pageNumber = _ref.pageNumber;

      var data = {
        query: query,
        filterType: type || '',
        itemsPerPage: perPage || 25,
        pageNumber: pageNumber || 1
      };

      this.get('appInsights').trackEvent('search-global', { query: query, type: type });

      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.GLOBAL_SEARCHER_URL, data).then(this._serializeResults.bind(this, type));
    },

    bookingTypesValidForResource: function bookingTypesValidForResource(resource) {
      var _this = this;

      var type = arguments.length <= 1 || arguments[1] === undefined ? 'resource' : arguments[1];

      var endpoint = type === 'resource' ? _scientiaResourcebookerConfigEnvironment['default'].API.GLOBAL_SEARCHER_VALID_BOOKING_TYPE_FOR_RESOURCE : _scientiaResourcebookerConfigEnvironment['default'].API.GLOBAL_SEARCHER_VALID_BOOKING_TYPE_FOR_RESOURCE_GROUP;
      return this.get('authenticatedRequest').post(endpoint, resource).then(function (data) {
        var serializer = _this.get('store').serializerFor('booking-type');
        var typeClass = _this.get('store').modelFor('booking-type');

        var serializedData = serializer.normalizeArrayResponse(_this.get('store'), typeClass, data, null, 'query');
        var bookingTypes = _this.get('store').push(serializedData);
        return bookingTypes;
      });
    },

    _serializeResults: function _serializeResults(type, jsonResults) {
      var results = {};
      var jsonType = undefined;

      if (!type) {
        SEARCH_TYPES.forEach(function (key) {
          jsonType = jsonResults[key].currentPage && jsonResults[key].totalPages ? jsonResults[key] : null;
          results[key] = jsonResults[key].results;
        });

        if (!jsonType) jsonType = jsonResults.bookingTypes;
      } else {
        jsonType = jsonResults[type.pluralize()];
        results = jsonType.results;
      }

      return {
        results: results,
        pagination: {
          currentPage: jsonType.currentPage || 1,
          totalPages: jsonType.totalPages || 1
        }
      };
    }
  });
});