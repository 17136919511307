define("scientia-resourcebooker/pods/print/booking/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/print/booking/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "booking-request-overview", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", false, "showOccurrencesCountAfterPattern", true, "printMode", true, "close", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [6, 26], [6, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 16], [6, 31]]], 0, 0]], [], ["loc", [null, [6, 8], [6, 32]]], 0, 0], "location", "bookingRequestOverviewPrint", "cancelBooking", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [8, 34], [8, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 24], [8, 39]]], 0, 0]], [], ["loc", [null, [8, 16], [8, 40]]], 0, 0], "editBooking", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [9, 32], [9, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 22], [9, 37]]], 0, 0]], [], ["loc", [null, [9, 14], [9, 38]]], 0, 0]], ["loc", [null, [1, 0], [10, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});