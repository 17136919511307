define('scientia-resourcebooker/pods/components/manage-resources/task-modal/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmModal taskModal'],

    taskProgressFetcher: _ember['default'].inject.service(),

    progressKey: null,
    progressResults: null,
    loading: false,
    progressId: null,

    localePrefix: 'error_messages',

    isUserGroupTask: _ember['default'].computed('progressKey', function () {
      return this.get('progressKey') ? this.get('progressKey').indexOf('UserGroup') === 0 : false;
    }),

    percentageComputed: _ember['default'].computed('progressResults', function () {
      var p = this.get('progressResults');
      var disablePercentage = p ? p.disablePercentageProgress : false;
      return disablePercentage ? 100 : p ? p.percentageCompleted : 0;
    }),

    title: (0, _emberI18n.translationMacro)('components.manage_resources.task_modal.title'),
    message: (0, _emberI18n.translationMacro)('components.manage_resources.task_modal.message'),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('progressKey')) {
        this.send('getProgress');
      }
    },

    actions: {

      getProgress: function getProgress() {
        var _this = this;

        var progressKey = this.get('progressKey');
        this.set('loading', true);
        this.get('taskProgressFetcher').fetch(progressKey).then(function (progressResults) {
          if (!progressResults.progressCacheKey) {
            return _this.get('onGetProgressFailed')({
              prevProgressResults: progressResults,
              lastProgressResults: progressResults });
          }

          // Translate progressMessage
          if (progressResults.progressMessage && progressResults.progressMessage.messageKey) {
            progressResults.progressMessage = _this.get('i18n').t(_this.get('localePrefix') + '.' + progressResults.progressMessage.messageKey.toLowerCase());
          }

          _this.set('progressResults', progressResults);
          if ((progressResults.percentageCompleted < 100 && !progressResults.disablePercentageProgress || progressResults.disablePercentageProgress) && !progressResults.isCompleted) {
            var id = _ember['default'].run.later(_this, 'send', 'getProgress', progressKey, 2000);
            _this.set('progressId', id);
          } else {
            _this.get('onComplete')(progressResults);
          }
        })['catch'](function (error) {
          _this.get('onGetProgressFailed')({ progressKey: progressKey,
            lastProgressResults: _this.get('progressResults'),
            error: error
          });
        });
      },

      onCancelTask: function onCancelTask() {
        _ember['default'].run.cancel(this.get('progressId'));
        this.sendAction('onCancel');
      }

    }
  });
});