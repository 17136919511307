define('scientia-resourcebooker/pods/components/dc-tab/component', ['exports', 'dc-tabs/components/dc-tab'], function (exports, _dcTabsComponentsDcTab) {
  exports['default'] = _dcTabsComponentsDcTab['default'].extend({
    tagName: 'li',
    classNameBindings: [':dc-tabs-list-item', 'disabled:dc-tabs-list-item--disabled', 'faded:dc-tabs-list-item--faded'],
    disabled: false,

    select: (function (options) {
      if (!this.get('disabled')) this._super(options);
    }).on('click')
  });
});