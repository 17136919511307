define('scientia-resourcebooker/models/resource', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    description: attr('string'),
    imageUri: attr('string'),
    canEdit: attr('boolean'),
    canDelete: attr('boolean'),
    canEditAll: attr('boolean'),
    canEditAvailability: attr('boolean'),
    canEditCustomFields: attr('boolean'),
    canEditPermissions: attr('boolean'),
    canEditDescription: attr('boolean'),
    canViewCalendar: attr('boolean'),
    // canViewBookingsReport: attr('boolean'),

    externalApplicationId: attr('string'),

    properties: (0, _modelFragmentsAttributes.fragmentArray)('property', { defaultValue: [] }),

    readPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('read-permission-group', { defaultValue: [] }),
    writePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('write-permission-group', { defaultValue: [] }),
    approvePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('approve-permission-group', { defaultValue: [] }),
    bookPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('book-permission-group', { defaultValue: [] }),
    requestPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('request-permission-group', { defaultValue: [] }),

    viewCalendarsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-calendars-permission-group'),
    viewAuditReportPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-audit-report-permission-group'),
    viewBookingsReportPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('view-bookings-report-permission-group'),
    manageBookingsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('manage-bookings-permission-group'),

    editDescriptionPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-description-permission-group'),
    editCustomFieldsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-custom-fields-permission-group'),
    editAvailabilityPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-availability-permission-group'),
    editPermissionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-permissions-permission-group'),
    editAllPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-all-permission-group'),

    resourceTypeId: attr('string'),
    resourceTypeName: attr('string'),
    resourceType: belongsTo('resource-type'),

    bookingTypes: hasMany('booking-type'),

    events: hasMany('event'),
    resourceRestrictionId: attr('string'),

    permissionsKeys: ['read', 'write', 'approve', 'book', 'request', 'view-calendars', 'view-bookings-report', 'view-audit-report', 'manage-bookings', 'edit-description', 'edit-custom-fields', 'edit-availability', 'edit-permissions', 'edit-all']

  });
});