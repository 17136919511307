define('scientia-resourcebooker/pods/logged-in/search-results/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/page-changer'], function (exports, _ember, _scientiaResourcebookerMixinsPageChanger) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], {
    globalSearcher: _ember['default'].inject.service(),

    queryParams: ['query', 'type', 'page', 'perPage'],

    type: 'bookingType',
    query: '',
    page: 1,
    perPage: 10,

    boundQuery: _ember['default'].computed.oneWay('query'),
    // shouldShowPaging: true,
    // shouldShowPaging: Ember.computed.gt('model.items.pagination.totalPages', 1),
    shouldShowPaging: _ember['default'].computed.gt('model.items.results.length', 0),

    boundQueryDidChange: (function () {
      if (this.get('boundQuery') === '') {
        this.send('clearSuggestions');
      } else {
        this.send('submitSearch');
      }
    }).observes('boundQuery'),

    types: (function () {
      return [{ id: 'bookingType', value: 'bookingType', label: this.get('i18n').t('pods.logged_in.search_results.booking_types') }, { id: 'resourceGroup', value: 'resourceGroup', label: this.get('i18n').t('pods.logged_in.search_results.resource_groups') }, { id: 'resource', value: 'resource', label: this.get('i18n').t('pods.logged_in.search_results.resources') }];
    }).property(),

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          type: 'bookingType',
          query: '',
          page: 1
        });
      }
    },

    actions: {
      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 1000, false);
      },

      clearSuggestions: function clearSuggestions() {
        this.set('model', []);
      }
    },

    _search: function _search() {
      this.set('query', this.get('boundQuery'));
    }
  });
});