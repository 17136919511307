define('scientia-resourcebooker/pods/components/manage-resources/claims-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'components.manage_resources.claims_form';
  var CLAIM_TYPE_VALUES = ['group', 'nameidentifier', 'emailaddress', 'givenname'];

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),

    classNames: ['claimsForm'],

    localePrefix: DEFAULT_LOCALE_PREFIX,
    claims: [],

    showClaims: false,
    isLoadingClaims: false,

    claimTypeValues: (function () {
      var _this = this;

      var values = CLAIM_TYPE_VALUES;

      return values.map(function (value) {
        return { value: value, label: _this.get('i18n').t('components.manage_resources.claims_form.claim_types.' + value) };
      });
    }).property(),

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property(),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('claims.length') < 300) {
        this.set('showClaims', true);
      }
    },

    actions: {
      addClaim: function addClaim() {
        var emptyClaim = this.get('claims').find(function (claim) {
          return claim.get('claimValue') === '';
        });

        // Prevent adding an new claims while another one is empty
        if (!emptyClaim) {
          this.get('claims').addObject(this.get('store').createFragment('claim', { claimValue: '', claimType: 'group', isEditing: true }));
        }
      },

      deleteClaim: function deleteClaim(index) {
        var _this2 = this;

        this.get('globalConfirmer').confirm(this.get('i18n').t(DEFAULT_LOCALE_PREFIX + '.confirm_delete')).then(function () {
          return _this2.get('claims').removeAt(index);
        });
      },

      showClaims: function showClaims() {
        this.set('isLoadingClaims', true);
        _ember['default'].run.later(this, function () {
          this.set('showClaims', true);
        }, 500);

        _ember['default'].run.later(this, function () {
          this.set('isLoadingClaims', false);
        }, 1000);
      }
    }
  });
});