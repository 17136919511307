define('scientia-resourcebooker/pods/components/reports/reports-dashboard/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tabForm at-reports reportsDashboard'],

    locale: _ember['default'].computed.alias('i18n.locale'),
    embeddedReportsFetcher: _ember['default'].inject.service(),

    errorMessage: null,
    selectedReportData: null,
    isLoading: false,

    isInitialLoading: true,

    reports: null,
    noReports: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isInitialLoading', true);
      this.get('embeddedReportsFetcher').fetchAll().then(function (reports) {
        if (reports) {

          if (reports.length === 1) {
            _this.send('didSelectReport', reports[0]);
          } else if (reports.length > 1) {
            _this.set('reports', reports);
          } else {
            _this.set('noReports', true);
          }
        }
      })['catch'](function () {
        _this.set('noReports', true);
      })['finally'](function () {
        _this.set('isInitialLoading', false);
      });
    },

    actions: {

      didSelectReport: function didSelectReport(report) {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('embeddedReportsFetcher').fetchById(report.id, report.name).then(function (report) {

          if (report.errorMessage) {
            _this2.setProperties({
              errorMessage: report.errorMessage,
              selectedReportData: null
            });
          } else {
            _this2.setProperties({
              errorMessage: null,
              selectedReportData: report
            });
          }
        })['catch'](function () {
          _this2.set('errorMessage', _this2.get('i18n').t('components.manage_resources.reports.dashboard.fetch_error'));
        })['finally'](function () {
          _this2.set('isLoading', false);
        });
      },
      onEmbedded: function onEmbedded() {
        // do nothing
      }

    }
  });
});