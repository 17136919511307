define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/configuration/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    queryParams: ['isNew'],

    selectedConcurrencyGroupMembers: null,
    showSortModal: false,
    error: null,
    isNew: false,

    _mapSelectionsFromModel: function _mapSelectionsFromModel(model) {
      if (!_ember['default'].isEmpty(model)) {
        var selected = model.map(function (item) {
          return {
            id: item.id || item.identity,
            name: item.name,
            order: item.order,
            type: 'concurrencygroups'
          };
        });
        this.set('selectedConcurrencyGroupMembers', selected);
      } else {
        this.set('selectedConcurrencyGroupMembers', []);
      }
    },

    actions: {

      onSelectionChanged: function onSelectionChanged(selection) {
        this.set('selectedConcurrencyGroupMembers', selection);
      },

      onSelectionSorted: function onSelectionSorted(selection) {
        this.set('selectedConcurrencyGroupMembers', selection);
        this.set('showSortModal', false);
      },

      onCancel: function onCancel() {
        this.transitionToRoute('manage-resources.resource-type.concurrency.index');
      }
    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: true, path: 'manage-resources.resource-type.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title'), linkable: true, path: 'manage-resources.resource-type.concurrency.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.configuration'), linkable: false }, { label: this.get('model.concurrencyConfiguration.name'), linkable: false }];
    }).property('model.concurrencyConfiguration.id', 'i18n.locale')
  });
});