define("scientia-resourcebooker/pods/components/rb-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            dom.setAttribute(el1, "disabled", "");
            dom.setAttribute(el1, "hidden", "");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'selected');
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            return morphs;
          },
          statements: [["attribute", "selected", ["subexpr", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "value", ["loc", [null, [4, 45], [4, 50]]], 0, 0, 0, 0], null], [], ["loc", [null, [4, 41], [4, 56]]], 0, 0], ["subexpr", "eq", [["get", "value", ["loc", [null, [4, 61], [4, 66]]], 0, 0, 0, 0], ""], [], ["loc", [null, [4, 57], [4, 70]]], 0, 0]], [], ["loc", [null, [4, 37], [4, 71]]], 0, 0], "selected"], [], ["loc", [null, [null, null], [4, 84]]], 0, 0], 0, 0, 0, 0], ["content", "placeholder", ["loc", [null, [4, 92], [4, 107]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 8,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element0, 'aria-selected');
            morphs[1] = dom.createAttrMorph(element0, 'aria-label');
            morphs[2] = dom.createAttrMorph(element0, 'disabled');
            morphs[3] = dom.createAttrMorph(element0, 'value');
            morphs[4] = dom.createAttrMorph(element0, 'selected');
            morphs[5] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "aria-selected", ["concat", [["subexpr", "eq", [["get", "value", ["loc", [null, [7, 34], [7, 39]]], 0, 0, 0, 0], ["subexpr", "get", [["get", "contentChoice", ["loc", [null, [7, 45], [7, 58]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [7, 59], [7, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 40], [7, 75]]], 0, 0]], [], ["loc", [null, [7, 29], [7, 77]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "get", [["get", "contentChoice", ["loc", [null, [7, 97], [7, 110]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [7, 111], [7, 126]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 91], [7, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "contentChoice.disabled", ["loc", [null, [7, 141], [7, 163]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["subexpr", "get", [["get", "contentChoice", ["loc", [null, [7, 178], [7, 191]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [7, 192], [7, 207]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [7, 209]]], 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "value", ["loc", [null, [7, 224], [7, 229]]], 0, 0, 0, 0], ["subexpr", "get", [["get", "contentChoice", ["loc", [null, [7, 235], [7, 248]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [7, 249], [7, 264]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 230], [7, 265]]], 0, 0]], [], ["loc", [null, [null, null], [7, 267]]], 0, 0], 0, 0, 0, 0], ["inline", "get", [["get", "contentChoice", ["loc", [null, [7, 274], [7, 287]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [7, 288], [7, 303]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 268], [7, 305]]], 0, 0]],
          locals: ["contentChoice"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element2, 'aria-label');
          morphs[1] = dom.createAttrMorph(element2, 'disabled');
          morphs[2] = dom.createAttrMorph(element2, 'onblur');
          morphs[3] = dom.createAttrMorph(element2, 'onchange');
          morphs[4] = dom.createAttrMorph(element2, 'class');
          morphs[5] = dom.createMorphAt(element2, 1, 1);
          morphs[6] = dom.createMorphAt(element2, 2, 2);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["get", "ariaLabel", ["loc", [null, [2, 24], [2, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [2, 48], [2, 56]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onblur", ["subexpr", "action", ["updateValue"], ["value", "target.value"], ["loc", [null, [null, null], [2, 111]]], 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["updateValue"], ["value", "target.value"], ["loc", [null, [null, null], [2, 166]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["rb-select-select ", ["get", "class", ["loc", [null, [2, 194], [2, 199]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showPlaceholder", ["loc", [null, [3, 10], [3, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 11]]]], ["block", "each", [["get", "content", ["loc", [null, [6, 12], [6, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [6, 4], [8, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "canShowComponent", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [10, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});