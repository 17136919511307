define("scientia-resourcebooker/pods/components/manage-resources/rules-form/types/date/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/rules-form/types/date/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bs form-input form-input--datepicker");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "value", ["loc", [null, [5, 7], [5, 12]]], 0, 0, 0, 0]], [], [], 0, 0], "stepping", 30, "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [7, 11], [7, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", true, "sideBySide", true, "format", "YYYY-MM-DD", "showTodayButton", true, "showClose", true, "allowInputToggle", true, "locale", ["subexpr", "@mut", [["get", "locale", ["loc", [null, [14, 9], [14, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [15, 14], [15, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "updateDate", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [16, 26], [16, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 21], [16, 32]]], 0, 0]], [], ["loc", [null, [16, 13], [16, 33]]], 0, 0]], ["loc", [null, [4, 2], [16, 35]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});