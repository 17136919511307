define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item', 'manageResources-itemForm-form-item--topAligned'],

    actions: {
      addToList: function addToList(choice) {
        if (this.get('disabled')) return;
        if (_ember['default'].typeOf(this.get('property.value')) !== 'array') {
          this.set('property.value', [choice]);
        } else {
          this.set('property.value', this.get('property.value').slice().addObject(choice));
        }
      },

      removeFromList: function removeFromList(choice) {
        if (this.get('disabled')) return;
        this.set('property.value', this.get('property.value').slice().removeObject(choice));
      }
    }
  });
});