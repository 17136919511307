define('scientia-resourcebooker/pods/components/manage-resources/remove-unavailability-date-slot/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    model: null,
    userGroup: null,
    isSaving: false,

    formattedSpecificDate: _ember['default'].computed('model.fromTime', function () {
      if (this.get('model.fromTime') != null) {
        return this.get('model.fromTime').format(this.get('i18n').t('date_formats.date_full').toString());
      }
    }),

    // Format date only. Subtract 1 day if it ends in 00:00 so we can display 24:00 on formattedSpecificToTime
    formattedSpecificToDate: _ember['default'].computed('model.toTime', function () {
      if (this.get('model.toTime') != null) {
        var toTime = this.get('model.toTime').clone();
        if (toTime.hour() === 0 && toTime.minute() === 0) toTime.subtract(1, 'day');
        return toTime.format(this.get('i18n').t('date_formats.date_full').toString());
      }
    }),

    formattedSpecificFromTime: _ember['default'].computed('model.fromTime', function () {
      if (this.get('model.fromTime') != null) {
        return this.get('model.fromTime').format(this.get('i18n').t('date_formats.hour_full').toString());
      }
    }),

    formattedSpecificToTime: _ember['default'].computed('model.toTime', function () {
      if (this.get('model.toTime') != null) {
        var toTime = this.get('model.toTime').clone();
        if (toTime.hour() === 0 && toTime.minute() === 0) {
          return this.get('i18n').t('date_formats.midnight_twenty_four').toString();
        }

        return toTime.format(this.get('i18n').t('date_formats.hour_full').toString());
      }
    }),

    actions: {
      submit: function submit() {
        this.get('deleteUnavailabilityDateSlot')(this.get('model'), this.get('userGroup'));
      }
    }
  });
});