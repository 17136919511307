define('scientia-resourcebooker/pods/manage-resources/resource-type/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.index.page_title');
    },

    model: function model() {
      return this.store.query('resource-type', { adminPermissionsOnly: true });
    }
  });
});