define("scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "objectName", ["loc", [null, [3, 4], [3, 18]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["subexpr", "optional", [["get", "close", ["loc", [null, [2, 44], [2, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 34], [2, 50]]], 0, 0]], [], ["loc", [null, [2, 26], [2, 51]]], 0, 0]], 0, null, ["loc", [null, [2, 2], [4, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 8
                  },
                  "end": {
                    "line": 16,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", [["subexpr", "concat-strings", ["icons/", ["get", "type.icon", ["loc", [null, [15, 46], [15, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 21], [15, 56]]], 0, 0]], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [15, 8], [15, 94]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 4
                },
                "end": {
                  "line": 20,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createElementMorph(element3);
              morphs[2] = dom.createMorphAt(element4, 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [12, 35], [12, 46]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [12, 47], [12, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 31], [12, 53]]], 0, 0], "selected"], [], ["loc", [null, [12, 26], [12, 66]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "selectedTab", ["loc", [null, [12, 90], [12, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 85], [12, 102]]], 0, 0], ["get", "index", ["loc", [null, [12, 103], [12, 108]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 77], [12, 109]]], 0, 0]], [], ["loc", [null, [12, 68], [12, 111]]], 0, 0], ["block", "if", [["get", "type.icon", ["loc", [null, [14, 14], [14, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 8], [16, 15]]]], ["inline", "t", [["subexpr", "concat-strings", ["components.object_usage_summary.", ["get", "type.name", ["loc", [null, [17, 69], [17, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 18], [17, 79]]], 0, 0]], [], ["loc", [null, [17, 14], [17, 81]]], 0, 0]],
            locals: ["type", "index"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model", ["loc", [null, [11, 12], [11, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 4], [20, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 4
              },
              "end": {
                "line": 38,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'class');
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["permissionSummary-tabContent permissionSummary-tabContent--", ["get", "tabContent.name", ["loc", [null, [28, 79], [28, 94]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["subexpr", "to-s", [["get", "selectedTab", ["loc", [null, [28, 112], [28, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 106], [28, 124]]], 0, 0], ["subexpr", "to-s", [["get", "tabIndex", ["loc", [null, [28, 131], [28, 139]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 125], [28, 140]]], 0, 0]], [], ["loc", [null, [28, 102], [28, 141]]], 0, 0], "", "permissionSummary-tabContent--hidden"], [], ["loc", [null, [28, 97], [28, 185]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["permissionSummary-tabContent permissionSummary-tabContent--", ["get", "tabContent.name", ["loc", [null, [30, 81], [30, 96]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "manage-resources/object-usage-summary/tab-content", [], ["model", ["subexpr", "@mut", [["get", "tabContent.values", ["loc", [null, [32, 16], [32, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "objectName", ["subexpr", "@mut", [["get", "objectName", ["loc", [null, [33, 21], [33, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "objectId", ["subexpr", "@mut", [["get", "objectId", ["loc", [null, [34, 19], [34, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 10], [35, 12]]], 0, 0]],
          locals: ["tabIndex", "tabContent"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-tabs");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-content");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          return morphs;
        },
        statements: [["block", "unless", [["get", "hideTabs", ["loc", [null, [10, 12], [10, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 2], [21, 13]]]], ["block", "each-in", [["get", "model", ["loc", [null, [26, 15], [26, 20]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [26, 4], [38, 16]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 2
              },
              "end": {
                "line": 46,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", [["get", "close", ["loc", [null, [45, 21], [45, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 12], [45, 28]]], 0, 0], ["inline", "t", ["general.close"], [], ["loc", [null, [45, 78], [45, 99]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-footer", [], [], 0, null, ["loc", [null, [44, 2], [46, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "hideHeader", ["loc", [null, [1, 10], [1, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [5, 11]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [7, 0], [41, 18]]]], ["block", "unless", [["get", "hideFooter", ["loc", [null, [43, 10], [43, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [43, 0], [47, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});