define("scientia-resourcebooker/pods/components/resources-list/item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "aria-hidden", "true");
            dom.setAttribute(el1, "class", "resourcesList-item-icon");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element3, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "resource.imageUri", ["loc", [null, [4, 67], [4, 84]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "resourcesList-item-infos");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "resourcesList-item-small");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "resourcesList-item-name");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 3]), 0, 0);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "resource.imageUri", ["loc", [null, [3, 10], [3, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 11]]]], ["content", "resource.name", ["loc", [null, [8, 44], [8, 61]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 8
                },
                "end": {
                  "line": 30,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "rb-dropdown-content-list-item");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "rb-dropdown-content-list-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("strong");
              dom.setAttribute(el2, "class", "rb-dropdown-content-list-value");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["pods.logged_in.resource.show.description"], [], ["loc", [null, [27, 57], [27, 105]]], 0, 0], ["inline", "markdown-to-html", [["get", "resource.description", ["loc", [null, [28, 78], [28, 98]]], 0, 0, 0, 0]], ["tagName", "", "simplifiedAutoLink", true, "openLinksInNewWindow", true], ["loc", [null, [28, 59], [28, 161]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 16
                    },
                    "end": {
                      "line": 39,
                      "column": 16
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("strong");
                  dom.setAttribute(el1, "class", "rb-dropdown-content-list-value");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "markdown-to-html", [["get", "property.value", ["loc", [null, [38, 84], [38, 98]]], 0, 0, 0, 0]], ["tagName", "", "simplifiedAutoLink", true, "openLinksInNewWindow", true], ["loc", [null, [38, 65], [38, 161]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 16
                    },
                    "end": {
                      "line": 41,
                      "column": 16
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("strong");
                  dom.setAttribute(el1, "class", "rb-dropdown-content-list-value");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "formatted-property-value", [["get", "property.value", ["loc", [null, [40, 92], [40, 106]]], 0, 0, 0, 0], ["get", "property.valueType", ["loc", [null, [40, 107], [40, 125]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 65], [40, 127]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 10
                  },
                  "end": {
                    "line": 43,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "role", "listitem");
                dom.setAttribute(el1, "tabindex", "0");
                dom.setAttribute(el1, "class", "rb-dropdown-content-list-item");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "rb-dropdown-content-list-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element0, 3, 3);
                return morphs;
              },
              statements: [["content", "property.computedDisplayName", ["loc", [null, [36, 61], [36, 93]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "property.valueType", ["loc", [null, [37, 26], [37, 44]]], 0, 0, 0, 0], "text"], [], ["loc", [null, [37, 22], [37, 52]]], 0, 0]], [], 0, 1, ["loc", [null, [37, 16], [41, 23]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 8
                },
                "end": {
                  "line": 45,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "unless", [["subexpr", "and", [["subexpr", "or", [["subexpr", "eq", [["get", "property.valueType", ["loc", [null, [34, 33], [34, 51]]], 0, 0, 0, 0], "text"], [], ["loc", [null, [34, 29], [34, 59]]], 0, 0], ["subexpr", "eq", [["get", "property.valueType", ["loc", [null, [34, 64], [34, 82]]], 0, 0, 0, 0], "email"], [], ["loc", [null, [34, 60], [34, 91]]], 0, 0]], [], ["loc", [null, [34, 25], [34, 93]]], 0, 0], ["subexpr", "not", [["get", "property.value", ["loc", [null, [34, 99], [34, 113]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 94], [34, 114]]], 0, 0]], [], ["loc", [null, [34, 20], [34, 115]]], 0, 0]], [], 0, null, ["loc", [null, [34, 10], [43, 23]]]]],
            locals: ["property"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "role", "list");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "rb-dropdown-content-list at-resourceList-properties");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'data-focus-id');
            morphs[1] = dom.createAttrMorph(element2, 'aria-label');
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            morphs[3] = dom.createMorphAt(element2, 2, 2);
            return morphs;
          },
          statements: [["attribute", "data-focus-id", ["concat", ["resource-property-list-", ["get", "resource.id", ["loc", [null, [24, 75], [24, 86]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["pods.logged_in.resource.show.list_resource_properties"], [], ["loc", [null, [24, 102], [24, 163]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "resource.description", ["loc", [null, [25, 14], [25, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 8], [30, 15]]]], ["block", "each", [["get", "properties", ["loc", [null, [31, 16], [31, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [31, 8], [45, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "rb-dropdown-content-empty");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.resource.show.no_properties"], [], ["loc", [null, [48, 45], [48, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "resourcesList-item-properties-container");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasProperties", ["loc", [null, [23, 10], [23, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 4], [49, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-list/item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "resourcesList-item-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["logged-in.resource.show", ["get", "bookingType.id", ["loc", [null, [2, 39], [2, 53]]], 0, 0, 0, 0], ["get", "resource.id", ["loc", [null, [2, 54], [2, 65]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "firstDay", ["loc", [null, [2, 85], [2, 93]]], 0, 0, 0, 0], "toDate", ["get", "lastDay", ["loc", [null, [2, 101], [2, 108]]], 0, 0, 0, 0], "fromTime", ["get", "fromTime", ["loc", [null, [2, 118], [2, 126]]], 0, 0, 0, 0], "toTime", ["get", "toTime", ["loc", [null, [2, 134], [2, 140]]], 0, 0, 0, 0], "bookingId", ["get", "bookingId", ["loc", [null, [2, 151], [2, 160]]], 0, 0, 0, 0], "editFromPage", ["get", "editOrigin", ["loc", [null, [2, 174], [2, 184]]], 0, 0, 0, 0], "editedPrimary", ["get", "editingPrimary", ["loc", [null, [2, 199], [2, 213]]], 0, 0, 0, 0]], ["loc", [null, [2, 66], [2, 214]]], 0, 0]], ["class", "resourcesList-item-link"], 0, null, ["loc", [null, [2, 2], [10, 14]]]], ["inline", "resources-list/item/show-properties-button", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [13, 13], [13, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingTypeId", ["subexpr", "@mut", [["get", "bookingType.id", ["loc", [null, [14, 18], [14, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "properties", ["subexpr", "@mut", [["get", "properties", ["loc", [null, [15, 15], [15, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "propertiesLoaded", ["subexpr", "@mut", [["get", "propertiesLoaded", ["loc", [null, [16, 21], [16, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "groupId", ["subexpr", "@mut", [["get", "groupId", ["loc", [null, [17, 12], [17, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "showProperties", ["subexpr", "@mut", [["get", "showProperties", ["loc", [null, [18, 19], [18, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 2], [18, 35]]], 0, 0], ["block", "if", [["get", "showPropertiesContainer", ["loc", [null, [21, 6], [21, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [21, 0], [51, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});