define('scientia-resourcebooker/pods/manage-resources/user-group/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsPageChanger['default'], {
    currentUser: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    taskProgressModalDisplayer: _ember['default'].inject.service(),
    userGroupPersister: _ember['default'].inject.service(),

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.user_group.index.user_groups'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: ['page', 'perPage', 'query', { selectedTab: 'section' }],

    page: 1,
    perPage: 10,
    query: '',
    boundQuery: _ember['default'].computed.oneWay('query'),
    userGroup: null,
    confirmTitle: null,
    confirmMessage: null,
    showConfirmDeleteUserGroup: false,

    selectedTab: 0,

    isAdmin: _ember['default'].computed('currentUser.user', function () {
      return this.get('currentUser').isAdmin();
    }),

    systemGroups: _ember['default'].computed('model.systemGroups', function () {
      var hideResourceOwners = this.get('systemSettings').getSetting('hidePermissionsVersion3');
      if (hideResourceOwners) {
        return this.get('model.systemGroups').rejectBy('id', _scientiaResourcebookerConfigEnvironment['default'].IDS.USER_GROUP_RESOURCE_OWNER);
      } else {
        return this.get('model.systemGroups');
      }
    }),

    // shouldShowPaging: Ember.computed.gt('model.userGroups.meta.totalPages', 1),
    shouldShowPaging: true,

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.set('page', 1);
      this.set('query', this.get('boundQuery'));
    },

    actions: {

      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 500, false);
      },

      deleteUserGroup: function deleteUserGroup(userGroup) {
        this.set('showConfirmDeleteUserGroup', true);
        this.set('userGroup', userGroup);
      },

      confirmDeleteUserGroup: function confirmDeleteUserGroup() {
        var _this = this;

        var forceProgress = true;
        if (forceProgress || this.get('userGroup.claims.length') >= 300) {
          return this._deleteWithProgress();
        }

        this.get('userGroup').destroyRecord().then(function () {
          return _this.deleteSuccessFlash('user-group');
        })['catch'](function () {
          return _this.deleteErrorFlash('user-group');
        })['finally'](function () {
          _this.set('showConfirmDeleteUserGroup', false);
          _this.set('userGroup', null);
          _this.set('confirmMessage', null);
          _this.set('confirmTitle', null);
        });
      }
    },

    _deleteWithProgress: function _deleteWithProgress() {
      var _this2 = this;

      this.get('userGroupPersister').getDeleteTaskKey(this.get('userGroup.id')).then(function (taskKey) {
        _this2.get('taskProgressModalDisplayer').show(taskKey).then(function () {
          return _this2.deleteSuccessFlash('user-group');
        })['catch'](function () {
          return _this2.deleteErrorFlash('user-group');
        })['finally'](function () {
          _this2.set('showConfirmDeleteUserGroup', false);
          _this2.set('userGroup', null);
          _this2.set('confirmMessage', null);
          _this2.set('confirmTitle', null);
          _this2.send('refreshModel');
        });
      });
    }
  });
});