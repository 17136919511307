define('scientia-resourcebooker/services/global-confirmer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    modalData: null,

    confirm: function confirm(message) {
      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (window.confirm(message)) resolve();
      });
    },

    confirmModal: function confirmModal(data) {
      this.set('modalData', data);
    },

    close: function close() {
      this.set('modalData', null);
    }

  });
});