define('scientia-resourcebooker/pods/manage-resources/resource/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource.new.page_title');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    model: function model(params, transition) {
      return _ember['default'].RSVP.hash({
        resourceType: this.store.find('resource-type', transition.params['manage-resources.resource'].resourceTypeId),
        resource: this.store.createRecord('resource', {
          resourceTypeId: transition.params['manage-resources.resource'].resourceTypeId
        })
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: '',
          isSaving: false
        });
      }
    },

    actions: {
      createResource: function createResource(model) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.resource.new');

        var name = model.get('name') || '';
        if (_ember['default'].isEmpty(name.trim())) {
          controller.set('error', this.get('i18n').t('error_messages.resource_noname'));
          return;
        }

        controller.set('isSaving', true);
        model.save().then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('resource');
          return _this.transitionTo('manage-resources.resource.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('resource');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      }
    }
  });
});