define('scientia-resourcebooker/adapters/resource', ['exports', 'scientia-resourcebooker/adapters/application', 'scientia-resourcebooker/config/environment'], function (exports, _scientiaResourcebookerAdaptersApplication, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var suffix = this._getSuffix(snapshot.record.get('resourceTypeId'));
      var url = this._buildURL(modelName);
      url = url.replace('/Resources', suffix);
      return url;
    },

    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var suffix = this._getSuffix(snapshot.record.get('resourceTypeId'), id);
      var url = this._buildURL(modelName);
      url = url.replace('/Resources', suffix);
      return url;
    },

    _getSuffix: function _getSuffix(resourceTypeId) {
      var resourceId = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.resourceType.resource.loc(resourceTypeId);
      var suffix = '/' + url;
      if (resourceId) suffix += '/' + resourceId;
      return suffix;
    }
  });
});