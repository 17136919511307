define('scientia-resourcebooker/pods/components/list-items-searcher/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['listItemsSearcher'],
    chosenItems: [],
    defaultItems: [],

    // Ember seems to keep state in components between page transitions
    // We make sure it’s reset here
    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.set('chosenItems', []);
      });
    }).on('init'),

    actions: {
      onSearch: function onSearch(term, deferred) {
        this.sendAction('onSearch', term, deferred);
      },

      onInsertElement: function onInsertElement() {
        this.sendAction('onInsertElement');
      },

      removeItemFromList: function removeItemFromList(item) {
        this.get('chosenItems').removeObject(item);
        this.sendAction('onRemoveItem', item);
      },

      addItemToList: function addItemToList(item) {
        if (item) {
          if (_ember['default'].isArray(item)) item = item[0];

          this.get('chosenItems').pushObject(item);
          this.sendAction('onAddItem', item);
        }
      }
    }
  });
});