define('scientia-resourcebooker/serializers/notification-template', ['exports', 'ember', 'ember-data/serializers/json'], function (exports, _ember, _emberDataSerializersJson) {
  exports['default'] = _emberDataSerializersJson['default'].extend({

    normalize: function normalize(type, payload, prop) {

      var newPayload = this.store.serializerFor('application')._camelizeKeys(payload);

      newPayload.id = newPayload.identity;
      newPayload.toEmailList = !_ember['default'].isEmpty(newPayload.toEmailList) ? newPayload.toEmailList.join(',') : '';
      newPayload.ccEmailList = !_ember['default'].isEmpty(newPayload.ccEmailList) ? newPayload.ccEmailList.join(',') : '';
      newPayload.bccEmailList = !_ember['default'].isEmpty(newPayload.bccEmailList) ? newPayload.bccEmailList.join(',') : '';

      var results = this._super(type, newPayload, prop);
      return results;
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var to = !_ember['default'].isArray(json.toEmailList) ? json.toEmailList.split(',') : json.toEmailList;
      var cc = !_ember['default'].isArray(json.ccEmailList) ? json.ccEmailList.split(',') : json.ccEmailList;
      var bcc = !_ember['default'].isArray(json.bccEmailList) ? json.bccEmailList.split(',') : json.bccEmailList;

      json.toEmailList = to;
      json.ccEmailList = cc;
      json.bccEmailList = bcc;

      return json;
    }
  });
});