define("scientia-resourcebooker/pods/components/resources-grid/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 2
              },
              "end": {
                "line": 24,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "globalSearchResourceClicked.name", ["loc", [null, [23, 4], [23, 40]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 4
                },
                "end": {
                  "line": 34,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "role", "listitem");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesGrid-item-link");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'aria-label');
              morphs[1] = dom.createAttrMorph(element3, 'class');
              morphs[2] = dom.createElementMorph(element3);
              morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["get", "bookingType.name", ["loc", [null, [29, 23], [29, 39]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["resourcesGrid-item resourcesGrid-item--mode-", ["get", "bookingType.mode", ["loc", [null, [29, 170], [29, 186]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "route-action", ["onClickBookingType", ["get", "bookingType", ["loc", [null, [29, 102], [29, 113]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 67], [29, 114]]], 0, 0]], [], ["loc", [null, [29, 58], [29, 116]]], 0, 0], ["inline", "resources-grid/item", [], ["model", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [31, 38], [31, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 10], [31, 51]]], 0, 0]],
            locals: ["bookingType"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "resources-grid-modal-description");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.resources_grid.resource_in_multiple_booking_types"], [], ["loc", [null, [27, 48], [27, 116]]], 0, 0], ["block", "each", [["get", "bookingTypes", ["loc", [null, [28, 12], [28, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [28, 4], [34, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 2
              },
              "end": {
                "line": 41,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["element", "action", [["get", "close", ["loc", [null, [38, 21], [38, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 12], [38, 28]]], 0, 0], ["inline", "t", ["components.resources_grid.cancel"], [], ["loc", [null, [39, 6], [39, 46]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "modal-header", [], ["needsCloseButton", true, "onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [22, 56], [22, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 48], [22, 62]]], 0, 0]], 0, null, ["loc", [null, [22, 2], [24, 19]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [26, 2], [36, 20]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [37, 2], [41, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 4
                },
                "end": {
                  "line": 53,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesGrid-item-link");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["resourcesGrid-item resourcesGrid-item--mode-", ["get", "bookingType.mode", ["loc", [null, [48, 122], [48, 138]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "route-action", ["onClickBookingType", ["get", "bookingType", ["loc", [null, [48, 54], [48, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 19], [48, 66]]], 0, 0]], [], ["loc", [null, [48, 10], [48, 68]]], 0, 0], ["inline", "resources-grid/item", [], ["model", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [50, 38], [50, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [50, 10], [50, 51]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 8
                  },
                  "end": {
                    "line": 57,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "resources-grid/item", [], ["model", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [56, 38], [56, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [56, 10], [56, 51]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 4
                },
                "end": {
                  "line": 59,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["resourcesGrid-item resourcesGrid-item--mode-", ["get", "bookingType.mode", ["loc", [null, [54, 63], [54, 79]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["logged-in.booking-type.show", ["get", "bookingType.id", ["loc", [null, [55, 49], [55, 63]]], 0, 0, 0, 0]], ["class", "resourcesGrid-item-link"], 0, null, ["loc", [null, [55, 8], [57, 20]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 2
              },
              "end": {
                "line": 61,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "bookingType.isDisabled", ["loc", [null, [47, 10], [47, 32]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [47, 4], [59, 11]]]]],
          locals: ["bookingType"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 62,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "bookingTypes", ["loc", [null, [45, 10], [45, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [45, 2], [61, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-grid/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isModal", ["loc", [null, [21, 6], [21, 13]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [21, 0], [62, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});