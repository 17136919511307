define('scientia-resourcebooker/services/availability-pattern-duplicator', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    nestedResourceStore: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    copy: function copy(patternId) {
      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.AVAILABILITY_PATTERN_DUPLICATE.loc(patternId), {});
    }

  });
});