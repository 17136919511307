define('scientia-resourcebooker/mixins/availability-form-route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),
    resourceBusyExceptionsFetcher: _ember['default'].inject.service(),

    resourceUnavailabilityPatternsProcessor: _ember['default'].inject.service(),
    resourceUnavailabilityPreviewFetcher: _ember['default'].inject.service(),

    unavailabilityUpdater: _ember['default'].inject.service(),

    availabilityTabs: { DEFAULT: 0, SPECIFIC: 1, PREVIEW: 2 },

    /**
     * Fetches Week Exceptions
     */
    _fetchBusyExceptions: function _fetchBusyExceptions() {
      var _this = this;

      var con = this.controllerFor(this.routeName);
      var model = this.modelFor(this.routeName);

      con.set('isLoadingBusyExceptions', true);

      return this.get('resourceBusyExceptionsFetcher').fetch({
        type: con.get('type'),
        id: con.get('modelId'),
        parentId: con.get('parentId')
      }).then(function (events) {

        // Make a copy
        var cloned = _this.get('resourceBusyExceptionsFetcher').cloneBusyExceptions(events);

        // Clear existing
        model.busyExceptions.clear();

        _ember['default'].set(model, 'busyExceptions', cloned.slice());
        con.set('busyExceptionsData', cloned);
      })['finally'](function () {
        con.set('isLoadingBusyExceptions', false);
      });
    },

    /**
     * Fetches Linked Patterns
     */
    _fetchLinkedUnavailability: function _fetchLinkedUnavailability() {
      var _this2 = this;

      var con = this.controllerFor(this.routeName);
      var model = this.modelFor(this.routeName);

      this.get('resourceUnavailabilityPatternsProcessor').fetch({
        type: con.get('type'),
        id: con.get('modelId'),
        parentId: con.get('parentId')
      }).then(function (data) {

        var cloned = _this2.get('resourceUnavailabilityPatternsProcessor').clonePatternData(data);
        _ember['default'].set(model, 'linkedAvailability', data.slice());
        con.set('busyPatternsLinkedData', cloned);
      })['catch'](function () {
        //no-op
      })['finally'](function () {
        return con.set('isLoadingLinkedPatterns', false);
      });
    },

    /**
     * Saves pending unavailability
     * @returns Promise The save promise
     */
    _updateUnavailability: function _updateUnavailability() {
      var con = this.controllerFor(this.routeName);

      var pendingPatternSlots = con.get('pendingPatternSlots');
      var pendingDateSlots = con.get('pendingDateSlots');
      if (_ember['default'].isEmpty(pendingPatternSlots) && _ember['default'].isEmpty(pendingDateSlots)) return _ember['default'].RSVP.resolve();

      return this.get('unavailabilityUpdater').update({
        type: con.get('type'),
        id: con.get('modelId'),
        parentId: con.get('parentId'),
        patternSlots: pendingPatternSlots,
        specificDateSlots: pendingDateSlots
      });
    },

    actions: {

      /**
       * Fetches Defaults Unavailability (Busy Patterns)
       * @param {*} userGroupId
       * @param {*} isReloading
       * @returns
       */
      fetchBusyPatterns: function fetchBusyPatterns(userGroupId, isReloading) {
        var _this3 = this;

        var con = this.controllerFor(this.routeName);
        var model = this.modelFor(this.routeName);

        this.send('loading');

        return this.get('resourceBusyPatternsFetcher').fetch({
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          userGroupId: userGroupId
        }).then(function (events) {
          model.eventPatterns.clear();
          _ember['default'].set(model, 'eventPatterns', events.slice());
          if (isReloading) {
            con.set('pendingPatternSlots', []);
          }
        })['catch'](function (e) {
          _this3.flashError(_this3.get('i18n').t('components.manage_resources.availability_form.unavailability_fetch_error'));
          LE.error({ location: 'fetchBusyPatterns', error: e });
        })['finally'](function () {
          _this3.send('finished');
        });
      },

      /**
       * Fetches Specific Date Unavailability (BusyTimes)
       * @param {*} startDate
       * @param {*} endDate
       * @param {*} userGroupId
       * @param {*} isReloading
       * @returns
       */
      fetchBusyTimes: function fetchBusyTimes(startDate, endDate, userGroupId, isReloading) {
        var _this4 = this;

        var con = this.controllerFor(this.routeName);
        var model = this.modelFor(this.routeName);

        this.send('loading');

        return this.get('resourceBusyTimesFetcher').fetch({
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          startDate: startDate,
          endDate: endDate,
          userGroupId: userGroupId
        }).then(function (events) {
          model.events.clear();
          if (isReloading) {
            _this4.controllerFor(_this4.routeName).set('pendingDateSlots', []);
          }
          _ember['default'].set(model, 'events', events.slice());
        })['catch'](function (e) {
          _this4.flashError(_this4.get('i18n').t('components.manage_resources.availability_form.unavailability_fetch_error'));
          LE.error({ location: 'fetchBusyTimes', error: e });
        })['finally'](function () {
          _this4.send('finished');
        });
      },

      /**
       * Fetches events for PREVIEW tab
       * @param {*} startDate
       * @param {*} endDate
       * @param {*} userGroupId
       * @returns
       */
      fetchPreviewEvents: function fetchPreviewEvents(startDate, endDate, userGroupId) {
        var _this5 = this;

        var model = this.modelFor(this.routeName);
        var con = this.controllerFor(this.routeName);

        this.send('loading');

        return this.get('resourceUnavailabilityPreviewFetcher').fetch({
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          startDate: startDate,
          endDate: endDate,
          userGroupId: userGroupId,
          includeDisabledPatternsUnavailability: con.get('includeDisabledPatternsUnavailability')
        }).then(function (events) {
          model.previewEvents.clear();
          _ember['default'].set(model, 'previewEvents', events.slice());
        })['catch'](function (e) {
          _this5.flashError(_this5.get('i18n').t('components.manage_resources.availability_form.unavailability_fetch_error'));
          LE.error({ location: 'fetchPreviewEvents', error: e });
        })['finally'](function () {
          _this5.send('finished');
        });
      },

      /**
       * Updates Week Exceptions
       * @param {*} busyExceptions
       */
      updateBusyExceptions: function updateBusyExceptions(busyExceptions) {
        var _this6 = this;

        var con = this.controllerFor(this.routeName);

        con.set('isLoadingBusyExceptions', true);

        var data = {
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          exceptionDateSlots: busyExceptions
        };

        this.get('unavailabilityUpdater').update(data).then(function () {
          _this6.flashSuccess(_this6.get('i18n').t('components.manage_resources.availability_week_exceptions_modal.save_ok'));
          _this6._fetchBusyExceptions();

          _this6.get('appInsights').trackAvailabilityUpdateExceptions(data);
          _this6.send('reloadAvailabilityPreview');
        })['catch'](function () {
          _this6.flashError(_this6.get('i18n').t('components.manage_resources.availability_week_exceptions_modal.save_error'));
        });
      },

      /**
       * Updates linked Patterns
       * @param {*} patterns
       */
      updateLinkedPatterns: function updateLinkedPatterns(patterns) {
        var _this7 = this;

        var con = this.controllerFor(this.routeName);

        con.set('isLoadingLinkedPatterns', true);

        var data = {
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          patterns: patterns
        };

        this.get('resourceUnavailabilityPatternsProcessor').update(data).then(function () {
          _this7.get('appInsights').trackLinkPattern(data);

          _this7.flashSuccess(_this7.get('i18n').t('components.manage_resources.availability_patterns_modal.link_mode.save_ok'));
          _this7._fetchLinkedUnavailability();
          _this7.send('reloadAvailabilityPreview');
        })['catch'](function () {
          _this7.flashError(_this7.get('i18n').t('components.manage_resources.availability_patterns_modal.link_mode.save_error'));
        })['finally'](function () {
          return con.set('isLoadingLinkedPatterns', false);
        });
      },

      /**
       * Copies unavailability from a linked pattern to the current object's unavailability
       * @param {*} pattern
       * @param {*} replaceAll
       */
      copyAvailabilityPattern: function copyAvailabilityPattern(pattern, replaceAll) {
        var _this8 = this;

        var con = this.controllerFor(this.routeName);

        con.set('isLoadingLinkedPatterns', true);

        var data = {
          type: con.get('type'),
          id: con.get('modelId'),
          parentId: con.get('parentId'),
          pattern: pattern,
          replaceAll: replaceAll
        };

        this.get('resourceUnavailabilityPatternsProcessor').copy(data).then(function () {
          _this8.flashSuccess(_this8.get('i18n').t('components.manage_resources.availability_patterns_modal.link_mode.save_ok'));
          _this8._fetchLinkedUnavailability();
          _this8._fetchBusyExceptions();

          _this8.get('appInsights').trackCopyPattern(data);

          _this8.send('reloadAvailability');
        })['catch'](function () {
          _this8.flashError(_this8.get('i18n').t('components.manage_resources.availability_patterns_modal.link_mode.save_error'));
        })['finally'](function () {
          con.set('isLoadingLinkedPatterns', false);
        });
      },

      /**
       * Cancels all pending Week Exceptions
       */
      revertBusyExceptions: function revertBusyExceptions() {
        var con = this.controllerFor(this.routeName);

        // Create a copy of original/clean week exceptions
        var cloned = this.get('resourceBusyExceptionsFetcher').cloneBusyExceptions(con.get('model.busyExceptions'));

        // Set it as the current data
        con.set('busyExceptionsData', cloned);
      },

      /**
       * Cancels all pending Linked Patterns
       */
      revertLinkedPatterns: function revertLinkedPatterns() {
        var con = this.controllerFor(this.routeName);

        // Create a copy of original/clean linked availability
        var cloned = this.get('resourceUnavailabilityPatternsProcessor').clonePatternData(con.get('model.linkedAvailability'));

        // Set it as the current data
        con.set('busyPatternsLinkedData', cloned);
      },

      /**
       * Saves all pending unavailability
       */
      saveUnavailability: function saveUnavailability() {
        var _this9 = this;

        var con = this.controllerFor(this.routeName);
        con.set('isComittingUnavailability', true);

        this._updateUnavailability().then(function () {
          _this9.flashSuccess(_this9.get('i18n').t('components.manage_resources.availability_form.save_ok'));
          _this9.send('reloadAvailability');
        })['catch'](function () {
          _this9.flashError(_this9.get('i18n').t('components.manage_resources.availability_form.save_error'));
        })['finally'](function () {
          con.set('isComittingUnavailability', false);
        });
      },

      /**
       * Reloads availability based on what tab you are currently on
       * @returns
       */
      reloadAvailability: function reloadAvailability() {

        var con = this.controllerFor(this.routeName);

        con.initWeekdaySlotsArray();
        con.initDateSlotsArray();

        // Fetch Defaults
        if (con.get('availabilityTab') === this.availabilityTabs.DEFAULT) {
          this.send('fetchBusyPatterns', null, true);
        }

        var startDate = con.get('startDate');
        var endDate = con.get('endDate');
        if (!startDate || !endDate) return;

        // Fetch Specific
        if (con.get('availabilityTab') === this.availabilityTabs.SPECIFIC) {
          this.send('fetchBusyTimes', startDate, endDate, null, true);
        }

        // Fetch Preview
        if (con.get('availabilityTab') === this.availabilityTabs.PREVIEW) {
          this.send('reloadAvailabilityPreview', startDate, endDate);
        }
      },

      /**
       * Reloads Preview Events
       */
      reloadAvailabilityPreview: function reloadAvailabilityPreview() {
        var con = this.controllerFor(this.routeName);
        var startDate = con.get('startDate');
        var endDate = con.get('endDate');

        if (con.get('availabilityTab') === this.availabilityTabs.PREVIEW) {
          this.send('fetchPreviewEvents', startDate, endDate, null);
        }
      }
    },

    resetAvailabilityProperties: function resetAvailabilityProperties() {
      var con = this.controllerFor(this.routeName);
      con.initWeekdaySlotsArray();
      con.initDateSlotsArray();

      con.setProperties({
        busyExceptionsData: null,
        busyPatternsLinkedData: null
      });
    }

  });
});