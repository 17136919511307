define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/component', ['exports', 'ember'], function (exports, _ember) {

  var FILE_TYPES = ['Image', 'Word', 'Excel', 'Pdf', 'Text', 'Archive', 'Custom'];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item-group'],

    systemSettings: _ember['default'].inject.service(),

    attachmentTypesAvailable: (function () {
      var storageTypes = this.get('systemSettings').getSetting('attachmentFileTypes');
      var types = this.get('i18n').t('components.manage_resources.booking_form_field.attachment.types').toString().split(',');

      return types.map(function (fileType, i) {
        var typeKey = FILE_TYPES[i].toLowerCase();
        var extensions = i === 6 ? '' : storageTypes[typeKey].replace(/ /g, '').replace(/,/g, ', ');

        var fileTypeLabel = i === 6 ? fileType : fileType + ' (' + extensions + '}';
        return { id: i, value: i, label: fileTypeLabel };
      });
    }).property('i18n.locale'),

    actions: {

      toggleAttachmentTypeOption: function toggleAttachmentTypeOption(option) {
        var selected = this.get('model.attachmentTypes') || [];
        if (selected.includes(option.value)) {
          var _newSelected = selected.toArray();
          _newSelected.removeAt(_newSelected.indexOf(option.value));
          this.set('model.attachmentTypes', _newSelected);
        } else {
          var newSelected = selected.toArray();
          newSelected.push(option.value);
          this.set('model.attachmentTypes', newSelected);
        }
      }
    }
  });
});