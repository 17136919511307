define('scientia-resourcebooker/models/booking-form-field-url', ['exports', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'ember-cp-validations'], function (exports, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    text: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 1 })],
    url: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?.*/,
      type: 'url'
    })]

  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, {
    text: (0, _emberDataAttr['default'])('string'),
    url: (0, _emberDataAttr['default'])('string')
  });
});