define('scientia-resourcebooker/models/booking-form-field-textbox', ['exports', 'ember', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'ember-cp-validations'], function (exports, _ember, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    maxCharacterLimit: [(0, _emberCpValidations.validator)('number', {
      integer: false,
      allowString: true,
      gte: 1,
      disabled: _ember['default'].computed(function () {
        return !this.get('model.isCharacterLimited');
      })
    })]
  });

  var ValidationsUser = (0, _emberCpValidations.buildValidations)({

    value: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: _ember['default'].computed(function () {
          return this.get('model.isManaging') || !this.get('model.isMandatory') || !this.get('model.isActive');
        })
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        disabled: _ember['default'].computed(function () {
          return this.get('model.isManaging') || !this.get('model.isMandatory') || !this.get('model.isActive');
        })
      }), (0, _emberCpValidations.validator)('length', {
        max: _ember['default'].computed.alias('model.maxCharacterLimit'),
        disabled: _ember['default'].computed(function () {
          return this.get('model.isManaging') || !this.get('model.maxCharacterLimit') || !this.get('model.isCharacterLimited') || !this.get('model.isActive');
        })
      })]
    }

  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, ValidationsUser, {
    maxCharacterLimit: (0, _emberDataAttr['default'])('number'),
    isCharacterLimited: (0, _emberDataAttr['default'])('boolean'),
    isMultiLine: (0, _emberDataAttr['default'])('boolean')
  });
});