define('scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-results/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkImporterPreview bulkImporterResults'],
    taskProgressResults: null,
    showErrorsOnCompletion: false,

    // Some of the generic messages (non type dependent) should use translations from the component itself
    // so that we don't need to translate them for every type (claims, resource permissions etc)
    // For that we set those generic translations in the component translation path itself
    genericLocalePrefix: 'components.manage_resources.bulk_importer.',

    uploadPercentage: _ember['default'].computed('taskProgressResults.percentageCompleted', function () {
      return this.get('taskProgressResults.percentageCompleted') || 0;
    }),

    uploadPercentageSafeString: _ember['default'].computed('uploadPercentage', function () {
      if (!this.get('uploadPercentage')) return 0;
      var percentage = parseInt(this.get('uploadPercentage'), 10);
      return _ember['default'].String.htmlSafe(percentage);
    }),

    uploadPercentageCSS: _ember['default'].computed('uploadPercentage', function () {
      var percentage = parseInt(this.get('uploadPercentage'), 10);
      return _ember['default'].String.htmlSafe('width: ' + percentage + '%');
    }),

    actions: {
      print: function print() {
        window.print();
      }
    }

  });
});