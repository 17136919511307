define("scientia-resourcebooker/pods/components/resource-filters/singleselection/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/singleselection/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "resourceFilters-helpText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "property.helpText", ["loc", [null, [5, 56], [5, 77]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 10
              },
              "end": {
                "line": 24,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-filters/singleselection/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "class", "button button--link button--icon");
            var el2 = dom.createTextNode("✕");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'aria-label');
            morphs[1] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.resource_filters.clear_selection"], [], ["loc", [null, [23, 164], [23, 215]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "optionChanged", ["loc", [null, [23, 37], [23, 50]]], 0, 0, 0, 0], ["get", "identity", ["loc", [null, [23, 51], [23, 59]]], 0, 0, 0, 0], null, ["get", "property.computedDisplayName", ["loc", [null, [23, 65], [23, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 29], [23, 94]]], 0, 0]], [], ["loc", [null, [23, 20], [23, 96]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 4
            },
            "end": {
              "line": 28,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/singleselection/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "role", "listitem");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "resourceFilters-label resourceFilters-label--inlineItems at-discrete");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "resourceFilters-labelText");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element1, 5, 5);
          return morphs;
        },
        statements: [["inline", "resource-filters/singleselection/item", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [14, 17], [14, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "@mut", [["get", "selected.value", ["loc", [null, [15, 18], [15, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "optionChanged", ["subexpr", "action", [["get", "optionChanged", ["loc", [null, [16, 32], [16, 45]]], 0, 0, 0, 0], ["get", "identity", ["loc", [null, [16, 46], [16, 54]]], 0, 0, 0, 0], ["get", "choice.name", ["loc", [null, [16, 55], [16, 66]]], 0, 0, 0, 0], ["get", "property.computedDisplayName", ["loc", [null, [16, 67], [16, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 24], [16, 96]]], 0, 0], "class", "resourceFilters-checkbox"], ["loc", [null, [13, 10], [18, 12]]], 0, 0], ["content", "choice.name", ["loc", [null, [19, 50], [19, 65]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "selected.value", ["loc", [null, [22, 20], [22, 34]]], 0, 0, 0, 0], ["get", "choice.name", ["loc", [null, [22, 35], [22, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 16], [22, 47]]], 0, 0]], [], 0, null, ["loc", [null, [22, 10], [24, 17]]]]],
        locals: ["choice"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-filters/singleselection/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "resourceFilters-subtitle");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "resourceFilters-fields");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "role", "list");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createAttrMorph(element3, 'aria-label');
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        return morphs;
      },
      statements: [["content", "property.computedDisplayName", ["loc", [null, [1, 50], [1, 82]]], 0, 0, 0, 0], ["block", "if", [["get", "property.helpText", ["loc", [null, [4, 8], [4, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [6, 9]]]], ["attribute", "aria-label", ["concat", [["get", "property.computedDisplayName", ["loc", [null, [8, 45], [8, 73]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "property.valueChoicesWithId", ["loc", [null, [10, 12], [10, 39]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 4], [28, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});