define('scientia-resourcebooker/serializers/booking-form-field-single-choice', ['exports', 'ember-data/serializers/json', 'ember-data'], function (exports, _emberDataSerializersJson, _emberData) {
  exports['default'] = _emberDataSerializersJson['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      items: { embedded: 'always' }
    },

    normalize: function normalize() /*type, payload, prop*/{
      this._super.apply(this, arguments);
    },

    serialize: function serialize() {
      var _store$serializerFor;

      return (_store$serializerFor = this.store.serializerFor('booking-form-field')).serialize.apply(_store$serializerFor, arguments);
    }

  });
});