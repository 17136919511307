define('scientia-resourcebooker/services/print-handler', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    router: _ember['default'].inject.service(),

    // printType: booking, calendar
    // viewType: agenda, month, week, day
    // from: my-bookings, manage-resources..
    handle: function handle(_ref) {
      var printType = _ref.printType;
      var viewType = _ref.viewType;
      var model = _ref.model;

      if (printType === 'booking') this.handleBookingPrint(model);
      if (printType === 'calendar') this.handleCalendarPrint(model, viewType);
      if (printType === 'report') this.handleReportPrint(model);
    },

    handleCalendarPrint: function handleCalendarPrint(_ref2, viewType) {
      var startRange = _ref2.startRange;
      var endRange = _ref2.endRange;
      var from = _ref2.from;

      var isAgenda = viewType === 'agenda';
      var isHidingPastEvents = isAgenda && _ember['default'].$('.chronos-agendaView-header-pastEventsToggler').attr('data-show-previous-days') === 'false';
      if (isHidingPastEvents) startRange = moment().startOf('day');

      if (viewType === 'month') {
        startRange.add(10, 'days'); // Let's make sure we get out of the first days of the previous month
        startRange.startOf('month');
        endRange = startRange.clone().endOf('month');
      }

      var router = this.get('router');
      var host = router.get('location.location.host');
      var url = router.urlFor('print.calendar', { queryParams: {
          startRange: startRange.format(),
          endRange: endRange.format(),
          from: from,
          viewType: viewType }
      });
      window.open('//' + host + url, '_blank', 'height=800,width=1024');
    },

    handleBookingPrint: function handleBookingPrint(model) {
      var router = this.get('router');
      var host = router.get('location.location.host');

      var url = undefined;
      if (model.get('hasRecurrence')) {
        url = router.urlFor('print.booking', model.get('eventId'), { queryParams: { occurrenceId: model.get('id') } });
      } else {
        url = router.urlFor('print.booking', model.get('eventId'));
      }
      window.open('//' + host + url, '_blank', 'height=800,width=1024');
    },

    handleReportPrint: function handleReportPrint(_ref3) {
      var startRange = _ref3.startRange;
      var endRange = _ref3.endRange;
      var from = _ref3.from;
      var id = _ref3.id;
      var filterActiveBookings = _ref3.filterActiveBookings;

      var router = this.get('router');
      var host = router.get('location.location.host');
      var url = router.urlFor('print.report', { queryParams: {
          startRange: startRange.format(),
          endRange: endRange.format(),
          id: id,
          filterActiveBookings: filterActiveBookings,
          from: from }
      });
      window.open('//' + host + url, '_blank', 'height=800,width=1024');
    },

    isPrintRoute: function isPrintRoute() {
      var currentRoute = this.get('router').get('currentRouteName');
      if (currentRoute) return currentRoute.indexOf('print') === 0;
    }
  });
});