define('scientia-resourcebooker/pods/application/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    configFetcher: _ember['default'].inject.service(),
    printHandler: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      return this.get('configFetcher').fetch();
    },

    actions: {
      loading: function loading(transition, originRoute) {
        var controller = this.controllerFor('application');

        if (!transition) {
          controller.set('loading', true);
          return;
        }

        if (transition.targetName === originRoute.routeName) {
          controller.set('loading', true);
          this.router.one('didTransition', function () {
            return controller.set('loading', false);
          });
          _ember['default'].$('.progressLoading').remove();
        }

        return true;
      },

      loadingWithoutTransition: function loadingWithoutTransition() {
        var controller = this.controllerFor('application');
        controller.set('loading', true);
      },

      finished: function finished() {
        this.controllerFor('application').set('loading', false);
      },

      error: function error() {
        this.controllerFor('application').set('loading', false);
        this.flashError(this.get('i18n').t('general.generic_error'));
      },

      // doubleBack: If true means we need to go back 2 pages. This is needed when we enter an edit route after clicking save
      // on the /new route which triggers the extra transition.
      goBack: function goBack() {
        history.go(-1);
      },

      // Work around for ember-route-action throwing an exception that could not find route handler when moving from "booking-type.index to manage-resources.booking-type.index"
      onClickBookingType: function onClickBookingType() {},

      didTransition: function didTransition() {
        _ember['default'].$('.progressLoading').remove();
      },

      onHelpClick: function onHelpClick() {
        this.controller.send('onShowHelpModal');
      },

      onPrintClick: function onPrintClick(_ref) {
        var printType = _ref.printType;
        var model = _ref.model;

        this.get('printHandler').handle({ printType: printType, model: model });
      },

      downloadFile: function downloadFile(url) {
        this.controller.set('downloadFile', url);
      },

      showProgress: function showProgress(taskData) {
        this.controller.setProperties({
          taskKey: taskData.taskKey,
          taskDefer: taskData.deferred
        });
      },

      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        this.controllerFor('logged-in').set('isSidebarOpen', false);
        this.controllerFor('manage-resources').set('isSidebarOpen', false);
        return true;
      }
    }

  });
});