define('scientia-resourcebooker/pods/components/resources-list/filters/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend(_ember['default'].Evented, {
    classNameBindings: [':resourcesList-filters', 'hasNoFilters:resourcesList-filters--inactive'],

    customEvents: _ember['default'].inject.service(),

    customFilters: null,
    duration: null,

    hasNoDefaultFilters: _ember['default'].computed.empty('defaultFilters'),
    hasNoCustomFilters: _ember['default'].computed.empty('customFiltersSelected'),
    hasNoFilters: _ember['default'].computed.and('hasNoDefaultFilters', 'hasNoCustomFilters'),

    customFiltersSelected: [],
    customFiltersFormatted: [],
    hasFilters: _ember['default'].computed.notEmpty('customFiltersSelected'),

    // Listen for custom Event customFiltersSelectedChanged emitted by the controller
    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        // Listen for change events
        var customEvents = this.get('customEvents');
        customEvents.on('customFiltersSelectedChanged', this, 'recheckCustomFiltersSelected');
        customEvents.on('resetFilters', this, 'resetFilters');
        // Make sure we check if there are filters when the components inits for the first time
        this.recheckCustomFiltersSelected();
      });
    }).on('init'),

    resetFilters: function resetFilters() {
      this.recheckCustomFiltersSelected();
    },

    // Generate an array with all the default Filters selected
    defaultFilters: (function () {
      var _this = this;

      var defaultFilters = ['availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration'];
      var filters = [];

      defaultFilters.forEach(function (key) {

        var filterLabel = key.decamelize();
        var filterValue = _this.get(key);

        if (filterValue === true) {
          filterValue = _this.get('i18n').t('components.resources_list.filters.true');
        }

        if (key === 'duration' && filterValue !== null) {
          var label = _this.get('i18n').t('components.resource_filters.duration_items.' + filterValue).toString();
          if (_this.get('availableNow') && _this.get('duration') === '0') label = _this.get('i18n').t('components.resource_filters.available_now_with_all_day');
          filterValue = label;
        }

        if (filterValue) {
          filters.pushObject({
            name: _this.get('i18n').t('components.resources_list.filters.' + filterLabel),
            value: filterValue,
            id: key
          });
        }
      });

      return filters;
    }).property('availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration'),

    // This function is called when the event "customFiltersSelectedChanged" is emitted and generates
    // and array with all the custom filters that are currently selected
    recheckCustomFiltersSelected: function recheckCustomFiltersSelected() {
      var _this2 = this;

      var customFiltersSelected = this.get('customFiltersSelected') || [];
      var customFiltersFormatted = customFiltersSelected.reduce(function (memo, filter) {

        var filterName = _this2._getCustomFilterNameFromSelectedFilterId(filter.identity);
        var filterValue = filter.value;

        // If it's a boolean value make sure we translate it
        if (filterValue === true) {
          filterValue = _this2.get('i18n').t('components.resources_list.filters.true').toString();
        }

        if (_ember['default'].typeOf(filterValue) === 'object') {
          var keys = Object.keys(filterValue);
          keys.forEach(function (key) {
            var newFilter = {
              identity: filter.identity,
              name: filterName + ('(' + key + ')'),
              key: key,
              value: filterValue[key]
            };
            memo.pushObject(newFilter);
          });
        } else if (_ember['default'].isArray(filterValue)) {
          filterValue.forEach(function (singleValue) {
            var newFilter = {
              identity: filter.identity,
              name: filterName,
              value: singleValue
            };
            memo.pushObject(newFilter);
          });
        } else {
          memo.pushObject({
            identity: filter.identity,
            name: filterName,
            value: filterValue
          });
        }

        return memo;
      }, []);

      // Save the array to the property
      if (this.get('isDestroyed')) return;
      this.set('customFiltersFormatted', customFiltersFormatted);
    },

    _getCustomFilterNameFromSelectedFilterId: function _getCustomFilterNameFromSelectedFilterId(filterId) {
      var customFilters = this.get('customFilters');
      var filter = customFilters.findBy('identity', filterId);
      return filter.get('computedDisplayName') || false;
    },

    actions: {
      removeDefaultFilter: function removeDefaultFilter(filter) {
        this.sendAction('removeDefaultFilter', filter);
      },

      removeCustomFilter: function removeCustomFilter(filter) {
        this.sendAction('removeCustomFilter', filter);
      }
    }
  });
});