define('scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {

  var LOCALE_PREFIX = 'components.booking_request_edit_restrictions_modal.';

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal bookingRequestEditRestrictionsModal'],

    model: null, // {restrictions, deferred, isOccurrence} an Ember.RSVP.defer() object / promise

    hasRestrictions: _ember['default'].computed.notEmpty('model.restrictions'),
    hasMultipleOtherRestrictions: _ember['default'].computed('model.restrictions.@each.messageKey', function () {
      return this.get('model.restrictions').filterBy('messageKey', 'RR_Other').get('length') > 1;
    }),

    // Whether the booking can still be edited. It means that any restrictions that exist will need to have allowEdit=true
    canEdit: _ember['default'].computed('model.restrictions.@each.allowEdit', 'model.isOccurrence', function () {

      if (!this.get('model') || !this.get('model.restrictions')) return false;

      // If it's an occurrence edit and there are restrictions do not allow edit
      if (this.get('model.restrictions.length') && this.get('model.isOccurrence')) return false;

      // canEdit only if all restrictions are allowEdit=true
      return this.get('model.restrictions').isEvery('allowEdit');
    }),

    modalTitle: _ember['default'].computed('model.restrictions.@each.messageKey', function () {
      return this.get('i18n').t(LOCALE_PREFIX + _ember['default'].String.underscore(this.get('model.restrictions.firstObject.messageKey') + '_title'));
    }),

    actions: {
      confirm: function confirm() {
        this.get('model.deferred').resolve();
      },

      cancel: function cancel() {
        this.get('model.deferred').reject();
      }
    }
  });
});