define('scientia-resourcebooker/pods/components/manage-resources/user-group-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'component.manage_resources.user_group_form';

  exports['default'] = _ember['default'].Component.extend({
    item: null,
    submitAction: null,
    localePrefix: DEFAULT_LOCALE_PREFIX,
    isSystemGroup: false,
    currentUser: _ember['default'].inject.service(),

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property()
  });
});