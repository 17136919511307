define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/booking-form-field-single-choice/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item manageResources-itemForm-inputList at-bookingForm'],
    displayReadOnly: false,
    defaultValue: _ember['default'].computed.oneWay('model.defaultValue'),

    defaultItem: _ember['default'].computed('model.defaultValue', function () {
      if (this.get('model.defaultValue') && !_ember['default'].isEmpty(this.get('model.items'))) {
        var selected = this.get('model.items').findBy('value', this.get('model.defaultValue'));
        return selected;
      }
    })
  });
});