define('scientia-resourcebooker/services/bulk-importer', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var TASK_PROGRESS = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.TASK_PROGRESS_URL;

  var CLAIMS_PREVIEW = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CLAIMS.PREVIEW;
  var CLAIMS_PREVIEW_DATA = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CLAIMS.PREVIEW_DATA;
  var CLAIMS_CONFIRM = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CLAIMS.CONFIRM;

  var RESOURCES_PREVIEW = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.RESOURCES.PREVIEW;
  var RESOURCES_PREVIEW_DATA = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.RESOURCES.PREVIEW_DATA;
  var RESOURCES_CONFIRM = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.RESOURCES.CONFIRM;

  var FIELDS_PREVIEW = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CUSTOM_FIELDS.PREVIEW;
  var FIELDS_PREVIEW_DATA = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CUSTOM_FIELDS.PREVIEW_DATA;
  var FIELDS_CONFIRM = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.CUSTOM_FIELDS.CONFIRM;

  var BOOKINGS_PREVIEW = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.BOOKINGS.PREVIEW;
  var BOOKINGS_PREVIEW_DATA = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.BOOKINGS.PREVIEW_DATA;
  var BOOKINGS_CONFIRM = _scientiaResourcebookerConfigEnvironment['default'].API.BULK_IMPORT.BOOKINGS.CONFIRM;

  var PREVIEW_URLS = { claims: CLAIMS_PREVIEW, resources: RESOURCES_PREVIEW, customFields: FIELDS_PREVIEW, bookings: BOOKINGS_PREVIEW };
  var PREVIEW_DATA_URLS = { claims: CLAIMS_PREVIEW_DATA, resources: RESOURCES_PREVIEW_DATA, customFields: FIELDS_PREVIEW_DATA, bookings: BOOKINGS_PREVIEW_DATA };
  var CONFIRM_URLS = { claims: CLAIMS_CONFIRM, resources: RESOURCES_CONFIRM, customFields: FIELDS_CONFIRM, bookings: BOOKINGS_CONFIRM };

  var TYPE_NAMES = { claims: 'user-groups', resources: 'resources', customFields: 'custom-fields', bookings: 'bookings' };

  var STEPS = [null, 'preview', 'result'];

  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    getTaskProgress: function getTaskProgress(taskKey) {
      return this.get('authenticatedRequest').fetch(TASK_PROGRESS.loc(taskKey));
    },

    getPreviewURL: function getPreviewURL(_ref) {
      var type = _ref.type;
      var params = _ref.params;

      var previewURL = PREVIEW_URLS[type];
      var endpoint = this._appendQueryParamsToUrl(previewURL, params);

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
      return url;
    },

    getPreviewData: function getPreviewData(_ref2) {
      var type = _ref2.type;
      var taskKey = _ref2.taskKey;

      var url = PREVIEW_DATA_URLS[type].loc(taskKey);
      return this.get('authenticatedRequest').fetch(url);
    },

    confirmTask: function confirmTask(_ref3) {
      var type = _ref3.type;
      var taskKey = _ref3.taskKey;

      var confirmURL = CONFIRM_URLS[type];

      var data = { taskKey: taskKey };

      if (type === 'resources' || type === 'customFields') {
        data.resourceImportType = type === 'resources' ? 0 : 1;
      }

      this.get('session').stopAutoLogout();
      return this.get('authenticatedRequest').post(confirmURL, data);
    },

    log: function log(data) {
      data.step = STEPS[data.step];
      data.type = TYPE_NAMES[data.type];
      this.get('appInsights').trackEvent('bulk-import', data);
    },

    getAjaxSettings: function getAjaxSettings() {
      return {
        headers: {
          'Authorization': 'Bearer ' + this.get('session.authorization.access_token')
        }
      };
    },

    _appendQueryParamsToUrl: function _appendQueryParamsToUrl(url) {
      var queryParams = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      url += '?';
      queryParams.forEach(function (param) {
        if (param.key) {
          url += param.key.camelize() + '=' + param.value + '&';
        }
      });

      url = url.substr(0, url.length - 1);
      return url;
    }

  });
});