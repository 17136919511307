define('scientia-resourcebooker/helpers/formatted-property-value', ['exports', 'ember', 'ember-linkify/helpers/linkify'], function (exports, _ember, _emberLinkifyHelpersLinkify) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2);

      var value = _ref2[0];
      var valueType = _ref2[1];

      switch (valueType) {
        case 'yesno':
          return value ? this.get('i18n').t('helpers.formatted_property_value.yes').toString() : this.get('i18n').t('helpers.formatted_property_value.no').toString();
        case 'singleselection':
          return value ? value.getEach('name').join(', ') : null;
        case 'multipleselections':
          return value ? value.getEach('name').join(', ') : null;
      }

      if (valueType === 'text' && value && value.length) {
        return (0, _emberLinkifyHelpersLinkify.linkify)(value, '_blank');
      } else {
        return value;
      }
    }
  });
});