define('scientia-resourcebooker/models/resource-group-and-resource', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    description: attr('string'),
    imageUri: attr('string'),

    externalApplicationId: attr('string'),

    properties: (0, _modelFragmentsAttributes.fragmentArray)('property', { defaultValue: [] }),
    readPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('read-permission-group', { defaultValue: [] }),
    writePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('write-permission-group', { defaultValue: [] }),

    resourceTypeId: attr('string'),
    resourceTypeName: attr('string'),
    resourceType: belongsTo('resource-type'),

    bookingTypes: hasMany('booking-type'),

    events: hasMany('event')
  });
});