define('scientia-resourcebooker/abilities/resource', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canEdit: _ember['default'].computed.alias('model.canEdit'),

    canEditAll: _ember['default'].computed.alias('model.canEditAll'),
    canEditAvailability: _ember['default'].computed.alias('model.canEditAvailability'),
    canEditCustomFields: _ember['default'].computed.alias('model.canEditCustomFields'),
    canEditPermissions: _ember['default'].computed.alias('model.canEditPermissions'),
    canEditDescription: _ember['default'].computed.alias('model.canEditDescription'),
    canViewCalendar: _ember['default'].computed.alias('model.canViewCalendar'),
    canDelete: _ember['default'].computed.alias('model.canDelete')
  });
});