define('scientia-resourcebooker/pods/manage-resources/resource-type/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.edit.page_title');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        resourceType: this.get('store').queryRecord('resource-type', { id: params.id, adminPermissionsOnly: true })
      });
    },

    afterModel: function afterModel(model) {
      if (!model.resourceType.get('canEdit')) return this.transitionTo('manage-resources.resource-type.index');

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resourceType.get('name'),
        id: model.resourceType.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          name: '',
          properties: [],
          error: '',
          isSaving: false
        });
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.resource-type.edit');
        var properties = model.resourceType.get('properties');
        properties.setEach('isEditing', false);
        if (model.resourceType.get('hasDirtyAttributes')) model.resourceType.rollbackAttributes();
      },

      updateResourceType: function updateResourceType() {
        var _this = this;

        var transition = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var model = this.modelFor('manage-resources.resource-type.edit');
        var controller = this.controllerFor('manage-resources.resource-type.edit');

        // Validate name
        if (_ember['default'].isEmpty(model.resourceType.get('name').trim())) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.name_required').toString(), 0);
        }

        var propertyNames = model.resourceType.get('properties').getEach('name');
        if (propertyNames.includes('')) {
          return this._displayError(this.get('i18n').t('components.manage_resources.resource_type_custom_fields_edit.error_field_empty').toString(), 1);
        }

        var lowerCaseNames = propertyNames.map(function (property) {
          return property.toLowerCase();
        });
        if (lowerCaseNames.length !== lowerCaseNames.uniq().length) {
          return this._displayError(this.get('i18n').t('components.manage_resources.resource_type_custom_fields_edit.error_field_duplicated').toString(), 1);
        }

        // Validate permissions
        var permissions = controller.get('permissions');
        if (!this._validatePermissions(permissions)) return false;
        var permissionsTypes = model.resourceType.get('permissionsKeys');

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, permissionsTypes);
        Object.keys(permissionFragments).forEach(function (type) {
          model.resourceType.get(type).setObjects(permissionFragments[type]);
        });

        controller.set('isSaving', true);
        model.resourceType.save().then(function () {
          _this.editSuccessFlash('resource-type');
          _this.refresh();

          if (transition) {
            return _this.transitionTo('manage-resources.resource-type.index');
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('resource-type');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), 2);
        return false;
      }

      return true;
    },

    _displayError: function _displayError(error, tabIndex) {
      var controller = this.controllerFor('manage-resources.resource-type.edit');
      controller.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    }
  });
});