define('scientia-resourcebooker/validators/max-decimal-places', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {
  var MaxDecimalPlaces = _emberCpValidationsValidatorsBase['default'].extend({
    i18n: _ember['default'].inject.service(),
    validate: function validate(value, options, model /*, attribute*/) {
      if (!value) return true;
      var valueSplit = value.split('.');
      if (valueSplit.length > 1) {
        var decimals = valueSplit[1];
        if (decimals.length > model.get('maxDecimalPlaces')) {
          return this.get('i18n').t('errors.maxDecimalPlaces', { count: model.get('maxDecimalPlaces') }).toString();
        }
      }

      return true;
    }
  });

  MaxDecimalPlaces.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = MaxDecimalPlaces;
});