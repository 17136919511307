define("scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 117
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.title"], [], ["loc", [null, [1, 41], [1, 117]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 4
              },
              "end": {
                "line": 24,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--primary");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'disabled');
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createMorphAt(element3, 0, 0);
            return morphs;
          },
          statements: [["attribute", "disabled", ["subexpr", "or", [["subexpr", "not", [["get", "canAddRange", ["loc", [null, [23, 64], [23, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 59], [23, 76]]], 0, 0], ["subexpr", "not", [["get", "selectedDate", ["loc", [null, [23, 82], [23, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 77], [23, 95]]], 0, 0]], [], ["loc", [null, [null, null], [23, 97]]], 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", ["onRangeAdd"], [], ["loc", [null, [23, 107], [23, 128]]], 0, 0]], [], ["loc", [null, [23, 98], [23, 130]]], 0, 0], ["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.add_range"], [], ["loc", [null, [23, 131], [23, 211]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 8
                },
                "end": {
                  "line": 38,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "badge at-availabilityException--pendingAdd");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.pending_addition"], [], ["loc", [null, [37, 67], [37, 154]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 8
                },
                "end": {
                  "line": 41,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "badge at-availabilityException--pendingRemove");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.pending_deletion"], [], ["loc", [null, [40, 70], [40, 157]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 6
                },
                "end": {
                  "line": 48,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "manageResourcesItemsList-item-link");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'aria-label');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.delete"], [], ["loc", [null, [45, 107], [45, 129]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", ["onRemoveClick", ["get", "e", ["loc", [null, [45, 90], [45, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 66], [45, 92]]], 0, 0]], [], ["loc", [null, [45, 57], [45, 94]]], 0, 0], ["inline", "inline-svg", [["subexpr", "if", [["get", "e.isPendingRemove", ["loc", [null, [46, 27], [46, 44]]], 0, 0, 0, 0], "icons/refresh.svg", "icons/trash.svg"], [], ["loc", [null, [46, 23], [46, 83]]], 0, 0]], ["class", "manageResourcesItemsList-item-actions-icon manageResourcesItemsList-item-actions-icon--delete"], ["loc", [null, [46, 10], [46, 187]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "manageResourcesItemsList-item-label");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" - ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            morphs[3] = dom.createMorphAt(element2, 5, 5);
            morphs[4] = dom.createMorphAt(element2, 6, 6);
            morphs[5] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["manageResourcesItemsList-item ", ["subexpr", "if", [["get", "e.isPendingAdd", ["loc", [null, [33, 50], [33, 64]]], 0, 0, 0, 0], "at-availabilityException--pendingAdd", ""], [], ["loc", [null, [33, 45], [33, 108]]], 0, 0], " ", ["subexpr", "if", [["get", "e.isPendingRemove", ["loc", [null, [33, 114], [33, 131]]], 0, 0, 0, 0], "at-availabilityException--pendingRemove", ""], [], ["loc", [null, [33, 109], [33, 178]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "e.fromTime", ["loc", [null, [35, 26], [35, 36]]], 0, 0, 0, 0]], ["format", ["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [35, 44], [35, 72]]], 0, 0]], ["loc", [null, [35, 8], [35, 74]]], 0, 0], ["inline", "datetime-format", [["get", "e.toTime", ["loc", [null, [35, 95], [35, 103]]], 0, 0, 0, 0]], ["format", ["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [35, 111], [35, 139]]], 0, 0]], ["loc", [null, [35, 77], [35, 141]]], 0, 0], ["block", "if", [["get", "e.isPendingAdd", ["loc", [null, [36, 14], [36, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [36, 8], [38, 15]]]], ["block", "if", [["get", "e.isPendingRemove", ["loc", [null, [39, 14], [39, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [39, 8], [41, 15]]]], ["block", "unless", [["get", "disabled", ["loc", [null, [44, 16], [44, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [44, 6], [48, 17]]]]],
          locals: ["e"],
          templates: [child0, child1, child2]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 4
              },
              "end": {
                "line": 54,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "availabilityWeekExceptions-note");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.no_exceptions"], [], ["loc", [null, [53, 53], [53, 137]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "availabilityWeekExceptions-content");
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "availabilityWeekExceptions-description");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "weekExceptions-list");
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          dom.setAttribute(el3, "class", "availabilityWeekExceptions-sectionTitle");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [7]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          morphs[2] = dom.createMorphAt(element4, 5, 5);
          morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.description"], [], ["loc", [null, [6, 52], [6, 134]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "selectedDate", ["loc", [null, [9, 9], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [11, 17], [11, 45]]], 0, 0]], [], ["loc", [null, [11, 11], [11, 46]]], 0, 0], "updateDate", ["subexpr", "action", ["updateSelectedDate"], [], ["loc", [null, [12, 15], [12, 44]]], 0, 0], "viewUpdate", ["subexpr", "action", ["onUpdateCalendarView"], [], ["loc", [null, [13, 15], [13, 46]]], 0, 0], "readonly", true, "inline", true, "keepOpen", true, "position", "left,auto", "class", ["subexpr", "concat-strings", ["at-unavailabilityWeekExceptions", ["subexpr", "if", [["get", "disabled", ["loc", [null, [18, 64], [18, 72]]], 0, 0, 0, 0], " at-unavailabilityWeekExceptions--disabled", ""], [], ["loc", [null, [18, 60], [18, 121]]], 0, 0]], [], ["loc", [null, [18, 10], [18, 122]]], 0, 0], "allowInputToggle", false], ["loc", [null, [8, 4], [20, 6]]], 0, 0], ["block", "unless", [["get", "disabled", ["loc", [null, [22, 14], [22, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 4], [24, 15]]]], ["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.assigned_exceptions"], [], ["loc", [null, [28, 52], [28, 142]]], 0, 0], ["block", "each", [["get", "model", ["loc", [null, [31, 12], [31, 17]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [31, 4], [54, 13]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 4
                },
                "end": {
                  "line": 66,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["general.cancel"], [], ["loc", [null, [65, 6], [65, 28]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 2
              },
              "end": {
                "line": 69,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "async-button", [], ["disableButton", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [64, 34], [64, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["cancel"], [], ["loc", [null, [64, 51], [64, 68]]], 0, 0], "class", "button button--white at-lf-dialog-footer"], 0, null, ["loc", [null, [64, 4], [66, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 4
                },
                "end": {
                  "line": 72,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["general.close"], [], ["loc", [null, [71, 6], [71, 27]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 2
              },
              "end": {
                "line": 74,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "async-button", [], ["disableButton", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [70, 34], [70, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", [["get", "close", ["loc", [null, [70, 59], [70, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 51], [70, 65]]], 0, 0], "class", "button button--white at-lf-dialog-footer"], 0, null, ["loc", [null, [70, 4], [72, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 2
                },
                "end": {
                  "line": 79,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["general.save"], [], ["loc", [null, [78, 4], [78, 24]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 2
              },
              "end": {
                "line": 80,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "async-button", [], ["disableButton", ["subexpr", "not", [["get", "hasPendingChanges", ["loc", [null, [77, 37], [77, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 32], [77, 55]]], 0, 0], "loading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [77, 64], [77, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["save"], [], ["loc", [null, [77, 81], [77, 96]]], 0, 0], "class", "button button--primary at-lf-dialog-footer"], 0, null, ["loc", [null, [77, 2], [79, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 0
            },
            "end": {
              "line": 82,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasPendingChanges", ["loc", [null, [62, 8], [62, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [62, 2], [74, 9]]]], ["block", "unless", [["get", "disabled", ["loc", [null, [76, 12], [76, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [76, 2], [80, 13]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 83,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["needsCloseButton", false], 0, null, ["loc", [null, [1, 0], [1, 134]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [2, 0], [59, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [61, 0], [82, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});