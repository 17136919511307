define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-form/component', ['exports', 'ember', 'scientia-resourcebooker/pods/components/manage-resources/resource-form/component'], function (exports, _ember, _scientiaResourcebookerPodsComponentsManageResourcesResourceFormComponent) {
  var getOwner = _ember['default'].getOwner;

  var COMPONENT_PATH = 'manage-resources/resource-custom-fields-inputs/%@';

  exports['default'] = _scientiaResourcebookerPodsComponentsManageResourcesResourceFormComponent['default'].extend({

    actions: {
      componentExists: function componentExists(valueType) {
        var componentPath = COMPONENT_PATH.loc(valueType);
        return getOwner(this).lookup('component:' + componentPath);
      }
    }
  });
});