define('scientia-resourcebooker/pods/components/manage-resources/item-types-list/component', ['exports', 'ember', 'ember-can', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _emberCan, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend(_emberCan.CanMixin, {
    tagName: 'ul',
    classNames: ['manageResourcesItemsList'],
    classNameBindings: ['location'],
    location: '',

    systemSettings: _ember['default'].inject.service(),

    iconUri: 'icons/tag.svg',
    items: [],
    showItemPath: '',
    editItemPath: '',
    hideShowAction: false,
    hideDeleteAction: false,
    hideEditAction: false,
    showDuplicateAction: false,
    ballPrefixPath: 'mode',
    editAbilityPath: 'edit',

    // Only applicable to type booking-type
    exchangeEnabled: false,

    personResourceType: _scientiaResourcebookerConfigEnvironment['default'].IDS.PERSON_RESOURCE_TYPE,

    enablePersonResourceTypeDeletion: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('enablePersonResourceTypeDeletion');
    }),

    editAbilityString: (function () {
      return this.get('editAbilityPath') + ' ' + this.get('type');
    }).property(),

    deleteAbilityString: (function () {
      return 'delete ' + this.get('type');
    }).property()
  });
});