define('scientia-resourcebooker/pods/components/manage-resources/concurrency-configuration-main-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'component.manage_resources.concurrency_form';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['concurrencyConfigurationMainForm'],
    model: null,
    submitAction: null,
    localePrefix: DEFAULT_LOCALE_PREFIX,

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property()

  });
});