define('scientia-resourcebooker/adapters/user-group', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    shouldReloadRecord: function shouldReloadRecord() /*store, snapshot*/{
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return false;
    },

    urlForQuery: function urlForQuery(query, modelName) {

      if (!query || !query.query) return this._super.apply(this, arguments);

      var data = {
        pageNumber: query.pageNumber || 1,
        itemsPerPage: query.itemsPerPage || 10,
        query: query.query
      };

      var url = this._buildURL(modelName);
      data.query = encodeURIComponent(data.query || '');
      url += '/Search?query=' + data.query + '&pageNumber=' + data.pageNumber + '&itemsPerPage=' + data.itemsPerPage;

      // Clear manually added url params so that Ember don't automatically add them again.
      delete query.query;
      delete query.pageNumber;
      delete query.itemsPerPage;

      return url;
    }

  });
});