define('scientia-resourcebooker/pods/components/search-results-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: ['bookingTypesList', 'resourcesList'],

    results: null,

    actions: {
      onResourceClick: function onResourceClick(bookingTypesAvailable, resource, type) {
        this.sendAction('onClickSearchResource', bookingTypesAvailable, resource, type);
      }
    }
  });
});