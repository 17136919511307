define('scientia-resourcebooker/mixins/flash-messages', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var prefix = 'mixins.resource_flashable';

  exports['default'] = _ember['default'].Mixin.create({
    flashMessages: _ember['default'].inject.service(),

    createSuccessFlash: function createSuccessFlash(entity) {
      this.flashSuccess(this._t('create_success', this._lookupEntity(entity)));
    },

    createErrorFlash: function createErrorFlash(entity) {
      this.flashError(this._t('create_error', this._lookupEntity(entity)));
    },

    editSuccessFlash: function editSuccessFlash(entity) {
      this.flashSuccess(this._t('edit_success', this._lookupEntity(entity)));
    },

    editErrorFlash: function editErrorFlash(entity) {
      this.flashError(this._t('edit_error', this._lookupEntity(entity)));
    },

    deleteSuccessFlash: function deleteSuccessFlash(entity) {
      this.flashSuccess(this._t('delete_success', this._lookupEntity(entity)));
    },

    deleteErrorFlash: function deleteErrorFlash(entity) {
      this.flashError(this._t('delete_error', this._lookupEntity(entity)));
    },

    flashSuccess: function flashSuccess(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.timeout : arguments[1];

      this.flashClear();
      this.get('flashMessages').success(message, { timeout: timeout });
    },

    flashError: function flashError(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.timeout : arguments[1];

      this.flashClear();
      this.get('flashMessages').danger(message, { timeout: timeout });
    },

    flashInfo: function flashInfo(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.timeout : arguments[1];

      this.flashClear();
      this.get('flashMessages').info(message, { timeout: timeout });
    },

    flashCustom: function flashCustom(flashMessageObject) {
      this.flashClear();
      this.get('flashMessages').add(flashMessageObject);
    },

    flashClear: function flashClear() {
      this.get('flashMessages').clearMessages();
    },

    _lookupEntity: function _lookupEntity(entity) {
      return { entity: this._t('flashable_resources.' + entity.underscore()) };
    },

    _t: function _t(key, args) {
      return this.get('i18n').t(prefix + '.' + key, args);
    }
  });
});