define('scientia-resourcebooker/pods/components/section-title-actions/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['sectionTitle-actions-item'],
    classNameBindings: ['location', 'typeClass', 'disabled:sectionTitle-actions-item--disabled'],
    attributeBindings: ['disabled', 'tabindex'],
    disabled: false,
    tabindex: 0,
    type: '',
    typeClass: _ember['default'].computed('type', function () {
      return this.get('type') ? 'sectionTitle-actions-item--type-' + this.get('type') : '';
    }),

    action: null,
    actionParam: null,
    count: null,
    icon: null,

    routeTarget: null,
    routeModel: null,
    routeModelSegmentCount: _ember['default'].computed('routeModel', 'routeModel.length', function () {
      return this.get('routeModel.length') || 0;
    }),

    click: function click() {
      if (this.get('disabled') || !this.get('action')) return;
      this.sendAction.apply(this, ['action', this.get('actionParam')]);
    },
    mouseDown: function mouseDown() {
      if (this.get('disabled') || !this.get('action')) return;
      this.sendAction.apply(this, ['action', this.get('actionParam')]);
    }
  });
});