define('scientia-resourcebooker/services/entity-usage-summary-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var ROUTE_PATH_FOR_TYPES = {
    templates: 'manage-resources.booking-type.edit',
    resourcetypes: 'manage-resources.resource-type.edit',
    resourcegroups: 'manage-resources.resource-group.edit',
    resources: 'manage-resources.resource.edit',
    usergroups: 'manage-resources.user-group.edit'
  };

  var EDIT_TAB_INDEX_FOR_TYPES = {

    // add others if this is ever used with another entity other than availability
    availability: {
      templates: 3,
      resources: 2
    }
  };

  /**
   * Service that fetches Usage information ( In Use By) for a given entity
   * Initially designed to check which objects (resources, templates..) were assigned to a specific Availability Pattern but should work with any other entity
   *
   */
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    /**
     * Fetches the entities assigned to the object Id we pass in
     * @param {*} data The data object (type, id, parentId)
     * @returns
     */
    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;

      var url = this._getUrl(type, id, parentId);
      return this.get('authenticatedRequest').fetch(url).then(function (data) {
        return _this._normalizeResponse(type, data);
      });
    },

    /**
     * Designed to work with multiple tabs similar to permissions-summary-fetcher (if passing hideTabs=false in component)
     */
    _normalizeResponse: function _normalizeResponse(entityType, data) {
      return [{
        // name: 'main',
        // icon: 'example.svg',
        values: [{ name: 'templates', values: this.addLinks(entityType, 'templates', data.bookingTypes), count: data.bookingTypesCount }, { name: 'resources', values: this.addLinksForResources(entityType, data.resources), count: data.resourcesCount }]
      }];
    },

    /**
     * Simply loops through each entityType children and assign them a link property
     */
    addLinks: function addLinks(entityType, type, data) {
      var _this2 = this;

      data.forEach(function (d) {
        d.link = _this2.getRouteDataForObject(entityType, type, d);
      });

      return data;
    },

    /**
     * Similar to above but an extra inner loop for resources as they are grouped by ResourceType
     */
    addLinksForResources: function addLinksForResources(entityType, data) {
      var _this3 = this;

      data.forEach(function (resourceType) {
        resourceType.resources.forEach(function (resource) {
          resource.resourceType = resourceType.resourceType;
          resource.link = _this3.getRouteDataForObject(entityType, 'resources', resource);
        });
      });

      return data;
    },

    /**
     * Assembles link information and query params so that the objects in the summary are linkified
     */
    getRouteDataForObject: function getRouteDataForObject(entityType, type, object) {

      // First get the route path
      var linkPath = ROUTE_PATH_FOR_TYPES[type];
      var linkData = [linkPath];

      // If a resource push the resourceTypeId
      if (type === 'resources') {
        linkData.push(object.resourceType.id);
      }

      // Finally push the Id
      linkData.push(object.id);

      linkData.push({
        isQueryParams: true,
        values: { selectedTab: EDIT_TAB_INDEX_FOR_TYPES[entityType][type] }
      });

      return linkData;
    },

    _getUrl: function _getUrl(type, id, parentId) {

      // Add any other types to OBJECT_USAGE_SUMMARY if this is ever used with other entities other than Availability
      var baseUrl = _scientiaResourcebookerConfigEnvironment['default'].API.OBJECT_USAGE_SUMMARY[type];
      var url = undefined;

      if (parentId) {
        url = baseUrl.loc(parentId, id);
      } else {
        url = baseUrl.loc(id);
      }

      return url;
    }

  });
});