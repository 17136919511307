define('scientia-resourcebooker/pods/components/additional-resource-booking/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['additionalResourceBooking'],
    attributeBindings: ['role', 'aria-label', 'tabindex'],
    role: 'region',
    tabindex: '0',
    'aria-label': (0, _emberI18n.translationMacro)('components.booking_request_form.additional_resources'),
    resourceRestrictions: [],

    hasRequiredRestrictions: _ember['default'].computed('resourceRestrictions.@each.min', function () {
      return !this.get('resourceRestrictions').rejectBy('isPrimary').isEvery('min', 0);
    }),

    hasOptionalRestrictions: _ember['default'].computed('resourceRestrictions.@each.min', function () {
      return this.get('resourceRestrictions').rejectBy('isPrimary').isAny('min', 0);
    }),

    actions: {

      filterByRequiredRestrictions: function filterByRequiredRestrictions(minValue) {
        return minValue > 0;
      },

      filterByOptionalRestrictions: function filterByOptionalRestrictions(minValue) {
        return minValue === 0;
      }
    }

  });
});