define('scientia-resourcebooker/pods/components/week-time-picker/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    busyPatterns: [],
    systemSettings: _ember['default'].inject.service(),
    zoomLevel: 0,
    appInsights: _ember['default'].inject.service(),

    weekdays: (function () {

      var weekDays = this.get('i18n').t('general.weekdays').toString().split(',');
      var weekDayOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');

      var busyPatterns = this.get('busyPatterns');
      var result = weekDays.map(function (name, index) {

        var indexOffset = index - weekDayOffset;
        indexOffset = indexOffset < 0 ? indexOffset + 7 : indexOffset;
        return {
          name: name,
          busyPatterns: busyPatterns[index],
          index: index,
          sortedIndex: indexOffset
        };
      });

      return result;
    }).property('busyPatterns'),

    hours: (function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var startIndex = 0;
      var endIndex = 24;

      for (var index = startIndex; index < endIndex; index++) {
        hours.push(startTime.clone().add(index, 'hours'));
      }

      return hours;
    }).property(),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.sendAction('onInsertElement', moment(), moment());
        _ember['default'].run.later(this, '_initializeDragEvents', 300);
      });
    }).on('init'),

    didReceiveAttrs: function didReceiveAttrs() {
      _ember['default'].run.later(this, '_initializeDragEvents', 300);
    },

    actions: {
      showAddSlot: function showAddSlot(weekdayIndex, hour) {
        var slot = {
          dayIndex: weekdayIndex,
          isRepeat: true,
          specificDate: moment().startOf('day').day(weekdayIndex),
          fromTime: hour.hour() * 60,
          toTime: (hour.hour() + 1) * 60
        };
        this.get('showAddSlot')(slot);
      },

      onHeaderClick: function onHeaderClick(weekdayIndex) {
        var slot = {
          dayIndex: weekdayIndex,
          isRepeat: true,
          specificDate: moment().startOf('day').day(weekdayIndex),
          fromTime: 0,
          toTime: 1440
        };
        this.get('showAddSlot')(slot);
        this.get('appInsights').trackAvailabilityHeaderClick({ dayOfWeek: weekdayIndex, isSpecifics: false });
      },

      zoomIncrease: function zoomIncrease() {
        this.sendAction('onZoomChanged', this.get('zoomLevel') + 1);
      },

      zoomDecrease: function zoomDecrease() {
        this.sendAction('onZoomChanged', this.get('zoomLevel') - 1);
      },

      zoomReset: function zoomReset() {
        this.sendAction('onZoomChanged', 0);
      }

    },

    _isBetween: function _isBetween(a, b, c) {
      var x = a.asMinutes();
      var start = b.asMinutes();
      var end = c.asMinutes();
      return x > start && x < end;
    },

    _onDateRangeDrag: function _onDateRangeDrag(weekdayIndex, startHour, endHour) {
      var _this = this;

      var slot = {
        dayIndex: parseInt(weekdayIndex, 10),
        isRepeat: true,
        specificDate: moment().startOf('day').day(weekdayIndex),
        fromTime: parseInt(startHour, 10) * 60,
        toTime: (parseInt(endHour, 10) + 1) * 60
      };

      var eventFound = this.get('busyPatterns').objectAt(slot.dayIndex).find(function (e) {
        var eventStart = _ember['default'].get(e, 'fromTime');
        var eventEnd = _ember['default'].get(e, 'toTime');
        var slotStart = moment.duration(slot.fromTime, 'm');
        var slotEnd = moment.duration(slot.toTime, 'm');
        return _this._isBetween(eventStart, slotStart, slotEnd) || _this._isBetween(eventEnd, slotStart, slotEnd);
      });

      if (!eventFound) {
        this.get('showAddSlot')(slot);
        return true;
      } else {
        return false;
      }
    },

    _initializeDragEvents: function _initializeDragEvents() {
      var _this2 = this;

      // To prevent adding duplicate eventListeners we are checking if the elements already contained
      // the draggable element which is added when the event listeners are added. (We only need to check 1)
      var hourGridItems = document.querySelector('.chronos-weekView-hourGrid-item');
      if (hourGridItems && hourGridItems.getAttribute('draggable')) {
        return;
      }

      var dragImage = new Image();
      dragImage.src = 'assets/transparent.gif';
      var startSlot = null;
      var endSlot = null;

      var clearHighlightSlots = function clearHighlightSlots() {
        _ember['default'].$('.chronos-weekView-hourGrid-item').removeClass('chronos-weekView-hourGrid-item--dragging chronos-weekView-hourGrid-item--error');
      };

      var highLightSlots = function highLightSlots(isRangeInvalid) {
        var weekdayIndex = startSlot.getAttribute('data-weekday');
        var start = parseInt(startSlot.getAttribute('data-hour'), 10);
        var end = endSlot ? parseInt(endSlot.getAttribute('data-hour')) : start;
        if (!start && start !== 0 || !end) {
          return;
        }

        var tmp = start;
        if (end < start) {
          start = end;
          end = tmp;
        }

        clearHighlightSlots();
        for (var i = start; i <= end; i++) {
          document.querySelector('.chronos-weekView').querySelector('.chronos-weekView-hourGrid-item[data-hour="' + i + '"][data-weekday="' + weekdayIndex + '"]').classList.add(isRangeInvalid ? 'chronos-weekView-hourGrid-item--error' : 'chronos-weekView-hourGrid-item--dragging');
        }

        // Lets clear red highlighted cells after 300ms
        if (isRangeInvalid) {
          _ember['default'].run.later(_this2, function () {
            clearHighlightSlots();
          }, 300);
        }
      };

      _ember['default'].$('.chronos-weekView-hourGrid-item').attr('draggable', true);

      Array.from(document.querySelectorAll('.chronos-weekView-hourGrid-item')).forEach(function (el) {

        // drag
        el.addEventListener('drag', function () {}, false);

        // dragstart
        el.addEventListener('dragstart', function (e) {
          startSlot = e.target;
          endSlot = null;

          e.dataTransfer.setDragImage(dragImage, 0, 0);
          e.dataTransfer.effectAllowed = 'move';

          _ember['default'].$('.chronos-weekView-hourGrid-item').removeClass('chronos-weekView-hourGrid-item--dragging');
          highLightSlots();
        }, false);

        // dragend
        el.addEventListener('dragend', function () {
          clearHighlightSlots();
          var weekday = startSlot.getAttribute('data-weekday');
          var startHour = parseInt(startSlot.getAttribute('data-hour'), 10);
          var endHour = parseInt(endSlot.getAttribute('data-hour'), 10);
          var tmp = endHour;
          if (endHour < startHour) {
            endHour = startHour;
            startHour = tmp;
          }

          // Emit action and check if the response is invalid. If it is we highlight the slots with red
          var isValidRange = _this2._onDateRangeDrag(weekday, startHour, endHour);

          _this2.get('appInsights').trackAvailabilityDrag({ start: startHour, end: endHour, isSpecifics: false, isValid: isValidRange });

          if (!isValidRange) {
            highLightSlots(true);
          }
        }, false);

        // dragover
        el.addEventListener('dragover', function (e) {
          // prevent default to allow drop
          e.preventDefault();
        }, false);

        el.addEventListener('dragenter', function (e) {
          e.dataTransfer.dropEffect = 'move';
          endSlot = e.target;
          highLightSlots();
        }, false);

        el.addEventListener('dragleave', function () {}, false);

        el.addEventListener('drop', function (e) {
          e.preventDefault();
        }, false);
      });
    }

  });
});
// Drag events
// Array.from(document.querySelectorAll('.chronos-weekView-hourGrid-item')).forEach(el => {
//   el.addEventListener('dragstart', function() {
//     el.classList.add('chronos-weekView-hourGrid-item--highlighted')
//     });
// el.addEventListener('dragenter', function() {
//     el.classList.add('chronos-weekView-hourGrid-item--highlighted')
//     });
// });