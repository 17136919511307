define('scientia-resourcebooker/pods/components/resource-tree/item/component', ['exports', 'ember'], function (exports, _ember) {
  var _slice = Array.prototype.slice;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    classNames: ['manageResourcesItemsList-item'],

    model: null,
    selected: null,

    loadChildrenData: null,
    childrenData: null,
    hasChildrenData: false,

    isLoadingChildren: false,
    hasLoadedChildren: false,
    hasErrorChildren: false,
    isExpanded: false,

    isSelected: _ember['default'].computed('selected.@each.id', 'model.id', function () {
      var selected = this.get('selected');
      if (_ember['default'].isEmpty(selected)) return false;
      return selected.getEach('id').indexOf(this.get('model.id')) !== -1;
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    _getModelPropertiesFromParamsList: function _getModelPropertiesFromParamsList() {
      var childDataParams = arguments.length <= 0 || arguments[0] === undefined ? ['id'] : arguments[0];

      return _ember['default'].getProperties(this.get('model'), childDataParams);
    },

    actions: {

      onSelect: function onSelect() {
        this.sendAction.apply(this, ['onSelect'].concat(_slice.call(arguments)));
      },

      onItemClick: function onItemClick() {
        var _this = this;

        if (this.get('isLoadingChildren')) return;

        if (this.get('hasLoadedChildren')) {
          return this.toggleProperty('isExpanded');
        }

        if (!this.get('children')) return;

        this.set('isLoadingChildren', true);

        var hashes = {};
        this.get('children').forEach(function (child) {
          var params = _this._getModelPropertiesFromParamsList(child.childDataParams);
          hashes[child.name] = _this._fetchChildData(child, params);
        });

        _ember['default'].RSVP.hash(hashes).then(function (results) {
          var childrenData = [];
          Object.keys(results).forEach(function (k) {
            if (results[k].hasData) _this.set('hasChildrenData', true);
            childrenData.push(results[k]);
          });

          _this.set('childrenData', childrenData);

          _this.setProperties({
            isLoadingChildren: false,
            hasLoadedChildren: true,
            hasErrorChildren: false,
            isExpanded: true
          });
        })['catch'](function () {
          _this.setProperties({
            isLoadingChildren: false,
            hasLoadedChildren: false,
            hasErrorChildren: true,
            isExpanded: false
          });
        });
      },

      paginate: function paginate(childName, page) {
        var _this2 = this;

        var child = this.get('children').findBy('name', childName);
        var params = this._getModelPropertiesFromParamsList(child.childDataParams);
        this._fetchChildData(child, params, page).then(function (results) {

          var existingChildData = _this2.get('childrenData').findBy('name', child.name);

          _ember['default'].set(existingChildData, 'data', results.data);
          _ember['default'].set(existingChildData, 'hasResults', results.hasResults);
          _ember['default'].set(existingChildData, 'meta', results.meta);
        });
      }

    },

    _fetchChildData: function _fetchChildData(child, params, page) {
      return child.loadChildData(params, page).then(function (results) {
        var hasData = results.data.get('length');
        results.hasData = hasData;
        return results;
      });
    }

  });
});