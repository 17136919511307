define('scientia-resourcebooker/pods/components/toggle-button-group/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['toggleButtonGroup'],
    attributeBindings: ['role', 'tabindex', 'aria-label'],
    role: 'group',
    tabindex: 0,
    'aria-label': null,
    content: [],

    selected: [],
    singleValue: false,
    disabled: false,
    actions: {
      toggled: function toggled(option) {
        if (this.get('disabled')) return false;
        this.sendAction('action', option);
      }
    }
  });
});