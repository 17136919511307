define('scientia-resourcebooker/helpers/to-lower', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (str) {
    if (_ember['default'].isEmpty(str)) {
      return '';
    } else if (_ember['default'].isArray(str)) {
      return str[0] ? str[0].toLowerCase() : '';
    } else {
      return str.toLowerCase();
    }
  });
});