define('scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['auditOptions datePeriodSelector'],
    classNameBindings: ['location'],

    model: null,
    selected: null,

    startDate: null,
    endDate: null,

    actions: {

      onTimeframeChange: function onTimeframeChange(timeframe) {
        this.get('onChange')(timeframe);
      },

      onStartRangeChange: function onStartRangeChange(startDate) {
        this.get('onRangeChange')(startDate, this.get('endDate'));
      },

      onEndRangeChange: function onEndRangeChange(endDate) {
        this.get('onRangeChange')(this.get('startDate'), endDate);
      }

    }

  });
});