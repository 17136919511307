define('scientia-resourcebooker/pods/manage-resources/availability/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSaving: false,
    error: '',

    actions: {
      save: function save() {
        this.send('create', this.get('model.availabilityPattern'));
      }
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.availability.index.availability_patterns'),
        path: 'manage-resources.availability.index',
        linkable: true
      }, {
        label: this.get('i18n').t('pods.manage_resources.availability.new.availability_pattern'),
        linkable: false
      }];
    })
  });
});