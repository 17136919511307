define("scientia-resourcebooker/pods/components/resource-filters/multipleselections/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/multipleselections/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "resourceFilters-helpText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "property.helpText", ["loc", [null, [4, 56], [4, 77]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/multipleselections/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "listitem");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "resource-filters/list/item", [], ["choice", ["subexpr", "@mut", [["get", "choice.name", ["loc", [null, [12, 15], [12, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "contains", [["get", "choice.name", ["loc", [null, [13, 26], [13, 37]]], 0, 0, 0, 0], ["get", "selected.value", ["loc", [null, [13, 38], [13, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 16], [13, 53]]], 0, 0], "listChanged", ["subexpr", "action", [["get", "attrs.listChanged", ["loc", [null, [14, 28], [14, 45]]], 0, 0, 0, 0], ["get", "property.identity", ["loc", [null, [14, 46], [14, 63]]], 0, 0, 0, 0], ["get", "choice.name", ["loc", [null, [14, 64], [14, 75]]], 0, 0, 0, 0], ["get", "property.computedDisplayName", ["loc", [null, [14, 76], [14, 104]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 20], [14, 105]]], 0, 0]], ["loc", [null, [11, 6], [15, 8]]], 0, 0]],
        locals: ["choice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-filters/multipleselections/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "resourceFilters-subtitle");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "resourceFilters-fields");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "role", "list");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'aria-label');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["content", "property.computedDisplayName", ["loc", [null, [1, 37], [1, 69]]], 0, 0, 0, 0], ["block", "if", [["get", "property.helpText", ["loc", [null, [3, 8], [3, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 9]]]], ["attribute", "aria-label", ["concat", [["get", "property.computedDisplayName", ["loc", [null, [7, 45], [7, 73]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "property.valueChoicesWithId", ["loc", [null, [9, 10], [9, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [9, 2], [17, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});