define("scientia-resourcebooker/pods/components/manage-resources/custom-field-item/value-types/yesno/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/value-types/yesno/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("fieldset");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("legend");
        dom.setAttribute(el2, "class", "sr-only");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-customFieldItem-valueTypes-bool-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-customFieldItem-valueTypes-bool-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element0, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["content", "label", ["loc", [null, [2, 26], [2, 35]]], 0, 0, 0, 0], ["inline", "t", ["components.manage_resources.custom_field_item.yesno.yes"], [], ["loc", [null, [4, 10], [4, 73]]], 0, 0], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "displayReadOnly", ["loc", [null, [5, 21], [5, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", "selected", "type", "radio", "name", "option", "value", "1"], ["loc", [null, [5, 4], [5, 95]]], 0, 0], ["inline", "t", ["components.manage_resources.custom_field_item.yesno.no"], [], ["loc", [null, [9, 10], [9, 72]]], 0, 0], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "displayReadOnly", ["loc", [null, [10, 21], [10, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "radio", "name", "option", "value", "0"], ["loc", [null, [10, 4], [10, 75]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});