define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    queryParams: ['isNew'],

    selectedGroupResources: null,
    showSortModal: false,
    error: null,
    isNew: false,

    _mapSelectionsFromModel: function _mapSelectionsFromModel(model) {
      if (!_ember['default'].isEmpty(model)) {
        var selected = model.map(function (item) {
          return {
            id: item.id,
            name: item.name,
            resourceTypeId: item.resourceTypeId,
            isPrimary: item.isPrimary,
            order: item.order,
            type: 'resources'
          };
        });
        this.set('selectedGroupResources', selected);
      } else {
        this.set('selectedGroupResources', []);
      }
    },

    actions: {
      onSelectionChanged: function onSelectionChanged(selection) {
        this.set('selectedGroupResources', selection);
      },

      onSelectionSorted: function onSelectionSorted(selection) {
        this.set('selectedGroupResources', selection);
        this.set('showSortModal', false);
      },
      onCancel: function onCancel() {
        this.transitionToRoute('manage-resources.resource-type.concurrency.index');
      }
    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: true, path: 'manage-resources.resource-type.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title'), linkable: true, path: 'manage-resources.resource-type.concurrency.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.group'), linkable: false }, { label: this.get('model.concurrencyGroup.name'), linkable: false }];
    }).property('model.id', 'i18n.locale')
  });
});