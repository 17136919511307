define('scientia-resourcebooker/pods/components/resource-tree/search/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourceTree-search'],
    classNameBindings: ['disabled:resourceTree-search--disabled', 'location'],
    location: null,

    types: null,
    typeSelected: null,
    query: '',
    disabled: false,
    searchDisabled: false
  });
});