define('scientia-resourcebooker/pods/components/manage-resources/resource-type-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'component.manage_resources.resource_form';

  exports['default'] = _ember['default'].Component.extend({
    resource: null,
    submitAction: null,
    localePrefix: DEFAULT_LOCALE_PREFIX,
    disabled: false,

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property(),

    actions: {
      openIconSelector: function openIconSelector() {
        if (this.get('disabled')) return;
        this.sendAction('openIconSelector');
      },

      clearImageUri: function clearImageUri() {
        if (this.get('disabled')) return;
        this.sendAction('clearImageUri');
      }
    }
  });
});