define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    errorHandler: _ember['default'].inject.service(),

    titleToken: function titleToken(model) {
      return model.concurrencyGroup.get('name') + ' — ' + this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.concurrency_groups');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        concurrencyGroup: this._fetchConcurrencyGroup(params)
      });
    },

    _fetchConcurrencyGroup: function _fetchConcurrencyGroup(params) {
      var _this = this;

      return this.get('store').findRecord('concurrency-group', params.id, { reload: true })['catch'](function () {
        _this.flashError(_this.get('i18n').t('general.generic_error'));
        return _this.transitionTo('manage-resources.resource-type.concurrency.index');
      });
    },

    afterModel: function afterModel(model) {
      this.controllerFor(this.routeName)._mapSelectionsFromModel(model.concurrencyGroup.get('resources'));
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: null
        });
      }
    },

    actions: {
      updateModel: function updateModel(model, transitionToIndex) {
        var _this2 = this;

        var c = this.controllerFor(this.routeName);

        if (_ember['default'].isEmpty(model.get('name'))) {
          c.set('error', this.get('i18n').t('error_messages.concurrencygroupnoname').toString());
          return;
        }

        c.set('error', null);
        c.set('isSaving', true);

        c.set('isSaving', true);
        model.set('resources', c.get('selectedGroupResources'));
        model.save().then(function () {
          _this2.editSuccessFlash('concurrency-group');
          if (transitionToIndex) {
            return _this2.transitionTo('manage-resources.resource-type.concurrency.index');
          }
        })['catch'](function (error) {
          var errorMessage = _this2.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            c.set('error', errorMessage);
            _ember['default'].$('.container-wrapper').scrollTop(0);
          } else {
            _this2.createErrorFlash('concurrency-group');
          }
        })['finally'](function () {
          c.set('isSaving', false);
        });
      },

      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        if (model.concurrencyGroup.get('hasDirtyAttributes')) model.concurrencyGroup.rollbackAttributes();
        return true;
      }
    }
  });
});