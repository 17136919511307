define('scientia-resourcebooker/pods/print/booking/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    queryParams: {
      occurrenceId: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('components.booking_request_overview.booking_summary').toString();
    },

    model: function model(params) {

      if (params.id) {
        if (params.occurrenceId) {
          return this.store.queryRecord('booking-request', { bookingId: params.id, occurrenceId: params.occurrenceId });
        } else {
          return this.store.find('booking-request', params.id);
        }
      }
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackEvent('booking-print', { bookingTypeId: model.get('bookingTypeId') });

      _ember['default'].run.next(this, function () {
        this.send('updateHeader', model.get('name'));
        this.send('updateSubheader', [this.get('i18n').t('components.booking_request_overview.reference').toString(), model.get('reference')]);
        _ember['default'].run.later(this, function () {
          window.print();
        }, 500);
      });
    }
  });
});