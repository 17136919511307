define('scientia-resourcebooker/pods/logged-in/user-profile/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {
    session: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.user_profile.page_title');
    },

    model: function model() {
      return this.modelFor('logged-in').user;
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});