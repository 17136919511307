define('scientia-resourcebooker/models/booking-form', ['exports', 'ember-data', 'model-fragments/attributes', 'ember'], function (exports, _emberData, _modelFragmentsAttributes, _ember) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    description: attr('string', { defaultValue: '' }),
    sections: (0, _modelFragmentsAttributes.fragmentArray)('booking-form-section'),

    // This has to be a readonly attribute and not a hasMany(booking-type) as we don't want the booking-form
    // model to start pushing booking types to the store as some properties on these are not defined and will override
    // the existing working models with these invalid ones from associatedBookingTypes below
    associatedBookingTypes: attr(),

    hasAssociatedBookingTypes: _ember['default'].computed.gt('associatedBookingTypes.length', 0)
  });
});