define('scientia-resourcebooker/services/permissions-validator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    validate: function validate(permissions) {
      var _this = this;

      var isValid = true;
      var lastFailedIndex = null;
      permissions.forEach(function (permission, index) {
        var isClaimMode = permission.get('isClaimMode');
        var value = permission.get('permissionValue');

        // Validate Request Permission
        if (permission.get('permissionType') === 'request') {
          if (!_this.validateRequestPermissionValue(permission)) {
            isValid = false;
            lastFailedIndex = index;
            return;
          }
        } else if (isClaimMode) {
          if (_ember['default'].isEmpty(value) || !value.claimType || !value.claimValue) {
            isValid = false;
            lastFailedIndex = index;
            return;
          }
        } else {

          // Else validate normal value
          if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue'))) {
            isValid = false;
            lastFailedIndex = index;
          }
        }
      });

      return { isValid: isValid, index: lastFailedIndex };
    },

    /**
     * Validates a request permission by checking if `permissionTarget` is empty or if `approvers` is empty.
     * @param {*} permission Request Permission
     */
    validateRequestPermissionValue: function validateRequestPermissionValue(permission) {

      var approvers = permission.get('permissionValue.approvers') || [];
      var targetIsClaimMode = permission.get('permissionValue.permissionTarget.isClaimMode');
      var approversIsClaimMode = approvers && approvers.isClaimMode;

      if (_ember['default'].isEmpty(permission)) return false;
      var isValid = true;

      if (targetIsClaimMode) {
        if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.permissionTarget.claimType'))) {
          isValid = false;
        }
        if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.permissionTarget.claimValue'))) {
          isValid = false;
        }
      }

      if (approversIsClaimMode) {
        if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.approvers.claimType'))) {
          isValid = false;
        }
        if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.approvers.claimValue'))) {
          isValid = false;
        }
      }

      if (_ember['default'].isEmpty(permission)) return false;
      if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.approvers'))) return false;
      if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue.permissionTarget'))) return false;

      return isValid;
    }

  });
});