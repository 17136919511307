define('scientia-resourcebooker/models/concurrency-configuration', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({

    name: attr('string'),
    description: attr('string'),
    concurrencyGroupMembers: attr('raw'),
    canDelete: attr('boolean'),
    canEdit: attr('boolean'),
    canViewCalendar: attr('boolean')
  });
});