define("scientia-resourcebooker/pods/components/booking-report-list/section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 6
                },
                "end": {
                  "line": 12,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-report-list/item", [], ["model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [11, 41], [11, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "loadingBookingRequest", ["subexpr", "@mut", [["get", "loadingBookingRequest", ["loc", [null, [11, 68], [11, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "openEventOverview", ["subexpr", "action", [["get", "openEventOverview", ["loc", [null, [11, 116], [11, 133]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 108], [11, 134]]], 0, 0]], ["loc", [null, [11, 8], [11, 136]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 6
                },
                "end": {
                  "line": 14,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-report-list/item-activity", [], ["model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [13, 50], [13, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 8], [13, 56]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/section/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "item.reference", ["loc", [null, [10, 12], [10, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 6], [14, 13]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [8, 12], [8, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 4], [15, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportList-dayItem");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bookingReportList-dateHeader");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "resourceTree-expand at-bookingReport");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["element", "action", [["subexpr", "toggle", ["isShowingData", ["get", "this", ["loc", [null, [3, 77], [3, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 53], [3, 82]]], 0, 0]], [], ["loc", [null, [3, 44], [3, 84]]], 0, 0], ["inline", "inline-svg", ["icons/caret-down.svg"], ["class", ["subexpr", "if", [["get", "isShowingData", ["loc", [null, [4, 101], [4, 114]]], 0, 0, 0, 0], "expanded", "collapsed"], [], ["loc", [null, [4, 97], [4, 138]]], 0, 0]], ["loc", [null, [4, 55], [4, 140]]], 0, 0], ["inline", "moment-format", [["get", "date", ["loc", [null, [5, 26], [5, 30]]], 0, 0, 0, 0], "dddd DD MMM YYYY"], [], ["loc", [null, [5, 10], [5, 51]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "model", ["loc", [null, [7, 13], [7, 18]]], 0, 0, 0, 0], ["get", "isShowingData", ["loc", [null, [7, 19], [7, 32]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 8], [7, 33]]], 0, 0]], [], 0, null, ["loc", [null, [7, 2], [16, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});