define("scientia-resourcebooker/pods/components/manage-resources/availability-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle manageResources-itemForm-subtitle--disabledTab");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [7, 2], [7, 81]]], 0, 0], ["inline", "t", ["pods.manage_resources.tab_no_permission"], [], ["loc", [null, [8, 4], [8, 51]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 12
                  },
                  "end": {
                    "line": 31,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.manage_resources.availability_form.link_patterns"], ["count", ["subexpr", "if", [["get", "linkedPatternsCount", ["loc", [null, [30, 90], [30, 109]]], 0, 0, 0, 0], ["get", "linkedPatternsCount", ["loc", [null, [30, 110], [30, 129]]], 0, 0, 0, 0], 0], [], ["loc", [null, [30, 86], [30, 132]]], 0, 0]], ["loc", [null, [30, 14], [30, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 12
                  },
                  "end": {
                    "line": 36,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.manage_resources.availability_form.copy_patterns"], [], ["loc", [null, [35, 14], [35, 81]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 8
                },
                "end": {
                  "line": 37,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dropdown-button-item", [], ["icon", "icons/link.svg", "action", ["subexpr", "action", [["get", "showPatternsLinkModal", ["loc", [null, [29, 73], [29, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 65], [29, 95]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "hasPendingUnavailability", ["loc", [null, [29, 105], [29, 129]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [29, 12], [31, 37]]]], ["block", "dropdown-button-item", [], ["icon", "icons/copy.svg", "action", ["subexpr", "action", [["get", "showPatternsCopyModal", ["loc", [null, [34, 73], [34, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 65], [34, 95]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "hasPendingUnavailability", ["loc", [null, [34, 105], [34, 129]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [34, 12], [36, 37]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 8
                },
                "end": {
                  "line": 42,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_form.clear_unavailability"], [], ["loc", [null, [41, 10], [41, 84]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 6
              },
              "end": {
                "line": 43,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "isManagingPatterns", ["loc", [null, [27, 18], [27, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 8], [37, 19]]]], ["block", "dropdown-button-item", [], ["icon", "icons/cross.svg", "action", ["subexpr", "action", [["get", "showClearUnavailability", ["loc", [null, [40, 70], [40, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 62], [40, 94]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "hasPendingUnavailability", ["loc", [null, [40, 104], [40, 128]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [40, 8], [42, 33]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 6
              },
              "end": {
                "line": 48,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.availability_week_exceptions_modal.manage_exceptions"], ["count", ["subexpr", "if", [["get", "busyExceptions.length", ["loc", [null, [47, 105], [47, 126]]], 0, 0, 0, 0], ["get", "busyExceptions.length", ["loc", [null, [47, 127], [47, 148]]], 0, 0, 0, 0], 0], [], ["loc", [null, [47, 101], [47, 151]]], 0, 0]], ["loc", [null, [47, 8], [47, 153]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 50,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "unless", [["get", "disabled", ["loc", [null, [25, 16], [25, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 6], [43, 17]]]], ["block", "dropdown-button-item", [], ["icon", "icons/exception.svg", "action", ["subexpr", "action", [["get", "showBusyExceptionsManager", ["loc", [null, [46, 72], [46, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 64], [46, 98]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "hasPendingUnavailability", ["loc", [null, [46, 108], [46, 132]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [46, 6], [48, 31]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 57,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.availability_form.commit_changes"], ["count", ["subexpr", "@mut", [["get", "pendingChangesCount", ["loc", [null, [56, 81], [56, 100]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [56, 8], [56, 102]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 4
            },
            "end": {
              "line": 63,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "button button--error at-unavailability-undoChanges");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "button--label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [4]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createAttrMorph(element1, 'disabled');
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          return morphs;
        },
        statements: [["block", "async-button", [], ["disableButton", ["subexpr", "not", [["get", "hasPendingUnavailability", ["loc", [null, [55, 41], [55, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 36], [55, 66]]], 0, 0], "loading", ["subexpr", "@mut", [["get", "isComittingUnavailability", ["loc", [null, [55, 75], [55, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", [["get", "commitUnavailability", ["loc", [null, [55, 116], [55, 136]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 108], [55, 137]]], 0, 0], "class", "button button--success at-unavailability-undoChanges"], 0, null, ["loc", [null, [55, 6], [57, 23]]]], ["attribute", "disabled", ["subexpr", "not", [["get", "hasPendingUnavailability", ["loc", [null, [60, 29], [60, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [60, 55]]], 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "undoAllUnavailability", ["loc", [null, [60, 73], [60, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [60, 65], [60, 95]]], 0, 0]], [], ["loc", [null, [60, 56], [60, 97]]], 0, 0], ["inline", "t", ["components.manage_resources.availability_form.undo_changes"], ["count", ["subexpr", "@mut", [["get", "pendingChangesCount", ["loc", [null, [61, 107], [61, 126]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [61, 36], [61, 128]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 4
                },
                "end": {
                  "line": 69,
                  "column": 77
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_form.defaults"], [], ["loc", [null, [69, 15], [69, 77]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 4
                },
                "end": {
                  "line": 70,
                  "column": 83
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_form.specific_dates"], [], ["loc", [null, [70, 15], [70, 83]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 71,
                  "column": 76
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.availability_form.preview"], [], ["loc", [null, [71, 15], [71, 76]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 2
              },
              "end": {
                "line": 72,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [69, 4], [69, 88]]]], ["block", "dc-tab", [], [], 1, null, ["loc", [null, [70, 4], [70, 94]]]], ["block", "dc-tab", [], [], 2, null, ["loc", [null, [71, 4], [71, 87]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 2
              },
              "end": {
                "line": 86,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "week-time-picker", [], ["busyPatterns", ["subexpr", "@mut", [["get", "busyPatterns", ["loc", [null, [77, 17], [77, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "zoomLevel", ["subexpr", "readonly", [["get", "zoomLevel", ["loc", [null, [78, 24], [78, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 14], [78, 34]]], 0, 0], "onZoomChanged", ["subexpr", "action", ["onZoomChanged"], [], ["loc", [null, [79, 18], [79, 42]]], 0, 0], "showRemoveSlot", ["subexpr", "action", ["showRemoveUnavailabilityWeekdaySlot"], [], ["loc", [null, [80, 19], [80, 65]]], 0, 0], "showAddSlot", ["subexpr", "action", ["showAddUnavailabilityWeekdaySlot"], [], ["loc", [null, [81, 16], [81, 59]]], 0, 0], "undoAddSlot", ["subexpr", "action", ["undoAddWeekdaySlot"], [], ["loc", [null, [82, 16], [82, 45]]], 0, 0], "undoRemoveSlot", ["subexpr", "action", ["undoRemoveWeekdaySlot"], [], ["loc", [null, [83, 19], [83, 51]]], 0, 0], "onInsertElement", "fetchBusyPatterns"], ["loc", [null, [76, 4], [85, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 4
                  },
                  "end": {
                    "line": 93,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "loading-icon", [], ["size", "big", "align", "center"], ["loc", [null, [92, 6], [92, 48]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 4
                  },
                  "end": {
                    "line": 114,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "rb-calendar", [], ["location", "at-unavailabilitySpecificDate", "displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [96, 23], [96, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarEvents", ["subexpr", "@mut", [["get", "busyTimes", ["loc", [null, [97, 23], [97, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "show-month-button", false, "show-agenda-button", false, "slotRangeDragEnabled", true, "calendarStartTime", "00:00", "calendarEndTime", "24:00", "showWeekends", true, "small-view-type", "day", "zoomLevel", ["subexpr", "readonly", [["get", "zoomLevel", ["loc", [null, [105, 28], [105, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [105, 18], [105, 38]]], 0, 0], "on-sheet-change", "fetchBusyTimes", "on-day-header-click", ["subexpr", "action", ["onDayHeaderClick"], [], ["loc", [null, [107, 28], [107, 55]]], 0, 0], "on-date-click", ["subexpr", "action", ["showAddUnavailabilityDateSlot"], [], ["loc", [null, [108, 22], [108, 62]]], 0, 0], "on-event-click", ["subexpr", "action", ["showRemoveUnavailabilityDateSlot"], [], ["loc", [null, [109, 23], [109, 66]]], 0, 0], "on-date-range-drag", ["subexpr", "action", ["onDateRangeDrag"], [], ["loc", [null, [110, 27], [110, 53]]], 0, 0], "onZoomChanged", ["subexpr", "action", ["onZoomChanged"], [], ["loc", [null, [111, 22], [111, 46]]], 0, 0], "useCustomCalendarOptions", true], ["loc", [null, [94, 6], [113, 8]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 2
                },
                "end": {
                  "line": 115,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isClearingUnavailability", ["loc", [null, [91, 10], [91, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [91, 4], [114, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 2
              },
              "end": {
                "line": 116,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [89, 8], [89, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [89, 2], [115, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 4
                  },
                  "end": {
                    "line": 128,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "manageResources-itemForm-headerSubActions at-alignRight at-availabilityPreview");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "templateUnavailability-toggler");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "toggler-label");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element0, 3, 3);
                return morphs;
              },
              statements: [["inline", "t", ["components.manage_resources.availability_form.include_disabled_patterns"], [], ["loc", [null, [124, 38], [124, 117]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "includeDisabledPatternsUnavailability", ["loc", [null, [125, 44], [125, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [125, 34], [125, 82]]], 0, 0], "onToggle", ["subexpr", "action", ["toggleIncludeDisabledPatternsUnavailability"], [], ["loc", [null, [125, 92], [125, 146]]], 0, 0]], ["loc", [null, [125, 10], [125, 149]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 4
                },
                "end": {
                  "line": 151,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["block", "unless", [["get", "isManagingPatterns", ["loc", [null, [121, 14], [121, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [121, 4], [128, 15]]]], ["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [131, 25], [131, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarEvents", ["subexpr", "@mut", [["get", "previewEvents", ["loc", [null, [132, 25], [132, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "tag", "event-title-path", "name", "on-sheet-change", "fetchPreviewEvents", "on-date-click", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [138, 42], [138, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [138, 32], [138, 47]]], 0, 0]], [], ["loc", [null, [138, 24], [138, 48]]], 0, 0], "on-event-click", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [139, 43], [139, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [139, 33], [139, 48]]], 0, 0]], [], ["loc", [null, [139, 25], [139, 49]]], 0, 0], "on-calendar-loaded", ["subexpr", "action", ["onPreviewCalendarLoaded"], [], ["loc", [null, [140, 29], [140, 63]]], 0, 0], "show-month-button", false, "show-tag-button", true, "calendarStartTime", "00:00", "calendarEndTime", "24:00", "showWeekends", true, "small-view-type", ["subexpr", "@mut", [["get", "previewCalendarMobileView", ["loc", [null, [146, 26], [146, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "zoomLevel", ["subexpr", "readonly", [["get", "zoomLevel", ["loc", [null, [147, 30], [147, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [147, 20], [147, 40]]], 0, 0], "onZoomChanged", ["subexpr", "action", ["onZoomChanged"], [], ["loc", [null, [148, 24], [148, 48]]], 0, 0], "useCustomCalendarOptions", true], ["loc", [null, [130, 6], [150, 10]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 118,
                "column": 2
              },
              "end": {
                "line": 152,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [119, 10], [119, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [119, 4], [151, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 0
            },
            "end": {
              "line": 153,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [68, 2], [72, 18]]]], ["block", "dc-tab-panel", [], ["class", "at-availabilityForm"], 1, null, ["loc", [null, [74, 2], [86, 19]]]], ["block", "dc-tab-panel", [], ["class", "at-availabilityForm"], 2, null, ["loc", [null, [88, 2], [116, 19]]]], ["block", "dc-tab-panel", [], [], 3, null, ["loc", [null, [118, 2], [152, 19]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 154,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "manageResources-itemForm-description");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-headerSubActions at-availabilityForm");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(fragment, [6]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createMorphAt(element3, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createUnsafeMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[4] = dom.createMorphAt(element4, 1, 1);
        morphs[5] = dom.createMorphAt(element4, 3, 3);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["manageResources-itemForm-title ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [1, 47], [1, 55]]], 0, 0, 0, 0], "manageResources-itemForm-title--hasSubtitle", ""], [], ["loc", [null, [1, 42], [1, 106]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.manage_resources.availability_form.title"], ["resource", ["subexpr", "@mut", [["get", "resource.name", ["loc", [null, [2, 69], [2, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 84]]], 0, 0], ["block", "if", [["get", "disabled", ["loc", [null, [5, 6], [5, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 0], [10, 7]]]], ["inline", "t", ["components.manage_resources.availability_form.description"], [], ["loc", [null, [13, 2], [13, 69]]], 0, 0], ["block", "dropdown-button", [], ["label", ["subexpr", "t", ["components.manage_resources.availability_form.tools"], [], ["loc", [null, [19, 10], [19, 67]]], 0, 0], "disabled", ["subexpr", "@mut", [["get", "hasPendingUnavailability", ["loc", [null, [20, 13], [20, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "classes", "button button--white at-availabilityTools", "icon", "icons/cog.svg"], 1, null, ["loc", [null, [18, 4], [50, 24]]]], ["block", "unless", [["get", "disabled", ["loc", [null, [52, 14], [52, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [52, 4], [63, 15]]]], ["block", "dc-tabs", [], ["selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [67, 26], [67, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], 3, null, ["loc", [null, [67, 0], [153, 12]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});