define('scientia-resourcebooker/pods/components/resource-filters/email/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourceFilter-type-text'],
    identity: _ember['default'].computed.alias('property.identity'),
    value: '',
    customEvents: _ember['default'].inject.service(),

    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        var customEvents = this.get('customEvents');
        customEvents.on('removeCustomFilter', this, 'removeValueOnFilterRemoved');
        customEvents.on('resetFilters', this, 'resetValue');
      });
    }).on('init'),

    removeValueOnFilterRemoved: function removeValueOnFilterRemoved(filter) {
      if (filter.identity !== this.get('property.identity')) return;
      if (this.get('isDestroyed')) return;
      this.set('value', '');
    },

    resetValue: function resetValue() {
      this.set('value', '');
    },

    valueDidChange: (function () {
      _ember['default'].run.debounce(this, this.attrs.textChanged, this.get('property.identity'), this.get('value'), this.get('property.computedDisplayName'), 100, false);
    }).observes('value')
  });
});