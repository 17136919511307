define('scientia-resourcebooker/services/booking-request-restrictions-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    fetch: function fetch(bookingId) {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.EDIT_RESTRICTIONS.loc(bookingId)).then(function (data) {
        data.refittedResources = data.refittedResources || [];
        data.refittedResources = data.refittedResources.map(function (r) {
          return _ember['default'].Object.create(r);
        });

        data.editRestrictions = data.editRestrictions || [];

        return data;
      });
    },

    fetchRefitted: function fetchRefitted(bookingId) {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.EDIT_RESTRICTIONS.loc(bookingId)).then(function (data) {
        data.refittedResources = data.refittedResources || [];
        return data.refittedResources.map(function (r) {
          return _ember['default'].Object.create(r);
        });
      });
    },

    fetchEditRestrictions: function fetchEditRestrictions(bookingId) {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.EDIT_RESTRICTIONS.loc(bookingId)).then(function (data) {
        return data.editRestrictions || [];
      });
    }

  });
});