define('scientia-resourcebooker/services/task-progress-modal-displayer', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({

    router: _ember['default'].inject.service(),

    show: function show(taskKey) {
      var app = getOwner(this).lookup('route:application');
      var deferred = new _ember['default'].RSVP.defer();
      app.send('showProgress', { taskKey: taskKey, deferred: deferred });
      return deferred.promise;
    }
  });
});