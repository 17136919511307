define("scientia-resourcebooker/pods/components/manage-resources/custom-field-item/value-types/number/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/value-types/number/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "manageResources-customFieldItem-valueTypes-bool-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "number");
        dom.setAttribute(el1, "class", "form-input manageResources-customFieldItem-valueTypes-integer-input manageResources-itemForm-form-input");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "manageResources-customFieldItem-valueTypes-bool-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "number");
        dom.setAttribute(el1, "class", "form-input manageResources-customFieldItem-valueTypes-integer-input manageResources-itemForm-form-input");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [6]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element0, 'aria-label');
        morphs[2] = dom.createAttrMorph(element0, 'disabled');
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[4] = dom.createAttrMorph(element1, 'aria-label');
        morphs[5] = dom.createAttrMorph(element1, 'disabled');
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.custom_field_item.number.min"], [], ["loc", [null, [2, 2], [2, 66]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.custom_field_item.number.min"], [], ["loc", [null, [6, 12], [6, 76]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "displayReadOnly", ["loc", [null, [7, 11], [7, 26]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.manage_resources.custom_field_item.number.max"], [], ["loc", [null, [11, 2], [11, 66]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.custom_field_item.number.max"], [], ["loc", [null, [15, 12], [15, 76]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "displayReadOnly", ["loc", [null, [16, 11], [16, 26]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});