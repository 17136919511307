define('scientia-resourcebooker/validators/attachment-empty-custom-extensions', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var AttachmentEmptyCustomExtensions = _emberCpValidationsValidatorsBase['default'].extend({

    i18n: _ember['default'].inject.service(),

    validate: function validate(value, options, model /*, attribute*/) {

      // If user has selected Custom for attachment
      var attachmentTypes = model.get('attachmentTypes') || [];
      if (attachmentTypes.includes(6)) {
        if (_ember['default'].isEmpty(value)) {
          return this.get('i18n').t('errors.attachmentEmptyCustomExtensions').toString();
        }
      }

      return true;
    }
  });

  AttachmentEmptyCustomExtensions.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = AttachmentEmptyCustomExtensions;
});