define('scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['manageResourcesItemsList-item'],
    can: _ember['default'].inject.service(),
    model: null,
    modelName: null,
    routePathPrefix: null,
    routePath: _ember['default'].computed('routePathPrefix', function () {
      return this.get('routePathPrefix') + '.';
    }),

    canEdit: _ember['default'].computed('routePath', function () {
      return this.get('can').can('edit ' + this.get('modelName'), this.get('model'));
    })

  });
});