define("scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 263
              },
              "end": {
                "line": 11,
                "column": 374
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/repeat.svg"], ["class", "bookingRequestOverview-recurrence-icon"], ["loc", [null, [11, 294], [11, 374]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "data-span", "true");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "date-span", "true");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'datetime');
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createAttrMorph(element3, 'datetime');
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element3, 2, 2);
          morphs[5] = dom.createMorphAt(element3, 4, 4);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDateTime", ["loc", [null, [10, 42], [10, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [10, 104], [10, 123]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateTimeFormat", ["loc", [null, [10, 131], [10, 152]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 86], [10, 154]]], 0, 0], ["attribute", "datetime", ["get", "model.endDateTime", ["loc", [null, [11, 42], [11, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [11, 102], [11, 119]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateFormat", ["loc", [null, [11, 127], [11, 144]]], 0, 0, 0, 0]], [], [], 0, 0], "subtractDayOnMidnight", true], ["loc", [null, [11, 84], [11, 173]]], 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [11, 192], [11, 209]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [11, 217], [11, 234]]], 0, 0, 0, 0]], [], [], 0, 0], "midnightAsTwentyFour", true], ["loc", [null, [11, 174], [11, 262]]], 0, 0], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [11, 269], [11, 292]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 263], [11, 381]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 8
              },
              "end": {
                "line": 17,
                "column": 152
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/repeat.svg"], ["class", "bookingRequestOverview-recurrence-icon", "tabindex", "-1", "aria-hidden", "true"], ["loc", [null, [17, 39], [17, 152]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 18,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-start");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" -\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-end");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'datetime');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDateTime", ["loc", [null, [13, 25], [13, 44]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [13, 87], [13, 106]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateFormat", ["loc", [null, [13, 114], [13, 131]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 69], [13, 133]]], 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [14, 55], [14, 74]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [14, 82], [14, 99]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 37], [14, 101]]], 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [15, 53], [15, 70]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [15, 78], [15, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "midnightAsTwentyFour", true], ["loc", [null, [15, 35], [15, 123]]], 0, 0], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [17, 14], [17, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 8], [17, 159]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 136
              },
              "end": {
                "line": 20,
                "column": 256
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("(");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(": ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(")");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_overview.occurrences"], [], ["loc", [null, [20, 177], [20, 232]]], 0, 0], ["content", "occurrencesLength", ["loc", [null, [20, 234], [20, 255]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 20,
              "column": 271
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "bookingRequestOverview-recurrenceDescription");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["content", "recurrenceDescription", ["loc", [null, [20, 110], [20, 135]]], 0, 0, 0, 0], ["block", "if", [["get", "showOccurrencesCountAfterPattern", ["loc", [null, [20, 142], [20, 174]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 136], [20, 263]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 4
              },
              "end": {
                "line": 30,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "tabindex", "0");
            dom.setAttribute(el1, "class", "bookingRequestOverview-timeDate-right");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "button-print", [], ["onClick", ["subexpr", "route-action", ["onPrintClick", ["subexpr", "hash", [], ["printType", "booking", "model", ["get", "model", ["loc", [null, [28, 90], [28, 95]]], 0, 0, 0, 0]], ["loc", [null, [28, 58], [28, 96]]], 0, 0]], [], ["loc", [null, [28, 29], [28, 97]]], 0, 0], "location", "at-bookingRequestOverview"], ["loc", [null, [28, 6], [28, 136]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 1
            },
            "end": {
              "line": 31,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [26, 14], [26, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [26, 4], [30, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestOverview-timeDate-left");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [1]);
        var element5 = dom.childAt(element4, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element4, 2, 2);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "bookingRequestOverview-timeDate-icon"], ["loc", [null, [5, 2], [5, 82]]], 0, 0], ["block", "if", [["get", "spansMultipleDays", ["loc", [null, [9, 12], [9, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 6], [18, 13]]]], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [20, 12], [20, 35]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [20, 6], [20, 278]]]], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [25, 11], [25, 25]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [25, 1], [31, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});