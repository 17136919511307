define('scientia-resourcebooker/pods/components/booking-type-notification-banner/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bookingTypeNotificationBanner'],
    attributeBindings: ['role', 'aria-label'],
    role: 'region',
    'aria-label': (0, _emberI18n.translationMacro)('components.booking_type_notification_banner.alert_banner'),
    classNameBindings: ['location', 'isAlert:bookingTypeNotificationBanner--alert', 'isInfo:bookingTypeNotificationBanner--info'],
    location: '',
    isAlert: _ember['default'].computed.bool('model.alert'),
    isInfo: _ember['default'].computed.bool('model.info')
  });
});