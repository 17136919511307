define('scientia-resourcebooker/breakpoints', ['exports'], function (exports) {
  // export default {
  //   mobile:  '(max-width: 767px)',
  //   tablet:  '(min-width: 768px) and (max-width: 991px)',
  //   desktop: '(min-width: 992px) and (max-width: 1200px)'
  // };

  exports['default'] = {
    xsmall: '(max-width: 450px)',
    small: '(min-width: 451px) and (max-width: 600px)',
    medium: '(min-width: 601px) and (max-width: 900px)',
    large: '(min-width: 901px) and (max-width: 1250)',
    'extra-large': '(min-width: 1251px)',

    tiny: '(max-width: 600px)',
    mobile: '(max-width: 900px)',
    desktop: '(min-width: 901px) and (max-width: 1250px)',
    jumbo: '(min-width: 1251px) and (max-width: 1599px)',
    massive: '(min-width: 1600px)'
  };

  // $page-width-extra-large: 1250px;
  // $page-width-large: 1085px;
  // $page-width-medium: 900px;
  // $page-width-small: 600px;
  // $page-width-xsmall: 450px;
  // $page-width-xxsmall: 320px;
});