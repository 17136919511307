define("scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/claims-form/claim-value", [], ["excludeGroup", true, "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [5, 76], [5, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "claim", ["subexpr", "@mut", [["get", "permission.permissionValue.permissionTarget", ["loc", [null, [5, 91], [5, 134]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-permissionWithClaimForm"], ["loc", [null, [5, 6], [5, 174]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "permission.permissionValue.permissionTarget", ["loc", [null, [8, 14], [8, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [9, 21], [9, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 16], [9, 30]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "didSelect", ["subexpr", "action", ["didSelectPermissionTarget", ["get", "permission", ["loc", [null, [12, 54], [12, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 18], [12, 65]]], 0, 0], "query", "searchUserGroups", "placeholder", ["subexpr", "t", ["components.manage_resources.permissions_form.request_target_placeholder"], [], ["loc", [null, [14, 20], [14, 97]]], 0, 0], "class", "manageResources-itemForm-form-input at-permissionsForm"], ["loc", [null, [7, 6], [16, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 27,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--white at-buttonGroup at-permissionsForm at-claimToggler");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'aria-label');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.permissions_form.toggle_claim_mode"], [], ["loc", [null, [23, 20], [23, 90]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleClaimModeForPermissionTarget", ["get", "permission", ["loc", [null, [21, 62], [21, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 16], [21, 74]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", ["subexpr", "concat-strings", ["manageResources-itemForm-form-groupActions-icon ", ["subexpr", "if", [["get", "permission.permissionValue.permissionTarget.isClaimMode", ["loc", [null, [25, 115], [25, 170]]], 0, 0, 0, 0], "manageResources-itemForm-form-groupActions-icon--active", ""], [], ["loc", [null, [25, 111], [25, 232]]], 0, 0]], [], ["loc", [null, [25, 44], [25, 233]]], 0, 0]], ["loc", [null, [25, 8], [25, 235]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 6
            },
            "end": {
              "line": 36,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/claims-form/claim-value", [], ["claim", ["subexpr", "@mut", [["get", "permission.permissionValue.approvers", ["loc", [null, [35, 57], [35, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "excludeGroup", true, "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [35, 121], [35, 129]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-permissionWithClaimForm"], ["loc", [null, [35, 8], [35, 170]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 6
            },
            "end": {
              "line": 48,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "permission.permissionValue.approvers", ["loc", [null, [38, 14], [38, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [39, 21], [39, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 16], [39, 30]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "multiple", true, "query", "searchUserGroups", "placeholder", ["subexpr", "t", ["components.manage_resources.permissions_form.request_approvers_placeholder"], [], ["loc", [null, [44, 20], [44, 100]]], 0, 0], "class", "manageResources-itemForm-form-input at-permissionsForm"], ["loc", [null, [37, 8], [47, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--white at-buttonGroup at-permissionsForm at-claimToggler");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'aria-label');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.permissions_form.toggle_claim_mode"], [], ["loc", [null, [51, 30], [51, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleClaimModeForApprover", ["get", "permission", ["loc", [null, [51, 140], [51, 150]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 102], [51, 152]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", ["subexpr", "concat-strings", ["manageResources-itemForm-form-groupActions-icon ", ["subexpr", "if", [["get", "permission.permissionValue.approvers.isClaimMode", ["loc", [null, [51, 353], [51, 401]]], 0, 0, 0, 0], "manageResources-itemForm-form-groupActions-icon--active", ""], [], ["loc", [null, [51, 349], [51, 463]]], 0, 0]], [], ["loc", [null, [51, 282], [51, 464]]], 0, 0]], ["loc", [null, [51, 246], [51, 466]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/request/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--column manageResources-itemForm-form-item--requestRule");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-requestRuleOptionWrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "buttonGroup manageResources-itemForm-form-groupActions at-claimsForm");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label at-permissionFormApprovers");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-requestRuleOptionWrapper at-requestRuleApprovers");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "buttonGroup manageResources-itemForm-form-groupActions at-claimsForm");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(element4, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "permission.permissionValue.permissionTarget.isClaimMode", ["loc", [null, [4, 10], [4, 65]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 4], [17, 11]]]], ["block", "if", [["subexpr", "not", [["get", "preventClaimToggler", ["loc", [null, [20, 17], [20, 36]]], 0, 0, 0, 0], ["get", "disabled", ["loc", [null, [20, 37], [20, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 12], [20, 46]]], 0, 0]], [], 2, null, ["loc", [null, [20, 6], [27, 13]]]], ["inline", "t", ["components.manage_resources.permissions_form.request_approvers_title"], [], ["loc", [null, [31, 80], [31, 156]]], 0, 0], ["block", "if", [["get", "permission.permissionValue.approvers.isClaimMode", ["loc", [null, [34, 12], [34, 60]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [34, 6], [48, 13]]]], ["block", "if", [["subexpr", "not", [["get", "preventClaimToggler", ["loc", [null, [50, 19], [50, 38]]], 0, 0, 0, 0], ["get", "disabled", ["loc", [null, [50, 39], [50, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 14], [50, 48]]], 0, 0]], [], 5, null, ["loc", [null, [50, 8], [52, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});