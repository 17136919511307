define("scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "modalTitle", ["loc", [null, [2, 2], [2, 16]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 17,
                        "column": 14
                      },
                      "end": {
                        "line": 21,
                        "column": 14
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "bookingRequestEditRestrictionsModal-affectedResource-name");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element3, 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "bookingRequestOverview-resourcesList-resource-icon"], ["loc", [null, [18, 20], [18, 109]]], 0, 0], ["content", "r.name", ["loc", [null, [19, 90], [19, 100]]], 0, 0, 0, 0]],
                  locals: ["r"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 12
                    },
                    "end": {
                      "line": 23,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "bookingRequestEditRestrictionsModal-affectedResources");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "each", [["get", "restrictionOther.affectedResources", ["loc", [null, [17, 22], [17, 56]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 14], [21, 23]]]]],
                locals: ["restrictionOther"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 8
                  },
                  "end": {
                    "line": 25,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("           ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_multiple"], [], ["loc", [null, [13, 14], [13, 84]]], 0, 0], ["block", "with", [["subexpr", "find-by", ["messageKey", "RR_Other", ["get", "model.restrictions", ["loc", [null, [15, 53], [15, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 20], [15, 72]]], 0, 0]], [], 0, null, ["loc", [null, [15, 12], [23, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 33,
                            "column": 16
                          },
                          "end": {
                            "line": 37,
                            "column": 16
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                      },
                      isEmpty: false,
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("span");
                        dom.setAttribute(el2, "class", "bookingRequestEditRestrictionsModal-affectedResource-name");
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                  ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element2 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(element2, 0, 0);
                        morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
                        return morphs;
                      },
                      statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "bookingRequestOverview-resourcesList-resource-icon"], ["loc", [null, [34, 22], [34, 111]]], 0, 0], ["content", "r.name", ["loc", [null, [35, 92], [35, 102]]], 0, 0, 0, 0]],
                      locals: ["r"],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 31,
                          "column": 14
                        },
                        "end": {
                          "line": 39,
                          "column": 14
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("ul");
                      dom.setAttribute(el1, "class", "bookingRequestEditRestrictionsModal-affectedResources");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "each", [["get", "restriction.affectedResources", ["loc", [null, [33, 24], [33, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 16], [37, 25]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 30,
                        "column": 12
                      },
                      "end": {
                        "line": 40,
                        "column": 12
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "unless", [["subexpr", "eq", [["subexpr", "underscore", [["get", "restriction.messageKey", ["loc", [null, [31, 40], [31, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 28], [31, 63]]], 0, 0], "rr_max_limit_changed"], [], ["loc", [null, [31, 24], [31, 87]]], 0, 0]], [], 0, null, ["loc", [null, [31, 14], [39, 25]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 10
                    },
                    "end": {
                      "line": 41,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", [["subexpr", "concat-strings", ["components.booking_request_edit_restrictions_modal.", ["subexpr", "underscore", [["get", "restriction.messageKey", ["loc", [null, [28, 101], [28, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 89], [28, 124]]], 0, 0]], [], ["loc", [null, [28, 19], [28, 125]]], 0, 0]], [], ["loc", [null, [28, 15], [28, 127]]], 0, 0], ["block", "if", [["get", "restriction.affectedResources", ["loc", [null, [30, 18], [30, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [30, 12], [40, 19]]]]],
                locals: ["restriction"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 8
                  },
                  "end": {
                    "line": 43,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "with", [["get", "model.restrictions.firstObject", ["loc", [null, [27, 18], [27, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 10], [41, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 4
                },
                "end": {
                  "line": 47,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "if", [["get", "hasMultipleOtherRestrictions", ["loc", [null, [12, 14], [12, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [12, 8], [43, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 3
              },
              "end": {
                "line": 52,
                "column": 3
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasRestrictions", ["loc", [null, [10, 10], [10, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 4], [47, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 6
                },
                "end": {
                  "line": 59,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "concat-strings", ["components.booking_request_edit_restrictions_modal.", ["subexpr", "underscore", [["get", "model.restrictions.firstObject.messageKey", ["loc", [null, [56, 94], [56, 135]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 82], [56, 136]]], 0, 0]], [], ["loc", [null, [56, 12], [56, 137]]], 0, 0]], [], ["loc", [null, [56, 8], [56, 139]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 8
                  },
                  "end": {
                    "line": 62,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_occurrence"], [], ["loc", [null, [61, 10], [61, 82]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 8
                  },
                  "end": {
                    "line": 64,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_primary"], [], ["loc", [null, [63, 8], [63, 77]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 6
                },
                "end": {
                  "line": 66,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "if", [["get", "model.isOccurrence", ["loc", [null, [60, 14], [60, 32]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [60, 8], [64, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 3
              },
              "end": {
                "line": 68,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasRestrictions", ["loc", [null, [55, 12], [55, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [55, 6], [66, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "confirmModal-message bookingRequestEditRestrictionsModal-message");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "canEdit", ["loc", [null, [8, 9], [8, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 3], [68, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 2
                },
                "end": {
                  "line": 84,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.edit"], [], ["loc", [null, [83, 4], [83, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 0
              },
              "end": {
                "line": 86,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["element", "action", ["cancel"], [], ["loc", [null, [78, 10], [78, 29]]], 0, 0], ["inline", "t", ["general.cancel"], [], ["loc", [null, [79, 4], [79, 26]]], 0, 0], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "buttonIsDisabled", ["loc", [null, [82, 26], [82, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "confirm", "type", "submit", "class", "button button--primary at-lf-dialog-footer"], 0, null, ["loc", [null, [82, 2], [84, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 0
              },
              "end": {
                "line": 90,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["cancel"], [], ["loc", [null, [87, 12], [87, 31]]], 0, 0], ["inline", "t", ["general.ok"], [], ["loc", [null, [88, 4], [88, 22]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 0
            },
            "end": {
              "line": 91,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "canEdit", ["loc", [null, [76, 6], [76, 13]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [76, 0], [90, 7]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["needsCloseButton", false], 0, null, ["loc", [null, [1, 0], [3, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [5, 0], [72, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [74, 0], [91, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});