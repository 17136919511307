define('scientia-resourcebooker/services/locale-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  var getOwner = _ember['default'].getOwner;

  var LOCALES = [{ id: 'cy', text: 'Cymraeg' }, { id: 'da', text: 'Dansk' }, { id: 'de', text: 'Deutsch' }, { id: 'en', text: 'English' }, { id: 'ga', text: 'Gaeilge' }, { id: 'nl', text: 'Nederlands' }, { id: 'zh-cn', text: '中文' }];

  exports['default'] = _ember['default'].Service.extend({
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    configFetcher: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    profileProcessor: _ember['default'].inject.service(),
    canShowCalendar: true,

    localesLoaded: [],

    localesWithLabel: LOCALES,

    /**
     * List of available locale Ids
     */
    locales: _ember['default'].computed(function () {
      return LOCALES.map(function (loc) {
        return loc.id;
      });
    }),

    /**
     * Checks if the passed in locale Id is a valid one
     * @param string locale The locale to check
     * @returns boolean True if it's a valid locale Id
     */
    isValidLocale: function isValidLocale(locale) {
      return this.get('locales').includes(locale);
    },

    /**
     * Checks if the locale has already been loaded
     * @param string} locale
     * @returns boolean If the locale has been loaded
     */
    isLocaleLoaded: function isLocaleLoaded(locale) {
      return this.get('localesLoaded').includes(locale);
    },

    /**
     * Action to set the locale
     * @param string locale The new locale to set
     * @param boolean userAction If it was triggered by an action (or simply by application loading user profile)
     * @returns
     */
    setLocale: function setLocale(locale, userAction) {
      var _this2 = this;

      // If not a valid locale, calculate the locale based on local storage / systemSetting
      if (!this.isValidLocale(locale)) {
        locale = this.calculateLocale();
      }

      // If locale not loaded, load it and recall
      if (!this.isLocaleLoaded(locale)) {
        return this.loadLocaleAsync(locale).then(function () {
          return _this2.setLocale(locale, userAction);
        });
      }

      // Set the locale
      this.get('i18n').set('locale', locale);
      moment.locale(locale);

      // Save locale selection in localStorage and translations
      localStorage.setItem('scientia-rb-language', locale);
      this.saveTranslationsInStorage(locale);

      // Refresh calendar
      this.set('canShowCalendar', false);
      _ember['default'].run.later(this, function () {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('canShowCalendar', true);
        }
      }, 200);

      // Trigger customEvent
      this.get('customEvents').triggerLocaleChanged();

      // If triggered by user log and save to Profile
      if (userAction) {
        this.get('appInsights').trackEvent('language-change', { language: locale });
        this.get('profileProcessor').setLanguage(locale);
        this.get('profileProcessor').save();
      }

      // Update HTML lang attr
      var langAttr = locale === 'zh-cn' ? 'zh' : locale;
      document.querySelector('html').setAttribute('lang', langAttr);

      // return Ember.RSVP.resolve(true);
    },

    saveTranslationsInStorage: function saveTranslationsInStorage(locale) {
      window.translations = getOwner(this)._lookupFactory('locale:' + locale + '/translations') || {};
    },

    initLocale: function initLocale() {
      var locale = this.calculateLocale();
      return this.setLocale(locale);
    },

    loadUserLanguage: function loadUserLanguage(language) {
      this.setLocale(language);
    },

    calculateLocale: function calculateLocale() {
      return localStorage.getItem('scientia-rb-language') || this.get('configFetcher.language') || 'en';
    },

    loadLocaleAsync: function loadLocaleAsync(locale) {
      var _this3 = this;

      if (!locale || this.isLocaleLoaded(locale)) return _ember['default'].RSVP.resolve();
      var version = _scientiaResourcebookerConfigEnvironment['default'].WEB_VERSION;
      return _ember['default'].$.getJSON('/assets/locales/' + locale + '.json?v=' + version).then(function (response) {
        _this3.get('localesLoaded').push(locale);

        // Add fetched locale
        _this3.addLocaleTranslations(locale, response);

        // Add any custom translations for this locale
        var customTranslations = _this3.get('configFetcher.translations');
        _this3.addCustomTranslations(locale, customTranslations);
        return true;
      });
    },

    addLocaleTranslations: function addLocaleTranslations(locale, translations) {
      var localeData = {};
      localeData[locale] = this.withFlattenedKeys(translations);
      this.addCustomTranslations(locale, localeData, false);
    },

    addCustomTranslations: function addCustomTranslations(locale, translations) {
      var forceParse = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var i18n = this.get('i18n');

      try {
        var _ret = (function () {

          var json = forceParse ? JSON.parse(translations) : translations;
          if (locale in json) {
            Object.keys(json[locale]).forEach(function (key) {
              var translation = {};
              translation[key] = json[locale][key];
              i18n.addTranslations(locale, translation);
            });
          }
          return {
            v: true
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } catch (e) {
        return false;
      }
    },

    getChronosTranslations: function getChronosTranslations() {
      return this.unflatJson(window.translations).calendar;
    },

    /* jshint ignore:start */
    unflatJson: function unflatJson(json) {
      var unflatten = {};

      var _loop = function (item) {
        var _this = undefined;
        var splittedKey = item.split('.');

        splittedKey.map(function (keysPart, i) {

          if (i === 0) {
            _this = unflatten;
          }

          if (!_this[keysPart]) {
            if (splittedKey.length === i + 1) {
              _this[keysPart] = json[item];
            } else {

              _this[keysPart] = {};
              _this = _this[keysPart];
            }
          } else {
            _this = _this[keysPart];
          }
        });
      };

      for (var item in json) {
        _loop(item);
      }

      return unflatten;
    },
    /* jshint ignore:end */

    // Flattens json
    withFlattenedKeys: function withFlattenedKeys(object) {
      var _this4 = this;

      var result = {};

      Object.keys(object).forEach(function (key) {
        var value = object[key];

        if (_ember['default'].typeOf(value) === 'object') {
          value = _this4.withFlattenedKeys(value);

          Object.keys(value).forEach(function (suffix) {
            result[key + '.' + suffix] = value[suffix];
          });
        } else {
          result[key] = value;
        }
      });

      return result;
    }
  });
});