define('scientia-resourcebooker/services/booking-type-form-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    fetch: function fetch(bookingTypeId, resourceId) {
      var _this = this;

      var url = undefined;
      if (resourceId) {
        url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_TYPE_FORM_EDIT_FROM_MANAGE_RESOURCES.loc(bookingTypeId, resourceId);
      } else {
        url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_TYPE_FORM.loc(bookingTypeId);
      }
      return this.get('authenticatedRequest').fetch(url).then(function (data) {
        if (!data) return;
        var serializer = _this.get('store').serializerFor('booking-form');
        var typeClass = _this.get('store').modelFor('booking-form');

        var serializedData = undefined;

        serializedData = serializer.normalizeSingleResponse(_this.get('store'), typeClass, data, data.id);
        return _this.get('store').push(serializedData);
      });
    }

  });
});