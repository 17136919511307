define('scientia-resourcebooker/helpers/ignore-children', ['exports', 'ember-ignore-children-helper/helpers/ignore-children'], function (exports, _emberIgnoreChildrenHelperHelpersIgnoreChildren) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberIgnoreChildrenHelperHelpersIgnoreChildren['default'];
    }
  });
  Object.defineProperty(exports, 'ignoreChildren', {
    enumerable: true,
    get: function get() {
      return _emberIgnoreChildrenHelperHelpersIgnoreChildren.ignoreChildren;
    }
  });
});