define("scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "title", ["loc", [null, [3, 4], [3, 13]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["subexpr", "optional", [["get", "close", ["loc", [null, [2, 44], [2, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 34], [2, 50]]], 0, 0]], [], ["loc", [null, [2, 26], [2, 51]]], 0, 0]], 0, null, ["loc", [null, [2, 2], [4, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 28,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [22, 37], [22, 48]]], 0, 0, 0, 0], 3], [], ["loc", [null, [22, 33], [22, 51]]], 0, 0], "selected"], [], ["loc", [null, [22, 28], [22, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tabChange", 3], [], ["loc", [null, [22, 66], [22, 90]]], 0, 0], ["inline", "inline-svg", ["icons/users.svg"], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [24, 10], [24, 78]]], 0, 0], ["inline", "t", ["components.permissions_summary.user_groups"], [], ["loc", [null, [25, 16], [25, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 41,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["permissionSummary-tabContent permissionSummary-tabContent--", ["get", "dataValue", ["loc", [null, [35, 79], [35, 88]]], 0, 0, 0, 0], "  ", ["subexpr", "if", [["subexpr", "compute", [["subexpr", "action", ["isTabHidden", ["get", "dataValue", ["loc", [null, [35, 128], [35, 137]]], 0, 0, 0, 0], ["get", "selectedTab", ["loc", [null, [35, 138], [35, 149]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 106], [35, 150]]], 0, 0]], [], ["loc", [null, [35, 97], [35, 151]]], 0, 0], "permissionSummary-tabContent--hidden", ""], [], ["loc", [null, [35, 92], [35, 195]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "manage-resources/permissions-summary/tab-content", [], ["model", ["subexpr", "get", [["get", "this", ["loc", [null, [37, 19], [37, 23]]], 0, 0, 0, 0], ["get", "dataValue", ["loc", [null, [37, 24], [37, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 14], [37, 34]]], 0, 0], "isUserGroupTab", ["subexpr", "eq", [["get", "dataValue", ["loc", [null, [38, 27], [38, 36]]], 0, 0, 0, 0], "userGroupsPermissions"], [], ["loc", [null, [38, 23], [38, 61]]], 0, 0]], ["loc", [null, [36, 8], [39, 10]]], 0, 0]],
          locals: ["index", "dataValue"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-tabs");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-content");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [0]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element4, [3]);
          var element8 = dom.childAt(element7, [1]);
          var morphs = new Array(10);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createElementMorph(element5);
          morphs[2] = dom.createMorphAt(element6, 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
          morphs[4] = dom.createAttrMorph(element7, 'class');
          morphs[5] = dom.createElementMorph(element7);
          morphs[6] = dom.createMorphAt(element8, 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
          morphs[8] = dom.createMorphAt(element4, 5, 5);
          morphs[9] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [9, 33], [9, 44]]], 0, 0, 0, 0], 1], [], ["loc", [null, [9, 29], [9, 47]]], 0, 0], "selected"], [], ["loc", [null, [9, 24], [9, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tabChange", 1], [], ["loc", [null, [9, 62], [9, 86]]], 0, 0], ["inline", "inline-svg", ["icons/security.svg"], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [11, 8], [11, 79]]], 0, 0], ["inline", "t", ["components.permissions_summary.administrative"], [], ["loc", [null, [12, 14], [12, 67]]], 0, 0], ["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [15, 35], [15, 46]]], 0, 0, 0, 0], 2], [], ["loc", [null, [15, 31], [15, 49]]], 0, 0], "selected"], [], ["loc", [null, [15, 26], [15, 62]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tabChange", 2], [], ["loc", [null, [15, 64], [15, 88]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [17, 8], [17, 75]]], 0, 0], ["inline", "t", ["components.permissions_summary.booking"], [], ["loc", [null, [18, 14], [18, 60]]], 0, 0], ["block", "if", [["get", "showUserGroupsTab", ["loc", [null, [21, 10], [21, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 4], [28, 11]]]], ["block", "each-in", [["get", "tabs", ["loc", [null, [34, 15], [34, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [34, 4], [41, 16]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 2
              },
              "end": {
                "line": 49,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", [["get", "close", ["loc", [null, [48, 21], [48, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 12], [48, 28]]], 0, 0], ["inline", "t", ["general.close"], [], ["loc", [null, [48, 78], [48, 99]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 0
            },
            "end": {
              "line": 50,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-footer", [], [], 0, null, ["loc", [null, [47, 2], [49, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "hideHeader", ["loc", [null, [1, 10], [1, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [5, 11]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [7, 0], [44, 18]]]], ["block", "unless", [["get", "hideFooter", ["loc", [null, [46, 10], [46, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [46, 0], [50, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});