define('scientia-resourcebooker/helpers/datetime-format', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Helper.extend({

    i18n: _ember['default'].inject.service(),

    compute: function compute(_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 1);

      var date = _ref2[0];

      if (!date) return '';
      if (!moment.isMoment(date) && moment(date).isValid()) date = moment(date);

      var format = hash.format && hash.format.string ? hash.format.string : hash.format;

      if (hash.subtractDayOnMidnight && hash.midnightAsTwentyFour) {
        throw 'datetime-format helper: subtractDayOnMidnight should not be used together with midnightAsTwentyFour';
      }
      var twentyFourLabel = this.get('i18n').t('date_formats.midnight_twenty_four').toString();

      if (!format) return date.format();

      // For dates only: When formatting a date we can pass in subtractDayOnMidnight to check if the full date ends at midnight
      // we'll subtract 1 day so we can use it in conjunction with midnightAsTwentyFour when rendering the time in a different html element.
      if (hash.subtractDayOnMidnight & date.hour() === 0 && date.minute() === 0) {
        var changedDate = date.clone().subtract(1, 'day');
        return changedDate.format(format);
      }

      // For time only: midnightAsTwentyFour . If the hour ends up being 00:00 we'll replace it with 24:00 (or the the current locale equivalent)
      if (hash.midnightAsTwentyFour & date.hour() === 0 && date.minute() === 0) {
        return twentyFourLabel;
      }

      return moment(date).format(format);
    }

  });
});