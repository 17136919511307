define('scientia-resourcebooker/pods/components/section-title/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':sectionTitle', 'location'],
    location: '',
    icon: '',
    actionIcon: null,
    actionTitle: null,
    beta: false,

    actions: {

      clickAction: function clickAction() {
        this.sendAction('clickAction');
      }
    }
  });
});