define("scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/claims-form/claim-value", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [2, 54], [2, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "claim", ["subexpr", "@mut", [["get", "permission.permissionValue", ["loc", [null, [2, 69], [2, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "excludeGroup", true, "location", "at-permissionWithClaimForm"], ["loc", [null, [2, 2], [2, 153]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "permission.permissionValue", ["loc", [null, [7, 10], [7, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [8, 17], [8, 25]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 12], [8, 26]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "query", "searchUserGroups", "didSelect", ["subexpr", "action", ["didSelectPermissionGroup", ["get", "permission", ["loc", [null, [12, 49], [12, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 14], [12, 60]]], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.resource_owner_list.add_resource_owners"], [], ["loc", [null, [13, 16], [13, 89]]], 0, 0], "class", "manageResources-itemForm-form-input at-permissionsForm"], ["loc", [null, [6, 2], [16, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--white at-buttonGroup at-permissionsForm at-claimToggler");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'aria-label');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.permissions_form.toggle_claim_mode"], [], ["loc", [null, [20, 24], [20, 94]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "toggleClaimMode", ["loc", [null, [20, 105], [20, 120]]], 0, 0, 0, 0], ["get", "permission", ["loc", [null, [20, 121], [20, 131]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 96], [20, 133]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", ["subexpr", "concat-strings", ["manageResources-itemForm-form-groupActions-icon ", ["subexpr", "if", [["get", "permission.isClaimMode", ["loc", [null, [20, 334], [20, 356]]], 0, 0, 0, 0], "manageResources-itemForm-form-groupActions-icon--active", ""], [], ["loc", [null, [20, 330], [20, 418]]], 0, 0]], [], ["loc", [null, [20, 263], [20, 419]]], 0, 0]], ["loc", [null, [20, 227], [20, 421]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "buttonGroup manageResources-itemForm-form-groupActions at-claimsForm");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "permission.isClaimMode", ["loc", [null, [1, 6], [1, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [17, 7]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "preventClaimToggler", ["loc", [null, [19, 18], [19, 37]]], 0, 0, 0, 0], ["get", "disabled", ["loc", [null, [19, 38], [19, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 13], [19, 47]]], 0, 0]], [], ["loc", [null, [19, 8], [19, 48]]], 0, 0]], [], 2, null, ["loc", [null, [19, 2], [21, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});