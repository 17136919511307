define('scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/component', ['exports', 'ember'], function (exports, _ember) {

  var RULES_PROPERTY_PATH = 'bookingType.resourceRestrictionRules';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-groupWrapper manageResources-resourceRestrictions-form'],
    store: _ember['default'].inject.service(),
    disabled: false,

    actions: {
      addItem: function addItem() {
        if (this.get('disabled')) return false;
        var newRule = this.get('store').createRecord('resource-restriction-rule', { min: 0, max: 1 });

        var rules = this.get(RULES_PROPERTY_PATH);
        if (rules.get('length') === 0) newRule.set('isPrimary', true);

        rules.addObject(newRule);
      },

      deleteItem: function deleteItem(index) {
        var _this = this;

        if (this.get('disabled')) return false;
        var deferred = new _ember['default'].RSVP.defer();
        deferred.promise.then(function () {
          _this.get(RULES_PROPERTY_PATH).removeAt(index);
        });
        this.attrs.deleteItem(deferred);
      },

      // onSearchResourceType(query, deferred) {
      //   this.sendAction('onSearchResourceType', query, deferred);
      // },

      // onSearchResourceGroup(rule, query, deferred) {
      //   this.sendAction('onSearchResourceGroup', rule, query, deferred);
      // },

      onSetPrimary: function onSetPrimary(rule) {
        if (this.get('disabled')) return false;
        var rules = this.get(RULES_PROPERTY_PATH);
        rules.setEach('isPrimary', false);
        _ember['default'].run.next(this, function () {
          rule.set('isPrimary', true);
        });
      }
    }
  });
});