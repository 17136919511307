define('scientia-resourcebooker/pods/components/dropdown-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['dropDownButton-wrapper'],
    classNameBindings: ['location', 'isShowing:dropDownButton-wrapper--showing', 'icon:dropDownButton-wrapper--hasIcon'],
    label: '',
    disabled: false,
    classes: '',
    contentClasses: '',
    location: '',
    icon: null,
    isShowing: false,

    containerIsModal: false,

    mouseEnter: function mouseEnter() {
      this._adjustContent();
    },

    mouseLeave: function mouseLeave() {

      var $content = this.$('.dropdownButton-content');
      $content.removeClass('at-positionLeft at-positionTop');
    },

    click: function click() {
      this._adjustContent();
    },

    _adjustContent: function _adjustContent() {

      var $content = this.$('.dropdownButton-content');
      if (!$content.length) return;

      var contentHeight = $content.height();
      var contentOffsetTop = $content.offset().top;

      var contentWidth = $content.width();
      var contentOffsetLeft = $content.offset().left;

      var documentHeight = _ember['default'].$(document).height();
      var documentWidth = _ember['default'].$(document).width();

      if (contentOffsetLeft + contentWidth + 30 > documentWidth) {
        // console.log('Content is overflowing right side of screen');
        $content.addClass('at-positionLeft');
      }

      if (contentOffsetTop + contentHeight + 10 > documentHeight) {
        // console.log('Content is overflowing bottom');
        $content.addClass('at-positionTop');
      }
    }
  });
});