define('scientia-resourcebooker/pods/manage-resources/user-lookup/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    appInsights: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.user_lookup.index.page_title');
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        page: transition.queryParams.page || '1',
        query: transition.queryParams.query
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        return this.refresh();
      }
    }
  });
});