define('scientia-resourcebooker/services/resource-events-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var bookingTypeId = _ref.bookingTypeId;
      var resourceTypeId = _ref.resourceTypeId;
      var resourceId = _ref.resourceId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var includeBookingTypes = _ref.includeBookingTypes;
      var includeConcurrencyGroups = _ref.includeConcurrencyGroups;
      var includeLinkedAvailability = _ref.includeLinkedAvailability;
      var forceResync = _ref.forceResync;

      // Default endpoint for a resource BusyTimes
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.resource.busyTime.loc(bookingTypeId, resourceId);

      // If there is a resourceTypeId instead of bookingTypeId it means this is to be viewed in the
      // resource calendar so we use a different endpoint
      if (resourceTypeId) {
        url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_ADMIN_BUSY_TIMES.loc(resourceTypeId, resourceId);
      }

      var data = {
        startDate: startDate.startOf('minute').toISOString(),
        endDate: endDate.startOf('minute').toISOString(),
        forceResync: forceResync,
        includeBookingTypes: includeBookingTypes,
        includeConcurrencyGroups: includeConcurrencyGroups,
        includeLinkedAvailability: includeLinkedAvailability,
        excludeExamEvents: false
      };

      return this.get('authenticatedRequest').fetch(url, data).then(function (response) {
        return _this._normalizeResponse(response);
      });
    },

    _normalizeResponse: function _normalizeResponse(response) {
      var _this2 = this;

      if (!response) return [];

      // Filter out events with invalid unavailability types
      var results = response.filter(function (r) {
        return _this2._unavailabilityTypeIsValid(r.availabilityType);
      });
      results.forEach(function (r) {

        // Adjust events that span DST boundaries (have a different start and end utc offsets)
        var start = moment(r.startDateTime);
        var end = start.clone().add(r.duration, 'minute');

        var startOffset = start.clone().utcOffset();
        var endOffset = end.clone().utcOffset();

        //  Subtract 1 minute if the event ends at dst crossover
        if (endOffset !== end.clone().subtract(1, 'm').utcOffset()) {
          r.duration = r.duration - 1;
          endOffset = end.clone().subtract(1, 'm').utcOffset();
        }

        if (startOffset > endOffset) {
          r.duration += Math.abs(startOffset - endOffset);
        } else if (startOffset < endOffset) {
          r.duration -= Math.abs(startOffset - endOffset);
        }
      });

      return results;
    },

    /**
     * Helper to check if the unavailabilityType value (int) is valid
     * @param int unavailabilityType
     * @returns Boolean whether or not the unavailability Type is valid
     */
    _unavailabilityTypeIsValid: function _unavailabilityTypeIsValid(unavailabilityType) {
      return unavailabilityType < _scientiaResourcebookerConfigEnvironment['default'].UNAVAILABILITY_TYPES.length;
    }
  });
});