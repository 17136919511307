define('scientia-resourcebooker/pods/components/modal-header/component', ['exports', 'ember'], function (exports, _ember) {

  // Default autofocus element to focus on when modal initializes
  var DEFAULT_AUTOFOCUS_ELEMENT = 'main-modal-section';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'header',
    classNames: ['lf-dialog-header'],
    classNameBindings: ['class', 'needsCloseButton'],

    title: '',
    needsCloseButton: true,
    needsMinimizeButton: false,
    isMinimized: false,

    // Optional element selector to autofocus on after modal has been loaded
    autofocusOnElement: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        _ember['default'].run.later(function () {

          // Get element from the dom and if it exists autofocus
          var autofocusElement = _this.get('autofocusOnElement') || DEFAULT_AUTOFOCUS_ELEMENT;
          var el = document.querySelector('[id="' + autofocusElement + '"]');
          if (el) el.focus();
        }, 500);
      });
    },

    actions: {

      onHeaderClick: function onHeaderClick() {
        _ember['default'].$('.lf-dialog').removeClass('lf-dialog--minimized');
        this.set('isMinimized', false);
      },

      close: function close() {
        this.sendAction('onClose');
      },

      toggleMinimize: function toggleMinimize() {
        this.toggleProperty('isMinimized');
        _ember['default'].$('.lf-dialog').toggleClass('lf-dialog--minimized');
      }
    }
  });
});