define("scientia-resourcebooker/pods/manage-resources/resource/show/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [7, 12], [7, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [8, 25], [8, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 20], [8, 44]]], 0, 0], false], [], ["loc", [null, [8, 12], [8, 51]]], 0, 0], "showBookingRequestActions", ["subexpr", "@mut", [["get", "showBookingRequestActions", ["loc", [null, [9, 32], [9, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [10, 20], [10, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [11, 18], [11, 47]]], 0, 0]], ["loc", [null, [5, 9], [11, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [13, 32], [13, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 27], [13, 51]]], 0, 0], false], [], ["loc", [null, [13, 19], [13, 58]]], 0, 0]], ["loc", [null, [4, 7], [13, 59]]], 0, 0]], ["loc", [null, [2, 2], [13, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [22, 12], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 9], [22, 34]]], 0, 0]], ["loc", [null, [19, 7], [22, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [23, 37], [23, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 27], [23, 42]]], 0, 0]], [], ["loc", [null, [23, 19], [23, 43]]], 0, 0]], ["loc", [null, [17, 2], [24, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [34, 12], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [35, 25], [35, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 20], [35, 64]]], 0, 0], null], [], ["loc", [null, [35, 12], [35, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [36, 14], [36, 58]]], 0, 0]], ["loc", [null, [32, 9], [36, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [37, 32], [37, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 27], [37, 71]]], 0, 0], null], [], ["loc", [null, [37, 19], [37, 77]]], 0, 0]], ["loc", [null, [31, 7], [37, 78]]], 0, 0]], ["loc", [null, [29, 2], [38, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 0
            },
            "end": {
              "line": 70,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [60, 21], [60, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [61, 22], [61, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "name", "subtitlePath", "bookerName", "tagPath", "tag", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [65, 25], [65, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 20], [65, 50]]], 0, 0], null], [], ["loc", [null, [65, 12], [65, 56]]], 0, 0], "on-date-click", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [66, 38], [66, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 28], [66, 43]]], 0, 0]], [], ["loc", [null, [66, 20], [66, 44]]], 0, 0], "on-event-click", ["subexpr", "action", ["onEventClick"], [], ["loc", [null, [67, 21], [67, 44]]], 0, 0]], ["loc", [null, [58, 9], [67, 45]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [68, 32], [68, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [68, 27], [68, 57]]], 0, 0], null], [], ["loc", [null, [68, 19], [68, 63]]], 0, 0]], ["loc", [null, [57, 7], [68, 64]]], 0, 0]], ["loc", [null, [56, 2], [69, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["event-overview"], ["model", ["subexpr", "@mut", [["get", "showEventOverview", ["loc", [null, [78, 12], [78, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showEventOverview", ["loc", [null, [79, 25], [79, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 20], [79, 43]]], 0, 0], null], [], ["loc", [null, [79, 12], [79, 49]]], 0, 0]], ["loc", [null, [76, 9], [79, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showEventOverview", ["loc", [null, [80, 32], [80, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [80, 27], [80, 50]]], 0, 0], null], [], ["loc", [null, [80, 19], [80, 56]]], 0, 0]], ["loc", [null, [75, 7], [80, 57]]], 0, 0]], ["loc", [null, [73, 0], [80, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 6
              },
              "end": {
                "line": 107,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "templateUnavailability-toggler");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "toggler-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["pods.manage_resources.resource.show.show_concurrency_unavailability"], [], ["loc", [null, [104, 38], [104, 113]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "showConcurrencyUnavailability", ["loc", [null, [105, 44], [105, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [105, 34], [105, 74]]], 0, 0], "onToggle", ["subexpr", "action", ["toggleConcurrencyUnavailability"], [], ["loc", [null, [105, 84], [105, 126]]], 0, 0]], ["loc", [null, [105, 10], [105, 128]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 88,
              "column": 2
            },
            "end": {
              "line": 109,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle-actions");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "templateUnavailability-toggler");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "toggler-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["pods.manage_resources.resource.show.show_template_unavailability"], [], ["loc", [null, [98, 38], [98, 110]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "showTemplateUnavailability", ["loc", [null, [99, 44], [99, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 34], [99, 71]]], 0, 0], "onToggle", ["subexpr", "action", ["toggleTemplateUnavailability"], [], ["loc", [null, [99, 81], [99, 120]]], 0, 0]], ["loc", [null, [99, 10], [99, 122]]], 0, 0], ["block", "if", [["get", "model.concurrencyGroups.length", ["loc", [null, [102, 12], [102, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [102, 6], [107, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 4
            },
            "end": {
              "line": 128,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [114, 23], [114, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "calendarEvents", ["subexpr", "@mut", [["get", "model.events", ["loc", [null, [116, 23], [116, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "event-title-path", "name", "event-subtitle-path", "bookerName", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "tag", "show-tag-button", true, "on-event-click", "onEventClick", "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-sheet-change", "fetchEvents"], ["loc", [null, [113, 6], [127, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 133,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource/show/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-manageResources-resource");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [11]);
        var element4 = dom.childAt(element3, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[5] = dom.createMorphAt(element3, 1, 1);
        morphs[6] = dom.createAttrMorph(element4, 'aria-label');
        morphs[7] = dom.createMorphAt(element4, 1, 1);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showBookingRequest", ["loc", [null, [1, 6], [1, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [14, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [16, 6], [16, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 0], [25, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [28, 6], [28, 44]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [39, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [55, 6], [55, 30]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [55, 0], [70, 7]]]], ["block", "if", [["get", "showEventOverview", ["loc", [null, [72, 6], [72, 23]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [72, 0], [81, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [84, 39], [84, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [84, 0], [84, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [86, 118], [86, 146]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["icon", ["subexpr", "@mut", [["get", "model.resource.imageUri", ["loc", [null, [89, 7], [89, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["pods.manage_resources.resource.show.title"], ["name", ["get", "model.resource.name", ["loc", [null, [90, 59], [90, 78]]], 0, 0, 0, 0]], ["loc", [null, [90, 7], [90, 79]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [92, 14], [92, 73]]], 0, 0], "clickAction", "reloadView", "location", "at-manageResources-resource"], 5, null, ["loc", [null, [88, 2], [109, 22]]]], ["block", "if", [["get", "canShowCalendar", ["loc", [null, [112, 10], [112, 25]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [112, 4], [128, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});