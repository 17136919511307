define('scientia-resourcebooker/pods/manage-resources/resource-type/importer/permissions/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var PREFIX = 'pods.manage_resources.resource_type.importer.permissions.';

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    errorHandler: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    userGroupSearcher: _ember['default'].inject.service(),
    bulkExporter: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    resourceTypeSearcher: _ember['default'].inject.service(),

    importExportSelectedTab: 0,

    // previousTransition: null,
    // showUnsavedChangesModal: false,

    resourceTypesSelected: [],

    importOptions: _ember['default'].computed('i18n.locale', function () {
      return [{
        id: 'import_file',
        valueType: 'file',
        key: 'import_file',
        value: null
      }, {
        id: 'existing_permissions',
        valueType: 'toggle',
        key: 'updateMode',
        options: [{ id: 'update', label: 'update', value: 1 }, { id: 'replace', label: 'replace', value: 0 }],
        value: ['update']
      }, {
        id: 'create_new_resources',
        key: 'createResources',
        isCreateKey: true,
        valueType: 'boolean',
        value: false
      }, {
        id: 'has_headers',
        key: 'hasHeader',
        valueType: 'boolean',
        value: true
      }];
    }),

    progressTaskKey: null,
    isExportingAll: false,
    isExportingSelected: false,

    downloadUrl: null,

    handleExceptionFromExport: function handleExceptionFromExport() /*data*/{
      this.flashError(this.get('i18n').t('general.generic_error'));
    },

    actions: {

      onResourceTypeSearch: function onResourceTypeSearch(query, deferred) {
        query.searchType = 'permissions';
        this.get('resourceTypeSearcher').search(query).then(function (response) {
          deferred.resolve(response);
        });
      },

      onResourceTypeSelect: function onResourceTypeSelect(resources) {
        this.set('resourceTypesSelected', resources);
      },

      onExportAll: function onExportAll() {
        var _this = this;

        this.set('isExportingAll', true);
        this.get('bulkExporter').exportAll({ type: 'resources' }).then(function (progressTaskKey) {
          _this.set('progressTaskKey', progressTaskKey);
        })['catch'](function () {
          return _this.handleExceptionFromExport();
        })['finally'](function () {
          return _this.set('isExportingAll', false);
        })['catch'](function () {
          return _this.handleExceptionFromExport;
        });
      },

      onExportSelected: function onExportSelected() {
        var _this2 = this;

        this.set('isExportingSelected', true);
        var ids = this.get('resourceTypesSelected').getEach('id');
        this.get('bulkExporter').exportSelected({ type: 'resources', ids: ids }).then(function (progressTaskKey) {
          _this2.set('progressTaskKey', progressTaskKey);
        })['catch'](function () {
          return _this2.handleExceptionFromExport();
        })['finally'](function () {
          return _this2.set('isExportingSelected', false);
        });
      },

      onGetProgressFailed: function onGetProgressFailed() {
        this.setProperties({
          progressTaskKey: null,
          isExportingAll: false,
          isExportingSelected: false
        });
        this.handleExceptionFromExport();
      },

      showDownloadModal: function showDownloadModal(data) {
        var downloadUrl = this.get('bulkExporter').getDownloadURL(data.dataFetchKey);
        this.setProperties({
          progressTaskKey: null,
          downloadUrl: downloadUrl
        });
        this.send('downloadFile', downloadUrl);
      }
    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: true, path: 'manage-resources.resource-type.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.importer.index.page_title'), linkable: true, path: 'manage-resources.resource-type.importer.index' }, { label: this.get('i18n').t(PREFIX + 'resource_permissions'), linkable: false }];
    }).property('i18n.locale')
  });
});