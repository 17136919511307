define("scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 20,
                      "column": 12
                    },
                    "end": {
                      "line": 22,
                      "column": 12
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["content", "object.name", ["loc", [null, [21, 14], [21, 29]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [21, 68], [21, 134]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 8
                  },
                  "end": {
                    "line": 27,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "permissionsSummary-content-object permissionsSummary-content-object--noBorder");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "permissionsSummary-content-permissionValue");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["block", "link-to", [], ["params", ["subexpr", "unbound", [["get", "object.link", ["loc", [null, [20, 39], [20, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 30], [20, 51]]], 0, 0], "target", "_blank", "bubbles", false, "invokeAction", ["subexpr", "action", ["logLink", ["get", "objectType", ["loc", [null, [20, 113], [20, 123]]], 0, 0, 0, 0], ["get", "object", ["loc", [null, [20, 124], [20, 130]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 95], [20, 131]]], 0, 0]], 0, null, ["loc", [null, [20, 12], [22, 24]]]]],
              locals: ["object"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 8
                  },
                  "end": {
                    "line": 29,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["components.object_usage_summary.no_usage"], [], ["loc", [null, [28, 54], [28, 102]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 6
                },
                "end": {
                  "line": 32,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "permissionsSummary-content-objectHeader");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "objectTypeValue.resourceType.name", ["loc", [null, [14, 60], [14, 97]]], 0, 0, 0, 0], ["block", "each", [["get", "objectTypeValue.resources", ["loc", [null, [17, 16], [17, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [17, 8], [29, 17]]]]],
            locals: ["objectTypeValue"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 6
                },
                "end": {
                  "line": 34,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.object_usage_summary.no_usage"], [], ["loc", [null, [33, 52], [33, 100]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 37,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "each", [["get", "objectType.values", ["loc", [null, [12, 14], [12, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [12, 6], [34, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 10
                  },
                  "end": {
                    "line": 45,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                return morphs;
              },
              statements: [["content", "object.name", ["loc", [null, [44, 12], [44, 27]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [44, 66], [44, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 6
                },
                "end": {
                  "line": 50,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "permissionsSummary-content-object permissionsSummary-content-object--noBorder");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "permissionsSummary-content-permissionValue");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "link-to", [], ["params", ["subexpr", "unbound", [["get", "object.link", ["loc", [null, [43, 37], [43, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 28], [43, 49]]], 0, 0], "target", "_blank", "bubbles", false, "invokeAction", ["subexpr", "action", ["logLink", ["get", "objectType", ["loc", [null, [43, 111], [43, 121]]], 0, 0, 0, 0], ["get", "object", ["loc", [null, [43, 122], [43, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 93], [43, 129]]], 0, 0]], 0, null, ["loc", [null, [43, 10], [45, 22]]]]],
            locals: ["object"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 6
                },
                "end": {
                  "line": 52,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.object_usage_summary.no_usage"], [], ["loc", [null, [51, 52], [51, 100]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 4
              },
              "end": {
                "line": 54,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "each", [["get", "objectType.values", ["loc", [null, [40, 14], [40, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [40, 6], [52, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 58,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "permissionsSummary-content-rootName");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          morphs[2] = dom.createMorphAt(element1, 2, 2);
          morphs[3] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["permissionsSummary-content-section permissionsSummary-content-section--", ["get", "objectType.name", ["loc", [null, [3, 87], [3, 102]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.object_usage_summary.", ["get", "objectType.name", ["loc", [null, [5, 107], [5, 122]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 56], [5, 123]]], 0, 0]], [], ["loc", [null, [5, 52], [5, 125]]], 0, 0], ["content", "objectType.count", ["loc", [null, [5, 127], [5, 147]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "objectType.name", ["loc", [null, [9, 14], [9, 29]]], 0, 0, 0, 0], "resources"], [], ["loc", [null, [9, 10], [9, 42]]], 0, 0]], [], 0, 1, ["loc", [null, [9, 4], [54, 11]]]]],
        locals: ["objectType"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "model", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [58, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});