define("scientia-resourcebooker/pods/manage-resources/user-lookup/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/user-lookup/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-userLookup");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'aria-label');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 41], [2, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 55]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [4, 104], [4, 132]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "section-title", [], ["icon", "icons/users.svg", "text", ["subexpr", "t", ["pods.manage_resources.user_lookup.index.page_title"], [], ["loc", [null, [5, 48], [5, 104]]], 0, 0], "location", "sectionTitle--noActions"], ["loc", [null, [5, 4], [5, 141]]], 0, 0], ["content", "manage-resources/user-lookup", ["loc", [null, [6, 4], [6, 36]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});