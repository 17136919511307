define('scientia-resourcebooker/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    location: attr('string'),
    description: attr('string'),
    name: attr('string'),
    eventType: attr('string'),
    owner: attr('string'),
    startDateTime: attr('date'),
    endDateTime: attr('date'),
    lastModified: attr('date'),
    isPublished: attr('boolean')
  });
});