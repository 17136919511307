define('scientia-resourcebooker/services/current-user-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    localeProcessor: _ember['default'].inject.service(),

    fetch: function fetch() {
      var _this = this;

      var currentUser = this.get('currentUser').getUser();
      if (currentUser) return _ember['default'].RSVP.resolve(currentUser);

      this.get('appInsights').trackEvent('login');
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.FETCH_USER_PROFILE).then(function (user) {
        _this.get('localeProcessor').loadUserLanguage(user.userLanguage);
        return user;
      });
    }
  });
});