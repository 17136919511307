define('scientia-resourcebooker/services/unavailability-slots-merger', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Service.extend({

    mergePatternSlots: function mergePatternSlots(busyPatterns, pendingPatterns) {
      if (pendingPatterns.length === 0) {
        pendingPatterns = Array(7);
        for (var i = 0; i < 7; i++) {
          pendingPatterns[i] = [];
        }
      }

      return pendingPatterns.reduce(function (memo, day, dayIndex) {
        memo.push([]); // Push a new Day
        memo[dayIndex] = []; // Assign new day to empty array

        if (busyPatterns[dayIndex] && busyPatterns[dayIndex].length) {
          var _memo$dayIndex;

          (_memo$dayIndex = memo[dayIndex]).push.apply(_memo$dayIndex, _toConsumableArray(busyPatterns[dayIndex])); // Push all busyPatterns of the day into it
        }

        day.forEach(function (pendingPattern) {
          var isRemoving = pendingPattern.isPendingRemove;

          if (isRemoving) {
            var existing = memo[dayIndex].find(function (x) {
              return x.fromTime.asMilliseconds() === pendingPattern.fromTime.asMilliseconds() && x.toTime.asMilliseconds() === pendingPattern.toTime.asMilliseconds();
            });
            memo[dayIndex].removeObject(existing);
          }

          memo[dayIndex].push(pendingPattern);
        });
        return memo;
      }, []);
    },

    mergeDateSlots: function mergeDateSlots(dates, pendingDates) {

      var merged = [];
      merged.push.apply(merged, _toConsumableArray(dates));

      pendingDates.forEach(function (slot) {

        if (slot.isPendingRemove) {
          var existing = merged.find(function (p) {
            return !p.isPendingAdd && !p.isPendingRemove && p.fromTime.isSame(slot.fromTime) && p.toTime.isSame(slot.toTime);
          });
          merged.removeObject(existing);
        }

        merged.push(slot);
      });

      return merged;
    }
  });
});