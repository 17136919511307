define("scientia-resourcebooker/pods/components/toggle-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/toggle-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "role", "checkbox");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'value');
        morphs[1] = dom.createAttrMorph(element0, 'aria-checked');
        morphs[2] = dom.createAttrMorph(element0, 'aria-label');
        morphs[3] = dom.createAttrMorph(element0, 'disabled');
        morphs[4] = dom.createAttrMorph(element0, 'class');
        morphs[5] = dom.createElementMorph(element0);
        morphs[6] = dom.createMorphAt(element0, 0, 0);
        return morphs;
      },
      statements: [["attribute", "value", ["concat", [["get", "elementId", ["loc", [null, [1, 17], [1, 26]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-checked", ["concat", [["get", "isActive", ["loc", [null, [1, 62], [1, 70]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "if", [["get", "ariaLabel", ["loc", [null, [1, 91], [1, 100]]], 0, 0, 0, 0], ["get", "ariaLabel", ["loc", [null, [1, 101], [1, 110]]], 0, 0, 0, 0], ["get", "option.label", ["loc", [null, [1, 111], [1, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 86], [1, 125]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 158], [1, 166]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["toggleButton ", ["subexpr", "if", [["get", "isActive", ["loc", [null, [1, 194], [1, 202]]], 0, 0, 0, 0], "toggleButton--active"], [], ["loc", [null, [1, 189], [1, 227]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggle"], [], ["loc", [null, [1, 127], [1, 146]]], 0, 0], ["content", "option.label", ["loc", [null, [1, 229], [1, 245]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});