define('scientia-resourcebooker/pods/manage-resources/booking-form/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_form.index.page_title');
    },

    model: function model() {
      return this.store.findAll('booking-form');
    }

  });
});