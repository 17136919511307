define('scientia-resourcebooker/services/resource-busy-times-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var userGroupId = _ref.userGroupId;

      var url = this._urlFromIds(id, parentId, type);
      var data = {
        rangeStartDate: startDate.startOf('minute').toISOString(),
        rangeEndDate: endDate.startOf('minute').toISOString()
      };

      if (userGroupId && userGroupId !== DEFAULT_ALL_GROUPS) data.userGroupIdentity = userGroupId;

      return this.get('authenticatedRequest').fetch(url, data).then(function (results) {
        return _this._normalizeResults(results);
      });
    },

    _urlFromIds: function _urlFromIds(id, parentId, type) {
      if (parentId) {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_TIMES[type].loc(parentId, id);
      } else {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_TIMES[type].loc(id);
      }
    },

    _normalizeResults: function _normalizeResults(data) {
      return data.filter(function (x) {
        return !x.isException;
      }).map(function (item) {
        var fromTime = moment(item.startDateTime);
        var toTime = fromTime.clone().add(item.duration, 'minutes');
        return _ember['default'].Object.create({ fromTime: fromTime, toTime: toTime, data: item, isPendingAdd: false, isPendingRemove: false });
      });
    }
  });
});