define('scientia-resourcebooker/pods/components/manage-resources/quotas-form-mirego/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    timespanValues: (function () {
      return [{ id: 'days', value: 'days', label: this.get('i18n').t('components.manage_resources.quotas_form.days') }, { id: 'weeks', value: 'weeks', label: this.get('i18n').t('components.manage_resources.quotas_form.weeks') }, { id: 'years', value: 'years', label: this.get('i18n').t('components.manage_resources.quotas_form.years') }];
    }).property(),

    actions: {
      showUserGroupSearcher: function showUserGroupSearcher() {
        this.sendAction('showUserGroupSearcher');
      }
    }
  });
});