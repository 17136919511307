define('scientia-resourcebooker/pods/manage-resources/booking-form/new/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    errorHandler: _ember['default'].inject.service(),

    error: '',
    isSaving: false,

    actions: {
      save: function save() {
        var _this = this;

        if (_ember['default'].isEmpty(this.get('model.name'))) {
          var errorMessage = this.get('i18n').t('error_messages.bookingformvalidation_nonameonform');
          this.set('error', errorMessage.toString());
          return;
        }

        this.set('error', '');
        this.set('isSaving', true);

        this.get('model').save().then(function (form) {
          _this.createSuccessFlash('booking-form');
          _this.transitionToRoute('manage-resources.booking-form.edit', form.id, { queryParams: { isNew: true, selectedTab: 1 } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            _this.set('error', errorMessage.toString());
          } else {
            _this.createErrorFlash('booking-form');
          }
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_form.index.forms'),
        linkable: true,
        path: 'manage-resources.booking-form.index'
      }, { label: this.get('i18n').t('pods.manage_resources.booking_form.new.page_title'), linkable: false }];
    }).property('i18n.locale')
  });
});