define('scientia-resourcebooker/adapters/booking-form', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    shouldReloadAll: function shouldReloadAll() /*store, snapshot*/{
      return true;
    },

    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() /*store, snapshot*/{
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return true;
    },

    /**
     * Modifies the URL when calling findRecord(booking-request, id);
     * When fetching a booking we need to call the API with the Event ID and not with the Unique ID (occurrence ID)
     * So we check the if the snapshot has an eventId. If it does use it else use the normal ID
     */
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {

      var url = this._buildURL(modelName, query.id);
      if (query.isManaging) url += '?isManaging=true';
      delete query.id;
      delete query.isManaging;
      return url;
    }
  });
});