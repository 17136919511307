define('scientia-resourcebooker/pods/components/custom-checkbox/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    attributeBindings: ['type', 'value', 'checked', 'disabled'],
    tagName: 'input',
    type: 'checkbox',
    checked: false,
    disabled: false,
    change: function change() {
      if (this.get('disabled')) return false;
      this.sendAction('action', this.get('value'), this.get('checked'));
    }
  });
});