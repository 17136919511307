define("scientia-resourcebooker/pods/components/resource-tree/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "resource-tree/item", [], ["model", ["subexpr", "unbound", [["get", "item", ["loc", [null, [3, 17], [3, 21]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 8], [3, 22]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [4, 11], [4, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "blockActions", ["subexpr", "@mut", [["get", "model.blockActions", ["loc", [null, [5, 15], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "hideIcon", ["subexpr", "@mut", [["get", "model.hideIcon", ["loc", [null, [6, 11], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "paginate", ["subexpr", "action", ["paginate", ["get", "model", ["loc", [null, [7, 30], [7, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 11], [7, 36]]], 0, 0], "onSelect", ["subexpr", "action", ["onSelect"], [], ["loc", [null, [8, 11], [8, 30]]], 0, 0], "children", ["subexpr", "@mut", [["get", "model.children", ["loc", [null, [9, 11], [9, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [9, 27]]], 0, 0]],
        locals: ["item"],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "resources-meta", [], ["useActions", true, "currentPage", ["subexpr", "@mut", [["get", "model.meta.currentPage", ["loc", [null, [16, 16], [16, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.meta.totalPages", ["loc", [null, [17, 15], [17, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "onPreviousPage", ["subexpr", "action", ["onPreviousPage", ["get", "model", ["loc", [null, [18, 44], [18, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 19], [18, 50]]], 0, 0], "onNextPage", ["subexpr", "action", ["onNextPage", ["get", "model", ["loc", [null, [19, 36], [19, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 15], [19, 42]]], 0, 0], "changePage", ["subexpr", "action", ["changePage", ["get", "model", ["loc", [null, [20, 36], [20, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 15], [20, 42]]], 0, 0]], ["loc", [null, [14, 4], [20, 44]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["subexpr", "eq", [["get", "model.meta.totalPages", ["loc", [null, [13, 16], [13, 37]]], 0, 0, 0, 0], 1], [], ["loc", [null, [13, 12], [13, 40]]], 0, 0]], [], 0, null, ["loc", [null, [13, 2], [21, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-tree/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "model.data", ["loc", [null, [1, 8], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [10, 9]]]], ["block", "if", [["get", "model.meta", ["loc", [null, [12, 6], [12, 16]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 0], [22, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});