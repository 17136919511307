define("scientia-resourcebooker/pods/components/reports/reports-dashboard/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["location", "at-reportsDashboard--big"], ["loc", [null, [4, 4], [4, 56]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "error-box", [], ["error", ["subexpr", "t", ["components.reports.dashboard.fetch_error"], [], ["loc", [null, [12, 22], [12, 68]]], 0, 0]], ["loc", [null, [12, 4], [12, 70]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "reportsDashboard-noReports");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.reports.dashboard.no_reports"], [], ["loc", [null, [16, 42], [16, 89]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 40
              },
              "end": {
                "line": 32,
                "column": 105
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "loading-icon", [], ["location", "at-reportsDashboard"], ["loc", [null, [32, 58], [32, 105]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tabForm-item reportsDashboard-selector");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "tabForm-label at-auditReports");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "tabForm-item-item");
          var el3 = dom.createTextNode("\n        	");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["components.reports.dashboard.select_report"], [], ["loc", [null, [22, 52], [22, 102]]], 0, 0], ["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "reports", ["loc", [null, [25, 20], [25, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "value", ["subexpr", "@mut", [["get", "selectedReport", ["loc", [null, [28, 18], [28, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "didSelect", ["subexpr", "action", ["didSelectReport"], [], ["loc", [null, [29, 22], [29, 48]]], 0, 0], "searchEnabled", false, "placeholder", ["subexpr", "t", ["components.reports.dashboard.placeholder"], [], ["loc", [null, [31, 24], [31, 70]]], 0, 0], "class", "at-select2--large"], ["loc", [null, [24, 9], [32, 39]]], 0, 0], ["block", "if", [["get", "isLoading", ["loc", [null, [32, 46], [32, 55]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 40], [32, 112]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 2
            },
            "end": {
              "line": 44,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "powerbi-report", [], ["embedUrl", ["subexpr", "@mut", [["get", "selectedReportData.embedUrl", ["loc", [null, [40, 13], [40, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "accessToken", ["subexpr", "@mut", [["get", "selectedReportData.accessToken", ["loc", [null, [41, 16], [41, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "reportId", ["subexpr", "@mut", [["get", "selectedReportData.reportId", ["loc", [null, [42, 13], [42, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "onEmbedded", ["subexpr", "action", ["onEmbedded"], [], ["loc", [null, [43, 15], [43, 36]]], 0, 0]], ["loc", [null, [39, 4], [43, 39]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/reports/reports-dashboard/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "tabForm-section tabForm-section-reportsDashboard");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(element2, 5, 5);
        morphs[3] = dom.createMorphAt(element2, 7, 7);
        morphs[4] = dom.createMorphAt(element2, 9, 9);
        return morphs;
      },
      statements: [["block", "if", [["get", "isInitialLoading", ["loc", [null, [3, 8], [3, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 9]]]], ["block", "if", [["get", "errorMessage", ["loc", [null, [11, 8], [11, 20]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 2], [13, 9]]]], ["block", "if", [["get", "noReports", ["loc", [null, [15, 8], [15, 17]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [15, 2], [17, 9]]]], ["block", "if", [["get", "reports.length", ["loc", [null, [19, 8], [19, 22]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [19, 2], [35, 9]]]], ["block", "if", [["get", "selectedReportData", ["loc", [null, [38, 8], [38, 26]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [38, 2], [44, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});