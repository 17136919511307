define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/configuration/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    concurrencyConfigurationPersister: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.concurrency.configuration.new.page_title');
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        concurrencyConfiguration: this.store.createRecord('concurrencyConfiguration')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: null
        });
      }
    },

    actions: {
      createConcurrencyConfiguration: function createConcurrencyConfiguration(model) {
        var _this = this;

        var controller = this.controllerFor(this.routeName);

        if (_ember['default'].isEmpty(model.get('name'))) {
          controller.set('error', this.get('i18n').t('error_messages.concurrencyconfigurationnoname').toString());
          return;
        }

        controller.set('error', null);
        controller.set('isSaving', true);

        this.get('concurrencyConfigurationPersister').persist(model).then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('concurrency-configuration');
          return _this.transitionTo('manage-resources.resource-type.concurrency.configuration.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error.responseJSON);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('concurrency-configuration');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    }
  });
});