define("scientia-resourcebooker/pods/components/manage-resources/rules-form/types/time/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/rules-form/types/time/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bs form-input form-input--datepicker");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "value", ["loc", [null, [3, 7], [3, 12]]], 0, 0, 0, 0]], [], [], 0, 0], "stepping", 1, "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [5, 11], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", true, "format", "HH:mm", "showClose", true, "allowInputToggle", true, "locale", ["subexpr", "@mut", [["get", "locale", ["loc", [null, [10, 9], [10, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.rules_form.fields.release_time_placeholder"], [], ["loc", [null, [11, 14], [11, 90]]], 0, 0], "updateDate", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [12, 26], [12, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 21], [12, 32]]], 0, 0]], [], ["loc", [null, [12, 13], [12, 33]]], 0, 0]], ["loc", [null, [2, 2], [12, 35]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});