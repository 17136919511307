define('scientia-resourcebooker/pods/components/user-profile-info/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['userProfile-info'],

    displayMode: 1,

    showUserProfileSimple: _ember['default'].computed.gte('displayMode', 1),
    showUserProfileGroups: _ember['default'].computed.gte('displayMode', 2),
    showUserProfileADGroups: _ember['default'].computed.gte('displayMode', 3),

    aDGroups: _ember['default'].computed('model.groups', function () {
      if (_ember['default'].isEmpty('model.groups')) return [];

      return this.get('model.groups').map(function (g) {
        return { name: g };
      });
    })
  });
});