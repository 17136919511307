define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 27,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1, "type", "number");
          dom.setAttribute(el1, "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing at-bookingFormField-numeric-limitRange");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1, "type", "number");
          dom.setAttribute(el1, "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(fragment, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element3, 'aria-label');
          morphs[1] = dom.createAttrMorph(element3, 'value');
          morphs[2] = dom.createAttrMorph(element3, 'placeholder');
          morphs[3] = dom.createAttrMorph(element3, 'oninput');
          morphs[4] = dom.createAttrMorph(element4, 'aria-label');
          morphs[5] = dom.createAttrMorph(element4, 'value');
          morphs[6] = dom.createAttrMorph(element4, 'placeholder');
          morphs[7] = dom.createAttrMorph(element4, 'oninput');
          return morphs;
        },
        statements: [["attribute", "aria-label", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.minimum"], [], ["loc", [null, [null, null], [14, 85]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.minimum", ["loc", [null, [15, 12], [15, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.min"], [], ["loc", [null, [null, null], [16, 82]]], 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.minimum", ["loc", [null, [17, 26], [17, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 21], [17, 40]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [17, 63]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.maximum"], [], ["loc", [null, [null, null], [22, 85]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.maximum", ["loc", [null, [23, 12], [23, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.max"], [], ["loc", [null, [null, null], [24, 82]]], 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.maximum", ["loc", [null, [25, 26], [25, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 21], [25, 40]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [25, 63]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 96
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "input-error");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "minimum"], [], [], 0, 0], "message"], [], ["loc", [null, [28, 50], [28, 85]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "number");
          dom.setAttribute(el3, "min", "0");
          dom.setAttribute(el3, "max", "9");
          dom.setAttribute(el3, "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          morphs[2] = dom.createAttrMorph(element2, 'aria-label');
          morphs[3] = dom.createAttrMorph(element2, 'value');
          morphs[4] = dom.createAttrMorph(element2, 'oninput');
          morphs[5] = dom.createAttrMorph(element2, 'placeholder');
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.booking_form_field.numeric.max_decimal_places"], [], ["loc", [null, [50, 6], [50, 87]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.max_decimal_places_explanation"], [], ["loc", [null, [52, 8], [52, 101]]], 0, 0], ["attribute", "aria-label", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.max_decimal_places"], [], ["loc", [null, [null, null], [60, 98]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.maxDecimalPlaces", ["loc", [null, [61, 14], [61, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.maxDecimalPlaces", ["loc", [null, [64, 28], [64, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 23], [64, 51]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [64, 74]]], 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.no_maximum"], [], ["loc", [null, [null, null], [65, 91]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 70,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(fragment, [3]);
        var element9 = dom.childAt(element8, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element7, 1, 1);
        morphs[3] = dom.createMorphAt(element7, 3, 3);
        morphs[4] = dom.createMorphAt(element7, 5, 5);
        morphs[5] = dom.createMorphAt(element9, 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[8] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.booking_form_field.numeric.limit_range"], [], ["loc", [null, [3, 4], [3, 78]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.limit_range_explanation"], [], ["loc", [null, [5, 6], [5, 92]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.isMinMaxRestricted", ["loc", [null, [10, 26], [10, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.isMinMaxRestricted", ["loc", [null, [10, 73], [10, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 68], [10, 98]]], 0, 0]], [], ["loc", [null, [10, 60], [10, 99]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [10, 2], [10, 140]]], 0, 0], ["block", "if", [["get", "model.isMinMaxRestricted", ["loc", [null, [11, 8], [11, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 2], [27, 11]]]], ["block", "if", [["get", "didValidate", ["loc", [null, [28, 8], [28, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [28, 2], [28, 103]]]], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.allow_decimal"], [], ["loc", [null, [36, 4], [36, 80]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.allow_decimal_explanation"], [], ["loc", [null, [38, 6], [38, 94]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.allowDecimal", ["loc", [null, [43, 26], [43, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.allowDecimal", ["loc", [null, [43, 67], [43, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 62], [43, 86]]], 0, 0]], [], ["loc", [null, [43, 54], [43, 87]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [43, 2], [43, 128]]], 0, 0], ["block", "if", [["get", "model.allowDecimal", ["loc", [null, [47, 6], [47, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [47, 0], [69, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});