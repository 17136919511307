define('scientia-resourcebooker/pods/manage-resources/resource-type/route', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _ember['default'].Route.extend(_emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (!this.can('list resource-type')) {
        this.transitionTo('logged-in');
      }

      this.store.unloadAll('resource-type');
    }
  });
});