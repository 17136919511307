define('scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['importerOption manageResources-itemForm-form-item at-importerOptions'],
    classNameBindings: ['isFileType:manageResources-itemForm-form-item--topAligned'],
    isFileType: _ember['default'].computed.equal('type', 'file'),
    optionId: '',
    type: 'boolean',
    value: false,
    disabled: false,

    localePrefix: '',

    translatedOptions: _ember['default'].computed('options[]', 'i18n.locale', 'localePrefix', function () {
      var _this = this;

      var localePrefix = this.get('localePrefix');
      return this.get('options').map(function (op) {
        return {
          id: op.id,
          label: _this.get('i18n').t(localePrefix + op.id)
        };
      });
    }),

    actions: {
      toggleValue: function toggleValue(option) {
        this.set('value', [option.id]);
      },

      onFileChanged: function onFileChanged(file) {
        this.attrs.onFileChanged(this.get('optionId'), file[0]);
      }
    }

  });
});