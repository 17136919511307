define('scientia-resourcebooker/pods/logged-in/user-profile/controller', ['exports', 'ember', 'ember-concurrency', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _emberConcurrency, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    systemSettings: _ember['default'].inject.service(),
    profileProcessor: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    resourcePermissionSummaryFetcher: _ember['default'].inject.service(),

    isSaving: false,

    showResourcePermissionSummaryModal: null,

    userProfileDisplayMode: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('userProfileDisplayMode');
    }),

    canShowUserProfile: _ember['default'].computed.gte('userProfileDisplayMode', 1),

    canViewOwnPermissionSummary: _ember['default'].computed.alias('currentUser.canViewOwnPermissionSummary'),

    fetchPermissionSummary: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var result;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;
            context$1$0.next = 3;
            return this.get('resourcePermissionSummaryFetcher').fetchForSelf();

          case 3:
            result = context$1$0.sent;

            this.set('showResourcePermissionSummaryModal', result);

            context$1$0.next = 9;
            break;

          case 7:
            context$1$0.prev = 7;
            context$1$0.t0 = context$1$0['catch'](0);

          case 9:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[0, 7]]);
    })).drop(),

    actions: {
      save: function save() {
        var _this = this;

        this.set('isSaving', true);

        this.get('profileProcessor').save(this.get('model')).then(function () {

          _this.send('refreshModel');
          _this.flashSuccess(_this.get('i18n').t('pods.logged_in.user_profile.saved'));
        })['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.user_profile.error_saving'));
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      }

    }
  });
});