define('scientia-resourcebooker/pods/components/manage-resources/rules-form/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-group'],
    attributeBindings: ['data-rule-id'],
    'data-rule-id': _ember['default'].computed.alias('rule.identity'),
    rule: null,

    actions: {
      searchUserGroups: function searchUserGroups(query, deferred) {
        this.sendAction('searchUserGroups', query, deferred);
      },
      deleteRule: function deleteRule() {
        this.sendAction('deleteRule', this.get('rule'));
      }
    }
  });
});