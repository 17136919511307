define("scientia-resourcebooker/pods/components/manage-resources/resource-group-resources-manager/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-group-resources-manager/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle manageResources-itemForm-subtitle--disabledTab");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [5, 4], [5, 83]]], 0, 0], ["inline", "t", ["pods.manage_resources.tab_no_permission"], [], ["loc", [null, [6, 10], [6, 57]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-group-resources-manager/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "resources-manager-container");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "resources-manager-list");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-subtitle at-resources-manager-list");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "select2--arrow");
        dom.setAttribute(el3, "role", "presentation");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        dom.setAttribute(el4, "role", "presentation");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(fragment, [4, 1]);
        var element3 = dom.childAt(element2, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(element1, 0, 0);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createElementMorph(element3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["manageResources-itemForm-title ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [1, 47], [1, 55]]], 0, 0, 0, 0], "manageResources-itemForm-title--hasSubtitle", ""], [], ["loc", [null, [1, 42], [1, 106]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.manage_resources.resource_group_resources_manager.title"], ["name", ["subexpr", "@mut", [["get", "resourceGroup.name", ["loc", [null, [1, 186], [1, 204]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 108], [1, 206]]], 0, 0], ["block", "if", [["get", "disabled", ["loc", [null, [3, 6], [3, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [8, 7]]]], ["inline", "t", ["components.manage_resources.resource_group_resources_manager.help"], [], ["loc", [null, [13, 76], [13, 149]]], 0, 0], ["inline", "select-2", [], ["query", ["subexpr", "action", ["onResourceSearch"], [], ["loc", [null, [15, 12], [15, 39]]], 0, 0], "value", ["subexpr", "@mut", [["get", "resourceGroup.resourceIds", ["loc", [null, [16, 12], [16, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [17, 19], [17, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 14], [17, 28]]], 0, 0], "searchEnabled", true, "optionIdPath", "id", "optionLabelPath", "name", "optionDescriptionPath", "no-description", "multiple", true, "keepOpen", true, "didSelect", ["subexpr", "action", ["didSelectResources"], [], ["loc", [null, [24, 16], [24, 45]]], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.resource_group_resources_manager.placeholder"], [], ["loc", [null, [25, 18], [25, 96]]], 0, 0], "class", "at-resources-manager-container at-select--three-col", "cssClass", "at-resources-manager-container at-select--three-col"], ["loc", [null, [14, 2], [27, 70]]], 0, 0], ["element", "action", ["triggerListOpen"], [], ["loc", [null, [28, 53], [28, 81]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});