define('scientia-resourcebooker/serializers/concurrency-group', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      resources: { embedded: 'always' }
    },

    normalize: function normalize(type, payload, prop) {
      payload = this.store.serializerFor('application')._camelizeKeysDeep(payload);
      if (!payload.description) payload.description = '';
      payload.resources = this._normalizeResources(payload.resources);
      var data = this._super(type, payload, prop);
      return data;
    },

    _normalizeResources: function _normalizeResources(data) {
      if (!data) return [];
      return data.map(function (item) {
        return {
          id: item.resourceIdentity,
          name: item.resourceName,
          resourceTypeId: item.resourceTypeIdentity,
          isPrimary: item.isPrimary,
          order: item.order,
          type: 'resources'
        };
      });
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.resources) {
        json.resources = this._serializeResources(json.resources);
      }

      return json;
    },

    _serializeResources: function _serializeResources(data) {
      return data.map(function (r) {
        return {
          resourceIdentity: r.id,
          resourceName: r.name,
          resourceTypeIdentity: r.resourceTypeId,
          isPrimary: r.isPrimary,
          order: r.order
        };
      });
    }

  });
});