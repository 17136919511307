define('scientia-resourcebooker/services/rb-toast', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    flashMessages: _ember['default'].inject.service(),

    success: function success(_ref) {
      var title = _ref.title;
      var message = _ref.message;
      var icon = _ref.icon;
      var iconClass = _ref.iconClass;
      var _ref$timeout = _ref.timeout;
      var timeout = _ref$timeout === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.extendedTimeout : _ref$timeout;

      this.toastClear();
      this.get('flashMessages').add({ title: title, message: message, icon: icon, iconClass: iconClass, timeout: timeout, type: 'success alert--toast' });
    },

    info: function info(_ref2) {
      var title = _ref2.title;
      var message = _ref2.message;
      var icon = _ref2.icon;
      var iconClass = _ref2.iconClass;
      var _ref2$timeout = _ref2.timeout;
      var timeout = _ref2$timeout === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.extendedTimeout : _ref2$timeout;

      this.toastClear();
      this.get('flashMessages').add({ title: title, message: message, icon: icon, iconClass: iconClass, timeout: timeout, type: 'info alert--toast' });
    },

    error: function error(_ref3) {
      var title = _ref3.title;
      var message = _ref3.message;
      var icon = _ref3.icon;
      var iconClass = _ref3.iconClass;
      var _ref3$timeout = _ref3.timeout;
      var timeout = _ref3$timeout === undefined ? _scientiaResourcebookerConfigEnvironment['default'].flashMessageDefaults.extendedTimeout : _ref3$timeout;

      this.toastClear();
      this.get('flashMessages').add({ title: title, message: message, icon: icon, iconClass: iconClass, timeout: timeout, type: 'danger alert--toast' });
    },

    toastClear: function toastClear() {
      this.get('flashMessages').clearMessages();
    }

  });
});