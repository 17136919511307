define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    error: null,
    isSaving: false,

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: true, path: 'manage-resources.resource-type.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title'), linkable: true, path: 'manage-resources.resource-type.concurrency.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.group.new.page_title'), linkable: false }];
    }).property('i18n.locale')
  });
});