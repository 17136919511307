define("scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "manageResources-customFieldItem-valueChoices-itemEdit");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "class", "form-input manageResources-customFieldItem-valueChoices-input");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "button button--link button--icon");
          var el3 = dom.createTextNode("✕");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'value');
          morphs[1] = dom.createAttrMorph(element1, 'aria-label');
          morphs[2] = dom.createAttrMorph(element1, 'oninput');
          morphs[3] = dom.createAttrMorph(element2, 'aria-label');
          morphs[4] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["attribute", "value", ["get", "choice.name", ["loc", [null, [5, 14], [5, 25]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.manage_resources.custom_field_item.value_choices"], [], ["loc", [null, [6, 18], [6, 85]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "choice.name", ["loc", [null, [7, 28], [7, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 23], [7, 40]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [7, 63]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.delete"], [], ["loc", [null, [10, 26], [10, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["remove", ["get", "choice", ["loc", [null, [10, 68], [10, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 50], [10, 76]]], 0, 0]],
        locals: ["choice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "type", "button");
        dom.setAttribute(el1, "class", "button button--white button--small");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element3, 'disabled');
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(element3, 0, 0);
        return morphs;
      },
      statements: [["block", "each", [["get", "choices", ["loc", [null, [2, 10], [2, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [12, 11]]]], ["attribute", "disabled", ["get", "hasEmptyChoices", ["loc", [null, [15, 36], [15, 51]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["add"], [], ["loc", [null, [15, 8], [15, 24]]], 0, 0], ["inline", "t", ["components.manage_resources.custom_field_item.add"], [], ["loc", [null, [15, 111], [15, 168]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});