define("scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["item-sort-modal"], ["model", ["subexpr", "unbound", [["get", "selectedGroupResources", ["loc", [null, [7, 23], [7, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 14], [7, 46]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showSortModal", ["loc", [null, [8, 27], [8, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 22], [8, 41]]], 0, 0], false], [], ["loc", [null, [8, 14], [8, 48]]], 0, 0], "confirm", ["subexpr", "action", ["onSelectionSorted"], [], ["loc", [null, [9, 16], [9, 44]]], 0, 0]], ["loc", [null, [5, 11], [9, 45]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [10, 39], [10, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 29], [10, 44]]], 0, 0]], [], ["loc", [null, [10, 21], [10, 45]]], 0, 0]], ["loc", [null, [4, 9], [10, 46]]], 0, 0]], ["loc", [null, [2, 4], [11, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 10
                },
                "end": {
                  "line": 28,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [27, 12], [27, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.concurrency.tabs.main"], [], ["loc", [null, [27, 73], [27, 138]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 10
                },
                "end": {
                  "line": 32,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [31, 12], [31, 68]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.concurrency.tabs.resources"], [], ["loc", [null, [31, 68], [31, 138]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 34,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [26, 10], [28, 21]]]], ["block", "dc-tab", [], [], 1, null, ["loc", [null, [30, 10], [32, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 41,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/concurrency-group-main-form", [], ["model", ["subexpr", "@mut", [["get", "model.concurrencyGroup", ["loc", [null, [38, 16], [38, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "localePrefix", "components.manage_resources.concurrency_group_edit"], ["loc", [null, [37, 10], [40, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 51,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/concurrency-group-resources-form", [], ["model", ["subexpr", "@mut", [["get", "model.concurrencyGroup", ["loc", [null, [45, 16], [45, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedGroupResources", ["loc", [null, [46, 19], [46, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelectionChanged", ["subexpr", "action", ["onSelectionChanged"], [], ["loc", [null, [47, 29], [47, 58]]], 0, 0], "onSortTrigger", ["subexpr", "action", [["subexpr", "mut", [["get", "showSortModal", ["loc", [null, [48, 37], [48, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 32], [48, 51]]], 0, 0], true], [], ["loc", [null, [48, 24], [48, 57]]], 0, 0], "localePrefix", "components.manage_resources.concurrency_group_edit"], ["loc", [null, [44, 10], [50, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 53,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [24, 8], [34, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [36, 8], [41, 25]]]], ["block", "dc-tab-panel", [], [], 2, null, ["loc", [null, [43, 8], [51, 25]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'aria-label');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 1, 1);
        morphs[5] = dom.createMorphAt(element2, 3, 3);
        morphs[6] = dom.createMorphAt(element2, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showSortModal", ["loc", [null, [1, 6], [1, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [15, 39], [15, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 0], [15, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [17, 90], [17, 118]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.resource_type.concurrency.group.edit.page_title"], ["name", ["subexpr", "unbound", [["get", "model.concurrencyGroup.name", ["loc", [null, [18, 114], [18, 141]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 105], [18, 142]]], 0, 0]], ["loc", [null, [18, 25], [18, 143]]], 0, 0]], ["loc", [null, [18, 4], [18, 145]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [21, 24], [21, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 6], [21, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", 0], 1, null, ["loc", [null, [23, 6], [53, 18]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [55, 49], [55, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["onCancel"], [], ["loc", [null, [55, 71], [55, 90]]], 0, 0], "onSave", ["subexpr", "route-action", ["updateModel", ["get", "model.concurrencyGroup", ["loc", [null, [55, 126], [55, 148]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 98], [55, 149]]], 0, 0]], ["loc", [null, [55, 6], [55, 151]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});