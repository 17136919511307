define('scientia-resourcebooker/pods/components/system/notifications-templates/list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['notificationsTemplates-list'],

    notificationsTemplates: [],
    selectedTemplate: null,

    actions: {
      selectTemplate: function selectTemplate(template) {
        this.sendAction('selectTemplate', template);
      }
    }
  });
});