define('scientia-resourcebooker/serializers/booking-form', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _ember, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      associatedBookingTypes: { embedded: 'always' }
    },

    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload /*, id, requestType*/) {

      payload.Sections.forEach(function (s) {
        s.Fields.forEach(function (f) {
          f.IsManaging = true;
        });
      });

      var result = this._super.apply(this, arguments);
      return result;
    },

    normalize: function normalize(type, payload) {
      // We have to create Ember Objects from associatedBookingTypes as we are using attr() instead of
      // hasMany(bookingType) as we don't want the booking-form model to start pushing booking-types
      // into the store
      var newPayload = this.store.serializerFor('booking-request')._camelizeKeysDeep(payload);
      if (!newPayload.associatedBookingTypes) newPayload.associatedBookingTypes = [];
      newPayload.associatedBookingTypes = newPayload.associatedBookingTypes.map(function (type) {
        type.id = type.identity;
        return _ember['default'].Object.create(type);
      });
      var results = this._super(type, newPayload);
      return results;
    }

  });
});