define("scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 10
                },
                "end": {
                  "line": 15,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [14, 12], [14, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.concurrency.tabs.main"], [], ["loc", [null, [14, 73], [14, 138]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 19,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [18, 12], [18, 68]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.concurrency.tabs.resources"], [], ["loc", [null, [18, 68], [18, 138]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 21,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [13, 10], [15, 21]]]], ["block", "dc-tab", [], ["disabled", true], 1, null, ["loc", [null, [17, 10], [19, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 8
              },
              "end": {
                "line": 28,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/concurrency-group-main-form", [], ["model", ["subexpr", "@mut", [["get", "model.concurrencyGroup", ["loc", [null, [25, 16], [25, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "localePrefix", "components.manage_resources.concurrency_group_new"], ["loc", [null, [24, 10], [27, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 29,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [11, 8], [21, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [23, 8], [28, 25]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/group/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-note");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'aria-label');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[6] = dom.createMorphAt(element2, 7, 7);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [4, 90], [4, 118]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.resource_type.concurrency.group.new.page_title"], [], ["loc", [null, [5, 25], [5, 99]]], 0, 0]], ["loc", [null, [5, 4], [5, 101]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [8, 24], [8, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 6], [8, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", 0], 0, null, ["loc", [null, [10, 6], [29, 18]]]], ["inline", "t", ["pods.manage_resources.resource_group.new.disabled_tab_note"], [], ["loc", [null, [31, 52], [31, 118]]], 0, 0], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [33, 49], [33, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "hideSaveClose", true, "onSave", ["subexpr", "route-action", ["createConcurrencyGroup", ["get", "model.concurrencyGroup", ["loc", [null, [33, 123], [33, 145]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 84], [33, 146]]], 0, 0]], ["loc", [null, [33, 6], [33, 148]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});