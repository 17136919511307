define("scientia-resourcebooker/pods/components/resources-list/items/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 10
                  },
                  "end": {
                    "line": 27,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "aria-hidden", "true");
                dom.setAttribute(el1, "tabindex", "-1");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["badge at-resourcesList-item ", ["subexpr", "if", [["get", "resourceGroup.resourceCount", ["loc", [null, [26, 89], [26, 116]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [26, 84], [26, 132]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "resourceGroup.resourceCount", ["loc", [null, [26, 134], [26, 165]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 8
                },
                "end": {
                  "line": 33,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "aria-hidden", "true");
              dom.setAttribute(el1, "tabindex", "-1");
              dom.setAttribute(el1, "class", "resourcesList-item-icon resourcesList-item-icon--group");
              dom.setAttribute(el1, "src", "icons/folder.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "resourcesList-item-infos");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesList-item-small");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "sr-only");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesList-item-name");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [5]);
              var element2 = dom.childAt(element1, [3]);
              var element3 = dom.childAt(element1, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[1] = dom.createAttrMorph(element2, 'id');
              morphs[2] = dom.createMorphAt(element2, 0, 0);
              morphs[3] = dom.createAttrMorph(element3, 'aria-describedby');
              morphs[4] = dom.createMorphAt(element3, 0, 0);
              return morphs;
            },
            statements: [["block", "unless", [["get", "hideResourceGroupCount", ["loc", [null, [24, 20], [24, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 10], [27, 21]]]], ["attribute", "id", ["get", "resourceGroup.id", ["loc", [null, [30, 23], [30, 39]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "if", [["get", "hideResourceGroupCount", ["loc", [null, [30, 64], [30, 86]]], 0, 0, 0, 0], ["subexpr", "t", ["components.resources_list.items.group_description"], [], ["loc", [null, [30, 87], [30, 142]]], 0, 0], ["subexpr", "t", ["components.resources_list.items.group_description_with_count"], ["count", ["get", "resourceGroup.resourceCount", ["loc", [null, [30, 215], [30, 242]]], 0, 0, 0, 0]], ["loc", [null, [30, 143], [30, 243]]], 0, 0]], [], ["loc", [null, [30, 59], [30, 245]]], 0, 0], ["attribute", "aria-describedby", ["concat", [["get", "resourceGroup.id", ["loc", [null, [31, 70], [31, 86]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "resourceGroup.name", ["loc", [null, [31, 90], [31, 112]]], 0, 0, 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 4
              },
              "end": {
                "line": 35,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "listitem");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createMorphAt(element4, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["resourcesList-item resourcesList-item--group ", ["subexpr", "if", [["subexpr", "not", [["get", "groupsListExpanded", ["loc", [null, [20, 88], [20, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 83], [20, 107]]], 0, 0], " resourcesList-item--group--hidden", ""], [], ["loc", [null, [20, 78], [20, 149]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["logged-in.booking-type.show", ["get", "bookingType.id", ["loc", [null, [21, 49], [21, 63]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "firstDay", ["loc", [null, [21, 83], [21, 91]]], 0, 0, 0, 0], "fromTime", ["get", "calendarFromTime", ["loc", [null, [21, 101], [21, 117]]], 0, 0, 0, 0], "toTime", ["get", "calendarToTime", ["loc", [null, [21, 125], [21, 139]]], 0, 0, 0, 0], "resourceGroupId", ["get", "resourceGroup.id", ["loc", [null, [21, 156], [21, 172]]], 0, 0, 0, 0]], ["loc", [null, [21, 64], [21, 173]]], 0, 0]], ["class", "resourcesList-item-link"], 0, null, ["loc", [null, [21, 8], [33, 20]]]]],
          locals: ["resourceGroup"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "listitem");
          dom.setAttribute(el1, "class", "resources-list--groups-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "resourcesList-item resourcesList-item--expander");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "resourcesList-item-link");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "resourceTree-expand");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4, "class", "resourcesList-item-icon resourcesList-item-icon--group");
          dom.setAttribute(el4, "src", "icons/folder.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "resourcesList-item-infos");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "resourcesList-item-small");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "resourcesList-item-name");
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "role", "list");
          dom.setAttribute(el2, "tabindex", "0");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element7, [3]);
          var element9 = dom.childAt(element5, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [1]), 1, 1);
          morphs[2] = dom.createAttrMorph(element8, 'alt');
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [5, 3]), 1, 1);
          morphs[4] = dom.createAttrMorph(element9, 'aria-label');
          morphs[5] = dom.createMorphAt(element9, 1, 1);
          return morphs;
        },
        statements: [["element", "action", [["subexpr", "action", [["subexpr", "toggle", ["groupsListExpanded", ["get", "this", ["loc", [null, [4, 111], [4, 115]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 82], [4, 116]]], 0, 0]], [], ["loc", [null, [4, 74], [4, 117]]], 0, 0]], [], ["loc", [null, [4, 65], [4, 119]]], 0, 0], ["inline", "inline-svg", ["icons/caret-down.svg"], ["class", ["subexpr", "if", [["get", "groupsListExpanded", ["loc", [null, [7, 56], [7, 74]]], 0, 0, 0, 0], "expanded", "collapsed"], [], ["loc", [null, [7, 52], [7, 98]]], 0, 0]], ["loc", [null, [7, 10], [7, 100]]], 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [9, 18], [9, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.resources_list.items.groups"], [], ["loc", [null, [12, 49], [12, 95]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.resources_list.items.groups_label"], [], ["loc", [null, [18, 43], [18, 95]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "resourceGroups", ["loc", [null, [19, 12], [19, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 4], [35, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "resources-list/item", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [43, 13], [43, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "groupId", ["subexpr", "@mut", [["get", "groupId", ["loc", [null, [44, 12], [44, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "firstDay", ["subexpr", "@mut", [["get", "calendarDate", ["loc", [null, [45, 13], [45, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "lastDay", ["subexpr", "@mut", [["get", "lastDay", ["loc", [null, [46, 12], [46, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "calendarFromTime", ["loc", [null, [47, 13], [47, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "calendarToTime", ["loc", [null, [48, 11], [48, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingId", ["subexpr", "@mut", [["get", "bookingId", ["loc", [null, [49, 14], [49, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "editOrigin", ["subexpr", "@mut", [["get", "editOrigin", ["loc", [null, [50, 15], [50, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "editingPrimary", ["subexpr", "@mut", [["get", "editingPrimary", ["loc", [null, [51, 19], [51, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingType", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [52, 16], [52, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 2], [52, 29]]], 0, 0]],
        locals: ["resource"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 2
              },
              "end": {
                "line": 56,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "resourcesList-item resourcesList-item--empty");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [55, 61], [55, 106]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "resourceGroups", ["loc", [null, [54, 12], [54, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 2], [56, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "resourceGroups.length", ["loc", [null, [1, 6], [1, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [39, 7]]]], ["block", "each", [["get", "resources", ["loc", [null, [41, 8], [41, 17]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [41, 0], [57, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});