define('scientia-resourcebooker/services/config-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    localeProcessor: _ember['default'].inject.service(),

    config: null,
    language: _ember['default'].computed.alias('config.Language'),
    extraDatasets: _ember['default'].computed.alias('config.ExtraDatasets'),
    logonMessage: _ember['default'].computed.alias('config.LogOnMessage'),
    logonMessageEnabled: _ember['default'].computed.alias('config.LogOnMessageEnabled'),
    clientId: _ember['default'].computed.alias('config.ClientId'),
    baseURL: _ember['default'].computed.alias('config.BaseUrl'),
    redirectURL: _ember['default'].computed.alias('config.RedirectUrl'),
    entityId: _ember['default'].computed.alias('config.EntityId'),
    welcomeMessage: _ember['default'].computed.alias('config.WelcomeMessage'),
    hideWelcomeMessage: _ember['default'].computed.alias('config.HideWelcomeMessage'),
    appName: _ember['default'].computed.alias('config.ApplicationName'),
    translations: _ember['default'].computed.alias('config.Translations'),

    fetch: function fetch() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.WEBSITE_CONFIG;
        var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;

        _ember['default'].$.getJSON(url).done(function (config) {
          _this.set('config', config);
          _this.get('localeProcessor').initLocale(config.Language).then(function () {
            resolve(config);
          });
        }).fail(reject);
      });
    },

    getConfig: function getConfig() {
      return this.get('config');
    }
  });
});