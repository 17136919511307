define('scientia-resourcebooker/services/additional-resources-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    search: function search(_ref) {
      var resourceTypeId = _ref.resourceTypeId;
      var resourceGroups = _ref.resourceGroups;
      var query = _ref.query;
      var page = _ref.page;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.ADDITIONAL_RESOURCE_GROUP_FILTERER_URL.loc(resourceTypeId);
      var queryParams = { query: query, itemsPerPage: 20, pageNumber: page };
      var newURL = this._appendQueryParamsToURL(queryParams, url);
      var data = {};

      if (!_ember['default'].isEmpty(resourceGroups)) {
        var resourceGroupIds = resourceGroups.getEach('id');
        data.resourceGroupIds = resourceGroupIds;
      }

      return this.get('authenticatedRequest').post(newURL, data).then(this._normalizeResponse.bind(this));
    },

    _normalizeResponse: function _normalizeResponse(response) {
      response.results = response.results.map(function (r) {
        return _ember['default'].Object.create(r);
      });
      return response;
    },

    _appendQueryParamsToURL: function _appendQueryParamsToURL(queryParams, url) {

      url += '?';
      var paramKeys = Object.keys(queryParams);

      paramKeys.forEach(function (paramKey, i, params) {
        url += paramKey + '=' + queryParams[paramKey];
        if (params.length !== i + 1) {
          url += '&';
        }
      });

      return url;
    }
  });
});