define('scientia-resourcebooker/pods/components/reports/reports-audit/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var LOCALE_PREFIX = 'components.reports.audit.';
  var LOCALE_MESSAGES_PREFIX = 'components.reports.flash_messages.';

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    classNames: ['tabForm at-reports at-reportsAudit reportsAudit'],
    can: _ember['default'].inject.service(),
    reportsProcessor: _ember['default'].inject.service(),
    resourceTreeDataFetcher: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    locale: _ember['default'].computed.alias('i18n.locale'),

    // Holds root model (resource-types)
    model: null,

    // holds all selected objects
    selected: null,

    // current search query
    query: '',

    searchResults: null,

    // The current timeframe selected
    timeframeSelected: null,

    // The dates related to the time frame
    startDateTime: null,
    endDateTime: null,

    dateOptionSelected: null,
    reportSelectionSelected: null,

    progressTaskKey: null,

    isLoading: false,
    isGeneratingReport: false,

    isResourcePickerDisabled: _ember['default'].computed.equal('reportSelectionSelected', 'everything'),

    dateOptionsContent: _ember['default'].computed('i18n.locale', function () {
      return [{ id: 'useBookingDates', label: this.get('i18n').t(LOCALE_PREFIX + 'booking_dates') }, { id: 'actionDates', label: this.get('i18n').t(LOCALE_PREFIX + 'action_dates') }];
    }),

    reportSelectionTypes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var labels = ['everything', 'custom_selection'];
      return labels.map(function (label) {
        return {
          id: label,
          label: _this.get('i18n').t('components.reports.audit.report_' + label)
        };
      });
    }),

    dateFormat: _ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t(LOCALE_PREFIX + 'date_format').toString();
    }),

    dateFormatCreated: _ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t(LOCALE_PREFIX + 'date_format_created').toString();
    }),

    // List of available search types
    searchTypes: _ember['default'].computed('i18n.locale', function () {
      var _this2 = this;

      var labels = ['everything', 'resourcetypes', 'resourcegroups', 'resources'];
      return labels.map(function (label, i) {
        return {
          label: _this2.get('i18n').t('components.resource_tree.search.' + label.toLowerCase()),
          value: i
        };
      });
    }),

    // list of available timeframe types
    timeframeTypes: _ember['default'].computed('i18n.locale', function () {
      var _this3 = this;

      var labels = [/*'today', 'next_seven_days', 'next_month',*/'specific_range'];
      return labels.map(function (label) {
        return {
          label: _this3.get('i18n').t('components.date_period_selector.' + label),
          value: label
        };
      });
    }),

    searchTypePlaceholder: _ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t('components.resource_tree.search.search_type');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.get('session').restoreAutoLogout();
      this.set('selected', []);
      this.set('dateOptionSelected', 'actionDates');
      this.set('reportSelectionSelected', 'custom_selection');

      this.send('onTimeframeChange', 'specific_range');

      this.fetchResourceTypes();
    },

    fetchResourceTypes: function fetchResourceTypes() {
      var _this4 = this;

      this.set('isLoading', true);
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'root.resourceType',
        data: {
          viewPermissionGroupType: 1
        }
      }).then(function (data) {
        var result = _ember['default'].Object.create({
          name: 'resourcetypes',
          data: data.results,

          children: [_ember['default'].Object.create({
            name: 'resourcegroups',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref, page) {
              var id = _ref.id;
              return _this4._fetchResourceGroups(id, page);
            }
          }), _ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref2, page) {
              var id = _ref2.id;
              return _this4._fetchResourcesForResourceType(id, page);
            }
          })]
        });

        _this4.set('model', result);
      })['finally'](function () {
        _this4.set('isLoading', false);
      });
    },

    /**
    * Fetches Groups for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourceGroups: function _fetchResourceGroups(resourceTypeId, page) {
      var _this5 = this;

      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceType.resourceGroup',
        parentId: resourceTypeId,
        data: {
          viewPermissionGroupType: 1,
          pageNumber: page || 1,
          itemsPerPage: 10,
          getAssociatedTemplates: true,
          adminPermissionsOnly: true
        }
      }).then(function (data) {

        return _ember['default'].Object.create({
          name: 'resourcegroups',
          data: data.results,
          meta: data.meta,
          rank: 0,
          children: [_ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref3, page) {
              var resourceGroupId = _ref3.id;
              return _this5._fetchResourcesForGroup(resourceTypeId, resourceGroupId, page);
            }
          })]
        });
      });
    },

    /**
    * Fetches Resources for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourcesForResourceType: function _fetchResourcesForResourceType(resourceTypeId, page) {
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceType.resource',
        parentId: resourceTypeId,
        data: {
          viewPermissionGroupType: 1,
          pageNumber: page || 1, itemsPerPage: 10
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          rank: 1,
          data: data.results,
          hideIcon: true,
          meta: data.meta
        });
      });
    },

    /**
    * Fetches resources for a given ResourceGroup
    * @param {string} resourceTypeId The resourceType Id
    * @param {string} groupId The group Id
    */
    _fetchResourcesForGroup: function _fetchResourcesForGroup(resourceTypeId, groupId, page) {
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceGroup.resource',
        parentId: resourceTypeId,
        id: null,
        groupId: groupId,
        suffix: null,
        data: {
          viewPermissionGroupType: 1,
          pageNumber: page || 1, itemsPerPage: 5, adminPermissionsOnly: true
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          hideIcon: true,
          data: data.results,
          meta: data.meta
        });
      });
    },

    _getAdjustedTimeframeDates: function _getAdjustedTimeframeDates(start, end) {
      if (!start) {
        if (end) start = end.clone().startOf('day');
      }

      if (!end) {
        end = start.clone().endOf('day');
      }

      if (end && start && end.isBefore(start)) {
        end = start.clone().endOf('day');
      }

      return { startDate: start, endDate: end };
    },

    actions: {

      //  When clicking on an item
      onSelect: function onSelect() {
        var args = arguments;
        var item = args[args.length - 1];
        var itemType = args[args.length - 2];
        if (!itemType && args.length === 1) {
          itemType = 'resourcetypes';
        }

        var selected = this.get('selected');

        var existingItem = selected.findBy('id', item.id);
        if (_ember['default'].isEmpty(existingItem)) {
          var itemId = _ember['default'].get(item, 'id');
          var typeRank = ['resourcetypes', 'resourcegroups', 'resources'];
          var itemData = {
            id: itemId,
            resourceTypeId: _ember['default'].get(args[0], 'id'),
            resourceGroupId: itemType === 'resourcegroups' ? itemId : null,
            resourceId: itemType === 'resources' ? itemId : null,
            name: _ember['default'].get(item, 'name'),
            imageUri: _ember['default'].get(item, 'imageUri'),
            type: itemType,
            rank: typeRank.indexOf(itemType)
          };

          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }
      },

      onSelectSearchResultItem: function onSelectSearchResultItem(itemType, item) {

        var selected = this.get('selected');
        var existingItem = selected.findBy('id', item.id);
        if (_ember['default'].isEmpty(existingItem)) {
          var itemId = _ember['default'].get(item, 'id');
          var typeRank = ['resourcetypes', 'resourcegroups', 'resources'];
          var itemData = {
            id: itemId,
            resourceTypeId: itemType === 'resourcetypes' ? itemId : null,
            resourceGroupId: itemType === 'resourcegroups' ? itemId : null,
            resourceId: itemType === 'resources' ? itemId : null,
            name: _ember['default'].get(item, 'name'),
            type: itemType,
            rank: typeRank.indexOf(itemType)
          };
          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }
      },

      // When removing an item from selection
      onRemoveItem: function onRemoveItem(item) {
        this.get('selected').removeObject(item);
      },

      onTimeframeChange: function onTimeframeChange(rangeType) {
        this.set('timeframeSelected', rangeType);
        if (rangeType === 'specific_range') {
          var today = moment().startOf('day');
          this.setProperties({
            startDateTime: today.clone().subtract(7, 'day'),
            endDateTime: today
          });
          return;
        } else {

          var start = undefined;
          var end = undefined;
          switch (rangeType) {

            case 'today':
              start = moment().startOf('day');
              end = moment().endOf('day');
              break;

            case 'next_seven_days':
              start = moment().startOf('day');
              end = start.clone().add(6, 'days').endOf('day');
              break;

            case 'next_month':
              start = moment().startOf('day');
              end = start.clone().add(1, 'month').endOf('day');
              break;

            default:
              break;
          }
          this.setProperties({
            startDateTime: start,
            endDateTime: end
          });
        }
      },

      onTimeframeDatesChange: function onTimeframeDatesChange(start, end) {
        var _getAdjustedTimeframeDates2 = this._getAdjustedTimeframeDates(start, end);

        var startDate = _getAdjustedTimeframeDates2.startDate;
        var endDate = _getAdjustedTimeframeDates2.endDate;

        this.setProperties({
          startDateTime: startDate,
          endDateTime: endDate
        });
      },

      setDateOptionType: function setDateOptionType(value) {
        this.set('dateOptionSelected', value.id);
      },

      setReportSelectionSelected: function setReportSelectionSelected(value) {
        this.set('reportSelectionSelected', value.id);
      },

      // When submiting a search
      onSearch: function onSearch(query, type) {
        var _this6 = this;

        this.setProperties({
          query: query,
          isLoading: true
        });

        this.get('resourceTreeDataFetcher').search(query, type || 0).then(function (data) {
          _this6.set('searchResults', data);
        })['finally'](function () {
          _this6.set('isLoading', false);
        });
      },

      onClearSearch: function onClearSearch() {
        this.setProperties({
          query: '',
          searchResults: null
        });
      },

      clearSelection: function clearSelection() {
        this.set('selected', []);
      },

      generateReport: function generateReport() {
        var _this7 = this;

        var fromDate = this.get('startDateTime');
        var toDate = this.get('endDateTime');
        var useBookingDates = this.get('dateOptionSelected') === 'useBookingDates';
        var reportOnEverything = this.get('reportSelectionSelected') === 'everything';
        var data = this.get('selected');

        if (reportOnEverything) {
          if (_ember['default'].isEmpty(this.get('model.data'))) return;

          data = this.get('model.data').map(function (resourceType) {
            return {
              resourceTypeId: resourceType.id
            };
          });
        }

        this.set('isGeneratingReport', true);
        this.get('reportsProcessor').generate({ data: data, fromDate: fromDate, toDate: toDate, useBookingDates: useBookingDates }).then(function (progressTaskKey) {
          _this7.set('progressTaskKey', progressTaskKey);
        })['catch'](function () {
          _this7.flashError(_this7.get('i18n').t(LOCALE_MESSAGES_PREFIX + 'generate_fail'));
        })['finally'](function () {
          _this7.set('isGeneratingReport', false);
        });
      },

      onGetProgressFailed: function onGetProgressFailed() {
        this.setProperties({
          progressTaskKey: null,
          isLoading: false,
          isGeneratingReport: false
        });

        this.flashError(this.get('i18n').t(LOCALE_MESSAGES_PREFIX + 'generate_fail'));
      },

      onCancelProgress: function onCancelProgress() {
        this.set('progressTaskKey', null);
        this.get('session').restoreAutoLogout();
      },

      showDownloadModal: function showDownloadModal(data) {

        var downloadUrl = this.get('reportsProcessor').getDownloadURL(data.dataFetchKey);
        this.setProperties({
          progressTaskKey: null,
          downloadUrl: downloadUrl
        });

        if (data.taskFailed) {
          this.flashError(this.get('i18n').t(LOCALE_MESSAGES_PREFIX + 'generate_fail'));
          this.get('session').restoreAutoLogout();
          return;
        }

        this.get('downloadFile')(downloadUrl);
      },

      invalidateModel: function invalidateModel() {
        this.send('loadReports');
      }

    }
  });
});