define('scientia-resourcebooker/pods/components/notification-accept-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmModal acceptModal'],

    showNoteInput: false,
    note: '',

    actions: {
      showNoteInput: function showNoteInput() {
        this.set('showNoteInput', true);
        _ember['default'].run.later(this, function () {
          this.$('.bookingCancellationModal-textarea').focus();
        }, 100);
      }
    }
  });
});