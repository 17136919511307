define('scientia-resourcebooker/helpers/booking-form-field-type-to-name', ['exports', 'ember'], function (exports, _ember) {
  exports.bookingFormFieldTypeToName = bookingFormFieldTypeToName;

  var ATTACHMENT = 'attachment';
  var SINGLECHOICE = 'single_choice';
  var LABEL = 'label';
  var MULTIPLECHOICE = 'multiple_choice';
  var NUMERIC = 'numeric';
  var TEXTBOX = 'textbox';
  var LINK = 'url';

  var FIELD_TYPES = [ATTACHMENT, LABEL, MULTIPLECHOICE, NUMERIC, SINGLECHOICE, TEXTBOX, LINK];

  function bookingFormFieldTypeToName(params /*, hash*/) {
    if (_ember['default'].isArray(params)) {
      return FIELD_TYPES[params[0]];
    } else {
      return FIELD_TYPES[params];
    }
  }

  exports['default'] = _ember['default'].Helper.helper(bookingFormFieldTypeToName);
});