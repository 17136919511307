define('scientia-resourcebooker/initializers/allow-link-action', ['exports', 'ember', 'scientia-resourcebooker/mixins/link-action'], function (exports, _ember, _scientiaResourcebookerMixinsLinkAction) {
  exports.initialize = initialize;

  function initialize() {
    _ember['default'].LinkComponent.reopen(_scientiaResourcebookerMixinsLinkAction['default']);
  }

  exports['default'] = {
    name: 'allow-link-action',
    initialize: initialize
  };
});