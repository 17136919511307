define('scientia-resourcebooker/mixins/availability-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    rbToast: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    unavailabilityClearer: _ember['default'].inject.service(),
    unavailabilitySlotsMerger: _ember['default'].inject.service(),

    resourceBusyExceptionsFetcher: _ember['default'].inject.service(),

    availabilityTab: 0,

    showAddUnavailabilityDateSlot: null,
    showAddUnavailabilityWeekdaySlot: null,
    showRemoveUnavailabilityDateSlot: null,
    showRemoveUnavailabilityWeekdaySlot: null,

    showClearUnavailability: null,
    showConfirmClearUnavailability: null,

    isComittingUnavailability: false,
    isClearingUnavailability: false,

    pendingPatternSlots: null,
    pendingDateSlots: null,

    showBusyExceptionsModal: false,
    isLoadingBusyExceptions: false,

    busyExceptionsData: null,
    busyPatternsLinkedData: null,

    showPatternsLinkModal: false,
    showPatternsCopyModal: false,

    includeDisabledPatternsUnavailability: false,

    busyPatternsMergedWithPendingPatterns: _ember['default'].computed('pendingPatternSlots', 'model.eventPatterns', function () {
      var slots = this.get('model.eventPatterns');
      var pendingSlots = this.get('pendingPatternSlots');
      return this.get('unavailabilitySlotsMerger').mergePatternSlots(slots, pendingSlots);
    }),

    busyTimesMergedWithPending: _ember['default'].computed('pendingDateSlots', 'model.events', function () {
      var slots = this.get('model.events');
      var pendingSlots = this.get('pendingDateSlots');
      return this.get('unavailabilitySlotsMerger').mergeDateSlots(slots, pendingSlots);
    }),

    hasPendingUnavailability: _ember['default'].computed.gt('pendingChangesCount', 0),

    pendingChangesCount: _ember['default'].computed('pendingPatternSlots', 'pendingDateSlots', function () {
      return this.get('pendingDateSlots.length') + this.get('pendingPatternSlots').reduce(function (memo, p) {
        return memo + p.length;
      }, 0);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.initWeekdaySlotsArray();
      this.initDateSlotsArray();
    },

    initWeekdaySlotsArray: function initWeekdaySlotsArray() {
      var pendingPatternSlots = new Array(7);
      for (var i = 0; i < 7; i++) {
        pendingPatternSlots[i] = [];
      }

      this.set('pendingPatternSlots', pendingPatternSlots);
    },

    initDateSlotsArray: function initDateSlotsArray() {
      this.set('pendingDateSlots', []);
    },

    getPendingPatternSlots: function getPendingPatternSlots() {
      var pendingPatternSlots = this.get('pendingPatternSlots');
      if (!pendingPatternSlots.length) {
        this.initWeekdaySlotsArray();
      }

      return this.get('pendingPatternSlots');
    },

    actions: {
      showAddUnavailabilityDateSlot: function showAddUnavailabilityDateSlot(moment, userGroup, optionalEndMoment) {

        var slot = {
          fromTime: moment,
          toTime: optionalEndMoment || moment.clone().add(1, 'hour')
        };

        this.setProperties({
          userGroup: userGroup,
          showAddUnavailabilityDateSlot: slot
        });
      },

      showAddUnavailabilityWeekdaySlot: function showAddUnavailabilityWeekdaySlot(slot, userGroup) {
        this.setProperties({
          userGroup: userGroup,
          showAddUnavailabilityWeekdaySlot: slot
        });
      },

      showRemoveUnavailabilityDateSlot: function showRemoveUnavailabilityDateSlot(slot) {
        this.setProperties({
          showRemoveUnavailabilityDateSlot: slot
        });
      },

      showRemoveUnavailabilityWeekdaySlot: function showRemoveUnavailabilityWeekdaySlot(slot) {
        this.setProperties({
          showRemoveUnavailabilityWeekdaySlot: slot
        });
      },

      saveUnavailabilityDateSlot: function saveUnavailabilityDateSlot(dateSlot) {

        var pendingSlots = this.get('pendingDateSlots');
        var pendingSlot = _ember['default'].Object.create({
          isPendingAdd: true,
          fromTime: dateSlot.fromTime.clone(),
          toTime: dateSlot.toTime.clone(),
          data: {
            duration: dateSlot.toTime.diff(dateSlot.fromTime, 'minute'),
            specificDate: dateSlot.fromTime.clone()
          },
          title: this.get('i18n').t('components.remove_unavailability_slot.pending_addition').toString(),
          extraClassNames: 'chronos-event--pendingAdd'
        });

        pendingSlots.pushObject(pendingSlot);

        this.setProperties({
          showAddUnavailabilityDateSlot: null,
          pendingDateSlots: pendingSlots.slice()
        });
      },

      saveUnavailabilityWeekdaySlot: function saveUnavailabilityWeekdaySlot(newSlot, userGroup) {

        var allSlots = this.get('busyPatternsMergedWithPendingPatterns');
        var pendingSlots = this.getPendingPatternSlots();

        var conflictingSlot = allSlots[newSlot.dayIndex].find(function (existingSlot) {
          var newStart = newSlot.fromTime;
          var newEnd = newSlot.toTime;
          var exStart = existingSlot.fromTime.asMinutes();
          var exEnd = existingSlot.toTime.asMinutes();

          return !existingSlot.isPendingRemove && !(newStart >= exEnd || newEnd <= exStart);
        });

        if (!_ember['default'].isEmpty(conflictingSlot)) return _ember['default'].RSVP.reject(conflictingSlot);

        newSlot.isPendingAdd = true;
        var duration = newSlot.toTime - newSlot.fromTime;
        _ember['default'].set(newSlot, 'fromTime', moment.duration(newSlot.fromTime * 1000 * 60));
        _ember['default'].set(newSlot, 'toTime', moment.duration(newSlot.toTime * 1000 * 60));

        newSlot.specificDate.add(newSlot.fromTime, 'minute');

        newSlot.data = {
          weekdayIndex: newSlot.dayIndex,
          specificDate: newSlot.specificDate,
          duration: duration,
          userGroup: userGroup
        };

        pendingSlots.objectAt(newSlot.dayIndex).pushObject(newSlot);

        this.setProperties({
          showAddUnavailabilityWeekdaySlot: null,
          pendingPatternSlots: pendingSlots.slice()
        });

        return _ember['default'].RSVP.resolve();
      },

      deleteUnavailabilityDateSlot: function deleteUnavailabilityDateSlot(slot) {
        var pendingSlots = this.get('pendingDateSlots');
        var dateSlots = this.get('model.events');

        var existingSlot = dateSlots.find(function (d) {
          return d.fromTime.isSame(slot.fromTime) && d.toTime.isSame(slot.toTime);
        });

        var pendingSlot = _ember['default'].Object.create({
          isPendingRemove: true,
          fromTime: slot.fromTime.clone(),
          toTime: slot.toTime.clone(),
          data: existingSlot.data,
          title: this.get('i18n').t('components.remove_unavailability_slot.pending_removal').toString(),
          extraClassNames: 'chronos-event--pendingRemove'
        });

        pendingSlots.pushObject(pendingSlot);

        this.setProperties({
          showRemoveUnavailabilityDateSlot: null,
          pendingDateSlots: pendingSlots.slice()
        });
      },

      deleteUnavailabilityWeekdaySlot: function deleteUnavailabilityWeekdaySlot(slot) {

        var pendingSlot = {
          isPendingRemove: true,
          fromTime: slot.fromTime,
          toTime: slot.toTime,
          dayIndex: slot.dayIndex,
          data: slot.data
        };

        var pendingSlots = this.getPendingPatternSlots();
        pendingSlots.objectAt(slot.dayIndex).pushObject(pendingSlot);

        this.setProperties({
          showRemoveUnavailabilityWeekdaySlot: null,
          pendingPatternSlots: pendingSlots.slice()
        });
      },

      onUnavailabilitySlotClick: function onUnavailabilitySlotClick(slot) {
        if (!slot.extraClassNames) {
          this.send('showRemoveUnavailabilityDateSlot', slot);
        } else {
          if (slot.extraClassNames.indexOf('pendingRemove') !== -1) {
            this.send('undoRemoveDateSlot', slot);
          } else {
            this.send('undoAddDateSlot', slot);
          }
        }
      },

      undoAddDateSlot: function undoAddDateSlot(slot) {
        var _this = this;

        var pendingSlots = this.get('pendingDateSlots');

        var defer = _ember['default'].RSVP.defer();
        var confirmData = this._getGlobalConfirmParams(true);
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise['catch'](function () {
          var existingSlot = pendingSlots.find(function (p) {
            return p.fromTime.isSame(slot.fromTime) && p.toTime.isSame(slot.toTime);
          });
          pendingSlots.removeObject(existingSlot);
          _this.set('pendingDateSlots', pendingSlots.slice());
        })['finally'](function () {
          _this.get('globalConfirmer').close();
        });
      },

      undoRemoveDateSlot: function undoRemoveDateSlot(slot) {
        var _this2 = this;

        var pendingSlots = this.get('pendingDateSlots');

        var defer = _ember['default'].RSVP.defer();
        var confirmData = this._getGlobalConfirmParams();
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise['catch'](function () {
          var existingSlot = pendingSlots.find(function (p) {
            return p.fromTime.isSame(slot.fromTime) && p.toTime.isSame(slot.toTime);
          });
          pendingSlots.removeObject(existingSlot);
          _this2.set('pendingDateSlots', pendingSlots.slice());
        })['finally'](function () {
          _this2.get('globalConfirmer').close();
        });
      },

      undoAddWeekdaySlot: function undoAddWeekdaySlot(slot) {
        var _this3 = this;

        var pendingSlots = this.getPendingPatternSlots();

        var defer = _ember['default'].RSVP.defer();
        var confirmData = this._getGlobalConfirmParams(true);
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise['catch'](function () {
          pendingSlots.objectAt(slot.dayIndex).removeObject(slot);
          _this3.set('pendingPatternSlots', pendingSlots.slice());
        })['finally'](function () {
          _this3.get('globalConfirmer').close();
        });
      },

      undoRemoveWeekdaySlot: function undoRemoveWeekdaySlot(slot) {
        var _this4 = this;

        var pendingSlots = this.getPendingPatternSlots();

        var defer = _ember['default'].RSVP.defer();
        var confirmData = this._getGlobalConfirmParams();
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise['catch'](function () {
          pendingSlots.objectAt(slot.dayIndex).removeObject(slot);
          _this4.set('pendingPatternSlots', pendingSlots.slice());
        })['finally'](function () {
          _this4.get('globalConfirmer').close();
        });
      },

      undoAllUnavailability: function undoAllUnavailability() {
        var _this5 = this;

        var defer = _ember['default'].RSVP.defer();

        var params = {
          titlePath: 'components.manage_resources.availability_form.undo_changes_title',
          messagePath: 'components.manage_resources.availability_form.undo_changes_message',
          confirmLabelPath: 'general.yes',
          cancelLabelPath: 'general.no',
          deferred: defer
        };

        this.get('globalConfirmer').confirmModal(params);

        defer.promise.then(function () {
          _this5.initWeekdaySlotsArray();
          _this5.initDateSlotsArray();
        })['finally'](function () {
          _this5.get('globalConfirmer').close();
        });
      },

      onRequestClearUnavailability: function onRequestClearUnavailability(unavailabilityType) {
        this.set('showConfirmClearUnavailability', unavailabilityType);
      },

      confirmClearUnavailability: function confirmClearUnavailability() {
        var _this6 = this;

        this.set('isClearingUnavailability', true);

        var data = {
          type: this.get('type'),
          id: this.get('modelId'),
          parentId: this.get('parentId'),
          unavailabilityOption: this.get('showConfirmClearUnavailability')
        };

        this.get('unavailabilityClearer').clear(data).then(function () {

          _this6.set('showConfirmClearUnavailability', null);
          _this6.set('showClearUnavailability', false);
          _this6.get('flashMessages').success(_this6.get('i18n').t('components.manage_resources.availability_form.unavailability_clear_success'));
          _this6.get('appInsights').trackClearUnavailability(data);

          _this6.send('reload', {});
        })['finally'](function () {
          _this6.set('isClearingUnavailability', false);
          _this6.set('showConfirmClearUnavailability', null);
        })['catch'](this._handleErrors.bind(this));
      },

      showBusyExceptionsManager: function showBusyExceptionsManager() {
        this.set('showBusyExceptionsModal', true);
      },

      closeBusyExceptionsManager: function closeBusyExceptionsManager() {
        var clonedBusyExceptions = this.get('resourceBusyExceptionsFetcher').cloneBusyExceptions(this.get('model.busyExceptions'));

        this.setProperties({
          busyExceptionsData: clonedBusyExceptions,
          showBusyExceptionsModal: false
        });
      }
    },

    _getGlobalConfirmParams: function _getGlobalConfirmParams(isAddition) {
      return {
        titlePath: 'components.remove_unavailability_slot.pending_' + (isAddition ? 'addition' : 'removal'),
        messagePath: 'components.remove_unavailability_slot.slot_pending_' + (isAddition ? 'addition' : 'removal'),
        cancelLabelPath: 'general.undo',
        confirmLabelPath: 'general.ok'
      };
    },

    _handleErrors: function _handleErrors(error) {
      var errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON);
      if (errorMessage) {
        this.get('flashMessages').danger(errorMessage);
      } else {
        this.get('flashMessages').danger(this.get('i18n').t('general.generic_error'));
      }

      this.setProperties({
        showAddUnavailabilityDateSlot: null,
        showAddUnavailabilityWeekdaySlot: null,
        showRemoveUnavailabilityDateSlot: null,
        showRemoveUnavailabilityWeekdaySlot: null,
        showConfirmClearUnavailability: null
      });
    }
  });
});