define('scientia-resourcebooker/pods/components/confirm-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog', 'ember-i18n'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal'],
    classNameBindings: ['location'],
    location: '',
    confirmTitle: '',
    confirmMessage: '',
    buttonIsDisabled: false,
    headerIconClass: '',
    singleButtonMode: false,

    confirmYesLabel: (0, _emberI18n.translationMacro)('general.ok'),
    confirmNoLabel: (0, _emberI18n.translationMacro)('general.cancel'),

    model: null,

    actions: {
      confirm: function confirm() {

        this.set('buttonIsDisabled', true);

        if (this.get('model') && this.get('model.deferred')) {
          this.get('model.deferred').resolve();
        } else {
          if (this.singleButtonAction && this.get('singleButtonMode')) {
            this.singleButtonAction(this.get('model'));
          } else {
            this.confirm(this.get('model'));
          }
        }
      },

      cancel: function cancel() {
        if (this.get('close')) {
          this.get('close')();
        }

        if (this.get('model') && this.get('model.deferred')) {
          this.get('model.deferred').reject();
        }
      }
    }
  });
});