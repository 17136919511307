define('scientia-resourcebooker/pods/manage-resources/resource-group/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_group.new.page_title');
    },

    errorHandler: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),

    model: function model(params, transition) {
      return _ember['default'].RSVP.hash({
        resourceType: this.store.find('resource-type', transition.params['manage-resources.resource-group'].resourceTypeId),
        resourceGroup: this.store.createRecord('resourceGroup', {
          resourceTypeId: transition.params['manage-resources.resource-group'].resourceTypeId
        })
      });
    },

    afterModel: function afterModel(model) {
      if (!this.get('can').can('create-resource-groups resource-type', model.resourceType)) {
        this.transitionTo('manage-resources.resource-type.index');
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: '',
          isSaving: false
        });
      }
    },

    actions: {
      createResourceGroup: function createResourceGroup() {
        var _this = this;

        var controller = this.controllerFor('manage-resources.resource-group.new');
        var model = controller.get('model.resourceGroup');

        controller.set('isSaving', true);

        model.save().then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('resource-group');
          return _this.transitionTo('manage-resources.resource-group.edit', model.get('resourceTypeId'), id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('resource-group');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.resource-group.new');
        if (model.resourceGroup.get('isNew')) model.resourceGroup.destroyRecord();
      }

    }
  });
});