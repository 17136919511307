define('scientia-resourcebooker/models/property', ['exports', 'ember-data', 'model-fragments/fragment'], function (exports, _emberData, _modelFragmentsFragment) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _modelFragmentsFragment['default'].extend({
    identity: attr('string'),
    name: attr('string'),
    displayName: attr('string'),
    helpText: attr('string'),
    computedDisplayName: (function () {
      return this.get('displayName') ? this.get('displayName') : this.get('name');
    }).property('name', 'displayName'),
    value: attr('raw'),
    valueType: attr('string', { defaultValue: 'text' }),
    valueChoices: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    valueChoicesWithId: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    isFilterable: attr('boolean'),

    isRestrictedVisibility: attr('boolean'),
    restrictedVisibilityPermissionGroups: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } }),

    isObsolete: attr('boolean'),
    rank: attr('number'),
    resource: belongsTo('resource'),
    autoDisplayId: attr('string', {
      defaultValue: function defaultValue() {
        return new Date().getTime().toString();
      } }),
    displayId: (function () {
      return this.get('identity') ? this.get('identity') : this.get('autoDisplayId');
    }).property('identity', 'autoDisplayId')
  });
});