define('scientia-resourcebooker/pods/components/manage-resources/resource-group-resources-manager/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-group-resources-manager'],

    resourceGroup: null,
    nestedResourceStore: _ember['default'].inject.service(),
    isLoadingResources: false,

    resources: [],

    actions: {
      onResourceSearch: function onResourceSearch(query, deferred) {
        if (this.get('disabled')) return;
        _ember['default'].run.debounce(this, 'send', 'onResourceSearchDebounced', query, deferred, 500);
      },

      onResourceSearchDebounced: function onResourceSearchDebounced(query, deferred) {
        var resourceGroup = this.get('resourceGroup');
        var data = {
          type: 'resourceType.resource',
          parentId: resourceGroup.get('resourceTypeId'),
          data: { pageNumber: query.page || 1, query: query.term, itemsPerPage: 10, adminPermissionsOnly: true }
        };

        this.get('nestedResourceStore').fetch(data).then(function (response) {
          var resources = response;
          var meta = response.meta;
          delete response.meta;
          var hasMoreResults = meta.currentPage < meta.totalPages;
          var responseData = { data: resources, more: hasMoreResults };

          deferred.resolve(responseData);
        });
      },

      didSelectResources: function didSelectResources(resources) {
        this.set('resourceGroup.resourceIds', resources);
      },

      triggerListOpen: function triggerListOpen() {
        this.$().find('.select2-choices').trigger('click');
      }
    }

  });
});