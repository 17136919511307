define('scientia-resourcebooker/mixins/page-changer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    actions: {
      changePage: function changePage(pageNumber, isGroup) {
        var queryParam = isGroup ? 'groupPage' : 'page';
        this.set(queryParam, pageNumber);
      }
    }
  });
});