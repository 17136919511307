define("scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 6
            },
            "end": {
              "line": 8,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "role", "listitem");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "bookingRequestForm-field-singleChoice-itemList");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "bookingRequestForm-field-singleChoice-itemList-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [4]), 0, 0);
          return morphs;
        },
        statements: [["inline", "custom-checkbox", [], ["checked", ["subexpr", "contains", [["get", "item.value", ["loc", [null, [16, 46], [16, 56]]], 0, 0, 0, 0], ["get", "selectedValues", ["loc", [null, [16, 57], [16, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 36], [16, 72]]], 0, 0], "action", ["subexpr", "action", ["toggleSelection", ["get", "item", ["loc", [null, [16, 106], [16, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 80], [16, 111]]], 0, 0]], ["loc", [null, [16, 10], [16, 113]]], 0, 0], ["content", "item.text", ["loc", [null, [19, 77], [19, 90]]], 0, 0, 0, 0]],
        locals: ["item"],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 6
            },
            "end": {
              "line": 24,
              "column": 180
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "aria-live", "polite");
          dom.setAttribute(el1, "aria-atomic", "true");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [24, 140], [24, 173]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 6
            },
            "end": {
              "line": 27,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          dom.setAttribute(el1, "aria-live", "polite");
          dom.setAttribute(el1, "aria-atomic", "true");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "externalDataError", ["loc", [null, [26, 74], [26, 95]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "aria-hidden", "true");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "role", "list");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createAttrMorph(element3, 'aria-label');
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createMorphAt(element3, 3, 3);
        morphs[5] = dom.createMorphAt(element3, 5, 5);
        return morphs;
      },
      statements: [["content", "model.description", ["loc", [null, [5, 6], [5, 27]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["get", "model.isMinMaxRestricted", ["loc", [null, [6, 17], [6, 41]]], 0, 0, 0, 0], ["subexpr", "gte", [["get", "model.minimum", ["loc", [null, [6, 47], [6, 60]]], 0, 0, 0, 0], 1], [], ["loc", [null, [6, 42], [6, 63]]], 0, 0], ["get", "model.description", ["loc", [null, [6, 64], [6, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 12], [6, 82]]], 0, 0]], [], 0, null, ["loc", [null, [6, 6], [8, 13]]]], ["attribute", "aria-label", ["concat", [["get", "model.description", ["loc", [null, [12, 32], [12, 49]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["subexpr", "and", [["get", "model.isMinMaxRestricted", ["loc", [null, [12, 62], [12, 86]]], 0, 0, 0, 0], ["subexpr", "gte", [["get", "model.minimum", ["loc", [null, [12, 92], [12, 105]]], 0, 0, 0, 0], 1], [], ["loc", [null, [12, 87], [12, 108]]], 0, 0]], [], ["loc", [null, [12, 57], [12, 109]]], 0, 0], ["subexpr", "t", ["components.booking_request_form.field_required"], [], ["loc", [null, [12, 110], [12, 162]]], 0, 0], ""], [], ["loc", [null, [12, 52], [12, 168]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "model.items", ["loc", [null, [13, 12], [13, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [13, 4], [22, 13]]]], ["block", "if", [["subexpr", "and", [["get", "shouldDisplayValidations", ["loc", [null, [24, 17], [24, 41]]], 0, 0, 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [24, 42], [24, 73]]], 0, 0]], [], ["loc", [null, [24, 12], [24, 74]]], 0, 0]], [], 2, null, ["loc", [null, [24, 6], [24, 187]]]], ["block", "if", [["get", "externalDataError", ["loc", [null, [25, 12], [25, 29]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [25, 6], [27, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});