define('scientia-resourcebooker/services/recurrence-processor', ['exports', 'ember', 'scientia-resourcebooker/helpers/offset-array'], function (exports, _ember, _scientiaResourcebookerHelpersOffsetArray) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var DAILY = 0;
  var WEEKLY = 1;
  var MONTHLY = 2;
  // const YEARLY = 3;

  var UNTIL_NR_OCCURRENCES = 0;
  var UNTIL_DATE = 1;

  var MONTHLY_TYPE_DATES_OF_MONTH = 0;
  var MONTHLY_TYPE_DAY_OF_WEEK = 1;

  exports['default'] = _ember['default'].Service.extend({

    i18n: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    defaultRecurrence: _ember['default'].Object.create({
      recurrenceTypeSelected: 0,
      startDate: moment(),
      interval: 1,
      excludeWeekends: false,
      untilType: UNTIL_NR_OCCURRENCES,
      fromTime: '',
      toTime: '',
      untilDate: moment(),
      monthlyType: 1,
      recurringDaysOfWeek: [],
      recurrenceDescription: '',
      numberOfOccurrences: 2
    }),

    generate: function generate(recurrence) {

      try {
        var _recurrence$get$split = recurrence.get('fromTime').split(':');

        var _recurrence$get$split2 = _slicedToArray(_recurrence$get$split, 2);

        var fromTimeHour = _recurrence$get$split2[0];
        var fromTimeMin = _recurrence$get$split2[1];

        var _recurrence$get$split3 = recurrence.get('toTime').split(':');

        var _recurrence$get$split32 = _slicedToArray(_recurrence$get$split3, 2);

        var toTimeHour = _recurrence$get$split32[0];
        var toTimeMin = _recurrence$get$split32[1];

        var startDateTime = recurrence.get('startDate').hour(fromTimeHour).minute(fromTimeMin).startOf('minute');
        var endDateTime = recurrence.get('startDate').clone().hour(toTimeHour).minute(toTimeMin).startOf('minute');
        if (endDateTime.isBefore(startDateTime) || endDateTime.isSame(startDateTime)) endDateTime.add(1, 'day');
        var pattern = {
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          interval: parseInt(recurrence.get('interval')),
          untilType: parseInt(recurrence.get('untilType')),
          recurrenceType: parseInt(recurrence.get('recurrenceTypeSelected')),
          recurrenceDescription: this.generateDescription(recurrence)
        };

        if (pattern.untilType === UNTIL_NR_OCCURRENCES) {
          pattern.numberOfOccurrences = parseInt(recurrence.get('numberOfOccurrences'));
        } else {
          pattern.untilDate = recurrence.get('untilDate').endOf('day').format('YYYY-MM-DD');
        }

        switch (parseInt(recurrence.get('recurrenceTypeSelected'))) {
          case DAILY:
            pattern = this.daily(recurrence, pattern);
            break;
          case WEEKLY:
            pattern = this.weekly(recurrence, pattern);
            break;
          case MONTHLY:
            pattern = this.monthly(recurrence, pattern);
            break;
        }

        return pattern;
      } catch (e) {
        return null;
      }
    },

    daily: function daily(recurrence, pattern) {
      pattern.excludeWeekends = recurrence.get('excludeWeekends');
      return pattern;
    },

    weekly: function weekly(recurrence, pattern) {
      pattern.recurringDaysOfWeek = recurrence.get('recurringDaysOfWeek');
      return pattern;
    },

    monthly: function monthly(recurrence, pattern) {
      pattern.monthlyType = parseInt(recurrence.get('monthlyType'));

      if (pattern.monthlyType === MONTHLY_TYPE_DATES_OF_MONTH) {
        pattern.datesOfMonth = recurrence.get('datesOfMonth');
      } else {
        pattern.recurrenceMonthlyInterval = recurrence.get('recurrenceMonthlyInterval').objectAt(0);
        pattern.dayOfWeek = recurrence.get('dayOfWeek').objectAt(0);
      }

      // If the monthlyInterval is "Last Mon/Tue.. of the month" change the index from 5 to -1 for the API
      if (pattern.recurrenceMonthlyInterval === 5) pattern.recurrenceMonthlyInterval = -1;

      return pattern;
    },

    validate: function validate(recurrence, isLimitedRecurrence) {

      var validation = this.validateGlobal(recurrence, isLimitedRecurrence);

      switch (parseInt(recurrence.get('recurrenceTypeSelected'))) {
        case DAILY:
          validation = this.validateDaily(validation, recurrence);
          break;
        case WEEKLY:
          validation = this.validateWeekly(validation, recurrence);
          break;
        case MONTHLY:
          validation = this.validateMonthly(validation, recurrence);
          break;
      }

      return validation;
    },

    // jshint maxcomplexity:21
    validateGlobal: function validateGlobal(recurrence, isLimitedRecurrence) {
      var validation = [];

      if (isLimitedRecurrence) {
        if (_ember['default'].isNone(recurrence.get('startDate'))) validation.push('start_week_commencing');
        if (_ember['default'].isNone(recurrence.get('untilDate'))) validation.push('end_week_commencing');
      } else {
        if (_ember['default'].isNone(recurrence.get('startDate'))) validation.push('start_date');
      }

      if (_ember['default'].isEmpty(recurrence.get('fromTime'))) validation.push('from_time');
      if (_ember['default'].isEmpty(recurrence.get('toTime'))) validation.push('to_time');
      if (!_ember['default'].isEmpty(validation)) return validation;

      if (!parseInt(recurrence.get('interval'))) validation.push('frequency');

      // Check from and to time

      var _recurrence$get$split4 = recurrence.get('fromTime').split(':');

      var _recurrence$get$split42 = _slicedToArray(_recurrence$get$split4, 2);

      var fromTimeHour = _recurrence$get$split42[0];
      var fromTimeMin = _recurrence$get$split42[1];

      var _recurrence$get$split5 = recurrence.get('toTime').split(':');

      var _recurrence$get$split52 = _slicedToArray(_recurrence$get$split5, 2);

      var toTimeHour = _recurrence$get$split52[0];
      var toTimeMin = _recurrence$get$split52[1];

      var startDateTime = recurrence.get('startDate').hour(fromTimeHour).minute(fromTimeMin).startOf('minute');
      var endDateTime = recurrence.get('startDate').clone().hour(toTimeHour).minute(toTimeMin).startOf('minute');

      // We are checking if toTimeHour is 00 because startDate and endDate are always the same for recurring just the times
      // that change. However validation would fail if someone picked from 23:00 to 00:00 (24:00) so we exclude 00:00 from to_time
      // validation
      if (endDateTime.isBefore(startDateTime) && (toTimeHour !== '00' || toTimeMin !== '00') || endDateTime.isSame(startDateTime)) {
        validation.push('to_time');
      }

      // If untilType is invalid
      var untilType = parseInt(recurrence.get('untilType'));
      if (untilType !== UNTIL_NR_OCCURRENCES && untilType !== UNTIL_DATE) validation.push('repeat_until');

      // If untilType is Nr Occurrences
      if (untilType === UNTIL_NR_OCCURRENCES) {
        if (!parseInt(recurrence.get('numberOfOccurrences'))) validation.push('occurrences');

        // If untiltype is until date
      } else if (untilType === UNTIL_DATE) {
          // If a start date and until date are selected lets make sure the until date is after the start date
          var startDate = recurrence.get('startDate');
          var untilDate = recurrence.get('untilDate');
          if (!_ember['default'].isEmpty(startDate) && !_ember['default'].isEmpty(untilDate)) {
            if (untilDate.isBefore(startDate, 'day') || untilDate.isSame(startDate, 'day') || _ember['default'].isNone(untilDate)) validation.push('until_end_date');
          } else if (_ember['default'].isNone(untilDate)) {
            validation.push('until_end_date');
          }
        }

      return validation;
    },

    // No extra fields require validation for Daily ( the only "excludeWeekends" is optional)
    validateDaily: function validateDaily(validation /*, recurrence*/) {
      return validation;
    },

    validateWeekly: function validateWeekly(validation, recurrence) {

      if (_ember['default'].isEmpty(recurrence.get('recurringDaysOfWeek'))) validation.push('weekday');
      return validation;
    },

    validateMonthly: function validateMonthly(validation, recurrence) {
      var type = parseInt(recurrence.get('monthlyType'));
      if (type === MONTHLY_TYPE_DATES_OF_MONTH) {
        if (_ember['default'].isEmpty(recurrence.get('datesOfMonth'))) validation.push('day_of_month');
      } else if (type === MONTHLY_TYPE_DAY_OF_WEEK) {
        if (_ember['default'].isEmpty(recurrence.get('recurrenceMonthlyInterval'))) validation.push('period');
        if (_ember['default'].isEmpty(recurrence.get('dayOfWeek'))) validation.push('weekday');
      }

      return validation;
    },

    generateDescription: function generateDescription(recurrence) {

      if (!recurrence) return '';

      var description = undefined;
      switch (parseInt(recurrence.get('recurrenceTypeSelected'))) {
        case DAILY:
          description = this.generateDescriptionDaily(recurrence);
          break;
        case WEEKLY:

          if (recurrence.get('isSPlusRestricted')) {
            description = this.generateDescriptionWeeklyRestricted(recurrence);
          } else {
            description = this.generateDescriptionWeekly(recurrence);
          }
          break;

        case MONTHLY:
          description = this.generateDescriptionMonthly(recurrence);
          break;
        default:
          return '';
      }

      return description;
    },

    generateDescriptionDaily: function generateDescriptionDaily(recurrence) {
      var props = recurrence.getProperties(['startDate', 'untilType', 'recurrenceTypeSelected', 'untilDate', 'interval', 'numberOfOccurrences', 'fromTime', 'toTime', 'excludeWeekends']);

      var startDate = this._getEffectiveStartDateDaily({
        startDate: props.startDate,
        fromTime: props.fromTime,
        excludeWeekends: props.excludeWeekends,
        weekendDays: this.get('systemSettings').getSetting('weekendDays')
      });

      var endDate = undefined;

      if (parseInt(props.untilType) === UNTIL_DATE) {
        endDate = props.untilDate;
      } else {

        endDate = this._getEndDateDaily({
          startDate: startDate,
          interval: props.interval,
          untilType: props.untilType,
          untilDate: props.untilDate,
          numberOfOccurrences: props.numberOfOccurrences,
          fromTime: props.fromTime,
          excludeWeekends: props.excludeWeekends,
          weekendDays: this.get('systemSettings').getSetting('weekendDays')
        });
      }

      return this.get('i18n').t('components.recurring_event_fields.description_daily', {
        count: props.interval,
        interval: props.interval,
        startDate: startDate ? startDate.format('DD-MM-YYYY') : '____',
        startTime: props.fromTime || '____',
        endTime: props.toTime || '____',
        endDate: endDate ? endDate.format('DD-MM-YYYY') : '____'
      }).toString();
    },

    generateDescriptionWeekly: function generateDescriptionWeekly(recurrence) {

      var props = recurrence.getProperties(['startDate', 'untilType', 'untilDate', 'interval', 'numberOfOccurrences', 'fromTime', 'toTime', 'recurringDaysOfWeek']);
      var weekdaysValues = props.recurringDaysOfWeek || [];
      var weekDaysFull = this._getWeekDaysFromValues(weekdaysValues);

      var effectiveStartDate = this._getEffectiveStartDateWeekly({
        startDate: props.startDate,
        recurringDaysOfWeek: weekdaysValues
      });

      var endDate = this._getEndDateWeekly({
        count: props.interval,
        startDate: effectiveStartDate,
        daysOfWeek: weekdaysValues,
        interval: props.interval,
        untilType: props.untilType,
        endDate: props.untilDate,
        numberOfOccurrences: props.numberOfOccurrences });

      if (endDate && effectiveStartDate && endDate.clone().startOf('day').isBefore(effectiveStartDate.clone().startOf('day'))) {
        return this.get('i18n').t('components.recurring_event_fields.no_occurrences_error').toString();
      }

      return this.get('i18n').t('components.recurring_event_fields.description_weekly', {
        count: props.interval,
        weekdays: weekDaysFull || '____',
        interval: props.interval,
        startDate: effectiveStartDate ? effectiveStartDate.format('DD-MM-YYYY') : '____',
        startTime: props.fromTime || '____',
        endTime: props.toTime || '____',
        endDate: endDate ? endDate.format('DD-MM-YYYY') : '____'
      }).toString();
    },

    generateDescriptionWeeklyRestricted: function generateDescriptionWeeklyRestricted(recurrence) {
      var props = recurrence.getProperties(['startDate', 'untilDate', 'fromTime', 'toTime', 'interval', 'recurringDaysOfWeek']);

      var weekdaysValues = props.recurringDaysOfWeek || [];
      var weekDaysFull = this._getWeekDaysFromValues(weekdaysValues);

      var effectiveStartDate = this._getEffectiveStartDateWeeklyRestricted({
        startDate: props.startDate,
        recurringDaysOfWeek: weekdaysValues
      });

      var endDate = this._getEndDateWeeklyRestricted({
        untilDate: props.untilDate,
        interval: props.interval,
        daysOfWeek: weekdaysValues });

      return this.get('i18n').t('components.recurring_event_fields.description_weekly', {
        count: props.interval,
        weekdays: weekDaysFull || '____',
        interval: props.interval,
        startDate: effectiveStartDate ? effectiveStartDate.format('DD-MM-YYYY') : '____',
        startTime: props.fromTime || '____',
        endTime: props.toTime || '____',
        endDate: endDate ? endDate.format('DD-MM-YYYY') : '____'
      }).toString();
    },

    generateDescriptionMonthly: function generateDescriptionMonthly(recurrence) {
      var monthlyType = parseInt(recurrence.get('monthlyType'));
      if (monthlyType === MONTHLY_TYPE_DATES_OF_MONTH) {
        return this.generateDescriptionMonthlySpecificDay(recurrence);
      } else if (monthlyType === MONTHLY_TYPE_DAY_OF_WEEK) {
        return this.generateDescriptionMonthlyPeriod(recurrence);
      }
    },

    generateDescriptionMonthlySpecificDay: function generateDescriptionMonthlySpecificDay(recurrence) {
      var props = recurrence.getProperties(['startDate', 'untilType', 'untilDate', 'interval', 'numberOfOccurrences', 'fromTime', 'toTime', 'datesOfMonth']);

      var datesOfMonth = props.datesOfMonth || [];

      var effectiveStartDate = this._getEffectiveStartDateMonthlySpecificDay({
        startDate: props.startDate,
        fromTime: props.fromTime,
        datesOfMonth: datesOfMonth });

      var endDate = this._getEndDateMonthlySpecificDay({
        startDate: effectiveStartDate,
        interval: props.interval,
        untilType: props.untilType,
        endDate: props.untilDate,
        numberOfOccurrences: props.numberOfOccurrences });

      var day = undefined;

      if (!_ember['default'].isEmpty(datesOfMonth)) {
        day = datesOfMonth.get('firstObject');
      }

      if (endDate && effectiveStartDate && endDate.clone().startOf('day').isBefore(effectiveStartDate.clone().startOf('day'))) {
        return this.get('i18n').t('components.recurring_event_fields.no_occurrences_error').toString();
      }

      return this.get('i18n').t('components.recurring_event_fields.description_monthly_specific_day', {
        count: props.interval,
        day: day || '__',
        interval: props.interval,
        startDate: effectiveStartDate ? effectiveStartDate.format('DD-MM-YYYY') : '____',
        startTime: props.fromTime || '____',
        endTime: props.toTime || '____',
        endDate: endDate ? endDate.format('DD-MM-YYYY') : '____'
      }).toString();
    },

    generateDescriptionMonthlyPeriod: function generateDescriptionMonthlyPeriod(recurrence) {
      var props = recurrence.getProperties(['startDate', 'untilType', 'recurrenceTypeSelected', 'untilDate', 'interval', 'numberOfOccurrences', 'fromTime', 'toTime', 'dayOfWeek', 'recurrenceMonthlyInterval']);

      var periodObject = props.recurrenceMonthlyInterval || [];

      var periodName = this._getPeriodNameFromValues(periodObject);

      var dayOfWeek = props.dayOfWeek || [];

      var effectiveStartDate = this._getEffectiveStartDateMonthlyPeriod({
        startDate: props.startDate,
        fromTime: props.fromTime,
        dayOfWeek: dayOfWeek,
        recurrenceMonthlyInterval: props.recurrenceMonthlyInterval
      });

      var endDate = this._getEndDateMonthlyPeriod({
        startDate: effectiveStartDate || props.startDate,
        interval: props.interval,
        untilType: props.untilType,
        endDate: props.untilDate,
        recurrenceMonthlyInterval: periodObject,
        numberOfOccurrences: props.numberOfOccurrences });

      var weekdaysString = this._getWeekDaysFromValues(dayOfWeek);

      if (endDate && effectiveStartDate && endDate.clone().startOf('day').isBefore(effectiveStartDate.clone().startOf('day'))) {
        return this.get('i18n').t('components.recurring_event_fields.no_occurrences_error').toString();
      }

      return this.get('i18n').t('components.recurring_event_fields.description_monthly_period', {
        count: props.interval,
        period: periodName || '____',
        day: weekdaysString || '____',
        interval: props.interval,
        startDate: effectiveStartDate ? effectiveStartDate.format('DD-MM-YYYY') : '____',
        startTime: props.fromTime || '____',
        endTime: props.toTime || '____',
        endDate: endDate ? endDate.format('DD-MM-YYYY') : '____'
      }).toString();
    },

    // Calculates a startDate for daily pattern
    _getEffectiveStartDateDaily: function _getEffectiveStartDateDaily(_ref) {
      var startDate = _ref.startDate;
      var fromTime = _ref.fromTime;
      var excludeWeekends = _ref.excludeWeekends;
      var weekendDays = _ref.weekendDays;

      if (!startDate) return null;
      if (!fromTime) return startDate;

      var effectiveStartDate = startDate.clone();

      if (excludeWeekends && !_ember['default'].isEmpty(weekendDays)) {
        while (weekendDays.includes(effectiveStartDate.day())) effectiveStartDate.add(1, 'day');
      }

      return effectiveStartDate;
    },

    // Calculates start date for weekly pattern.
    _getEffectiveStartDateWeekly: function _getEffectiveStartDateWeekly(_ref2) {
      var startDate = _ref2.startDate;
      var recurringDaysOfWeek = _ref2.recurringDaysOfWeek;

      if (!startDate) return null;
      if (_ember['default'].isEmpty(recurringDaysOfWeek)) return startDate;

      var effectiveStartDate = startDate.clone();

      while (!recurringDaysOfWeek.includes(effectiveStartDate.day())) {
        effectiveStartDate.add(1, 'day');
      }

      return effectiveStartDate;
    },

    // Calculates start date for SPlus restricted weekly pattern.
    _getEffectiveStartDateWeeklyRestricted: function _getEffectiveStartDateWeeklyRestricted(_ref3) {
      var startDate = _ref3.startDate;
      var recurringDaysOfWeek = _ref3.recurringDaysOfWeek;

      if (!startDate) return null;
      if (_ember['default'].isEmpty(recurringDaysOfWeek)) return startDate;

      var effectiveStartDate = startDate.clone();

      while (!recurringDaysOfWeek.includes(effectiveStartDate.day())) {
        effectiveStartDate.add(1, 'day');
      }

      return effectiveStartDate;
    },

    _getEffectiveStartDateMonthlySpecificDay: function _getEffectiveStartDateMonthlySpecificDay(_ref4) {
      var startDate = _ref4.startDate;
      var fromTime = _ref4.fromTime;
      var datesOfMonth = _ref4.datesOfMonth;

      if (!startDate) return null;
      if (_ember['default'].isEmpty(datesOfMonth)) return startDate;
      var selected = datesOfMonth.get('firstObject');
      var addMonth = selected < startDate.date();
      var effectiveStartDate = startDate.clone().date(selected);
      if (addMonth) effectiveStartDate.add(1, 'month');
      return effectiveStartDate;
    },

    _getEffectiveStartDateMonthlyPeriod: function _getEffectiveStartDateMonthlyPeriod(_ref5) {
      var startDate = _ref5.startDate;
      var fromTime = _ref5.fromTime;
      var dayOfWeek = _ref5.dayOfWeek;
      var recurrenceMonthlyInterval = _ref5.recurrenceMonthlyInterval;

      if (_ember['default'].isEmpty(dayOfWeek) || _ember['default'].isEmpty(recurrenceMonthlyInterval) || _ember['default'].isEmpty(startDate) || _ember['default'].isEmpty(fromTime)) return null;

      if (_ember['default'].isArray(dayOfWeek) && dayOfWeek.objectAt(0) === undefined) return null;

      var _fromTime$split = fromTime.split(':');

      var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

      var hours = _fromTime$split2[0];
      var minutes = _fromTime$split2[1];

      // Make sure startDate has the proper hours and minutes selected
      startDate.hour(hours).minute(minutes).startOf('minute');

      var effectiveStartDate = startDate.clone();
      var chosenWeekIndex = dayOfWeek.get('firstObject');
      var chosenMultiplier = recurrenceMonthlyInterval.get('firstObject');

      // If multiplier is -1 then it means "last" weekday of the month so it requires us to calculate
      // it from the end of the month
      if (chosenMultiplier === -1) {
        effectiveStartDate = this._getLastWeekDayOfTheMonth(effectiveStartDate, chosenWeekIndex);
      } else {
        effectiveStartDate = this._getNDayOfTheMonth(effectiveStartDate, chosenMultiplier, chosenWeekIndex);
      }

      // If the calculated date happens to be before the start date then we know we need to add 1 month and recalculate
      if (effectiveStartDate.isBefore(startDate)) {
        effectiveStartDate.add(1, 'month');

        if (chosenMultiplier === -1) {
          effectiveStartDate = this._getLastWeekDayOfTheMonth(effectiveStartDate, chosenWeekIndex);
        } else {
          effectiveStartDate = this._getNDayOfTheMonth(effectiveStartDate, chosenMultiplier, chosenWeekIndex);
        }
      }

      return effectiveStartDate;
    },

    _getEndDateDaily: function _getEndDateDaily(_ref6) {
      var startDate = _ref6.startDate;
      var fromTime = _ref6.fromTime;
      var interval = _ref6.interval;
      var untilType = _ref6.untilType;
      var endDate = _ref6.endDate;
      var numberOfOccurrences = _ref6.numberOfOccurrences;
      var excludeWeekends = _ref6.excludeWeekends;
      var weekendDays = _ref6.weekendDays;

      if (parseInt(untilType) === UNTIL_DATE) return endDate;
      if (!startDate) return null;

      interval = parseInt(interval);
      numberOfOccurrences = parseInt(numberOfOccurrences);
      endDate = startDate.clone();

      var shouldSkipWeekends = excludeWeekends && !_ember['default'].isEmpty(weekendDays);

      var daysToAdd = interval * numberOfOccurrences - interval;

      // If should exclude weekends
      if (shouldSkipWeekends) {

        // If the original startDate is a weekendDay make sure we go forward until its a week day
        while (weekendDays.includes(endDate.day())) endDate.add(1, 'day');

        while (daysToAdd > 0) {
          endDate.add(1, 'day');
          if (weekendDays.includes(endDate.day())) continue;
          daysToAdd--;
        }

        return endDate;
      }

      // Else just add them all
      endDate.add(daysToAdd, 'day');
      return endDate;
    },

    _getEndDateWeekly: function _getEndDateWeekly(_ref7) {
      var startDate = _ref7.startDate;
      var interval = _ref7.interval;
      var daysOfWeek = _ref7.daysOfWeek;
      var untilType = _ref7.untilType;
      var endDate = _ref7.endDate;
      var numberOfOccurrences = _ref7.numberOfOccurrences;

      // Use endDate if the end Type is specified to a Date
      if (parseInt(untilType) === UNTIL_DATE) return endDate;
      if (_ember['default'].isEmpty(daysOfWeek)) return null;
      if (!startDate) return null;

      endDate = startDate.clone().subtract(1, 'day');
      interval = parseInt(interval);
      var occurrencesLeft = parseInt(numberOfOccurrences);

      while (occurrencesLeft > 0) {
        endDate.add(1, 'day');

        if (daysOfWeek.includes(endDate.day())) {
          occurrencesLeft--;
        }

        if (endDate.day() === 0 && interval > 1 && occurrencesLeft > 0) {
          endDate.add(interval - 1, 'week');
        }
      }
      return endDate;
    },

    _getEndDateWeeklyRestricted: function _getEndDateWeeklyRestricted(_ref8) {
      var untilDate = _ref8.untilDate;
      var interval = _ref8.interval;
      var daysOfWeek = _ref8.daysOfWeek;

      if (!untilDate) return null;
      if (_ember['default'].isEmpty(daysOfWeek)) return untilDate;

      var firstDayOfWeek = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
      var lastDayOfWeek = firstDayOfWeek - 1;
      lastDayOfWeek = lastDayOfWeek < 0 ? lastDayOfWeek + 7 : lastDayOfWeek;

      var endDate = untilDate.clone();

      while (!daysOfWeek.includes(endDate.day())) {
        endDate.subtract(1, 'day');
      }

      while (untilDate.clone().add(interval, 'week').isBefore(untilDate)) {
        untilDate.add(interval, 'week');
      }

      return endDate;
    },

    _getEndDateMonthlySpecificDay: function _getEndDateMonthlySpecificDay(_ref9) {
      var startDate = _ref9.startDate;
      var interval = _ref9.interval;
      var untilType = _ref9.untilType;
      var endDate = _ref9.endDate;
      var numberOfOccurrences = _ref9.numberOfOccurrences;

      // Use endDate if the end Type is specified to a Date
      if (parseInt(untilType) === UNTIL_DATE) {
        if (!endDate) return null;
        return endDate;
      }

      numberOfOccurrences = parseInt(numberOfOccurrences);
      interval = parseInt(interval);

      if (!startDate) return null;

      // Else get start date and calculate the number of occurrences
      endDate = startDate.clone().add(interval * numberOfOccurrences - interval, 'month');
      return endDate;
    },

    _getEndDateMonthlyPeriod: function _getEndDateMonthlyPeriod(_ref10) {
      var startDate = _ref10.startDate;
      var interval = _ref10.interval;
      var recurrenceMonthlyInterval = _ref10.recurrenceMonthlyInterval;
      var untilType = _ref10.untilType;
      var endDate = _ref10.endDate;
      var numberOfOccurrences = _ref10.numberOfOccurrences;

      if (!startDate || _ember['default'].isEmpty(recurrenceMonthlyInterval)) return null;

      if (parseInt(untilType) === UNTIL_DATE) return endDate;

      numberOfOccurrences = parseInt(numberOfOccurrences);
      interval = parseInt(interval);

      var period = recurrenceMonthlyInterval.get('firstObject');

      var shiftedIndex = interval * numberOfOccurrences - interval;
      var shiftedDate = startDate.clone().add(shiftedIndex, 'month');

      if (period === -1) {
        endDate = this._getLastWeekDayOfTheMonth(shiftedDate, startDate.day());
      } else {
        endDate = this._getNDayOfTheMonth(shiftedDate, period, startDate.day());
      }
      return endDate;
    },

    _getWeekDaysFromValues: function _getWeekDaysFromValues() {
      var values = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      var firstDayOfWeekOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');

      // We need to create objects to retain the original indexes
      var weekdaysObjects = this.get('i18n').t('general.weekdays').toString().split(',').map(function (day, i) {
        return _ember['default'].Object.create({ index: i, label: day });
      });
      var sorted = (0, _scientiaResourcebookerHelpersOffsetArray.offsetArray)(weekdaysObjects, firstDayOfWeekOffset);

      var labels = sorted.reduce(function (memo, day) {
        if (values.includes(day.index)) memo.push(day.label);
        return memo;
      }, []);

      return labels.join(', ');
    },

    // Get a period name from it's ID (First, Second, Third, Fourth, Last..)
    _getPeriodNameFromValues: function _getPeriodNameFromValues() {
      var values = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      if (_ember['default'].isEmpty(values)) return null;

      var periods = this.get('i18n').t('components.recurring_event_fields.monthly_periods').toString().split(',');

      var periodsMapping = {
        '-1': periods[4],
        '1': periods[0],
        '2': periods[1],
        '3': periods[2],
        '4': periods[3]
      };
      // We can only select one period so we just fetch the first object
      var periodValue = values.get('firstObject');
      if (!periodValue) return null;
      // The objects values indexes start at 1 while the arrays start at 0 so we need to make sure we fetch
      // the right string by subracting 1 from the value
      return periodsMapping[periodValue].toLowerCase();
    },

    _getLastWeekDayOfTheMonth: function _getLastWeekDayOfTheMonth(startDate, weekIndex) {

      // backup hours and minutes as endOf resets them
      var hours = startDate.hour();
      var minutes = startDate.minute();

      startDate.endOf('month');
      while (startDate.day() !== weekIndex) startDate.subtract(1, 'day');

      startDate.hour(hours).minute(minutes);
      return startDate;
    },

    _getNDayOfTheMonth: function _getNDayOfTheMonth(date, number, day) {
      if (!date) date = moment();
      if (!day && day !== 0) return null;
      var result = date.clone().date(1);

      while (number > 0) {
        while (day !== result.day()) {
          result.add(1, 'day');
        }

        number--;
        if (number > 0) result.add(1, 'day');
      }

      return result;
    },

    copyFragment: function copyFragment(fragment) {

      var newFragment = this.get('store').createFragment('recurrence-pattern');
      fragment.eachAttribute(function (attribute) {
        newFragment.set(attribute, fragment.get(attribute));
      });
      return newFragment;
    }

  });
});