define('scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkImporterPreview'],
    model: null,

    taskProgressResults: null,

    actions: {
      toggleHeader: function toggleHeader(className) {
        this.$('.bulkImporterPreview-messages-header.' + className + ' + div').toggle();
      }
    }

  });
});