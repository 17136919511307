define('scientia-resourcebooker/pods/manage-resources/user-group/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.user_group.new.page_title');
    },

    userGroupPersister: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          name: '',
          error: ''
        });
      }
    },

    actions: {
      createUserGroup: function createUserGroup(userGroup) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.user-group.new');

        controller.set('isSaving', true);

        this.get('userGroupPersister').persist(userGroup).then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('user-group');
          _this.transitionTo('manage-resources.user-group.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('user-group');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    }

  });
});