define('scientia-resourcebooker/pods/manage-resources/resource/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/availability-form-route'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAvailabilityFormRoute) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAvailabilityFormRoute['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    nestedResourceStore: _ember['default'].inject.service(),
    unavailabilityUpdater: _ember['default'].inject.service(),

    resourceUnavailabilityPatternsProcessor: _ember['default'].inject.service(),
    resourceBusyExceptionsFetcher: _ember['default'].inject.service(),
    resourceUnavailabilityPreviewFetcher: _ember['default'].inject.service(),

    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),

    queryParams: {
      selectedTab: { replace: false, refreshModel: false }
    },

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource.edit.page_title') + ' — ' + model.resource.get('name');
    },

    model: function model(params, transition) {
      var resourceTypeId = transition.params['manage-resources.resource'].resourceTypeId;

      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: resourceTypeId, adminPermissionsOnly: true }),
        resource: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        eventPatterns: [],
        busyExceptions: [],
        linkedAvailability: [],
        previewEvents: []
      });
    },

    afterModel: function afterModel(model) {

      if (!this._redirectIfInvalidModel(model)) {
        return;
      }

      // Calling the mixin methods in run.next gives the controller enough time to compute the
      // properties required (modelId, parentId)
      _ember['default'].run.next(this, function () {
        this._fetchBusyExceptions();
        this._fetchLinkedUnavailability();
      });

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id'),
        resourceTypeName: model.resourceType.get('name'),
        resourceTypeId: model.resourceType.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {

        this.resetAvailabilityProperties();

        controller.setProperties({
          selectedTab: 0,
          error: '',
          isSaving: false,
          isNew: false
        });
      }
    },

    _redirectIfInvalidModel: function _redirectIfInvalidModel(_ref) {
      var resourceType = _ref.resourceType;
      var resource = _ref.resource;

      if (resource.get('resourceTypeId') !== resourceType.get('id')) {
        this.transitionTo('manage-resources.resource-type.index');
        return false;
      }
      return true;
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        this.store.peekAll('property').filterBy('resource', model.resource).invoke('rollbackAttributes');
        if (model.resource.get('hasDirtyAttributes')) model.resource.rollbackAttributes();
        model.resource.unloadRecord();
      },

      updateResource: function updateResource(model, transitionIndex) {
        var _this = this;

        var promises = [this._updateUnavailability(), model.save()];

        var controller = this.controllerFor(this.routeName);

        controller.set('isSaving', true);
        controller.set('error', '');

        _ember['default'].RSVP.all(promises).then(function () {
          _this.editSuccessFlash('resource');

          if (transitionIndex) {
            return _this.transitionTo('manage-resources.resource-type.show', model.get('resourceTypeId'));
          } else {

            _this.send('reload');
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('resource');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      reload: function reload() {
        var model = this.modelFor(this.routeName);

        this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: model.resource.get('resourceTypeId'),
          id: model.resource.get('id'),
          data: { adminPermissionsOnly: true }
        });

        this._fetchBusyExceptions();
        this.send('reloadAvailability');
      }
    }
  });
});