define('scientia-resourcebooker/pods/components/booking-report-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    iconsAvailable: ['RBT-BL', 'RBT-BN', 'EXCHANGE', 'RB-IMPORT'],

    classNames: ['bookingReportListItem'],
    model: null,

    bookingTimeFormat: _ember['default'].computed('i18n.locale', 'model.spanMultipleDays', function () {
      var isMultipleDay = this.get('model.spansMultipleDays');
      var format = 'components.booking_report_list.' + (isMultipleDay ? 'date_format_span' : 'time_format');
      return this.get('i18n').t(format);
    }),

    bookingEndTimeSpanFormat: _ember['default'].computed('i18n.locale', 'model.spanMultipleDays', function () {
      var format = 'components.booking_report_list.date_format_span_twenty_four';
      return this.get('i18n').t(format);
    }),

    loadingBookingRequest: false,
    isLoadingBookingRequest: _ember['default'].computed('loadingBookingRequest', 'model.id', function () {
      return this.get('loadingBookingRequest') === this.get('model.id');
    }),

    showBookingSource: _ember['default'].computed('model.source', function () {
      return this.get('model.source') && this.get('model.source') !== 'RB';
    }),

    hasSourceIcon: _ember['default'].computed('model.source', 'showBookingSource', function () {
      return this.get('showBookingSource') && this.get('iconsAvailable').map(function (i) {
        return i.toLowerCase();
      }).includes(this.get('model.source').toLowerCase());
    }),

    hasCheckedIn: _ember['default'].computed('model.bookingExecutions', 'model.eventId', function () {
      var _this = this;

      var execution = (this.get('model.bookingExecutions') || []).find(function (b) {
        return b.occurrenceId === _this.get('model.id');
      });
      return execution ? !!execution.startInfo : false;
    }),

    hasCheckedOut: _ember['default'].computed('model.bookingExecutions', 'model.eventId', function () {
      var _this2 = this;

      var execution = (this.get('model.bookingExecutions') || []).find(function (b) {
        return b.occurrenceId === _this2.get('model.id');
      });
      return execution ? !!execution.endInfo : false;
    })

  });
});