define('scientia-resourcebooker/pods/manage-resources/booking-type/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/app-insights', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAppInsights, _emberCan) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _emberCan.CanMixin, _scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_type.new.page_title');
    },

    beforeModel: function beforeModel() {
      if (!this.can('create bookingType')) {
        this.transitionTo('manage-resources.booking-type.index');
      }
    },

    bookingTypePersister: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          name: '',
          description: '',
          imageUri: '',
          properties: [],
          error: ''
        });
      }
    },

    actions: {
      createBookingType: function createBookingType(bookingType) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.booking-type.new');

        controller.set('isSaving', true);

        if (_ember['default'].isEmpty(bookingType.name.trim())) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.name_required').toString(), 0);
        }

        this.get('bookingTypePersister').persist(bookingType).then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('booking-type');
          return _this.transitionTo('manage-resources.booking-type.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('booking-type');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    },

    _displayError: function _displayError(error, tabIndex) {
      var controller = this.controllerFor('manage-resources.booking-type.new');
      controller.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      return false;
    }
  });
});