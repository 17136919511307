define("scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "notificationsTemplates-toggler at-customTemplatesToggler");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "notificationsTemplates-toggler-label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.notification_templates.override_system_templates"], [], ["loc", [null, [3, 57], [3, 141]]], 0, 0], ["inline", "toggle-switch", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [4, 31], [4, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "readonly", [["get", "useCustomTemplates", ["loc", [null, [4, 58], [4, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 48], [4, 77]]], 0, 0], "onToggle", ["subexpr", "action", [["get", "toggleUseCustomTemplates", ["loc", [null, [4, 95], [4, 119]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 87], [4, 120]]], 0, 0]], ["loc", [null, [4, 6], [4, 122]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 16,
                    "column": 10
                  },
                  "end": {
                    "line": 18,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "round-ball", [], ["location", ["subexpr", "concat-strings", ["roundBall-at-notificationTemplateList roundBall--mode-green"], [], ["loc", [null, [17, 34], [17, 112]]], 0, 0]], ["loc", [null, [17, 12], [17, 114]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 10
                  },
                  "end": {
                    "line": 20,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "round-ball", [], ["location", ["subexpr", "concat-strings", ["roundBall-at-notificationTemplateList roundBall--mode-transparent"], [], ["loc", [null, [19, 34], [19, 118]]], 0, 0]], ["loc", [null, [19, 12], [19, 120]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 6
                },
                "end": {
                  "line": 25,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "roundBall-wrapper-flex-center");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "notificationsTemplates-list-item-title");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element2, 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[4] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["notificationsTemplates-list-item ", ["subexpr", "if", [["subexpr", "eq", [["get", "clickedItemId", ["loc", [null, [14, 61], [14, 74]]], 0, 0, 0, 0], ["get", "item.id", ["loc", [null, [14, 75], [14, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 57], [14, 83]]], 0, 0], "selected"], [], ["loc", [null, [14, 52], [14, 96]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "onSelect", ["loc", [null, [14, 107], [14, 115]]], 0, 0, 0, 0], ["get", "item", ["loc", [null, [14, 116], [14, 120]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 98], [14, 122]]], 0, 0], ["block", "if", [["get", "item.sendEmail", ["loc", [null, [16, 16], [16, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [16, 10], [20, 17]]]], ["inline", "t", [["subexpr", "concat-strings", ["components.manage_resources.notification_templates.names.", ["subexpr", "to-lower", [["get", "item.notificationType", ["loc", [null, [21, 153], [21, 174]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 143], [21, 175]]], 0, 0]], [], ["loc", [null, [21, 67], [21, 176]]], 0, 0]], [], ["loc", [null, [21, 63], [21, 178]]], 0, 0], ["inline", "inline-svg", ["icons/pencil.svg"], ["class", "notificationsTemplates-list-item-icon-edit"], ["loc", [null, [24, 8], [24, 92]]], 0, 0]],
            locals: ["item"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 4
              },
              "end": {
                "line": 26,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "notificationsTemplates-list-item--header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "type", ["loc", [null, [11, 59], [11, 67]]], 0, 0, 0, 0], ["block", "each", [["get", "items", ["loc", [null, [12, 14], [12, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 6], [25, 15]]]]],
          locals: ["type", "items"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each-in", [["subexpr", "group-by", ["recipientType", ["get", "model", ["loc", [null, [10, 41], [10, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 15], [10, 47]]], 0, 0]], [], 0, null, ["loc", [null, [10, 4], [26, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "notificationsTemplates-list-actions");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "button button--white notificationsTemplates-list-copy-system");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", [["get", "copyFromSystem", ["loc", [null, [33, 21], [33, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 12], [33, 37]]], 0, 0], ["inline", "t", ["components.manage_resources.notification_templates.copy_all_from_system"], [], ["loc", [null, [33, 107], [33, 186]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("ul");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isSystem", ["loc", [null, [1, 12], [1, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 2], [6, 13]]]], ["block", "if", [["get", "model", ["loc", [null, [9, 8], [9, 13]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [9, 2], [27, 7]]]], ["block", "unless", [["subexpr", "or", [["get", "isSystem", ["loc", [null, [31, 14], [31, 22]]], 0, 0, 0, 0], ["get", "disabled", ["loc", [null, [31, 23], [31, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 10], [31, 32]]], 0, 0]], [], 2, null, ["loc", [null, [31, 0], [35, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});