define('scientia-resourcebooker/validators/booking-form-field-external-data-source-uri', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var URL_REGEX = /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?.*/;

  var BookingFormFieldExternalDataSourceUri = _emberCpValidationsValidatorsBase['default'].extend({
    i18n: _ember['default'].inject.service(),

    validate: function validate(value, options /*, model, attribute*/) {
      if (options.get('disabled')) return true;
      if (!value || !value.match(URL_REGEX)) {
        return this.get('i18n').t('errors.url').toString();
      } else {
        return true;
      }
    }
  });

  BookingFormFieldExternalDataSourceUri.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
     *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = BookingFormFieldExternalDataSourceUri;
});