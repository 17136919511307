define('scientia-resourcebooker/models/recurrence-pattern', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    recurrenceTypeSelected: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    startDate: (0, _emberDataAttr['default'])('date'),
    fromTime: (0, _emberDataAttr['default'])('string', { defaultValue: '00:00' }),
    toTime: (0, _emberDataAttr['default'])('string', { defaultValue: '01:00' }),
    interval: (0, _emberDataAttr['default'])('number', { defaultValue: 1 }),
    excludeWeekends: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    untilType: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    untilDate: (0, _emberDataAttr['default'])('date'),
    numberOfOccurrences: (0, _emberDataAttr['default'])('number', { defaultValue: 2 }),
    recurringDaysOfWeek: (0, _emberDataAttr['default'])('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    monthlyType: (0, _emberDataAttr['default'])('number', { defaultValue: 1 }),
    datesOfMonth: (0, _emberDataAttr['default'])('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    recurrenceMonthlyInterval: (0, _emberDataAttr['default'])('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    dayOfWeek: (0, _emberDataAttr['default'])('raw', { defaultValue: function defaultValue() {
        return [];
      } }),
    recurrenceDescription: (0, _emberDataAttr['default'])('string', { defaultValue: '' })

  });
});