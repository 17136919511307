define('scientia-resourcebooker/services/user-group-persister', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    persist: function persist(_ref) {
      var name = _ref.name;
      var _ref$claims = _ref.claims;
      var claims = _ref$claims === undefined ? [] : _ref$claims;
      var _ref$editClaimsPermissionGroups = _ref.editClaimsPermissionGroups;
      var editClaimsPermissionGroups = _ref$editClaimsPermissionGroups === undefined ? [] : _ref$editClaimsPermissionGroups;

      var userGroup = this.get('store').createRecord('user-group', { name: name, claims: claims, editClaimsPermissionGroups: editClaimsPermissionGroups });

      var promise = userGroup.save();

      promise['catch'](function () {
        userGroup.rollbackAttributes();
      });

      return promise;
    },

    getDeleteTaskKey: function getDeleteTaskKey(id) {
      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.USER_GROUPS_WITH_PROGRESS.DELETE.loc(id));
    },
    getUpdateTaskKey: function getUpdateTaskKey(model) {
      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.USER_GROUPS_WITH_PROGRESS.UPDATE.loc(model.get('id')), model);
    }
  });
});