define('scientia-resourcebooker/services/concurrency-groups-persister', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    persist: function persist(data) {
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.CONCURRENCY_GROUPS.FETCH;
      return this.get('authenticatedRequest').post(url, data);
    }
  });
});