define('scientia-resourcebooker/pods/components/system/notifications-templates/editor/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['notificationsTemplates-editor'],
    selectedTemplate: null,

    optionTags: _ember['default'].computed('selectedTemplate', function () {
      return this.get('selectedTemplate.options') || [];
    }),

    conditionalTags: _ember['default'].computed('selectedTemplate', function () {
      return this.get('selectedTemplate.conditionals') || [];
    }),

    editorOptions: _ember['default'].computed('selectedTemplate', function () {

      if (this.get('selectedTemplate')) {
        return {
          height: 200,
          'menubar': true,
          'toolbar1': 'insertfile undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview',
          'toolbar2': this.get('optionTags').join(' ') + ' ' + this.get('conditionalTags').join(' '),
          'convert_urls': true,
          'relative_urls': false,
          'remove_script_host': false
        };
      }
    }),

    actions: {
      sendSample: function sendSample() {
        this.sendAction('sendSample');
      },

      save: function save() {
        this.sendAction('save');
      }
    }
  });
});