define('scientia-resourcebooker/pods/components/manage-resources/booking-type-calendar-options/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),
    dateTimeHelpers: _ember['default'].inject.service(),

    classNames: ['bookingTypeCalendarOptions'],

    // Calendar options
    startTime: '00:00',
    endTime: '24:00',
    showWeekends: true,
    calendarBookingTimes: 0,
    useCustomCalendarOptions: false,
    bookingSpanMultipleDays: false,
    disableToggleBookingSpanMultipleDays: false,

    contentStartTimes: null,
    contentEndTimes: null,

    // Is this component disabled (no permission canEditCalendar)
    disabed: false,

    // Is being displayed on System tab
    isSystem: false,

    // Are system settings being saved
    isSaving: false,

    init: function init() {
      this._super.apply(this, arguments);

      this._adjustStartAndEndTimes();
      this.send('updateContentStartTimes');
      this.send('updateContentEndTimes');
    },

    startHour: _ember['default'].computed('startTime', function () {
      var startTime = this.get('startTime');
      if (!startTime) return 0;

      var _startTime$split = startTime.split(':');

      var _startTime$split2 = _slicedToArray(_startTime$split, 1);

      var h = _startTime$split2[0];

      return parseInt(h, 10);
    }),

    endHour: _ember['default'].computed('endTime', function () {
      var endTime = this.get('endTime');
      if (!endTime) return 0;

      var _endTime$split = endTime.split(':');

      var _endTime$split2 = _slicedToArray(_endTime$split, 2);

      var h = _endTime$split2[0];
      var m = _endTime$split2[1];

      var hour = parseInt(h, 10);
      var minutes = parseInt(m, 10);
      if (hour !== 24 && minutes !== 0) {
        return hour + 1;
      } else {
        return hour;
      }
    }),

    /**
     * Gets the minutes labels based of the current selected value on the dropdown Booking Times
     */
    calendarBookingTimesLabel: _ember['default'].computed('calendarBookingTimes', function () {
      var selected = this.get('calendarBookingTimes');
      if (!selected && selected !== 0) return null;
      var values = this.get('i18n').t('components.manage_resources.booking_type_calendar_options.booking_times_values').toString().split('|');
      return values[selected];
    }),

    /**
     * Generates list of booking times options for the dropdown
     */
    bookingTimesOptions: _ember['default'].computed('i18n.locale', function () {
      var values = this.get('i18n').t('components.manage_resources.booking_type_calendar_options.booking_times_values').toString().split('|');
      var labels = this.get('i18n').t('components.manage_resources.booking_type_calendar_options.booking_times_values_labels').toString().split('|');

      var options = [];
      for (var i = 0; i < values.length; i++) {
        options.push({ id: i, text: labels[i] });
      }
      return options;
    }),

    _forceTimesRefresh: function _forceTimesRefresh() {
      // const startTime = this.get('startTime');
      // const endTime = this.get('endTime');

      // this.set('startTime', '');
      // this.set('endTime', '');

      // Ember.run.later(this, function() {
      //   this.set('startTime', startTime);
      //   this.set('endTime', endTime);
      // }, 100);
    },

    /**
     * Adjusts start and end times to make sure they adhere to the current Booking Times dropdown option selected
     */
    _adjustStartAndEndTimes: function _adjustStartAndEndTimes() {

      var calendarOptionsHelper = this.get('calendarOptionsHelper');
      var startTime = this.get('startTime');

      var _startTime$split3 = startTime.split(':');

      var _startTime$split32 = _slicedToArray(_startTime$split3, 2);

      var startH = _startTime$split32[0];
      var startM = _startTime$split32[1];

      var endTime = this.get('endTime') || this.get('contentEndTimes.lastObject.value');

      var _endTime$split3 = endTime.split(':');

      var _endTime$split32 = _slicedToArray(_endTime$split3, 2);

      var endH = _endTime$split32[0];
      var endM = _endTime$split32[1];

      var calendarOptions = {
        calendarStartTime: '00:00',
        calendarEndTime: '24:00',
        calendarBookingTimes: this.get('calendarBookingTimes')
      };

      var startMoment = moment().hour(startH).minute(startM);
      var endMoment = startMoment.clone().hour(endH).minute(endM);

      if (calendarOptionsHelper.isBookingTimesValidForCalendarOptions(startMoment, endMoment, calendarOptions)) {
        return;
      }

      var startSlots = calendarOptionsHelper.getTimeSlotsForStartTime(startMoment, calendarOptions);
      var endSlots = calendarOptionsHelper.getTimeSlotsForEndTime(startMoment, endMoment, calendarOptions);

      var adjustedStart = calendarOptionsHelper.getNearestTimeInTimeSlots(startTime, startSlots);
      var adjustedEnd = calendarOptionsHelper.getNearestTimeInTimeSlots(endTime, endSlots);

      this.set('startTime', adjustedStart);
      this.set('endTime', adjustedEnd === '00:00' ? '24:00' : adjustedEnd);
      this._forceTimesRefresh();
    },

    actions: {
      onChangeBookingTimes: function onChangeBookingTimes(value) {
        this.set('calendarBookingTimes', value);
        this._adjustStartAndEndTimes();

        this.send('updateContentStartTimes');
        this.send('updateContentEndTimes');
      },

      onChangeStartTime: function onChangeStartTime(startTime) {
        this.set('startTime', startTime);
        var endTime = this.get('endTime') || this.get('startTime'); // If no endtime, use startTime
        if (endTime && this.get('dateTimeHelpers').isTimeBefore(endTime, startTime, true)) {
          this.set('endTime', this.get('contentEndTimes.lastObject.value'));
        }

        this.send('updateContentEndTimes');
        this._forceTimesRefresh();
      },

      onChangeEndTime: function onChangeEndTime(endTime) {
        this.set('endTime', endTime);
        this._forceTimesRefresh();
      },

      updateContentStartTimes: function updateContentStartTimes() {

        var calendarOptions = {
          calendarBookingTimes: this.get('calendarBookingTimes'),
          useCustomCalendarOptions: true,
          calendarStartTime: '00:00',
          calendarEndTime: '24:00'
        };

        var result = this.get('calendarOptionsHelper').getTimeSlotsForWholeCalendarRange(moment(), calendarOptions, false);
        this.set('contentStartTimes', this._mapValues(result));
      },

      updateContentEndTimes: function updateContentEndTimes() {
        var startTime = this.get('startTime');

        var _startTime$split4 = startTime.split(':');

        var _startTime$split42 = _slicedToArray(_startTime$split4, 2);

        var h = _startTime$split42[0];
        var m = _startTime$split42[1];

        var fullFromDate = moment().hour(h).minute(m);

        var endTime = this.get('endTime') || '24:00';

        var _endTime$split4 = endTime.split(':');

        var _endTime$split42 = _slicedToArray(_endTime$split4, 2);

        var endHour = _endTime$split42[0];
        var endMinutes = _endTime$split42[1];

        var toDate = moment().hour(endHour).minute(endMinutes);

        var calendarOptions = {
          calendarBookingTimes: this.get('calendarBookingTimes'),
          useCustomCalendarOptions: true,
          calendarStartTime: this.get('startTime'),
          calendarEndTime: '24:00'
        };

        // const result = this.get('calendarOptionsHelper').getAvailableEndTimes(fullFromDate, toDate, calendarOptions);
        var result = this.get('calendarOptionsHelper').getTimeSlotsForEndTime(fullFromDate, toDate, calendarOptions);
        this.set('contentEndTimes', this._mapValues(result));
      },

      copyFromSystem: function copyFromSystem() {
        var system = this.get('systemSettings').getCalendarOptions();
        this.setProperties({
          startTime: system.calendarStartTime,
          endTime: system.calendarEndTime,
          showWeekends: system.calendarShowWeekends,
          calendarBookingTimes: system.calendarBookingTimes,
          bookingSpanMultipleDays: system.bookingSpanMultipleDays
        });
      },

      saveOptions: function saveOptions() {
        var data = {
          calendarStartTime: this.get('startTime'),
          calendarEndTime: this.get('endTime'),
          calendarShowWeekends: this.get('showWeekends'),
          calendarBookingTimes: this.get('calendarBookingTimes'),
          bookingSpanMultipleDays: this.get('bookingSpanMultipleDays')
        };

        if (!this.get('isSystem')) {
          data.useCustomCalendarOptions = this.get('useCustomCalendarOptions');
        }

        this.get('save')(data);
      }
    },

    _mapValues: function _mapValues(values) {

      var lastSlot = values[values.length - 1];
      if (lastSlot === '00:00' && values.length > 1) values[values.length - 1] = '24:00';

      return values.map(function (v) {
        return { value: v, label: v };
      });
    }

  });
});