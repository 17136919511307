define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    queryParams: ['query'],
    query: '',
    boundQuery: _ember['default'].computed.oneWay('query'),
    itemScheduledForDeletion: null,

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.set('query', this.get('boundQuery'));
    },

    actions: {

      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 500, false);
      },

      confirmDeletion: function confirmDeletion(itemScheduledForDeletion) {
        var _this = this;

        itemScheduledForDeletion.destroyRecord()['finally'](function () {
          _this.set('itemScheduledForDeletion', null);
        })['catch'](function () {});
      }
    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: true, path: 'manage-resources.resource-type.index' }, { label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title'), linkable: false }];
    }).property('i18n.locale')
  });
});