define('scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    choices: [],

    emptyChoices: _ember['default'].computed.filterBy('choices', 'content', ''),
    hasEmptyChoices: _ember['default'].computed.gte('emptyChoices.length', 1),

    actions: {
      add: function add() {
        this.sendAction('add', '');
      },

      remove: function remove(object) {
        this.sendAction('remove', object);
      }
    }
  });
});