define('scientia-resourcebooker/services/notification-templates-persister', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var RECIPIENT_TYPES = ['Booker', 'Approver', 'Resource'];
  var NOTIFICATION_TYPES = ['BookingAccepted', 'BookingRequested', 'BookingInfoRequest', 'BookingRejected', 'BookingCancelled'];

  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetchTemplate: function fetchTemplate(_ref) {
      var parentId = _ref.parentId;
      var templateId = _ref.templateId;
      var isSystem = _ref.isSystem;

      return this.get('store').queryRecord('notification-template', { parentId: parentId, id: templateId, isSystem: isSystem });
    },

    updateTemplate: function updateTemplate(template) {

      this.get('appInsights').trackBookingTypeNotificationTemplateUpdate(template);
      return template.save();
    },

    getSummary: function getSummary(_ref2) {
      var parentId = _ref2.parentId;
      var isSystem = _ref2.isSystem;

      var path = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATION_TEMPLATES;
      var url = isSystem ? path.SYSTEM.INFO : path.BOOKING_TYPE.INFO.loc(parentId);
      return this.get('authenticatedRequest').fetch(url).then(function (response) {
        response.sort(function (a, b) {
          var notificationIndexA = NOTIFICATION_TYPES.indexOf(a.notificationType);
          var notificationIndexB = NOTIFICATION_TYPES.indexOf(b.notificationType);
          var recipientIndexA = RECIPIENT_TYPES.indexOf(a.recipientType);
          var recipientIndexB = RECIPIENT_TYPES.indexOf(b.recipientType);

          return recipientIndexA + notificationIndexA < recipientIndexB + notificationIndexB ? -1 : 1;
        });

        return response;
      });
    },

    copySystem: function copySystem(_ref3) {
      var isSingle = _ref3.isSingle;
      var parentId = _ref3.parentId;
      var model = _ref3.model;

      var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATION_TEMPLATES.BOOKING_TYPE;
      var url = isSingle ? endpoint.COPY_SINGLE_FROM_SYSTEM : endpoint.COPY_ALL_FROM_SYSTEM;
      var data = { destinationBookingTypeIdentities: [parentId] };

      if (isSingle) {
        data.notificationType = model.notificationType;
        data.recipientType = model.recipientType;
      }

      var logData = { bookingTypeId: parentId, allTemplates: !isSingle, notificationType: isSingle ? data.notificationType : null, recipientType: isSingle ? data.recipientType : null };
      this.get('appInsights').trackEvent('booking-type-notification-templates-copy-from-system', logData);

      return this.get('authenticatedRequest').post(url, data);
    },

    toggleUseCustomTemplates: function toggleUseCustomTemplates(parentId, value) {
      var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATION_TEMPLATES.BOOKING_TYPE.TOGGLE_USE_CUSTOM.loc(parentId);
      this.get('appInsights').trackEvent('booking-type-notification-templates-toogle', { bookingTypeId: parentId, value: value });
      return this.get('authenticatedRequest').put(endpoint, value);
    }

  });
});