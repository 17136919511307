define("scientia-resourcebooker/pods/logged-in/notifications/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-accept-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showAcceptModal", ["loc", [null, [9, 25], [9, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 41]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 48]]], 0, 0], "isAccepting", ["subexpr", "@mut", [["get", "isAccepting", ["loc", [null, [10, 18], [10, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["confirmAcceptRequest"], [], ["loc", [null, [11, 14], [11, 45]]], 0, 0]], ["loc", [null, [7, 9], [11, 46]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [12, 37], [12, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 27], [12, 42]]], 0, 0]], [], ["loc", [null, [12, 19], [12, 43]]], 0, 0]], ["loc", [null, [6, 7], [12, 44]]], 0, 0]], ["loc", [null, [4, 2], [12, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-reject-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showRejectModal", ["loc", [null, [21, 25], [21, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 20], [21, 41]]], 0, 0], false], [], ["loc", [null, [21, 12], [21, 48]]], 0, 0], "isRejecting", ["subexpr", "@mut", [["get", "isRejecting", ["loc", [null, [22, 18], [22, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["showRejectModal"], [], ["loc", [null, [23, 14], [23, 40]]], 0, 0]], ["loc", [null, [19, 9], [23, 41]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 37], [24, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 27], [24, 42]]], 0, 0]], [], ["loc", [null, [24, 19], [24, 43]]], 0, 0]], ["loc", [null, [18, 7], [24, 44]]], 0, 0]], ["loc", [null, [16, 2], [24, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-more-info-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showMoreInfoModal", ["loc", [null, [33, 25], [33, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 20], [33, 43]]], 0, 0], false], [], ["loc", [null, [33, 12], [33, 50]]], 0, 0], "isConfirmingMoreInfo", ["subexpr", "@mut", [["get", "isConfirmingMoreInfo", ["loc", [null, [34, 27], [34, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["showMoreInfoModal"], [], ["loc", [null, [35, 14], [35, 42]]], 0, 0]], ["loc", [null, [31, 9], [35, 43]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [36, 37], [36, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 27], [36, 42]]], 0, 0]], [], ["loc", [null, [36, 19], [36, 43]]], 0, 0]], ["loc", [null, [30, 7], [36, 44]]], 0, 0]], ["loc", [null, [28, 2], [36, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-notes-modal"], ["model", ["subexpr", "@mut", [["get", "showNotesModal", ["loc", [null, [45, 12], [45, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showNotesModal", ["loc", [null, [46, 25], [46, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 20], [46, 40]]], 0, 0], false], [], ["loc", [null, [46, 12], [46, 47]]], 0, 0]], ["loc", [null, [43, 9], [46, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showNotesModal", ["loc", [null, [47, 32], [47, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 27], [47, 47]]], 0, 0], false], [], ["loc", [null, [47, 19], [47, 54]]], 0, 0]], ["loc", [null, [42, 7], [47, 55]]], 0, 0]], ["loc", [null, [40, 2], [47, 57]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 0
            },
            "end": {
              "line": 60,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [56, 12], [56, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", false, "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [58, 25], [58, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [58, 20], [58, 44]]], 0, 0], false], [], ["loc", [null, [58, 12], [58, 51]]], 0, 0]], ["loc", [null, [54, 9], [58, 52]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [59, 32], [59, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 27], [59, 51]]], 0, 0], false], [], ["loc", [null, [59, 19], [59, 58]]], 0, 0]], ["loc", [null, [53, 7], [59, 59]]], 0, 0]], ["loc", [null, [51, 2], [59, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["date-period-selector"], ["isModal", true, "model", ["subexpr", "@mut", [["get", "timeframeTypes", ["loc", [null, [69, 12], [69, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "readonly", [["get", "_modalTimeframeSelected", ["loc", [null, [70, 25], [70, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 15], [70, 49]]], 0, 0], "location", "at-bookingReportsDatePeriodSelector at-bookingReportsDatePeriodSelector--modal", "startDate", ["subexpr", "readonly", [["get", "_modalStartDateTime", ["loc", [null, [72, 26], [72, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [72, 16], [72, 46]]], 0, 0], "endDate", ["subexpr", "readonly", [["get", "_modalEndDateTime", ["loc", [null, [73, 24], [73, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 14], [73, 42]]], 0, 0], "onRangeChange", ["subexpr", "action", ["onTimeframeModalDatesChange"], [], ["loc", [null, [74, 20], [74, 58]]], 0, 0], "onChange", ["subexpr", "action", ["onTimeframeModalTimeframeChange"], [], ["loc", [null, [75, 15], [75, 57]]], 0, 0], "onModalCancel", ["subexpr", "action", ["onTimeframeModalCancel"], [], ["loc", [null, [76, 20], [76, 53]]], 0, 0], "onModalConfirm", ["subexpr", "action", ["onTimeframeModalConfirm"], [], ["loc", [null, [77, 21], [77, 55]]], 0, 0]], ["loc", [null, [66, 9], [77, 56]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [78, 37], [78, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 27], [78, 42]]], 0, 0]], [], ["loc", [null, [78, 19], [78, 43]]], 0, 0]], ["loc", [null, [65, 7], [78, 44]]], 0, 0]], ["loc", [null, [63, 1], [79, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 6
              },
              "end": {
                "line": 102,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "role", "region");
            dom.setAttribute(el1, "class", "notificationSearcher-wrapper");
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'aria-label');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["pods.logged_in.notifications.region_label"], [], ["loc", [null, [88, 76], [88, 125]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "notification-searcher", [], ["content", ["subexpr", "@mut", [["get", "searchOptions", ["loc", [null, [91, 18], [91, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionSelected", ["subexpr", "@mut", [["get", "lastSearchOptionSelected", ["loc", [null, [92, 25], [92, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "query", ["subexpr", "@mut", [["get", "searchQuery", ["loc", [null, [93, 16], [93, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "search", "searchNotifications", "searchOptionChanged", ["subexpr", "action", ["searchOptionChanged"], [], ["loc", [null, [95, 30], [95, 60]]], 0, 0]], ["loc", [null, [90, 10], [96, 12]]], 0, 0], ["content", "help-trigger", ["loc", [null, [98, 10], [98, 26]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 4
            },
            "end": {
              "line": 103,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "media.isTiny", ["loc", [null, [86, 16], [86, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [86, 6], [102, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 5
            },
            "end": {
              "line": 115,
              "column": 5
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dc-tabs dc-tabs--fullWidth");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dc-tabs-list");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "notificationTab notificationTab--search notificationTab dc-tabs-list-item");
          dom.setAttribute(el3, "selected", "selected");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "searchResults-notificationTab-close");
          var el5 = dom.createTextNode("X");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1, 1]);
          var element1 = dom.childAt(element0, [4]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          morphs[2] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/search.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [109, 16], [109, 75]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.search"], [], ["loc", [null, [109, 75], [109, 124]]], 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [110, 66], [110, 90]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child8 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 6
              },
              "end": {
                "line": 123,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.notifications.loading_notifications"], [], ["loc", [null, [122, 11], [122, 69]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 8
                },
                "end": {
                  "line": 130,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["pods.logged_in.notifications.notifications_count"], ["count", ["subexpr", "@mut", [["get", "model.results.length", ["loc", [null, [127, 72], [127, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "notificationType", ["subexpr", "@mut", [["get", "currentTabName", ["loc", [null, [127, 110], [127, 124]]], 0, 0, 0, 0]], [], [], 0, 0], "page", ["subexpr", "@mut", [["get", "model.pagination.currentPage", ["loc", [null, [127, 130], [127, 158]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [127, 11], [127, 160]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 133,
                    "column": 12
                  },
                  "end": {
                    "line": 137,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message_approve"], [], ["loc", [null, [134, 14], [134, 72]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 137,
                    "column": 12
                  },
                  "end": {
                    "line": 139,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message"], [], ["loc", [null, [138, 14], [138, 64]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 130,
                  "column": 10
                },
                "end": {
                  "line": 140,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [133, 22], [133, 33]]], 0, 0, 0, 0], 1], [], ["loc", [null, [133, 18], [133, 36]]], 0, 0]], [], 0, 1, ["loc", [null, [133, 12], [139, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 6
              },
              "end": {
                "line": 141,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "hasNotifications", ["loc", [null, [126, 19], [126, 35]]], 0, 0, 0, 0], ["get", "model.results.length", ["loc", [null, [126, 36], [126, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [126, 14], [126, 57]]], 0, 0]], [], 0, 1, ["loc", [null, [126, 8], [140, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 146,
                    "column": 10
                  },
                  "end": {
                    "line": 148,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/bell.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [147, 12], [147, 69]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.all"], [], ["loc", [null, [147, 69], [147, 115]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 150,
                      "column": 135
                    },
                    "end": {
                      "line": 150,
                      "column": 241
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "badge badge--notification at-notificationTab");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                  return morphs;
                },
                statements: [["content", "toApproveCount", ["loc", [null, [150, 216], [150, 234]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 149,
                    "column": 12
                  },
                  "end": {
                    "line": 151,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/requested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [150, 14], [150, 84]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.requests"], [], ["loc", [null, [150, 84], [150, 135]]], 0, 0], ["block", "if", [["get", "toApproveCount", ["loc", [null, [150, 141], [150, 155]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [150, 135], [150, 248]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 153,
                      "column": 142
                    },
                    "end": {
                      "line": 153,
                      "column": 258
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "badge badge--notification at-notificationTab");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                  return morphs;
                },
                statements: [["content", "moreInfoActionCount", ["loc", [null, [153, 228], [153, 251]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 152,
                    "column": 12
                  },
                  "end": {
                    "line": 154,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/moreinforequested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [153, 12], [153, 90]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.more_info"], [], ["loc", [null, [153, 90], [153, 142]]], 0, 0], ["block", "if", [["get", "moreInfoActionCount", ["loc", [null, [153, 148], [153, 167]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [153, 142], [153, 265]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 155,
                    "column": 10
                  },
                  "end": {
                    "line": 157,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/requested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [156, 12], [156, 82]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.requested"], [], ["loc", [null, [156, 82], [156, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 10
                  },
                  "end": {
                    "line": 160,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/accepted.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [159, 12], [159, 81]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.accepted"], [], ["loc", [null, [159, 81], [159, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 161,
                    "column": 10
                  },
                  "end": {
                    "line": 163,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/rejected.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [162, 12], [162, 81]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.rejected"], [], ["loc", [null, [162, 81], [162, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 164,
                    "column": 10
                  },
                  "end": {
                    "line": 166,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/cancelled.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [165, 12], [165, 82]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.cancelled"], [], ["loc", [null, [165, 82], [165, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 145,
                  "column": 8
                },
                "end": {
                  "line": 167,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], ["class", "notificationTab notificationTab--all"], 0, null, ["loc", [null, [146, 10], [148, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--requests"], 1, null, ["loc", [null, [149, 12], [151, 23]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--more-info"], 2, null, ["loc", [null, [152, 12], [154, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--requested"], 3, null, ["loc", [null, [155, 10], [157, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--accepted"], 4, null, ["loc", [null, [158, 10], [160, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--rejected"], 5, null, ["loc", [null, [161, 10], [163, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--cancelled"], 6, null, ["loc", [null, [164, 10], [166, 21]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 5
              },
              "end": {
                "line": 168,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [145, 8], [167, 24]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 5
            },
            "end": {
              "line": 170,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sr-only");
          dom.setAttribute(el1, "aria-atomic", "true");
          dom.setAttribute(el1, "aria-live", "polite");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "isLoadingNotifications", ["loc", [null, [121, 12], [121, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [121, 6], [141, 15]]]], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth at-notifications", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [144, 75], [144, 86]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [144, 5], [168, 16]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child9 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 174,
              "column": 8
            },
            "end": {
              "line": 176,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["size", "big", "align", "center"], ["loc", [null, [175, 12], [175, 54]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child10 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 198,
                  "column": 12
                },
                "end": {
                  "line": 204,
                  "column": 12
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "resources-meta", [], ["changePage", "changePage", "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [201, 29], [201, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "currentPage", ["subexpr", "@mut", [["get", "model.pagination.currentPage", ["loc", [null, [202, 28], [202, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.pagination.totalPages", ["loc", [null, [203, 27], [203, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [199, 14], [203, 56]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 184,
                "column": 10
              },
              "end": {
                "line": 206,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "notifications-list", [], ["askMoreInfos", "askMoreInfos", "sendMoreInfos", "sendMoreInfos", "acceptRequest", "acceptRequest", "rejectRequest", "rejectRequest", "moreInfoRequest", "moreInfoRequest", "viewNotes", "viewNotes", "openEventOverview", "openEventOverview", "removeSearchTerm", "removeSearchTerm", "loadingBookingRequest", ["subexpr", "@mut", [["get", "loadingBookingRequest", ["loc", [null, [194, 36], [194, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "searchOptionsSelected", ["subexpr", "@mut", [["get", "searchOptionsSelected", ["loc", [null, [195, 36], [195, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "notifications", ["subexpr", "@mut", [["get", "model.results", ["loc", [null, [196, 28], [196, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [185, 12], [196, 43]]], 0, 0], ["block", "if", [["get", "shouldShowPaging", ["loc", [null, [198, 18], [198, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [198, 12], [204, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 210,
                  "column": 18
                },
                "end": {
                  "line": 212,
                  "column": 18
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["pods.logged_in.notifications.no_results"], [], ["loc", [null, [211, 20], [211, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 213,
                    "column": 20
                  },
                  "end": {
                    "line": 215,
                    "column": 20
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message_approve"], [], ["loc", [null, [214, 22], [214, 80]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 215,
                    "column": 20
                  },
                  "end": {
                    "line": 217,
                    "column": 20
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message"], [], ["loc", [null, [216, 22], [216, 72]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 212,
                  "column": 18
                },
                "end": {
                  "line": 218,
                  "column": 18
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [213, 30], [213, 41]]], 0, 0, 0, 0], 1], [], ["loc", [null, [213, 26], [213, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [213, 20], [217, 27]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 208,
                "column": 10
              },
              "end": {
                "line": 220,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("               ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasSearchOptions", ["loc", [null, [210, 24], [210, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [210, 18], [218, 25]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 176,
              "column": 8
            },
            "end": {
              "line": 222,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "modal-dropdown-trigger", [], ["label", ["subexpr", "t", ["components.date_period_selector.timeframe_label"], [], ["loc", [null, [179, 16], [179, 69]]], 0, 0], "value", ["subexpr", "@mut", [["get", "timeframeSelectedLabel", ["loc", [null, [180, 16], [180, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-notifications", "onClick", ["subexpr", "action", ["onShowTimeframeModal"], [], ["loc", [null, [182, 18], [182, 49]]], 0, 0]], ["loc", [null, [178, 10], [182, 51]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "hasSearchOptions", ["loc", [null, [184, 20], [184, 36]]], 0, 0, 0, 0], ["get", "hasNotifications", ["loc", [null, [184, 37], [184, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [184, 16], [184, 54]]], 0, 0]], [], 0, null, ["loc", [null, [184, 10], [206, 17]]]], ["block", "if", [["subexpr", "not", [["get", "hasNotifications", ["loc", [null, [208, 21], [208, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [208, 16], [208, 38]]], 0, 0]], [], 1, null, ["loc", [null, [208, 10], [220, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 227,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("main");
        dom.setAttribute(el2, "class", "container-content at-notifications");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        dom.setAttribute(el3, "class", "dc-tabs-panel active");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [13]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        morphs[2] = dom.createMorphAt(element3, 5, 5);
        morphs[3] = dom.createMorphAt(element3, 7, 7);
        morphs[4] = dom.createMorphAt(element3, 9, 9);
        morphs[5] = dom.createMorphAt(element3, 11, 11);
        morphs[6] = dom.createAttrMorph(element4, 'aria-label');
        morphs[7] = dom.createMorphAt(element4, 1, 1);
        morphs[8] = dom.createMorphAt(element4, 3, 3);
        morphs[9] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showAcceptModal", ["loc", [null, [3, 6], [3, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [13, 7]]]], ["block", "if", [["get", "showRejectModal", ["loc", [null, [15, 6], [15, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [25, 7]]]], ["block", "if", [["get", "showMoreInfoModal", ["loc", [null, [27, 6], [27, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 0], [37, 7]]]], ["block", "if", [["get", "showNotesModal", ["loc", [null, [39, 6], [39, 20]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [39, 0], [48, 7]]]], ["block", "if", [["get", "showBookingRequest", ["loc", [null, [50, 6], [50, 24]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [50, 0], [60, 7]]]], ["block", "if", [["get", "showTimeframeModal", ["loc", [null, [62, 6], [62, 24]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [62, 0], [81, 7]]]], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [83, 108], [83, 136]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.logged_in.notifications.title"], [], ["loc", [null, [84, 26], [84, 66]]], 0, 0], "icon", "icons/bell.svg", "location", "at-notifications"], 6, null, ["loc", [null, [84, 4], [103, 22]]]], ["block", "if", [["get", "hasSearchOptions", ["loc", [null, [105, 11], [105, 27]]], 0, 0, 0, 0]], [], 7, 8, ["loc", [null, [105, 5], [170, 11]]]], ["block", "if", [["get", "isLoadingNotifications", ["loc", [null, [174, 14], [174, 36]]], 0, 0, 0, 0]], [], 9, 10, ["loc", [null, [174, 8], [222, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
    };
  })());
});