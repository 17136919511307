define("scientia-resourcebooker/pods/components/manage-resources/rules-form/types/number/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/rules-form/types/number/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "number");
        dom.setAttribute(el1, "min", "0");
        dom.setAttribute(el1, "max", "9999");
        dom.setAttribute(el1, "class", "form-input manageResources-customFieldItem-valueTypes-integer-input manageResources-itemForm-form-input");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'oninput');
        morphs[1] = dom.createAttrMorph(element0, 'aria-label');
        morphs[2] = dom.createAttrMorph(element0, 'value');
        morphs[3] = dom.createAttrMorph(element0, 'disabled');
        return morphs;
      },
      statements: [["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [5, 22], [5, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 17], [5, 28]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [5, 51]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["get", "label", ["loc", [null, [6, 14], [6, 19]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "value", ["loc", [null, [7, 8], [7, 13]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [8, 11], [8, 19]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});