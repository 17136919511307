define('scientia-resourcebooker/pods/manage-resources/availability/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/availability-form-route'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAvailabilityFormRoute) {

  var TABS = { DESCRIPTION: 0, AVAILABILITY: 1 };

  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAvailabilityFormRoute['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    can: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    unavailabilityUpdater: _ember['default'].inject.service(),

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.availability.edit.page_title') + ' — ' + model.availabilityPattern.get('name');
    },

    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),
    resourceBusyExceptionsFetcher: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('list availability')) {
        this.transitionTo('logged-in');
      }
    },

    model: function model(params) {

      return _ember['default'].RSVP.hash({
        availabilityPattern: this.store.findRecord('availability', params.id),
        events: [],
        eventPatterns: [],
        busyExceptions: [],
        previewEvents: []
      });
    },

    afterModel: function afterModel(model) {

      // Calling the mixin methods in run.next gives the controller enough time to compute the
      // properties required (modelId, parentId)
      _ember['default'].run.next(this, function () {
        this._fetchBusyExceptions();
      });

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.availabilityPattern.get('name'),
        id: model.availabilityPattern.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {

        this.resetAvailabilityProperties();

        controller.setProperties({
          selectedTab: 0,
          error: '',
          isLoadingInUseBy: false
        });
      }
    },

    actions: {

      save: function save(transitionIndex) {
        var _this = this;

        var model = this.modelFor(this.routeName);
        var controller = this.controllerFor(this.routeName);

        controller.set('error', '');

        if (_ember['default'].isEmpty(model.availabilityPattern.get('name').trim())) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.name_required').toString(), TABS.DESCRIPTION);
        }

        controller.set('isSaving', true);

        var promises = [model.availabilityPattern.save()];

        if (this.get('can').can('edit-availability availability', { model: model.availabilityPattern })) {
          promises.push(this._updateUnavailability());
        }

        _ember['default'].RSVP.all(promises).then(function () {
          _this.editSuccessFlash('availability');
          _this.get('appInsights').trackUpdate('availability-pattern', { id: model.availabilityPattern.get('id'), name: model.availabilityPattern.get('name') });
          if (transitionIndex) {
            return _this.transitionTo('manage-resources.availability.index');
          } else {
            _this.send('reload');
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error.responseJSON || error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('availability');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      },

      reload: function reload() {
        this._fetchBusyExceptions();
        this.send('reloadAvailability');
      },

      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        if (model.availabilityPattern.get('hasDirtyAttributes')) model.availabilityPattern.rollbackAttributes();
        model.availabilityPattern.unloadRecord();
      }
    }

  });
});