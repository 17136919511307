define('scientia-resourcebooker/pods/components/container-sidebar/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':container-sidebar', ':sidebar', 'isSidebarOpen:container-sidebar--open'],
    attributeBindings: ['isSidebarOpen:aria-expanded', 'ariaLabel:aria-label', 'role'],
    ariaLabel: (0, _emberI18n.translationMacro)('components.container_sidebar.main_sidebar'),
    tagName: 'nav',
    role: 'region',

    session: _ember['default'].inject.service(),

    user: null,
    notificationsCount: 0,

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.sendAction('toggleSidebar');
      },

      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});