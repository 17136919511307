define('scientia-resourcebooker/services/booking-type-persister', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    persist: function persist(data) {
      var bookingType = this.get('store').createRecord('booking-type', data);

      var promise = bookingType.save().then(function (bookingTypeSaved) {
        return bookingTypeSaved;
      });

      promise['catch'](function () {
        bookingType.rollbackAttributes();
      });
      return promise;
    }
  });
});