define('scientia-resourcebooker/pods/components/manage-resources/user-lookup/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['userLookup'],

    resourcePermissionSummaryFetcher: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    bulkClaimClearer: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    searchTypeSelected: null,
    defaultData: null,
    fetchError: false,
    query: null,
    showBulkClearModal: false,

    progressTaskKey: null,

    isShowingEveryoneGroups: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        fetchError: false,
        query: null,
        searchTypeSelected: 'nameidentifier',
        defaultData: this.get('resourcePermissionSummaryFetcher').getEmptyResults()
      });
    },

    didInsertElement: function didInsertElement() {
      this.higlightSearchField();
    },

    higlightSearchField: function higlightSearchField() {
      this.$('.resourceTree-search-input').focus();
    },

    searchTypeSelectedLabel: _ember['default'].computed('searchTypeSelected', function () {
      return this.get('i18n').t('components.manage_resources.claims_form.claim_types.' + this.get('searchTypeSelected').toLowerCase());
    }),

    searchTypes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var labels = ['nameidentifier', 'emailaddress', 'givenname'];
      return labels.map(function (label) {
        return {
          label: _this.get('i18n').t('components.manage_resources.claims_form.claim_types.' + label.toLowerCase()),
          value: label
        };
      });
    }),

    fetchPermissionSummary: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(type, query, includeEveryoneGroup) {
      var result;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;

            this.set('searchResults', null);
            context$1$0.next = 4;
            return this.get('resourcePermissionSummaryFetcher').fetchForUser({ type: type, query: query, includeEveryoneGroup: includeEveryoneGroup });

          case 4:
            result = context$1$0.sent;

            this.set('searchResults', result);

            context$1$0.next = 11;
            break;

          case 8:
            context$1$0.prev = 8;
            context$1$0.t0 = context$1$0['catch'](0);

            this.set('fetchError', true);

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[0, 8]]);
    })).restartable(),

    confirmBulkClear: function confirmBulkClear() {
      var _this2 = this;

      this.get('bulkClaimClearer').clear({
        claimType: this.get('searchTypeSelected'),
        claimValue: this.get('query')
      }).then(function (progressTaskKey) {
        _this2.set('progressTaskKey', progressTaskKey);
      });
    },

    actions: {
      onSearch: function onSearch(query) {
        this.set('query', query);
        this.set('fetchError', false);
        var searchType = this.get('searchTypeSelected');
        var isShowingEveryoneGroups = this.get('isShowingEveryoneGroups');
        if (_ember['default'].isEmpty(query) || _ember['default'].isEmpty(searchType)) return;
        this.get('fetchPermissionSummary').perform(searchType, query, isShowingEveryoneGroups);
      },

      toggleEveryoneGroupVisibility: function toggleEveryoneGroupVisibility() {
        this.toggleProperty('isShowingEveryoneGroups');
        this.send('onSearch', this.get('query'));
      },

      onBulkClear: function onBulkClear() {
        var _this3 = this;

        var defer = _ember['default'].RSVP.defer();
        var prefix = 'components.manage_resources.user_lookup';
        var confirmData = {
          titlePath: prefix + '.bulk_clear_title',
          cancelLabelPath: 'general.cancel',
          message: this.get('i18n').t(prefix + '.bulk_clear_message', { key: this.get('searchTypeSelectedLabel'), value: this.get('query') }),
          confirmLabelPath: 'general.confirm',
          showConfirmText: true
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this3.confirmBulkClear();
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this3.get('globalConfirmer').close();
        });
      },

      onBulkClearComplete: function onBulkClearComplete(_ref) {
        var _this4 = this;

        var totalResults = _ref.totalResults;
        var successfulResults = _ref.successfulResults;

        this.set('progressTaskKey', null);
        this.send('onSearch', this.get('query'), true);
        this.get('appInsights').trackEvent('permissions-summary-bulk-clear', { totalResults: totalResults, successfulResults: successfulResults });

        var prefix = 'components.manage_resources.user_lookup';
        var confirmData = {
          titlePath: prefix + '.task_complete_title',
          confirmLabelPath: 'general.ok',
          singleButtonMode: true
        };

        if (totalResults === 0) {
          confirmData.message = this.get('i18n').t(prefix + '.task_complete_message_no_results', { key: this.get('searchTypeSelectedLabel'), value: this.get('query') });
        } else if (totalResults !== successfulResults) {
          confirmData.message = this.get('i18n').t(prefix + '.task_complete_message_incomplete', { key: this.get('searchTypeSelectedLabel'), value: this.get('query'), totalResults: totalResults, successfulResults: successfulResults });
        } else {
          confirmData.message = this.get('i18n').t(prefix + '.task_complete_message_all', { key: this.get('searchTypeSelectedLabel'), value: this.get('query'), successfulResults: successfulResults });
        }

        var defer = _ember['default'].RSVP.defer();
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this4.set('taskProgressKey', null);
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this4.get('globalConfirmer').close();
        });
      },

      onGetProgressFailed: function onGetProgressFailed() {
        var _this5 = this;

        var defer = _ember['default'].RSVP.defer();
        var prefix = 'components.manage_resources.user_lookup';
        var confirmData = {
          titlePath: prefix + '.progress_failed_title',
          messagePath: prefix + '.progress_failed_message',
          confirmLabelPath: 'general.ok',
          singleButtonMode: true
        };
        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this5.set('taskProgressKey', null);
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this5.get('globalConfirmer').close();
        });
      },

      clearSearch: function clearSearch() {
        this.setProperties({
          query: null,
          searchResults: null
        });

        this.higlightSearchField();
      }

    }
  });
});