define('scientia-resourcebooker/pods/components/manage-resources/image-form-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-imageFormPreview'],
    imageUri: null,
    disabled: false,

    actions: {
      openIconSelector: function openIconSelector() {
        this.sendAction('openIconSelector');
      },

      clearImageUri: function clearImageUri() {
        this.sendAction('clearImageUri');
      }
    }
  });
});