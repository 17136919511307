define('scientia-resourcebooker/adapters/notification-template', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    pathForType: function pathForType(type) {
      return type.classify();
    },

    urlForQueryRecord: function urlForQueryRecord(query) {

      var url = undefined;
      if (query.isSystem) {
        url = this.buildURL('notification-template', query.id, null, 'findRecord', { id: query.id });
        url = url.replace('NotificationTemplate/', 'NotificationTemplate/Template/');
      } else {

        url = this.buildURL('notification-template', query.id, null, 'findRecord', { id: query.id });
        url = url.replace('NotificationTemplate/', 'NotificationTemplate/BookingType/' + query.parentId + '/Template/');
      }

      delete query.id;
      delete query.parentId;
      delete query.isSystem;
      return url;
    },

    urlForUpdateRecord: function urlForUpdateRecord(id) {
      var url = this.buildURL('notification-template', id);
      url = url.replace('NotificationTemplate/', 'NotificationTemplate/Template/');
      return url;
    }
  });
});