define('scientia-resourcebooker/services/rules-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var DEPRECATED_PROPERTIES = ['747c6f81-fbdc-4ec8-b499-b3832a9049a7'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    /**
     * Retrieves the existing quotas for a given resource type
     * @param  {string} type - The type of the resource we are trying to fetch (booking-type, resource-type)
     * @param  {string} id - The id of the resource type
     */
    fetch: function fetch(type, id) {
      var _this = this;

      type = type.camelize();
      var typeEndpoint = _scientiaResourcebookerConfigEnvironment['default'].API.RULES.FETCH[type];
      return this.get('authenticatedRequest').fetch(typeEndpoint.loc(id)).then(function (rules) {
        return _this._filterRulesDeprecatedProperties(rules);
      });
    },

    fetchRules: function fetchRules() {
      var _this2 = this;

      return this.get('authenticatedRequest').fetch('Rules').then(function (rules) {
        return _this2._filterRulesDeprecatedProperties(rules);
      });
    },

    _filterRulesDeprecatedProperties: function _filterRulesDeprecatedProperties() {
      var rules = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      if (rules.length) {
        rules.forEach(function (rule) {
          if (rule.properties) {
            rule.properties = rule.properties.reject(function (rule) {
              return DEPRECATED_PROPERTIES.includes(rule.id);
            });
          }
        });
      }

      return rules;
    }

  });
});