define('scientia-resourcebooker/pods/components/manage-resources/concurrency-group-resources-form/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['concurrencyConfigurationGroupsForm'],
    concurrencyGroupResourceTreeDataFetcher: _ember['default'].inject.service(),

    model: null,
    resourceTreeModel: null,

    selected: null,
    searchResults: null,
    query: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.fetchResourceTypes();
    },

    selectedWithIcons: _ember['default'].computed('selected.length', 'resourceTreeModel', function () {
      var _this = this;

      return this.get('selected').map(function (resource) {
        if (_ember['default'].isEmpty(_this.get('resourceTreeModel'))) return resource;
        var rt = _this.get('resourceTreeModel.data').find(function (r) {
          return r.id === resource.resourceTypeId;
        });
        _ember['default'].set(resource, 'imageUri', rt && rt.imageUri ? rt.imageUri : 'icons/tag.svg');
        _ember['default'].set(resource, 'imageTitle', rt && rt.name ? rt.name + ' - ' + resource.name : null);
        return resource;
      });
    }),

    fetchResourceTypes: function fetchResourceTypes() {
      var _this2 = this;

      this.set('isLoading', true);
      return this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
        type: 'resourceType'
      }).then(function (data) {
        var result = _ember['default'].Object.create({
          name: 'resourcetypes',
          data: data.results,
          blockActions: true,
          children: [_ember['default'].Object.create({
            name: 'resourcegroups',
            childDataParams: ['id'],
            blockActions: true,
            loadChildData: function loadChildData(_ref, page) {
              var id = _ref.id;
              return _this2._fetchResourceGroups(id, page);
            }
          }), _ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref2, page) {
              var id = _ref2.id;
              return _this2._fetchResourcesForResourceType(id, page);
            }
          })]
        });

        _this2.set('resourceTreeModel', result);
      })['finally'](function () {
        _this2.set('isLoading', false);
      });
    },

    /**
    * Fetches Groups for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourceGroups: function _fetchResourceGroups(resourceTypeId, page) {
      var _this3 = this;

      return this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
        type: 'resourceGroup',
        parentId: resourceTypeId,
        data: {
          pageNumber: page || 1,
          itemsPerPage: 10
        }
      }).then(function (data) {

        return _ember['default'].Object.create({
          name: 'resourcegroups',
          blockActions: true,
          data: data.results,
          meta: data.meta,
          rank: 0,
          children: [_ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref3, page) {
              var resourceGroupId = _ref3.id;
              return _this3._fetchResourcesForGroup(resourceTypeId, resourceGroupId, page);
            }
          })]
        });
      });
    },

    /**
    * Fetches Resources for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourcesForResourceType: function _fetchResourcesForResourceType(resourceTypeId, page) {
      return this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
        type: 'resource',
        data: {
          resourceTypeId: resourceTypeId,
          pageNumber: page || 1,
          itemsPerPage: 10
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          rank: 1,
          data: data.results,
          hideIcon: true,
          meta: data.meta
        });
      });
    },

    /**
    * Fetches resources for a given ResourceGroup
    * @param {string} resourceTypeId The resourceType Id
    * @param {string} groupId The group Id
    */
    _fetchResourcesForGroup: function _fetchResourcesForGroup(resourceTypeId, groupId, page) {
      return this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
        type: 'resourceGroupResources',
        parentId: resourceTypeId,
        id: null,
        groupId: groupId,
        data: {
          pageNumber: page || 1,
          itemsPerPage: 10
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          data: data.results,
          hideIcon: true,
          meta: data.meta
        });
      });
    },

    actions: {

      onSelect: function onSelect() {

        var args = arguments;
        var item = args[args.length - 1];
        var itemType = args[args.length - 2];
        if (!itemType && args.length === 1) {
          itemType = 'resourcetypes';
        }

        // Only allow selection of resources
        if (itemType !== 'resources') return;

        var selected = this.get('selected');

        var existingItem = selected.findBy('id', item.id);
        if (_ember['default'].isEmpty(existingItem)) {
          var highestIndex = selected.get('length') ? Math.max.apply(Math, selected.getEach('order')) : 0;
          var itemId = _ember['default'].get(item, 'id');
          var itemData = {
            id: itemId,
            resourceTypeId: _ember['default'].get(args[0], 'id'),
            resourceGroupId: itemType === 'resourcegroups' ? itemId : null,
            resourceId: itemType === 'resources' ? itemId : null,
            name: _ember['default'].get(item, 'name'),
            order: highestIndex + 1,
            type: 'resources'
          };

          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }

        this.get('onSelectionChanged')(selected);
      },

      // When submiting a search
      onSearch: function onSearch(query) {
        var _this4 = this;

        this.setProperties({
          query: query,
          isLoading: true
        });

        // this.get('resourceTreeDataFetcher').search(query, 'resource').then(data => {
        //   this.set('searchResults', data);
        this.get('concurrencyGroupResourceTreeDataFetcher').fetch({
          type: 'resource',
          data: {
            query: query,
            itemsPerPage: 999
          } }).then(function (data) {
          _this4.set('searchResults', { resources: data.results });
        })['finally'](function () {
          _this4.set('isLoading', false);
        });
      },

      onClearSearch: function onClearSearch() {
        this.setProperties({
          query: '',
          searchResults: null
        });
      },

      onSelectSearchResultItem: function onSelectSearchResultItem(type, item) {
        this.send('onSelect', { id: item.resourceTypeId }, type, item);
      },

      clearSelection: function clearSelection() {
        this.set('selected', []);
        this.get('onSelectionChanged')([]);
      },

      onRemoveItem: function onRemoveItem(item) {
        this.send('onSelect', 'resources', item);
      }

    }

  });
});