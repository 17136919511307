define('scientia-resourcebooker/pods/components/manage-resources/rules-form/types/discrete/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    choice: null,

    checked: false,

    actions: {
      changed: function changed(value) {
        this.sendAction('changed', value);
      }
    }
  });
});