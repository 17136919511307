define('scientia-resourcebooker/pods/print/calendar/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['startRange', 'endRange', 'viewType', 'from'],

    startRange: null,
    endRange: null,
    viewType: null,
    from: null,

    startRangeMoment: _ember['default'].computed('startRange', function () {
      return moment(this.get('startRange'));
    }),
    endRangeMoment: _ember['default'].computed('endRange', function () {
      return moment(this.get('endRange'));
    })
  });
});