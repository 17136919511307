define("scientia-resourcebooker/pods/components/language-selector/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/language-selector/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("	");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        return morphs;
      },
      statements: [["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "locales", ["loc", [null, [2, 9], [2, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "text", "value", ["subexpr", "readonly", [["get", "current", ["loc", [null, [5, 17], [5, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 7], [5, 25]]], 0, 0], "didSelect", "changeLocale", "searchEnabled", false, "placeholder", ["subexpr", "t", ["components.resource_filters.select"], [], ["loc", [null, [8, 13], [8, 53]]], 0, 0], "class", "at-userProfile-form-checkbox"], ["loc", [null, [1, 1], [10, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});