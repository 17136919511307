define('scientia-resourcebooker/services/bulk-resources-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    nestedResourceStore: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    copy: function copy(resourceTypeId, resources) {
      var data = resources.map(function (resource) {
        return resource.get('id');
      });

      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.BULK_RESOURCES.COPY.loc(resourceTypeId), { resources: data });
    },

    'delete': function _delete(resourceTypeId, resources) {
      var data = resources.map(function (resource) {
        return resource.get('id');
      });

      return this.get('authenticatedRequest')['delete'](_scientiaResourcebookerConfigEnvironment['default'].API.BULK_RESOURCES.DELETE.loc(resourceTypeId), { resources: data });
    },

    // The bulk delete is not implemented in the API for resource group,
    // so we just loop in all resourceGroups and make a DELETE request.
    deleteGroups: function deleteGroups(resourceTypeId, resourceGroups) {
      var _this = this;

      var promises = resourceGroups.map(function (resourceGroup) {
        return _this.get('nestedResourceStore').destroyResource({
          type: 'resourceType.resourceGroup',
          id: resourceGroup.get('id'),
          parentId: resourceGroup.get('resourceTypeId')
        });
      });

      return _ember['default'].RSVP.all(promises);
    }
  });
});