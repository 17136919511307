define("scientia-resourcebooker/pods/components/time-input/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/time-input/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "timeInput-separator");
        var el2 = dom.createTextNode(":");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "hours", ["loc", [null, [2, 10], [2, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "label", "optionValuePath", "value", "value", ["subexpr", "@mut", [["get", "timeHours", ["loc", [null, [5, 8], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "—", "showPlaceholder", true, "class", "timeInput-select timeInput-select-hours"], ["loc", [null, [1, 0], [9, 2]]], 0, 0], ["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "minutes", ["loc", [null, [12, 10], [12, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "label", "optionValuePath", "value", "value", ["subexpr", "@mut", [["get", "timeMinutes", ["loc", [null, [15, 8], [15, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "—", "showPlaceholder", true, "class", "timeInput-select timeInput-select-minutes"], ["loc", [null, [11, 0], [19, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});