define('scientia-resourcebooker/pods/components/manage-resources/availability-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  exports['default'] = _ember['default'].Component.extend({

    appInsights: _ember['default'].inject.service(),

    resource: null,
    busyTimes: [],

    busyPatterns: [],
    busyExceptions: [],
    previewEvents: [],

    startDate: null,
    endDate: null,
    displayedDate: null,

    selectedTab: 0,
    disabled: false,
    selectedUserGroup: DEFAULT_ALL_GROUPS,
    isClearingUnavailability: false,
    pendingChangesCount: 0,
    hasPendingUnavailability: _ember['default'].computed.gt('pendingChangesCount', 0),

    isLoadingBusyExceptions: false,
    isComittingUnavailability: false,

    linkedPatternsCount: 0,

    localeProcessor: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    previewCalendarMobileView: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('calendarSmallViewType');
    }),

    zoomLevel: 0,

    includeDisabledPatternsUnavailability: false,

    // If we are in Edit Pattern route
    isManagingPatterns: false,

    // If we are in Edit pattern route but as Read Only mode (non admin)
    isPatternReadOnly: false,

    userGroups: (function () {
      return [{
        id: DEFAULT_ALL_GROUPS,
        value: DEFAULT_ALL_GROUPS,
        label: this.get('i18n').t('components.manage_resources.availability_form.user_groups.any')
      }];
    }).property(),

    selectedUserGroupObserver: (function () {
      this._refreshBusyCalendar(this.getProperties('selectedTab', 'selectedUserGroup', 'startDate', 'endDate'));
    }).observes('selectedUserGroup'),

    actions: {

      onPreviewCalendarLoaded: function onPreviewCalendarLoaded() {
        var model = this.get('resource');

        this.get('appInsights').trackAvailabilityPreview({
          type: model.constructor.modelName,
          id: model.get('id'),
          name: model.get('name')
        });
      },

      showAddUnavailabilityWeekdaySlot: function showAddUnavailabilityWeekdaySlot(index, hour) {
        if (this.get('disabled')) return false;
        this.get('showAddUnavailabilityWeekdaySlot')(index, hour, this.get('selectedUserGroup'));
      },

      showRemoveUnavailabilityWeekdaySlot: function showRemoveUnavailabilityWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('showRemoveUnavailabilityWeekdaySlot')(slot);
      },

      undoAddWeekdaySlot: function undoAddWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('undoAddWeekdaySlot')(slot, this.get('selectedUserGroup'));
      },

      undoRemoveWeekdaySlot: function undoRemoveWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('undoRemoveWeekdaySlot')(slot);
      },

      showAddUnavailabilityDateSlot: function showAddUnavailabilityDateSlot(moment, endMoment) {
        if (this.get('disabled')) return false;
        this.get('showAddUnavailabilityDateSlot')(moment, this.get('selectedUserGroup'), endMoment);
      },

      showRemoveUnavailabilityDateSlot: function showRemoveUnavailabilityDateSlot(event) {
        if (this.get('disabled')) return false;
        this.get('showRemoveUnavailabilityDateSlot')(event, this.get('selectedUserGroup'));
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        this.set('displayedDate', startDate);

        this.sendAction('fetchBusyTimes', startDate, endDate, this.get('selectedUserGroup'));
      },

      fetchBusyPatterns: function fetchBusyPatterns() {
        this.sendAction('fetchBusyPatterns', this.get('selectedUserGroup'));
      },

      fetchPreviewEvents: function fetchPreviewEvents(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        this.set('displayedDate', startDate);

        // This prevents the date going going to previous month  all the time when switching between agenda<->week views constantly
        var dayDiff = endDate.diff(startDate, 'day');
        if (dayDiff === 6) {
          this.set('displayedDate', endDate.clone());
        }

        this.sendAction('fetchPreviewEvents', startDate, endDate, this.get('selectedUserGroup'), this.get('includeDisabledPatternsUnavailability'));
      },

      onZoomChanged: function onZoomChanged(zoomLevel) {
        this.set('zoomLevel', zoomLevel);
      },

      toggleIncludeDisabledPatternsUnavailability: function toggleIncludeDisabledPatternsUnavailability(value) {
        this.set('includeDisabledPatternsUnavailability', value);

        this.get('appInsights').trackAvailabilityPreviewIncludeDisabledPatterns(value);

        this.sendAction('fetchPreviewEvents', this.get('startDate'), this.get('endDate'), this.get('selectedUserGroup'), value);
      },

      onDateRangeDrag: function onDateRangeDrag(startRange, endRange) {
        // Check if we are dragging over an existing booking/unav slot.
        endRange.add(1, 'hour');
        var events = this.get('busyTimes');
        var eventFound = events.find(function (e) {
          var eventStart = _ember['default'].get(e, 'fromTime');
          var eventEnd = _ember['default'].get(e, 'toTime');
          return eventStart.isBetween(startRange, endRange, 'minute') || eventEnd.isBetween(startRange, endRange);
        });

        this.get('appInsights').trackAvailabilityDrag({ start: startRange.hour(), end: endRange.hour(), isSpecifics: true, isValid: !eventFound });

        if (!eventFound) {
          this.send('showAddUnavailabilityDateSlot', startRange, endRange);
          return true;
        } else {
          return false;
        }
      },

      onDayHeaderClick: function onDayHeaderClick(date) {
        this.get('appInsights').trackAvailabilityHeaderClick({ dayOfWeek: date.day(), isSpecifics: true });
        this.send('showAddUnavailabilityDateSlot', date, date.clone().add(1, 'day'));
      }
    },

    _refreshBusyCalendar: function _refreshBusyCalendar(_ref) {
      var selectedTab = _ref.selectedTab;
      var selectedUserGroup = _ref.selectedUserGroup;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;

      switch (selectedTab) {
        case 0:
          this.sendAction('fetchBusyPatterns', selectedUserGroup);
          break;
        case 1:
          this.sendAction('fetchBusyTimes', startDate, endDate, selectedUserGroup);
          break;
      }
    }
  });
});