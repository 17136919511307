define("scientia-resourcebooker/pods/manage-resources/system/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 10
                },
                "end": {
                  "line": 11,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/cog.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [10, 12], [10, 68]]], 0, 0], ["inline", "t", ["pods.manage_resources.system.settings"], [], ["loc", [null, [10, 69], [10, 114]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [13, 12], [13, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.system.calendar"], [], ["loc", [null, [13, 74], [13, 119]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 10
                },
                "end": {
                  "line": 17,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/bell.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [16, 12], [16, 69]]], 0, 0], ["inline", "t", ["pods.manage_resources.system.notifications_templates"], [], ["loc", [null, [16, 70], [16, 130]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 8
              },
              "end": {
                "line": 18,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [9, 10], [11, 21]]]], ["block", "dc-tab", [], [], 1, null, ["loc", [null, [12, 10], [14, 21]]]], ["block", "dc-tab", [], [], 2, null, ["loc", [null, [15, 10], [17, 21]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 8
              },
              "end": {
                "line": 28,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "system/system-settings", [], ["options", ["subexpr", "@mut", [["get", "model.options", ["loc", [null, [22, 20], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [23, 21], [23, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "changeMode", "changeMode", "onToggleNotificationsEnabled", ["subexpr", "action", ["toggleNotificationsEnabled"], [], ["loc", [null, [25, 41], [25, 78]]], 0, 0], "onToggleWelcomeMessageEnabled", ["subexpr", "action", ["toggleWelcomeMessageEnabled"], [], ["loc", [null, [26, 42], [26, 80]]], 0, 0], "save", "saveOptions"], ["loc", [null, [21, 12], [27, 32]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 8
              },
              "end": {
                "line": 41,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/booking-type-calendar-options", [], ["isSystem", true, "isSaving", ["subexpr", "@mut", [["get", "isSavingCalendarOptions", ["loc", [null, [33, 19], [33, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "startTime", ["subexpr", "@mut", [["get", "calendarStartTime", ["loc", [null, [34, 20], [34, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "endTime", ["subexpr", "@mut", [["get", "calendarEndTime", ["loc", [null, [35, 18], [35, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "showWeekends", ["subexpr", "@mut", [["get", "calendarShowWeekends", ["loc", [null, [36, 23], [36, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarBookingTimes", ["subexpr", "@mut", [["get", "calendarBookingTimes", ["loc", [null, [37, 31], [37, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingSpanMultipleDays", ["subexpr", "@mut", [["get", "bookingSpanMultipleDays", ["loc", [null, [38, 34], [38, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "save", ["subexpr", "action", ["saveCalendarOptions"], [], ["loc", [null, [39, 15], [39, 45]]], 0, 0]], ["loc", [null, [31, 10], [40, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/notification-templates", [], ["notificationsDisabled", ["subexpr", "@mut", [["get", "model.options.disableEmailNotifications", ["loc", [null, [46, 34], [46, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystem", true], ["loc", [null, [45, 12], [48, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [8, 8], [18, 24]]]], ["block", "dc-tab-panel", [], ["class", "at-settings"], 1, null, ["loc", [null, [20, 8], [28, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-calendar-options at-system-calendar-options"], 2, null, ["loc", [null, [30, 8], [41, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-templateNotifications"], 3, null, ["loc", [null, [43, 8], [49, 25]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/system/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-system");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'aria-label');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(element1, 5, 5);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [4, 100], [4, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.system.page_title"], [], ["loc", [null, [5, 23], [5, 68]]], 0, 0], "icon", "icons/cog.svg", "location", "at-system"], ["loc", [null, [5, 2], [5, 112]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [6, 20], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 2], [6, 27]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth at-system", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [7, 65], [7, 76]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [7, 2], [50, 16]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});