define('scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal availabilityClearModal'],
    i18n: _ember['default'].inject.service(),
    classNameBindings: ['location'],
    location: '',
    unavailabilityTypeSelected: null,

    unavailabilityTypes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var localePrefix = 'components.manage_resources.availability_form.types.';
      var types = ['all_defaults', 'all_specific', 'future_specific'];
      return types.map(function (type) {
        return {
          label: _this.get('i18n').t(localePrefix + type),
          value: type
        };
      });
    }),

    actions: {
      confirm: function confirm() {
        this.attrs.confirm(this.get('unavailabilityTypeSelected'));
      }
    }
  });
});