define('scientia-resourcebooker/pods/manage-resources/resource-type/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsPageChanger['default'], {
    nestedResourceStore: _ember['default'].inject.service(),
    bulkResourcesProcessor: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    queryParams: ['page', 'groupPage', 'query', 'perPage', 'groupsPerPage'],
    page: 1,
    groupPage: 1,
    query: '',
    perPage: 10,
    groupsPerPage: 10,
    boundQuery: _ember['default'].computed.oneWay('query'),
    errorHandler: _ember['default'].inject.service(),
    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        linkable: false
      }];
    }).property('model.resourceType.name', 'i18n.locale'),

    proxiedResources: _ember['default'].computed.map('model.resources', function (model) {
      return _ember['default'].ObjectProxy.create({
        content: model,
        isSelected: false
      });
    }),

    persistedResources: _ember['default'].computed.filterBy('proxiedResources', 'isDeleted', false),

    resourcesWithCanDeleteFalse: _ember['default'].computed.filterBy('checkedResources', 'canDelete', false),

    resources: _ember['default'].computed.sort('persistedResources', 'resourceSorting'),
    resourceSorting: ['name'],

    checkedResources: _ember['default'].computed.filterBy('proxiedResources', 'isSelected', true),

    proxiedResourceGroups: _ember['default'].computed.map('model.resourceGroups', function (model) {
      return _ember['default'].ObjectProxy.create({
        content: model,
        isSelected: false
      });
    }),

    persistedResourceGroups: _ember['default'].computed.filterBy('proxiedResourceGroups', 'isDeleted', false),
    resourceGroups: _ember['default'].computed.sort('persistedResourceGroups', 'resourceGroupSorting'),
    resourceGroupSorting: ['name'],

    checkedResourceGroups: _ember['default'].computed.filterBy('proxiedResourceGroups', 'isSelected', true),

    personResourceType: _scientiaResourcebookerConfigEnvironment['default'].IDS.PERSON_RESOURCE_TYPE,

    enablePersonResourceTypeDeletion: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('enablePersonResourceTypeDeletion');
    }),

    confirmBulkDeleteModal: false,
    confirmBulkDuplicateModal: false,
    confirmBulkDeleteGroupModal: false,
    addToResourceGroup: false,

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.set('query', this.get('boundQuery'));
    },

    resetPaginationOnParamsChange: (function () {
      _ember['default'].run.once(this, 'set', 'page', 1);
    }).observes('query'),

    actions: {
      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 500, false);
      },

      confirmBulkDelete: function confirmBulkDelete() {
        var _this = this;

        var resourceTypeId = this.get('model').resourceType.get('id');
        var resources = this.get('checkedResources');

        this.get('bulkResourcesProcessor')['delete'](resourceTypeId, resources).then(function () {
          var message = _this.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_delete_success', { count: resources.get('length') });
          _this.flashSuccess(message);

          _this.send('reloadResources');
        })['catch'](function () {
          var message = _this.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_delete_error', { count: resources.get('length') });
          _this.flashError(message);
        })['finally'](function () {
          _this.set('confirmBulkDeleteModal', false);
          _this.set('confirmMessage', '');
          _this.set('confirmTitle', '');
        });
      },

      confirmBulkDuplicate: function confirmBulkDuplicate() {
        var _this2 = this;

        var resourceTypeId = this.get('model').resourceType.get('id');
        var resources = this.get('checkedResources');

        this.get('bulkResourcesProcessor').copy(resourceTypeId, resources).then(function () {
          var message = _this2.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_duplicate_success', { count: resources.get('length') });
          _this2.flashSuccess(message);

          _this2.send('reloadResources');
        })['catch'](function () {
          var message = _this2.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_duplicate_error', { count: resources.get('length') });
          _this2.flashError(message);
        })['finally'](function () {
          _this2.set('confirmBulkDuplicateModal', false);
          _this2.set('confirmMessage', '');
          _this2.set('confirmTitle', '');
        });
      },

      bulkDeleteResourceGroups: function bulkDeleteResourceGroups() {
        this.set('confirmBulkDeleteGroupModal', true);
        this.set('confirmTitle', this.get('i18n').t('pods.manage_resources.resource_type.show.confirm_bulk_group_delete.title'));
        this.set('confirmMessage', this.get('i18n').t('pods.manage_resources.resource_type.show.confirm_bulk_group_delete.message', { count: this.get('checkedResourceGroups').length }));
      },

      confirmBulkDeleteGroup: function confirmBulkDeleteGroup() {
        var _this3 = this;

        var resourceTypeId = this.get('model').resourceType.get('id');
        var resourceGroups = this.get('checkedResourceGroups');

        this.get('bulkResourcesProcessor').deleteGroups(resourceTypeId, resourceGroups).then(function () {
          var message = _this3.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_delete_group_success', { count: resourceGroups.get('length') });
          _this3.flashSuccess(message);

          _this3.send('reloadResourceGroups');
        })['catch'](function (error) {
          var responseJSON = error.responseJSON;

          if (responseJSON && responseJSON.ExceptionMessageParams) {
            responseJSON = _this3._formatErrorParams(responseJSON);
          }

          var message = _this3.get('errorHandler').buildErrorMessage(responseJSON);

          if (!message) {
            message = _this3.get('i18n').t('pods.manage_resources.resource_type.flash_messages.bulk_delete_group_error', { count: resourceGroups.get('length') });
          }

          _this3.flashError(message);
        })['finally'](function () {
          _this3.set('confirmBulkDeleteGroupModal', false);
          _this3.set('confirmMessage', '');
          _this3.set('confirmTitle', '');
        });
      },

      deleteModel: function deleteModel() {
        var _this4 = this;

        var defer = _ember['default'].RSVP.defer();

        var confirmData = {
          titlePath: 'pods.manage_resources.resource_type.index.confirm_delete_title',
          messagePath: 'pods.manage_resources.resource_type.index.confirm_delete_message',
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.confirm'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this4.send('deleteModelConfirm');
        })['catch'](function () {
          _this4.get('globalConfirmer').close();
        });
      },

      deleteModelConfirm: function deleteModelConfirm() {
        var _this5 = this;

        this.get('model.resourceType').destroyRecord().then(function () {
          _this5.get('globalConfirmer').close();
          _this5.deleteSuccessFlash('resource-type');
          _this5.transitionToRoute('manage-resources.resource-type.index');
        })['catch'](function () {
          _this5.get('globalConfirmer').close();
          _this5.deleteErrorFlash('resource-type');
        });
      },

      deleteResource: function deleteResource(resource) {
        this.get('checkedResources').map(function (checkedResource) {
          checkedResource.set('isSelected', false);
        });

        resource.set('isSelected', true);

        this.set('confirmBulkDeleteModal', true);
      },

      deleteResourceGroup: function deleteResourceGroup(resourceGroup) {
        this.get('checkedResourceGroups').map(function (checkedGroup) {
          checkedGroup.set('isSelected', false);
        });

        resourceGroup.set('isSelected', true);

        this.send('bulkDeleteResourceGroups');
      },

      addToResourceGroup: function addToResourceGroup(resourceGroup, resources, deferred) {
        var _this6 = this;

        var resourceIds = resources.mapBy('id');
        var groupResourceIds = (resourceGroup.get('resourceIds') || []).mapBy('id').concat(resourceIds).uniq().map(function (id) {
          return { id: id };
        });

        resourceGroup.content.set('resourceIds', groupResourceIds);
        resourceGroup.content.save().then(function () {
          var message = _this6.get('i18n').t('pods.manage_resources.resource_type.flash_messages.add_to_resource_group_success', { count: resources.get('length') });
          _this6.flashSuccess(message);

          deferred.resolve();
          _this6.send('reloadResourceGroups');
        })['catch'](function () {
          var message = _this6.get('i18n').t('pods.manage_resources.resource_type.flash_messages.add_to_resource_group_error', { count: resources.get('length') });
          _this6.flashError(message);

          deferred.reject();
        });
      }
    },

    _formatErrorParams: function _formatErrorParams(errorResponse) {
      if (errorResponse.ExceptionMessageKey === 'resourcegroup_groupinuse') {
        (function () {
          var params = errorResponse.ExceptionMessageParams || {};
          var keys = Object.keys(params);
          var bookingTypeNames = '';
          keys.forEach(function (k, i, arr) {
            bookingTypeNames += params[k];
            if (i + 1 < arr.length) bookingTypeNames += ', ';
          });

          errorResponse.ExceptionMessageParams.bookingTypes = bookingTypeNames;
        })();
      }

      return errorResponse;
    }
  });
});