define('scientia-resourcebooker/services/system-settings', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    localeProcessor: _ember['default'].inject.service(),

    options: null,

    defaults: {
      attachmentFileTypes: {
        Image: 'jpg, gif',
        Word: 'doc, docx',
        Excel: 'xlx, xlsx',
        Pdf: 'pdf',
        Text: 'txt',
        Archive: 'zip, rar'
      },
      betaConcurrencyCombinedCalendarsDisabled: false,
      bookingHistoryAdminOnly: false,
      bookingSpanMultipleDays: false,
      bookingUseSplitTimePicker: true,
      calendarAgendaViewShowDuration: false,
      calendarDefaultViewType: 'week',
      calendarDefaultZoomLevel: 0,
      calendarDisableWarnOnHoursNotAligned: false,
      calendarDragDisabled: false,
      calendarEndTime: '00:00',
      calendarEndTimeOffset: 0,
      calendarFirstDayOfTheWeekOffset: 0,
      calendarShowWeekends: false,
      calendarSmallViewType: 'month',
      calendarStartTime: '00:00',
      calendarStartTimeOffset: 0,
      contactPageUrl: null,
      detailedUnavailabilityBookingDisplay: '',
      disableBookingSummaryPrint: false,
      disableBookingPrimaryResourceChange: false,
      disableHelpAdmin: true,
      disableHelpNonAdmin: true,
      disableEmailNotifications: false,
      enableBase: false,
      enableBobo: false,
      enableDescriptionFormField: false,
      enablePersonResourceTypeDeletion: false,
      enforceAutoLogoutOnNotificationsPollFailure: false,
      helpPageUrl: null,
      hideDashboard: false,
      hidePermissionsVersion3: true,
      icalDisableBookings: false,
      maxBookingTitleTextLength: 255,
      maxBookingFormTextLength: 2000,
      maxFileSizeKB: 2048,
      notificationsCountRefreshMinutes: 5,
      pendingNotificationsDisplayMode: 3,
      resourceFilterAppInsightsLogLevel: 2,
      resourcesListAdditionalLoadSize: 10,
      resourcesListInitialLoadSize: 10,
      exchangeEnabled: false,
      singleLogoutMode: 0,
      userProfileDisplayMode: 3,
      weekendDays: []
    },

    // Dont raise a warning for the following properties if not set
    ignoreNonSetProperties: ['calendarDefaultZoomLevel'],

    getSetting: function getSetting(key) {
      if (this.get('options') && key in this.get('options') && this.get('options.' + key) !== null) {
        return this.get('options.' + key);
      } else {

        // Only log warning if default is not null or false
        if (this.get('defaults.' + key) !== null && this.get('defaults.' + key) !== false) {

          if (this.get('ignoreNonSetProperties').includes(key)) return;

          LE.warn('InstitutionSettings key with name "' + key + '" not found. Using the default value of "' + this.defaults[key] + '". This might cause unexpected behaviour. To suppress this warning please set a value for "' + key + '" in InstitutionSettings.');
        }

        // Return default value
        return this.defaults[key];
      }
    },

    getSystemStartHour: function getSystemStartHour() {
      var start = this.getSetting('calendarStartTime');

      var _start$split = start.split(':');

      var _start$split2 = _slicedToArray(_start$split, 1);

      var h = _start$split2[0];

      return parseInt(h, 10);
    },

    getSystemEndHour: function getSystemEndHour() {
      var end = this.getSetting('calendarEndTime');

      var _end$split = end.split(':');

      var _end$split2 = _slicedToArray(_end$split, 1);

      var h = _end$split2[0];

      return parseInt(h, 10);
    },

    getCalendarOptions: function getCalendarOptions() {

      return {
        calendarStartTime: this.getSetting('calendarStartTime'),
        calendarEndTime: this.getSetting('calendarEndTime'),
        calendarShowWeekends: this.getSetting('calendarShowWeekends'),
        calendarBookingTimes: this.getSetting('calendarBookingTimes'),
        bookingSpanMultipleDays: this.getSetting('bookingSpanMultipleDays')
      };
    },

    /**
     * Sets default calendar Times if properties were not set
     */
    setDefaultCalendarOptions: function setDefaultCalendarOptions() {
      var calendarOptions = this.getCalendarOptions();
      if (!calendarOptions.calendarStartTime || !calendarOptions.calendarEndTime) {
        // LE.warn('CalendarStartTime or CalendarEndTime properties not set');
        var startHour = this.getSetting('calendarStartTimeOffset') || 0;
        var endHour = this.getSetting('calendarEndTimeOffset') || 0;

        var startTime = moment().hour(startHour).minute(0);
        var endTime = moment().hour(24 - endHour).minute(0);
        this.setProperties({
          'options.calendarStartTime': startTime.format('HH:mm'),
          'options.calendarEndTime': endTime.format('HH:mm')
        });
      }
    },

    fetch: function fetch() {
      var _this = this;

      var forceReload = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (this.get('options') && !forceReload) return this.get('options');
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.INSTITUTION_SETTINGS).then(function (options) {

        _this.set('options', options);
        _this.setDefaultCalendarOptions();

        moment.tz.setDefault(options.timeZone);
        if (!moment.tz.zone(options.timeZone)) {
          LE.error('Unable to set timeZone to "' + options.timeZone + '" This might cause unexpected behaviour. Please check azure storage');
        }

        _this.get('localeProcessor').initLocale();

        return options;
      });
    },

    reload: function reload() {
      return this.fetch(true);
    },

    update: function update(options) {
      var _this2 = this;

      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.INSTITUTION_SETTINGS, options).then(function (newOptions) {
        _this2.set('options', newOptions);
        return _ember['default'].RSVP.resolve(newOptions);
      });
    },

    isOpen: (function () {
      return this.get('options.systemMode') === 'Open';
    }).property('options.systemMode'),

    isAdminOnly: (function () {
      return this.get('options.systemMode') === 'AdminOnly';
    }).property('options.systemMode'),

    isClosed: (function () {
      return this.get('options.systemMode') === 'Closed';
    }).property('options.systemMode')
  });
});