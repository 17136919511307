define('scientia-resourcebooker/mixins/authenticated-controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    isSidebarOpen: false,
    confirmLogout: false,
    session: _ember['default'].inject.service(),
    notificationsCountFetcher: _ember['default'].inject.service(),
    notificationsCount: _ember['default'].computed.alias('notificationsCountFetcher.notificationsCount'),
    configFetcher: _ember['default'].inject.service(),

    appName: _ember['default'].computed.alias('configFetcher.appName'),

    notificationsCountFormatted: (function () {
      if (!this.get('shouldShowNotificationsForMenuItem')) return 0;

      var notificationsCount = this.get('notificationsCount');
      if (notificationsCount >= 100) return '100+';
      return notificationsCount;
    }).property('notificationsCount'),

    shouldShowNotificationsForMenuItem: _ember['default'].computed(function () {
      return this.get('notificationsCountFetcher').shouldFetchForMenuItem();
    }),

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('isSidebarOpen');
      }
    }
  });
});