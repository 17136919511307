define("scientia-resourcebooker/pods/components/resource-filters/number/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/number/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "tabindex", "0");
          dom.setAttribute(el1, "class", "resourceFilters-helpText");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "property.helpText", ["loc", [null, [4, 56], [4, 77]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 195
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/number/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--link button--icon");
          var el2 = dom.createTextNode("✕");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'aria-label');
          morphs[1] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.resource_filters.number.minimum_clear"], [], ["loc", [null, [16, 127], [16, 183]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "min", ["loc", [null, [16, 47], [16, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 42], [16, 51]]], 0, 0], null], [], ["loc", [null, [16, 34], [16, 57]]], 0, 0]], [], ["loc", [null, [16, 25], [16, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 6
            },
            "end": {
              "line": 26,
              "column": 195
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-filters/number/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "button button--link button--icon");
          var el2 = dom.createTextNode("✕");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'aria-label');
          morphs[1] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.resource_filters.number.maximum_clear"], [], ["loc", [null, [26, 127], [26, 183]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "max", ["loc", [null, [26, 47], [26, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 42], [26, 51]]], 0, 0], null], [], ["loc", [null, [26, 34], [26, 57]]], 0, 0]], [], ["loc", [null, [26, 25], [26, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-filters/number/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "resourceFilters-subtitle");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "resourceFilters-fields");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "resourceFilters-label resourceFilters-label--inlineItems");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "resourceFilters-labelText");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "resourceFilter-number-wrapper");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "number");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "resourceFilters-label resourceFilters-label--inlineItems");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "resourceFilters-labelText");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "resourceFilter-number-wrapper");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "number");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [4]);
        var element5 = dom.childAt(element4, [2]);
        var element6 = dom.childAt(element2, [5]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element7, [2]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[3] = dom.createAttrMorph(element5, 'value');
        morphs[4] = dom.createAttrMorph(element5, 'oninput');
        morphs[5] = dom.createAttrMorph(element5, 'class');
        morphs[6] = dom.createMorphAt(element4, 4, 4);
        morphs[7] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
        morphs[8] = dom.createAttrMorph(element8, 'value');
        morphs[9] = dom.createAttrMorph(element8, 'oninput');
        morphs[10] = dom.createAttrMorph(element8, 'class');
        morphs[11] = dom.createMorphAt(element7, 4, 4);
        return morphs;
      },
      statements: [["content", "property.computedDisplayName", ["loc", [null, [1, 50], [1, 82]]], 0, 0, 0, 0], ["block", "if", [["get", "property.helpText", ["loc", [null, [3, 8], [3, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 9]]]], ["inline", "t", ["components.resource_filters.number.minimum"], [], ["loc", [null, [9, 44], [9, 94]]], 0, 0], ["attribute", "value", ["get", "min", ["loc", [null, [14, 35], [14, 38]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", ["onMinChange"], ["value", "target.value"], ["loc", [null, [null, null], [14, 94]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["form-input resourceFilters-input resource-filter-input--number ", ["subexpr", "if", [["get", "min", ["loc", [null, [14, 170], [14, 173]]], 0, 0, 0, 0], "resource-filter-input--number--hasClearButton", ""], [], ["loc", [null, [14, 165], [14, 226]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "min", ["loc", [null, [16, 12], [16, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 6], [16, 202]]]], ["inline", "t", ["components.resource_filters.number.maximum"], [], ["loc", [null, [21, 44], [21, 94]]], 0, 0], ["attribute", "value", ["get", "max", ["loc", [null, [24, 35], [24, 38]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", ["onMaxChange"], ["value", "target.value"], ["loc", [null, [null, null], [24, 94]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["form-input resourceFilters-input resource-filter-input--number ", ["subexpr", "if", [["get", "max", ["loc", [null, [24, 170], [24, 173]]], 0, 0, 0, 0], "resource-filter-input--number--hasClearButton", ""], [], ["loc", [null, [24, 165], [24, 226]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "max", ["loc", [null, [26, 12], [26, 15]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [26, 6], [26, 202]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});