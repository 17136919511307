define('scientia-resourcebooker/helpers/notification-type-resolver', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service(),

    compute: function compute(params) {

      var types = [{ value: 32, name: this.get('i18n').t('notification_types.more_info') }, { value: 2, name: this.get('i18n').t('notification_types.requested') }, { value: 8, name: this.get('i18n').t('notification_types.accepted') }, { value: 16, name: this.get('i18n').t('notification_types.rejected') }, { value: 1, name: this.get('i18n').t('notification_types.cancelled') }];
      var type = types.findBy('value', params[0]);
      return type.name.toString();
    }
  });
});