define('scientia-resourcebooker/serializers/booking-request', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _ember, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      occurrences: { embedded: 'always' }
    },

    normalize: function normalize(type, payload, prop) {

      payload = this.store.serializerFor('application')._camelizeKeys(payload);
      payload = this._camelizeBookingFormValues(payload);
      payload = this._camelizeOccurrences(payload);
      payload = this._normalizeResources(payload);

      return this._super(type, payload, prop);
    },

    _camelizeBookingFormValues: function _camelizeBookingFormValues(payload) {
      if (payload.bookingRequestFormValue && payload.bookingRequestFormValue.sections) {
        payload.bookingRequestFormValue.sections = this._camelizeKeysDeep(payload.bookingRequestFormValue.sections);
      }

      if (!_ember['default'].isEmpty(payload.editRestrictions)) {
        payload.editRestrictions = this._camelizeKeysDeep(payload.editRestrictions);
      }

      return payload;
    },

    _normalizeResources: function _normalizeResources(payload) {
      var _this = this;

      payload.resources = this._camelizeKeysDeep(payload.resources);

      payload.resources = payload.resources.map(function (r) {
        r = _this._identitiesToIds(r);
        return _ember['default'].Object.create(r);
      });

      // Move primary resource to the top if necessary
      if (payload.resources.length > 1) {
        var primaryIndex = payload.resources.findIndex(function (r) {
          return r.isPrimary;
        });
        if (primaryIndex > 0) {
          var primary = payload.resources.splice(primaryIndex, 1);
          payload.resources.unshift(primary[0]);
        }
      }

      if (payload.refittedRestrictionsResources) {
        payload.refittedRestrictionsResources = this._camelizeKeysDeep(payload.refittedRestrictionsResources);
        payload.refittedRestrictionsResources = payload.refittedRestrictionsResources.map(function (r) {
          r = _this._identitiesToIds(r);
          return _ember['default'].Object.create(r);
        });
      }

      return payload;
    },

    _camelizeOccurrences: function _camelizeOccurrences(payload) {
      if (payload.occurrences) {
        payload.occurrences = this._camelizeKeysDeep(payload.occurrences);
      }

      return payload;
    },

    _identitiesToIds: function _identitiesToIds(payload) {
      var newObjectType = _ember['default'].typeOf(payload) === 'object' ? {} : [];
      payload = Object.keys(payload).reduce(function (memo, key) {

        if (key === 'identity') memo.id = payload[key];

        if (key.endsWith('Identities')) {
          memo[key.slice(0, -10).pluralize()] = payload[key];
        } else if (key.endsWith('Identity') && key.length > 8) {
          var newKey = key.slice(0, -8);
          memo[newKey + 'Id'] = payload[key];
          memo[newKey] = payload[key];
        } else {
          memo[key] = payload[key];
        }

        return memo;
      }, newObjectType);

      return payload;
    },

    _camelizeKeysDeep: function _camelizeKeysDeep(object) {
      var _this2 = this;

      var newObjectType = _ember['default'].typeOf(object) === 'object' ? {} : [];

      return Object.keys(object).reduce(function (memo, key) {
        if (_ember['default'].typeOf(object[key]) === 'object' && !object[key]._isAMomentObject) {
          memo[key.camelize()] = _this2._camelizeKeysDeep(object[key]);
        } else if (_ember['default'].typeOf(object[key]) === 'array') {
          object[key] = _this2._camelizeKeysDeep(object[key]);
          memo[key.camelize()] = object[key];
        } else {
          memo[key.camelize()] = object[key];
        }

        return memo;
      }, newObjectType);
    }

  });
});