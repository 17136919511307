define('scientia-resourcebooker/pods/manage-resources/booking-type/bookings-importer/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    can: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_type.bookings_importer.page_title');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        bookingType: this.get('store').findRecord('booking-type', params.id, { reload: true })
      });
    },

    afterModel: function afterModel(model) {
      if (!this.get('can').can('import-bookings booking-type', { model: model.bookingType })) {
        this.transitionTo('manage-resources.booking-type.index');
        return;
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.send('resetControllerProperties', controller);
        this.get('session').restoreAutoLogout();
      }
    },

    actions: {
      resetControllerProperties: function resetControllerProperties(controller) {
        if (!controller) controller = this.controllerFor(this.routeName);
        controller.setProperties({
          importExportSelectedTab: 0
        });
      }
    }

  });
});