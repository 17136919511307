define('scientia-resourcebooker/serializers/application', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _emberData, _scientiaResourcebookerConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // List of all Edit Permissions types. Their array index is their enum value in the API
  var EDIT_PERMISSION_TYPES = ['all', 'status', 'availability', 'resource-restrictions', 'permissions', 'rules', 'description', 'custom-fields', 'resources', 'notifications', 'calendar'];

  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeArrayResponse: function normalizeArrayResponse(store, type, payload, id, requestType) {
      var newPayload = {};
      payload = this._camelizeKeys(payload);
      var typeKey = type.modelName.camelize().pluralize();

      if (_ember['default'].typeOf(payload) === 'object' && _ember['default'].isArray(payload.results)) {
        newPayload[typeKey] = payload.results;
        newPayload.count = payload.count;
        newPayload.totalPages = payload.totalPages;
        newPayload.currentPage = payload.currentPage;
      } else {
        newPayload[typeKey] = payload;
      }

      // newPayload[typeKey] = payload;

      var reply = this._super(store, type, newPayload, id, requestType);
      return reply;
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload, recordId) {
      var newPayload = {};
      var typeKey = type.modelName.camelize();
      newPayload[typeKey] = payload;
      var reply = this._super(store, type, newPayload, recordId || payload.Identity);
      // reply.data.id = recordId;
      return reply;
    },

    extractMeta: function extractMeta(store, type, payload) {
      var meta = {};

      if (payload.currentPage) {
        meta = { currentPage: payload.currentPage, totalPages: payload.totalPages, count: payload.count };
        delete payload.currentPage;
        delete payload.totalPages;
        delete payload.count;
      }

      return meta;
    },

    extractId: function extractId(modelClass, resourceHash) {
      return resourceHash.identity || resourceHash.Identity || resourceHash.id || resourceHash.Id;
    },

    extractAttributes: function extractAttributes(modelClass, resourceHash) {

      resourceHash = this._camelizeKeys(resourceHash);
      var attributes = {};

      modelClass.eachAttribute(function (key) {
        if (resourceHash.hasOwnProperty(key)) {
          attributes[key] = resourceHash[key];
        }
      });

      return attributes;
    },

    normalize: function normalize(type, payload, prop) {

      payload.id = payload.Identity || payload.identity;

      payload = Object.keys(payload).reduce(function (memo, key) {
        if (key.endsWith('Identities')) {
          memo[key.slice(0, -10).pluralize()] = payload[key];
        } else if (key.endsWith('Identity') && key.length > 8) {
          var newKey = key.slice(0, -8);
          memo[newKey + 'Id'] = payload[key];
          memo[newKey] = payload[key];
        } else {
          memo[key] = payload[key];
        }

        return memo;
      }, {});

      payload = this._camelizeKeys(payload);
      payload = this._addNestedResourcesLinks(type, payload);

      var reply = this._super(type, payload, prop);

      // if (!reply.data.id) reply.data.id = payload.identity;
      return reply;
    },

    _camelizeKeys: function _camelizeKeys(object) {
      var _this = this;

      var newObjectType = _ember['default'].typeOf(object) === 'object' ? {} : [];

      return Object.keys(object).reduce(function (memo, key) {
        if (_ember['default'].typeOf(object[key]) === 'object' && !object[key]._isAMomentObject) {
          memo[key.camelize()] = _this._camelizeKeys(object[key]);
        } else {
          memo[key.camelize()] = object[key];
        }

        return memo;
      }, newObjectType);
    },

    _camelizeKeysDeep: function _camelizeKeysDeep(object) {
      var _this2 = this;

      var newObjectType = _ember['default'].typeOf(object) === 'object' ? {} : [];

      return Object.keys(object).reduce(function (memo, key) {
        if (_ember['default'].typeOf(object[key]) === 'object' && !object[key]._isAMomentObject) {
          memo[key.camelize()] = _this2._camelizeKeysDeep(object[key]);
        } else if (_ember['default'].typeOf(object[key]) === 'array') {
          object[key] = _this2._camelizeKeysDeep(object[key]);
          memo[key.camelize()] = object[key];
        } else {
          memo[key.camelize()] = object[key];
        }

        return memo;
      }, newObjectType);
    },

    _addNestedResourcesLinks: function _addNestedResourcesLinks(type, payload) {
      var nestedResourcesURLs = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS[type.modelName.camelize()];
      var newPayload = undefined;

      if (nestedResourcesURLs) {
        payload.links = {};

        newPayload = Object.keys(nestedResourcesURLs).reduce(function (memo, resourceName) {
          payload.links[resourceName] = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + nestedResourcesURLs[resourceName.singularize()].loc(payload.id);
          return payload;
        }, payload);
      }

      return newPayload || payload;
    },

    /**
     * Simply sets any permissionGroup to an empty array if it's NULL
     * @param {*} payload
     */
    _setPermissionGroupDefaults: function _setPermissionGroupDefaults(payload) {
      Object.keys(payload).forEach(function (k) {
        if (k.endsWith('PermissionGroups') || k.endsWith('Permissions')) {
          if (payload[k] === null) payload[k] = [];
        }
      });

      return payload;
    },

    /**
     * Normalize nested editPermissions from the API into root level permissions
     * @param {*} payload
     * @param {*} editPermissions
     */
    _normalizeNestedPermissionGroups: function _normalizeNestedPermissionGroups(payload, permissionNames, propertyKeyName) {
      var permissionNameTemplate = arguments.length <= 3 || arguments[3] === undefined ? '%@' : arguments[3];

      var permissionsData = payload[propertyKeyName];
      if (_ember['default'].isEmpty(permissionsData)) {
        permissionsData = [];
        return payload;
      }

      permissionNames.forEach(function (p) {
        var key = p;
        var keyNormalized = permissionNameTemplate.loc(key.classify());

        // Get all named objects matching the current permissionType
        var permData = permissionsData.filter(function (x) {
          return x.editPermissionType === EDIT_PERMISSION_TYPES.indexOf(key.dasherize());
        });

        if (permData) {
          payload[keyNormalized] = permData;
        } else {
          payload[keyNormalized] = [];
        }
      });

      delete payload[propertyKeyName];
      return payload;
    },

    serialize: function serialize(snapshot, options) {
      var serialized = this._super(snapshot, options);

      if (snapshot.id) serialized.Identity = snapshot.id;

      // Object.keys(serialized).forEach(key => {
      //   if (key.endsWith('Id') && key.length > 2) {
      //     let newKey = key.slice(0, -2);
      //     serialized[`${newKey}Identity`] = serialized[key];
      //   }
      // });

      return serialized;
    },

    /**
     * Serialize root level permissions to nested level to match API model
     * @param {*} payload
     * @param {*} editPermissions
     */
    _serializeNestedPermissionGroups: function _serializeNestedPermissionGroups(payload, permissionNames, propertyKeyName) {
      var permissionNameTemplate = arguments.length <= 3 || arguments[3] === undefined ? '%@' : arguments[3];

      payload[propertyKeyName] = [];
      permissionNames.forEach(function (p) {
        var _payload$propertyKeyName;

        var key = p.classify();
        var keyNormalized = permissionNameTemplate.loc(key);

        var permissionEditTypeEnum = EDIT_PERMISSION_TYPES.indexOf(p.dasherize());
        var permissionData = payload[keyNormalized];
        permissionData.forEach(function (perm) {
          return perm.editPermissionType = permissionEditTypeEnum;
        });

        (_payload$propertyKeyName = payload[propertyKeyName]).push.apply(_payload$propertyKeyName, _toConsumableArray(permissionData));
        delete payload[keyNormalized];
      });

      return payload;
    },

    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var serializedSnapshot = this.serialize(snapshot, options);

      Object.keys(serializedSnapshot).forEach(function (key) {
        hash[key] = serializedSnapshot[key];
      });
    }
  });
});