define('scientia-resourcebooker/services/event-occurrences-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(eventId) {
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.EVENTS.loc(eventId);
      return this.get('authenticatedRequest').fetch(url);
    },

    fetchSchedules: function fetchSchedules(eventId) {
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.EVENTS.loc(eventId);
      return this.get('authenticatedRequest').fetch(url).then(this._normalizeScheduleResults.bind(this));
    },

    _normalizeScheduleResults: function _normalizeScheduleResults(event) {
      if (!event || !event.schedule) return [];

      return event.schedule.map(function (schedule) {
        schedule.startDateTime = moment(schedule.startDateTime);
        schedule.endDateTime = moment(schedule.startDateTime).add(schedule.duration, 'minute');
        return schedule;
      });
    }
  });
});