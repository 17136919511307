define("scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "toggle-switch", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [10, 31], [10, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [10, 48], [10, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [10, 76], [10, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 71], [10, 82]]], 0, 0]], [], ["loc", [null, [10, 63], [10, 83]]], 0, 0], "location", "at-importerOption"], ["loc", [null, [10, 6], [10, 114]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "toggle-button-group", [], ["content", ["subexpr", "@mut", [["get", "translatedOptions", ["loc", [null, [15, 16], [15, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "value", ["loc", [null, [16, 17], [16, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [17, 17], [17, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["toggleValue"], [], ["loc", [null, [18, 15], [18, 37]]], 0, 0], "class", "at-bulUploader"], ["loc", [null, [14, 6], [19, 32]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 12
              },
              "end": {
                "line": 36,
                "column": 12
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "bulkImporterUploader-fileName");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "bulkImporterUploader-fileSize");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("KB");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "button");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [5, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            morphs[2] = dom.createElementMorph(element0);
            morphs[3] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["inline", "truncate", [["get", "file.name", ["loc", [null, [31, 67], [31, 76]]], 0, 0, 0, 0], 30], [], ["loc", [null, [31, 56], [31, 81]]], 0, 0], ["content", "file.sizeKB", ["loc", [null, [32, 56], [32, 71]]], 0, 0, 0, 0], ["element", "action", ["removeFile"], [], ["loc", [null, [34, 22], [34, 45]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [34, 81], [34, 93]]], 0, 0, 0, 0], ["get", "optionId", ["loc", [null, [34, 94], [34, 102]]], 0, 0, 0, 0], "_change_file"], [], ["loc", [null, [34, 65], [34, 118]]], 0, 0]], [], ["loc", [null, [34, 61], [34, 120]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 12
              },
              "end": {
                "line": 40,
                "column": 12
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "button button--white");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [37, 72], [37, 84]]], 0, 0, 0, 0], ["get", "optionId", ["loc", [null, [37, 85], [37, 93]]], 0, 0, 0, 0], "_choose_file"], [], ["loc", [null, [37, 56], [37, 109]]], 0, 0]], [], ["loc", [null, [37, 52], [37, 111]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [38, 38], [38, 50]]], 0, 0, 0, 0], "or_drag_drop"], [], ["loc", [null, [38, 22], [38, 66]]], 0, 0]], [], ["loc", [null, [38, 18], [38, 68]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-item--content");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "bulkImporterUploader-file-dropzone");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bulkImporterUploader-dragSupportMessage");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element3, 1, 1);
          morphs[2] = dom.createMorphAt(element3, 3, 3);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["bulkImporterUploader-fileWrapper ", ["subexpr", "if", [["get", "file", ["loc", [null, [25, 58], [25, 62]]], 0, 0, 0, 0], "bulkImporterUploader-fileWrapper--hasFile", ""], [], ["loc", [null, [25, 53], [25, 111]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/upload.svg"], ["class", "at-bulkImporterUploader-dropzone"], ["loc", [null, [28, 12], [28, 86]]], 0, 0], ["block", "if", [["get", "file", ["loc", [null, [30, 18], [30, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 12], [40, 19]]]], ["inline", "file-upload", [], ["accept", ".csv", "aria-label", ["subexpr", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [45, 40], [45, 52]]], 0, 0, 0, 0], ["get", "optionId", ["loc", [null, [45, 53], [45, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 24], [45, 62]]], 0, 0]], [], ["loc", [null, [45, 21], [45, 63]]], 0, 0], "uploadOnSelect", false, "filesDidChange", ["subexpr", "action", ["onFileChanged"], [], ["loc", [null, [47, 25], [47, 49]]], 0, 0], "class", "form-input"], ["loc", [null, [43, 10], [48, 30]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [51, 82], [51, 94]]], 0, 0, 0, 0], "drag_support"], [], ["loc", [null, [51, 66], [51, 110]]], 0, 0]], [], ["loc", [null, [51, 62], [51, 112]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-option/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label-help");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item--content");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [1]);
        var element5 = dom.childAt(fragment, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element5, 1, 1);
        morphs[3] = dom.createMorphAt(element5, 3, 3);
        morphs[4] = dom.createMorphAt(element5, 5, 5);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [2, 24], [2, 36]]], 0, 0, 0, 0], ["get", "optionId", ["loc", [null, [2, 37], [2, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 8], [2, 46]]], 0, 0]], [], ["loc", [null, [2, 4], [2, 48]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [4, 26], [4, 38]]], 0, 0, 0, 0], ["get", "optionId", ["loc", [null, [4, 39], [4, 47]]], 0, 0, 0, 0], "_description"], [], ["loc", [null, [4, 10], [4, 63]]], 0, 0]], [], ["loc", [null, [4, 6], [4, 65]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [9, 14], [9, 18]]], 0, 0, 0, 0], "boolean"], [], ["loc", [null, [9, 10], [9, 29]]], 0, 0]], [], 0, null, ["loc", [null, [9, 4], [11, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [13, 14], [13, 18]]], 0, 0, 0, 0], "toggle"], [], ["loc", [null, [13, 10], [13, 28]]], 0, 0]], [], 1, null, ["loc", [null, [13, 4], [20, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [22, 14], [22, 18]]], 0, 0, 0, 0], "file"], [], ["loc", [null, [22, 10], [22, 26]]], 0, 0]], [], 2, null, ["loc", [null, [22, 4], [53, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});