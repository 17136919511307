define('scientia-resourcebooker/pods/logged-in/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/authenticated-route', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsAuthenticatedRoute, _emberCan) {

  var ROUTE_RESOURCE = 'logged-in.resource.show';
  var ROUTE_BOOKING_TYPE = 'logged-in.booking-type.show';
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAuthenticatedRoute['default'], _emberCan.CanMixin, {

    systemSettings: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    currentUserRolesFetcher: _ember['default'].inject.service(),
    bookingRequestClashChecker: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super();
      this.get('systemSettings').fetch();
      return this.get('currentUserFetcher').fetch().then(function (user) {

        if (_ember['default'].isEmpty(user.name) || _ember['default'].isEmpty(user.nameIdentifier) || _ember['default'].isEmpty(user.email)) {
          _this.get('session').logout();
          return;
        }

        _this.get('currentUser').setUser(user);
        _this.get('bookingRequestClashChecker').start();
      });
    },

    // Load user admin roles asynchronously
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      this.get('currentUserRolesFetcher').fetch();
    },

    // // Preload all booking types if not loaded already
    // afterModel() {
    //   this._super(...arguments);

    //   const bookingTypeController = this.controllerFor('logged-in.booking-type.show');
    //   if (bookingTypeController.get('model.bookingTypes')) return;
    //   this.store.findAll('booking-type').then(bookingTypes => {
    //     bookingTypeController.set('model', {bookingTypes});
    //   });
    // },

    resetProperties: function resetProperties() {
      this.controller.setProperties({
        showBookingTypesModal: false,
        bookingTypesAvailableForResourceSearching: null,
        globalSearchResourceClicked: null,
        globalSearchResourceClickedType: null
      });
    },

    actions: {

      clearBookingTypesModal: function clearBookingTypesModal() {
        this.resetProperties();
      },

      // When we click "OK" on the Confirm transition to a disabled booking-type
      // we check if we have clicked on any resource from the global-search and if so, if that resource is a group
      // we then set the relevant queryParams and transition to the correct route
      onConfirmTransition: function onConfirmTransition() {
        var resourceClicked = this.controller.get('globalSearchResourceClicked');
        var resourceClickedIsGroup = this.controller.get('globalSearchResourceClickedType') === 'resource-group';
        var bookingType = this.controller.get('showDisabledBookingTypeModal');

        var route = resourceClicked && !resourceClickedIsGroup ? ROUTE_RESOURCE : ROUTE_BOOKING_TYPE;
        var queryParams = { queryParams: {} };
        if (resourceClickedIsGroup) {
          queryParams.queryParams.group = resourceClicked.id;
        } else if (!resourceClicked) {
          queryParams.queryParams.group = '';
        }

        if (resourceClickedIsGroup || !resourceClicked) {
          this.transitionTo(route, bookingType.id, queryParams);
        } else {
          this.transitionTo(route, bookingType.id, resourceClicked.id);
        }

        this.controller.setProperties({
          showBookingTypesModal: false,
          showDisabledBookingTypeModal: false,
          globalSearchResourceClicked: null,
          globalSearchResourceClickedType: 'resource'
        });
      },

      onCancelTransition: function onCancelTransition() {
        this.controller.send('onCloseDisabledResourceModal');
      },

      onClickBookingType: function onClickBookingType(bookingType) {
        if (!bookingType.get('isDisabled')) {
          this.send('transitionToBookingType', bookingType);
        } else {
          this.controller.send('showDisabledBookingTypeModal', bookingType);
        }
      },

      transitionToBookingType: function transitionToBookingType(bookingType) {
        var resourceClicked = this.controller.get('globalSearchResourceClicked');
        var resourceClickedIsGroup = this.controller.get('globalSearchResourceClickedType') === 'resource-group';
        var queryParams = { queryParams: { group: resourceClickedIsGroup ? resourceClicked.id : '' } };

        var transitionToResource = resourceClicked && this.controller.get('globalSearchResourceClickedType') === 'resource';
        if (transitionToResource) {
          this.transitionTo('logged-in.resource.show', bookingType.id, resourceClicked.id);
        } else {
          this.transitionTo('logged-in.booking-type.show', bookingType.id, queryParams);
        }

        this.controller.setProperties({
          showBookingTypesModal: false,
          showDisabledBookingTypeModal: false,
          globalSearchResourceClicked: null,
          globalSearchResourceClickedType: 'resource'
        });
      },

      transitionToSingleBookingTypeResource: function transitionToSingleBookingTypeResource(bookingType, resource) {
        this.transitionTo('logged-in.resource.show', bookingType.id, resource.id);
        this.setProperties({
          globalSearchResourceClicked: null,
          globalSearchResourceClickedType: null
        });
      },

      transitionToSingleBookingTypeResourceGroup: function transitionToSingleBookingTypeResourceGroup(bookingType, resourceGroup) {
        var queryParams = { queryParams: { group: resourceGroup.id } };
        this.transitionTo('logged-in.booking-type.show', bookingType.id, queryParams);
        this.setProperties({
          globalSearchResourceClicked: null,
          globalSearchResourceClickedType: null
        });
      },

      onClickGlobalSearchResource: function onClickGlobalSearchResource(bookingTypesAvailable, resource) {
        var type = arguments.length <= 2 || arguments[2] === undefined ? 'resource' : arguments[2];

        // Save the item we clicked on and its type
        this.controller.setProperties({
          globalSearchResourceClicked: resource,
          globalSearchResourceClickedType: type
        });

        if (bookingTypesAvailable.length === 1) {

          if (type === 'resource') {
            this.send('transitionToSingleBookingTypeResource', bookingTypesAvailable[0], resource);
          } else if (type === 'resource-group') {
            this.send('transitionToSingleBookingTypeResourceGroup', bookingTypesAvailable[0], resource);
          } else {
            this.send('onClickBookingType', bookingTypesAvailable[0]);
          }
        } else {

          // If there are more than 1 booking-type then show selection modal
          this.controller.setProperties({
            bookingTypesAvailableForResourceSearching: bookingTypesAvailable,
            showBookingTypesModal: true
          });
        }
      },

      resetController: function resetController(controller, isExiting) {
        if (isExiting) {
          controller.setProperties({
            bookingTypesAvailableForResourceSearching: null,
            showDisabledBookingTypeModal: null,
            showBookingTypesModal: false,
            globalSearchResourceClicked: null,
            globalSearchResourceClickedType: null
          });
        }
      },

      /** Error handler for ember-route-action */
      save: function save() {
        // no-op
      },

      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        // If leaving "logged-in" routes (into admin) unload all store
        if (transition.targetName.indexOf('logged-in') !== 0) {
          this.store.unloadAll();
        }

        return true;
      }
    }

  });
});