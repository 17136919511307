define('scientia-resourcebooker/serializers/booking-form-field-attachment', ['exports', 'ember', 'ember-data/serializers/json', 'ember-data'], function (exports, _ember, _emberDataSerializersJson, _emberData) {
  exports['default'] = _emberDataSerializersJson['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {

    normalize: function normalize() /*type, payload, prop*/{
      this._super.apply(this, arguments);
    },

    serialize: function serialize(snapshot) {
      var _store$serializerFor;

      var record = snapshot.record;
      var customAttachmentTypes = record.get('customAttachmentTypes');
      if (!_ember['default'].isEmpty(customAttachmentTypes)) {
        customAttachmentTypes = customAttachmentTypes.replace(/ /g, '').replace(/\./g, '');
        record.set('customAttachmentTypes', customAttachmentTypes);
      }
      return (_store$serializerFor = this.store.serializerFor('booking-form-field')).serialize.apply(_store$serializerFor, arguments);
    }

  });
});