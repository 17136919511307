define('scientia-resourcebooker/services/profile-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    fetch: function fetch() {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.USER_PROFILE);
    },

    save: function save(userProfile) {
      if (!userProfile) {
        userProfile = this.get('currentUser').getUser();
      }

      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.USER_PROFILE, userProfile);
    },

    setLanguage: function setLanguage(language) {
      this.set('currentUser.user.userLanguage', language);
    }

  });
});