define('scientia-resourcebooker/models/user-group', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    claims: (0, _modelFragmentsAttributes.fragmentArray)('claim', { defaultValue: [] }),
    editClaimsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-claims-permission-group', { defaultValue: [] }),
    isAdminGroup: attr('boolean'),
    isSystemGroup: attr('boolean'),
    canEditClaims: attr('boolean'),
    scopes: attr('raw'),
    permissionsKeys: ['edit-claims']
  });
});