define("scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "button button--white at-manageResources-itemForm-form-delete");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element6, 'aria-label');
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(element6, 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.delete"], [], ["loc", [null, [17, 24], [17, 46]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["delete", ["get", "index", ["loc", [null, [17, 66], [17, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 48], [17, 73]]], 0, 0], ["inline", "inline-svg", ["icons/trash.svg"], ["class", "manageResources-itemForm-form-item-deleteIcon"], ["loc", [null, [17, 143], [17, 229]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "manageResources-itemForm-form-details-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-details-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.resource_restrictions_form.name"], [], ["loc", [null, [25, 71], [25, 138]]], 0, 0], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [26, 25], [26, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "rule.name", ["loc", [null, [26, 40], [26, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input manageResources-itemForm-form-item-details-input"], ["loc", [null, [26, 8], [26, 119]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 6
              },
              "end": {
                "line": 57,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "manageResources-itemForm-form-details-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-details-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "manageResources-itemForm-form-details-item");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-item-details-label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.resource_restrictions_form.min"], [], ["loc", [null, [49, 71], [49, 137]]], 0, 0], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [50, 25], [50, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "rule.min", ["loc", [null, [50, 40], [50, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input manageResources-itemForm-form-item-details-input"], ["loc", [null, [50, 8], [50, 118]]], 0, 0], ["inline", "t", ["components.manage_resources.resource_restrictions_form.max"], [], ["loc", [null, [54, 71], [54, 137]]], 0, 0], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [55, 25], [55, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "rule.max", ["loc", [null, [55, 40], [55, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input manageResources-itemForm-form-item-details-input"], ["loc", [null, [55, 8], [55, 118]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-item-details");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-details-item");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "manageResources-itemForm-form-item-details-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-details-item manageResources-itemForm-form-details-item--setPrimary");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "manageResources-itemForm-form-item-details-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element3, [7]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element4, 3, 3);
          morphs[3] = dom.createMorphAt(element3, 5, 5);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
          morphs[5] = dom.createMorphAt(element5, 3, 3);
          return morphs;
        },
        statements: [["block", "unless", [["get", "rule.isPrimary", ["loc", [null, [23, 16], [23, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 6], [28, 17]]]], ["inline", "t", ["components.manage_resources.resource_restrictions_form.resource_groups"], [], ["loc", [null, [31, 71], [31, 149]]], 0, 0], ["inline", "select-2", [], ["query", "searchResourceGroup", "value", ["subexpr", "@mut", [["get", "rule.resourceGroups", ["loc", [null, [34, 16], [34, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "didSelect", ["subexpr", "action", ["didSelectResourceGroups"], [], ["loc", [null, [35, 20], [35, 54]]], 0, 0], "multiple", true, "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [37, 23], [37, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 18], [37, 32]]], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.resource_restrictions_form.resource_group_optional"], [], ["loc", [null, [38, 22], [38, 106]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "class", "manageResources-itemForm-form-input manageResources-itemForm-form-input--rule"], ["loc", [null, [32, 8], [44, 10]]], 0, 0], ["block", "unless", [["get", "rule.isPrimary", ["loc", [null, [47, 16], [47, 30]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [47, 6], [57, 17]]]], ["inline", "t", ["components.manage_resources.resource_restrictions_form.is_primary"], [], ["loc", [null, [59, 71], [59, 144]]], 0, 0], ["inline", "custom-checkbox", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [60, 35], [60, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "@mut", [["get", "rule.isPrimary", ["loc", [null, [60, 52], [60, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "onSetPrimary"], ["loc", [null, [60, 8], [60, 90]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--withDetails");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(element7, 3, 3);
        morphs[2] = dom.createMorphAt(element7, 5, 5);
        morphs[3] = dom.createMorphAt(element7, 7, 7);
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.resource_restrictions_form.resource_type"], [], ["loc", [null, [3, 4], [3, 80]]], 0, 0], ["inline", "select-2", [], ["value", ["subexpr", "@mut", [["get", "rule.resourceType", ["loc", [null, [7, 10], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "and", [["subexpr", "not", [["get", "disabled", ["loc", [null, [8, 22], [8, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 17], [8, 31]]], 0, 0], ["subexpr", "not", [["get", "rule.id", ["loc", [null, [8, 37], [8, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 32], [8, 45]]], 0, 0]], [], ["loc", [null, [8, 12], [8, 46]]], 0, 0], "optionIdPath", "id", "optionLabelPath", "name", "query", "searchResourceType", "class", "manageResources-itemForm-form-input manageResources-itemForm-form-input--rule at-manageResources-itemForm-rule"], ["loc", [null, [6, 2], [14, 4]]], 0, 0], ["block", "unless", [["get", "disabled", ["loc", [null, [16, 12], [16, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 2], [18, 13]]]], ["block", "if", [["get", "hasResourceType", ["loc", [null, [20, 8], [20, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 2], [64, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});