define('scientia-resourcebooker/abilities/resource-group', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canEditAll: _ember['default'].computed.alias('model.canEditAll'),
    canEditPermissions: _ember['default'].computed.alias('model.canEditPermissions'),
    canEditDescription: _ember['default'].computed.alias('model.canEditDescription'),
    canEditResources: _ember['default'].computed.alias('model.canEditResources'),

    canAddResources: _ember['default'].computed('model.canAddResources', 'checkedResources.@each.id', function () {
      var selectedIds = this.get('checkedResources').getEach('id');
      var groupResourceIds = (this.get('model.resourceIds') || []).getEach('id');
      var allIds = [];
      allIds.push.apply(allIds, _toConsumableArray(selectedIds).concat(_toConsumableArray(groupResourceIds)));

      return this.get('model.canAddResources') && allIds.uniq().length > groupResourceIds.length;
    }),

    canRemoveResources: _ember['default'].computed.alias('model.canAddResources'),

    canDelete: _ember['default'].computed('model.hasAssociatedBookingTypes', 'model.canDelete', function () {
      return !this.get('model.hasAssociatedBookingTypes') && this.get('model.canDelete');
    })

  });
});