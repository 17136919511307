define('scientia-resourcebooker/pods/manage-resources/availability/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.availability.new.page_title');
    },

    errorHandler: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        availabilityPattern: this.store.createRecord('availability')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: '',
          isSaving: false
        });

        var model = this.modelFor(this.routeName);
        if (model.availabilityPattern.get('hasDirtyAttributes')) model.availabilityPattern.rollbackAttributes();
      }
    },

    actions: {
      create: function create(model) {
        var _this = this;

        var controller = this.controllerFor(this.routeName);

        var name = model.get('name') || '';
        if (_ember['default'].isEmpty(name.trim())) {
          controller.set('error', this.get('i18n').t('error_messages.availabilitypattern_noname'));
          return;
        }

        controller.set('isSaving', true);
        model.save().then(function (_ref) {
          var id = _ref.id;
          var name = _ref.name;

          _this.createSuccessFlash('availability');
          _this.get('appInsights').trackCreate('availability-pattern', { id: id, name: name });
          return _this.transitionTo('manage-resources.availability.edit', id, { queryParams: { isNew: true } });
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('availability');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      }
    }
  });
});