define('scientia-resourcebooker/pods/components/manage-resources/booking-form-list/component', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _ember['default'].Component.extend(_emberCan.CanMixin, {
    tagName: 'ul',
    classNames: ['manageResourcesItemsList at-bookingFormIndex'],

    items: [],
    showItemPath: '',
    editItemPath: '',
    hideShowAction: false,
    hideDeleteAction: false,
    hideEditAction: false,

    editAbilityString: (function () {
      return 'edit ' + this.get('type');
    }).property(),

    deleteAbilityString: (function () {
      return 'delete ' + this.get('type');
    }).property()
  });
});