define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/singleselection/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item', 'manageResources-itemForm-form-item--topAligned'],

    actions: {
      setChoice: function setChoice(choice) {
        if (this.get('disabled')) return;
        this.set('property.value', [choice]);
      }
    }
  });
});