define('scientia-resourcebooker/pods/components/resources-list/search/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourcesList-search'],

    _updateQuery: function _updateQuery(query) {
      this.set('query', query.target.value);
      this.sendAction('resetLoadMore');
    },

    actions: {
      updateQuery: function updateQuery(query) {
        // this._updateQuery(query);
        _ember['default'].run.debounce(this, '_updateQuery', query, 1000);
      }
    }
  });
});