define('scientia-resourcebooker/pods/print/report/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    printHandler: _ember['default'].inject.service(),
    bookingReportFetcher: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('components.reports.bookings.print_title').toString();
    },

    queryParams: {
      id: { replace: true, refreshModel: false },
      startRange: { replace: true, refreshModel: false },
      endRange: { replace: true, refreshModel: false },
      from: { replace: true, refreshModel: false },
      filterActiveBookings: { replace: true, refreshModel: false }
    },

    model: function model(params) {
      return this._fetchData(params);
    },

    _fetchData: function _fetchData(params) {
      return this.get('bookingReportFetcher').fetchPrintDataByReportId(params.id);
    },

    afterModel: function afterModel(model, transition) {

      this.get('appInsights').trackEvent('report-print');

      _ember['default'].run.next(this, function () {

        if (transition.queryParams.filterActiveBookings) {
          this.controllerFor(this.routeName).set('filterActiveBookings', true);
        }

        this.send('updateHeader', this.get('i18n').t('components.reports.bookings.print_title').toString().toUpperCase());

        var dateFormat = this.get('i18n').t('components.date_period_selector.specific_range_date_format').toString();
        var startRange = moment(transition.queryParams.startRange).format(dateFormat);
        var endRange = moment(transition.queryParams.endRange).format(dateFormat);
        this.send('updateSubheader', startRange + ' - ' + endRange);

        _ember['default'].run.later(this, function () {
          window.print();
        }, 500);
      });
    }
  });
});