define('scientia-resourcebooker/abilities/notifications', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    canAccess: (function () {

      // Nobody can access it
      if (this.get('systemSettings.isClosed')) return false;

      // If mode is Admin-only and you are not a resource Owner(?) you can't access it
      if (this.get('systemSettings.isAdminOnly') && !this.get('currentUser').isResourceOwner()) return false;

      return true;
    }).property('systemSettings.options.systemMode')
  });
});