define('scientia-resourcebooker/pods/manage-resources/availability/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    appInsights: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.availability.index.page_title');
    },

    queryParams: {
      query: { replace: true, refreshModel: true }
    },

    model: function model(params, transition) {
      return this.store.query('availability', { pageNumber: 1, itemsPerPage: 999, query: transition.queryParams.query || '' });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          query: '',
          boundQuery: ''
        });
      }
    },

    actions: {

      deleteModel: function deleteModel(item) {
        var _this = this;

        var id = item.get('id');
        var name = item.get('name');

        item.destroyRecord().then(function () {
          _this.deleteSuccessFlash('availability');
          _this.get('appInsights').trackDelete('availability-pattern', { id: id, name: name, from: 'page-index' });
        })['catch'](function () {
          _this.deleteErrorFlash('availability');
          item.rollbackAttributes();
        })['finally'](function () {
          // no-op
        });
      },

      reload: function reload() {
        this.refresh();
      }
    }
  });
});