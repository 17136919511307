define('scientia-resourcebooker/pods/components/manage-resources/resource-restrictions-form/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-groupWrapper'],

    chosenResourceGroup: '',
    disabled: false,
    test: [],
    // onInit: function() {
    //   Ember.run.schedule('afterRender', this, function() {
    //     this.set('chosenResourceGroup', this.get('rule.resourceGroups').slice());
    //   });
    // }.on('init'),

    // chosenResourceGroupChanged: function() {
    //   Ember.run.next(() => {
    //     let chosenResourceGroup = this.get('chosenResourceGroup');
    //     if (chosenResourceGroup === null) {
    //       this.get('rule.resourceGroups').clear();
    //     } else if (this.get('rule.resourceGroups')) {
    //       this.get('rule.resourceGroups').setObjects(chosenResourceGroup);
    //     }
    //   });
    // }.observes('chosenResourceGroup'),

    // chosenResourceTypeChanged: function() {
    //   Ember.run.next(() => {
    //     if (this.get('isDestroyed')) return;
    //     this.set('chosenResourceGroup', '');
    //   });
    // }.observes('rule.resourceType'),

    // chosenResourceGroups: Ember.computed('rule.resourceGroups@each.identity', function() {
    //   return this.get('rule.resourceGroups').map((r) => {
    //     return {identity: r.id || r.identity, name: r.get('name')};
    //   });
    // }),

    resourceGroupIds: _ember['default'].computed('rule.resourceGroups@each.identity', function () {
      if (_ember['default'].isEmpty(this.get('rule.resourceGroups'))) return [];
      return this.get('rule.resourceGroups').getEach('identity');
    }),

    hasResourceType: (function () {
      return this.get('rule.resourceType.id');
    }).property('rule.resourceType'),

    actions: {
      searchResourceType: function searchResourceType(query, deferred) {
        this.sendAction('onSearchResourceType', query, deferred);
      },

      searchResourceGroup: function searchResourceGroup(query, deferred) {
        this.sendAction('onSearchResourceGroup', this.get('rule'), query, deferred);
      },

      didSelectResourceGroups: function didSelectResourceGroups(resourceGroups) {
        // this.set('test', resourceGroups);
        this.set('rule.resourceGroups', resourceGroups);
      },

      onSetPrimary: function onSetPrimary() {
        if (this.get('disabled')) return false;
        this.set('rule.min', 1);
        this.set('rule.max', 1);
        this.sendAction('onSetPrimary', this.get('rule'));
      },

      'delete': function _delete(item) {
        if (this.get('disabled')) return false;
        this.sendAction('deleteItem', item);
      }
    }
  });
});