define('scientia-resourcebooker/pods/components/recurring-event-fields/daily/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),

    // This is the calendar options setting
    showWeekends: false,

    // This is the user toggle
    excludeWeekends: false,

    init: function init() {
      this._super.apply(this, arguments);
      // Automatically toggle and disable Exclude Weekends checkbox if Calendar Options setting is not enabled
      if (!this.get('showWeekends')) {
        _ember['default'].run.next(this, function () {
          this.send('updateExcludeWeekends', false);
        });
      }
    },

    actions: {
      updateExcludeWeekends: function updateExcludeWeekends(key, value) {
        this.get('updateExcludeWeekends')(!value);
        // this.set('excludeWeekends', !value);
      }
    }

  });
});