define('scientia-resourcebooker/pods/logged-in/booking-type/show/controller', ['exports', 'ember', 'ember-concurrency', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/mixins/resource-filters'], function (exports, _ember, _emberConcurrency, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerMixinsResourceFilters) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], _scientiaResourcebookerMixinsResourceFilters['default'], {

    appInsights: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    bookingTypeResourceSearcher: _ember['default'].inject.service(),
    router: _ember['default'].inject.service(),

    queryParams: ['availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'query', 'editingPrimary', 'bookingId', 'currentResourceId', 'editOrigin', 'isAdminPrimaryResourceSearch', { 'resourceGroupId': 'group' }],

    query: '',
    availableNow: false,
    spanMultipleDays: false,
    firstDay: null,
    lastDay: null,
    fromTime: '',
    toTime: '',
    duration: null,
    resourceGroupId: '',
    currentResourceId: '',

    hideResourceGroupCount: false,
    groupsListExpanded: true,
    editingPrimary: false,
    hasChangedReccurencePatternWhileEditing: false,
    isAdminPrimaryResourceSearch: false,

    // The page where the Edit Primary Resource was originally triggered from (my bookings, resource calendar..)
    editOrigin: null,

    // The booking Id which we are editing the primary resource for
    bookingId: '',

    isResourceGroup: _ember['default'].computed.bool('resourceGroupId'),

    isSearching: false,

    // Holds the booking type Alert and Info messages which will be set when the booking type is loaded
    bookingTypeAlert: null,
    bookingTypeInfo: null,

    bookingTypeAlertIsDismissed: false,
    bookingTypeInfoIsDismissed: false,

    bookingTypeAlertIsPopup: _ember['default'].computed.equal('bookingTypeAlert.type', 1),
    bookingTypeInfoIsPopup: _ember['default'].computed.equal('bookingTypeInfo.type', 1),

    bookingTypeAlertIsBanner: _ember['default'].computed.equal('bookingTypeAlert.type', 0),
    bookingTypeInfoIsBanner: _ember['default'].computed.equal('bookingTypeInfo.type', 0),

    showBookingTypeHelpMessageModal: false,

    calendarOptions: _ember['default'].computed('model.bookingType.id', function () {
      var bt = this.get('model.bookingType');
      if (!bt) return this.get('systemSettings').getCalendarOptions();
      if (bt.get('calendarOptions.useCustomCalendarOptions')) {
        return bt.get('calendarOptions');
      } else {
        return this.get('systemSettings').getCalendarOptions();
      }
    }),

    resourceGroup: _ember['default'].computed('isResourceGroup', 'resourceGroupId', 'model.resourceGroups', 'model.resourceGroups.[]', 'filterResourcesAndGroups.isRunning', function () {
      var _this = this;

      if (!this.get('isResourceGroup')) return null;

      var group = this.get('model.resourceGroups').find(function (x) {
        return x.id === _this.get('resourceGroupId');
      });
      return group;
    }),

    initialLoadSize: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('resourcesListInitialLoadSize');
    }),

    additionalLoadSize: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('resourcesListAdditionalLoadSize');
    }),

    resourcesLastFetchedCount: 0,

    hasMoreResults: _ember['default'].computed('loadedIds.length', 'initialLoadSize', 'additionalLoadSize', 'resourcesLastFetchedCount', function () {

      // If the last fetch count matches the total loadedIds count then we know it's initial fetch
      var isInitialFetch = this.get('loadedIds.length') === this.get('resourcesLastFetchedCount');

      if (isInitialFetch) {
        return this.get('initialLoadSize') === this.get('resourcesLastFetchedCount');
      } else {
        return this.get('additionalLoadSize') === this.get('resourcesLastFetchedCount');
      }
    }),

    loadedIds: [],

    queryParamsChanged: (function () {
      _ember['default'].run.once(this, '_updateCustomFilters');
    }).observes('availableNow', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'query', 'resourceGroupId'),

    actions: {

      loadMore: function loadMore() {
        this.get('filterResourcesAndGroups').perform(this.get('model.bookingType'));
        this.get('appInsights').trackBookingTypeLoadMore(this.get('model.bookingType'), this.get('resourceGroup'));
      },

      resetLoadMore: function resetLoadMore() {
        this.set('currentLoadSize', this.get('systemSettings').getSetting('resourcesListInitialLoadSize'));
      },

      onBookingTypeHelpTriggerClick: function onBookingTypeHelpTriggerClick() {
        this.get('appInsights').trackBookingTypeLoadMore(this.get('model.bookingType'), this.get('resourceGroup'));
        this.set('showBookingTypeHelpMessageModal', true);
      },

      onPrimaryResourceModeCancelClick: function onPrimaryResourceModeCancelClick() {
        this.get('router').transitionTo('logged-in.resource.show', this.get('model.bookingType.id'), this.get('currentResourceId'));
      },

      onAlertDismissal: function onAlertDismissal() {
        this.setProperties({ bookingTypeAlert: null, bookingTypeAlertIsDismissed: true });
      },

      onInfoDismissal: function onInfoDismissal() {
        this.setProperties({ bookingTypeInfo: null, bookingTypeInfoIsDismissed: true });
      },

      onLogFilter: function onLogFilter(filterData) {
        this.get('appInsights').trackEvent('resource-filter', filterData, this.get('model.bookingType'));
      }

    },

    filterResourcesAndGroups: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(bookingType) {
      var filters, calendarOptions, recurrenceEnabled, recurrence, resourcesPerPage, customFiltersSelected, _ref, resources, resourceGroups, isInitialLoad;

      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:

            this.set('page', 1);
            this.get('customEvents').triggerCustomFiltersSelectedChanged();
            filters = this.getResourceFilters();
            calendarOptions = this.get('calendarOptions');
            recurrenceEnabled = this.get('bookingMode') === 2;
            recurrence = recurrenceEnabled ? this.get('recurrence') : null;

            recurrence = recurrence ? this.get('recurrenceProcessor').generate(recurrence) : null;

            resourcesPerPage = this.get('systemSettings').getSetting(this.get('loadedIds.length') ? 'resourcesListAdditionalLoadSize' : 'resourcesListInitialLoadSize');
            customFiltersSelected = this.get('customFiltersSelected');

            if (!_ember['default'].isEmpty(customFiltersSelected)) {
              filters.properties = customFiltersSelected;
            }

            filters.itemsPerPage = resourcesPerPage;
            filters.recurrencePattern = recurrence;

            if (this.get('isAdminPrimaryResourceSearch')) {
              filters.isAdminPrimaryResourceSearch = true;
            }

            this.set('hideResourceGroupCount', this.calculateHideResourceGroupCount(recurrence));
            this.send('loading');
            context$1$0.prev = 15;
            context$1$0.next = 18;
            return this.get('bookingTypeResourceSearcher').search({
              parentId: bookingType ? bookingType.get('id') : this.get('model.bookingType.id'),
              filters: filters,
              calendarOptions: calendarOptions,
              resourceGroupIdentities: this.get('isResourceGroup') ? [this.get('resourceGroupId')] : [],
              loadedIdentities: this.get('loadedIds')
            });

          case 18:
            _ref = context$1$0.sent;
            resources = _ref.resources;
            resourceGroups = _ref.resourceGroups;

            this.set('resourcesLastFetchedCount', resources.get('length'));

            isInitialLoad = !this.get('loadedIds.length');

            if (!isInitialLoad) {
              this.get('model.resources').pushObjects(resources);
            } else {
              this.set('model.resources', resources);
              this.set('model.resourceGroups', resourceGroups);
            }

            this.get('loadedIds').pushObjects(resources.getEach('id'));
            this.send('finished');
            context$1$0.next = 32;
            break;

          case 28:
            context$1$0.prev = 28;
            context$1$0.t0 = context$1$0['catch'](15);

            this._handleErrors(context$1$0.t0);
            this.send('finished');

          case 32:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[15, 28]]);
    })).restartable(),

    breadCrumbs: (function () {
      var bread = [{
        label: this.get('i18n').t('pods.logged_in.booking_type.show.bookings'),
        path: 'logged-in.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        path: 'logged-in.booking-type.show',
        model: this.get('model.bookingType.id'),
        linkable: !!this.get('resourceGroup') // Only make it clickable if there is a resourceGroup
      }];

      if (this.get('resourceGroup')) {
        bread.pushObject({
          label: this.get('resourceGroup.name'),
          linkable: false
        });
      }

      return bread;
    }).property('model.bookingType.name', 'resourceGroup.name', 'i18n.locale')

  });
});