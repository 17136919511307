define("scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 11,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "aria-hidden", "true");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 10
              },
              "end": {
                "line": 35,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-2", [], ["query", "externalDataSourceSearch", "value", ["subexpr", "@mut", [["get", "model.value", ["loc", [null, [21, 20], [21, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionIdPath", "value", "optionLabelPath", "text", "searchEnabled", true, "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [25, 27], [25, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 22], [25, 36]]], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [26, 26], [26, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [27, 20], [27, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "ariaLabel", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [28, 24], [28, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "typeaheadSearchingText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadSearchingText"], [], ["loc", [null, [29, 43], [29, 124]]], 0, 0]], [], ["loc", [null, [29, 37], [29, 125]]], 0, 0], "typeaheadNoMatchesText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadNoMatchesText"], [], ["loc", [null, [30, 43], [30, 124]]], 0, 0]], [], ["loc", [null, [30, 37], [30, 125]]], 0, 0], "typeaheadErrorText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadErrorText"], [], ["loc", [null, [31, 39], [31, 116]]], 0, 0]], [], ["loc", [null, [31, 33], [31, 117]]], 0, 0], "optionDescriptionPath", "we-dont-want-description", "class", "at-bookingRequestForm-field-singleChoice-dropdown"], ["loc", [null, [19, 12], [34, 14]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 10
              },
              "end": {
                "line": 49,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [37, 22], [37, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.value", ["loc", [null, [38, 20], [38, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionIdPath", "value", "optionLabelPath", "text", "searchEnabled", true, "placeholder", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [42, 26], [42, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [43, 20], [43, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "ariaLabel", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [44, 24], [44, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [45, 27], [45, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 22], [45, 36]]], 0, 0], "optionDescriptionPath", "we-dont-want-description", "class", "at-bookingRequestForm-field-singleChoice-dropdown"], ["loc", [null, [36, 12], [48, 14]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 10
              },
              "end": {
                "line": 53,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "class", "button button--link button--icon at-manageResources-bookingTypeForm-bookingFormClear");
            var el2 = dom.createTextNode("✕");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'aria-label');
            morphs[1] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.clear_current_selection"], [], ["loc", [null, [52, 31], [52, 115]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "model.value", ["loc", [null, [52, 139], [52, 150]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 134], [52, 151]]], 0, 0], null], [], ["loc", [null, [52, 126], [52, 157]]], 0, 0]], [], ["loc", [null, [52, 117], [52, 159]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 56,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "select2-container--withClearButton");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "isUsingExternalDataContent", ["loc", [null, [18, 16], [18, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [18, 10], [49, 17]]]], ["block", "if", [["get", "model.value", ["loc", [null, [51, 16], [51, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [51, 10], [53, 17]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 12
                  },
                  "end": {
                    "line": 68,
                    "column": 267
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList--clearValue");
                var el2 = dom.createTextNode("✕");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'aria-label');
                morphs[1] = dom.createElementMorph(element0);
                return morphs;
              },
              statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.clear_current_selection"], [], ["loc", [null, [68, 69], [68, 153]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["updateRadioValue", null], [], ["loc", [null, [68, 222], [68, 256]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 8
                },
                "end": {
                  "line": 70,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("           ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "bookingRequestForm-field-singleChoice-itemList-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              morphs[2] = dom.createMorphAt(element2, 2, 2);
              return morphs;
            },
            statements: [["inline", "radio-button", [], ["name", ["subexpr", "@mut", [["get", "model.identity", ["loc", [null, [62, 17], [62, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [63, 18], [63, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "@mut", [["get", "checkedValue", ["loc", [null, [64, 20], [64, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "changed", "updateRadioValue", "class", "form-input at-bookingRequestForm-field-singleChoice-itemList", "type", "radio"], ["loc", [null, [61, 12], [66, 95]]], 0, 0], ["content", "item.text", ["loc", [null, [67, 79], [67, 92]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "checkedValue", ["loc", [null, [68, 22], [68, 34]]], 0, 0, 0, 0], ["get", "item.value", ["loc", [null, [68, 35], [68, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [68, 18], [68, 46]]], 0, 0]], [], 0, null, ["loc", [null, [68, 12], [68, 274]]]]],
            locals: ["item"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 6
              },
              "end": {
                "line": 72,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("fieldset");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("legend");
            dom.setAttribute(el2, "class", "sr-only");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element3, 3, 3);
            return morphs;
          },
          statements: [["content", "model.description", ["loc", [null, [58, 32], [58, 53]]], 0, 0, 0, 0], ["block", "each", [["get", "model.items", ["loc", [null, [59, 16], [59, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [59, 8], [70, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 6
            },
            "end": {
              "line": 72,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.isRadio", ["loc", [null, [56, 16], [56, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [56, 6], [72, 6]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 6
            },
            "end": {
              "line": 74,
              "column": 167
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "aria-live", "polite");
          dom.setAttribute(el1, "aria-atomic", "true");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [74, 127], [74, 160]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 6
            },
            "end": {
              "line": 77,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          dom.setAttribute(el1, "aria-live", "polite");
          dom.setAttribute(el1, "aria-atomic", "true");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "externalDataError", ["loc", [null, [76, 73], [76, 94]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "role", "region");
        dom.setAttribute(el1, "tabindex", "0");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "aria-hidden", "true");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [1]);
        var element7 = dom.childAt(element6, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element6, 'aria-label');
        morphs[1] = dom.createMorphAt(element7, 1, 1);
        morphs[2] = dom.createMorphAt(element7, 3, 3);
        morphs[3] = dom.createMorphAt(element6, 3, 3);
        morphs[4] = dom.createMorphAt(element6, 5, 5);
        morphs[5] = dom.createMorphAt(element6, 7, 7);
        return morphs;
      },
      statements: [["attribute", "aria-label", ["concat", [["subexpr", "if", [["get", "model.isMandatory", ["loc", [null, [4, 18], [4, 35]]], 0, 0, 0, 0], ["subexpr", "concat-strings", [["get", "model.description", ["loc", [null, [4, 52], [4, 69]]], 0, 0, 0, 0], ". ", ["subexpr", "t", ["components.booking_request_form.field_required"], [], ["loc", [null, [4, 75], [4, 127]]], 0, 0]], [], ["loc", [null, [4, 36], [4, 128]]], 0, 0], ["get", "model.description", ["loc", [null, [4, 129], [4, 146]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 13], [4, 148]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.description", ["loc", [null, [8, 6], [8, 27]]], 0, 0, 0, 0], ["block", "if", [["get", "model.isMandatory", ["loc", [null, [9, 12], [9, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 6], [11, 13]]]], ["block", "if", [["subexpr", "or", [["get", "model.isDropdown", ["loc", [null, [15, 16], [15, 32]]], 0, 0, 0, 0], ["get", "model.isSearch", ["loc", [null, [15, 33], [15, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 12], [15, 48]]], 0, 0]], [], 1, 2, ["loc", [null, [15, 6], [72, 13]]]], ["block", "if", [["subexpr", "and", [["get", "didValidate", ["loc", [null, [74, 17], [74, 28]]], 0, 0, 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [74, 29], [74, 60]]], 0, 0]], [], ["loc", [null, [74, 12], [74, 61]]], 0, 0]], [], 3, null, ["loc", [null, [74, 6], [74, 174]]]], ["block", "if", [["get", "externalDataError", ["loc", [null, [75, 12], [75, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [75, 6], [77, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});