define("scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/resource-custom-fields-inputs/multipleselections/item", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [11, 15], [11, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "uniqueId", ["subexpr", "@mut", [["get", "choice.id", ["loc", [null, [12, 17], [12, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [13, 17], [13, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "not", [["subexpr", "is-empty", [["subexpr", "find-by", ["identity", ["get", "choice.identity", ["loc", [null, [14, 51], [14, 66]]], 0, 0, 0, 0], ["get", "property.value", ["loc", [null, [14, 67], [14, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 31], [14, 82]]], 0, 0]], [], ["loc", [null, [14, 21], [14, 83]]], 0, 0]], [], ["loc", [null, [14, 16], [14, 84]]], 0, 0], "onChecked", "addToList", "onUnchecked", "removeFromList"], ["loc", [null, [10, 6], [17, 8]]], 0, 0]],
        locals: ["choice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-inputList");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("fieldset");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("legend");
        dom.setAttribute(el3, "class", "sr-only");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["content", "property.name", ["loc", [null, [2, 2], [2, 19]]], 0, 0, 0, 0], ["content", "property.name", ["loc", [null, [7, 28], [7, 45]]], 0, 0, 0, 0], ["block", "each", [["get", "property.valueChoicesWithId", ["loc", [null, [8, 12], [8, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 4], [18, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});