define('scientia-resourcebooker/pods/logged-in/search-results/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    globalSearcher: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    queryParams: {
      query: { replace: true, refreshModel: true },
      type: { replace: true, refreshModel: true },
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.search_results.page_title');
    },

    model: function model(params) {
      if (params.query) {
        // this.controllerFor('logged-in.search-results').set('page', 1);

        return _ember['default'].RSVP.hash({
          items: this.getResults(params.query, params.type, params.page, params.perPage),
          type: params.type
        });
      }
    },

    getResults: function getResults(query, type, pageNumber, perPage) {
      var _this = this;

      return this.get('globalSearcher').search({ query: query, type: type, pageNumber: pageNumber, perPage: perPage }).then(function (response) {
        if (type === 'bookingType') {
          if (response.results && response.results.length) {
            response.results = response.results.map(function (bookingType) {
              return _this.get('store').peekRecord('booking-type', bookingType.id) || bookingType;
            });
          }
        }

        return response;
      });
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackPageView(this.get('routeName'), transition.queryParams);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          type: 'bookingType',
          query: '',
          page: 1,
          perPage: 10
        });
      }
    }

  });
});