define('scientia-resourcebooker/pods/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    configFetcher: _ember['default'].inject.service(),

    extraDatasets: _ember['default'].computed.alias('configFetcher.extraDatasets'),

    loginMessage: _ember['default'].computed.alias('configFetcher.logonMessage'),
    loginMessageEnabled: _ember['default'].computed.alias('configFetcher.logonMessageEnabled'),

    welcomeMessage: _ember['default'].computed.alias('configFetcher.welcomeMessage'),
    hideWelcomeMessage: _ember['default'].computed.alias('configFetcher.hideWelcomeMessage'),

    hasClickedLoginButton: false,
    datasets: (function () {

      var datasets = this.get('extraDatasets').split(',');
      return datasets.reduce(function (memo, set) {
        var currentSet = set.split(':');
        memo.pushObject({ label: currentSet[0], value: currentSet[1] });
        return memo;
      }, []);
    }).property('extraDatasets'),

    actions: {

      onLoginClick: function onLoginClick() {
        this.set('hasClickedLoginButton', true);
        this.send('login');
      }

    }
  });
});