define('scientia-resourcebooker/utils/i18n/missing-message', ['exports', 'ember', 'ember-i18n/utils/locale'], function (exports, _ember, _emberI18nUtilsLocale) {

  var FALLBACK_LOCALE = 'en';

  var missingMessage = function missingMessage(locale, key, data) {
    if (locale === FALLBACK_LOCALE || window.env === 'development') {
      return data && data.fallback ? data.fallback : null;
    } else {
      // NOTE This relies on internal APIs and is brittle.
      // Emulating the internals of ember-i18n's translate method.
      var i18n = this;

      var count = _ember['default'].get(data, 'count');

      var defaults = _ember['default'].makeArray(_ember['default'].get(data, 'default'));
      defaults.unshift(key);

      var localeObj = new _emberI18nUtilsLocale['default'](FALLBACK_LOCALE, _ember['default'].getOwner(i18n));
      var template = localeObj.getCompiledTemplate(defaults, count);
      return template(data); // english fallback
    }
  };

  exports['default'] = missingMessage;
});
// FILE AT app/utils/i18n/missing-message.js
//
// NOTE if ember-i18n add support for fallback locales we won't need this
// https://github.com/jamesarosen/ember-i18n/issues/256