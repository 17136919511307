define('scientia-resourcebooker/pods/components/global-search-field/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    globalSearcher: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    classNameBindings: ['location'],
    classNames: ['globalSearchField'],
    location: '',
    isLoading: false,
    isFocused: false,
    query: '',
    suggestions: {},
    hasQueryAndIsFocused: _ember['default'].computed.and('query', 'isFocused'),

    hasResults: (function () {
      var suggestions = this.get('suggestions');

      if (suggestions.bookingTypes || suggestions.resourceGroups || suggestions.resources) {
        return !!(suggestions.bookingTypes.length || suggestions.resourceGroups.length || suggestions.resources.length);
      } else {
        return false;
      }
    }).property('suggestions'),

    queryDidChange: (function () {
      if (this.get('query') === '') {
        this.send('clearSuggestions');
      } else {
        this.send('submitSearch');
      }
    }).observes('query'),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        _ember['default'].$('body').on('click.globalSearchField-' + this.$().attr('id'), function () {
          _this.send('clearSuggestions');
        });

        _ember['default'].$('body').on('focus', '.select2-input', function () {
          _this.send('clearSuggestions');
        });
      });
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('body').off('click.globalSearchField-' + this.$().attr('id'));
      _ember['default'].$('body').off('focus', '.select2-input');
    },

    click: function click(event) {
      event.stopPropagation();
    },

    actions: {
      submitSearch: function submitSearch() {
        this.set('isFocused', true);
        _ember['default'].run.debounce(this, '_search', 1000, false);
      },

      clearSuggestions: function clearSuggestions() {
        if (!this.get('isDestroyed')) {
          this.set('isFocused', false);
          this.set('suggestions', {});
        }
      },

      onResourceClick: function onResourceClick(resource) {
        var _this2 = this;

        var type = arguments.length <= 1 || arguments[1] === undefined ? 'resource' : arguments[1];

        this.set('isLoading', true);
        this.get('globalSearcher').bookingTypesValidForResource(resource, type).then(function (bookingTypesAvailable) {
          if (_ember['default'].isEmpty(bookingTypesAvailable)) {
            return _this2.get('flashMessages').warning(_this2.get('i18n').t('components.global_search_field.resource_not_available'));
          }
          _this2.sendAction('onClickGlobalSearchResource', bookingTypesAvailable, resource, type);
        })['finally'](function () {
          if (!_this2.get('isDestroyed')) _this2.set('isLoading', false);
        });
      },

      onResourceGroupClick: function onResourceGroupClick(resourceGroup) {
        this.send('onResourceClick', resourceGroup, 'resource-group');
      }

    },

    _search: function _search() {
      var _this3 = this;

      if (this.get('query')) {
        this.set('isLoading', true);
        return this.get('globalSearcher').search({ query: this.get('query'), perPage: 10 }).then(function (searchResults) {
          if (!_ember['default'].isEmpty(searchResults.results) && !_ember['default'].isEmpty(searchResults.results.bookingTypes)) {

            searchResults.results.bookingTypes = searchResults.results.bookingTypes.map(function (bookingType) {
              return _this3.get('store').peekRecord('booking-type', bookingType.id) || bookingType;
            });
          }

          if (_this3.get('isDestroyed') || _this3.get('isDestroying')) return;
          _this3.set('suggestions', searchResults.results);
        })['finally'](function () {
          if (!_this3.get('isDestroyed')) _this3.set('isLoading', false);
        });
      }
    }
  });
});