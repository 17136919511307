define('scientia-resourcebooker/pods/components/manage-resources/resource-type-custom-fields-form/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var DEFAULT_LOCALE_PREFIX = 'components.manage_resources.resource_type_form';

  exports['default'] = _ember['default'].Component.extend({
    properties: [],
    localePrefix: DEFAULT_LOCALE_PREFIX,
    store: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),

    disabled: false,
    sortCriteria: ['rank'],
    propertiesSorted: _ember['default'].computed.sort('properties', 'sortCriteria'),
    propertiesEditing: (function () {
      return this.get('properties').filter(function (property) {
        return property.isEditing;
      }).length > 0;
    }).property('properties.@each.isEditing'),

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property(),

    actions: {
      addProperty: function addProperty() {
        var propertiesRanks = !_ember['default'].isEmpty(this.get('properties')) ? this.get('properties').getEach('rank') : [0];
        var highestRank = Math.max.apply(Math, _toConsumableArray(propertiesRanks)) || 0;
        this.get('properties').addObject(this.get('store').createFragment('property', { valueType: 'text', value: '', valueChoicesWithId: [], name: '', displayName: '', helpText: '', isEditing: true, isFilterable: false, rank: highestRank + 1, identity: '' }));
      },

      deleteProperty: function deleteProperty(displayId) {
        var props = this.get('properties');
        var propToDelete = props.findBy('displayId', displayId);
        this.get('globalConfirmer').confirm(this.get('i18n').t(DEFAULT_LOCALE_PREFIX + '.confirm_delete')).then(function () {
          return props.removeObject(propToDelete);
        });
      }
    }
  });
});