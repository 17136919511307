define('scientia-resourcebooker/pods/components/manage-resources/resources-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: ['manageResourcesItemsList'],

    resources: null,
    resourceTypeId: (function () {
      return this.get('resources.firstObject.resourceTypeId');
    }).property('resources.firstObject'),

    hideDeleteAction: false,
    hideIcon: false,
    currentPage: 1,
    totalPages: 1,
    isGroup: false,
    checkedResources: [],

    // shouldShowPaging: Ember.computed.gt('totalPages', 1),
    shouldShowPaging: _ember['default'].computed.gt('resources.length', 0),

    actions: {
      deleteResource: function deleteResource(resource) {
        this.sendAction('deleteResource', resource);
      },

      deleteResourceGroup: function deleteResourceGroup(resourceGroup) {
        this.sendAction('deleteResourceGroup', resourceGroup);
      },

      addToResourceGroup: function addToResourceGroup(resourceGroup, deferred) {
        this.sendAction('addToResourceGroup', resourceGroup, this.get('checkedResources'), deferred);
      },

      changePage: function changePage(page) {
        this.sendAction('changePage', page, this.get('isGroup'));
      }
    }
  });
});