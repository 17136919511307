define('scientia-resourcebooker/models/booking-form-field-attachment', ['exports', 'ember', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'ember-cp-validations'], function (exports, _ember, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({

    customAttachmentTypes: [(0, _emberCpValidations.validator)('attachmentEmptyCustomExtensions', {
      dependentKeys: ['model.attachmentTypes.length'] })],

    attachmentTypes: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.attachmentTypes.length']
    })]
  });

  var ValidationsUser = (0, _emberCpValidations.buildValidations)({
    value: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'errors.attachmentRequired',
        disabled: _ember['default'].computed(function () {
          return !this.get('model.isMandatory') || !this.get('model.isActive') || this.get('model.isManaging');
        })
      })]
    }
  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, ValidationsUser, {
    maximumFileSize: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    attachmentTypes: (0, _emberDataAttr['default'])(),
    customAttachmentTypes: (0, _emberDataAttr['default'])('string', { defaultValue: '' })
  });
});