define('scientia-resourcebooker/helpers/get-each', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (params) {

    var key = params[0];
    var arr = params[1];
    if (!key || _ember['default'].isEmpty(arr)) return [];
    if (_ember['default'].isArray(arr)) {
      return arr.getEach(key);
    }
  });
});