define('scientia-resourcebooker/pods/components/manage-resources/icon-selector/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNameBindings: [':iconSelector-item', 'isSelected:iconSelector-item--selected'],

    selectedIcon: '',
    url: '',

    isSelected: (function () {
      if (!this.get('selectedIcon')) return false;

      return this.get('selectedIcon.url') === this.get('url');
    }).property('selectedIcon.url', 'url'),

    click: function click() {
      this.attrs.selectIcon(_ember['default'].Object.create({ url: this.get('url') }));
    }
  });
});