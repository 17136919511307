define('scientia-resourcebooker/models/resource-restriction-rule', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    min: attr('number'),
    max: attr('number'),
    isPrimary: attr('boolean'),

    resourceType: belongsTo('resource-type', { async: false }),
    resourceGroups: attr('raw', { defaultValue: function defaultValue() {
        return [];
      } })
  });
});