define('scientia-resourcebooker/services/resource-concurrency-groups-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var resourceTypeId = _ref.resourceTypeId;
      var resourceId = _ref.resourceId;
      var enabledOnly = _ref.enabledOnly;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.resource.concurrencyGroup.loc(resourceTypeId, resourceId);
      if (enabledOnly) url += '?enabledOnly=true';
      return this.get('authenticatedRequest').fetch(url);
    }
  });
});