define('scientia-resourcebooker/pods/components/internal-anchor-link/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'a',
    classNames: ['internalAnchorLink'],
    attributeBindings: ['tabindex', 'computedHref:href', 'isMain:data-is-main-skip-link'],
    isMain: false,
    tabindex: '0',
    text: null,
    fragment: null,
    href: null,
    computedHref: _ember['default'].computed('fragment', function () {
      return '#' + this.get('fragment');
    }),

    click: function click(event) {
      var _this = this;

      event.preventDefault();
      event.stopImmediatePropagation();
      window.location.hash = this.get('fragment');

      var elementId = this.get('fragment');

      var element = document.querySelector('[id=' + elementId + ']');
      if (element) {
        _ember['default'].run.next(this, function () {
          return _this.$(element).trigger('focus');
        });
      }
    }

  });
});