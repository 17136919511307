define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/configuration/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    errorHandler: _ember['default'].inject.service(),

    titleToken: function titleToken(model) {
      return model.concurrencyConfiguration.get('name') + ' — ' + this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.concurrency_configurations');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        concurrencyConfiguration: this._fetchConcurrencyConfiguration(params),
        concurrencyGroups: this._fetchConcurrencyGroups()
      });
    },

    afterModel: function afterModel(model) {
      this.controllerFor(this.routeName)._mapSelectionsFromModel(model.concurrencyConfiguration.get('concurrencyGroupMembers'));
    },

    _fetchConcurrencyConfiguration: function _fetchConcurrencyConfiguration(params) {
      var _this = this;

      return this.get('store').findRecord('concurrency-configuration', params.id, { reload: true })['catch'](function () {
        _this.flashError(_this.get('i18n').t('general.generic_error'));
        return _this.transitionTo('manage-resources.resource-type.concurrency.index');
      });
    },

    _fetchConcurrencyGroups: function _fetchConcurrencyGroups() {
      var groups = this.get('store').peekAll('concurrency-group');
      return _ember['default'].isEmpty(groups) ? this.get('store').query('concurrency-group', {}) : _ember['default'].RSVP.resolve(groups);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: null
        });
      }
    },

    actions: {

      updateModel: function updateModel(model, transitionToIndex) {
        var _this2 = this;

        var c = this.controllerFor(this.routeName);

        if (_ember['default'].isEmpty(model.get('name'))) {
          c.set('error', this.get('i18n').t('error_messages.concurrencyconfigurationnoname').toString());
          return;
        }

        c.set('error', null);
        c.set('isSaving', true);

        model.set('concurrencyGroupMembers', c.get('selectedConcurrencyGroupMembers'));
        model.save().then(function (newModel) {
          _this2.editSuccessFlash('concurrency-configuration');
          if (transitionToIndex) {
            return _this2.transitionTo('manage-resources.resource-type.concurrency.index');
          } else {
            _this2.controllerFor(_this2.routeName)._mapSelectionsFromModel(_ember['default'].get(newModel, 'concurrencyGroupMembers'));
          }
        })['catch'](function (error) {
          var errorMessage = _this2.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            c.set('error', errorMessage);
            _ember['default'].$('.container-wrapper').scrollTop(0);
          } else {
            _this2.createErrorFlash('concurrency-configuration');
          }
        })['finally'](function () {
          c.set('isSaving', false);
        });
      },

      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        if (model.concurrencyConfiguration.get('hasDirtyAttributes')) model.concurrencyConfiguration.rollbackAttributes();
        return true;
      }
    }
  });
});