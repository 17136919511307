define('scientia-resourcebooker/pods/manage-resources/resource-type/importer/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    can: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('import-custom-fields resource-type') && !this.get('can').can('import-permissions resource-type')) {
        this.transitionTo('manage-resources.resource-type.index');
      }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.importer.index.page_title');
    }

  });
});