define('scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['permissionsSummary objectUsageSummary'],
    classNameBindings: ['location'],
    location: null,

    objectName: null,
    objectId: null,

    hideTabs: false,
    selectedTab: 0

  });
});