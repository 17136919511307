define('scientia-resourcebooker/pods/manage-resources/reports/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    systemSettings: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.reports.page_title'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: [{ selectedTab: 'section' }, 'resume', 'bookingId', 'occurrenceId'],

    selectedTab: 0,

    // When returning to the reports route after editing a booking (resume=true), we will need to fetch the edited booking
    // and show the updated popup. For that we need to pass bookingId and occurrenceId together with the above resume queryParam
    resume: false, // resume a report (returning from editing a booking in a report)
    bookingId: null,
    occurrenceId: null,

    loadingBookingRequest: false,
    showBookingRequest: null,
    showBookingRequestActions: false,
    isViewingReport: false,

    hideDashboard: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('hideDashboard');
    }),

    actions: {

      clearBookingParams: function clearBookingParams() {
        this.setProperties({
          bookingId: null,
          occurrenceId: null
        });
      }

    }
  });
});