define('scientia-resourcebooker/pods/components/container-sidebar/meta/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sidebarMeta', 'at-sidebar'],
    systemSettings: _ember['default'].inject.service(),
    helpPageUrl: _ember['default'].computed.alias('systemSettings.options.helpPageUrl'),
    contactPageUrl: _ember['default'].computed.alias('systemSettings.options.contactPageUrl'),
    hasHelpAndContactUrls: _ember['default'].computed.and('helpPageUrl', 'contactPageUrl'),

    isContactMailToLink: _ember['default'].computed('contactPageUrl', function () {
      return this.get('contactPageUrl').indexOf('mailto:') === 0;
    }),

    isHelpPageMailToLink: _ember['default'].computed('helpPageUrl', function () {
      return this.get('helpPageUrl').indexOf('mailto:') === 0;
    })
  });
});