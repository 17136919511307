define('scientia-resourcebooker/app', ['exports', 'ember', 'scientia-resourcebooker/resolver', 'ember-load-initializers', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerResolver, _emberLoadInitializers, _scientiaResourcebookerConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _scientiaResourcebookerConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _scientiaResourcebookerConfigEnvironment['default'].podModulePrefix,
    Resolver: _scientiaResourcebookerResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _scientiaResourcebookerConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});