define('scientia-resourcebooker/pods/components/booking-cancellation-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal bookingCancellationModal'],
    currentUser: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    classNameBindings: ['location'],
    location: '',
    cancelTypeIsOccurrence: _ember['default'].computed.equal('model.cancelType', 1),
    showNoteInput: false,

    note: '',
    // Model is an object: {booking: BookingRequest, cancelType: Number, occurrencesCount: Number}
    // model: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('currentUser').getNameIdentifier() !== this.get('model.booking.owner')) {
        this.send('showNoteInput');
      }
    },
    actions: {
      confirm: function confirm() {
        var model = this.get('model');
        this.set('buttonIsDisabled', true);
        this.attrs.confirm(model.booking, model.cancelType, this.get('note'));
      },

      showNoteInput: function showNoteInput() {
        this.set('showNoteInput', true);
        _ember['default'].run.later(this, function () {
          this.$('.bookingCancellationModal-textarea').focus();
        }, 100);
      }
    }
  });
});