define('scientia-resourcebooker/pods/manage-resources/user-group/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    userGroupSearcher: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    queryParams: {
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true },
      query: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.user_group.index.page_title');
    },

    model: function model(params, transition) {
      return _ember['default'].RSVP.hash({
        userGroups: this.store.query('user-group', {
          query: transition.queryParams.query || '',
          itemsPerPage: transition.queryParams.perPage || 10,
          pageNumber: transition.queryParams.page || 1,
          restrictedAccess: true
        }),

        systemGroups: this.store.query('user-group', { systemGroupsOnly: true })
      });
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        page: transition.queryParams.page || '1',
        query: transition.queryParams.query
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        return this.refresh();
      }
    }
  });
});