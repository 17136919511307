define('scientia-resourcebooker/pods/components/container-sidebar/user/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sidebarUser'],
    session: _ember['default'].inject.service(),
    user: null,

    actions: {
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});