define('scientia-resourcebooker/transitions', ['exports'], function (exports) {
  exports['default'] = function () {
    this.transition(this.inHelper('liquid-modal'), this.use('explode', {
      pick: '.lm-container',
      use: ['fade', { easing: 'linear', duration: 150 }]
    }));

    this.transition(this.fromRoute(function (routeName) {
      return routeName.indexOf('logged-in') !== -1;
    }), this.toRoute(function (routeName) {
      return routeName.indexOf('logged-in') !== -1;
    }), this.use('fade', { duration: 150 }));

    this.transition(this.fromRoute(function (routeName) {
      return routeName.indexOf('manage-resources') !== -1;
    }), this.toRoute(function (routeName) {
      return routeName.indexOf('manage-resources') !== -1;
    }), this.use('fade', { duration: 150 }));
  };
});