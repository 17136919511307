define('scientia-resourcebooker/services/network', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    isNetworkOffline: false,

    init: function init() {
      var _this = this;

      this._super();

      // Trigger networkOffline to indicate to the controller that there has been a change in network status i.e. offline.
      window.addEventListener('offline', function () {
        _ember['default'].run(function () {
          return _this.trigger('networkOffline');
        });
      });

      // Trigger networkRestored to indicate to the controller that there has been a change in network status i.e. restored and online.
      window.addEventListener('online', function () {
        _ember['default'].run(function () {
          return _this.trigger('networkRestored');
        });
      });
    },

    /**
     * Sets the property isNetworkOffline to true indicating that the network is offline.
    */
    offline: function offline() {
      this.set('isNetworkOffline', true);
    },

    /**
     * Sets the property isNetworkOffline to false indicating that the network has restored and is no longer offline.
     */
    restored: function restored() {
      this.set('isNetworkOffline', false);
    }
  });
});