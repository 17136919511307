define('scientia-resourcebooker/pods/components/manage-resources/permissions-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'components.manage_resources.permissions_form';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-permissionsForm'],
    userGroupSearcher: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    permissionsTypes: [{ value: 'read' }, { value: 'write' }],

    labelOverrides: null,
    permissions: [],

    showPermissionSummaryLink: false,
    isLoadingPermissionSummary: false,

    preventClaimToggler: false,

    permissionsTypeValues: (function () {
      var _this = this;

      return this.get('permissionsTypes').map(function (permission) {

        // If permission is separator make sure to add --- and read only
        if (permission.value === 'separator') return { value: null, label: '------------------', disabled: true };

        var labelKey = _this._getLabelForKey(permission.value);
        return {
          value: permission.value,
          disabled: permission.disabled,
          deprecated: permission.deprecated,
          label: _this.get('i18n').t('components.manage_resources.permissions_form.permission_types.' + labelKey)
        };
      });
    }).property(),

    claimTypeValues: (function () {
      var _this2 = this;

      var values = ['group', 'nameidentifier', 'emailaddress', 'givenname'];

      return values.map(function (value) {
        return { value: value, label: _this2.get('i18n').t('components.manage_resources.claims_form.claim_types.' + value) };
      });
    }).property('i18n.locale'),

    cannotAddPermission: (function () {
      return this.get('permissions').findBy('permissionValue', '');
    }).property('permissions.@each.permissionValue'),

    _getLabelForKey: function _getLabelForKey(key) {
      var overrides = this.get('labelOverrides');

      var labelKey = key.underscore();

      if (overrides && overrides[key]) {
        labelKey = overrides[key].underscore();
      }

      return labelKey;
    },

    actions: {

      toggleClaimMode: function toggleClaimMode(permission) {

        var isClaimMode = permission.get('isClaimMode') || false;
        if (isClaimMode) {
          permission.set('permissionValue', null);
        } else {
          permission.set('permissionValue', { claimType: 'group', claimValue: '' });
        }

        permission.set('isClaimMode', !isClaimMode);
      },

      addPermission: function addPermission() {
        var firstPermission = this.get('permissionsTypes').find(function (p) {
          return !p.disabled && !p.deprecated && p.value !== 'separator';
        });
        this.get('permissions').addObject(this.get('store').createFragment('permission-group', { isClaimMode: false, permissionValue: '', permissionType: firstPermission.value }));
      },

      deletePermission: function deletePermission(index) {
        var _this3 = this;

        this.get('globalConfirmer').confirm(this.get('i18n').t(DEFAULT_LOCALE_PREFIX + '.confirm_delete')).then(function () {
          return _this3.get('permissions').removeAt(index);
        });
      },

      permissionShouldBeDisabled: function permissionShouldBeDisabled(permission, disabled) {
        if (disabled) return true;

        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permission.get('permissionType'));
        if (_ember['default'].isEmpty(perm)) {
          return true;
        }
        return perm.disabled || false;
      },

      permissionShouldBeHidden: function permissionShouldBeHidden(permission) {
        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permission.get('permissionType'));
        return _ember['default'].isEmpty(perm);
      },

      permissionIsDeprecated: function permissionIsDeprecated(permissionType) {
        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permissionType);
        if (_ember['default'].isEmpty(perm)) return true; // If permission not enabled then it probably means it was a deprecated perm that has now been hidden
        return perm.deprecated;
      }
    }
  });
});