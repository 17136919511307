define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item-group'],

    valueChanged: (function () {
      _ember['default'].run.next(this, function () {
        if (this.get('model.maxDecimalPlaces') < 0) {
          this.set('model.maxDecimalPlaces', 1);
        }

        if (this.get('model.maxDecimalPlaces') > 9) {
          this.set('model.maxDecimalPlaces', 9);
        }
      });
    }).observes('model.maxDecimalPlaces')
  });
});