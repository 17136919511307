define('scientia-resourcebooker/pods/manage-resources/user-group/importer/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    can: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.user_group.importer.page_title');
    },

    beforeModel: function beforeModel() {
      if (!this.get('can').can('import user-group')) {
        this.transitionTo('/');
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.send('resetControllerProperties', controller);
      }
    },

    actions: {
      resetControllerProperties: function resetControllerProperties(controller) {
        if (!controller) controller = this.controllerFor('manage-resources.user-group.importer');
        controller.setProperties({
          selectedTab: 0
        });
      }

    }

  });
});
// willTransition(transition) {
//   this._super(...arguments);

//   const controller = this.controllerFor('manage-resources.user-group.importer');
//   const step = controller.get('step');
//   if (step === 2 || step === 3 && !controller.get('taskProgressResults.isCompleted')) {
//     controller.setProperties({
//       showUnsavedChangesModal: true,
//       previousTransition: transition
//     });
//     transition.abort();
//     return false;
//   }

//   return true;
// }