define('scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    isUserGroupTab: false,
    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    actions: {
      logLink: function logLink(type) {
        this.get('appInsights').trackEvent('permissions-summary-link', { type: type, isAdmin: this.get('isAdmin') });
      }
    }
  });
});