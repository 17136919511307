define('scientia-resourcebooker/services/concurrency-bookings-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var resourceIds = _ref.resourceIds;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var _ref$checkSplitPermissions = _ref.checkSplitPermissions;
      var checkSplitPermissions = _ref$checkSplitPermissions === undefined ? false : _ref$checkSplitPermissions;
      var _ref$ignoreId = _ref.ignoreId;
      var ignoreId = _ref$ignoreId === undefined ? null : _ref$ignoreId;
      var _ref$ignoreEventId = _ref.ignoreEventId;
      var ignoreEventId = _ref$ignoreEventId === undefined ? null : _ref$ignoreEventId;
      var allowDuplicates = _ref.allowDuplicates;

      var data = {
        rangeStart: startDate.startOf('minute').toISOString(),
        rangeEnd: endDate.startOf('minute').toISOString(),
        resourceIdentities: resourceIds,
        checkSplitPermissions: checkSplitPermissions
      };

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.concurrency.bookingRequest;
      return this.get('authenticatedRequest').post(url, data).then(function (data) {
        return _this._normalizeBookings(data, allowDuplicates);
      });
    },

    _normalizeBookings: function _normalizeBookings(data, allowDuplicates) {

      if (!allowDuplicates) return this._normalizeWithStore(data);

      // Backup original id
      data.forEach(function (d) {
        return d._id = d.id;
      });

      var expandedBookings = [];
      data.forEach(function (booking) {

        // set resourceIdLanesViewVariant to the primary resource Id
        booking.resourceIdLanesViewVariant = booking.resources[0].id;

        // For bookings with more than 1 resource lets create more bookings for each resource
        if (booking.resources.length > 1) {
          booking.resources.forEach(function (resource, index) {

            // Only process if not the first one as we already processed the first one on the top of the foreach
            if (index !== 0) {
              var newBooking = JSON.parse(JSON.stringify(booking));
              newBooking.id = newBooking.id + '-' + index;
              newBooking.identity = newBooking.id;
              newBooking.resourceIdLanesViewVariant = resource.id;
              expandedBookings.push(newBooking);
            }
          });
        }
      });

      data.push.apply(data, expandedBookings);
      return this._normalizeWithStore(data);
    },

    _normalizeWithStore: function _normalizeWithStore(data) {
      var serializer = this.get('store').serializerFor('booking-request');
      var typeClass = this.get('store').modelFor('booking-request');

      var serializedData = serializer.normalizeArrayResponse(this.get('store'), typeClass, data, null, 'query');
      return this.get('store').push(serializedData);
    }
  });
});