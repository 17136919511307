define('scientia-resourcebooker/pods/components/manage-resources/resources-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['manageResourcesItemsList-item'],
    isSaving: false,
    isGroup: false,
    hideIcon: false,
    hideSelectCheckbox: false,
    actions: {
      deleteResource: function deleteResource(resource) {
        this.sendAction('deleteResource', resource);
      },

      deleteResourceGroup: function deleteResourceGroup(resourceGroup) {
        this.sendAction('deleteResourceGroup', resourceGroup);
      },

      addToResourceGroup: function addToResourceGroup(resourceGroup) {
        var _this = this;

        var deferred = _ember['default'].RSVP.defer();
        this.set('isSaving', true);

        this.sendAction('addToResourceGroup', resourceGroup, deferred);

        deferred.promise['finally'](function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });
});