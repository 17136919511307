define('scientia-resourcebooker/pods/components/container-sidebar/timezone/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['timezoneMeta', 'sidebarMeta', 'at-sidebar'],

    systemSettings: _ember['default'].inject.service(),

    timezone: _ember['default'].computed.alias('systemSettings.options.timeZone'),

    offset: (function () {
      return moment().tz(this.get('timezone')).format('Z');
    }).property('timezone')

  });
});