define("scientia-resourcebooker/pods/components/rb-agenda-view/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/rb-agenda-view/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "chronos-agendaView-item");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("time");
            dom.setAttribute(el2, "class", "chronos-agendaView-item-time");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "chronos-agendaView-item-time-hour");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "chronos-agendaView-item-time-duration");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "chronos-agendaView-item-infos chronos-agendaView-item-infos--imageLess");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'data-event-id');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "data-event-id", ["concat", [["get", "event.id", ["loc", [null, [5, 26], [5, 34]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["onEventClick", ["get", "event", ["loc", [null, [5, 94], [5, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 70], [5, 101]]], 0, 0], ["inline", "moment-format", [["get", "event.startDateTime", ["loc", [null, [7, 72], [7, 91]]], 0, 0, 0, 0], ["get", "hourFormat", ["loc", [null, [7, 92], [7, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 56], [7, 104]]], 0, 0], ["content", "event.duration", ["loc", [null, [8, 60], [8, 78]]], 0, 0, 0, 0], ["content", "event.nameWithPrimaryResourceNames", ["loc", [null, [12, 8], [12, 46]]], 0, 0, 0, 0]],
          locals: ["event"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-agenda-view/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "chronos-agendaView-header");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "moment-format", [["get", "timespan.date", ["loc", [null, [2, 58], [2, 71]]], 0, 0, 0, 0], ["get", "dateFormat", ["loc", [null, [2, 72], [2, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 42], [2, 84]]], 0, 0], ["block", "each", [["get", "timespan.events", ["loc", [null, [4, 10], [4, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [15, 11]]]]],
        locals: ["timespan"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/rb-agenda-view/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "sortedResults", ["loc", [null, [1, 8], [1, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [16, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});