define('scientia-resourcebooker/services/session', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var SESSION_KEY = _scientiaResourcebookerConfigEnvironment['default'].session.keyPrefix + '-authorization';

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    configFetcher: _ember['default'].inject.service(),
    loggedInAt: null,
    isSessionTimedout: false,
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    preventLogout: false,

    stopAutoLogout: function stopAutoLogout() {
      this.set('preventLogout', true);
    },

    restoreAutoLogout: function restoreAutoLogout() {
      this.set('preventLogout', false);
    },

    init: function init() {
      var _this = this;

      this._super();

      _ember['default'].$(window).on('storage', function (jQueryEvent) {
        var originalEvent = jQueryEvent.originalEvent;
        if (originalEvent.key === SESSION_KEY && originalEvent.newValue === null) {
          _ember['default'].run(function () {
            return _this.trigger('authorizationInvalidated');
          });
        }
      });

      this.set('loggedInAt', moment());
    },

    logout: function logout(invalidateToken) {
      var useSingleLogout = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      if (invalidateToken) this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.INVALIDATE_TOKEN);

      this.invalidateAuthorization();
      // This makes sure we restart with a clean slate

      var baseURL = this.get('configFetcher.baseURL');
      var authenticationDomainName = baseURL ? baseURL.split('://')[1].split('.')[0] : 'rbauth';
      var logoutPath = 'https://' + authenticationDomainName + '.scientia.com/account/signout?returnUrl=%@&sendSloMessage=%@';
      window.location = logoutPath.loc(window.location.origin, useSingleLogout);
    },

    timeout: function timeout() {

      var preventLogout = this.get('preventLogout');
      var enforceAutoLogoutOnNotificationsPollFailure = this.get('systemSettings').getSetting('enforceAutoLogoutOnNotificationsPollFailure');
      if (preventLogout && !enforceAutoLogoutOnNotificationsPollFailure) return;

      this.set('isSessionTimedout', true);
    },

    saveRedirectURL: function saveRedirectURL() {
      localStorage.setItem(_scientiaResourcebookerConfigEnvironment['default'].session.keyPrefix + '-redirectTo', encodeURIComponent(window.location.href));
    },

    invalidateAuthorization: function invalidateAuthorization() {
      localStorage.removeItem(SESSION_KEY);
    },

    authorization: _ember['default'].computed(function () {
      return JSON.parse(localStorage.getItem(SESSION_KEY));
    }).volatile()

    // authorization: Ember.computed({
    //   get() {
    //     return JSON.parse(localStorage.getItem(SESSION_KEY));
    //   },

    //   set(key, value) {
    //     if (value === null) {
    //       localStorage.removeItem(SESSION_KEY);
    //     } else {
    //       localStorage.setItem(SESSION_KEY, JSON.stringify(value));
    //     }

    //     return value;
    //   }
    // }).volatile()
  });
});