define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-textbox/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 24,
                "column": 110
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-textbox/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "input-error");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "maxCharacterLimit"], [], [], 0, 0], "message"], [], ["loc", [null, [24, 54], [24, 99]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-textbox/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1, "type", "number");
          dom.setAttribute(el1, "min", "1");
          dom.setAttribute(el1, "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing at-bookingFormField-numeric-limitRange");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'max');
          morphs[1] = dom.createAttrMorph(element0, 'placeholder');
          morphs[2] = dom.createAttrMorph(element0, 'aria-label');
          morphs[3] = dom.createAttrMorph(element0, 'value');
          morphs[4] = dom.createAttrMorph(element0, 'oninput');
          morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["attribute", "max", ["get", "maxBookingFormTextLength", ["loc", [null, [17, 12], [17, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.textbox.max_character_placeholder"], [], ["loc", [null, [null, null], [18, 106]]], 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["subexpr", "t", ["components.manage_resources.booking_form_field.textbox.max_character_limit"], [], ["loc", [null, [null, null], [19, 99]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.maxCharacterLimit", ["loc", [null, [20, 14], [20, 37]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.maxCharacterLimit", ["loc", [null, [21, 28], [21, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 23], [21, 52]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [21, 75]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "didValidate", ["loc", [null, [24, 12], [24, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 6], [24, 117]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-textbox/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(fragment, [4]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(element4, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.booking_form_field.textbox.max_character_limit"], [], ["loc", [null, [4, 6], [4, 88]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.textbox.max_character_limit_explanation"], [], ["loc", [null, [6, 8], [6, 102]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.isCharacterLimited", ["loc", [null, [11, 28], [11, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.isCharacterLimited", ["loc", [null, [11, 75], [11, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 70], [11, 100]]], 0, 0]], [], ["loc", [null, [11, 62], [11, 101]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [11, 4], [11, 142]]], 0, 0], ["block", "if", [["get", "model.isCharacterLimited", ["loc", [null, [13, 10], [13, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 4], [25, 11]]]], ["inline", "t", ["components.manage_resources.booking_form_field.textbox.multiline"], [], ["loc", [null, [33, 6], [33, 78]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.textbox.multiline_explanation"], [], ["loc", [null, [35, 8], [35, 92]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.isMultiLine", ["loc", [null, [39, 28], [39, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.isMultiLine", ["loc", [null, [39, 68], [39, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 63], [39, 86]]], 0, 0]], [], ["loc", [null, [39, 55], [39, 87]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [39, 4], [39, 128]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});