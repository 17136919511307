define('scientia-resourcebooker/pods/components/async-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'button',
    classNames: ['button', 'asyncButton'],
    classNameBindings: ['disableButton:asyncButton--disabled'],
    attributeBindings: ['disabled', 'type', 'autofocus', 'tabIndex'],

    disabled: _ember['default'].computed.readOnly('loading'),
    loading: false,
    tabIndex: '0',
    autofocus: false,
    action: null,
    actionParam: null,

    click: function click() {
      if (this.get('disabled') || this.get('disableButton')) return;
      this.sendAction.apply(this, ['action', this.get('actionParam')]);
    }
  });
});