define('scientia-resourcebooker/services/booking-request-history-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    fetch: function fetch(_ref) {
      var bookingId = _ref.bookingId;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.HISTORY.loc(bookingId);
      return this.get('authenticatedRequest').fetch(url).then(this._normalizeResults.bind(this));
    },

    _normalizeResults: function _normalizeResults(results) {
      var _this = this;

      return results.map(function (entry) {
        entry.actionDate = moment(entry.actionDate).format(_this.get('i18n').t('components.booking_request_overview.history.date_format').toString());
        return entry;
      });
    }
  });
});