define('scientia-resourcebooker/serializers/concurrency-configuration', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {

    normalize: function normalize(type, payload) {
      payload = this.store.serializerFor('application')._camelizeKeysDeep(payload);
      payload.concurrencyGroupMembers = this._sortGroups(payload.concurrencyGroupMembers);
      return this._super.apply(this, arguments);
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.concurrencyGroupMembers) {
        json.concurrencyGroupMembers.forEach(function (c) {
          c.Identity = c.id;
          delete c.type;
          delete c.id;
        });
      }
      return json;
    },

    _sortGroups: function _sortGroups(groups) {
      groups.sort(function (a, b) {
        return a.order < b.order ? -1 : 1;
      });
    }

  });
});