define("scientia-resourcebooker/pods/manage-resources/booking-form/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteTab", ["loc", [null, [7, 25], [7, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 46]]], 0, 0], null], [], ["loc", [null, [7, 12], [7, 52]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_delete_tab_title"], [], ["loc", [null, [8, 19], [8, 89]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_delete_tab_message"], [], ["loc", [null, [9, 21], [9, 93]]], 0, 0], "confirm", ["subexpr", "action", ["removeTabConfirm"], [], ["loc", [null, [10, 14], [10, 41]]], 0, 0]], ["loc", [null, [5, 9], [10, 42]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteTab", ["loc", [null, [11, 32], [11, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 27], [11, 53]]], 0, 0], null], [], ["loc", [null, [11, 19], [11, 59]]], 0, 0]], ["loc", [null, [4, 7], [11, 60]]], 0, 0]], ["loc", [null, [2, 1], [12, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", ["leavePageCancel"], [], ["loc", [null, [21, 12], [21, 38]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_discard_changes_title"], [], ["loc", [null, [22, 19], [22, 94]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_discard_changes_message"], [], ["loc", [null, [23, 21], [23, 98]]], 0, 0], "confirm", ["subexpr", "action", ["leavePageConfirm"], [], ["loc", [null, [24, 14], [24, 41]]], 0, 0]], ["loc", [null, [19, 9], [24, 42]]], 0, 0], "onOutsideClick", ["subexpr", "action", ["leavePageCancel"], [], ["loc", [null, [25, 19], [25, 45]]], 0, 0]], ["loc", [null, [18, 7], [25, 46]]], 0, 0]], ["loc", [null, [16, 0], [26, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 6
              },
              "end": {
                "line": 42,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "section-title-actions/item", [], ["type", "danger", "icon", "icons/trash.svg", "disabled", ["subexpr", "@mut", [["get", "model.hasAssociatedBookingTypes", ["loc", [null, [38, 17], [38, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.delete_form"], [], ["loc", [null, [39, 13], [39, 70]]], 0, 0], "action", ["subexpr", "action", ["deleteModel"], [], ["loc", [null, [40, 15], [40, 37]]], 0, 0]], ["loc", [null, [35, 8], [41, 10]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 4
            },
            "end": {
              "line": 43,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [34, 6], [42, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 6
                },
                "end": {
                  "line": 52,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [50, 17], [50, 78]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_form.edit.description"], [], ["loc", [null, [51, 6], [51, 65]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 6
                },
                "end": {
                  "line": 56,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/bullet-list.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [54, 17], [54, 81]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_form.edit.fields"], [], ["loc", [null, [55, 6], [55, 60]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [50, 6], [52, 17]]]], ["block", "dc-tab", [], [], 1, null, ["loc", [null, [54, 6], [56, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 6
              },
              "end": {
                "line": 67,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/booking-form-form", [], ["localePrefix", "components.manage_resources.booking_form_edit", "name", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [63, 11], [63, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [64, 18], [64, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "associatedBookingTypes", ["subexpr", "@mut", [["get", "model.associatedBookingTypes", ["loc", [null, [65, 29], [65, 57]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [61, 6], [66, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 81,
                            "column": 14
                          },
                          "end": {
                            "line": 83,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "inline-svg", ["icons/reorder-option.svg"], ["class", "scientia-tabs-item-link-icon handle"], ["loc", [null, [82, 16], [82, 101]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 85,
                            "column": 14
                          },
                          "end": {
                            "line": 89,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "scientia-tabs-item-link-text");
                        var el2 = dom.createTextNode("\n                  ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                        return morphs;
                      },
                      statements: [["inline", "input", [], ["class", "form-input at-scientia-tabs-tab at-bookingFormTab--editing", "autofocus", true, "value", ["subexpr", "@mut", [["get", "tab.name", ["loc", [null, [87, 114], [87, 122]]], 0, 0, 0, 0]], [], [], 0, 0], "focus-out", ["subexpr", "action", ["renameTabFinished", ["get", "tab", ["loc", [null, [87, 161], [87, 164]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 133], [87, 165]]], 0, 0], "enter", ["subexpr", "action", ["renameTabFinished", ["get", "tab", ["loc", [null, [87, 200], [87, 203]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 172], [87, 204]]], 0, 0], "maxlength", 20], ["loc", [null, [87, 18], [87, 219]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child2 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 92,
                              "column": 16
                            },
                            "end": {
                              "line": 94,
                              "column": 16
                            }
                          },
                          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("button");
                          dom.setAttribute(el1, "type", "button");
                          dom.setAttribute(el1, "class", "button button--link button--icon at-bookingForm-deleteTab");
                          var el2 = dom.createTextNode("✕");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element1 = dom.childAt(fragment, [1]);
                          var morphs = new Array(1);
                          morphs[0] = dom.createElementMorph(element1);
                          return morphs;
                        },
                        statements: [["element", "action", ["removeTab", ["get", "tab", ["loc", [null, [93, 61], [93, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [93, 40], [93, 66]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 89,
                            "column": 14
                          },
                          "end": {
                            "line": 95,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "scientia-tabs-item-link-text");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element2 = dom.childAt(fragment, [1]);
                        var morphs = new Array(3);
                        morphs[0] = dom.createElementMorph(element2);
                        morphs[1] = dom.createMorphAt(element2, 0, 0);
                        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["element", "action", ["renameTab", ["get", "tab.rank", ["loc", [null, [90, 80], [90, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [90, 59], [90, 90]]], 0, 0], ["content", "tab.name", ["loc", [null, [90, 91], [90, 103]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["subexpr", "eq", [["get", "tab.rank", ["loc", [null, [92, 36], [92, 44]]], 0, 0, 0, 0], 0], [], ["loc", [null, [92, 32], [92, 47]]], 0, 0]], [], ["loc", [null, [92, 27], [92, 48]]], 0, 0], ["subexpr", "eq", [["get", "formTabActiveRank", ["loc", [null, [92, 53], [92, 70]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [92, 71], [92, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 49], [92, 80]]], 0, 0]], [], ["loc", [null, [92, 22], [92, 81]]], 0, 0]], [], 0, null, ["loc", [null, [92, 16], [94, 23]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 78,
                          "column": 10
                        },
                        "end": {
                          "line": 99,
                          "column": 10
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2, "class", "scientia-tabs-item-link--content");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n              ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element3 = dom.childAt(fragment, [1]);
                      var element4 = dom.childAt(element3, [1]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createAttrMorph(element3, 'class');
                      morphs[1] = dom.createElementMorph(element3);
                      morphs[2] = dom.createMorphAt(element4, 1, 1);
                      morphs[3] = dom.createMorphAt(element4, 3, 3);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["scientia-tabs-item-link at-manageResources-bookingFormTab ", ["subexpr", "if", [["subexpr", "eq", [["get", "formTabActiveRank", ["loc", [null, [79, 142], [79, 159]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [79, 160], [79, 168]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 138], [79, 169]]], 0, 0], "scientia-tabs-item-link--active"], [], ["loc", [null, [79, 133], [79, 205]]], 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["get", "tabRankToRename", ["loc", [null, [79, 215], [79, 230]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [79, 231], [79, 239]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 211], [79, 240]]], 0, 0], "at-manageResources-bookingFormTab--editing"], [], ["loc", [null, [79, 206], [79, 287]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "formTabActiveRank", ["loc", [null, [79, 37], [79, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 32], [79, 55]]], 0, 0], ["get", "tab.rank", ["loc", [null, [79, 56], [79, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 24], [79, 65]]], 0, 0]], [], ["loc", [null, [79, 15], [79, 67]]], 0, 0], ["block", "unless", [["subexpr", "eq", [["get", "tab.rank", ["loc", [null, [81, 28], [81, 36]]], 0, 0, 0, 0], 0], [], ["loc", [null, [81, 24], [81, 39]]], 0, 0]], [], 0, null, ["loc", [null, [81, 14], [83, 25]]]], ["block", "if", [["subexpr", "eq", [["get", "tabRankToRename", ["loc", [null, [85, 24], [85, 39]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [85, 40], [85, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [85, 20], [85, 49]]], 0, 0]], [], 1, 2, ["loc", [null, [85, 14], [95, 21]]]]],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 77,
                        "column": 8
                      },
                      "end": {
                        "line": 100,
                        "column": 8
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "scientia-tabs-item", [], [], 0, null, ["loc", [null, [78, 10], [99, 33]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 75,
                      "column": 6
                    },
                    "end": {
                      "line": 102,
                      "column": 6
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "sortable-item", [], ["model", ["subexpr", "@mut", [["get", "tab", ["loc", [null, [77, 31], [77, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [77, 41], [77, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".handle"], 0, null, ["loc", [null, [77, 8], [100, 26]]]]],
                locals: ["tab"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 4
                  },
                  "end": {
                    "line": 104,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "each", [["subexpr", "sort-by", ["rank", ["get", "model.sections", ["loc", [null, [75, 30], [75, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 14], [75, 45]]], 0, 0]], [], 0, null, ["loc", [null, [75, 6], [102, 15]]]]],
              locals: ["group"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 4
                  },
                  "end": {
                    "line": 111,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "scientia-tabs-item-link at-bookingFormTabs-addNewTab");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "scientia-tabs-item-link-text at-bookingFormTabs-addNewTab");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["addTab"], [], ["loc", [null, [107, 7], [107, 26]]], 0, 0], ["inline", "inline-svg", ["icons/plus.svg"], ["class", "scientia-tabs-item-link-icon"], ["loc", [null, [108, 8], [108, 76]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_tab.add_new"], [], ["loc", [null, [109, 80], [109, 140]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 113,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["block", "sortable-group", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [73, 28], [73, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "sortableContainer at-bookingFormTabs", "direction", ["subexpr", "@mut", [["get", "tabSortingDirection", ["loc", [null, [73, 94], [73, 113]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", "reorderTabs"], 0, null, ["loc", [null, [73, 4], [104, 23]]]], ["block", "scientia-tabs-item", [], [], 1, null, ["loc", [null, [106, 4], [111, 29]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 4
              },
              "end": {
                "line": 126,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "scientia-tabs", [], [], 0, null, ["loc", [null, [71, 4], [113, 22]]]], ["inline", "manage-resources/booking-form-tab", [], ["model", ["subexpr", "find-by", ["rank", ["get", "formTabActiveRank", ["loc", [null, [116, 26], [116, 43]]], 0, 0, 0, 0], ["get", "model.sections", ["loc", [null, [116, 44], [116, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [116, 10], [116, 59]]], 0, 0], "addItem", ["subexpr", "action", ["addItem"], [], ["loc", [null, [117, 12], [117, 30]]], 0, 0], "deleteItem", ["subexpr", "action", ["deleteItem"], [], ["loc", [null, [118, 15], [118, 36]]], 0, 0], "toggleEditing", ["subexpr", "action", ["toggleEditing"], [], ["loc", [null, [119, 18], [119, 42]]], 0, 0], "rollbackItem", ["subexpr", "action", ["rollbackItem"], [], ["loc", [null, [120, 17], [120, 40]]], 0, 0], "reorderItems", ["subexpr", "action", ["reorderItems"], [], ["loc", [null, [121, 17], [121, 40]]], 0, 0], "searchResourceType", ["subexpr", "action", ["searchResourceType"], [], ["loc", [null, [122, 23], [122, 52]]], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [123, 16], [123, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [115, 4], [124, 6]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 6
            },
            "end": {
              "line": 127,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [48, 6], [58, 22]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [60, 6], [67, 21]]]], ["block", "dc-tab-panel", [], [], 2, null, ["loc", [null, [69, 4], [126, 21]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 133,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns at-manageResources-bookingForm--edit");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [4]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element6, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(element5, 1, 1);
        morphs[3] = dom.createAttrMorph(element6, 'aria-label');
        morphs[4] = dom.createMorphAt(element6, 1, 1);
        morphs[5] = dom.createMorphAt(element7, 1, 1);
        morphs[6] = dom.createMorphAt(element7, 3, 3);
        morphs[7] = dom.createMorphAt(element7, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showConfirmDeleteTab", ["loc", [null, [1, 6], [1, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 7]]]], ["block", "if", [["get", "showUnsavedChangesModal", ["loc", [null, [15, 6], [15, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [27, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [30, 39], [30, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 0], [30, 53]]], 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [32, 90], [32, 118]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.page_title"], ["name", ["get", "model.name", ["loc", [null, [33, 87], [33, 97]]], 0, 0, 0, 0]], ["loc", [null, [33, 26], [33, 98]]], 0, 0]], 2, null, ["loc", [null, [33, 4], [43, 22]]]], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [45, 24], [45, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 6], [45, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [47, 59], [47, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 3, null, ["loc", [null, [47, 6], [127, 16]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [129, 47], [129, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["onCancel"], [], ["loc", [null, [129, 69], [129, 88]]], 0, 0], "onSave", ["subexpr", "route-action", ["save"], [], ["loc", [null, [129, 96], [129, 117]]], 0, 0]], ["loc", [null, [129, 4], [129, 119]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});