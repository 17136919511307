define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    resourceConcurrencyGroupsFetcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    queryParams: {
      formattedDisplayDate: { replace: true, refreshModel: false },
      bookingId: { replace: true, refreshModel: false },
      occurrenceId: { replace: true, refreshModel: false },
      isConfiguration: { replace: true, refreshModel: false }
    },

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource_type.concurrency.show.page_title') + ' — ' + model.concurrencyObject.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('systemSettings').getSetting('betaConcurrencyCombinedCalendarsDisabled')) {
        return this.transitionTo('logged-in');
      }
    },

    model: function model(params, transition) {

      return _ember['default'].RSVP.hash({
        concurrencyObject: this._fetchConcurrencyModel(params, transition.queryParams.isConfiguration),
        events: [],
        bookingRequest: this._fetchBookingRequest(params)
        // concurrencyGroups: this._fetchConcurrencyGroupsForResource(resourceTypeId, params.id)
      });
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.concurrencyObject.get('name'),
        id: model.concurrencyObject.get('id')
      });

      var controller = this.controllerFor(this.routeName);
      if (model.bookingRequest) {
        controller.send('openBookingRequestWithModelLoaded', model.bookingRequest);
      }

      controller.set('isConcurrencyConfiguration', transition.queryParams.isConfiguration);

      if (transition.queryParams.lanesToggled) {
        controller.set('lanesToggledOnInit', true);
      }
    },

    _fetchConcurrencyModel: function _fetchConcurrencyModel(params, isConfiguration) {
      return this.get('store').findRecord(isConfiguration ? 'concurrency-configuration' : 'concurrency-group', params.id, { reload: true });
    },

    _fetchBookingRequest: function _fetchBookingRequest(params) {
      var _this = this;

      if (!params || !params.bookingId) return null;

      if (params.occurrenceId) {
        return this.store.queryRecord('booking-request', { bookingId: params.bookingId, occurrenceId: params.occurrenceId })['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      } else {

        return this.store.find('booking-request', params.bookingId)['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      }
    },

    // _fetchConcurrencyGroupsForResource(resourceTypeId, resourceId) {
    //   return this.get('resourceConcurrencyGroupsFetcher').fetch({resourceTypeId, resourceId}).catch(() => {
    //     // The "Show Concurrency Unavailability" toggle is based on concurrencyGroups.length so we just return a dummy array
    //     // to force showing the toggle in case this call ever fails for some reason.
    //     return [0];
    //   });
    // },

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          showBookingRequest: null,
          bookingId: null,
          occurrenceId: null,
          editRestrictionsModal: null,
          showConcurrencyUnavailability: true,
          showTemplateUnavailability: false,
          showViewModeOptions: true,
          lanesToggledOnInit: true
        });
      }
    }
  });
});