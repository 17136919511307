define('scientia-resourcebooker/pods/components/rb-select/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':rb-select', 'isFocused:rb-select--focused', 'class'],
    attributeBindings: ['disabled'],
    content: null,
    value: null,
    ariaLabel: (0, _emberI18n.translationMacro)('components.rb_select.placeholder'),
    placeholder: (0, _emberI18n.translationMacro)('components.rb_select.placeholder'),
    disabled: false,
    optionLabelPath: 'label',
    optionValuePath: 'value',
    showPlaceholder: false,
    forceInt: false,

    delayOnInit: false,
    canShowComponent: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // delayOnInit if set to True, will hide the html select component on init
      // and show it after 500ms. This has to be done as sometimes the select component doesn't reflect the correct selected value
      // when we navigate back to a previous route. A good example was RB-1013 where if you set Duration filter to a value, select
      // a resource and navigate back, the component would not remember the selection (even when the query param was there)
      if (this.get('delayOnInit')) {
        this.set('canShowComponent', false);

        _ember['default'].run.later(function () {
          if (_this.get('isDestroying') || _this.get('isDetroyed')) return;

          _this.set('canShowComponent', true);
        }, 1000);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.$('.rb-select-select').on('focus', function () {
        _ember['default'].run(function () {
          return _this2.set('isFocused', true);
        });
      });

      this.$('.rb-select-select').on('blur', function () {
        _ember['default'].run(function () {
          return _this2.set('isFocused', false);
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('isDestroyed')) this.$('.rb-select-select').off('focus blur');
    },

    actions: {
      updateValue: function updateValue(value) {

        // Do not update if value is the same or if it equals placeholder
        if (value === this.get('value')) return;
        if (this.get('placeholder') && this.get('placeholder').toString() === value) return;

        if (this.get('forceInt')) value = parseInt(value);
        if (this.get('onChange')) {
          this.sendAction('onChange', value);
        } else {
          this.set('value', value);
        }
      }
    }
  });
});