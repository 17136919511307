define('scientia-resourcebooker/pods/components/logout-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmModal logoutModal'],
    buttonIsDisabled: false,

    // 0 = SLO Disabled
    // 1 = SLO Enabled / Checkbox unchecked by default
    // 2 = SLO Eanbled / Checkbox checked by default
    // 3 = SLO Enabled / Checkbox checked + disabled
    // 4 = SLO Enabled / Checkbox checked and hidden
    singleLogoutMode: 0,

    singleLogoutShow: _ember['default'].computed('singleLogoutMode', function () {
      return [1, 2, 3].includes(this.get('singleLogoutMode')); // Only show if mode is 1/2/3
    }),

    singleLogoutCheckboxDisabled: _ember['default'].computed('singleLogoutMode', function () {
      return [3, 4].includes(this.get('singleLogoutMode')); // mode 3 or 4 should be disabled
    }),

    singleLogoutCheckboxValue: false,

    actions: {
      confirm: function confirm() {
        this.set('buttonIsDisabled', true);
        var shouldSLO = this.get('singleLogoutCheckboxValue') === 4 || this.get('singleLogoutCheckboxValue');
        this.attrs.confirm(shouldSLO);
      }
    }
  });
});