define('scientia-resourcebooker/pods/components/help-trigger/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['helpModal-trigger'],
    classNameBindings: ['mobile:helpModal-trigger--mobile'],
    systemSettings: _ember['default'].inject.service(),
    router: _ember['default'].inject.service(),

    currentRoute: _ember['default'].computed.alias('router.currentRouteName'),
    isAdminRoute: _ember['default'].computed('currentRoute', function () {
      return this.get('currentRoute').indexOf('manage-resources') !== -1;
    }),
    isNonAdminRoute: _ember['default'].computed('currentRoute', function () {
      return this.get('currentRoute').indexOf('logged-in') !== -1;
    }),

    mobile: false,

    helpDisabled: _ember['default'].computed(function () {

      if (this.get('isAdminRoute')) {
        return this.get('systemSettings').getSetting('disableHelpAdmin');
      }

      if (this.get('isNonAdminRoute')) {
        return this.get('systemSettings').getSetting('disableHelpNonAdmin');
      }

      return false;
    })
  });
});