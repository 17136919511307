define('scientia-resourcebooker/pods/components/resource-filters/number/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourceFilter-type-number'],
    identity: _ember['default'].computed.alias('property.identity'),
    customEvents: _ember['default'].inject.service(),
    min: null,
    max: null,

    minDidChange: (function () {
      this._updateValue();
    }).observes('min'),

    maxDidChange: (function () {
      this._updateValue();
    }).observes('max'),

    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        var customEvents = this.get('customEvents');
        customEvents.on('removeCustomFilter', this, 'removeValueOnFilterRemoved');
        customEvents.on('resetFilters', this, 'resetValues');
      });
    }).on('didRender'),

    removeValueOnFilterRemoved: function removeValueOnFilterRemoved(filter) {
      if (filter.identity === this.get('property.identity') && !this.get('isDestroyed')) {
        this.set(filter.key, null);
      }
    },

    resetValues: function resetValues() {
      if (!this.get('isDestroyed')) {
        this.set('min', null);
        this.set('max', null);
      }
    },

    onwillDestroyElement: (function () {
      if (!this.get('isDestroyed')) {
        this.set('min', null);
        this.set('max', null);
      }
    }).on('willDestroyElement'),

    // jshint maxcomplexity: 7
    _updateValue: function _updateValue() {
      var min = this.get('min');
      if (min === '') min = null;
      var max = this.get('max');
      if (max === '') max = null;

      var value = null;

      if (min || max) {
        value = {};
        if (min) value.min = parseInt(min, 10);
        if (max) value.max = parseInt(max, 10);
      }

      _ember['default'].run.debounce(this, 'send', 'valueDidChange', value, 1000, false);
    },

    actions: {

      onMinChange: function onMinChange(value) {
        if (!this.get('min') && !value) return;
        this.set('min', value);
      },

      onMaxChange: function onMaxChange(value) {
        if (!this.get('max') && !value) return;
        this.set('max', value);
      },

      clearValues: function clearValues() {
        this.resetValues();
      },

      valueDidChange: function valueDidChange(value) {
        this.attrs.numberChanged(this.get('identity'), value, this.get('property.computedDisplayName'));
      }
    }
  });
});