define('scientia-resourcebooker/services/date-time-formatter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    localePath: 'date_time_formats',
    defaultKey: 'default',
    i18n: _ember['default'].inject.service(),

    formatLookup: function formatLookup(key, variableKey) {
      var defaultFormat = this.get('i18n').t('error_messages.date_time_formats.default').toString();
      var parts = key.split('.');
      var parent = parts.slice(0, parts.length - 1);
      var keyName = parts[parts.length - 1];
      var formatParent = parent.join('.');
      var dateTimeFormatsPath = formatParent + '.' + this.localePath;
      var format = this.get('i18n').t(dateTimeFormatsPath + '.' + keyName + '.' + variableKey, { optional: true });
      return format ? format.string : defaultFormat;
    },

    format: function format(key, variableKey, val, forceUTCDateFormatting) {
      var format = this.formatLookup(key, variableKey);
      if (forceUTCDateFormatting) {
        return moment.utc(val).format(format);
      } else {
        return moment(val).format(format);
      }
    },

    isDate: function isDate(value) {
      if (Number.isInteger(value) || typeof value === 'number') return false;
      return value.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/) != null;
    }

  });
});