define('scientia-resourcebooker/models/concurrency-group', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({

    name: attr('string'),
    description: attr('string', { defaultValue: '' }),
    enabled: attr('boolean'),
    checkUnavailabilityPatterns: attr('boolean'),
    order: attr('number'),
    resources: attr('raw'),
    canDelete: attr('boolean'),
    canEdit: attr('boolean'),
    canViewCalendar: attr('boolean')
  });
});