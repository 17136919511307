define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/component', ['exports', 'ember'], function (exports, _ember) {
  var CUSTOM_OPTIONS_COMPONENT_PATH = 'manage-resources/booking-form-field/type-specific-options/%@';

  var ATTACHMENT = 'attachment';
  var SINGLECHOICE = 'single_choice';
  var LABEL = 'label';
  var MULTIPLECHOICE = 'multiple_choice';
  var NUMERIC = 'numeric';
  var TEXTBOX = 'textbox';
  var LINK = 'url';
  var FIELD_TYPES = [ATTACHMENT, LABEL, MULTIPLECHOICE, NUMERIC, SINGLECHOICE, TEXTBOX, LINK];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-groupWrapper at-manageResources-bookingFormField'],
    classNameBindings: ['displayReadOnly:readOnly'],

    bookingFormHelpToggler: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    specificOptionsForTypeComponent: _ember['default'].computed('model.fieldTypeName', function () {
      return CUSTOM_OPTIONS_COMPONENT_PATH.loc(this.get('model.fieldTypeName'));
    }),

    isShowingHelp: _ember['default'].computed.oneWay('bookingFormHelpToggler.isShowingHelp'),

    maxBookingFormTextLength: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('maxBookingFormTextLength');
    }),

    fieldTypes: (function () {
      var _this = this;

      return FIELD_TYPES.map(function (type) {
        return _this.get('i18n').t('components.manage_resources.booking_form_tab.value_types.' + type);
      });
    }).property('i18n.locale'),

    didReceiveAttrs: function didReceiveAttrs() {
      var isShowing = this.get('bookingFormHelpToggler.isShowingHelp');
      this.set('isShowingHelp', isShowing);

      _ember['default'].run.next(this, function () {
        _ember['default'].$('.manageResources-itemForm-form-label-help').css('display', isShowing ? 'block' : 'none');
      });
    },

    actions: {
      onSearchResourceType: function onSearchResourceType(query, deferred) {
        this.attrs.searchResourceType(query, deferred);
      },

      onSelectResourceType: function onSelectResourceType(externalResourceType) {
        this.set('model.externalResourceType', { identity: externalResourceType.id, name: externalResourceType.name });
      },

      toggleHelp: function toggleHelp() {
        this.get('bookingFormHelpToggler').toggle();
        var isShowing = this.get('bookingFormHelpToggler.isShowingHelp');
        this.set('isShowingHelp', isShowing);
        _ember['default'].run.next(this, function () {
          _ember['default'].$('.manageResources-itemForm-form-label-help').css('display', isShowing ? 'block' : 'none');
        });
      }
    }

  });
});