define("scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "externalLinkIconWrapper");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["content", "userGroup.name", ["loc", [null, [10, 10], [10, 28]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [10, 67], [10, 133]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 15,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "permissionsSummary-content-object permissionsSummary-content-object--noBorder");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "permissionsSummary-content-objectName");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", [], ["params", ["subexpr", "unbound", [["get", "userGroup.link", ["loc", [null, [9, 35], [9, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 26], [9, 50]]], 0, 0], "target", "_blank", "bubbles", false, "invokeAction", ["subexpr", "action", ["logLink", "user-group"], [], ["loc", [null, [9, 94], [9, 125]]], 0, 0]], 0, null, ["loc", [null, [9, 8], [11, 20]]]]],
          locals: ["userGroup"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 8
              },
              "end": {
                "line": 17,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.permissions_summary.no_groups"], [], ["loc", [null, [16, 52], [16, 100]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-content-section permissionsSummary-content-section--userGroups");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "permissionsSummary-content-rootName");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["components.permissions_summary.user_groups"], [], ["loc", [null, [5, 54], [5, 104]]], 0, 0], ["block", "each", [["get", "model", ["loc", [null, [6, 14], [6, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 6], [17, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 12
                  },
                  "end": {
                    "line": 34,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h3");
                dom.setAttribute(el1, "class", "permissionsSummary-content-objectHeader");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["content", "resource.header", ["loc", [null, [33, 66], [33, 85]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 14
                    },
                    "end": {
                      "line": 41,
                      "column": 14
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["content", "resource.name", ["loc", [null, [40, 16], [40, 33]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [40, 72], [40, 138]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 12
                  },
                  "end": {
                    "line": 43,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "link-to", [], ["params", ["subexpr", "unbound", [["get", "resource.link", ["loc", [null, [39, 41], [39, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 32], [39, 55]]], 0, 0], "target", "_blank", "bubbles", false, "invokeAction", ["subexpr", "action", ["logLink", "resource"], [], ["loc", [null, [39, 99], [39, 128]]], 0, 0]], 0, null, ["loc", [null, [39, 14], [41, 26]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 12
                  },
                  "end": {
                    "line": 45,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "resource.name", ["loc", [null, [44, 14], [44, 31]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 65,
                              "column": 26
                            },
                            "end": {
                              "line": 67,
                              "column": 26
                            }
                          },
                          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                            ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode(" ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("span");
                          dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                          var el2 = dom.createComment("");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(2);
                          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                          return morphs;
                        },
                        statements: [["content", "permission.requester.name", ["loc", [null, [66, 28], [66, 57]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [66, 96], [66, 162]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 63,
                            "column": 24
                          },
                          "end": {
                            "line": 69,
                            "column": 24
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["block", "link-to", ["manage-resources.user-group.edit", ["get", "permission.requester.id", ["loc", [null, [65, 72], [65, 95]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["selectedTab", 1], ["loc", [null, [65, 96], [65, 124]]], 0, 0]], ["target", "_blank", "bubbles", false, "invokeAction", ["subexpr", "action", ["logLink", "user-group"], [], ["loc", [null, [65, 168], [65, 199]]], 0, 0]], 0, null, ["loc", [null, [65, 26], [67, 38]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 69,
                            "column": 24
                          },
                          "end": {
                            "line": 71,
                            "column": 24
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["content", "permission.requester.name", ["loc", [null, [70, 26], [70, 55]]], 0, 0, 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 59,
                          "column": 20
                        },
                        "end": {
                          "line": 74,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue");
                      var el2 = dom.createTextNode("\n\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                      ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "if", [["subexpr", "and", [["get", "isAdmin", ["loc", [null, [63, 35], [63, 42]]], 0, 0, 0, 0], ["get", "permission.requester.isUserGroup", ["loc", [null, [63, 43], [63, 75]]], 0, 0, 0, 0], ["get", "permission.requester.isLinkable", ["loc", [null, [63, 76], [63, 107]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 30], [63, 108]]], 0, 0]], [], 0, 1, ["loc", [null, [63, 24], [71, 31]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 76,
                          "column": 20
                        },
                        "end": {
                          "line": 78,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue at-permissionValueHeader");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                      return morphs;
                    },
                    statements: [["inline", "t", ["components.permissions_summary.approvers"], [], ["loc", [null, [77, 104], [77, 152]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child2 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 84,
                              "column": 26
                            },
                            "end": {
                              "line": 86,
                              "column": 26
                            }
                          },
                          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                            ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode(" ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("span");
                          dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                          var el2 = dom.createComment("");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(2);
                          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                          return morphs;
                        },
                        statements: [["content", "approver.name", ["loc", [null, [85, 28], [85, 45]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [85, 84], [85, 150]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 82,
                            "column": 26
                          },
                          "end": {
                            "line": 88,
                            "column": 24
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["block", "link-to", ["manage-resources.user-group.edit", ["get", "approver.id", ["loc", [null, [84, 72], [84, 83]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["selectedTab", 1], ["loc", [null, [84, 84], [84, 112]]], 0, 0]], ["bubbles", false, "target", "_blank", "invokeAction", ["subexpr", "action", ["logLink", "user-group"], [], ["loc", [null, [84, 156], [84, 187]]], 0, 0]], 0, null, ["loc", [null, [84, 26], [86, 38]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 88,
                            "column": 24
                          },
                          "end": {
                            "line": 90,
                            "column": 24
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                          ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["content", "approver.name", ["loc", [null, [89, 26], [89, 43]]], 0, 0, 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 79,
                          "column": 20
                        },
                        "end": {
                          "line": 94,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                    },
                    isEmpty: false,
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "if", [["subexpr", "and", [["get", "isAdmin", ["loc", [null, [82, 37], [82, 44]]], 0, 0, 0, 0], ["get", "approver.isUserGroup", ["loc", [null, [82, 45], [82, 65]]], 0, 0, 0, 0], ["get", "approver.isLinkable", ["loc", [null, [82, 66], [82, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 32], [82, 86]]], 0, 0]], [], 0, 1, ["loc", [null, [82, 26], [90, 31]]]]],
                    locals: ["approver"],
                    templates: [child0, child1]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 57,
                        "column": 18
                      },
                      "end": {
                        "line": 96,
                        "column": 18
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "permissionsSummary-content-request");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element0, 1, 1);
                    morphs[1] = dom.createMorphAt(element0, 3, 3);
                    morphs[2] = dom.createMorphAt(element0, 4, 4);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "permission.requester", ["loc", [null, [59, 26], [59, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [59, 20], [74, 27]]]], ["block", "if", [["get", "permission.approvers.length", ["loc", [null, [76, 26], [76, 53]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [76, 20], [78, 27]]]], ["block", "each", [["get", "permission.approvers", ["loc", [null, [79, 28], [79, 48]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [79, 20], [94, 29]]]]],
                  locals: [],
                  templates: [child0, child1, child2]
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 100,
                            "column": 22
                          },
                          "end": {
                            "line": 102,
                            "column": 22
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode(" ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "externalLinkIconWrapper");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                        return morphs;
                      },
                      statements: [["content", "permission.name", ["loc", [null, [101, 24], [101, 43]]], 0, 0, 0, 0], ["inline", "inline-svg", ["icons/external-link.svg"], ["class", "externalLink-icon"], ["loc", [null, [101, 82], [101, 148]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 99,
                          "column": 20
                        },
                        "end": {
                          "line": 104,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [["block", "link-to", ["manage-resources.user-group.edit", ["get", "permission.id", ["loc", [null, [100, 68], [100, 81]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["selectedTab", 1], ["loc", [null, [100, 82], [100, 110]]], 0, 0]], ["bubbles", false, "target", "_blank", "invokeAction", ["subexpr", "action", ["logLink", "user-group"], [], ["loc", [null, [100, 154], [100, 185]]], 0, 0]], 0, null, ["loc", [null, [100, 22], [102, 34]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 104,
                          "column": 20
                        },
                        "end": {
                          "line": 106,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "permission.name", ["loc", [null, [105, 20], [105, 39]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 96,
                        "column": 18
                      },
                      "end": {
                        "line": 108,
                        "column": 18
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "and", [["get", "isAdmin", ["loc", [null, [99, 31], [99, 38]]], 0, 0, 0, 0], ["get", "permission.isUserGroup", ["loc", [null, [99, 39], [99, 61]]], 0, 0, 0, 0], ["get", "permission.isLinkable", ["loc", [null, [99, 62], [99, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 26], [99, 84]]], 0, 0]], [], 0, 1, ["loc", [null, [99, 20], [106, 27]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 16
                    },
                    "end": {
                      "line": 110,
                      "column": 16
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "permission.permissionName", ["loc", [null, [57, 28], [57, 53]]], 0, 0, 0, 0], "request"], [], ["loc", [null, [57, 24], [57, 64]]], 0, 0]], [], 0, 1, ["loc", [null, [57, 18], [108, 25]]]]],
                locals: ["permission"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 12
                  },
                  "end": {
                    "line": 115,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "permissionsSummary-content-table");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "permissionsSummary-content-permissionTypeName");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "permissionsSummary-content-permissionTypeValues");
                var el3 = dom.createTextNode("\n\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element1, [4]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", [["subexpr", "concat-strings", ["components.manage_resources.permissions_form.permission_types.", ["get", "permissionType.name", ["loc", [null, [52, 159], [52, 178]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 78], [52, 179]]], 0, 0]], [], ["loc", [null, [52, 74], [52, 181]]], 0, 0], ["block", "each", [["get", "permissionType.permissions", ["loc", [null, [56, 24], [56, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [56, 16], [110, 25]]]]],
              locals: ["permissionType"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 6
                },
                "end": {
                  "line": 118,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "permissionsSummary-content-object");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "permissionsSummary-content-objectName");
              var el3 = dom.createTextNode("\n\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element2, 2, 2);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [4]), 1, 1);
              morphs[2] = dom.createMorphAt(element2, 6, 6);
              return morphs;
            },
            statements: [["block", "if", [["get", "resource.header", ["loc", [null, [32, 18], [32, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 12], [34, 19]]]], ["block", "if", [["subexpr", "and", [["get", "isAdmin", ["loc", [null, [38, 23], [38, 30]]], 0, 0, 0, 0], ["get", "resource.isLinkable", ["loc", [null, [38, 31], [38, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 18], [38, 51]]], 0, 0]], [], 1, 2, ["loc", [null, [38, 12], [45, 19]]]], ["block", "each", [["get", "resource.permissions", ["loc", [null, [48, 20], [48, 40]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [48, 12], [115, 21]]]]],
            locals: ["resource"],
            templates: [child0, child1, child2, child3]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 6
                },
                "end": {
                  "line": 120,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.permissions_summary.no_permissions"], [], ["loc", [null, [119, 50], [119, 103]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 0
              },
              "end": {
                "line": 123,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            dom.setAttribute(el2, "class", "permissionsSummary-content-rootName");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
            morphs[2] = dom.createMorphAt(element3, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["permissionsSummary-content-section ", ["subexpr", "concat-strings", ["permissionsSummary-content-section--", ["get", "permissionTypeValue.name", ["loc", [null, [24, 109], [24, 133]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 53], [24, 135]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.permissions_summary.", ["get", "permissionTypeValue.name", ["loc", [null, [26, 108], [26, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 58], [26, 133]]], 0, 0]], [], ["loc", [null, [26, 54], [26, 135]]], 0, 0], ["block", "each", [["get", "permissionTypeValue.values", ["loc", [null, [27, 14], [27, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [27, 6], [120, 15]]]]],
          locals: ["permissionType", "permissionTypeValue"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 124,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each-in", [["get", "model", ["loc", [null, [22, 11], [22, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 0], [123, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 125,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/tab-content/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isUserGroupTab", ["loc", [null, [2, 6], [2, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 0], [124, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});