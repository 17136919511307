define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 8
            },
            "end": {
              "line": 43,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-itemList");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "custom-checkbox", [], ["checked", ["subexpr", "contains", [["get", "fileType.value", ["loc", [null, [36, 28], [36, 42]]], 0, 0, 0, 0], ["get", "model.attachmentTypes", ["loc", [null, [36, 43], [36, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 18], [36, 65]]], 0, 0], "action", ["subexpr", "action", ["toggleAttachmentTypeOption", ["get", "fileType", ["loc", [null, [37, 54], [37, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 17], [37, 63]]], 0, 0], "class", "form-input manageResources-itemForm-form-checkbox"], ["loc", [null, [35, 10], [39, 12]]], 0, 0], ["content", "fileType.label", ["loc", [null, [41, 62], [41, 80]]], 0, 0, 0, 0]],
        locals: ["fileType"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 6
            },
            "end": {
              "line": 57,
              "column": 7
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-item--content");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "validated-input", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [50, 16], [50, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "valuePath", "customAttachmentTypes", "placeholder", "txt,doc,rtf,gif,png...", "label", ["subexpr", "t", ["components.manage_resources.booking_form_field.attachment.custom_explanation"], [], ["loc", [null, [53, 16], [53, 98]]], 0, 0], "inputClasses", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--editing at-manageResources-bookingForm-attachment-customTypes", "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [55, 22], [55, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [49, 10], [55, 35]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 7
            },
            "end": {
              "line": 58,
              "column": 102
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "attachmentTypes"], [], [], 0, 0], "message"], [], ["loc", [null, [58, 52], [58, 95]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-attachment/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item manageResources-itemForm-inputList at-bookingForm");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-item--content");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("       ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element3, 5, 5);
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.booking_form_field.attachment.restrict_filetypes"], [], ["loc", [null, [25, 6], [25, 90]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.attachment.restrict_filetypes_explanation"], [], ["loc", [null, [27, 8], [27, 104]]], 0, 0], ["block", "each", [["get", "attachmentTypesAvailable", ["loc", [null, [33, 16], [33, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 8], [43, 17]]]], ["block", "if", [["subexpr", "contains", [6, ["get", "model.attachmentTypes", ["loc", [null, [47, 24], [47, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 12], [47, 46]]], 0, 0]], [], 1, null, ["loc", [null, [47, 6], [57, 14]]]], ["block", "if", [["get", "didValidate", ["loc", [null, [58, 13], [58, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [58, 7], [58, 109]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});