define('scientia-resourcebooker/pods/components/version-information/component', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',
    classNames: ['version-information'],
    configFetcher: _ember['default'].inject.service(),
    versionWebApp: _scientiaResourcebookerConfigEnvironment['default'].WEB_VERSION || '',
    versionAPI: _ember['default'].computed.alias('configFetcher.config.Version')

  });
});