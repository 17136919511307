define('scientia-resourcebooker/adapters/booking-type', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    // queryRecord needs to use a different endpoint when there is a query param "editFromPage: manage-resources / report".
    // This indicates that we are fetching the booking-type after clicking "Edit Booking" on the Resource Calendar / Report by someone
    // with "Manage Bookings" permission. Someone with "Manage Bookings" permission is allowed to edit a Booking however
    // if user only has that permission but no Show permission on the template, when the user is getting redirected to edit booking
    // the booking-type will fail to fetch due to no permissions on it. This new endpoint will make sure to validate the manage-bookings permision
    // See RB-385
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {

      // Just do normal query if it's not editing from resource-calendar or report
      if (query.editFromPage !== 'manage-resources' && query.editFromPage !== 'report') return this._super.apply(this, arguments);

      // If it is those 2 special cases use different endpoint
      var url = this._buildURL(modelName, query.id);
      url += '/ManageBookings/%@'.loc(query.resourceId);
      delete query.id;
      delete query.resourceId;
      delete query.editFromPage;
      return url;
    }
  });
});