define('scientia-resourcebooker/pods/components/booking-report-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingReportList'],
    classNameBindings: ['location'],
    model: null,
    timeframeStartRange: null,
    timeframeEndRange: null,

    loadingBookingRequest: false,
    filterActiveBookings: false,
    bookingReportHelper: _ember['default'].inject.service(),

    filteredModel: _ember['default'].computed('model', 'filterActiveBookings', function () {
      var shouldFilter = this.get('filterActiveBookings');
      var model = this.get('model');

      var data = shouldFilter ? model.filterBy('isCurrentlyActive') : model;

      var timeframeFrom = this.get('timeframeStartRange');
      var timeframeTo = this.get('timeframeEndRange');

      return this.get('bookingReportHelper').groupBookingsByDate(data, timeframeFrom, timeframeTo);
    })

  });
});