define('scientia-resourcebooker/serializers/property', ['exports', 'scientia-resourcebooker/serializers/application', 'ember'], function (exports, _scientiaResourcebookerSerializersApplication, _ember) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend({
    store: _ember['default'].inject.service(),

    attrs: {
      resource: { serialize: false },
      autoDisplayId: { serialize: false }
    },

    normalize: function normalize(modelClass, hash, prop) {
      var applicationSerializer = this.get('store').serializerFor('application');
      hash = applicationSerializer._camelizeKeysDeep(hash);

      if (hash.valueType) hash.valueType = hash.valueType.toLowerCase();
      if (hash.ValueType) hash.ValueType = hash.ValueType.toLowerCase();

      if (hash.valueChoicesWithId && hash.valueChoicesWithId.length) {
        hash.valueChoicesWithId.forEach(function (v) {
          v.id = v.identity;
        });
      }

      if (hash.restrictedVisibilityPermissionGroups && hash.restrictedVisibilityPermissionGroups.length) {
        hash.restrictedVisibilityPermissionGroups.forEach(function (v) {
          v.id = v.identity;
        });
      }

      var results = this._super(modelClass, hash, prop);
      return results;
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.restrictedVisibilityPermissionGroups && json.restrictedVisibilityPermissionGroups.length) {
        json.restrictedVisibilityPermissionGroups.forEach(function (g) {
          g.identity = g.id;
        });
      }
      return json;
    }
  });
});