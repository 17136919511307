define('scientia-resourcebooker/pods/components/manage-resources/availability-week-exceptions-modal/component', ['exports', 'ember'], function (exports, _ember) {

  var DATE_FORMAT = 'YYYY-MM-DD';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['availabilityWeekExceptions'],
    model: null,

    disabled: false,

    systemSettings: _ember['default'].inject.service(),

    selectedDate: null,
    isLoading: false,
    canAddRange: true,

    hasPendingAdd: _ember['default'].computed.filterBy('model', 'isPendingAdd', true),
    hasPendingRemove: _ember['default'].computed.filterBy('model', 'isPendingRemove', true),
    hasPendingChanges: _ember['default'].computed('hasPendingAdd', 'hasPendingRemove', function () {
      return this.get('hasPendingAdd.length') || this.get('hasPendingRemove.length');
    }),

    actions: {
      updateSelectedDate: function updateSelectedDate(date) {
        if (this.get('disabled')) return;

        var startDate = moment(date, DATE_FORMAT);
        this.set('selectedDate', startDate);

        _ember['default'].run.later(this, function () {

          var tr = _ember['default'].$('.datepicker .day.active').parent('tr');
          tr.addClass('datepicker--weekSelected');
        }, 50);

        this.set('canAddRange', true);
      },

      onUpdateCalendarView: function onUpdateCalendarView() {
        this.set('canAddRange', false);
      },

      onRangeAdd: function onRangeAdd() {
        var firstDayOfTheWeekOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
        var start = this.get('selectedDate').clone().startOf('day');

        while (start.day() !== firstDayOfTheWeekOffset) {
          start.subtract(1, 'day');
        }

        var end = start.clone().endOf('day').add(6, 'day');

        this.get('model').pushObject(_ember['default'].Object.create({
          fromTime: start,
          toTime: end,
          duration: end.diff(start, 'minute'),
          isPendingAdd: true,
          isPendingRemove: false,
          isException: true
        }));
      },

      onRemoveClick: function onRemoveClick(item) {
        if (item.get('isPendingAdd')) {
          this.send('onRangeAddUndo', item);
        } else if (item.get('isPendingRemove')) {
          this.send('onRangeRemoveUndo', item);
        } else {
          this.send('onRangeRemove', item);
        }
      },

      onRangeAddUndo: function onRangeAddUndo(item) {
        this.get('model').removeObject(item);
      },

      onRangeRemove: function onRangeRemove(item) {
        item.set('isPendingRemove', true);
      },

      onRangeRemoveUndo: function onRangeRemoveUndo(item) {
        item.set('isPendingRemove', false);
      },

      cancel: function cancel() {
        this.get('undo')();
      },

      save: function save() {
        this.get('save')(this.get('model'));
      }

    }
  });
});