define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {

    concurrencyGroupsPersister: _ember['default'].inject.service(),
    concurrencyConfigurationSearcher: _ember['default'].inject.service(),
    concurrencyGroupSearcher: _ember['default'].inject.service(),

    store: _ember['default'].inject.service(),

    queryParams: {
      query: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        concurrencyGroups: this._fetchConcurrencyGroups(params),
        concurrencyConfigurations: this._fetchConcurrencyConfigurations(params)
      });
    },

    _fetchConcurrencyConfigurations: function _fetchConcurrencyConfigurations(params) {
      return this.get('concurrencyConfigurationSearcher').search(params);
    },

    _fetchConcurrencyGroups: function _fetchConcurrencyGroups(params) {
      return this.get('concurrencyGroupSearcher').search(params);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          query: '',
          boundQuery: ''
        });
      }
    }
  });
});