define('scientia-resourcebooker/pods/components/manage-resources/booking-form-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'component.manage_resources.booking_form_form';

  exports['default'] = _ember['default'].Component.extend({
    item: null,
    submitAction: null,
    localePrefix: DEFAULT_LOCALE_PREFIX,

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property()

  });
});