define('scientia-resourcebooker/services/bulk-claim-clearer', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    clear: function clear(_ref) {
      var claimType = _ref.claimType;
      var claimValue = _ref.claimValue;

      var data = { claimType: claimType, claimValue: claimValue };
      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.USER_CLAIM_BULK_CLEAR, data);
    }

  });
});