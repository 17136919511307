define("scientia-resourcebooker/pods/components/booking-report-list/header/header-actions/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 6
              },
              "end": {
                "line": 11,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-actions/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.reports.bookings.export_csv"], [], ["loc", [null, [10, 8], [10, 54]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-actions/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dropdown-button-item", [], ["action", ["subexpr", "action", [["get", "onExportCSV", ["loc", [null, [9, 45], [9, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 37], [9, 57]]], 0, 0], "disabled", ["subexpr", "not", [["get", "hasResults", ["loc", [null, [9, 72], [9, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 67], [9, 83]]], 0, 0]], 0, null, ["loc", [null, [9, 6], [11, 31]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-actions/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "button button--white button--withIcon at-bookingReportList-refresh");
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "button--icon");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "button-label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "dropdown-button", [], ["label", ["subexpr", "t", ["components.reports.bookings.export"], [], ["loc", [null, [3, 10], [3, 50]]], 0, 0], "classes", ["subexpr", "if", [["subexpr", "not", [["get", "hasResults", ["loc", [null, [4, 21], [4, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 16], [4, 32]]], 0, 0], "button button--white actionIcon--fill-gray", "button button--white"], [], ["loc", [null, [4, 12], [4, 101]]], 0, 0], "disabled", ["subexpr", "not", [["get", "hasResults", ["loc", [null, [5, 18], [5, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 13], [5, 29]]], 0, 0], "contentClasses", "at-positionLeft", "icon", "icons/download.svg", "location", "at-bookingReports-export at-lf-dialog-footer"], 0, null, ["loc", [null, [2, 2], [12, 24]]]], ["inline", "button-print", [], ["onClick", ["subexpr", "action", [["get", "onPrintClick", ["loc", [null, [14, 35], [14, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 27], [14, 48]]], 0, 0], "location", "at-bookingReportList"], ["loc", [null, [14, 4], [14, 82]]], 0, 0], ["element", "action", [["subexpr", "action", [["get", "onRefreshReport", ["loc", [null, [17, 29], [17, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 21], [17, 45]]], 0, 0]], [], ["loc", [null, [17, 12], [17, 47]]], 0, 0], ["inline", "inline-svg", ["icons/refresh.svg"], [], ["loc", [null, [18, 33], [18, 67]]], 0, 0], ["inline", "t", ["general.refresh"], [], ["loc", [null, [19, 33], [19, 56]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});