define('scientia-resourcebooker/pods/components/manage-resources/object-usage-summary/tab-content/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    appInsights: _ember['default'].inject.service(),

    actions: {
      logLink: function logLink(objectType, object) {
        var data = {
          targetType: objectType.name,
          targetName: object.name,
          targetId: object.id,
          id: this.get('objectId'),
          name: this.get('objectName')
        };
        this.get('appInsights').trackEvent('availability-summary-link', data);
      }
    }
  });
});