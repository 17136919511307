define('scientia-resourcebooker/abilities/booking-form', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canList: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canEdit: function canEdit() {
      return this.get('currentUser').isAdmin();
    },

    canDelete: function canDelete() {
      return this.get('currentUser').isAdmin();
    }
  });
});