define('scientia-resourcebooker/pods/manage-resources/user-group/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/config/environment', 'ember-concurrency', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment, _emberConcurrency, _scientiaResourcebookerMixinsFlashMessages) {
  var TABS = { DESCRIPTION: 0, CLAIMS: 1, PERMISSIONS: 2 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    queryParams: ['isNew', { selectedTab: 'section' }],

    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),
    userGroupPersister: _ember['default'].inject.service(),
    resourcePermissionSummaryFetcher: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    taskProgressModalDisplayer: _ember['default'].inject.service(),

    name: '',

    selectedTab: 0,
    isSaving: false,
    error: '',
    fetchError: false,

    showResourcePermissionSummaryModal: null,

    permissionsTypes: [{ value: 'edit-claims' }],

    isResourceOwnerGroup: _ember['default'].computed('model.userGroup.id', function () {
      return this.get('model.userGroup.id') === _scientiaResourcebookerConfigEnvironment['default'].IDS.USER_GROUP_RESOURCE_OWNER;
    }),

    permissions: _ember['default'].computed('model.userGroup.editClaimsPermissionGroups', function () {
      var model = this.get('model.userGroup');
      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(model);
      return fragments;
    }),

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        return this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), TABS.PERMISSIONS);
      }

      return true;
    },

    _displayError: function _displayError(error, tabIndex) {
      this.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    },

    fetchPermissionSummary: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var result;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;
            context$1$0.next = 3;
            return this.get('resourcePermissionSummaryFetcher').fetchForUserGroup(this.get('model.userGroup.id'));

          case 3:
            result = context$1$0.sent;

            this.set('showResourcePermissionSummaryModal', result);

            context$1$0.next = 10;
            break;

          case 7:
            context$1$0.prev = 7;
            context$1$0.t0 = context$1$0['catch'](0);

            this.set('fetchError', true);

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[0, 7]]);
    })).drop(),

    actions: {

      save: function save() {
        var transitionIndex = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var model = this.get('model.userGroup');

        this.set('error', null);

        if (_ember['default'].isEmpty(model.get('name'))) {
          return this._displayError(this.get('i18n').t('error_messages.usergroup_noname').toString(), TABS.DESCRIPTION);
        }

        // Validate claim
        var emptyClaim = model.get('claims').find(function (claim) {
          return _ember['default'].isEmpty(claim.get('claimValue'));
        });
        if (emptyClaim) {
          var errorMessage = this.get('i18n').t('pods.manage_resources.user_group.index.empty_claim_error');
          return this._displayError(errorMessage, TABS.CLAIMS);
        }

        var isEditingClaims = model.get('claims').isAny('isEditing');
        if (isEditingClaims) {
          return this._displayError(this.get('i18n').t('pods.manage_resources.user_group.index.pending_claim_error'), TABS.CLAIMS);
        }

        // Validate permissions
        var permissions = this.get('permissions');
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, model.get('permissionsKeys'));
        Object.keys(permissionFragments).forEach(function (type) {
          model.get(type).setObjects(permissionFragments[type]);
        });

        this.send('updateUserGroup', model, transitionIndex);
      },

      deleteModel: function deleteModel() {
        var _this = this;

        var defer = _ember['default'].RSVP.defer();

        var confirmData = {
          titlePath: 'pods.manage_resources.user_group.index.confirm_delete_title',
          messagePath: 'pods.manage_resources.user_group.index.confirm_delete_message',
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.confirm'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this.send('deleteModelConfirm');
        })['catch'](function () {
          _this.get('globalConfirmer').close();
        });
      },

      deleteModelConfirm: function deleteModelConfirm() {
        var _this2 = this;

        if (this.get('model.userGroup.claims.length') >= 300) {
          return this._deleteWithProgress();
        }

        this.get('model.userGroup').destroyRecord().then(function () {
          _this2.get('globalConfirmer').close();
          _this2.deleteSuccessFlash('user-group');
          _this2.transitionToRoute('manage-resources.user-group.index');
        })['catch'](function () {
          _this2.get('globalConfirmer').close();
          _this2.deleteErrorFlash('user-group');
        });
      },

      onCancel: function onCancel() {
        this.transitionToRoute('manage-resources.user-group.index');
      },

      showResourcePermissionSummary: function showResourcePermissionSummary() {
        this.get('fetchPermissionSummary').perform();
      }
    },

    _deleteWithProgress: function _deleteWithProgress() {
      var _this3 = this;

      this.get('userGroupPersister').getDeleteTaskKey(this.get('model.userGroup.id')).then(function (taskKey) {
        _this3.get('taskProgressModalDisplayer').show(taskKey).then(function () {
          _this3.deleteSuccessFlash('user-group');
          _this3.transitionToRoute('manage-resources.user-group.index');
        })['catch'](function () {
          return _this3.deleteErrorFlash('user-group');
        })['finally'](function () {
          return _this3.get('globalConfirmer').close();
        });
      });
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', 'model.userGroup.name', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.user_group.index.user_groups'),
        path: 'manage-resources.user-group.index'
      }, {
        label: this.get('model.userGroup.name'),
        linkable: false
      }];
    })
  });
});