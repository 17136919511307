define('scientia-resourcebooker/services/rules-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var DEPRECATED_PROPERTIES = ['747c6f81-fbdc-4ec8-b499-b3832a9049a7'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    create: function create(_ref) {
      var _this = this;

      var model = _ref.model;
      var rule = _ref.rule;

      var type = model.constructor.modelName.camelize();
      var typeId = model.id;
      var typeEndpoint = _scientiaResourcebookerConfigEnvironment['default'].API.RULES.CREATE[type];

      var newRule = _ember['default'].Object.create(JSON.parse(JSON.stringify(rule)));
      newRule.properties.forEach(function (property) {
        if (property.endRange) {
          var value = property.value;
          // Ember.set(property, 'value', moment.utc(value).endOf('day'));
          _ember['default'].set(property, 'value', moment(value).endOf('day'));
        } // else if (property.valueType === 'date') {
        // let value = property.value;
        // Ember.set(property, 'value', moment.utc(value).startOf('day'));
        // }
      });

      return this.get('authenticatedRequest').post(typeEndpoint.loc(typeId), newRule).then(function (rule) {
        return _this._filterRulesDeprecatedProperties(rule);
      });
    },

    'delete': function _delete(_ref2) {
      var model = _ref2.model;
      var rule = _ref2.rule;

      var type = model.constructor.modelName.camelize();
      var typeId = model.id;
      var typeEndpoint = _scientiaResourcebookerConfigEnvironment['default'].API.RULES.UPDATE_OR_DELETE[type];
      var ruleId = rule.id;

      return this.get('authenticatedRequest')['delete'](typeEndpoint.loc(typeId, ruleId));
    },

    update: function update(_ref3) {
      var _this2 = this;

      var model = _ref3.model;
      var rule = _ref3.rule;

      var type = model.constructor.modelName.camelize();
      var typeId = model.id;
      var typeEndpoint = _scientiaResourcebookerConfigEnvironment['default'].API.RULES.UPDATE_OR_DELETE[type];
      var ruleId = rule.id;

      rule.properties.forEach(function (property) {
        if (property.endRange) {
          var value = property.value;
          _ember['default'].set(property, 'value', moment(value).endOf('day'));
        } // else if (property.valueType === 'date') {
        // let value = property.value;
        // Ember.set(property, 'value', moment(value).startOf('day'));
        // }
      });

      return this.get('authenticatedRequest').put(typeEndpoint.loc(typeId, ruleId), rule).then(function (rule) {
        return _this2._filterRulesDeprecatedProperties(rule);
      });
    },

    saveAllRules: function saveAllRules(_ref4) {
      var _this3 = this;

      var model = _ref4.model;
      var rules = _ref4.rules;

      var promises = [];

      rules.forEach(function (rule) {
        promises.push(_this3.update({ model: model, rule: rule }));
      });

      return _ember['default'].RSVP.all(promises);
    },

    _filterRulesDeprecatedProperties: function _filterRulesDeprecatedProperties(rule) {
      if (rule.properties) {
        rule.properties = rule.properties.reject(function (rule) {
          return DEPRECATED_PROPERTIES.includes(rule.id);
        });
      }
      return rule;
    }

  });
});