define('scientia-resourcebooker/services/unavailability-event-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(resourceTypeId, resourceId, eventId, startDate, endDate) {

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.UNAVAILABILITY_EVENTS.loc(resourceTypeId, resourceId, eventId);
      var data = { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
      return this.get('authenticatedRequest').fetch(url, data);
    }
  });
});