define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  Test\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-2", [], ["query", "externalDataSourceSearch", "optionIdPath", "value", "optionLabelPath", "text", "searchEnabled", true, "label", ["subexpr", "@mut", [["get", "description", ["loc", [null, [20, 12], [20, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "ariaLabel", ["subexpr", "@mut", [["get", "description", ["loc", [null, [21, 16], [21, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "typeaheadSearchingText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadSearchingText"], [], ["loc", [null, [23, 8], [25, 9]]], 0, 0]], [], ["loc", [null, [22, 29], [26, 7]]], 0, 0], "typeaheadNoMatchesText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadNoMatchesText"], [], ["loc", [null, [28, 8], [30, 9]]], 0, 0]], [], ["loc", [null, [27, 29], [31, 7]]], 0, 0], "typeaheadErrorText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadErrorText"], [], ["loc", [null, [33, 8], [35, 9]]], 0, 0]], [], ["loc", [null, [32, 25], [36, 7]]], 0, 0], "optionDescriptionPath", "we-dont-want-description", "class", "at-bookingRequestForm-field-singleChoice-dropdown"], ["loc", [null, [15, 4], [39, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 4
                },
                "end": {
                  "line": 44,
                  "column": 50
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "externalDataError", ["loc", [null, [44, 29], [44, 50]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 4
                },
                "end": {
                  "line": 48,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "loading-icon", ["loc", [null, [47, 6], [47, 22]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 10
                    },
                    "end": {
                      "line": 70,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList");
                  var el2 = dom.createTextNode("\n\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "bookingRequestForm-field-singleChoice-itemList-label");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element0, 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "radio-button", [], ["name", ["subexpr", "@mut", [["get", "model.identity", ["loc", [null, [58, 21], [58, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [59, 22], [59, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", true, "class", "form-input at-bookingRequestForm-field-singleChoice-itemList", "type", "radio"], ["loc", [null, [57, 14], [63, 16]]], 0, 0], ["content", "item.text", ["loc", [null, [67, 15], [67, 28]]], 0, 0, 0, 0]],
                locals: ["item"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 6
                  },
                  "end": {
                    "line": 74,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("fieldset");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("legend");
                dom.setAttribute(el2, "class", "sr-only");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element1, 3, 3);
                return morphs;
              },
              statements: [["content", "model.description", ["loc", [null, [52, 34], [52, 55]]], 0, 0, 0, 0], ["block", "each", [["get", "externalDataSourceResponse", ["loc", [null, [54, 18], [54, 44]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 10], [70, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 4
                },
                "end": {
                  "line": 75,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "externalDataSourceResponse", ["loc", [null, [50, 12], [50, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [50, 6], [74, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 2
              },
              "end": {
                "line": 76,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "externalDataError", ["loc", [null, [44, 10], [44, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [44, 4], [44, 57]]]], ["block", "if", [["get", "isFetchingData", ["loc", [null, [46, 10], [46, 24]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [46, 4], [75, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 78,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "isDropdown", ["loc", [null, [14, 8], [14, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 2], [76, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 79,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/external-data-source-preview/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "async-button", [], ["action", ["subexpr", "action", ["togglePreviewControl"], [], ["loc", [null, [3, 9], [3, 40]]], 0, 0], "type", "button", "class", "button button--primary"], 0, null, ["loc", [null, [2, 0], [8, 17]]]], ["block", "if", [["get", "showPreviewControl", ["loc", [null, [11, 6], [11, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 0], [78, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});