define("scientia-resourcebooker/pods/components/toggle-switch/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/toggle-switch/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'disabled');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 19], [1, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["button toggleSwitch-button ", ["subexpr", "if", [["get", "checked", ["loc", [null, [1, 103], [1, 110]]], 0, 0, 0, 0], "toggleSwitch-button--active"], [], ["loc", [null, [1, 98], [1, 142]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "onToggle", ["loc", [null, [1, 39], [1, 47]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "checked", ["loc", [null, [1, 53], [1, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 48], [1, 61]]], 0, 0]], [], ["loc", [null, [1, 30], [1, 63]]], 0, 0], ["inline", "if", [["get", "checked", ["loc", [null, [2, 5], [2, 12]]], 0, 0, 0, 0], ["get", "trueLabel", ["loc", [null, [2, 13], [2, 22]]], 0, 0, 0, 0], ["get", "falseLabel", ["loc", [null, [2, 23], [2, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 0], [2, 35]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});