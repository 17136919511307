define("scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 2
                },
                "end": {
                  "line": 18,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/booking-form-field", [], ["model", ["subexpr", "@mut", [["get", "field", ["loc", [null, [7, 12], [7, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "rank", ["subexpr", "@mut", [["get", "model.rank", ["loc", [null, [8, 11], [8, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "displayReadOnly", true, "addItem", ["subexpr", "@mut", [["get", "attrs.addItem", ["loc", [null, [10, 14], [10, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "deleteItem", ["subexpr", "@mut", [["get", "attrs.deleteItem", ["loc", [null, [11, 17], [11, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "rollbackItem", ["subexpr", "@mut", [["get", "attrs.rollbackItem", ["loc", [null, [12, 19], [12, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleEditing", ["subexpr", "@mut", [["get", "attrs.toggleEditing", ["loc", [null, [13, 20], [13, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "searchResourceType", ["subexpr", "@mut", [["get", "attrs.searchResourceType", ["loc", [null, [14, 25], [14, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [15, 18], [15, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "isEditingAnyField", ["subexpr", "@mut", [["get", "isEditingAnyField", ["loc", [null, [16, 24], [16, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 4], [17, 6]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 19,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "sortable-item", [], ["model", ["subexpr", "@mut", [["get", "field", ["loc", [null, [5, 25], [5, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [5, 37], [5, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".handle"], 0, null, ["loc", [null, [5, 2], [18, 20]]]]],
          locals: ["field"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 0
              },
              "end": {
                "line": 27,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "manageResources-itemForm-form-item");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-emptyInstruction");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.booking_form_tab.add_item_instruction"], [], ["loc", [null, [24, 6], [24, 79]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["subexpr", "sort-by", ["rank", ["get", "model.fields", ["loc", [null, [4, 26], [4, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 10], [4, 39]]], 0, 0]], [], 0, 1, ["loc", [null, [4, 2], [27, 9]]]]],
        locals: ["group"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-tab/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-actions at-bookingFormTab");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "type", "button");
        dom.setAttribute(el3, "class", "button button--primary at-bookingForm-addField");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [2]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createAttrMorph(element2, 'disabled');
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(element2, 0, 0);
        return morphs;
      },
      statements: [["block", "sortable-group", [], ["classNames", "sortableContainer at-bookingForm-fields", "direction", "y", "onChange", ["subexpr", "action", [["get", "reorderItems", ["loc", [null, [3, 102], [3, 114]]], 0, 0, 0, 0], ["get", "model.rank", ["loc", [null, [3, 115], [3, 125]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 94], [3, 126]]], 0, 0]], 0, null, ["loc", [null, [3, 0], [28, 19]]]], ["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "possibleValueTypes", ["loc", [null, [31, 10], [31, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "selectedNewFieldType", ["loc", [null, [34, 8], [34, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "didSelect", ["subexpr", "action", [["subexpr", "mut", [["get", "selectedNewFieldType", ["loc", [null, [35, 25], [35, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 20], [35, 46]]], 0, 0]], [], ["loc", [null, [35, 12], [35, 47]]], 0, 0], "searchEnabled", false, "placeholder", ["subexpr", "t", ["components.resource_filters.select"], [], ["loc", [null, [37, 14], [37, 54]]], 0, 0], "class", "manageResources-itemForm-form-input manageResources-itemForm-form-input--rule at-manageResources-itemForm-rule at-manageResources-bookingForm-addItem"], ["loc", [null, [30, 4], [39, 2]]], 0, 0], ["attribute", "disabled", ["get", "addFieldDisabled", ["loc", [null, [41, 75], [41, 91]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "addItem", ["loc", [null, [41, 33], [41, 40]]], 0, 0, 0, 0], ["get", "selectedNewFieldType", ["loc", [null, [41, 41], [41, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 24], [41, 63]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_tab.add_item"], [], ["loc", [null, [41, 149], [41, 210]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});