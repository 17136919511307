define('scientia-resourcebooker/pods/components/date-period-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['datePeriodSelector'],
    classNameBindings: ['location'],

    model: null,
    selected: null,

    startDate: null,
    endDate: null,

    isModal: false,

    // Let's backup the properties in case that, in modal mode, user clicks cancel
    _startDate: null,
    _endDate: null,
    _selected: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        _startDate: this.get('startDate'),
        _endDate: this.get('endDate'),
        _selected: this.get('selected')
      });
    },

    actions: {

      onTimeframeChange: function onTimeframeChange(timeframe) {
        this.get('onChange')(timeframe);
      },

      onStartRangeChange: function onStartRangeChange(startDate) {
        this.get('onRangeChange')(startDate, this.get('endDate'));
      },

      onEndRangeChange: function onEndRangeChange(endDate) {
        this.get('onRangeChange')(this.get('startDate'), endDate);
      },

      onModalConfirm: function onModalConfirm() {
        var props = this.getProperties(['selected', 'startDate', 'endDate']);
        this.get('onModalConfirm')(props);
      },

      onModalCancel: function onModalCancel() {
        var initialProps = this.getProperties(['_selected', '_startDate', '_endDate']);
        this.get('onModalCancel')(initialProps);
      }

    }

  });
});