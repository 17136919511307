define('scientia-resourcebooker/pods/print/calendar/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    printHandler: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.booking_request.index.my_bookings').toString();
    },

    queryParams: {
      startRange: { replace: true, refreshModel: true },
      endRange: { replace: true, refreshModel: false },
      from: { replace: true, refreshModel: false },
      viewType: { replace: true, refreshModel: false }
    },

    model: function model(params, transition) {

      if (transition.queryParams.from === 'my-bookings') {
        return this._fetchMyBookings(params);
      } else {
        return [];
      }
    },

    _fetchMyBookings: function _fetchMyBookings(params) {
      return this.get('store').query('booking-request', {
        startRange: params.startRange,
        endRange: params.endRange
      }).then(function (bookings) {
        return bookings.toArray().filter(function (b) {
          return b.get('startDateTime').isSameOrAfter(moment(params.startRange), 'month');
        }).map(function (b) {
          return b.toJSON();
        });
      });
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackEvent('calendar-print');

      _ember['default'].run.next(this, function () {

        this.send('updateHeader', this.get('i18n').t('pods.logged_in.booking_request.index.my_bookings').toString().toUpperCase());

        var viewType = transition.queryParams.viewType;
        var formattedDateRange = undefined;

        if (viewType === 'month' || viewType === 'agenda') {
          formattedDateRange = moment(transition.queryParams.startRange).format(this.get('i18n').t('date_formats.report_month').toString());
        } else if (viewType === 'day') {
          formattedDateRange = moment(transition.queryParams.startRange).format(this.get('i18n').t('date_formats.report_day').toString());
        } else {
          var dateFormat = this.get('i18n').t('date_formats.date_full').toString();
          formattedDateRange = moment(transition.queryParams.startRange).format(dateFormat) + ' - ' + moment(transition.queryParams.endRange).format(dateFormat);
        }

        this.send('updateSubheader', formattedDateRange);
        _ember['default'].run.later(this, function () {
          window.print();
        }, 500);
      });
    }
  });
});