define('scientia-resourcebooker/pods/components/search-results-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['resourcesList-item'],

    type: '',
    result: null,

    isBookingType: _ember['default'].computed.equal('type', 'bookingType'),
    isResourceGroup: _ember['default'].computed.equal('type', 'resourceGroup'),
    isResource: _ember['default'].computed.equal('type', 'resource'),

    globalSearcher: _ember['default'].inject.service(),

    actions: {
      onResourceClick: function onResourceClick(resource) {
        var _this = this;

        var type = arguments.length <= 1 || arguments[1] === undefined ? 'resource' : arguments[1];

        this.set('isLoading', true);

        this.get('globalSearcher').bookingTypesValidForResource(resource, type).then(function (bookingTypesAvailable) {
          if (_ember['default'].isEmpty(bookingTypesAvailable)) {
            return _this.get('flashMessages').warning(_this.get('i18n').t('components.global_search_field.resource_not_available'));
          }
          _this.sendAction('onResourceClick', bookingTypesAvailable, resource, type);
        })['finally'](function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });
});