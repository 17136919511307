define("scientia-resourcebooker/pods/components/manage-resources/claims-form/claim-value/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/claim-value/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--select");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "class", "form-input manageResources-itemForm-form-input");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element0, 'aria-label');
        morphs[2] = dom.createAttrMorph(element0, 'value');
        morphs[3] = dom.createAttrMorph(element0, 'disabled');
        morphs[4] = dom.createAttrMorph(element0, 'oninput');
        return morphs;
      },
      statements: [["inline", "rb-select", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [3, 13], [3, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "claimTypeValues", ["loc", [null, [4, 12], [4, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "claim.claimType", ["loc", [null, [5, 10], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "value", "optionLabelPath", "label", "class", "at-claimsForm"], ["loc", [null, [2, 2], [9, 4]]], 0, 0], ["attribute", "aria-label", ["get", "claim.claimType", ["loc", [null, [12, 20], [12, 35]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "claim.claimValue", ["loc", [null, [12, 46], [12, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [12, 76], [12, 84]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "claim.claimValue", ["loc", [null, [12, 109], [12, 125]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 104], [12, 126]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [12, 149]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});