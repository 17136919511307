define('scientia-resourcebooker/services/booking-report-helper', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    groupBookingsByDate: function groupBookingsByDate(bookings, timeframeFrom, timeframeTo) {
      var _this = this;

      return bookings.reduce(function (memo, booking) {
        var startDateTime = moment(_ember['default'].get(booking, 'startDateTime'));
        var endDateTime = moment(_ember['default'].get(booking, 'endDateTime')).subtract(1, 'second');

        if (startDateTime.isBefore(endDateTime, 'day')) {
          _ember['default'].set(booking, 'spansMultipleDays', true);
          return _this._expandMultipleDaysBooking(memo, booking, timeframeFrom, timeframeTo);
        }

        var startIso = startDateTime.format('YYYY-MM-DD');
        if (!(startIso in memo)) {
          memo[startIso] = [];
        }

        memo[startIso].push(booking);
        return memo;
      }, {});
    },

    _expandMultipleDaysBooking: function _expandMultipleDaysBooking(memo, booking, timeframeFrom, timeframeTo) {

      var bookingStart = moment(_ember['default'].get(booking, 'startDateTime'));
      var bookingEnd = moment(_ember['default'].get(booking, 'endDateTime'));

      var currentDay = bookingStart.isSameOrBefore(timeframeFrom) ? timeframeFrom.clone() : bookingStart.clone();

      while (currentDay.isSameOrBefore(timeframeTo, 'day') && currentDay.isSameOrBefore(bookingEnd.clone().subtract(1, 'minute'), 'day')) {

        var newBooking = JSON.parse(JSON.stringify(booking));
        var newStartIso = currentDay.format('YYYY-MM-DD');
        if (!(newStartIso in memo)) {
          memo[newStartIso] = [];
        }

        memo[newStartIso].push(newBooking);

        currentDay.add(1, 'day');
      }

      return memo;
    }
  });
});