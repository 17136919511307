define('scientia-resourcebooker/pods/components/item-sort-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    sortingBy: ['order'],

    sortedModel: _ember['default'].computed.sort('_model', 'sortingBy'),
    model: null,
    _model: null,
    locale: _ember['default'].computed.alias('i18n.locale'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_model', this.get('model').copy(true));
    },

    _reorderItems: function _reorderItems(sortedItems) {
      sortedItems.forEach(function (s, i) {
        _ember['default'].set(s, 'order', i);
      });

      this._clearStyles();

      // return sortedItems;
      this.set('_model', sortedItems);
    },

    // When dragging items up and down like a mad man, sometimes the css styling aplied on the fly by
    // the addon (transform: translateY(-40px)) does not get cleared and some items might get incorrectly positioned
    // We just run this to make sure all transform styling is cleared after sorting items
    _clearStyles: function _clearStyles() {
      _ember['default'].run.later(this, function () {
        _ember['default'].$('.sortable-item').css('transform', 'none');
      }, 1000);
    },

    actions: {

      onSortItem: function onSortItem(sortedItems) {
        this._reorderItems(sortedItems);
        // this.set('_model', sorted);
      },

      moveItemToTop: function moveItemToTop(item) {
        var model = this.get('_model');
        var index = model.indexOf(item);

        model.splice(index, 1);
        model.unshift(item);
        this._reorderItems(model);
      },

      moveItemToBottom: function moveItemToBottom(item) {
        var model = this.get('_model');
        var index = model.indexOf(item);

        model.splice(index, 1);
        model.push(item);
        this._reorderItems(model);
      },

      moveItemToPrevious: function moveItemToPrevious(item) {
        var model = this.get('_model');
        var index = model.indexOf(item);
        var previousIndex = index - 1;
        var previousItem = model.objectAt(previousIndex);
        if (previousItem) {
          var _ref = [model[previousIndex], model[index]];
          model[index] = _ref[0];
          model[previousIndex] = _ref[1];

          this._reorderItems(model);
        }
      },

      moveItemToNext: function moveItemToNext(item) {
        var model = this.get('_model');
        var index = model.indexOf(item);
        var nextIndex = index + 1;
        var nextItem = model.objectAt(nextIndex);
        if (nextItem) {
          var _ref2 = [model[nextIndex], model[index]];
          model[index] = _ref2[0];
          model[nextIndex] = _ref2[1];

          this._reorderItems(model);
        }
      },

      sortBy: function sortBy(property, desc) {
        var _this = this;

        var model = this.get('_model');
        var sorted = model.sort(function (a, b) {
          return _ember['default'].get(a, property).localeCompare(_ember['default'].get(b, property), _this.get('locale'));
        });
        if (desc) sorted = sorted.reverse();
        this._reorderItems(sorted);
      }

    }
  });
});