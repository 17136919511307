define('scientia-resourcebooker/pods/components/file-upload/component', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    session: _ember['default'].inject.service(),
    classNameBindings: ['isUploading'],
    isUploading: false,
    attributeBindings: ['accept', 'name', 'aria-label', 'aria-required', 'required'],

    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var uploader = _emberUploader['default'].Uploader.create({
        url: this.get('url'),
        ajaxSettings: {
          headers: {
            'Authorization': 'Bearer ' + this.get('session.authorization.access_token')
          }
        }
      });

      if (!_ember['default'].isEmpty(files)) {
        // this second argument is optional and can to be sent as extra data with the upload
        this.attrs.uploadStarted();
        uploader.upload(files[0]);

        uploader.on('progress', function (e) {
          _this.attrs.uploadProgress(parseInt(e.percent, 10));
        });

        uploader.on('didUpload', function (e) {
          _this.attrs.uploadFinished(e);
        });

        uploader.on('didError', function (jqXHR, textStatus, errorThrown) {
          _this.attrs.uploadFailed(jqXHR, textStatus, errorThrown);
        });
      }
    }
  });
});