define('scientia-resourcebooker/pods/logged-in/notifications/route', ['exports', 'ember', 'ember-can', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _emberCan, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_emberCan.CanMixin, _scientiaResourcebookerMixinsAppInsights['default'], {

    notificationsFetcher: _ember['default'].inject.service(),
    notificationsCountFetcher: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),

    lastModelParams: null,

    queryParams: {
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true },
      selectedTab: { replace: true, refreshModel: true },
      serializedSearch: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.notifications.page_title');
    },

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access notifications')) {
        this.transitionTo('/');
      }

      this.get('customEvents').on('notificationCountUpdated', this, '_onNotificationCountUpdatedEvent');
    },

    model: function model(params) {

      // Store the last queryParams inlastModelParams so we can load the notifications async.
      this.set('lastModelParams', params);
      return _ember['default'].RSVP.resolve(null);
    },

    afterModel: function afterModel() {
      var controller = this.controllerFor(this.routeName);

      controller.set('isLoadingNotifications', false);

      var shouldFetch = this.get('notificationsCountFetcher').shouldFetch();
      if (shouldFetch) {
        this.get('notificationsCountFetcher').updateNotificationCount();
      }

      if (controller.get('hasSearchOptions')) {
        this._loadNotificationSearchResults();
      } else {
        this._loadNotificationsAsync();
      }
    },

    _loadNotificationsAsync: function _loadNotificationsAsync() {
      var controller = this.controllerFor(this.routeName);

      var params = this.get('lastModelParams');

      var _controller$getProperties = controller.getProperties(['startDateTime', 'endDateTime']);

      var startDateTime = _controller$getProperties.startDateTime;
      var endDateTime = _controller$getProperties.endDateTime;

      if (!startDateTime || !endDateTime) {
        startDateTime = moment().subtract(6, 'days').startOf('day');
        endDateTime = moment().endOf('day');
      }

      controller.set('isLoadingNotifications', true);

      this.get('notificationsFetcher').fetch({
        page: params.page,
        perPage: params.perPage,
        notificationType: params.selectedTab,
        startDateTime: startDateTime,
        endDateTime: endDateTime
      }).then(function (data) {
        controller.set('model', data);
      })['catch'](function () {})['finally'](function () {
        controller.set('isLoadingNotifications', false);
      });
    },

    _loadNotificationSearchResults: function _loadNotificationSearchResults() {
      var controller = this.controllerFor(this.routeName);
      controller.set('isLoadingNotifications', true);

      var params = this.get('lastModelParams');

      var _controller$getProperties2 = controller.getProperties(['startDateTime', 'endDateTime']);

      var startDateTime = _controller$getProperties2.startDateTime;
      var endDateTime = _controller$getProperties2.endDateTime;

      if (!startDateTime || !endDateTime) {
        startDateTime = moment().subtract(6, 'days').startOf('day');
        endDateTime = moment().endOf('day');
      }

      this.get('notificationsFetcher').search({
        page: params.page,
        perPage: params.perPage,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        filters: controller.get('searchOptionsSelected')
      }).then(function (data) {
        controller.set('model', data);
      })['catch'](function () {})['finally'](function () {
        controller.set('isLoadingNotifications', false);
      });
    },

    _onNotificationCountUpdatedEvent: function _onNotificationCountUpdatedEvent(countResults) {

      // Now check if we actually should show them on the notification tabs
      var shouldShowInTabs = this.get('notificationsCountFetcher').shouldFetchForNotificationsPage();

      if (shouldShowInTabs) {
        this.controller.setProperties({
          toApproveCount: countResults.toApprove,
          moreInfoActionCount: countResults.moreInfo
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.set('lastModelParams', null);
        controller.setProperties({
          page: 1,
          perPage: 10,
          searchQuery: ''
        });
      }
    },

    actions: {
      reload: function reload() {
        this.refresh();
      },

      willTransition: function willTransition() {
        this.get('notificationsCountFetcher').isLeavingNotificationPage();
        return true;
      }
    }
  });
});