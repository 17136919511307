define('scientia-resourcebooker/services/resource-type-persister', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    persist: function persist(_ref) {
      var name = _ref.name;
      var imageUri = _ref.imageUri;

      var resourceType = this.get('store').createRecord('resource-type', { name: name, imageUri: imageUri });

      var promise = resourceType.save();

      promise['catch'](function () {
        resourceType.destroyRecord();
      });

      return promise;
    }
  });
});