define('scientia-resourcebooker/pods/components/manage-resources/add-unavailability-date-slot/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {

    model: null,
    userGroup: null,
    isSaving: false,

    // We need to use this 2 intermiedary properties as mutating the original properties closes the datepicker
    fromTimeValue: null,
    toTimeValue: null,

    formattedDate: _ember['default'].computed('model.fromTime', function () {
      if (this.get('model.fromTime') === null) return;
      return this.get('model.fromTime').format(this.get('i18n').t('components.add_unavailability_slot.date_format').toString());
    }),

    actions: {
      submit: function submit() {
        this.set('isSaving', true);
        var start = this.get('fromTimeValue') || this.get('model.fromTime');
        var end = this.get('toTimeValue') || this.get('model.toTime');

        if (end.isBefore(start) || end.isSame(start)) {
          this.set('error', this.get('i18n').t('components.add_unavailability_slot.end_cannot_be_before'));
          this.set('isSaving', false);
          return;
        }

        this.get('saveUnavailabilityDateSlot')({
          fromTime: this.get('fromTimeValue') || this.get('model.fromTime'),
          toTime: this.get('toTimeValue') || this.get('model.toTime'),
          userGroup: this.get('userGroup')
        });
      },

      updateStartDate: function updateStartDate(value) {
        this.set('fromTimeValue', value);
      },

      updateEndDate: function updateEndDate(value) {
        this.set('toTimeValue', value);
      }
    }
  });
});