define('scientia-resourcebooker/services/resource-busy-exceptions-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var userGroupId = _ref.userGroupId;

      var url = this._urlFromIds(id, parentId, type);
      var data = {};

      if (userGroupId && userGroupId !== DEFAULT_ALL_GROUPS) data.userGroupIdentity = userGroupId;

      return this.get('authenticatedRequest').fetch(url, data).then(function (results) {
        return _this._normalizeResults(results);
      });
    },

    _urlFromIds: function _urlFromIds(id, parentId, type) {
      if (parentId) {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_EXCEPTIONS[type].loc(parentId, id);
      } else {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_EXCEPTIONS[type].loc(id);
      }
    },

    _normalizeResults: function _normalizeResults(data) {
      return data.map(function (item) {
        var fromTime = moment(item.startDateTime);
        var toTime = fromTime.clone().add(item.duration, 'minutes');
        return _ember['default'].Object.create({ fromTime: fromTime, toTime: toTime, data: item });
      }).sort(function (a, b) {
        return a.fromTime.isBefore(b.fromTime, 'day') ? 1 : -1;
      });
    },

    cloneBusyExceptions: function cloneBusyExceptions(data) {
      return data.map(function (v) {
        var dupe = JSON.parse(JSON.stringify(v));
        return _ember['default'].Object.create({
          fromTime: moment(v.fromTime),
          toTime: moment(v.toTime),
          data: dupe.data
        });
      });
    }

  });
});