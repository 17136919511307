define('scientia-resourcebooker/services/booking-report-exporter', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    'export': function _export(key) {
      var rootUrl = this.get('authenticatedRequest').getRootUrl();
      var endpoint = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS.EXPORT_CSV_URL.loc(key);
      window.open(rootUrl + endpoint);
    }
  });
});