define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item', 'manageResources-itemForm-form-item--topAligned'],

    actions: {
      addToList: function addToList(choice) {
        if (_ember['default'].typeOf(this.get('property.value')) !== 'array') {
          this.set('property.value', []);
        }

        this.get('property.value').pushObject(choice);
      },

      removeFromList: function removeFromList(choice) {
        var existingChoice = this.get('property.value').findBy('identity', choice.identity);
        this.get('property.value').removeObject(existingChoice);
      }
    }
  });
});