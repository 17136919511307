define('scientia-resourcebooker/pods/components/manage-resources/footer-actions/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-actions manageResources-itemForm-form-actions--dual-action'],
    cancelLabel: false,

    actions: {
      hasHistory: function hasHistory() {
        return window.history.length > 1;
      }
    }
  });
});