define('scientia-resourcebooker/services/booking-form-help-toggler', ['exports', 'ember'], function (exports, _ember) {

  var STORAGE_KEY = 'scientia-rb-help';
  exports['default'] = _ember['default'].Service.extend({

    isShowingHelp: _ember['default'].computed(function () {
      return JSON.parse(localStorage.getItem(STORAGE_KEY));
    }).volatile(),

    toggle: function toggle() {
      var isShowing = this.get('isShowingHelp');
      localStorage.setItem(STORAGE_KEY, !isShowing);
    }

  });
});