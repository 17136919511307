define('scientia-resourcebooker/pods/components/manage-resources/rules-form/types/time/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    locale: _ember['default'].computed.alias('i18n.locale'),
    systemSettings: _ember['default'].inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var value = this.get('value');
      if (value) this.set('value', moment.utc(value).tz(this.get('systemSettings').getSetting('timeZone')));
    }
  });
});