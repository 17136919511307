define("scientia-resourcebooker/pods/components/system/notifications-templates/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/system/notifications-templates/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "system/notifications-templates/list", [], ["notificationsTemplates", ["subexpr", "@mut", [["get", "templates", ["loc", [null, [2, 23], [2, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [3, 17], [3, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "selectTemplate", "selectTemplate"], ["loc", [null, [1, 0], [4, 33]]], 0, 0], ["inline", "system/notifications-templates/editor", [], ["selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [7, 17], [7, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "sendSample", "sendSample", "save", "save"], ["loc", [null, [6, 0], [10, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});