define('scientia-resourcebooker/helpers/attachment-name-from-url', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (params) {

    var url = params[0];

    if (!_ember['default'].isEmpty(url)) {
      var splitted = url.split('/');
      if (splitted.length > 0) {
        return splitted[splitted.length - 1];
      } else {
        return null;
      }
    }

    return null;
  });
});