define('scientia-resourcebooker/services/concurrency-group-searcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    search: function search(_ref) {
      var _ref$query = _ref.query;
      var query = _ref$query === undefined ? '' : _ref$query;

      return this.get('store').query('concurrency-group', { query: query });
    }
  });
});