define('scientia-resourcebooker/helpers/linkify', ['exports', 'ember-linkify/helpers/linkify'], function (exports, _emberLinkifyHelpersLinkify) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberLinkifyHelpersLinkify['default'];
    }
  });
  Object.defineProperty(exports, 'linkify', {
    enumerable: true,
    get: function get() {
      return _emberLinkifyHelpersLinkify.linkify;
    }
  });
});