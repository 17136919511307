define('scientia-resourcebooker/services/resource-tree-data-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var parentId = _ref.parentId;
      var id = _ref.id;
      var groupId = _ref.groupId;
      var suffix = _ref.suffix;
      var data = _ref.data;

      var url = this._urlFromTypeAndIds(type, parentId, id, suffix, groupId);

      return this.get('authenticatedRequest').fetch(url, data).then(function (data) {
        var modelType = type.split('.')[1];
        return _this.normalizeData(modelType, data);
      });
    },

    search: function search(query, searchType) {
      var _this2 = this;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS.SEARCH_URL;
      var data = {
        searchTerm: query,
        searchType: searchType
      };
      return this.get('authenticatedRequest').fetch(url, data).then(function (response) {
        return _this2.normalizeSearchResults(response);
      });
    },

    normalizeSearchResults: function normalizeSearchResults(data) {
      var results = {};

      if (data.resourceTypes && data.resourceTypes.length) {
        results.resourcetypes = this.normalizeResourceType(data.resourceTypes);
      }
      if (data.resourceGroups && data.resourceGroups.length) {
        results.resourceGroups = this.normalizeResourceGroup(data.resourceGroups);
      }
      if (data.resources && data.resources.length) {
        results.resources = this.normalizeResource(data.resources);
      }

      if (data.concurrencyGroups && data.concurrencyGroups.length) {
        results.concurrencyGroups = this.normalizeConcurrencyGroup(data.concurrencyGroups);
      }

      return results;
    },

    normalizeData: function normalizeData(type, data) {
      if (type === 'resourceType') {
        data = {
          results: data
        };
      }

      switch (type) {
        case 'resourceType':
          data.results = this.normalizeResourceType(data.results);
          break;
        case 'resourceGroup':
          data.results = this.normalizeResourceGroup(data.results);
          break;
        case 'resource':
          data.results = this.normalizeResource(data.results);
          break;
        case 'concurrencygroup':
          data.results = this.normalizeConcurrencyGroup(data.results);
          break;
        default:
          break;
      }

      data.meta = {
        count: data.count,
        currentPage: data.currentPage,
        totalPages: data.totalPages
      };
      return data;
    },

    normalizeResourceType: function normalizeResourceType(data) {
      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri || 'icons/tag.svg'
        };
      });
    },

    normalizeResourceGroup: function normalizeResourceGroup(data) {
      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri,
          resourceCount: d.resourceCount,
          isGroup: true
        };
      });
    },

    normalizeResource: function normalizeResource(data) {
      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri
        };
      });
    },

    normalizeConcurrencyGroup: function normalizeConcurrencyGroup(data) {
      return data.map(function (d) {
        return {
          id: _ember['default'].get(d, 'id'),
          name: _ember['default'].get(d, 'name')
        };
      });
    },

    _urlFromTypeAndIds: function _urlFromTypeAndIds(type, parentId) {
      var id = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
      var suffix = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
      var groupId = arguments.length <= 4 || arguments[4] === undefined ? null : arguments[4];

      var url = undefined;
      if (groupId) {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS, type).loc(parentId, groupId);
      } else {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS, type).loc(parentId);
      }
      if (id) url += '/' + id;
      if (suffix) url += '/' + suffix;

      return url;
    }

  });
});