define('scientia-resourcebooker/pods/components/manage-resources/notification-templates/editor/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['notificationsTemplates-editor'],
    selectedTemplate: null,
    isSaving: false,
    disabled: false,

    sendEmail: _ember['default'].computed.alias('selectedTemplate.sendEmail'),
    subjectLine: _ember['default'].computed.alias('selectedTemplate.subjectLine'),
    fromEmail: _ember['default'].computed.alias('selectedTemplate.fromEmail'),

    optionTags: _ember['default'].computed('selectedTemplate', function () {
      return this.get('selectedTemplate.options') || [];
    }),

    conditionalTags: _ember['default'].computed('selectedTemplate', function () {
      return this.get('selectedTemplate.conditionals') || [];
    }),

    editorOptions: _ember['default'].computed('selectedTemplate', 'disabled', 'elementId', function () {

      if (this.get('selectedTemplate')) {
        return {
          height: 200,
          'menubar': true,
          'readonly': this.get('disabled'),
          'toolbar1': 'insertfile undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview',
          'toolbar2': this.get('optionTags').join(' ') + ' ' + this.get('conditionalTags').join(' '),
          'convert_urls': true,
          'relative_urls': false,
          'remove_script_host': false
        };
      }
    }),

    actions: {
      save: function save(template) {
        var _template$validateSync = template.validateSync();

        var validations = _template$validateSync.validations;

        this.set('didValidate', true);
        // this.get('save')();
        if (!validations.get('isValid')) {
          return false;
        } else {
          this.get('save')();
        }
      },

      onToggleSendEmail: function onToggleSendEmail() {
        this.toggleProperty('selectedTemplate.sendEmail');
        this.set('didValidate', true);
        this.get('selectedTemplate').validateSync();
      }
    }
  });
});