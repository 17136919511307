define("scientia-resourcebooker/pods/components/global-search-field/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 10,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "globalSearchField-suggestion");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.global_search_field.loading"], [], ["loc", [null, [9, 48], [9, 94]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 16,
                        "column": 12
                      },
                      "end": {
                        "line": 18,
                        "column": 12
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "globalSearchField-suggestion");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element4);
                    morphs[1] = dom.createMorphAt(element4, 0, 0);
                    return morphs;
                  },
                  statements: [["element", "action", [["subexpr", "route-action", ["onClickBookingType", ["get", "bookingType", ["loc", [null, [17, 101], [17, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 66], [17, 113]]], 0, 0]], [], ["loc", [null, [17, 57], [17, 115]]], 0, 0], ["content", "bookingType.name", ["loc", [null, [17, 116], [17, 136]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 19,
                          "column": 14
                        },
                        "end": {
                          "line": 19,
                          "column": 124
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "bookingType.name", ["loc", [null, [19, 14], [19, 124]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 18,
                        "column": 12
                      },
                      "end": {
                        "line": 20,
                        "column": 12
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["logged-in.booking-type.show", ["get", "bookingType.id", ["loc", [null, [19, 71], [19, 85]]], 0, 0, 0, 0]], ["class", "globalSearchField-suggestion"], 0, null, ["loc", [null, [19, 14], [19, 124]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 10
                    },
                    "end": {
                      "line": 21,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "bookingType.isDisabled", ["loc", [null, [16, 18], [16, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [16, 12], [20, 19]]]]],
                locals: ["bookingType"],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 8
                    },
                    "end": {
                      "line": 24,
                      "column": 181
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["components.global_search_field.more"], [], ["loc", [null, [24, 8], [24, 181]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 6
                  },
                  "end": {
                    "line": 25,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "globalSearchField-suggestions-section");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "globalSearchField-suggestions-title");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element5, 3, 3);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.global_search_field.booking_types"], [], ["loc", [null, [13, 59], [13, 111]]], 0, 0], ["block", "each", [["get", "suggestions.bookingTypes", ["loc", [null, [14, 18], [14, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 10], [21, 19]]]], ["block", "link-to", ["logged-in.search-results", ["subexpr", "query-params", [], ["type", "bookingType", "query", ["get", "query", ["loc", [null, [24, 126], [24, 131]]], 0, 0, 0, 0]], ["loc", [null, [24, 87], [24, 132]]], 0, 0]], ["class", "globalSearchField-suggestions-moreLink"], 1, null, ["loc", [null, [24, 8], [24, 181]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 10
                    },
                    "end": {
                      "line": 40,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "globalSearchField-suggestion");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [2]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createMorphAt(element2, 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["onResourceGroupClick", ["get", "resourceGroup", ["loc", [null, [37, 52], [37, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 20], [37, 67]]], 0, 0], ["content", "resourceGroup.name", ["loc", [null, [37, 105], [37, 127]]], 0, 0, 0, 0]],
                locals: ["resourceGroup"],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 43,
                      "column": 8
                    },
                    "end": {
                      "line": 43,
                      "column": 183
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["components.global_search_field.more"], [], ["loc", [null, [43, 8], [43, 183]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 6
                  },
                  "end": {
                    "line": 44,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "globalSearchField-suggestions-section");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "globalSearchField-suggestions-title");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element3, 3, 3);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.global_search_field.resource_groups"], [], ["loc", [null, [29, 59], [29, 113]]], 0, 0], ["block", "each", [["get", "suggestions.resourceGroups", ["loc", [null, [30, 18], [30, 44]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [30, 10], [40, 19]]]], ["block", "link-to", ["logged-in.search-results", ["subexpr", "query-params", [], ["type", "resourceGroup", "query", ["get", "query", ["loc", [null, [43, 128], [43, 133]]], 0, 0, 0, 0]], ["loc", [null, [43, 87], [43, 134]]], 0, 0]], ["class", "globalSearchField-suggestions-moreLink"], 1, null, ["loc", [null, [43, 8], [43, 183]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 10
                    },
                    "end": {
                      "line": 55,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "globalSearchField-suggestion");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(element0, 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["onResourceClick", ["get", "resource", ["loc", [null, [53, 47], [53, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 20], [53, 57]]], 0, 0], ["content", "resource.name", ["loc", [null, [53, 95], [53, 112]]], 0, 0, 0, 0]],
                locals: ["resource"],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 8
                    },
                    "end": {
                      "line": 58,
                      "column": 178
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["components.global_search_field.more"], [], ["loc", [null, [58, 8], [58, 178]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 6
                  },
                  "end": {
                    "line": 59,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "globalSearchField-suggestions-section");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "globalSearchField-suggestions-title");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element1, 3, 3);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.global_search_field.resources"], [], ["loc", [null, [48, 59], [48, 107]]], 0, 0], ["block", "each", [["get", "suggestions.resources", ["loc", [null, [49, 18], [49, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [49, 10], [55, 19]]]], ["block", "link-to", ["logged-in.search-results", ["subexpr", "query-params", [], ["type", "resource", "query", ["get", "query", ["loc", [null, [58, 123], [58, 128]]], 0, 0, 0, 0]], ["loc", [null, [58, 87], [58, 129]]], 0, 0]], ["class", "globalSearchField-suggestions-moreLink"], 1, null, ["loc", [null, [58, 8], [58, 178]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 4
                },
                "end": {
                  "line": 60,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "suggestions.bookingTypes", ["loc", [null, [11, 12], [11, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 6], [25, 13]]]], ["block", "if", [["get", "suggestions.resourceGroups", ["loc", [null, [27, 12], [27, 38]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [27, 6], [44, 13]]]], ["block", "if", [["get", "suggestions.resources", ["loc", [null, [46, 12], [46, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [46, 6], [59, 13]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 4
                },
                "end": {
                  "line": 62,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "globalSearchField-suggestion");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.global_search_field.no_results"], [], ["loc", [null, [61, 48], [61, 97]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 4
              },
              "end": {
                "line": 62,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasResults", ["loc", [null, [10, 14], [10, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 4], [62, 4]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 64,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "globalSearchField-suggestions");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "isLoading", ["loc", [null, [8, 10], [8, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 4], [62, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/global-search-field/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "sr-only");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element6, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["components.global_search_field.placeholder"], [], ["loc", [null, [2, 24], [2, 74]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [3, 14], [3, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "search", "role", "search", "class", "globalSearchField-input form-input", "focus-in", "submitSearch", "placeholder", ["subexpr", "t", ["components.global_search_field.placeholder"], [], ["loc", [null, [3, 127], [3, 175]]], 0, 0], "class", "globalSearchField-input form-input"], ["loc", [null, [3, 0], [3, 220]]], 0, 0], ["block", "if", [["get", "hasQueryAndIsFocused", ["loc", [null, [6, 6], [6, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 0], [64, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});