define('scientia-resourcebooker/pods/components/resource-tree/selection-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['resourceTreeSelectionList'],
    classNameBindings: ['disabled:resourceTreeSelectionList--disabled'],
    localePrefix: 'components.resource_tree.selection_list',
    disabled: false,
    rbToast: _ember['default'].inject.service(),
    model: null,
    isLoading: false,
    sortProperty: 'rank',
    enablePrimary: false,

    actions: {
      makePrimary: function makePrimary(item) {
        var itemIsAlreadySet = _ember['default'].get(item, 'isPrimary');

        this.get('model').setEach('isPrimary', false);

        if (!itemIsAlreadySet) {

          _ember['default'].set(item, 'isPrimary', true);

          this.get('rbToast').success({
            title: _ember['default'].get(item, 'name'),
            icon: 'icons/star.svg',
            iconClass: 'icon--star--white',
            message: this.get('i18n').t(this.get('localePrefix') + '.primary_set') });
        } else {

          this.get('rbToast').success({
            title: _ember['default'].get(item, 'name'),
            icon: 'icons/star.svg',
            iconClass: 'icon--star--transparent',
            message: this.get('i18n').t(this.get('localePrefix') + '.primary_unset') });
        }
      }
    }
  });
});