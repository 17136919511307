define('scientia-resourcebooker/mixins/modal-dialog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      close: function close() {
        this.sendAction('dismiss');
      },

      escape: function escape() {
        this.sendAction('dismiss');
      },

      outsideClick: function outsideClick() {}
    }
  });
});