define('scientia-resourcebooker/pods/components/list-items-searcher/input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['listItemsSearcher-powerInput'],

    defaultItems: [],
    chosenItem: '',

    itemWasChosen: (function () {
      _ember['default'].run.next(this, '_addItemToList', this.get('chosenItem'));
    }).observes('chosenItem'),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.sendAction('onInsertElement');
      });
    }).on('init'),

    actions: {
      search: function search(query, deferred) {
        if (query.term.length > 0) {
          this.sendAction('onSearch', query.term, deferred);
        } else {
          deferred.resolve(this.get('defaultItems'));
        }
      }
    },

    _addItemToList: function _addItemToList(resource) {
      this.sendAction('onItemChosen', resource);
      this._resetChosenItem();
    },

    _resetChosenItem: function _resetChosenItem() {
      // Reopen the list of suggestion.
      this.$().find('.select2-container').select2('search', '');

      if (!this.get('isDestroyed')) this.set('chosenItem', '');
    }
  });
});