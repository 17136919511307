define('scientia-resourcebooker/models/resource-type', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    identity: attr('string'),
    imageUri: attr('string'),
    canEdit: attr('boolean'),

    canEditAll: attr('boolean'),
    canEditDescription: attr('boolean'),
    canEditCustomFields: attr('boolean'),
    canEditPermissions: attr('boolean'),
    canCreateResourceGroups: attr('boolean'),
    canCreateResources: attr('boolean'),

    hasAssociatedBookingTypes: attr('boolean'),
    properties: (0, _modelFragmentsAttributes.fragmentArray)('property', { defaultValue: function defaultValue() {
        return [];
      } }),
    readPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('read-permission-group', { defaultValue: function defaultValue() {
        return [];
      } }),
    writePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('write-permission-group', { defaultValue: function defaultValue() {
        return [];
      } }),

    manageGroupsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('manage-groups-permission-group', { defaultValue: function defaultValue() {
        return [];
      } }),
    manageResourcesPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('manage-resources-permission-group', { defaultValue: function defaultValue() {
        return [];
      } }),

    editAllPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-all-permission-group'),
    editDescriptionPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-description-permission-group'),
    editCustomFieldsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-custom-fields-permission-group'),
    editPermissionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-permissions-permission-group'),

    resources: hasMany('resource'),
    resourceGroups: hasMany('resourceGroup'),

    permissionsKeys: ['read', 'write', 'manage-groups', 'manage-resources', 'edit-all', 'edit-description', 'edit-custom-fields', 'edit-permissions']
  });
});