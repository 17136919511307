define('scientia-resourcebooker/transforms/date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _moment['default'])(serialized);
    },

    serialize: function serialize(deserialized) {
      return deserialized.toISOString();
    }
  });
});