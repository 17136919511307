define('scientia-resourcebooker/pods/components/manage-resources/user-group-searcher/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    chosenUserGroups: [],
    defaultItems: [],

    userGroupSearcher: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    _cancel: function _cancel() {
      this.get('chosenUserGroups').clear();
    },

    actions: {
      save: function save() {
        this.sendAction('selectUserGroups', this.get('chosenUserGroups'));
      },

      onInsertSearchInput: function onInsertSearchInput() {
        var _this = this;

        this.get('chosenUserGroups').clear();

        this.get('store').findAll('user-group').then(function (groups) {
          _this.set('defaultItems', groups);
        });
      },

      searchItems: function searchItems(term, deferred) {
        this.get('userGroupSearcher').search({
          query: term
        }).then(function (groups) {
          deferred.resolve(groups);
        });
      },

      addUserGroup: function addUserGroup(group) {
        this.get('chosenUserGroups').pushObject(group);
      },

      removeUserGroup: function removeUserGroup(group) {
        this.get('chosenUserGroups').removeObject(group);
      }
    }
  });
});