define('scientia-resourcebooker/pods/components/notification-reject-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmModal rejectModal'],

    note: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.send('showNoteInput');
    },

    actions: {
      showNoteInput: function showNoteInput() {
        _ember['default'].run.later(this, function () {
          this.$('.bookingCancellationModal-textarea').focus();
        }, 100);
      }
    }
  });
});