define('scientia-resourcebooker/pods/manage-resources/user-group/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    errorHandler: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    nestedResourceStore: _ember['default'].inject.service(),
    userGroupPersister: _ember['default'].inject.service(),
    taskProgressModalDisplayer: _ember['default'].inject.service(),

    systemSettings: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.user_group.edit.page_title');
    },

    beforeModel: function beforeModel(transition) {

      // Prevent transition if resourceOwner group and hideDeprecations = true
      var isResourceOwnerId = transition.params[this.routeName].id === _scientiaResourcebookerConfigEnvironment['default'].IDS.USER_GROUP_RESOURCE_OWNER;
      var hideDeprecatedRole = this.get('systemSettings').getSetting('hidePermissionsVersion3');
      if (isResourceOwnerId && hideDeprecatedRole) {
        this.transitionTo('logged-in');
        return;
      }
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        userGroup: this.store.findRecord('user-group', params.id)
      });
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.userGroup.get('name'),
        id: model.userGroup.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          name: '',
          error: '',
          fetchError: false
        });
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.user-group.edit');
        if (model.userGroup.get('hasDirtyAttributes')) model.userGroup.rollbackAttributes();
      },

      updateUserGroup: function updateUserGroup(userGroup, transitionIndex) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.user-group.edit');
        controller.set('isSaving', true);

        if (userGroup.get('claims.length') >= 300) {
          return this._updateUserGroupWithProgress(userGroup, transitionIndex);
        }

        userGroup.save().then(function () {
          _this.editSuccessFlash('user-group');

          if (transitionIndex) {
            _this.transitionTo('manage-resources.user-group.index');
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('user-group');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    },

    _updateUserGroupWithProgress: function _updateUserGroupWithProgress(userGroup, transitionIndex) {
      var _this2 = this;

      this.get('userGroupPersister').getUpdateTaskKey(userGroup).then(function (taskKey) {
        _this2.get('taskProgressModalDisplayer').show(taskKey).then(function () {
          _this2.editSuccessFlash('user-group');
          _this2.controllerFor(_this2.routeName).set('isSaving', false);
          if (transitionIndex) {
            _this2.transitionTo('manage-resources.user-group.index');
          }
        })['catch'](function () {
          return _this2.editErrorFlash('user-group');
        });
      });
    }
  });
});