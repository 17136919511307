define("scientia-resourcebooker/pods/components/week-time-picker/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "chronos-headerView-button chronos-headerView-zoom chronos-headerView-zoom--reset");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["zoomReset"], [], ["loc", [null, [8, 16], [8, 38]]], 0, 0], ["inline", "t", ["general.reset"], [], ["loc", [null, [8, 128], [8, 149]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "chronos-weekView-headerDay");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["onHeaderClick", ["get", "weekday.index", ["loc", [null, [18, 74], [18, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 49], [18, 89]]], 0, 0], ["content", "weekday.name", ["loc", [null, [18, 90], [18, 106]]], 0, 0, 0, 0]],
        locals: ["weekday"],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 10
            },
            "end": {
              "line": 27,
              "column": 10
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "chronos-weekView-hour");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "chronos-weekView-hour-label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "moment-format", [["get", "hour", ["loc", [null, [25, 72], [25, 76]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["components.week_time_picker.hour_format"], [], ["loc", [null, [25, 83], [25, 128]]], 0, 0]], [], ["loc", [null, [25, 77], [25, 129]]], 0, 0]], [], ["loc", [null, [25, 56], [25, 131]]], 0, 0]],
        locals: ["hour"],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 14
              },
              "end": {
                "line": 34,
                "column": 14
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "chronos-weekView-hourGrid-item");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'data-weekday');
            morphs[1] = dom.createAttrMorph(element0, 'data-hour');
            morphs[2] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["attribute", "data-weekday", ["get", "weekday.index", ["loc", [null, [33, 76], [33, 89]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-hour", ["get", "index", ["loc", [null, [33, 104], [33, 109]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showAddSlot", ["get", "weekday.index", ["loc", [null, [33, 135], [33, 148]]], 0, 0, 0, 0], ["get", "hour", ["loc", [null, [33, 149], [33, 153]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 112], [33, 155]]], 0, 0]],
          locals: ["hour", "index"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 14
              },
              "end": {
                "line": 45,
                "column": 14
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "week-time-pattern", [], ["model", ["subexpr", "@mut", [["get", "pattern", ["loc", [null, [39, 22], [39, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "zoomLevel", ["subexpr", "@mut", [["get", "zoomLevel", ["loc", [null, [40, 26], [40, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "showRemoveSlot", ["subexpr", "action", [["get", "showRemoveSlot", ["loc", [null, [41, 39], [41, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 31], [41, 54]]], 0, 0], "undoAddSlot", ["subexpr", "action", [["get", "undoAddSlot", ["loc", [null, [42, 36], [42, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 28], [42, 48]]], 0, 0], "undoRemoveSlot", ["subexpr", "action", [["get", "undoRemoveSlot", ["loc", [null, [43, 39], [43, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 31], [43, 54]]], 0, 0], "slotClassName", "chronos-weekView-hourGrid-item"], ["loc", [null, [38, 16], [44, 64]]], 0, 0]],
          locals: ["pattern"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 8
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "chronos-weekView-day");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "chronos-weekView-hourGrid");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "chronos-weekView-events");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "hours", ["loc", [null, [32, 22], [32, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 14], [34, 23]]]], ["block", "each", [["get", "weekday.busyPatterns", ["loc", [null, [37, 22], [37, 42]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [37, 14], [45, 23]]]]],
        locals: ["weekday"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/week-time-picker/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "chronos-headerView");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "chronos-headerView-zoomControls");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "chronos-headerView-button chronos-headerView-zoom chronos-headerView-zoom--increment chronos-headerView-button--icon");
        dom.setAttribute(el4, "type", "button");
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el5, "viewBox", "0 0 490.8 490.8");
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "d", "M364.8 299.55c46.3-75.8 36.9-176.3-28.6-241.9-76.8-76.8-201.8-76.8-278.6 0s-76.8 201.8 0 278.5c65.5 65.5 166 74.9 241.9 28.6L412 477.25c18 18 47.3 18 65.3 0s18-47.3 0-65.3l-112.5-112.4zm-69.3-4c-54.4 54.4-142.8 54.4-197.1 0-54.4-54.4-54.4-142.8 0-197.1 54.4-54.4 142.8-54.4 197.1 0 54.3 54.3 54.3 142.7 0 197.1zM220 171.95h59.4v45.3H220v59.4h-45.3v-59.4h-59.3v-45.3h59.4v-59.4h45.3v59.4h-.1z");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "chronos-headerView-button chronos-headerView-zoom chronos-headerView-zoom--decrement chronos-headerView-button--icon disabled");
        dom.setAttribute(el4, "type", "button");
        dom.setAttribute(el4, "disabled", "disabled");
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el5, "viewBox", "0 0 490.8 490.8");
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "d", "M364.8 299.5c46.3-75.8 36.9-176.3-28.6-241.9-76.8-76.8-201.8-76.8-278.6 0s-76.8 201.8 0 278.6c65.5 65.5 166 74.9 241.9 28.6L412 477.3c18 18 47.3 18 65.3 0s18-47.3 0-65.3L364.8 299.5zm-69.3-4c-54.4 54.4-142.8 54.4-197.1 0C44 241.1 44 152.7 98.4 98.4c54.4-54.4 142.8-54.4 197.1 0 54.3 54.3 54.3 142.7 0 197.1zM128 167.3h138.7v54.6H128v-54.6z");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "chronos-events");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "chronos-weekView");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ol");
        dom.setAttribute(el4, "class", "chronos-weekView-headerDays");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "chronos-weekView-headerHours");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "chronos-weekView-days");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "chronos-weekView-hours");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element4, [3, 1]);
        var element9 = dom.childAt(element8, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createAttrMorph(element6, 'disabled');
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createMorphAt(element6, 1, 1);
        morphs[4] = dom.createAttrMorph(element7, 'disabled');
        morphs[5] = dom.createElementMorph(element7);
        morphs[6] = dom.createMorphAt(element7, 1, 1);
        morphs[7] = dom.createMorphAt(element5, 5, 5);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [1]), 3, 3);
        morphs[9] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
        morphs[10] = dom.createMorphAt(element9, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["chronos chronos-viewMode--week zoomLevel--", ["get", "zoomLevel", ["loc", [null, [1, 56], [1, 65]]], 0, 0, 0, 0], " chronos-faux-weekTimePicker"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "eq", [["get", "zoomLevel", ["loc", [null, [5, 54], [5, 63]]], 0, 0, 0, 0], 4], [], ["loc", [null, [null, null], [5, 67]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["zoomIncrease"], [], ["loc", [null, [5, 14], [5, 39]]], 0, 0], ["inline", "t", ["calendar.common.zoom_in"], [], ["loc", [null, [5, 688], [5, 719]]], 0, 0], ["attribute", "disabled", ["subexpr", "eq", [["get", "zoomLevel", ["loc", [null, [6, 54], [6, 63]]], 0, 0, 0, 0], -2], [], ["loc", [null, [null, null], [6, 68]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["zoomDecrease"], [], ["loc", [null, [6, 14], [6, 39]]], 0, 0], ["inline", "t", ["calendar.common.zoom_out"], [], ["loc", [null, [6, 666], [6, 698]]], 0, 0], ["block", "if", [["subexpr", "not-eq", [["get", "zoomLevel", ["loc", [null, [7, 20], [7, 29]]], 0, 0, 0, 0], 0], [], ["loc", [null, [7, 12], [7, 32]]], 0, 0]], [], 0, null, ["loc", [null, [7, 6], [9, 13]]]], ["block", "each", [["subexpr", "sort-by", ["sortedIndex", ["get", "weekdays", ["loc", [null, [17, 39], [17, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 16], [17, 48]]], 0, 0]], [], 1, null, ["loc", [null, [17, 8], [19, 17]]]], ["block", "each", [["get", "hours", ["loc", [null, [23, 18], [23, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [23, 10], [27, 19]]]], ["block", "each", [["subexpr", "sort-by", ["sortedIndex", ["get", "weekdays", ["loc", [null, [29, 39], [29, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 16], [29, 48]]], 0, 0]], [], 3, null, ["loc", [null, [29, 8], [48, 17]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});