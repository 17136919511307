define('scientia-resourcebooker/serializers/resource-restriction-rule', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {
  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      resourceType: { embedded: 'always' },
      resourceGroups: { embedded: 'always' }
    },

    normalize: function normalize(model, hash) {
      var applicationSerializer = this.get('store').serializerFor('application');
      hash = applicationSerializer._camelizeKeysDeep(hash);

      if (hash.resourceGroups && hash.resourceGroups.length) {
        hash.resourceGroups.forEach(function (g) {
          if (!g.id) g.id = g.identity;
        });
      }

      var data = this._super(model, hash);
      return data;
    },

    serialize: function serialize() /*snapshot, options*/{
      var json = this._super.apply(this, arguments);
      if (json.resourceGroups && json.resourceGroups.length) {
        json.resourceGroups.forEach(function (r) {
          if (!r.identity) r.identity = r.id;
        });
      }
      return json;
    }

  });
});