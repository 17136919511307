define("scientia-resourcebooker/pods/components/resources-meta/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 6,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [0]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["resourcesList-meta-itemsPerPage-option ", ["subexpr", "if", [["subexpr", "eq", [["get", "itemsPerPage", ["loc", [null, [5, 111], [5, 123]]], 0, 0, 0, 0], ["get", "option.value", ["loc", [null, [5, 124], [5, 136]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 107], [5, 137]]], 0, 0], "resourcesList-meta-itemsPerPage-option--active", ""], [], ["loc", [null, [5, 102], [5, 191]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeItemsPerPage", ["get", "option.value", ["loc", [null, [5, 41], [5, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 11], [5, 55]]], 0, 0], ["content", "option.label", ["loc", [null, [5, 199], [5, 215]]], 0, 0, 0, 0]],
          locals: ["option"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "resourcesList-meta-itemsPerPage");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["components.resources_meta.items_per_page"], [], ["loc", [null, [3, 8], [3, 56]]], 0, 0], ["block", "each", [["get", "itemsPerPageAvailable", ["loc", [null, [4, 10], [4, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [6, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "pageList", ["loc", [null, [11, 12], [11, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "currentPage", ["loc", [null, [14, 10], [14, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setPage"], [], ["loc", [null, [15, 13], [15, 31]]], 0, 0], "class", "resourcesList-meta-pageChanger"], ["loc", [null, [10, 2], [17, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "pageList", ["loc", [null, [20, 12], [20, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "currentPage", ["loc", [null, [23, 10], [23, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "resourcesList-meta-pageChanger"], ["loc", [null, [19, 2], [25, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 4
                },
                "end": {
                  "line": 43,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "resourcesList-meta-link resourcesList-meta-link--previous");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4, 1, 1);
              morphs[2] = dom.createMorphAt(element4, 3, 3);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "action", [["get", "onPreviousPage", ["loc", [null, [38, 92], [38, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 84], [38, 107]]], 0, 0]], [], ["loc", [null, [38, 75], [38, 109]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [39, 8], [39, 79]]], 0, 0], ["inline", "t", ["components.resources_meta.previous"], [], ["loc", [null, [40, 8], [40, 50]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 45,
                    "column": 6
                  },
                  "end": {
                    "line": 48,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [46, 8], [46, 79]]], 0, 0], ["inline", "t", ["components.resources_meta.previous"], [], ["loc", [null, [47, 8], [47, 50]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 4
                },
                "end": {
                  "line": 50,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["subexpr", "query-params", [], ["groupPage", ["get", "previousPage", ["loc", [null, [45, 41], [45, 53]]], 0, 0, 0, 0]], ["loc", [null, [45, 17], [45, 54]]], 0, 0]], ["class", "resourcesList-meta-link resourcesList-meta-link--previous"], 0, null, ["loc", [null, [45, 6], [48, 18]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 52,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "useActions", ["loc", [null, [36, 10], [36, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [36, 4], [50, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 4
                },
                "end": {
                  "line": 62,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "resourcesList-meta-link resourcesList-meta-link--previous");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3, 1, 1);
              morphs[2] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "action", [["get", "onPreviousPage", ["loc", [null, [57, 92], [57, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 84], [57, 107]]], 0, 0]], [], ["loc", [null, [57, 75], [57, 109]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [58, 8], [58, 79]]], 0, 0], ["inline", "t", ["components.resources_meta.previous"], [], ["loc", [null, [59, 8], [59, 50]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 4
                  },
                  "end": {
                    "line": 67,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [65, 6], [65, 77]]], 0, 0], ["inline", "t", ["components.resources_meta.previous"], [], ["loc", [null, [66, 6], [66, 48]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 4
                },
                "end": {
                  "line": 69,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["subexpr", "query-params", [], ["page", ["get", "previousPage", ["loc", [null, [64, 34], [64, 46]]], 0, 0, 0, 0]], ["loc", [null, [64, 15], [64, 47]]], 0, 0]], ["class", "resourcesList-meta-link resourcesList-meta-link--previous"], 0, null, ["loc", [null, [64, 4], [67, 16]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 2
              },
              "end": {
                "line": 71,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "useActions", ["loc", [null, [55, 10], [55, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [55, 4], [69, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 73,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "isGroup", ["loc", [null, [30, 8], [30, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 2], [71, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "resourcesList-meta-infos");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "resourcesList-meta-infos-separator");
          var el3 = dom.createTextNode("/");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 5, 5);
          return morphs;
        },
        statements: [["content", "currentPage", ["loc", [null, [77, 4], [77, 19]]], 0, 0, 0, 0], ["content", "totalPages", ["loc", [null, [79, 4], [79, 18]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "resourcesList-meta-separator");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 93,
                  "column": 4
                },
                "end": {
                  "line": 100,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "resourcesList-meta-link resourcesList-meta-link--next");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1, 1, 1);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "action", [["get", "onNextPage", ["loc", [null, [95, 88], [95, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 80], [95, 99]]], 0, 0]], [], ["loc", [null, [95, 71], [95, 101]]], 0, 0], ["inline", "t", ["components.resources_meta.next"], [], ["loc", [null, [96, 8], [96, 46]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [97, 8], [97, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 102,
                    "column": 4
                  },
                  "end": {
                    "line": 105,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.resources_meta.next"], [], ["loc", [null, [103, 6], [103, 44]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [104, 6], [104, 77]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 4
                },
                "end": {
                  "line": 106,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "link-to", [["subexpr", "query-params", [], ["groupPage", ["get", "nextPage", ["loc", [null, [102, 39], [102, 47]]], 0, 0, 0, 0]], ["loc", [null, [102, 15], [102, 48]]], 0, 0]], ["class", "resourcesList-meta-link resourcesList-meta-link--next"], 0, null, ["loc", [null, [102, 4], [105, 16]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 2
              },
              "end": {
                "line": 108,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "useActions", ["loc", [null, [93, 10], [93, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [93, 4], [106, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 4
                },
                "end": {
                  "line": 117,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "resourcesList-meta-link resourcesList-meta-link--next");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              morphs[2] = dom.createMorphAt(element0, 3, 3);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "action", [["get", "onNextPage", ["loc", [null, [112, 88], [112, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [112, 80], [112, 99]]], 0, 0]], [], ["loc", [null, [112, 71], [112, 101]]], 0, 0], ["inline", "t", ["components.resources_meta.next"], [], ["loc", [null, [113, 8], [113, 46]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [114, 8], [114, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 119,
                    "column": 4
                  },
                  "end": {
                    "line": 122,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.resources_meta.next"], [], ["loc", [null, [120, 6], [120, 44]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], ["class", "resourcesList-meta-link-icon"], ["loc", [null, [121, 6], [121, 77]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 117,
                  "column": 4
                },
                "end": {
                  "line": 124,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["subexpr", "query-params", [], ["page", ["get", "nextPage", ["loc", [null, [119, 34], [119, 42]]], 0, 0, 0, 0]], ["loc", [null, [119, 15], [119, 43]]], 0, 0]], ["class", "resourcesList-meta-link resourcesList-meta-link--next"], 0, null, ["loc", [null, [119, 4], [122, 16]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 2
              },
              "end": {
                "line": 125,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "useActions", ["loc", [null, [110, 10], [110, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [110, 4], [124, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 0
            },
            "end": {
              "line": 127,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "isGroup", ["loc", [null, [87, 8], [87, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [87, 2], [125, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 128,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-meta/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "itemsPerPage", ["loc", [null, [1, 6], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [8, 7]]]], ["block", "if", [["get", "useActions", ["loc", [null, [9, 6], [9, 16]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [9, 0], [26, 7]]]], ["block", "if", [["get", "previousPage", ["loc", [null, [28, 6], [28, 18]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [28, 0], [73, 7]]]], ["block", "if", [["get", "showPageIndicator", ["loc", [null, [75, 6], [75, 23]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [75, 0], [83, 7]]]], ["block", "if", [["get", "nextPage", ["loc", [null, [85, 6], [85, 14]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [85, 0], [127, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});