define('scientia-resourcebooker/services/user-group-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    search: function search(_ref) {
      var term = _ref.term;
      var page = _ref.page;
      var itemsPerPage = _ref.itemsPerPage;
      var systemGroupsOnly = _ref.systemGroupsOnly;
      var restrictedAccess = _ref.restrictedAccess;

      var data = { query: term, itemsPerPage: itemsPerPage || 30, pageNumber: page || 1 };

      if (systemGroupsOnly) data.systemGroupsOnly = true;
      if (restrictedAccess) data.restrictedAccess = true;

      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.SEARCH.USER_GROUP, data).then(function (response) {
        var hasMoreResults = response.currentPage < response.totalPages;
        var responseData = { data: response.results, more: hasMoreResults };
        return responseData;
      });
    }
  });
});