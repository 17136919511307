define('scientia-resourcebooker/pods/components/toggle-switch/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['toggleSwitch'],
    classNameBindings: ['location'],
    checked: false,

    disabled: false,

    trueLabel: (0, _emberI18n.translationMacro)('components.toggle_switch.yes'),
    falseLabel: (0, _emberI18n.translationMacro)('components.toggle_switch.no'),

    actions: {
      toggle: function toggle() {
        if (this.get('disabled')) return false;
        this.sendAction('toggled', this.get('option'));
      }
    }
  });
});