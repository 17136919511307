define("scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingReportListHeader-toggleItem bookingReportListHeader-toggleItem--activities");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "bookingReportListHeader-toggleItemLabel");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["components.reports.bookings.show_external"], [], ["loc", [null, [15, 61], [15, 110]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "showActivitiesToggleEnabled", ["loc", [null, [17, 26], [17, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 16], [17, 54]]], 0, 0], "disabled", false, "onToggle", ["subexpr", "action", [["get", "onToggleShowActivities", ["loc", [null, [19, 25], [19, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 17], [19, 48]]], 0, 0]], ["loc", [null, [16, 8], [19, 50]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportListHeader-timeframe");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportListHeader-toggleItem bookingReportListHeader-toggleItem--activeBookings");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "bookingReportListHeader-toggleItemLabel");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [7]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "modal-dropdown-trigger", [], ["value", ["subexpr", "@mut", [["get", "timeframeSelectedLabel", ["loc", [null, [4, 12], [4, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-bookingReportsList", "onClick", ["subexpr", "action", [["get", "onShowTimeframeModal", ["loc", [null, [6, 22], [6, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 14], [6, 43]]], 0, 0]], ["loc", [null, [3, 4], [6, 45]]], 0, 0], ["block", "if", [["get", "showActivitiesToggleVisible", ["loc", [null, [13, 8], [13, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 2], [21, 9]]]], ["inline", "t", ["components.reports.bookings.filter_active"], [], ["loc", [null, [25, 59], [25, 108]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "filterActiveBookings", ["loc", [null, [27, 24], [27, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 14], [27, 45]]], 0, 0], "disabled", ["subexpr", "not", [["get", "hasResults", ["loc", [null, [28, 20], [28, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 15], [28, 31]]], 0, 0], "onToggle", ["subexpr", "action", [["get", "onToggleFilterActiveBookings", ["loc", [null, [29, 23], [29, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 15], [29, 52]]], 0, 0]], ["loc", [null, [26, 6], [29, 54]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});