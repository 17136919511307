define('scientia-resourcebooker/services/user-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    search: function search(_ref) {
      var query = _ref.query;
      var perPage = _ref.perPage;
      var pageNumber = _ref.pageNumber;

      var data = {
        query: query,
        itemsPerPage: perPage || 30,
        pageNumber: pageNumber || 1
      };

      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.USER_SEARCHER_URL, data);
    }
  });
});