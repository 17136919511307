define('scientia-resourcebooker/pods/components/manage-resources/availability-patterns-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['availabilityPatternsModal'],

    store: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    model: null,
    availabilityPatterns: null,

    availabilityPatternsSorted: _ember['default'].computed.sort('availabilityPatterns', 'sortCriteria'),
    sortCriteria: ['name'],

    selectedAvailabilityPattern: null,

    isLoading: false,

    // Whether the availability pattern modal is in Copy mode or Link mode
    isCopyMode: false,

    copyPatternOptionReplaceAll: false,

    localePrefix: _ember['default'].computed('locale', function () {
      return this.get('locale') + '.';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchPatterns();
    },

    _fetchPatterns: function _fetchPatterns() {
      var _this = this;

      this.get('store').query('availability', { pageNumber: 1, itemsPerPage: 999 }).then(function (data) {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) return;
        _this.set('availabilityPatterns', data || []);
      });
    },

    hasPendingAdd: _ember['default'].computed.filterBy('model', 'isPendingAdd', true),
    hasPendingRemove: _ember['default'].computed.filterBy('model', 'isPendingRemove', true),
    hasPendingChanges: _ember['default'].computed('hasPendingAdd', 'hasPendingRemove', function () {
      return this.get('hasPendingAdd.length') || this.get('hasPendingRemove.length');
    }),

    actions: {
      onItemAdd: function onItemAdd() {

        this.get('model').pushObject(_ember['default'].Object.create({
          name: this.get('selectedAvailabilityPattern.name'),
          id: this.get('selectedAvailabilityPattern.id'),
          isPendingAdd: true
        }));
      },

      onRemoveClick: function onRemoveClick(item) {
        if (_ember['default'].get(item, 'isPendingAdd')) {
          this.send('onItemAddUndo', item);
        } else if (_ember['default'].get(item, 'isPendingRemove')) {
          this.send('onItemRemoveUndo', item);
        } else {
          this.send('onItemRemove', item);
        }
      },

      onItemAddUndo: function onItemAddUndo(item) {
        this.get('model').removeObject(item);
      },

      onItemRemove: function onItemRemove(item) {
        _ember['default'].set(item, 'isPendingRemove', true);
      },

      onItemRemoveUndo: function onItemRemoveUndo(item) {
        _ember['default'].set(item, 'isPendingRemove', false);
      },

      onCopyPatternClick: function onCopyPatternClick() {
        var _this2 = this;

        var defer = _ember['default'].RSVP.defer();

        var confirmData = {
          titlePath: 'components.manage_resources.availability_patterns_modal.copy_mode.confirm_import_pattern_title',
          messagePath: 'components.manage_resources.availability_patterns_modal.copy_mode.confirm_import_pattern_message',
          deferred: defer
        };

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this2.sendAction('copyPattern', _this2.get('selectedAvailabilityPattern'), _this2.get('copyPatternOptionReplaceAll'));
        })['catch'](function () {
          // cancel import
        })['finally'](function () {
          _this2.get('globalConfirmer').close();
        });
      },

      cancel: function cancel() {
        this.get('undo')();
      },

      save: function save() {
        this.get('save')(this.get('model'));
      },

      logLink: function logLink() {
        this.get('appInsights').trackAvailabilityPatternCopyLinkModalViewPattern({ isCopyMode: this.get('isCopyMode') });
      }

    }
  });
});