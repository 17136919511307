define('scientia-resourcebooker/pods/components/manage-resources/permissions-form/types/default/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item'],
    userGroupSearcher: _ember['default'].inject.service(),
    permission: null,

    _searchUserGroups: function _searchUserGroups(query, deferred) {
      this.get('userGroupSearcher').search(query).then(function (data) {
        deferred.resolve(data);
      });
    },

    actions: {

      searchUserGroups: function searchUserGroups(query, deferred) {
        _ember['default'].run.debounce(this, '_searchUserGroups', query, deferred, 1000);
      },

      didSelectPermissionGroup: function didSelectPermissionGroup(permission, value) {
        permission.set('permissionValue', { identity: value.id, name: value.name });
      }
    }
  });
});