define("scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.availability_form.clear_unavailability_title"], [], ["loc", [null, [2, 2], [2, 82]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "confirmModal-message");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.availability_form.clear_unavailability_message"], [], ["loc", [null, [7, 4], [7, 86]]], 0, 0], ["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "unavailabilityTypes", ["loc", [null, [10, 14], [10, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "value", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "unavailabilityTypeSelected", ["loc", [null, [13, 12], [13, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.availability_form.unavailability_type"], [], ["loc", [null, [14, 18], [14, 89]]], 0, 0], "showPlaceholder", true, "class", "at-availabilityClearModal", "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "unavailabilityTypeSelected", ["loc", [null, [17, 28], [17, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 23], [17, 55]]], 0, 0]], [], ["loc", [null, [17, 15], [17, 56]]], 0, 0]], ["loc", [null, [9, 6], [18, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 2
              },
              "end": {
                "line": 30,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["general.confirm"], [], ["loc", [null, [29, 4], [29, 27]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["element", "action", [["get", "close", ["loc", [null, [24, 19], [24, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 10], [24, 26]]], 0, 0], ["inline", "t", ["general.cancel"], [], ["loc", [null, [25, 4], [25, 26]]], 0, 0], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "buttonIsDisabled", ["loc", [null, [28, 26], [28, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "disableButton", ["subexpr", "not", [["get", "unavailabilityTypeSelected", ["loc", [null, [28, 62], [28, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 57], [28, 89]]], 0, 0], "action", "confirm", "type", "submit", "class", "button button--primary at-lf-dialog-footer"], 0, null, ["loc", [null, [28, 2], [30, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/availability-clear-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["needsCloseButton", true, "onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [1, 54], [1, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 46], [1, 60]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [3, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [5, 0], [21, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [23, 0], [32, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});