define('scientia-resourcebooker/pods/logged-in/booking-request/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsAppInsights['default'], {
    bookingRequestSearcher: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    queryParams: {
      query: { replace: true, refreshModel: true },
      bookingId: { replace: true, refreshModel: false },
      occurrenceId: { replace: true, refreshModel: false },
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.booking_request.index.page_title');
    },

    model: function model(params) {
      var hash = {
        searchResults: params.query ? this.searchBookingRequests(params.query, params.page, params.perPage) : null,
        bookings: [],
        bookingRequest: params.bookingId ? this.fetchBookingRequest(params.bookingId, params.occurrenceId) : null
      };

      return _ember['default'].RSVP.hash(hash);
    },

    afterModel: function afterModel(model) {
      if (model.bookingRequest) {
        var controller = this.controllerFor(this.routeName);
        controller.send('openBookingRequestWithModelLoaded', model.bookingRequest);
      }
    },

    searchBookingRequests: function searchBookingRequests(query, pageNumber, perPage) {
      return this.get('bookingRequestSearcher').search({ query: query, pageNumber: pageNumber, perPage: perPage });
    },

    fetchBookingRequest: function fetchBookingRequest(id, occurrenceId) {
      var _this = this;

      // We need to remove the cached bookingRequest if it exists or it will display the cached version
      // if we edit a booking
      var bookingRequestCached = this.store.peekAll('booking-request').findBy('id', id);
      if (bookingRequestCached) {
        this.store.unloadRecord(bookingRequestCached);
      }

      if (occurrenceId) {
        return this.store.queryRecord('booking-request', { bookingId: id, occurrenceId: occurrenceId })['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      } else {

        return this.store.find('booking-request', id)['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          query: '',
          boundQuery: '',
          page: 1,
          perPage: 10,
          showBookingRequest: null,
          editRestrictionsModal: null
        });
      }
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      },
      searchBookingRequests: function searchBookingRequests(query) {
        this.searchBookingRequests(query);
      }

    }
  });
});