define('scientia-resourcebooker/pods/manage-resources/resource-type/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    name: '',
    imageUri: '',

    isSaving: false,
    error: '',
    showIconSelector: false,

    actions: {
      save: function save() {
        this.send('createResourceType', {
          properties: [],
          name: this.get('name'),
          imageUri: this.get('imageUri')
        });
      },

      setIcon: function setIcon(icon) {
        this.set('showIconSelector', false);
        this.set('imageUri', icon.url);
      }
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('i18n').t('pods.manage_resources.resource_type.new.page_title'),
        linkable: false
      }];
    })
  });
});