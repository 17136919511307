define('scientia-resourcebooker/services/booking-request-error-handler', ['exports', 'ember'], function (exports, _ember) {

  /**
   * This servicve was created to handle the errors from violating template rules
   * For specific handling of a template rule all we need is a method with the same rule name
   * and do our logic in it
   */
  exports['default'] = _ember['default'].Service.extend({

    errorHandler: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    handle: function handle(error) {
      var localePrefix = arguments.length <= 1 || arguments[1] === undefined ? 'error_messages' : arguments[1];

      if (error.responseJSON) {

        var errorData = error.responseJSON;
        var exceptionMessageKey = error.responseJSON.ExceptionMessageKey;

        var method = exceptionMessageKey ? exceptionMessageKey.camelize() : null;

        var errorMessage = '';

        // We check if there is a method in this service with the same name of the error key
        if (typeof this[method] === 'function') {
          errorMessage = this[method](errorData, localePrefix);
          return errorMessage;
        }

        // If there wasn't, just handle it normally
        var defaultMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON, localePrefix);
        return defaultMessage ? defaultMessage.toString() : '';
      }
    },

    maximumDaysInAdvance: function maximumDaysInAdvance(data, localePrefix) {
      var message = this.get('errorHandler').buildErrorMessage(data, localePrefix, true);
      return message;
    },

    minimumDaysInAdvance: function minimumDaysInAdvance(data, localePrefix) {
      var message = this.get('errorHandler').buildErrorMessage(data, localePrefix, true);
      return message;
    },

    maximumTimeInAdvance: function maximumTimeInAdvance(data, localePrefix) {
      var validateValue = data.ExceptionMessageParams.validate.toLowerCase();
      var exceptionKey = 'maximum_time_in_advance_' + validateValue;
      data.ExceptionMessageKey = exceptionKey;
      var message = this.get('errorHandler').buildErrorMessage(data, localePrefix, true);
      return message;
    },

    minimumTimeInAdvance: function minimumTimeInAdvance(data, localePrefix) {
      var validateValue = data.ExceptionMessageParams.validate.toLowerCase();
      var exceptionKey = 'minimum_time_in_advance_' + validateValue;
      data.ExceptionMessageKey = exceptionKey;
      var message = this.get('errorHandler').buildErrorMessage(data, localePrefix, true);
      return message;
    },

    bookingWindowCustom: function bookingWindowCustom(data, localePrefix) {
      var message = this.get('errorHandler').buildErrorMessage(data, localePrefix);

      // We either need to use a comma as a separator (flashMessage doesn't support HTML) or a <br>
      // If we are not cancelling (we are creating/updating on the booking form popup)
      var isCancelling = localePrefix === 'pods.logged_in.booking_request.index.flash_messages';
      var separator = isCancelling ? ', ' : '</br>';

      message = message.replace(new RegExp('\\r\\n', 'g'), separator);
      return message;
    },

    bookingWindowMultiple: function bookingWindowMultiple(data, localePrefix) {
      var params = data.ExceptionMessageParams;
      var paramsKeys = Object.keys(params);
      var dateRangesKeys = paramsKeys.filter(function (k) {
        return k.indexOf('endDate') === 0 || k.indexOf('startDate') === 0;
      });
      var dateRangesPairsLength = dateRangesKeys.length / 2;

      //The params we get should be pairs of start and end dates so if it's not an even number we just use default message
      // as something went wrong
      if (dateRangesKeys.length % 2 !== 0) return data.ExceptionMessage;

      // Need to check if we are in MyBookings or Make a Booking popup so we replace a <br> with a comma
      var isCancelling = localePrefix === 'pods.logged_in.booking_request.index.flash_messages';
      var separator = isCancelling ? ', ' : '</br>';

      // Create the initial message
      var message = this.get('i18n').t(localePrefix + '.booking_window_multiple').toString();
      message += isCancelling ? ' ' : separator;

      // Then we loop through each range of dates and assemble it by using the special booking_window_multiple_line translation key
      for (var i = 1; i <= dateRangesPairsLength; i++) {
        var start = params['startDate_' + i];
        var end = params['endDate_' + i];
        var _data = {
          ExceptionMessageKey: 'booking_window_multiple_line',
          ExceptionMessageParams: { startDate: start, endDate: end },
          ExceptionMessageMessage: ''
        };
        var rangeMessage = this.get('errorHandler').buildErrorMessage(_data, localePrefix);
        message += rangeMessage;
        if (i !== dateRangesPairsLength) message += separator;
      }
      return message;
    }

  });
});