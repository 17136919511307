define('scientia-resourcebooker/pods/components/calendar-download/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmModal calendarDownload'],
    classNameBindings: ['hideIcalButtons'],

    systemSettings: _ember['default'].inject.service(),
    calendarSubscriber: _ember['default'].inject.service(),

    copyMessage: '',
    copyClass: '',
    appInsights: _ember['default'].inject.service(),

    hideIcalButtons: _ember['default'].computed.alias('systemSettings.options.icalHideCalendarButtons'),

    calendarFeeds: null,
    isLoadingFeeds: false,
    webcalFeedIsDisabled: _ember['default'].computed.alias('systemSettings.options.icalDisableBookingsWebCal'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('isLoadingFeeds', true);
      this.get('calendarSubscriber').fetchUserBookingsFeeds().then(function (feeds) {
        _this.set('calendarFeeds', feeds);
      })['finally'](function () {
        _this.set('isLoadingFeeds', false);
      });
    },

    actions: {

      subscribe: function subscribe(feed) {
        this.get('calendarSubscriber').subscribeToFeed(feed);
      },

      copy: function copy() {
        var copyText = document.querySelector('#calendarDownload-url');

        try {
          copyText.select();
          document.execCommand('copy');
          this.set('copyMessage', this.get('i18n').t('components.calendar_modal.message_copied'));
          this.set('copyClass', 'calendarDownload-copy-ok');
          this.get('appInsights').trackEvent('click-download-calendar-copy-button-success');
        } catch (err) {
          this.set('copyMessage', this.get('i18n').t('components.calendar_modal.message_not_copied'));
          this.set('copyClass', 'calendarDownload-copy-error');
          this.get('appInsights').trackEvent('click-download-calendar-copy-button-fail');
        }
      }
    }
  });
});