define("scientia-resourcebooker/pods/components/container-sidebar/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/container-sidebar/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "sidebar-toggler");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "sidebar-toggler-close");
        var el3 = dom.createTextNode("✕");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        return morphs;
      },
      statements: [["element", "action", ["toggleSidebar"], [], ["loc", [null, [1, 6], [1, 32]]], 0, 0], ["inline", "inline-svg", ["icons/list-sidebar.svg"], ["class", "sidebar-toggler-open"], ["loc", [null, [2, 2], [2, 70]]], 0, 0], ["inline", "container-sidebar/user", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [11, 30], [11, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "logout", "logout"], ["loc", [null, [11, 0], [11, 52]]], 0, 0], ["inline", "container-sidebar/nav", [], ["notificationsCount", ["subexpr", "@mut", [["get", "notificationsCount", ["loc", [null, [13, 43], [13, 61]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 0], [13, 63]]], 0, 0], ["content", "container-sidebar/meta", ["loc", [null, [15, 0], [15, 26]]], 0, 0, 0, 0], ["content", "container-sidebar/timezone", ["loc", [null, [17, 0], [17, 30]]], 0, 0, 0, 0], ["content", "container-sidebar/language", ["loc", [null, [18, 0], [18, 30]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});