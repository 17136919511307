define('scientia-resourcebooker/pods/components/dropdown-button-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['dropdownButton-item'],
    attributeBindings: ['disabled', 'tabindex'],
    tabindex: 0,
    action: null,
    actionParam: null,
    count: null,
    icon: null,

    click: function click() {
      if (this.get('disabled')) return;
      this.sendAction.apply(this, ['action', this.get('actionParam')]);
    },
    mouseDown: function mouseDown() {
      if (this.get('disabled')) return;
      this.sendAction.apply(this, ['action', this.get('actionParam')]);
    }
  });
});