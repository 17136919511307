define('scientia-resourcebooker/pods/components/bs-datetimepicker/component', ['exports', 'ember', 'ember-bootstrap-datetimepicker/components/bs-datetimepicker'], function (exports, _ember, _emberBootstrapDatetimepickerComponentsBsDatetimepicker) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  var computedProps = _ember['default'].A(['minDate', 'maxDate', 'disabledDates', 'enabledDates', 'dateIcon', 'placeholder', 'daysOfWeekDisabled', 'firstDayOfTheWeekOffset']);

  exports['default'] = _emberBootstrapDatetimepickerComponentsBsDatetimepicker['default'].extend({
    concatenatedProperties: ['textFieldClassNames'],
    classNames: ['bs-datetimepicker'],
    classNameBindings: ['inputGroupClass'],
    // attributeBindings: ['ariaLabel', 'tabindex'],
    ariaLabel: null,
    tabindex: 0,
    textFieldClassNames: ['form-control', 'form-input', 'form-input--date'],
    'class': '',

    systemSettings: _ember['default'].inject.service(),

    locale: _ember['default'].computed.alias('i18n.locale'),
    localeCalculated: _ember['default'].computed('locale', function () {
      return this.get('locale') === 'en' ? 'en_GB' : this.get('locale');
    }),

    bsDateTimePicker: null,
    dateIcon: 'glyphicon glyphicon-calendar',
    placeholder: '',
    debug: false,
    customEvents: _ember['default'].inject.service(),

    restrictToDayIndex: null,

    position: 'auto,auto',

    inputGroupClass: computed('attrs.noIcon', function () {
      if (!this.getAttr('noIcon')) {
        return 'input-group';
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var target;
      if (this.getAttr('noIcon')) {
        target = this.$('.' + this.get('textFieldClassNames').join('.'));
      } else {
        target = this.$();
      }

      var bsDateTimePicker = target.datetimepicker(this._buildConfig());
      this.bsDateTimePicker = bsDateTimePicker.data('DateTimePicker');
      this.scheduledUpdate = run.scheduleOnce('afterRender', this, this._setupChangeEvent, bsDateTimePicker);

      this._updateDateTimePicker();

      if (this.attrs.open) {
        this.bsDateTimePicker.show();
      }

      this.get('customEvents').on('localeChanged', this, '_localeChanged');

      try {
        var ariaLabel = this.get('ariaLabel');
        this.$('.form-input--date').attr('aria-label', ariaLabel || this.get('i18n').t('components.rb_datepicker.pick_a_date').toString());
      } catch (error) {
        //no-op
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      run.cancel(this.scheduledUpdate);
      this.get('customEvents').off('localeChanged', this, '_localeChanged');
      this.bsDateTimePicker.destroy();
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._updateDateTimePicker();
    },

    _setupChangeEvent: function _setupChangeEvent(bsDateTimePicker) {
      var _this = this;

      bsDateTimePicker.on('dp.change', function (ev) {
        run.schedule('actions', function () {
          if (_this.attrs.updateDate) {

            if (_ember['default'].isNone(ev.date) || ev.date === false) {
              _this.sendAction('updateDate', undefined);
              return;
            }

            if (ev.date) {
              if (_this.attrs.forceDateOutput) {
                _this.sendAction('updateDate', ev.date.toDate());
              } else {
                _this.sendAction('updateDate', ev.date.startOf('minute'));
              }
            }
          }
        });
      });

      bsDateTimePicker.on('dp.update', function (ev) {
        _this.sendAction('viewUpdate', ev);
      });
    },

    // jshint maxcomplexity:11
    _adjustDayByRestrictions: function _adjustDayByRestrictions(date) {
      if (!Number.isInteger(this.get('restrictToDayIndex')) || !moment.isMoment(date)) return date;

      // Check if we have a validator action passed in
      var externalValidatorAction = this.get('isValidFn');

      if (externalValidatorAction) {
        var i = 0;
        while (!externalValidatorAction(date) && i <= 100) {
          date.add(1, 'day');
          i++;
          if (i >= 100) {
            // This is just a safeguard to avoid an infinite loop
            break;
          }
        }
      }

      // If we want to restrict dates to certain da indexes (Only Saturdays or only sundays,
      // this will adjust the date until it matches the index (0 = sundays only...)

      // restrictToDayIndexDirection indicates how the date just be adjusted to index. Going forward or backward.
      if (this.get('restrictToDayIndexDirection') === 'forward') {
        while (date.day() !== this.getAttr('restrictToDayIndex')) date.add(1, 'day');
      } else if (this.get('restrictToDayIndexDirection') === 'backward') {
        while (date.day() !== this.getAttr('restrictToDayIndex')) date.subtract(1, 'day');
      }

      return date;
    },

    // jshint maxcomplexity:11
    _updateDateTimePicker: function _updateDateTimePicker() {

      var date = this.getAttr('date');
      if (this.getAttr('restrictToDayIndex') !== null) this._adjustDayByRestrictions(date);

      var dateTimePicker = this.bsDateTimePicker;

      if (dateTimePicker) {

        // Only update locale if not the same as this will trigger a redraw of the datepicker for no reason
        if (dateTimePicker.locale() !== this.get('localeCalculated')) {
          dateTimePicker.locale(this.get('localeCalculated'));
          dateTimePicker.tooltips(this._getTranslations());
        }

        if (this.getAttr('disabled')) {
          dateTimePicker.disable();
        } else {
          dateTimePicker.enable();
        }

        if (date === undefined) {
          dateTimePicker.date(null);
        } else {
          dateTimePicker.date(date);
        }

        if (!this.getAttr('minDate')) {
          dateTimePicker.minDate(false);
        } else {
          dateTimePicker.minDate(this.getAttr('minDate'));
        }

        if (!this.getAttr('maxDate')) {
          dateTimePicker.maxDate(false);
        } else {
          dateTimePicker.maxDate(this.getAttr('maxDate'));
        }

        if (!this.getAttr('disabledDates')) {
          dateTimePicker.disabledDates([]);
        } else {
          dateTimePicker.disabledDates(this.getAttr('disabledDates'));
        }

        if (!this.getAttr('enabledDates')) {
          dateTimePicker.enabledDates([]);
        } else {
          dateTimePicker.enabledDates(this.getAttr('enabledDates'));
        }

        if (!this.getAttr('daysOfWeekDisabled')) {
          dateTimePicker.daysOfWeekDisabled([]);
        } else {
          dateTimePicker.daysOfWeekDisabled(this.getAttr('daysOfWeekDisabled'));
        }
      }
    },

    _buildConfig: function _buildConfig() {
      var datetimepickerDefaultConfig = _ember['default'].$.fn.datetimepicker.defaults;
      var isDatetimepickerConfigKeys = Object.keys(datetimepickerDefaultConfig);
      var config = {};
      var configKey;
      for (var i = 0; i < isDatetimepickerConfigKeys.length; i++) {
        configKey = isDatetimepickerConfigKeys[i];
        if (!computedProps.includes(configKey)) {
          config[configKey] = this.getWithDefault(configKey, datetimepickerDefaultConfig[configKey]);
        }
      }

      config.ignoreReadonly = true;
      config.firstDayOfTheWeekOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
      config.tooltips = this._getTranslations();
      if (this.get('position') !== 'auto,auto') {
        var pos = this.get('position').split(',');
        if (pos.length === 2) config.widgetPositioning = { horizontal: pos[0], vertical: pos[1] };
      }
      return config;
    },

    _localeChanged: function _localeChanged() {
      var dateTimePicker = this.bsDateTimePicker;
      if (dateTimePicker) {
        dateTimePicker.locale(this.get('localeCalculated'));
        dateTimePicker.tooltips(this._getTranslations());
      }
    },

    _getTranslations: function _getTranslations() {
      var _this2 = this;

      var defaults = _ember['default'].$.fn.datetimepicker.defaults.tooltips;
      var tooltips = {};

      Object.keys(defaults).forEach(function (key) {
        tooltips[key] = _this2.get('i18n').t('components.datetimepicker.' + key);
      });

      return tooltips;
    }

  });
});