define("scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 0
              },
              "end": {
                "line": 36,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "model", ["loc", [null, [29, 12], [29, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "value", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [32, 10], [32, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "location", ["loc", [null, [33, 10], [33, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["onTimeframeChange"], [], ["loc", [null, [34, 13], [34, 41]]], 0, 0]], ["loc", [null, [28, 2], [35, 2]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 0
              },
              "end": {
                "line": 65,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "datePeriodSelector-datepickers");
            var el2 = dom.createTextNode("\n\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "tabForm-label at-auditReports");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n     ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "date-input");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "tabForm-label at-auditReports");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n     ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "date-input");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["components.date_period_selector.start_date"], [], ["loc", [null, [41, 48], [41, 98]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "startDate", ["loc", [null, [44, 15], [44, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_hour_full"], [], ["loc", [null, [45, 23], [45, 56]]], 0, 0]], [], ["loc", [null, [45, 17], [45, 57]]], 0, 0], "updateDate", ["subexpr", "action", ["onStartRangeChange"], [], ["loc", [null, [46, 21], [46, 50]]], 0, 0], "readonly", true, "position", "left,auto", "class", "at-datePeriodSelector", "allowInputToggle", true], ["loc", [null, [43, 10], [50, 33]]], 0, 0], ["inline", "t", ["components.date_period_selector.end_date"], [], ["loc", [null, [52, 50], [52, 98]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "endDate", ["loc", [null, [55, 15], [55, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_hour_full"], [], ["loc", [null, [56, 23], [56, 56]]], 0, 0]], [], ["loc", [null, [56, 17], [56, 57]]], 0, 0], "updateDate", ["subexpr", "action", ["onEndRangeChange"], [], ["loc", [null, [57, 21], [57, 48]]], 0, 0], "readonly", true, "position", "left,auto", "class", "at-datePeriodSelector at-datePeriodSelectorEndDate", "allowInputToggle", true], ["loc", [null, [54, 10], [61, 33]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 2
                },
                "end": {
                  "line": 78,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button button--success at-lf-dialog-footer");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              morphs[2] = dom.createElementMorph(element1);
              morphs[3] = dom.createMorphAt(element1, 1, 1);
              return morphs;
            },
            statements: [["element", "action", ["onModalCancel"], [], ["loc", [null, [70, 12], [70, 38]]], 0, 0], ["inline", "t", ["general.cancel"], [], ["loc", [null, [71, 6], [71, 28]]], 0, 0], ["element", "action", ["onModalConfirm"], [], ["loc", [null, [74, 12], [74, 39]]], 0, 0], ["inline", "t", ["general.ok"], [], ["loc", [null, [75, 6], [75, 24]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 0
              },
              "end": {
                "line": 79,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-footer", [], [], 0, null, ["loc", [null, [69, 2], [78, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "containerElement--padded containerElement--margin-bottom-small");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "tabForm-label at-auditReports");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n   ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "containerElement--padded containerElement--margin-bottom-small");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "tabForm-label at-auditReports");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n   ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "containerElement--padded");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(fragment, [3]);
          var element5 = dom.childAt(fragment, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element3, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(element4, 3, 3);
          morphs[4] = dom.createMorphAt(element5, 1, 1);
          morphs[5] = dom.createMorphAt(element5, 3, 3);
          morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["components.reports.audit.report_selection"], [], ["loc", [null, [4, 46], [4, 95]]], 0, 0], ["inline", "toggle-button-group", [], ["content", ["subexpr", "@mut", [["get", "reportSelectionTypes", ["loc", [null, [6, 12], [6, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "reportSelectionSelected", ["loc", [null, [7, 13], [7, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "singleValue", true, "action", ["subexpr", "action", [["get", "setReportSelectionSelected", ["loc", [null, [9, 19], [9, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 11], [9, 46]]], 0, 0], "class", "at-auditOptions"], ["loc", [null, [5, 3], [11, 6]]], 0, 0], ["inline", "t", ["components.reports.audit.report_based_on"], [], ["loc", [null, [15, 46], [15, 94]]], 0, 0], ["inline", "toggle-button-group", [], ["content", ["subexpr", "@mut", [["get", "dateOptionsContent", ["loc", [null, [17, 12], [17, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "dateOptionSelected", ["loc", [null, [18, 13], [18, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "singleValue", true, "action", ["subexpr", "action", [["get", "setDateOptionType", ["loc", [null, [20, 19], [20, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 11], [20, 37]]], 0, 0], "class", "at-auditOptions"], ["loc", [null, [16, 3], [22, 6]]], 0, 0], ["block", "unless", [["get", "hideTimeframeOptions", ["loc", [null, [27, 10], [27, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 0], [36, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "selected", ["loc", [null, [38, 10], [38, 18]]], 0, 0, 0, 0], "specific_range"], [], ["loc", [null, [38, 6], [38, 36]]], 0, 0]], [], 1, null, ["loc", [null, [38, 0], [65, 7]]]], ["block", "if", [["get", "isModal", ["loc", [null, [68, 6], [68, 13]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [68, 0], [79, 7]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 82,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/reports/reports-audit/audit-options/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "container-element", [], ["padded", true, "title", ["subexpr", "if", [["get", "title", ["loc", [null, [1, 43], [1, 48]]], 0, 0, 0, 0], ["get", "title", ["loc", [null, [1, 49], [1, 54]]], 0, 0, 0, 0], ["subexpr", "t", ["components.reports.audit.audit_options"], [], ["loc", [null, [1, 55], [1, 99]]], 0, 0]], [], ["loc", [null, [1, 39], [1, 100]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [81, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});