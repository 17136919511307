define('scientia-resourcebooker/pods/manage-resources/system/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.system.page_title');
    },

    systemSettings: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        //templates: this.get('templateProcessor').fetch(),
        options: this.get('systemSettings').fetch()
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0
        });
      }
    },

    actions: {

      // Invalidate model will refresh the model so lets make sure our systemSettings options have been reset to null
      // so we can refetch them
      invalidateModel: function invalidateModel() {
        this.set('systemSettings.options', null);
        this.refresh();
      }
    }

  });
});