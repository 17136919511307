define('scientia-resourcebooker/pods/components/dataset-selection/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['dataset-selection'],
    content: [],

    selected: null,

    onChange: (function () {
      _ember['default'].run.next(this, '_onChange', this.get('selected'));
    }).observes('selected'),

    _onChange: function _onChange(dataset) {
      this.set('selected', dataset);
      if (dataset) {
        localStorage.setItem('scientia-rb-dataset', JSON.stringify(dataset));
      } else {
        localStorage.removeItem('scientia-rb-dataset');
      }
    }
  });
});