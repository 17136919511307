define('scientia-resourcebooker/services/calendar-options-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    saveSystemCalendar: function saveSystemCalendar(data) {
      var _this = this;

      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.INSTITUTION_CALENDAR_SETTINGS, data).then(function (response) {
        _this.get('systemSettings').fetch(true);
        return response;
      });
    }

  });
});