define('scientia-resourcebooker/pods/components/bread-crumbs/component', ['exports', 'ember', 'ember-breadcrumbs/components/bread-crumbs'], function (exports, _ember, _emberBreadcrumbsComponentsBreadCrumbs) {
  exports['default'] = _emberBreadcrumbsComponentsBreadCrumbs['default'].extend({
    tagName: 'nav',
    classNames: ['breadCrumbs'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].run.schedule('afterRender', this, function () {
        this.element.setAttribute('aria-label', 'Breadcrumbs');
      });
    }
  });
});