define('scientia-resourcebooker/pods/manage-resources/resource-type/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    systemSettings: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    isAdmin: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    isLite: _ember['default'].computed.bool('systemSettings.options.isLite'),
    resources: _ember['default'].computed.sort('model', 'resourceSorting'),
    resourceSorting: ['name'],
    resource: null,
    showConfirmDeleteResourceType: false,

    actions: {
      deleteResourceType: function deleteResourceType(resourceType) {
        this.set('showConfirmDeleteResourceType', true);
        this.set('resourceType', resourceType);
      },

      confirmDeleteResourceType: function confirmDeleteResourceType() {
        var _this = this;

        this.get('resourceType').destroyRecord().then(function () {
          return _this.deleteSuccessFlash('resource-type');
        })['catch'](function () {
          return _this.deleteErrorFlash('resource-type');
        })['finally'](function () {
          _this.set('showConfirmDeleteResourceType', false);
          _this.set('resource', null);
          _this.set('confirmMessage', null);
          _this.set('confirmTitle', null);
        });
      }
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'), linkable: false }];
    })
  });
});