define('scientia-resourcebooker/pods/components/booking-report-list/item-activity/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    iconsAvailable: ['tt-splus'],

    classNames: ['bookingReportListItem', 'bookingReportListItem--activity'],
    model: null,

    timeFormat: _ember['default'].computed('i18n.locale', function () {
      var format = 'components.booking_report_list.time_format';
      return this.get('i18n').t(format);
    }),

    hasSourceIcon: _ember['default'].computed('model.source', function () {
      return this.get('iconsAvailable').map(function (i) {
        return i.toLowerCase();
      }).includes(this.get('model.source').toLowerCase());
    })

  });
});