define('scientia-resourcebooker/pods/logged-in/booking-type/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.logged_in.booking_type.index.bookings'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: [{ bookingID: 'id' }],
    bookingID: '',
    showBookingRequest: null,

    bookingTypeSorting: ['name'],
    bookingTypes: _ember['default'].computed.sort('model.bookingTypes', 'bookingTypeSorting'),
    showBookingRequestActions: false,
    isLoadingModel: false,

    showBookingRequestModal: function showBookingRequestModal(bookingRequest) {
      this.set('showBookingRequest', bookingRequest);
      _ember['default'].run.next(this, 'set', 'bookingID', '');
    },

    actions: {
      onConfirmNavigation: function onConfirmNavigation(bookingType) {
        this.transitionToRoute('logged-in.booking-type.show', bookingType.get('id'));
      }
    }

  });
});