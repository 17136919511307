define("scientia-resourcebooker/pods/components/modal-target/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 0
                },
                "end": {
                  "line": 15,
                  "column": 0
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/modal-target/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "liquid-modal");
              var el2 = dom.createTextNode("\n\n  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "liquid-child");
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "lm-container");
              var el4 = dom.createTextNode("\n      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "tabindex", "0");
              dom.setAttribute(el4, "role", "dialog");
              dom.setAttribute(el4, "aria-modal", "true");
              dom.setAttribute(el4, "id", "main-modal-section");
              var el5 = dom.createTextNode("\n      ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n      ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var element1 = dom.childAt(element0, [3, 1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createAttrMorph(element1, 'onclick');
              morphs[2] = dom.createAttrMorph(element2, 'class');
              morphs[3] = dom.createAttrMorph(element2, 'aria-label');
              morphs[4] = dom.createMorphAt(element2, 1, 1);
              return morphs;
            },
            statements: [["inline", "internal-anchor-link", [], ["fragment", "main-modal-section", "text", ["subexpr", "t", ["components.container_sidebar.skip_to_content"], [], ["loc", [null, [6, 60], [6, 110]]], 0, 0]], ["loc", [null, [6, 2], [6, 113]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", [["subexpr", "ignore-children", [["get", "currentModal.onOutsideClick", ["loc", [null, [8, 64], [8, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 47], [8, 92]]], 0, 0]], [], ["loc", [null, [null, null], [8, 94]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["lf-dialog ", ["get", "currentModal.dialogClass", ["loc", [null, [9, 75], [9, 99]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["get", "currentModal.dialogDescription", ["loc", [null, [9, 141], [9, 171]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["get", "currentModal.body", ["loc", [null, [10, 18], [10, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 6], [10, 37]]], 0, 0]],
            locals: ["currentModal"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 0
              },
              "end": {
                "line": 16,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/modal-target/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "liquid-bind", [["get", "modal", ["loc", [null, [3, 15], [3, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [15, 16]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/modal-target/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "modal", ["loc", [null, [2, 6], [2, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [16, 7]]]]],
        locals: ["modal"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/modal-target/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "from-elsewhere", [], ["name", "modal"], 0, null, ["loc", [null, [1, 0], [17, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});