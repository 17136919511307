define('scientia-resourcebooker/services/booking-request-searcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    search: function search(_ref) {
      var _this = this;

      var query = _ref.query;
      var type = _ref.type;
      var perPage = _ref.perPage;
      var pageNumber = _ref.pageNumber;

      var data = {
        query: query,
        itemsPerPage: perPage || 10,
        pageNumber: pageNumber || 1
      };

      return this.get('store').query('booking-request', data).then(function (results) {
        _this.get('appInsights').trackEvent('booking-search', {
          query: query,
          count: results.get('length'),
          currentPage: results.get('meta.currentPage'),
          totalPages: results.get('meta.totalPages') });
        return _this._serializeResults(results);
      });
    },

    _serializeResults: function _serializeResults(data) {

      var resultsByDate = data.reduce(function (memo, booking) {
        var formattedDate = moment(booking.get('startDateTime')).format('YYYY-MM-DD');
        if (!memo[formattedDate]) memo[formattedDate] = [];
        memo[formattedDate].push(booking);
        return memo;
      }, {});

      var newResults = Object.keys(resultsByDate).reduce(function (memo, date) {
        memo.push({
          date: date,
          events: resultsByDate[date]
        });

        return memo;
      }, []);

      // Sort the dates and its events chronologically
      newResults.forEach(function (eventGroup) {
        eventGroup = eventGroup.events.sort(function (left, right) {
          return moment.utc(right.timeStamp).diff(moment.utc(left.timeStamp));
        });
      });

      data.results = newResults;
      return {
        results: data.results,
        resourcesMeta: data.meta
      };
    }
  });
});