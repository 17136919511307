define('scientia-resourcebooker/pods/components/manage-resources/resources-meta/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['manageResourcesResourcesMeta'],
    currentPage: 1,
    totalPages: 1,

    jumptoPageObserver: (function () {
      var _this = this;

      _ember['default'].run.once('this', function () {
        _this.sendAction('changePage', _this.get('currentPage'));
      });
    }).observes('currentPage'),

    nextPage: (function () {
      if (this.get('currentPage') < this.get('totalPages')) {
        return this.get('currentPage') + 1;
      }
    }).property('currentPage', 'totalPages'),

    previousPage: (function () {
      if (this.get('currentPage') > 1) {
        return this.get('currentPage') - 1;
      }
    }).property('currentPage'),

    pageList: (function () {
      var list = [];
      for (var i = 1; i <= this.get('totalPages'); i++) {
        list.pushObject({ id: i, label: i });
      }
      return list;
    }).property('totalPages')
  });
});