define('scientia-resourcebooker/pods/components/error-box/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['errorBoxContainer'],
    classNameBindings: ['error:hasError'],
    error: null,
    showClear: false,

    click: function click() {
      this.set('error', null);
    }

  });
});