define("scientia-resourcebooker/pods/logged-in/booking-request/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["calendar-download"], ["showIcalRegenerateTokenModal", ["subexpr", "action", ["showIcalRegenerateTokenModal"], [], ["loc", [null, [8, 35], [8, 74]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [9, 25], [9, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 46]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 53]]], 0, 0]], ["loc", [null, [6, 9], [9, 54]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [10, 32], [10, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 27], [10, 53]]], 0, 0], false], [], ["loc", [null, [10, 19], [10, 60]]], 0, 0]], ["loc", [null, [5, 7], [10, 61]]], 0, 0]], ["loc", [null, [3, 2], [11, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["confirmMessage", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_confirm"], [], ["loc", [null, [20, 23], [20, 86]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_title"], [], ["loc", [null, [21, 21], [21, 82]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [22, 27], [22, 55]]], 0, 0, 0, 0], false], [], ["loc", [null, [22, 22], [22, 62]]], 0, 0]], [], ["loc", [null, [22, 14], [22, 63]]], 0, 0], "confirm", ["subexpr", "action", ["regenerateTokenConfirm"], [], ["loc", [null, [23, 16], [23, 49]]], 0, 0]], ["loc", [null, [18, 11], [23, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 39], [24, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 29], [24, 44]]], 0, 0]], [], ["loc", [null, [24, 21], [24, 45]]], 0, 0]], ["loc", [null, [17, 9], [24, 46]]], 0, 0]], ["loc", [null, [15, 4], [25, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 42,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [34, 12], [34, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", ["subexpr", "@mut", [["get", "showBookingRequestActions", ["loc", [null, [35, 32], [35, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [36, 25], [36, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 20], [36, 44]]], 0, 0], false], [], ["loc", [null, [36, 12], [36, 51]]], 0, 0], "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [37, 20], [37, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [38, 18], [38, 47]]], 0, 0]], ["loc", [null, [32, 9], [38, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "dialogDescription", ["subexpr", "t", ["pods.logged_in.booking_request.index.booking_details_description"], [], ["loc", [null, [40, 22], [40, 92]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [41, 32], [41, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 27], [41, 51]]], 0, 0], false], [], ["loc", [null, [41, 19], [41, 58]]], 0, 0]], ["loc", [null, [31, 7], [41, 59]]], 0, 0]], ["loc", [null, [29, 2], [41, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [50, 12], [50, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [48, 9], [50, 34]]], 0, 0]], ["loc", [null, [47, 7], [50, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [51, 37], [51, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 27], [51, 42]]], 0, 0]], [], ["loc", [null, [51, 19], [51, 43]]], 0, 0]], ["loc", [null, [45, 2], [52, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 0
            },
            "end": {
              "line": 67,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [61, 12], [61, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [62, 25], [62, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 20], [62, 64]]], 0, 0], null], [], ["loc", [null, [62, 12], [62, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [63, 14], [63, 58]]], 0, 0]], ["loc", [null, [59, 9], [63, 59]]], 0, 0], "dialogDescription", ["subexpr", "t", ["pods.logged_in.booking_request.index.cancel_modal_description"], [], ["loc", [null, [64, 22], [64, 89]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [65, 32], [65, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 27], [65, 71]]], 0, 0], null], [], ["loc", [null, [65, 19], [65, 77]]], 0, 0]], ["loc", [null, [58, 7], [65, 78]]], 0, 0]], ["loc", [null, [56, 2], [66, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 0
            },
            "end": {
              "line": 82,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [75, 25], [75, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 20], [75, 59]]], 0, 0], false], [], ["loc", [null, [75, 12], [75, 66]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_title"], [], ["loc", [null, [76, 19], [76, 95]]], 0, 0], "singleButtonMode", true, "confirmMessage", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_message"], [], ["loc", [null, [78, 21], [78, 99]]], 0, 0], "confirm", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [79, 27], [79, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 22], [79, 61]]], 0, 0], false], [], ["loc", [null, [79, 14], [79, 68]]], 0, 0]], ["loc", [null, [73, 9], [79, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [80, 32], [80, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [80, 27], [80, 66]]], 0, 0], null], [], ["loc", [null, [80, 19], [80, 72]]], 0, 0]], ["loc", [null, [72, 7], [80, 73]]], 0, 0]], ["loc", [null, [70, 2], [81, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 0
            },
            "end": {
              "line": 97,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["withBookingRequest", ["subexpr", "@mut", [["get", "withBookingRequest", ["loc", [null, [89, 25], [89, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [90, 21], [90, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [91, 22], [91, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "nameWithPrimaryResourceNames", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [93, 25], [93, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [93, 20], [93, 50]]], 0, 0], null], [], ["loc", [null, [93, 12], [93, 56]]], 0, 0], "on-event-click", ["subexpr", "action", ["openBookingRequestOverview"], [], ["loc", [null, [94, 21], [94, 58]]], 0, 0]], ["loc", [null, [87, 9], [94, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [95, 32], [95, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 27], [95, 57]]], 0, 0], null], [], ["loc", [null, [95, 19], [95, 63]]], 0, 0]], ["loc", [null, [86, 7], [95, 64]]], 0, 0]], ["loc", [null, [85, 2], [96, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 10
                },
                "end": {
                  "line": 120,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "button-print", [], ["onClick", ["subexpr", "action", ["onPrintClick"], [], ["loc", [null, [119, 35], [119, 58]]], 0, 0], "location", "at-bookingRequestsIndex"], ["loc", [null, [119, 12], [119, 95]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 8
              },
              "end": {
                "line": 126,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [118, 20], [118, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [118, 10], [120, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 130,
                "column": 8
              },
              "end": {
                "line": 132,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--primary at-downloadCalendar");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["showDownloadCalendar"], [], ["loc", [null, [131, 18], [131, 51]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "at-downloadCalendar-icon"], ["loc", [null, [131, 103], [131, 171]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.subscribe"], [], ["loc", [null, [131, 177], [131, 231]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 8
              },
              "end": {
                "line": 134,
                "column": 48
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "help-trigger", ["loc", [null, [134, 32], [134, 48]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 137,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle--align-right at-bookingRequest");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          morphs[3] = dom.createMorphAt(element5, 6, 6);
          morphs[4] = dom.createMorphAt(element5, 8, 8);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [113, 32], [113, 95]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [114, 24], [114, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", ["subexpr", "action", ["submitSearch"], [], ["loc", [null, [114, 41], [114, 64]]], 0, 0], "placeholder", ["subexpr", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [114, 77], [114, 138]]], 0, 0], "class", "form-input form-input--white sectionTitle-search-input at-bookingRequest"], ["loc", [null, [114, 10], [114, 221]]], 0, 0], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [117, 18], [117, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [117, 8], [126, 19]]]], ["block", "unless", [["get", "icalDisableBookings", ["loc", [null, [130, 18], [130, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [130, 8], [132, 19]]]], ["block", "unless", [["get", "media.isTiny", ["loc", [null, [134, 18], [134, 30]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [134, 8], [134, 59]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child8 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 8
              },
              "end": {
                "line": 152,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "rb-agenda-view", [], ["results", ["subexpr", "@mut", [["get", "model.searchResults.results", ["loc", [null, [150, 35], [150, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-bookingRequest", "onEventClick", "openBookingRequestOverview"], ["loc", [null, [150, 10], [150, 132]]], 0, 0], ["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.currentPage", ["loc", [null, [151, 39], [151, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [151, 98], [151, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "totalPages", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.totalPages", ["loc", [null, [151, 141], [151, 185]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [151, 10], [151, 187]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 139,
              "column": 4
            },
            "end": {
              "line": 154,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestSearch");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "bookingRequestSearch-title");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("em");
          dom.setAttribute(el4, "class", "bookingRequestSearch-title-query");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.results_for"], [], ["loc", [null, [143, 12], [143, 68]]], 0, 0], ["content", "query", ["loc", [null, [144, 57], [144, 66]]], 0, 0, 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [146, 18], [146, 42]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.clear_search"], [], ["loc", [null, [146, 58], [146, 115]]], 0, 0], ["block", "if", [["get", "model.searchResults.results.length", ["loc", [null, [149, 14], [149, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [149, 8], [152, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child9 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 154,
                "column": 4
              },
              "end": {
                "line": 158,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.booking_request.index.searchLoading"], [], ["loc", [null, [156, 8], [156, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 160,
                  "column": 6
                },
                "end": {
                  "line": 177,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [162, 25], [162, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "calendarEvents", ["subexpr", "@mut", [["get", "model.bookings", ["loc", [null, [164, 25], [164, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "event-id-path", "id", "event-title-path", "nameWithPrimaryResourceNames", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "status", "show-tag-button", false, "on-view-type-changed", ["subexpr", "action", ["onViewTypeChanged"], [], ["loc", [null, [171, 31], [171, 59]]], 0, 0], "on-event-click", "openBookingRequestOverview", "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-sheet-change", "fetchBookingRequests"], ["loc", [null, [161, 8], [176, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 4
              },
              "end": {
                "line": 179,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [160, 12], [160, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [160, 6], [177, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 154,
              "column": 4
            },
            "end": {
              "line": 179,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showSearchLoading", ["loc", [null, [154, 14], [154, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [154, 4], [179, 4]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 182,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("main");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        dom.setAttribute(el2, "id", "main");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "class", "container-content at-bookingRequest");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [15, 1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
        morphs[7] = dom.createAttrMorph(element7, 'aria-label');
        morphs[8] = dom.createMorphAt(element7, 1, 1);
        morphs[9] = dom.createMorphAt(element7, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "showDownloadCalendar", ["loc", [null, [2, 6], [2, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [12, 7]]]], ["block", "if", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [14, 6], [14, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 0], [26, 7]]]], ["block", "if", [["get", "showBookingRequest", ["loc", [null, [28, 6], [28, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [42, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [44, 6], [44, 27]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [44, 0], [53, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [55, 6], [55, 44]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [55, 0], [67, 7]]]], ["block", "if", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [69, 6], [69, 39]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [69, 0], [82, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [84, 6], [84, 30]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [84, 0], [97, 7]]]], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [100, 74], [100, 102]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["icon", "icons/calendar.svg", "text", ["subexpr", "t", ["pods.logged_in.booking_request.index.my_bookings"], [], ["loc", [null, [104, 9], [104, 63]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [107, 16], [107, 75]]], 0, 0], "clickAction", "reloadView", "location", "at-bookingRequest"], 7, null, ["loc", [null, [102, 4], [137, 22]]]], ["block", "if", [["get", "showSearchResults", ["loc", [null, [139, 10], [139, 27]]], 0, 0, 0, 0]], [], 8, 9, ["loc", [null, [139, 4], [179, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});