define('scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'label',
    classNames: ['manageResources-itemForm-form-item'],

    uniqueId: null,

    choice: null,
    checked: false,

    actions: {
      toggleProperty: function toggleProperty(key, value) {
        if (this.get('disabled')) return;
        var action = value ? 'onUnchecked' : 'onChecked';
        this.sendAction(action, this.get('choice'));
      }
    }
  });
});