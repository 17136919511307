define('scientia-resourcebooker/pods/manage-resources/availability/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    globalConfirmer: _ember['default'].inject.service(),
    availabilityPatternDuplicator: _ember['default'].inject.service(),

    query: '',
    boundQuery: '',
    queryParams: ['query'],

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.set('query', this.get('boundQuery'));
    },

    actions: {

      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 500, false);
      },

      deleteItem: function deleteItem(item) {
        var _this = this;

        var defer = _ember['default'].RSVP.defer();

        var messagePath = item.get('resourcesLinkedTo') || item.get('bookingTypesLinkedTo') ? 'confirm_delete_message_used' : 'confirm_delete_message_unused';
        var confirmData = {
          titlePath: 'pods.manage_resources.availability.index.confirm_delete_title',
          message: this.get('i18n').t('pods.manage_resources.availability.index.' + messagePath, { resourceCount: item.get('resourcesLinkedTo'), templateCount: item.get('bookingTypesLinkedTo') }),
          showConfirmText: item.get('resourcesLinkedTo') || item.get('bookingTypesLinkedTo'),
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.ok'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this.send('deleteModel', item);
        })['catch'](function () {
          //no-op
        })['finally'](function () {
          _this.get('globalConfirmer').close();
        });
      },

      duplicatePattern: function duplicatePattern(pattern) {
        var _this2 = this;

        var defer = _ember['default'].RSVP.defer();
        var confirmData = {
          titlePath: 'pods.manage_resources.availability.index.duplicate_title',
          messagePath: 'pods.manage_resources.availability.index.duplicate_message',
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.ok'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this2.send('confirmDuplicatePattern', pattern);
        })['catch'](function () {
          //no-op
        })['finally'](function () {
          _this2.get('globalConfirmer').close();
        });
      },

      confirmDuplicatePattern: function confirmDuplicatePattern(pattern) {
        var _this3 = this;

        this.send('loadingWithoutTransition');
        this.get('availabilityPatternDuplicator').copy(pattern.get('id')).then(function () {
          _this3.send('reload');
        })['finally'](function () {
          _this3.send('finished');
        });
      }

    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.availability.index.availability_patterns'), linkable: false }];
    }).property('i18n.locale')

  });
});