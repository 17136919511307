define('scientia-resourcebooker/pods/manage-resources/booking-form/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    showConfirmDeleteForm: null,
    showConfirmDuplicateForm: null,

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.booking_form.index.forms'), linkable: false }];
    }).property('i18n.locale'),

    actions: {

      deleteBookingFormConfirm: function deleteBookingFormConfirm() {
        var _this = this;

        var form = this.get('showConfirmDeleteForm');
        this.send('loading');
        return form.destroyRecord()['finally'](function () {
          _this.send('finished');
          _this.set('showConfirmDeleteForm', null);
        });
      },

      duplicateBookingFormConfirm: function duplicateBookingFormConfirm() {
        var _this2 = this;

        var form = this.get('showConfirmDuplicateForm');
        var json = form.toJSON();
        json.associatedBookingTypes = [];

        var newForm = this.get('store').createRecord('booking-form', json);
        var newName = form.get('name') + ' (2)';
        var allForms = this.get('model');
        while (!_ember['default'].isEmpty(allForms.findBy('name', newName))) {
          newName += ' (2)';
        }

        newForm.set('name', newName);

        // Reset section and field IDS
        newForm.get('sections').setEach('identity', null);
        newForm.get('sections').getEach('fields').forEach(function (field) {
          return field.setEach('identity', null);
        });

        newForm.save()['finally'](function () {
          _this2.send('finished');
          _this2.set('showConfirmDuplicateForm', null);
        });
      }
    }
  });
});