define('scientia-resourcebooker/services/calendar-subscriber', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var PROVIDER_ICONS = {
    apple: 'icons/apple.svg',
    google: 'icons/google.svg',
    office365: 'icons/office365.svg',
    outlook: 'icons/outlook.svg',
    outlookOnline: 'icons/outlook.svg',
    yahoo: 'icons/yahoo.svg'
  };

  exports['default'] = _ember['default'].Service.extend({

    systemSettings: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetchUserBookingsFeeds: function fetchUserBookingsFeeds() {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.USER_BOOKINGS_ICAL_FEEDS).then(function (results) {
        var feeds = {};
        Object.keys(results).forEach(function (key) {
          feeds[key] = {
            url: results[key],
            name: key.toLowerCase(),
            icon: PROVIDER_ICONS[key]
          };
        });

        return feeds;
      });
    },

    subscribeToFeed: function subscribeToFeed(feed) {
      this.get('appInsights').trackEvent('click-subscribe-' + feed.name);
      window.open(feed.url);
    },

    regenerateUserToken: function regenerateUserToken() {
      this.get('appInsights').trackEvent('click-subscribe-regenerate-token');
      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.USER_BOOKINGS_ICAL_RESET_TOKEN);
    }

  });
});