define('scientia-resourcebooker/pods/components/resource-tree/component', ['exports', 'ember'], function (exports, _ember) {
  var _slice = Array.prototype.slice;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourceTree manageResourcesItemsList'],
    classNameBindings: ['disabled:resourceTree--disabled'],
    tagName: 'ul',

    model: null,
    selected: null,
    disabled: false,
    actions: {

      onSelect: function onSelect() {
        this.sendAction.apply(this, ['onSelect'].concat(_slice.call(arguments)));
      },

      paginate: function paginate(page) {
        this.sendAction('paginate', parseInt(page, 10));
      },

      changePage: function changePage(modelType, page) {
        this.sendAction('paginate', parseInt(page, 10));
      },

      onPreviousPage: function onPreviousPage(modelType) {
        var page = modelType.meta.currentPage;
        this.sendAction('paginate', page - 1);
      },

      onNextPage: function onNextPage(modelType) {
        var page = modelType.meta.currentPage;
        this.sendAction('paginate', page + 1);
      }

    }

  });
});