define('scientia-resourcebooker/serializers/resource', ['exports', 'ember', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _ember, _emberData, _scientiaResourcebookerSerializersApplication) {

  var EDIT_PERMISSION_GROUPS = ['all', 'description', 'permissions', 'customFields', 'availability'];

  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    store: _ember['default'].inject.service(),

    attrs: {
      properties: { embedded: 'always' }
    },

    normalize: function normalize(model, hash) {
      var applicationSerializer = this.get('store').serializerFor('application');
      hash = applicationSerializer._camelizeKeysDeep(hash);
      hash = applicationSerializer._setPermissionGroupDefaults(hash);

      hash = applicationSerializer._normalizeNestedPermissionGroups(hash, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');

      if (hash.properties && hash.properties.length) hash.properties = hash.properties.reject(function (property) {
        return property.isObsolete;
      });
      hash.approvePermissionGroups = hash.resourceOwners || [];
      delete hash.resourceOwners;
      var data = this._super(model, hash);
      return data;
    },

    serialize: function serialize() {
      var applicationSerializer = this.get('store').serializerFor('application');

      var serialized = this._super.apply(this, arguments);

      serialized.resourceOwners = serialized.approvePermissionGroups;
      delete serialized.approvePermissionGroups;

      serialized.externalApplicationIdentity = serialized.externalApplicationId;
      delete serialized.externalApplicationId;

      serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');

      var properties = serialized.properties.map(function (property) {

        var val = property.value;
        var type = property.valueType;
        if (type === 'number' && !val) val = null;

        if (type === 'text' || type === 'email') {

          if (val) {
            val = val.trim();
          } else {
            val = null;
          }
        }

        return {
          identity: property.identity,
          value: val,
          valueType: property.valueType,
          valueChoices: property.valueChoices
        };
      });

      serialized.properties = properties;

      return serialized;
    }
  });
});