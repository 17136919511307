define('scientia-resourcebooker/pods/components/manage-resources/claims-form/claim-value/component', ['exports', 'ember'], function (exports, _ember) {

  var CLAIM_TYPE_VALUES = ['group', 'nameidentifier', 'emailaddress', 'givenname'];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item'],
    classNameBindings: ['location'],
    location: null,
    claim: null,
    excludeGroup: false,
    disabled: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('excludeGroup') && this.get('claim.claimType') === 'group') {
        this.set('claim.claimType', 'nameidentifier');
      }
    },

    claimTypeValues: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var values = CLAIM_TYPE_VALUES.slice();

      var excludeGroup = this.get('excludeGroup');
      if (excludeGroup) values.shift();

      return values.map(function (value) {
        return { value: value, label: _this.get('i18n').t('components.manage_resources.claims_form.claim_types.' + value) };
      });
    })

  });
});