define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/booking-form-field-numeric/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-item at-bookingForm'],
    displayReadOnly: false,

    placeholderMin: _ember['default'].computed('model.minimum', 'model.isMinMaxRestricted', function () {
      return this.get('model.isMinMaxRestricted') ? this.get('model.minimum') : null;
    }),

    placeholderMax: _ember['default'].computed('model.maximum', 'model.isMinMaxRestricted', function () {
      return this.get('model.isMinMaxRestricted') ? this.get('model.maximum') : null;
    })

  });
});