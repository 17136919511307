define('scientia-resourcebooker/pods/components/date-input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['date-input'],
    closeOnSelection: false,
    direction: 'any',
    systemSettings: _ember['default'].inject.service(),

    formattedValue: (function () {
      var value = this.get('value');
      if (!value) value = moment();
      return value.format(this.get('i18n').t('components.date_input.date_format').toString());
    }).property('value'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('datepicker', this._initializeDatepicker('input'));

      this.get('datepicker').subscribe('change', function (date) {
        _this.set('value', moment(date));
      });
    },

    change: function change(event) {
      this.set('value', moment(_ember['default'].$(event.currentTarget).find('input').val()));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('datepicker')) this.get('datepicker').destroy();
    },

    _initializeDatepicker: function _initializeDatepicker(input) {
      if (!this.$()) return false;
      return new Kalendae.Input(this.$().find(input)[0], {
        direction: this.get('direction'),
        format: this.get('i18n').t('components.date_input.date_format').toString(),
        closeOnSelection: this.get('closeOnSelection'),
        weekStart: this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset'),
        selected: this.get('value'),
        side: 'top'
      });
    },

    _supportsDateInput: function _supportsDateInput() {
      var input = document.createElement('input');
      input.setAttribute('type', 'date');

      var notADateValue = 'not-a-date';
      input.setAttribute('value', notADateValue);

      return input.value !== notADateValue;
    }
  });
});