define('scientia-resourcebooker/pods/components/download-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal downloadModal'],
    classNameBindings: ['location'],
    location: '',
    model: null,
    downloadUrlValid: true,

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].run.later(this, function () {
        if (this.get('isDestroyed') || this.get('isDestroying')) return;
        this.set('downloadUrlValid', false);
      }, 1000 * 60 * 30);
    },

    actions: {
      download: function download(url) {
        window.open(url, '_blank');
        this.get('close')();
      }
    }
  });
});