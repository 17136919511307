define('scientia-resourcebooker/services/resource-type-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var SEARCH_TYPE = [null, 'permissions', 'customFields'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    search: function search(query) {
      var data = { itemsPerPage: 30, query: query.term, pageNumber: query.page };
      data.searchType = SEARCH_TYPE.indexOf(query.searchType || null);

      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.SEARCH.RESOURCE_TYPE, data).then(function (response) {
        var hasMoreResults = response.currentPage < response.totalPages;
        var responseData = { data: response.results, more: hasMoreResults };
        return responseData;
      });
    }
  });
});