define('scientia-resourcebooker/pods/components/section-title-actions/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sectionTitle-actions', 'sectionTitle-actions--withManage'],
    classNameBindings: ['location', 'disabled:sectionTitle-actions--hidden'],

    location: '',
    disabled: false,

    label: (0, _emberI18n.translationMacro)('general.manage'),

    didInsertElement: function didInsertElement() {
      var items = this.$('.sectionTitle-actions-item');
      if (!items.length) {
        this.$().css('display', 'none');
      }
    }

  });
});