define('scientia-resourcebooker/services/notifications-count-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  // Just for reference of what the display modes are (0, 1, 2, 3)
  // const NOTIFICATION_DISPLAY_MODE = ['DISABLED', 'MENU_ONLY', 'TABS_ONLY', 'ALL'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    notificationsCount: 0,
    refreshTimer: null,
    customEvents: _ember['default'].inject.service(),

    notificationCountEnabledForMenuItem: _ember['default'].computed('systemSettings.pendingNotificationsDisplayMode', function () {
      return this.shouldFetchForMenuItem();
    }),

    fetch: function fetch() {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS.COUNT);
    },

    updateNotificationCount: function updateNotificationCount() {
      var _this = this;

      var data = [{ notificationType: 2, requiresAction: true }, { notificationType: 32, requiresAction: false }];

      this.startRefreshTimer();
      return this.get('authenticatedRequest').post(_scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS.COUNT, data).then(function (response) {
        var toApprove = response ? response.findBy('notificationType', 2) : {};
        var moreInfo = response ? response.findBy('notificationType', 32) : {};
        var notificationsCount = {
          toApprove: toApprove ? toApprove.notificationCount : 0,
          moreInfo: moreInfo ? moreInfo.notificationCount : 0
        };

        var totalCount = notificationsCount.toApprove + notificationsCount.moreInfo;
        _this.set('notificationsCount', totalCount);

        _this.get('customEvents').triggerNotificationCountUpdated(notificationsCount);
        return notificationsCount;
      })['catch'](function () {
        return { toApprove: 0, moreInfo: 0 };
      });
    },

    shouldCreateTimer: function shouldCreateTimer() {
      return !this.get('refreshTimer');
    },

    stopRefreshTimer: function stopRefreshTimer() {
      if (this.get('refreshTimer')) {
        _ember['default'].run.cancel(this.get('refreshTimer'));
        this.set('refreshTimer', null);
      }
    },

    startRefreshTimer: function startRefreshTimer() {
      this.stopRefreshTimer();

      var minutes = parseInt(this.get('systemSettings').getSetting('notificationsCountRefreshMinutes'), 10);
      var refreshInMinutes = !minutes || minutes < 1 ? 5 : minutes;
      // const refreshValueInMs = 10000;
      var refreshValueInMs = refreshInMinutes * 60 * 1000;
      var timer = _ember['default'].run.later(this, 'updateNotificationCount', refreshValueInMs);
      this.set('refreshTimer', timer);
    },

    shouldFetch: function shouldFetch() {
      var setting = this.get('systemSettings').getSetting('pendingNotificationsDisplayMode');
      return [1, 2, 3].includes(setting);
    },

    shouldFetchForNotificationsPage: function shouldFetchForNotificationsPage() {
      var setting = this.get('systemSettings').getSetting('pendingNotificationsDisplayMode');
      return [2, 3].includes(setting);
    },

    shouldFetchForMenuItem: function shouldFetchForMenuItem() {
      var setting = this.get('systemSettings').getSetting('pendingNotificationsDisplayMode');
      return [1, 3].includes(setting);
    },

    isLeavingNotificationPage: function isLeavingNotificationPage() {
      if (!this.shouldFetchForMenuItem()) {
        this.stopRefreshTimer();
      }
    }

  });
});