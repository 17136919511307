define('scientia-resourcebooker/models/booking-form-field-numeric', ['exports', 'ember', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'ember-cp-validations'], function (exports, _ember, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    minimum: [(0, _emberCpValidations.validator)('number', {
      integer: false,
      allowString: true,
      lte: _ember['default'].computed.readOnly('model.maximum'),
      messageKey: 'errors.minimumIsLowerThanMaximum',
      disabled: _ember['default'].computed('model.isManaging', 'model.isMinMaxRestricted', 'model.maximum', function () {
        return !this.get('model.isManaging') || !this.get('model.isMinMaxRestricted');
      })
    })]
  });

  var ValidationsUser = (0, _emberCpValidations.buildValidations)({
    value: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'errors.numberInvalid',
        disabled: _ember['default'].computed('model.isManaging', function () {
          return !this.get('model.isActive') || !this.get('model.isMandatory') || this.get('model.isManaging');
        })
      }), (0, _emberCpValidations.validator)('number', {
        integer: _ember['default'].computed.not('model.allowDecimal'),
        allowString: true,
        gte: _ember['default'].computed.readOnly('model.minimum'),
        disabled: _ember['default'].computed('model.value', function () {
          if (!this.get('model.isActive')) return true;
          if (this.get('model.isManaging')) return true;
          if (!this.get('model.isMinMaxRestricted')) return true;
          if (!this.get('model.isMandatory') && _ember['default'].isEmpty(this.get('model.value'))) return true;
          if (!this.get('model.minimum') && parseInt(this.get('model.minimum') !== 0)) return true;
          return false;
        })
      }), (0, _emberCpValidations.validator)('number', {
        integer: _ember['default'].computed.not('model.allowDecimal'),
        allowString: true,
        lte: _ember['default'].computed.readOnly('model.maximum'),
        disabled: _ember['default'].computed('model.value', function () {
          if (!this.get('model.isActive')) return true;
          if (this.get('model.isManaging')) return true;
          if (!this.get('model.isMinMaxRestricted')) return true;
          if (!this.get('model.isMandatory') && _ember['default'].isEmpty(this.get('model.value'))) return true;
          if (!this.get('model.maximum')) return true;
          return false;
        })
      }), (0, _emberCpValidations.validator)('maxDecimalPlaces', {
        dependentKeys: ['model.allowDecimal', 'model.maxDecimalPlaces'],
        disabled: _ember['default'].computed('model.value', 'model.allowDecimal', 'model.maxDecimalPlaces', function () {
          if (!this.get('model.isActive') || this.get('model.isManaging') || !this.get('model.allowDecimal') || !this.get('model.maxDecimalPlaces')) return true;
          return false;
        })
      })]
    }
  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, ValidationsUser, {
    minimum: (0, _emberDataAttr['default'])('number'),
    maximum: (0, _emberDataAttr['default'])('number'),
    isMinMaxRestricted: (0, _emberDataAttr['default'])('boolean'),
    allowDecimal: (0, _emberDataAttr['default'])('boolean'),
    maxDecimalPlaces: (0, _emberDataAttr['default'])('number')
  });
});