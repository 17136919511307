define('scientia-resourcebooker/pods/components/custom-tooltip/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',
    classNameBindings: [':customTooltip', 'class'],
    location: '',

    triggerText: '?',
    side: 'top'
  });
});