define('scientia-resourcebooker/pods/components/manage-resources/bulk-exporter/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkExporter'],
    classNameBindings: ['location'],
    location: '',
    localePrefix: 'components.manage_resources.bulk_exporter.claims.',
    disableExportSelected: false,
    bulkExporter: _ember['default'].inject.service(),
    exportType: '',

    resourcesSelected: [],

    actions: {

      onSearch: function onSearch(query, deferred) {
        _ember['default'].run.debounce(this, 'send', 'onSearchDebounced', query, deferred, 500);
      },

      onSearchDebounced: function onSearchDebounced(query, deferred) {
        this.attrs.onSearch(query, deferred);
      },

      onDownload: function onDownload(downloadURLProperty, url) {
        window.open(url);
        this.set(downloadURLProperty, null);
      },

      didSelectResources: function didSelectResources(resources) {
        this.set('resourcesSelected', resources);
        this.attrs.onSelect(resources);
      },

      triggerListOpen: function triggerListOpen() {
        this.$().find('.select2-choices').trigger('click');
      }
    }

  });
});