define('scientia-resourcebooker/validators/multiple-choice-min-max', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var MultipleChoiceMinMax = _emberCpValidationsValidatorsBase['default'].extend({

    i18n: _ember['default'].inject.service(),

    validate: function validate(value, options, model /*, attribute*/) {

      // if (options.get('disabled')) return true;
      var currentCount = value ? value.get('length') : 0;
      var minRequired = model.get('minimum');
      var maxRequired = model.get('maximum');

      // Special case for required single checkbox (ie t&c's)
      if (currentCount < minRequired && minRequired === 1 && model.get('items.length') === 1) {
        return this.get('i18n').t('errors.multipleChoiceMinRestrictionSingle').toString();
      }
      if (currentCount < minRequired) {
        return this.get('i18n').t('errors.multipleChoiceMinRestriction', { count: minRequired }).toString();
      }

      if (maxRequired > 0 && currentCount > maxRequired) {
        return this.get('i18n').t('errors.multipleChoiceMaxRestriction', { count: maxRequired }).toString();
      }

      return true;
    }
  });

  MultipleChoiceMinMax.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
     *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = MultipleChoiceMinMax;
});