define('scientia-resourcebooker/pods/components/toggle-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['toggleButtonWrapper'],
    classNameBindings: ['hidden'],
    id: _ember['default'].computed.alias('option.id'),
    selected: [],
    option: null,
    disabled: false,
    ariaLabel: null,
    hidden: _ember['default'].computed.bool('option.hidden'),
    singleValue: false,

    // optionLabelPath: 'label',

    isActive: (function () {
      if (this.get('singleValue')) return this.get('selected') === this.get('id');

      var options = this.get('selected');
      if (_ember['default'].isEmpty(options)) return false;
      return options.includes(this.get('id'));
    }).property('selected', 'selected.[]'),

    actions: {
      toggle: function toggle() {
        this.sendAction('toggled', this.get('option'));
      }
    }
  });
});