define('scientia-resourcebooker/pods/components/language-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    localeProcessor: _ember['default'].inject.service(),
    current: _ember['default'].computed.alias('i18n.locale'),

    locales: _ember['default'].computed(function () {
      return this.get('localeProcessor.localesWithLabel');
    }),

    actions: {
      changeLocale: function changeLocale(val) {
        this.get('localeProcessor').setLocale(val, true);
      }
    }
  });
});