define('scientia-resourcebooker/pods/logged-in/resource/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken(model) {
      return model.resource.get('name');
    },

    queryParams: {
      bookingId: { replace: false, refreshModel: false },
      bookingSource: { replace: false, refreshModel: false },
      editFromPage: { replace: false, refreshModel: false },
      editedPrimary: { replace: false, refreshModel: false },
      cancelPrimary: { replace: false, refreshModel: false }
    },

    store: _ember['default'].inject.service(),
    nestedResourceStore: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    bookingTypeFormFetcher: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    localeProcessor: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('systemSettings').getSetting('disableEditing') && transition.queryParams.bookingId) {
        return this.transitionTo('logged-in.booking-request');
      }

      // Workaround for rb-calendar component not refreshing availability or calendarSettings properly when coming from MyBookings
      // see afterModel()
      this.get('localeProcessor').set('canShowCalendar', false);
    },

    model: function model(params, transition) {
      var _this = this;

      var bookingId = params.bookingId;
      var occurrenceId = params.occurrenceId;
      var bookingRequestPromise = undefined;

      if (occurrenceId) {
        bookingRequestPromise = this.store.queryRecord('booking-request', { bookingId: bookingId, occurrenceId: occurrenceId });
      } else if (bookingId) {
        bookingRequestPromise = this.store.find('booking-request', bookingId);
      }

      return _ember['default'].RSVP.hash({
        bookingType: this._fetchBookingType(params, transition),

        resource: this.get('nestedResourceStore').fetch({
          type: 'bookingType.resource',
          parentId: params.bookingTypeId,
          id: params.id
        })['catch'](function () {
          _this.transitionTo('logged-in.booking-type.index').then(function () {
            _this.flashError(_this.get('i18n').t('pods.logged_in.resource.show.error_resource_not_found').toString(), 15000);
          });
        }),

        resourceRestrictionRules: [],
        events: [],
        bookingRequest: bookingRequestPromise,
        bookingForm: this._fetchBookingForm(params)
      });
    },

    _fetchBookingForm: function _fetchBookingForm(params) {
      if (params.editFromPage === 'manage-resources' || params.editFromPage === 'report') {
        return this.get('bookingTypeFormFetcher').fetch(params.bookingTypeId, params.id);
      } else {
        return this.get('bookingTypeFormFetcher').fetch(params.bookingTypeId);
      }
    },

    _fetchBookingType: function _fetchBookingType(params, transition) {
      var _this2 = this;

      if (params.editFromPage === 'manage-resources' || params.editFromPage === 'report') {
        return this.store.queryRecord('booking-type', { id: params.bookingTypeId, resourceId: params.id, editFromPage: params.editFromPage })['catch'](function () {
          _this2.flashError(_this2.get('i18n').t('pods.logged_in.booking_type.show.error_not_found').toString(), 15000);

          // In case bookingType fails to fetch we need to abort the transition or it will transition to edit booking in a failed state (no booking type)
          transition.abort();
          _this2.send('finished');
        });
      } else {
        return this.store.find('booking-type', params.bookingTypeId)['catch'](function () {
          _this2.transitionTo('logged-in.booking-type.index').then(function () {
            _this2.flashError(_this2.get('i18n').t('pods.logged_in.booking_type.show.error_not_found').toString(), 15000);
          });
        });
      }
    },

    afterModel: function afterModel(model) {

      // Workaround for rb-calendar component not refreshing calendarSettings properly when coming from MyBookings
      // on beforeModel we hid the calendar and after 500ms we show it. This should be enough time for Ember to destroy the component
      _ember['default'].run.later(this, function () {
        this.get('localeProcessor').set('canShowCalendar', true);
      }, 500);

      if (!model.resource || !model.bookingType) return;

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id'),
        bookingTypeName: model.bookingType.get('name'),
        bookingTypeId: model.bookingType.get('id')
      });
    },

    /**
     * When controller is instantiated set editFromPage and editedPrimary queryParams
     */
    setupController: function setupController(controller, model, transition) {

      this._super(controller, model, transition);

      if (transition.queryParams && transition.queryParams.from) {
        controller.set('editFromPage', transition.queryParams.from);
      }

      // If editedPrimary, then cancelPrimary is false
      if (transition.queryParams && transition.queryParams.editedPrimary === 'true') {
        controller.set('editedPrimary', true);
        controller.set('cancelPrimary', false);
      }

      // If cancelPrimary, then editedPrimary is false
      if (transition.queryParams && transition.queryParams.cancelPrimary === 'true') {
        controller.set('editedPrimary', false);
        controller.set('cancelPrimary', true);
      }

      if (transition.queryParams && transition.queryParams.bookingMode) {
        controller.set('bookingMode', parseInt(transition.queryParams.bookingMode), 10);
      }

      if (transition.queryParams && transition.queryParams.date) {
        controller.set('formattedDisplayedDate', transition.queryParams.date);
      }
    },

    resetController: function resetController(controller, isExiting, transition) {

      // If we're leaving this route and there is a bookingRequest in the model,
      // Rollback any changes ONLY if we are not going to the booking-type.show route
      // to edit primary resource
      if (isExiting && controller.get('model.bookingRequest')) {

        try {
          if (!transition || !transition.queryParams || !transition.queryParams.editingPrimary) {
            controller.get('model.bookingRequest').rollbackAttributes();
          }
        } catch (e) {
          // no-op
        }
      }

      // Reset all controller properties
      if (!transition || !transition.queryParams || !transition.queryParams.editingPrimary) {
        controller.resetProperties();
      }
    },

    actions: {

      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var con = this.controllerFor(this.routeName);

        // If we're navigating to a route other than the 3 below we need to clear the resource filters
        var names = ['logged-in.resource.show', 'logged-in.booking-type.show'];
        if (!names.includes(transition.targetName)) {
          this.get('customEvents').triggerClearResourceFilters();

          // Clear any pending changes to the form if we are not editing primary resource
          if (!transition || !transition.queryParams || !transition.queryParams.editingPrimary) {
            try {
              if (con.get('model.bookingForm')) con.get('model.bookingForm').rollbackAttributes();
              if (con.get('model.bookingRequest')) con.get('model.bookingRequest').rollbackAttributes();
              if (con.get('bookingRequest')) con.get('bookingRequest').rollbackAttributes();
            } catch (e) {
              //no-op
            }
          }
        }

        if (transition.targetName.indexOf('logged-in') !== 0) {
          this.store.unloadAll();
        }

        return true;
      }
    }

  });
});