define('scientia-resourcebooker/services/concurrency-group-resource-tree-data-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var parentId = _ref.parentId;
      var id = _ref.id;
      var groupId = _ref.groupId;
      var suffix = _ref.suffix;
      var data = _ref.data;

      var url = this._urlFromTypeAndIds(type, parentId, id, suffix, groupId);

      return this.get('authenticatedRequest').fetch(url, data).then(function (data) {
        return _this.normalizeData(type, data);
      });
    },

    normalizeData: function normalizeData(type, data) {

      var response = {};

      switch (type) {
        case 'resourceType':
          response.results = this.normalizeResourceType(data);
          break;
        case 'resourceGroup':
          response.results = this.normalizeResourceGroup(data.results);
          break;
        case 'resource':
          response.results = this.normalizeResource(data.results);
          break;
        case 'resourceGroupResources':
          response.results = this.normalizeResource(data.results);
          break;
        case 'concurrencygroup':
          response.results = this.normalizeConcurrencyGroup(data.results);
          break;
        default:
          break;
      }

      // Do not add meta for these 2 as they are all retrieved
      if (data.count && data.currentPage && data.totalPages) {
        response.meta = {
          count: data.count,
          currentPage: data.currentPage,
          totalPages: data.totalPages
        };
      }

      return response;
    },

    normalizeResourceType: function normalizeResourceType(data) {
      if (!data) return [];

      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri || 'icons/tag.svg'
        };
      });
    },

    normalizeResourceGroup: function normalizeResourceGroup(data) {
      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri,
          resourceCount: d.resourceIds ? d.resourceIds.length : 0,
          isGroup: true
        };
      });
    },

    normalizeResource: function normalizeResource(data) {
      return data.map(function (d) {
        return {
          id: d.id,
          name: d.name,
          imageUri: d.imageUri || 'icons/tag.svg',
          resourceTypeId: d.resourceTypeId
        };
      });
    },

    normalizeConcurrencyGroup: function normalizeConcurrencyGroup(data) {
      return data.map(function (d) {
        return {
          id: _ember['default'].get(d, 'id'),
          name: _ember['default'].get(d, 'name')
        };
      });
    },

    _urlFromTypeAndIds: function _urlFromTypeAndIds(type, parentId) {
      var id = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
      var suffix = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
      var groupId = arguments.length <= 4 || arguments[4] === undefined ? null : arguments[4];

      var url = undefined;
      if (groupId) {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.CONCURRENCY_GROUPS.RESOURCE_TREE, type).loc(parentId, groupId);
      } else {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.CONCURRENCY_GROUPS.RESOURCE_TREE, type).loc(parentId);
      }
      if (id) url += '/' + id;
      if (suffix) url += '/' + suffix;

      return url;
    }
  });
});