define('scientia-resourcebooker/services/authenticated-request', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    xhr: [],

    fetch: function fetch(url) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (data) data = this._serializeData(data);
      return this._ajax({ url: url, data: data, method: 'get' });
    },

    externalFetch: function externalFetch(externalUrl, data) {
      data = this._serializeData(data);
      return this._ajax({ externalUrl: externalUrl, data: data, method: 'get' });
    },

    post: function post(url, data) {
      var ignoreSerialize = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      if (!ignoreSerialize) {
        data = this._serializeData(data);
      }
      data = JSON.stringify(data);
      return this._ajax({ url: url, data: data, method: 'post', contentType: 'application/json' });
    },

    externalPost: function externalPost(externalUrl, data) {
      data = this._serializeData(data);
      return this._ajax({ externalUrl: externalUrl, data: data, method: 'post' });
    },

    put: function put(url, data) {
      data = this._serializeData(data);
      data = JSON.stringify(data);
      return this._ajax({ url: url, data: data, method: 'put', contentType: 'application/json' });
    },

    'delete': function _delete(url, data) {
      return this._ajax({ url: url, data: data, method: 'delete' });
    },

    _ajax: function _ajax(options) {
      var _this = this;

      var adapter = this;

      var defaults = {
        url: _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + options.url,
        headers: {
          Authorization: 'Bearer ' + this.get('session.authorization.access_token')
        }
      };

      delete options.url;
      options = _ember['default'].$.extend({}, defaults, options, true);
      options.url = options.externalUrl || options.url;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        var hash = _ember['default'].$.ajax(options);

        hash.done(function (data, textStatus, jqXHR) {
          if (jqXHR.status === 401) {
            _this.get('session').timeout();
          } else {
            resolve(_this._extractData(data));
          }
        });

        hash.fail(function (jqXHR) {
          if (jqXHR.status === 401) {
            var loggedInAt = _this.get('session.loggedInAt');

            if (moment().diff(loggedInAt, 'seconds') > 30) {
              _this.get('session').timeout();
            } else {
              _this.get('session').saveRedirectURL();
              _this.get('session').logout();
            }
          } else {
            reject(jqXHR);
          }
        });

        adapter.xhr.push({ url: options.url, xhr: hash });
      });
    },

    getRootUrl: function getRootUrl() {
      return _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/';
    },

    _extractData: function _extractData(data) {
      var dataType = _ember['default'].typeOf(data);

      if (dataType === 'object') {
        return this._extractObject(data);
      } else if (dataType === 'array') {
        return this._extractArray(data);
      } else {
        return data;
      }
    },

    _extractObject: function _extractObject(object) {
      var _this2 = this;

      return Object.keys(object).reduce(function (memo, key) {
        var camelizedKey = key.camelize();

        if (key === 'Identity') {
          memo.id = object.Identity;
        } else if (camelizedKey.endsWith('Identities')) {
          camelizedKey = camelizedKey.slice(0, -10) + 'Ids';
        } else if (camelizedKey.endsWith('Identity')) {
          camelizedKey = camelizedKey.slice(0, -8) + 'Id';
        }

        memo[camelizedKey] = _this2._extractData(object[key]);

        return memo;
      }, {});
    },

    _extractArray: function _extractArray(array) {
      var _this3 = this;

      return array.map(function (item) {
        return _this3._extractData(item);
      });
    },

    _serializeData: function _serializeData(data) {
      var dataType = _ember['default'].typeOf(data);

      if (moment.isMoment(data)) {
        return data.toISOString();
      } else if (dataType === 'object') {
        return this._serializeObject(data);
      } else if (dataType === 'array') {
        return this._serializeArray(data);
      } else {
        return data;
      }
    },

    _serializeObject: function _serializeObject(object) {
      var _this4 = this;

      return Object.keys(object).reduce(function (memo, key) {
        var classifiedKey = key.classify();

        if (key === 'id') {
          memo.Identity = object.id;
          return memo;
        } else if (classifiedKey.endsWith('Ids')) {
          classifiedKey = classifiedKey.slice(0, -3) + 'Identities';
        } else if (classifiedKey.endsWith('Id')) {
          classifiedKey = classifiedKey.slice(0, -2) + 'Identity';
        }

        memo[classifiedKey] = _this4._serializeData(object[key]);

        return memo;
      }, {});
    },

    _serializeArray: function _serializeArray(array) {
      var _this5 = this;

      return array.map(function (item) {
        return _this5._serializeData(item);
      });
    },

    abortRequest: function abortRequest(endpoint) {
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/' + endpoint;
      var matched = this.xhr.filter(function (x) {
        return x.url === url && x.xhr.readyState !== 4;
      });

      matched.forEach(function (req) {
        req.xhr.abort();
      });

      this.xhr.clear();
    }

  });
});