define('scientia-resourcebooker/pods/components/booking-request-form/fields/textbox/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestForm-field bookingRequestForm-field-textbox'],
    attributeBindings: ['fieldId:data-field-id'],
    fieldId: _ember['default'].computed.alias('model.identity'),

    maxLength: _ember['default'].computed('model.maxCharacterLimit', 'model.isCharacterLimited', 'maxBookingFormTextLength', function () {
      if (this.get('model.isCharacterLimited')) return Math.min(this.get('model.maxCharacterLimit'), this.get('maxBookingFormTextLength'));
      return this.get('maxBookingFormTextLength');
    })
  });
});