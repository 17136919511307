define("scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 8,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          dom.setAttribute(el1, "aria-hidden", "true");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "sr-only");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_form.field_required"], [], ["loc", [null, [7, 30], [7, 84]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 23,
                "column": 168
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "aria-atomic", "true");
            dom.setAttribute(el1, "aria-live", "polite");
            dom.setAttribute(el1, "class", "input-error at-bookingRequestForm-field-attachment");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [23, 128], [23, 161]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 24,
                "column": 152
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "aria-atomic", "true");
            dom.setAttribute(el1, "aria-live", "polite");
            dom.setAttribute(el1, "class", "input-error at-bookingRequestForm-field-attachment");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "errorMessage", ["loc", [null, [24, 129], [24, 145]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "tabindex", "0");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-fileInput--details");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-fileInput--details");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [7]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "file-upload", [], ["url", ["subexpr", "@mut", [["get", "uploadURL", ["loc", [null, [14, 10], [14, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", ["subexpr", "@mut", [["get", "fileInputExtensions", ["loc", [null, [15, 13], [15, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "isUploading", ["loc", [null, [16, 15], [16, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "uploadStarted", ["subexpr", "action", ["uploadStarted"], [], ["loc", [null, [17, 20], [17, 44]]], 0, 0], "uploadProgress", ["subexpr", "action", ["uploadProgress"], [], ["loc", [null, [18, 21], [18, 46]]], 0, 0], "uploadFinished", ["subexpr", "action", ["uploadFinished"], [], ["loc", [null, [19, 21], [19, 46]]], 0, 0], "uploadFailed", ["subexpr", "action", ["uploadFailed"], [], ["loc", [null, [20, 19], [20, 42]]], 0, 0], "class", "form-input bookingRequestForm-input at-fileInput"], ["loc", [null, [13, 6], [21, 64]]], 0, 0], ["block", "if", [["get", "didValidate", ["loc", [null, [23, 12], [23, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 6], [23, 175]]]], ["block", "if", [["get", "errorMessage", ["loc", [null, [24, 12], [24, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [24, 6], [24, 159]]]], ["inline", "t", ["components.booking_request_form.fields.attachment.max_file_size"], ["size", ["subexpr", "@mut", [["get", "maxFileSize", ["loc", [null, [27, 135], [27, 146]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 60], [27, 148]]], 0, 0], ["inline", "t", ["components.booking_request_form.fields.attachment.allowed_file_types"], ["types", ["subexpr", "@mut", [["get", "allowedTypes", ["loc", [null, [28, 141], [28, 153]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 60], [28, 155]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 41,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fileUpload--status bs");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "progress");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "progress-bar progress-bar-striped active");
          dom.setAttribute(el3, "role", "progressbar");
          dom.setAttribute(el3, "aria-valuemin", "0");
          dom.setAttribute(el3, "aria-valuemax", "100");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "sr-only");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("%");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element2, 'aria-valuenow');
          morphs[1] = dom.createAttrMorph(element2, 'style');
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "uploadPercentageSafeString", ["loc", [null, [36, 95], [36, 121]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "uploadPercentageCSS", ["loc", [null, [36, 170], [36, 189]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "uploadPercentageSafeString", ["loc", [null, [37, 30], [37, 60]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 2
            },
            "end": {
              "line": 49,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sr-only");
          dom.setAttribute(el1, "aria-atomic", "true");
          dom.setAttribute(el1, "aria-live", "polite");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fileUpload--status");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "fileUpload--status-filename");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "button button-small fileUpload--deleteFile");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_form.fields.attachment.file_uploaded"], [], ["loc", [null, [44, 61], [44, 132]]], 0, 0], ["inline", "truncate", [["get", "fileName", ["loc", [null, [46, 55], [46, 63]]], 0, 0, 0, 0], 40], [], ["loc", [null, [46, 44], [46, 68]]], 0, 0], ["element", "action", ["removeFile"], [], ["loc", [null, [47, 10], [47, 33]]], 0, 0], ["inline", "t", ["components.booking_request_form.fields.attachment.remove_file"], [], ["loc", [null, [47, 85], [47, 154]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 3, 3);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(element5, 4, 4);
        morphs[4] = dom.createMorphAt(element4, 3, 3);
        return morphs;
      },
      statements: [["content", "model.description", ["loc", [null, [4, 6], [4, 27]]], 0, 0, 0, 0], ["block", "if", [["get", "model.isMandatory", ["loc", [null, [5, 12], [5, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [8, 13]]]], ["block", "unless", [["get", "model.value", ["loc", [null, [12, 14], [12, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 4], [31, 17]]]], ["block", "if", [["subexpr", "and", [["get", "isUploading", ["loc", [null, [33, 13], [33, 24]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isUploaded", ["loc", [null, [33, 30], [33, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 25], [33, 41]]], 0, 0]], [], ["loc", [null, [33, 8], [33, 42]]], 0, 0]], [], 2, null, ["loc", [null, [33, 2], [41, 9]]]], ["block", "if", [["get", "fileName", ["loc", [null, [43, 8], [43, 16]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [43, 2], [49, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});