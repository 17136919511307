define('scientia-resourcebooker/pods/manage-resources/resource/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    resourceConcurrencyGroupsFetcher: _ember['default'].inject.service(),

    queryParams: {
      formattedDisplayDate: { replace: false, refreshModel: false },
      bookingId: { replace: true, refreshModel: false },
      occurrenceId: { replace: true, refreshModel: false }
    },

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource.show.page_title') + ' — ' + model.resource.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),

    model: function model(params, transition) {

      var resourceTypeId = transition.params['manage-resources.resource'].resourceTypeId;
      return _ember['default'].RSVP.hash({
        resource: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        bookingTypes: this.store.findAll('booking-type'),
        bookingRequest: this._fetchBookingRequest(params),
        concurrencyGroups: this._fetchConcurrencyGroupsForResource(resourceTypeId, params.id)
      });
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id')
      });

      this.store.find('resource-type', model.resource.get('resourceTypeId')).then(function (resourceType) {
        return model.resource.set('resourceType', resourceType);
      });

      if (model.bookingRequest) {
        var controller = this.controllerFor(this.routeName);
        controller.send('openBookingRequestWithModelLoaded', model.bookingRequest);
      }
    },

    _fetchBookingRequest: function _fetchBookingRequest(params) {
      var _this = this;

      if (!params || !params.bookingId) return null;

      if (params.occurrenceId) {
        return this.store.queryRecord('booking-request', { bookingId: params.bookingId, occurrenceId: params.occurrenceId })['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      } else {

        return this.store.find('booking-request', params.bookingId)['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.booking_request.index.flash_messages.unable_retrieve_booking'));
        });
      }
    },

    _fetchConcurrencyGroupsForResource: function _fetchConcurrencyGroupsForResource(resourceTypeId, resourceId) {
      return this.get('resourceConcurrencyGroupsFetcher').fetch({ resourceTypeId: resourceTypeId, resourceId: resourceId, enabledOnly: true })['catch'](function () {
        // The "Show Concurrency Unavailability" toggle is based on concurrencyGroups.length so we just return a dummy array
        // to force showing the toggle in case this call ever fails for some reason.
        return [0];
      });
    },

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          showBookingRequest: null,
          bookingId: null,
          occurrenceId: null,
          editRestrictionsModal: null,
          showConcurrencyUnavailability: false,
          showTemplateUnavailability: false
        });
      }
    }
  });
});