define('scientia-resourcebooker/services/notification-request-processor', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    accept: function accept(request, note) {
      return this._process(request.approveLink, note, 'accept');
    },

    reject: function reject(request, note) {
      return this._process(request.rejectLink, note, 'reject');
    },

    moreInfo: function moreInfo(request, note) {
      return this._process(request.requestMoreInfoLink, note, 'more-info');
    },

    _process: function _process(url, note, action) {
      var data = { note: note || '' };

      this.get('appInsights').trackEvent('notification-' + action);
      return this.get('authenticatedRequest').externalPost(url, data);
    }
  });
});