define('scientia-resourcebooker/pods/components/system/notifications-templates/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['notificationsTemplates'],
    templates: [],
    selectedTemplate: null,

    // Select the first item on initialization
    defaultSelectionOnInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        if (!this.get('selectedTemplate') && !_ember['default'].isEmpty(this.get('templates'))) {
          this.set('selectedTemplate', this.get('templates').get('firstObject'));
        }
      });
    }).on('init'),

    actions: {
      selectTemplate: function selectTemplate(template) {
        this.set('selectedTemplate', null);
        _ember['default'].run.next(this, 'set', 'selectedTemplate', template);
      },

      sendSample: function sendSample() {
        // console.log('send');
      },

      save: function save() {
        this.sendAction('save', this.get('selectedTemplate'));
      }
    }
  });
});