define('scientia-resourcebooker/pods/components/time-hour-input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':timeInput', 'class'],
    systemSettings: _ember['default'].inject.service(),

    startHour: 0,
    value: null,

    hours: _ember['default'].computed('startHour', function () {

      // The start hour is either an hour which we have passed in manually or the instutition start time
      // let startIndex = this.get('startHour') || this.get('systemSettings').getSetting('calendarStartTimeOffset');

      // let endIndex = 24 - this.get('systemSettings').getSetting('calendarEndTimeOffset');
      var startIndex = 0;
      var endIndex = 24;
      var hours = [];

      for (var index = startIndex; index <= endIndex; index++) {
        hours.push(_ember['default'].Object.create({
          value: index,
          label: index < 10 ? '0' + index : '' + index
        }));
      }

      return hours;
    })

  });
});