define('scientia-resourcebooker/pods/manage-resources/availability/edit/controller', ['exports', 'ember', 'ember-concurrency', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/availability-form'], function (exports, _ember, _emberConcurrency, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAvailabilityForm) {
  // const TABS = {DESCRIPTION: 0, AVAILABILITY: 1};

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsAvailabilityForm['default'], {

    can: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    entityUsageSummaryFetcher: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    queryParams: [{ selectedTab: 'section' }, 'availabilityTab'],

    modelId: _ember['default'].computed.alias('model.availabilityPattern.id'),
    type: 'availability',
    selectedTab: 0,

    patternUsageSummaryData: null,

    readOnly: _ember['default'].computed('model.availabilityPattern.id', function () {
      return this.get('can').can('read-only availability', { model: this.get('model.availabilityPattern') });
    }),

    canEditAll: _ember['default'].computed('model.availabilityPattern.id', function () {
      return this.get('can').can('edit-all availability', { model: this.get('model.availabilityPattern') });
    }),

    canEditDescription: _ember['default'].computed('model.availabilityPattern.id', function () {
      return this.get('can').can('edit-description availability', { model: this.get('model.availabilityPattern') });
    }),

    canEditAvailability: _ember['default'].computed('model.availabilityPattern.id', function () {
      return this.get('can').can('edit-availability availability', { model: this.get('model.availabilityPattern') });
    }),

    resourcesLinked: _ember['default'].computed.alias('model.availabilityPattern.resourcesLinkedTo'),
    bookingTypesLinked: _ember['default'].computed.alias('model.availabilityPattern.bookingTypesLinkedTo'),
    hasLinks: _ember['default'].computed.or('resourcesLinked', 'bookingTypesLinked'),

    /**
     * Fetches summary of pattern usage
     */
    fetchPatternSummary: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var data;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;
            context$1$0.next = 3;
            return this.get('entityUsageSummaryFetcher').fetch({
              type: 'availability',
              id: this.get('model.availabilityPattern.id')
            });

          case 3:
            data = context$1$0.sent;

            this.set('patternUsageSummaryData', data);
            this.get('appInsights').trackAvailabilitySummaryOpen(this.get('model.availabilityPattern'));
            context$1$0.next = 10;
            break;

          case 8:
            context$1$0.prev = 8;
            context$1$0.t0 = context$1$0['catch'](0);

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[0, 8]]);
    })).
    // no-op
    drop(),

    actions: {

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      fetchPreviewEvents: function fetchPreviewEvents(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      deleteModel: function deleteModel() {
        var _this = this;

        var item = this.get('model.availabilityPattern');
        var defer = _ember['default'].RSVP.defer();

        var messagePath = item.get('resourcesLinkedTo') || item.get('bookingTypesLinkedTo') ? 'confirm_delete_message_used' : 'confirm_delete_message_unused';
        var confirmData = {
          titlePath: 'pods.manage_resources.availability.index.confirm_delete_title',
          message: this.get('i18n').t('pods.manage_resources.availability.index.' + messagePath, { resourceCount: item.get('resourcesLinkedTo'), templateCount: item.get('bookingTypesLinkedTo') }),
          showConfirmText: item.get('resourcesLinkedTo') || item.get('bookingTypesLinkedTo'),
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.ok'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this.send('deleteModelConfirm');
        })['catch'](function () {
          _this.get('globalConfirmer').close();
        });
      },

      deleteModelConfirm: function deleteModelConfirm() {
        var _this2 = this;

        var id = this.get('model.availabilityPattern.id');
        var name = this.get('model.availabilityPattern.name');

        this.get('model.availabilityPattern').destroyRecord().then(function () {
          _this2.get('globalConfirmer').close();
          _this2.deleteSuccessFlash('availability');
          _this2.get('appInsights').trackDelete('availability-pattern', { id: id, name: name, from: 'page-edit' });

          _this2.transitionToRoute('manage-resources.availability.index');
        })['catch'](function () {
          _this2.get('globalConfirmer').close();
          _this2.deleteErrorFlash('availability');
        });
      },

      showInUseByModal: function showInUseByModal() {
        this.set('isLoadingInUseBy', true);
      },

      onCancel: function onCancel() {
        this.transitionToRoute('manage-resources.availability.index');
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.availability.index.availability_patterns'),
        path: 'manage-resources.availability.index',
        linkable: true
      }, {
        label: this.get('model.availabilityPattern.name'),
        linkable: false
      }];
    }).property('i18n.locale', 'model.availabilityPattern.name')
  });
});